import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CenterSearchComponent } from '../../entity-searching/center-search.component';
import { PersonSearchComponent } from '../../entity-searching/person-search.component';
import { CenterService } from '../../../services/center.service';
import { PersonService } from '../../../services/person.service';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog.component';
import { flashMessage } from '../../utils';

@Component({
  selector: 'edit-center',
  templateUrl: 'edit-center.component.html',
  styleUrls: ['../editing.scss'],
})
export class EditCenterComponent implements OnInit {
  // true to embed this component, false to not
  @Input() embed = false;

  // collab object to be created/modified
  @Input() collab: any = { contact: {} };

  // emit the change when a collab is saved or deleted
  @Output() collabChange: EventEmitter<any> = new EventEmitter<any>();

  // event for cancel button click (only displayed on embed)
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();

  // 'true' if a new collaborator is being created, and 'false' if an existing one is being updated
  newCollab = true;

  // 'true' if the current user is a curator, and 'false' if they are not
  isCurator = false;

  disableSaveMessage = 'Collaborator tag and name must be available and all required fields populated to save';

  @ViewChild(CenterSearchComponent) collabSearchComponent!: CenterSearchComponent;
  @ViewChild(PersonSearchComponent) contactSearchComponent!: PersonSearchComponent;

  // set to 'true' to show the extra details about projects, and to 'false' to not to show
  showProjects = false;
  // flag as to whether the current value for collab tag is already taken by another collaborator
  tagTaken = false;
  nameTaken = false;
  nameTakenBy = '';

  /**
   * class constructor
   */
  constructor(
    private centerService: CenterService,
    private personService: PersonService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {}

  /**
   * Function to return 'true' if all conditions to save this record have been met.
   *
   * Checks all relevant variables including mandatory fields: 'tag', 'name', and 'sort order'
   * @return {boolean} - 'true' if can save, 'false' if cannot
   */
  canSave(): boolean {
    // check all required inputs have values;
    // 'name' and 'tag' have already been trimmed and validated
    const sortorder = (this.collab.sortorder || '').trim();

    return this.collab.tag && this.collab.name && sortorder && !this.tagTaken && !this.nameTaken;
  }

  /**
   * Figure out if the current user is allowed to edit the collab
   *
   * @returns {any}: true if the current user can edit the collab, false if not
   */
  canEdit(): boolean {
    // curators can edit anything
    if (this.isCurator) {
      return true;
    }
    // this is a new collab, so can edit
    if (this.newCollab) {
      return true;
    }
    // creator can edit until a curator approves
    return this.personService.isCurrentUser(this.collab.user_creator.id);
  }

  /**
   * Figure out if the current user is allowed to delete the collab
   *
   * @returns {any}: true if the current user can delete the collab, false if not
   */
  canDelete(): boolean {
    // can't delete something that doesn't exist
    if (this.newCollab) {
      return false;
    }
    // can't delete if can't edit
    if (!this.canEdit()) {
      return false;
    }
    // can only delete if there are no associated projects
    return this.collab.projcount === 0;
  }

  ngOnInit() {
    if (this.collab.tag) {
      this.centerService.getCollab(this.collab.tag).subscribe((data) => {
        this.collab = data;
        this.newCollab = false;
        // don't emit here! this is the initial retreive for when this component is embedded
      });
    }

    this.personService.currentUser$.subscribe(() => {
      this.isCurator = this.personService.isCurator();
    });
  }

  /**
   * Deletes this center.
   *
   * User is prompted to confirm the deletion.
   */
  deleteCollabRecord(): void {
    if (!this.newCollab) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          header: 'Confirm Delete Collaborator',
          message:
            'Are you sure that you wish to delete this center?' +
            (this.collab.projcount > 0
              ? '<br>This center is associated with one or more projects, ' +
                '<br>so deleting it will result in it being removed from those projects.'
              : ''),
          falselabel: 'Cancel',
          truelabel: 'Delete',
          truebtn: 'btn-danger',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.centerService.deleteCollab(this.collab.tag).subscribe(() => {
            this.collabSearchComponent.clearSelection();
            this.collabChange.emit(this.collab);
          });
        }
      });
    }
  }

  /**
   * Generates simple comma-separated view of projects associated with certain collaborator.
   *
   * which will be within an accordion.
   * @returns {string}: an array of projects where for each projects it contains JSON structure:
   *	{projid, projsym, title}
   */
  displayProjects(): string {
    const projects = [];

    for (const project of this.collab.projects) {
      projects.push('{projid: ' + project.projid + ', projsym: ' + project.projsym + ', title: ' + project.title + '}');
    }

    return projects.join(', ');
  }

  /**
   * Returns true when the value passed is not only whitespaces.
   * @param {string} val - an alphanumeric character string (could be all whitespaces)
   * @return {boolean} - true or false
   */
  isNotEmpty(val: string): boolean {
    if (!val) {
      return false;
    }
    return val.trim() !== '';
  }

  /**
   * Actions for changes in collaborator's view values.
   *
   * On change event in the form, update certain flags and get details about associated projects
   * if a collaborator has been selected. This affects variables 'this.showProjects' and 'this.newCollab'.
   * @return: none
   */
  onCollabChange(): void {
    this.showProjects = false;
    this.nameTakenBy = '';

    if (this.collab.tag) {
      // get information about this individual collaborator and get the extra details about projects
      this.centerService.getCollab(this.collab.tag).subscribe((data) => {
        this.collab = data;
        this.collabChange.emit(this.collab);
        this.newCollab = false;
        this.tagTaken = false;
        this.nameTaken = false;
        this.nameTakenBy = '';
      });
    } else {
      this.newCollab = true;
      this.contactSearchComponent.clearSelection();
    }
  }

  /**
   * sends request to the service responsible for recording this institution's
   * information in the database; validates that the 'name', 'city' and 'country'
   * argument values are set and valid;
   * @return none
   */
  saveCollabRecord(): void {
    if (this.canSave()) {
      this.centerService.saveCollab(this.collab).subscribe(
        (result) => {
          this.collab = result;
          this.collabChange.emit(this.collab);
          this.newCollab = false;
          flashMessage(this.snackBar, 'Save successful!', 'alert-success', 2000);
        },
        () => {
          flashMessage(this.snackBar, 'Save failed.', 'alert-danger', 5000);
        },
      );
    }
  }

  /**
   * Validates this center's tag and name - values are required and must be unique.
   *
   * @return none
   */
  validateCollabTagAndName(): void {
    this.tagTaken = false;
    this.nameTaken = false;
    this.nameTakenBy = '';

    const cachedCollabs = this.centerService.collabs.getValue();
    // tag and name values with whitespaces stripped
    const tag: string = (this.collab.tag || '').trim();
    const name: string = (this.collab.name || '').trim();

    this.tagTaken = !tag;
    this.nameTaken = !name;

    for (let i = 0; i < cachedCollabs.length; i++) {
      if (tag !== '' && tag === cachedCollabs[i].tag) {
        if (this.newCollab) {
          this.tagTaken = true;
        } else if (name !== '' && name === cachedCollabs[i].name) {
          // if this is a modification event, the tag must be valid
          // and if the name has not changed, then it is valid too.
          break;
        }
      }
      if (name && name === cachedCollabs[i].name) {
        this.nameTaken = true;
        this.nameTakenBy = cachedCollabs[i].name;
        // if this is a modification event, then name must be valid
        if (!this.newCollab) {
          break;
        }
      }
    }
  }
}
