import * as Highcharts from 'highcharts';

// define custom symbol path
Highcharts.SVGRenderer.prototype.symbols.cross = function (x: any, y: any, w: any, h: any) {
  return ['M', x, y, 'L', x + w, y + h, 'M', x + w, y, 'L', x, y + h, 'z'];
};

Highcharts.SVGRenderer.prototype.symbols.plus = function (x: any, y: any, w: any, h: any) {
  return ['M', x, y + h / 2, 'L', x + w, y + h / 2, 'M', x + w / 2, y, 'L', x + w / 2, y + h, 'z'];
};

// color-codes used in vis
export const colorCodes = [
  '#D3D3D3',
  '#1414CB',
  '#96E9E9',
  '#008B50',
  '#00B8CE',
  '#91C74B',
  '#7DA564',
  '#C4A2A3',
  '#D0811D',
  '#F69686',
  '#B65578',
  '#A94F97',
  '#D5D5D5',
  '#98383A',
  '#964A94',
  '#0098A3',
  '#000000',
  '#8B8B8B',
  '#FF0000',
];

// some keys are repeated with minor case differences
// to accomodate different (error) spellings
export const doFounderColors: Record<string, string> = {
  'A/J': '#BF5700',
  'NZO/HlLtJ': '#00CCFF',
  '129S1/SvImJ': '#FF8888',
  'PWK/PhJ': '#FF0000',
  'C57BL/6J': '#888888',
  'NOD/ShiLtJ': '#1111FF',
  'WSB/EiJ': '#9900EE',
  'CAST/EiJ': '#00AA00',
};

export const lineDashStyles = [
  'Solid',
  'ShortDash',
  'ShortDot',
  'ShortDashDot',
  'ShortDashDotDot',
  'Dot',
  'Dash',
  'LongDash',
  'DashDot',
  'LongDashDot',
  'LongDashDotDot',
];

// symbols to be used in plots
export const symbols = ['circle', 'diamond', 'triangle', 'square', 'triangle-down', 'cross', 'plus'];

/**
 * converts JavaScript Date object to string representation: "yyyy-mm-dd"
 * @param {any} d - Date any
 * @return {string} - string in the form "yyyy-mm-dd"
 */
export const fromDateToString = (d: any): string => {
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getUTCDate();
  const year = d.getFullYear();

  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }

  return [year, month, day].join('-');
};

/**
 * reads the value of an html element (formatted as "yyyy-mm-dd") and
 * then creates new JavaScript Date object
 * @param {any} element - document object model element
 * @return {any} date - JavaScript Date object (or null)
 */
export const fromStringToDate = (dateString: string): any => {
  // let dateFormat = "yy-mm-dd";
  let date;
  try {
    date = new Date(dateString);
  } catch (error) {
    date = null;
  }
  return date;
};

/**
 * Calculates mean and standard deviation.
 * @param {number[]} nums
 * @return {{mean: number, stdDev: number}}
 */
export const meanStddev = (nums: number[]): any => {
  let sum = 0.0;
  let sumSq = 0.0;
  let count = nums.length;

  nums.forEach(function (num) {
    if (!isNaN(num)) {
      sum += num;
      sumSq += num * num;
    } else {
      count--;
    }
  });

  const mean = sum / count;
  return {
    mean: mean,
    stdDev: Math.sqrt(sumSq / count - Math.pow(mean, 2)),
    count: count,
  };
};

/**
 * Performs an alphanumeric sort on an object array
 * based on the specified object property - 'sortKey'.
 * @param {any} a - object with 'sortKey' property
 * @param {any} b - object with 'sortKey' property
 */
export const sortAlphanumeric = (a: any, b: any) => {
  const reA = /[^a-zA-Z]/g;
  const reN = /[^0-9/.]/g;

  const aA = a.sortKey.replace(reA, '');
  const bA = b.sortKey.replace(reA, '');
  if (aA === bA) {
    const aN = Number(a.sortKey.replace(reN, ''));
    const bN = Number(b.sortKey.replace(reN, ''));
    return aN === bN ? 0 : aN > bN ? 1 : -1;
  } else {
    return aA > bA ? 1 : -1;
  }
};
