import { FlashMessageComponent } from './flash-message.component';
import { MatSnackBar } from '@angular/material/snack-bar';

/**
 * From a passed-in list of strings, generates a single tooltip string which is a coherent comma-separated
 * string listing whatever entity is respresented by those strings (list of people, groups, projects...whatever)
 *
 * @param {string[]} stringList: list of strings to combine
 * @param {string} zeroDefault: default string to display if stringList.length === 0
 * @param {string} prefix: prefix to the tooltip string (ex: 'Groups: ', or 'Shared with -- ')
 *                         (only used if stringList.length > 0)
 * @param {string} postfix: postfix to the tooltip string (only used if stringList.length > 0)
 * @param {string} overShowNum: if stringList.length > showNum, we show the number by which we are over showNum,
 *                                followed by this string (and finally postfix) as the last item in the tooltip
 * @param {number} showNum: limit at which we cut off the items from stringList and instead show
 *                           '#' + overShowNum (+ postfix) as the last item in the list
 * @returns {string}: tooltip string
 */
export function buildListTooltip(
  stringList: string[] = [],
  zeroDefault: string = '',
  prefix: string = '',
  postfix: string = '.',
  overShowNum: string = ' others..',
  showNum: number = 5,
): string {
  // don't allow showNum below 2- shouldn't be using this function if you only want to show 1 item anyway...
  if (showNum < 2) {
    showNum = 2;
  }

  const length = stringList.length;
  if (length === 0) {
    return zeroDefault;
  }

  if (length > showNum) {
    stringList = stringList.slice(0, showNum - 1);
    stringList.push(String(length - (showNum - 1)) + overShowNum);
  }

  let tooltip = prefix + stringList[0];
  for (let i = 1; i < stringList.length - 1; i++) {
    tooltip += ', ' + stringList[i];
  }

  if (stringList.length === 2) {
    tooltip += ' and ' + stringList[1];
  } else if (stringList.length > 2) {
    tooltip += ', and ' + stringList.slice(-1)[0];
  }

  tooltip += postfix;
  return tooltip;
}

/**
 * Generate the tooltip for showing a list of members in the group
 *
 * @param {object} groupArg: group dict for which we are showing the list of members
 */
export function groupMembersTooltip(groupArg: any): string {
  const strings = [];
  for (const member of groupArg.members) {
    strings.push(member.name_or_email + ' (' + member.role + ')');
  }

  return buildListTooltip(strings, 'This group has no members.', 'Members: ');
}

/**
 * Builds a tooltip for opening the dialog to view/edit/create an object
 *
 * @param {any} value: valueCtrl.value, the current value of the input...
 *                     string if the user has typed in a value, object if they have selected an option
 * @param {any} obj: selected object (if there is one)
 * @param {string} primKey: primary key of the selected object... used to check whether there is a selected object
 * @param {boolean} canEdit: whether or not the current user can edit the selected object
 * @param {string} label: object label
 * @returns {string}
 */
export function buildOpenDialogTooltip(value: any, obj: any, primKey: string, canEdit: boolean, label: string): string {
  if (!value) {
    return '';
  }
  let action = 'View';
  if (!obj || !obj[primKey]) {
    action = 'Create';
  } else if (canEdit) {
    action = 'Edit';
  }
  return action + ' ' + label;
}

/**
 * Make sure passed-in text is displayed as text,
 * with any html tags being escaped to not act as html.
 *
 * @param {string} text: passed-in text
 * @param {string[]} allow: list of strings to allow... current possibilities are 'ALL' to just pass through
 *                          and not escape anything if input is trusted, '&' and 'sup' (for sup or sub) right now
 * @returns {string}: text with html tags escaped
 */
export function escapeHtml(text: string, allow: string[] = []): string {
  if (!text) {
    return '';
  }
  if (allow.indexOf('ALL') !== -1) {
    return text;
  }
  if (allow.indexOf('&') === -1) {
    text = text.replace(/&/g, '&amp;');
  }
  text = text.replace(/'/g, '&apos;').replace(/"/g, '&quot;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
  if (allow.indexOf('sup') !== -1) {
    // note, if the actual text was already '&lt;sup&gt;' instead of '<sup>' to begin with...
    // this will actually display it as a superscript... I feel that's a minor potential downside
    text = text
      .replace(/&lt;sup&gt;/g, '<sup>')
      .replace(/&lt;\/sup&gt;/g, '</sup>')
      .replace(/&lt;sub&gt;/g, '<sub>')
      .replace(/&lt;\/sub&gt;/g, '</sub>');
  }
  return text;
}

/**
 * Check if the object is empty.
 *
 * @param {any} obj: object we are checking
 * @returns {boolean} true if the object is empty, false if it is not
 */
export function objIsEmpty(obj: any): boolean {
  for (const prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
}

/**
 * Removes all of the keys from the input object, except those in the keysToKeep list
 *
 * @param {any} inputObject: object from which we are removing unneeded keys
 * @param {string[]} keysToKeep: list of keys to keep
 * @returns {any}: object with unneeded keys removed
 */
export function removeAllKeysExcept(inputObject: any, keysToKeep: string[] = []): any {
  for (const key in inputObject) {
    if (Object.prototype.hasOwnProperty.call(inputObject, key) && keysToKeep.indexOf(key) === -1) {
      delete inputObject[key];
    }
  }
  return inputObject;
}

/**
 * Build a object for the diff between the old and new objects, by comparing values in the object key-by-key.
 * If there are no changes, then the output will be an empty object. This serves two major functions:
 * 1. It allows us to avoid going out to the backend if the user clicks 'save' and there are no changes.
 * 2. This allows us to only send values that have been changed the user to the backend. This way, there will be less
 *    conflicts and lost work if multiple users are modifying the same thing (project, for example) at the same
 *    time.
 *
 *    Consider an example where two users (Al and Bob) begin modifying the same object, {'a': 1, 'b': 2}, in the
 *    front end at the same time. Al changes 'a' to 3, but doesn't touch 'b' and saves. Now, on the backend,
 *    the objects is {'a': 3, 'b': 2}. However, Bob doesn't have the information yet in his front end, so he changes
 *    'b' to 4 (without touching 'a') and saves after Al. If we were just sending the whole object as it was at the
 *    time in Bob's front end, {'a': 1, 'b': 4}, then to the backend it would look like Bob didn't only change 'b' to 4,
 *    but that he also changed 'a' back to 1, so it would undo Al's change. Using this function to build the 'diff'
 *    between the objects before sending them to the backend will allow us to only update values that changed and
 *    avoid this issue (to some degree... if they both changed the same value, then it will still happen).
 *
 * NOTE: oldObject should never have any keys set that newObject does not (generally, don't delete keys from the
 * object when you want to clear out a value: leave the key in the object and and clear out the value)
 *
 * @param {any} newObject: new version of the object that we're comparing key-by-key
 * @param {any} oldObject: old version of the object that we're comparing key-by-key
 * @param {any} objectDiffKeys: object in which the keys are keys within the objects we're comparing that should be
 *                                   compared key-by-key rather than as a single value. The value is itself an object
 *                                   and can contain its own keys, 'objectDiffKeys' and 'orderedListKeys', which
 *                                   act as these same-named arguments for this function. In this way, you can define
 *                                   a cascade of child objects and ordered lists of objects that should be compared
 *                                   key-by-key rather than as a single value. For example:
 *                                   {projdoc: {object_diff_keys: {projdoc_child_object: {}}}}
 * @param {any} orderedListKeys: the same as objectDiffKeys, only for keys that should be compared as
 *                                    ordered lists of objects. For example:
 *                                    {procedures: {ordered_list_keys: {steps: {}}}}
 * @param {String[]} ignoreKeys: list of keys to ignore,
 * @returns {any} {'empty': true if the output is an empty object,
 *                    'output': output object key-by-key diffs}
 */
export function buildObjectDiff(
  newObject: any,
  oldObject: any,
  objectDiffKeys: any = {},
  orderedListKeys: any = {},
  ignoreKeys: string[] = [],
): any {
  if (typeof newObject !== 'object') {
    return { empty: true, output: {} };
  }
  if (typeof oldObject !== 'object') {
    const output = JSON.parse(JSON.stringify(newObject));
    return { empty: objIsEmpty(output), output: output };
  }
  if (JSON.stringify(newObject) === JSON.stringify(oldObject)) {
    return { empty: true, output: {} };
  }

  const output: any = {};
  for (const key in newObject) {
    if (ignoreKeys.indexOf(key) !== -1) {
      // pass
    } else if (Object.prototype.hasOwnProperty.call(newObject, key)) {
      // if the key doesn't exist in oldObject, add it to the output
      let include = !Object.prototype.hasOwnProperty.call(oldObject, key);
      if (!include) {
        if (typeof newObject[key] !== 'object') {
          // default condition- if it has changed, then include
          include = newObject[key] !== oldObject[key];
        } else if (typeof oldObject[key] !== 'object') {
          include = true;
        } else {
          // if the value type is an object, then compare using stringify
          include = JSON.stringify(newObject[key]) !== JSON.stringify(oldObject[key]);
          if (include) {
            // special conditions for value comparisons here:
            if (Object.prototype.hasOwnProperty.call(objectDiffKeys, key)) {
              // condition for only including items in this dict that have changed
              // (don't compare the whole dict, do it item-by-item)
              include = false;
              const keyDiff = buildObjectDiff(
                newObject[key],
                oldObject[key],
                objectDiffKeys[key].object_diff_keys,
                objectDiffKeys[key].ordered_list_keys,
              );
              // NOTE: at this point we know there's a difference, but checking anyway...
              if (!keyDiff.empty) {
                output[key] = keyDiff.output;
              }
            } else if (Object.prototype.hasOwnProperty.call(orderedListKeys, key)) {
              include = false;
              const keyDiff = buildOrderedListDiff(
                newObject[key],
                oldObject[key],
                orderedListKeys[key].object_diff_keys,
                orderedListKeys[key].ordered_list_keys,
              );
              // NOTE: at this point we know there's a difference, and since it's a list, we want to set
              // it regardless of whether or not it's empty (since it could have been cleared out)
              output[key] = keyDiff.output;
            }
          }
        }
      }
      // if include is set, add the key-value pair to output
      if (include) {
        output[key] = newObject[key];
      }
    }
  }
  // if there were any keys in the old object that don't exist in the new,
  // then include the key because its value was cleared out
  for (const key in oldObject) {
    if (ignoreKeys.indexOf(key) !== -1) {
      // pass
    } else if (Object.prototype.hasOwnProperty.call(oldObject, key)) {
      if (!Object.prototype.hasOwnProperty.call(newObject, key)) {
        output[key] = null;
      }
    }
  }
  return { empty: objIsEmpty(output), output: output };
}

/**
 * Build an array for the diff between the old and new ordered list of objects.
 * Build a key-by-key object diff for each index in the new list vs the same index in the old list,
 * where no changes at that index is indicated by an empty object.
 *
 * @param {any[]} newList: old version of the ordered list of objects
 * @param {any[]} oldList: new version of the ordered list of objects
 * @param {any} objectDiffKeys: object in which the keys are keys within the objects we're comparing that should be
 *                                   compared key-by-key rather than as a single value. The value is itself an object
 *                                   and can contain its own keys, 'object_diff_keys' and 'ordered_list_keys', which
 *                                   act as these same-named arguments for this function. In this way, you can define
 *                                   a cascade of child objects and ordered lists of objects that should be compared
 *                                   key-by-key rather than as a single value. For example:
 *                                   {projdoc: {object_diff_keys: {projdoc_child_object: {}}}}
 * @param {any} orderedListKeys: the same as object_diff_keys, only for keys that should be compared as
 *                                    ordered lists of objects. For example:
 *                                    {procedures: {ordered_list_keys: {steps: {}}}}
 * @returns {any} {'empty': true if this is an empty list,
 *                    'output': output ordered list of objects with key-by-key diffs}
 */
export function buildOrderedListDiff(
  newList: any[],
  oldList: any[],
  objectDiffKeys: any = {},
  orderedListKeys: any = {},
): any {
  if (!Array.isArray(newList)) {
    return { empty: true, output: [] };
  }
  if (!Array.isArray(oldList)) {
    const output = JSON.parse(JSON.stringify(newList));
    return { empty: output.length === 0, output: output };
  }
  if (JSON.stringify(newList) === JSON.stringify(oldList)) {
    return { empty: true, output: [] };
  }

  const output = [];
  const oldLength = oldList.length;
  for (let i = 0; i < newList.length; i++) {
    if (i > oldLength - 1) {
      output.push(JSON.parse(JSON.stringify(newList[i])));
    } else {
      // it's important with an ordered list for the output to have the same length as newList, regardless
      // of whether there were any changes in the object in that index (so that the api gets the correct indexes)
      output.push(buildObjectDiff(newList[i], oldList[i], objectDiffKeys, orderedListKeys).output);
    }
  }
  return { empty: output.length === 0, output: output };
}

/**
 * Force/clamp the passed-in number into the passed-in max and min range
 *
 * @param {number} num: number we're forcing into the the range
 * @param {number} min: (optional) min value we're forcing the number to
 * @param {number} max: (optional) max value we're forcing the number to
 * @returns {number}: number forced to be within the range
 */
export function forceRange(num: number, min: number | null = null, max: number | null = null) {
  // if num isn't set, then return non-value (in case the value was cleared out)
  if (num !== 0 && !num) {
    return num;
  }
  if (max === 0 || max) {
    num = Math.min(num, max);
  }
  if (min === 0 || min) {
    num = Math.max(num, min);
  }
  return num;
}

/**
 * Like the range() function in python, except the stop is inclusive
 * Found somewhere on stackoverflow (don't remember where now)
 *
 * @param {number} start: starting number (inclusive)
 * @param {number} stop: stop number (inclusive)
 * @param {number} step: amount to increment by, defaults to 1
 * @returns {Array<number>}: range array based on inputs...example:
 *                           range(0, 4, 1) = [0,1,2,3,4]
 */
export function range(start: number, stop: number, step: number = 1) {
  return Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
}

/**
 * Build a html string for the output string of a 'DateTime' postgres sql column
 *
 * @param {string} date: datetime string in the format, "Fri, 17 Dec 2021 10:37:21 GMT",
 * @returns {string}: date html in the format,
 *                 '1 May, 5:07 PM <span style="color:darkgray">(2018)</span>'
 */
export function buildDateTimeHtml(date: string): string {
  let hours = Number(date.slice(17, 19));
  let ampm = 'AM';
  if (hours > 12) {
    hours -= 12;
    ampm = 'PM';
  }
  const minutes = date.slice(20, 22);
  const timestring = hours + ':' + minutes + ' ' + ampm;
  const displayVal =
    Number(date.slice(5, 7)) +
    ' ' +
    date.slice(8, 11) +
    ', ' +
    timestring +
    ' <span style="color:darkgray">(' +
    date.slice(12, 16) +
    ')</span>';
  const sortVal = date.slice(12, 16) + monthNums[date.slice(8, 11)] + date.slice(5, 7) + date.slice(17, 19) + minutes;
  return '<span style="display:none;">' + sortVal + '</span>' + displayVal;
}

/**
 * Make sure that the passed-in string is a valid email address
 * (from https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript)
 *
 * @param {string} email: string that we are testing to see if it's an email
 * @returns {boolean}: true if the string is an email, false if it is not
 */
export function validateEmail(email: string): boolean {
  if (email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
  }

  return false;
}

/**
 * Use the passed-in snackbar to flash the passed-in message for the passed-in duration in
 * this passed-in css class (bootstrap panel).
 *
 * @param {MatSnackBar} snackBar: snackbar initialized in the component
 * @param {string} message: message to show
 * @param {string} divClass: panel class to display the message in
 * @param {number} duration: duration to show the message
 * @param {string[]} warnings: list of warning strings to show
 */
export function flashMessage(
  snackBar: MatSnackBar,
  message: string,
  divClass: string = '',
  duration: number = 2000,
  warnings: string[] = [],
) {
  snackBar.openFromComponent(FlashMessageComponent, {
    duration: duration,
    data: {
      text: message,
      class: divClass,
      snackbar: snackBar, // TODO: make sure that this works - removed the 'this.' for linting purposes
      listcolor: '#CCCC00',
      listmessages: warnings,
    },
  });
}

// static variable for converting month 3-letter string to 2-digit # string
const monthNums: Record<string, string> = {
  Jan: '01',
  Feb: '02',
  Mar: '03',
  Apr: '04',
  May: '05',
  Jun: '06',
  Jul: '07',
  Aug: '08',
  Sep: '09',
  Oct: '10',
  Nov: '11',
  Dec: '12',
};

// background colors for different statuses
export const statusColors: Record<string, string> = {
  // 'In Review' > Molly marks as approved
  // (at this point would previewing in public mpd/ using curation QC tools become available?)
  Approved: 'green',
  // 'Submitted' > Molly or other curator begins reviewing
  // (is submitted necessary or can we just go straight to 'In Review'?)
  'In Review': '#ADB964',
  // 'In Progress' or 'Needs Work' > user has 'finished' required fields in all pages, gets button to 'Submit'
  Submitted: 'red',
  // 'Approved' > user clicks button to release/make public
  Public: 'blue',
  // 'In Review' > Molly leaves feedback/notes and changes to 'Needs Work'
  'Needs Work': 'darkviolet',
  // newly created project by user, or in any other status and user clicks a button to 'Make Edits'
  'In Progress': 'darkorange',
};
