import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Publication, PublicationService } from '../../../services/publication.service';
import { debounceTime, distinctUntilChanged, startWith, switchMap } from 'rxjs/operators';
import { MatSelectionListChange } from '@angular/material/list';
import { MatDialog } from '@angular/material/dialog';
import { AnimalLinksComponent } from './animal-links.component';
import { SelectionModel } from '@angular/cdk/collections';
import { FlatNode } from '../../../shared/selection-tree/selection-tree.component';
import { SavedAnimal } from '../animal-info-upload/animal-info-upload.component';
import { DivdbPublication } from '../../../services/divdb.service';

@Component({
  selector: 'publication-links',
  templateUrl: './publication-links.component.html',
  styleUrls: ['./publication-links.component.scss'],
})
export class PublicationLinksComponent implements OnInit {
  // publications that SIP (and/or DivDB) know about that can be added to
  @Input() inheritedPublications: Publication[] = [];

  // information about publications selected and linked samples
  @Input() publicationLinks: PublicationLink[] = [];

  // true if the current user should be able to make changes
  @Input() editable = false;

  // available samples to link
  @Input() animals: SavedAnimal[] = [];

  // user-readable IDs for the animals passed in
  animalIDs: string[] = [];

  // new publication input
  newPublication = new FormControl();

  // info about the publication associated with the entered PubMed ID
  publicationInfo$: Observable<Publication>;

  // emits when the user saves publication selections
  @Output() update: EventEmitter<PublicationLink[]> = new EventEmitter<PublicationLink[]>();

  constructor(private pubs: PublicationService, private dialog: MatDialog) {
    this.publicationInfo$ = this.newPublication.valueChanges.pipe(
      startWith(''),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((value) => this.pubs.getPublication(value)),
    );
  }

  ngOnInit() {
    this.animalIDs = this.animals.map((a) => a.animal_id);
  }

  /**
   * Returns whether changes have been made by the user
   */
  get changesMade(): boolean {
    return this.publicationLinks.some((pub) => {
      const initial = pub.initial;
      return (
        pub.selected !== initial.selected ||
        pub.animals.length !== initial.animals.length ||
        !pub.animals.every((a, i) => a === initial.animals[i])
      );
    });
  }

  /**
   * Returns true if the specified publication will be a new addition to the linked publications for the dataset
   * TODO: potentially a temporary function while the publication PATCH endpoint is not yet implemented to prevent
   *   users from making changes to selected animals that cannot be saved in the API
   * @param publication - publication in question
   */
  allowAnimalChanges(publication: PublicationLink): boolean {
    return !publication.initial.selected && publication.selected;
  }

  /**
   * Mark the publication link that was clicked on as (de)selected based on the selection status of the event
   * @param selection - change event resulting from the click of the publication link
   */
  changeSelection(selection: MatSelectionListChange): void {
    this.publicationLinks[selection.options[0].value].selected = selection.options[0].selected;
  }

  /**
   * Adds the new publication as a new publication link and automatically selects it
   * @param publication - publication to create a publication link from
   */
  linkPublication(publication: Publication): void {
    this.newPublication.setValue('');
    this.publicationLinks.push({
      selected: true,
      publication: publication,
      animals: [],
      initial: {
        selected: false,
        animals: [],
      },
    });
  }

  /**
   * Arranges the available samples (if there aren't any yet, this function won't be called) by array and opens a
   * dialog with the samples loaded into a selection tree and listens for the results of the changes to that tree when
   * the dialog is closed
   * @param event - click even on the text calling this function (the click event for the checkbox needs to be stopped)
   * @param publication - publication link that was clicked
   */
  selectLinkedAnimals(event: MouseEvent, publication: PublicationLink): void {
    // this stops the click from (de)selecting the checkbox for the associated publication
    event.stopPropagation();

    // nest all animals under one umbrella
    const noHierarchy = { 'All Animals': this.animalIDs };

    // nest animals under DO generation values
    const animalsByGeneration: Record<string, string[]> = {};
    this.animals.forEach((a) => {
      const gens = Object.keys(animalsByGeneration);
      const doGen = String(a.do_generation);
      if (gens.indexOf(doGen) >= 0) {
        animalsByGeneration[doGen].push(a.animal_id);
      } else {
        animalsByGeneration[doGen] = [a.animal_id];
      }
    });

    const dialogRef = this.dialog.open(AnimalLinksComponent, {
      data: {
        pubmedID: publication.publication.pmid,
        animals: Object.keys(animalsByGeneration).length > 1 ? animalsByGeneration : noHierarchy,
        selections: publication.animals.length ? publication.animals : this.animalIDs,
      },
    });
    dialogRef.afterClosed().subscribe((res: SelectionModel<FlatNode> | undefined) => {
      // if the dialog was closed by way of saving (vs just cancelling)
      if (res) {
        publication.animals = res.selected.filter((node) => !node.expandable).map((node) => node.item);
      }
    });
  }
}

export interface PublicationLink {
  selected: boolean;
  publication: Publication | DivdbPublication;
  animals: string[];
  initial: {
    selected: boolean;
    animals: string[];
  };
}
