import { Component, OnInit } from '@angular/core';
import { PersonService } from '../../services/person.service';

@Component({
  selector: 'tasks',
  templateUrl: 'tasks.component.html',
  styleUrls: ['tasks.component.scss'],
})
export class TasksComponent implements OnInit {
  isCurator = false;

  constructor(private personService: PersonService) {}

  ngOnInit() {
    this.personService.currentUser$.subscribe(() => {
      this.isCurator = this.personService.isCurator();
    });
  }
}
