<!-- buttons to export table content to excel, csv, txt and JSON formats -->
<div class="row">
  <div class="col-md-4"></div>
  <div class="col-md-4 text-center">
    <h3 class="margin-5">{{ title }}</h3>
  </div>
  <div class="col-md-4 text-right">
    <button
      *ngFor="let format of exportFormats"
      mat-flat-button
      class="margin-5"
      (click)="table.exportTable(format, { fileName: currentTimeInMilliseconds })"
    >
      {{ format | uppercase }}
    </button>
  </div>
</div>

<!-- table section -->
<div class="table-container mat-elevation-z8">
  <div class="filter-header">
    <mat-form-field style="width: 300px">
      <input matInput #tableFilter (keyup)="applyFilter($event.target.value)" placeholder="Search" />
      <button *ngIf="tableFilter.value" mat-icon-button matSuffix aria-label="clear" (click)="clearFilter(tableFilter)">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <span style="flex: 1 1 auto"></span>
    <mat-paginator style="float: right" #paginator [pageSize]="defPageSize" [pageSizeOptions]="[5, 10, 20]">
    </mat-paginator>
  </div>
  <mat-table #table="matTableExporter" [dataSource]="dataSource" matSort matTableExporter>
    <ng-container *ngIf="rowSelect && rowIdKey" matColumnDef="select">
      <mat-header-cell [style.flex]="1" *matHeaderCellDef></mat-header-cell>
      <mat-cell [style.flex]="1" *matCellDef="let row">
        <input
          type="checkbox"
          (click)="$event.stopPropagation()"
          (change)="$event ? onRowClick(row) : null"
          [checked]="selection.isSelected(row[rowIdKey])"
        />
      </mat-cell>
    </ng-container>

    <ng-container *ngFor="let column of columns" matColumnDef="{{ column.key }}">
      <mat-header-cell *matHeaderCellDef mat-sort-header [style.flex]="column.width">
        {{ column.label }}
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        [attr.data-title]="column.inline_label ? column.inline_label : column.label ? column.label + ':' : ''"
        [style.flex]="column.width"
      >
        <img
          *ngIf="objType === 'project' && column.key === 'project' && row.actions.bioconnect_id"
          src="./assets/bioconnect_icon.ico"
          alt=""
          [matTooltip]="'Linked to Bioconnect Study: ' + row.actions.bioconnect_identifier"
        />
        <span
          matTooltip="{{ row[column.key + '_tooltip'] }}"
          [style.cursor]="column.cursor ? column.cursor : row[column.key + '_cursor']"
          [style.background-color]="column.bg ? column.bg : row[column.key + '_bg']"
          [style.color]="column.color ? column.color : row[column.key + '_color']"
          [style.padding]="column.padding ? column.padding : row[column.key + '_padding']"
          [style.border-radius]="column.bord_rad ? column.bord_rad : row[column.key + '_bord_rad']"
        >
          <project-actions
            *ngIf="column.type === 'proj-actions'"
            [project]="row[column.key]"
            (projectDeleted)="triggerTableRefresh.emit($event)"
            (projectRestored)="triggerTableRefresh.emit($event)"
            (projectChanged)="triggerTableRefresh.emit($event)"
            (clickExternalLink)="openExternalLink($event)"
          ></project-actions>
          <mat-icon *ngIf="column.type === 'icon'">{{ row[column.key] }}</mat-icon>
          <span *ngIf="column.type === 'text' || !column.type">
            {{ row[column.key] }}
          </span>
          <span
            *ngIf="column.type === 'html'"
            [innerHTML]="row[column.key + '_safe'] || column.safe ? (row[column.key] | SafeHTML) : row[column.key]"
          ></span>
          <button
            *ngIf="column.type === 'button'"
            [class]="column.classes ? column.classes : row[column.key + '_classes']"
            (click)="actionClick.emit({ event: column.click, row: row })"
            [innerHTML]="
              row[column.key + '_safe'] || column.safe
                ? ((column.value ? column.value : row[column.key]) | SafeHTML)
                : column.value
                ? column.value
                : row[column.key]
            "
          ></button>
        </span>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row
      (click)="onRowClick(row)"
      [style.background]="rowBackground(row)"
      *matRowDef="let row; columns: displayedColumns"
    ></mat-row>
  </mat-table>
</div>
