import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';

import { HttpHeaders } from '@angular/common/http';
import { Person } from './person.service';
import { Publication } from './publication.service';

@Injectable()
export class ProjectService {
  // api url
  private api: string = environment.securedURLs.sip;

  // When we either get or save an individual project, then we cache the result in a newly created behaviorsubject,
  // along with the time it was cached. Then, any time that we subsequently get that project, we return the
  // behaviorsubject for the project observable rather than the api call (after checking if the project was cached
  // within some amount of time (10 seconds currently) and refreshing the cache if cache we are over that time).
  //
  // This was done for 2 main reasons:
  // 1. Each page when modifying the project gets and saves the project independent of one another. This caching
  //    makes it so that we hit the server less often and have less screen flashing (from the delay between going
  //    to the page and angular getting the project info from the api) when switching between pages.
  // 2. This also combats race conditions between the save and get when there are autosaves.
  //    Without this caching, if you make changes on one page (say, changing the project title) and then go to a
  //    different page, thus triggering an autosave...often the get for the next page would finish before the
  //    save from the previous page, so you wouldn't have the updated information on the new page.
  //
  // Format:
  // {projid1: {time: Date.now(), observable: new BehaviorSubject<object>(<project object>)}, projid2: {...}, ...}
  private projectCache: any = {};

  // declare http client
  constructor(private http: HttpClient) {}

  /**
   * Observable, which can be subscribed to in order to get a list of projects matching the inputs in the result.
   *
   * @param {string} filterString (optional): filtering arguments string (see project get api)
   */
  searchProjects(filterString: string = ''): Observable<ProjectSearchResults> {
    const url = this.api + 'projects' + filterString;
    // build the api call httpclient observable for getting the project from the sever
    return this.http.get<ProjectSearchResults>(url).pipe(
      tap((result) => {
        // since we're getting a project list, make sure any changes from a recent autosave are applied in the results
        for (let i = 0; i < result.projects.length; i++) {
          const tempProjectID = String(result.projects[i].projid);
          if (this.projectCache[tempProjectID]) {
            // using 1 second here. Note that any field changes provided by the backend won't be there... so
            // the date updated won't be updated if this is a race condition
            if ((Date.now() - this.projectCache[tempProjectID].time) / 1000 < 1) {
              result.projects[i] = this.projectCache[tempProjectID].observable.getValue();
            }
          }
        }
      }),
    );
  }

  /**
   * An observable, which can be subscribed to in order to get a project.
   * Projects, which have been imported from external sources will have unique 'id' and 'external id' values,
   * and either one could be passed as the first parameter to the function.
   * The second parameter defines which value it is - 'id' or 'external id'.
   * @param {string} id (required): this is the application's internal unique project id,
   *     or in case this is an imported project - it could also be the unique source id.
   * @param {boolean} isSourceId: 'true' when the provided 'id' is the source id; defaults to 'false'
   */
  getProject(id: string, isSourceId = false): Observable<Project> {
    const urlField = isSourceId ? 'external_id' : 'projid';

    const url = `${this.api}projects?${urlField}=${id}`;

    // build the api call httpclient observable for getting the project from the sever
    const apiCall = this.http.get<Project>(url).pipe(
      tap((result) => {
        const projid = result.projid;
        if (this.projectCache[projid]) {
          // update the project cache for this project if it already exists
          this.projectCache[projid].time = Date.now();
          this.projectCache[projid].observable.next(result);
        } else {
          // create the project cache for this project if it doesn't already exist
          this.projectCache[projid] = { time: Date.now(), observable: new BehaviorSubject<any>(result) };
        }
      }),
    );
    // this caching serves the dual purpose of not hitting the api too often to get the same information when
    // switching between pages in the api and also making it so that switching pages after an autosave will
    // immediately show any updates
    if (!isSourceId && this.projectCache[id]) {
      if ((Date.now() - this.projectCache[id].time) / 1000 > 10) {
        // if it has been over 10 seconds since the last time this was cached, then update the cache, but
        // still return the behaviorsubject because when this get request finishes, it will update the
        // behaviorsubject, and even better...if we are in a race condition with a save, then the save should
        // probably finish after this get and cause the behaviorsubject to have the most up-to-date info.
        apiCall.subscribe();
      }
      return this.projectCache[id].observable;
    }
    return apiCall;
  }

  /**
   * Observable for saving a project
   *
   * @param {any} project: any containing project data to save
   * @returns {Observable<any>}: Result is the updated project object on success
   *                                (not all changes sent may have been allowed)
   */
  saveProject(project: any): Observable<any> {
    const url = this.api + 'projects';
    if (project.projid) {
      if (this.projectCache[String(project.projid)]) {
        // at the beginning of a save, set the cached time to now, since the behaviorsubject will be updated with the
        // most up-to-date information when the save finishes... this mitigates the chances of a race condition with
        // a get resulting in the get bringing in out-of-date information after the save finishes (since save tends to
        // be much slower, so it's unlikely that a get that began before the save will finish after the save finished)
        this.projectCache[String(project.projid)].time = Date.now();
      }
    }
    return this.http
      .put<any>(url, project ? project : {}, { headers: new HttpHeaders().set('Content-Type', 'application/json') })
      .pipe(
        tap((result) => {
          const projid = String(result.projid);
          if (this.projectCache[projid]) {
            // update the project cache for this project if it already exists
            this.projectCache[projid].time = Date.now();
            this.projectCache[projid].observable.next(result);
          } else {
            // create the project cache for this project if it doesn't already exist
            this.projectCache[projid] = { time: Date.now(), observable: new BehaviorSubject<any>(result) };
          }
        }),
      );
  }

  /**
   * Observable for deleting a project
   *
   * @param {number} projid: projid of the project to delete
   * @param {boolean} permanent: true to delete permanently (only works for curators), otherwise archives the project
   * @returns {Observable<any>}: Result is a 204 code on success
   */
  deleteProject(projid: number, permanent: boolean = false): Observable<any> {
    let url = this.api + 'projects?projid=' + String(projid);
    if (permanent) {
      url += '&permanent=true';
    }
    return this.http.delete<any>(url);
  }

  /**
   * Trigger release of the project by sending it to public 'staging' schema.
   *
   * This is a several-step process, that will look something like this:
   * 1. Curation 'mpdrelease' PUT endpoint verifies the project can be released and the user has
   *    permission to do so, then sends a request to trigger the protected 'curationrelease' POST endpoint on public.
   * 2. Public 'curationrelease' POST endpoint sends a request to curation 'mpdrelease' POST endpoint to get
   *    table row information (this is creates a closed circuit for better security).
   * 3. Curation 'mpdrelease' POST endpoint once again verifies the project can be previewed or released and the
   *    user has permission to do so, then creates a copy of the schema, runs alembic scripts to downgrade the copy
   *    to the current version public is in, and then builds table row information for the project and send them
   *    back to the public 'curationrelease' POST endpoint in the response.
   * 4. Public 'curationrelease' POST endpoint makes sure that the Curation 'mpdrelease' POST endpoint was successful,
   *    and if it was, then it deletes the project and re-creates it using the table row information received from
   *    the curation 'mpdrelease' POST endpoint.
   *
   * @param {string} projid: project id
   * @returns {Observable<any>}: Result on success is json: project dict (not the full thing, just the projects table)
   *                                otherwise a 400, 403, or 500 code with an error message
   */
  releaseProject(projid: number) {
    const url = this.api + 'mpdrelease';
    return this.http.put<any>(
      url,
      { projid: projid },
      { headers: new HttpHeaders().set('Content-Type', 'application/json') },
    );
  }

  /**
   * Observable to trigger processing of the data file and datadict info into the mpd data schema
   * (pheno_measures, animdaldatapoints, etc) using the schemapop api.
   *
   * @param {string} projid: project id
   * @param {string} currentmeasures: 'delete' to delete all current measures before creating new measures,
   *                                  otherwise just leave existing measures alone and only add new measures
   *                                  with varnames that don't already exist
   * @returns {Observable<any>}: Result on success is json:
   *                                {'messages': an array of feedback messages,
   *                                 'dataformat': optional, project.dataformat dict,
   *                                 'pheno_measures': optional, project.pheno_measures array of dicts},
   *                                otherwise a 400, 403, or 500 code with an error message
   */
  processProjectData(projid: string = '', currentmeasures: string = ''): Observable<any> {
    const url = this.api + 'projects/processdata';
    return this.http.post<any>(
      url,
      { projid: projid, currentmeasures: currentmeasures },
      { headers: new HttpHeaders().set('Content-Type', 'application/json') },
    );
  }

  /**
   * Run analysis on a single passed-in measnum
   *
   * @param {number} measnum: measnum to analyze
   * @returns {Observable<any>}: JSON response,
   *                                {'message': success or error message}, 200 code if successful,
   *                                otherwise a 400, 403, or 500 error code
   */
  analyzeMeasure(measnum: number): Observable<any> {
    const url = this.api + 'projects/processdata';
    return this.http.put<any>(
      url,
      { measnum: measnum },
      { headers: new HttpHeaders().set('Content-Type', 'application/json') },
    );
  }

  /**
   * @param {string} apiID: identifies the external data resource (from which data will be imported)
   * @param {string} externalId: external ID of the data being imported
   * @param {number | null} projid: optional projid to import into an existing project
   * @return {Observable<any>}: JSON response,(id: string or null, message: string, warnings: object[])
   */
  importRequest(apiID: string, externalId: string, projid: number | null = null): Observable<any> {
    let url = this.api + 'projects/import/' + apiID + '/' + externalId;
    if (projid) {
      url += '?projid=' + projid;
    }

    return this.http.get<any>(url);
  }

  /**
   * @param {string} apiID: identifies the external data resource (from which data will be imported)
   * @param {number} projid: optional projid to import into an existing project
   * @return {Observable<any>}: JSON response
   */
  exportRequest(apiID: string, projid: number): Observable<any> {
    const url = this.api + 'projects/export/' + apiID + '/' + projid;

    return this.http.get<any>(url);
  }

  /**
   * Observable, which can be subscribed to in order to get a list of project genotype datasets
   *
   * @param {string} projid (required): id of project the datasets are associated with
   */
  getProjectGenotypeDatasets(projid: string): Observable<any> {
    const datasets = new BehaviorSubject<any[]>([]);
    const url = this.api + 'projects/genotypes?projid=' + String(projid);
    return this.http.get<any[]>(url).pipe(
      tap((result) => {
        datasets.next(result);
      }),
    );
  }

  /**
   * Save a project genotype dataset
   *
   * @param {string} projid: project id
   * @param {number} genotypeDatasetID: divDB dataset ID
   */
  saveProjectGenotypeDataset(projid: string, genotypeDatasetID: number) {
    const url = this.api + 'projects/genotypes';
    return this.http
      .post<any>(
        url,
        { projid: projid, genotype_dataset_id: genotypeDatasetID },
        { headers: new HttpHeaders().set('Content-Type', 'application/json') },
      )
      .subscribe();
  }

  /**
   * Observable for deleting a genotype dataset
   *
   * @param {string} projectId: project id
   * @param {number} genotypeDatasetID: divDB dataset ID
   */
  deleteProjectGenotypeDataset(projectId: string, genotypeDatasetID: number): Observable<any> {
    const url = `${this.api}projects/genotypes?genotype_dataset_id=${genotypeDatasetID}&projid=${projectId}`;
    return this.http.delete<any>(url);
  }
}

// defining the structure of data received from the API
/* eslint-disable camelcase */
export interface Project {
  ages: string;
  availstat: string;
  canDelete: boolean;
  canEdit: boolean;
  changesincereleased: any; // TODO: find out what this is
  correspondingpi: Person;
  corrpi: number;
  createdtime: string;
  current_user_permission: string;
  deletedtime: string;
  external_id: any; // TODO: find out what this is
  bioconnect_id: number;
  bioconnect_identifier: string;
  genotypes: {
    genotype_dataset_id: number;
    id: number;
    projid: number;
  }[];
  grouppermissions: any[]; // TODO: find out what this is
  has_datatypes: string[];
  has_genotypes: boolean;
  has_phenotypes: boolean;
  has_procedures: boolean;
  import_msgs?: any[]; // TODO: find out what this is
  instauth: any; // TODO: find out what this is
  isCurator: boolean;
  isReviewer: boolean;
  isOwner: boolean;
  largecollab: any; // TODO: find out what this is
  largecollab_rel: any; // TODO: find out what this is
  mpdsector: string;
  ncohorts: number;
  nstrains: number;
  onlyOneOwner: boolean;
  other_shared_entities: string[];
  owner: Person;
  panel: any; // TODO: find out what this is
  paneldesc: string;
  panelsym: string;
  participants: any; // TODO: find out what this is
  pheno_measures?: any[]; // TODO: find out what this is
  pistring: string;
  popcount_f: number;
  popcount_m: number;
  pophints: string;
  primarypublicationsinfo: Publication[];
  projid: number;
  projsym: string;
  projyear: string;
  releasedate: string;
  releasedtime: string;
  rnaseq_id: number;
  seriesstub: any; // TODO: find out what this is
  seriestag: any; // TODO: find out what this is
  sexes: string;
  shared_entities_exist: boolean;
  species: string;
  status: string;
  table_form: boolean;
  tags: string;
  title: string;
  treatments: any[]; // TODO: find out what this is
  updatedtime: string;
  url: string;
  userpermissions: Person[];
}

export interface ProjectFilter {
  filterValues: any[];
  key: string;
  type: string;
}

export interface ProjectSearchResults {
  projects: Project[];
  filters?: ProjectFilter[];
}
/* eslint-enable camelcase */
