import { Component, Input } from '@angular/core';
import { Person } from '../../services/person.service';

@Component({
  selector: 'person-display',
  template: `
    <span
      *ngIf="showCanDelete && person.candelete"
      style="color:red"
      matTooltip="You can delete this person"
      class="glyphicon glyphicon-trash"
    ></span>
    <span
      *ngIf="showCuratorIcon && person.approle === 'Curator'"
      style="color:gold"
      matTooltip="This user is a SIP curator"
      class="glyphicon glyphicon-certificate"
    ></span>
    <span
      *ngIf="showCuratorIcon && person.approle === 'Reviewer'"
      style="color:mediumpurple"
      matTooltip="This user is a SIP curator and reviewer"
      class="glyphicon glyphicon-certificate"
    ></span>
    <span
      *ngIf="person.isuser"
      style="color:blue;"
      matTooltip="This person is a user"
      class="glyphicon glyphicon-user"
    ></span>
    <span
      *ngIf="person.emailverified"
      style="color:green;"
      matTooltip="User email verified"
      class="glyphicon glyphicon-ok-circle"
    ></span>
    {{ nameOrEmail ? person.name_or_email : person.display_str }}
    <small *ngIf="person.orcid && !nameOrEmail">| ORCID: {{ person.orcid }}</small>
  `,
  styles: [],
})
export class PersonDisplayComponent {
  // person object to display
  @Input() person!: Person;

  // true to only show the name or email (instead of full display_str and orcid)
  @Input() nameOrEmail = false;

  // flag to show an icon to indicate is the user can delete this person
  @Input() showCanDelete = false;

  // flag to show an icon to indicate if the person is a mpd curator
  @Input() showCuratorIcon = false;
}
