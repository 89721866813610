// This file uses Highcharts and because I'm not familiar, I don't feel
// confident in resolving linting issues for it
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Component, Input, OnInit } from '@angular/core';

import * as Highcharts from 'highcharts';

// utils
import { colorCodes, meanStddev, symbols } from '../utils';

declare let require: any;
const Boost = require('highcharts/modules/boost');
const More = require('highcharts/highcharts-more');

Boost(Highcharts);
More(Highcharts);

@Component({
  selector: 'strain-survey-plot',
  templateUrl: './strain-survey-plot.component.html',
  styleUrls: ['../data-validation.scss'],
})
export class StrainSurveyPlotComponent implements OnInit {
  @Input() qcData: any[] = [];
  @Input() phenoInfoVariables: any[] = [];
  @Input() selectedMeasure: {
    datatype: string;
    id: string;
    seriesvarsuffix: string[];
    varname: string;
    units: string;
    projsym: string;
    seriestype: string;
    serieslabels: string[];
    seriesvarnames: string[];
  } = {
    datatype: '',
    id: '',
    seriesvarsuffix: [],
    varname: '',
    units: '',
    projsym: '',
    seriestype: '',
    serieslabels: [],
    seriesvarnames: [],
  };

  // Highchart's chart object
  chart: any = null;
  // in case the data is categorical, this array will contain the categories
  categories: any[] = [];
  // only used when visualizing series measures
  selectedSerie = '';

  // plot categories on the first xAxis
  categoriesX0: string[] = [];
  // plot categories on the second xAxis
  categoriesX1: string[] = [];
  categoriesY: string[] = [];

  // available formats to export the table data:
  // check valid options in TableComponent class
  exportFormats: string[] = ['xlsx', 'csv', 'txt', 'json'];

  // quick look-up map between plot categoris (strains) and the data
  strainIdToDataMap = {};

  selectedInfoVar1Value = '--';
  selectedInfoVar2Value = '--';
  selectedFactorValues: string[] = [];

  // dynamic subset (of the complete data set), which is updated according to the current
  // options selection and that contains just the data needed for rendering
  plotData: any[] = [];

  measureSeries: any[] = [];

  selectedTableData: {
    id: string;
    projsym: string;
    measnum: number;
    sex: string;
    strain: string;
    value: number;
    varname: string;
    zscore: number;
  }[] = [];

  visTableCols = [
    { key: 'id', label: 'ID', width: '4', inline_label: 'id' },
    { key: 'projsym', label: 'Symbol', width: '2', inline_label: 'projsym' },
    { key: 'measnum', label: 'Measure', width: '2', inline_label: 'measnum' },
    { key: 'sex', label: 'Sex', width: '2', inline_label: 'sex' },
    { key: 'strain', label: 'Strain', width: '2', inline_label: 'strain' },
    { key: 'value', label: 'Value', width: '2', padding: '0 2px', inline_label: 'value' },
    { key: 'varname', label: 'Varname', width: '2', inline_label: 'varname' },
    {
      key: 'remove',
      label: 'Deselect',
      width: '1',
      click: 'remove',
      type: 'button',
      classes: 'btn btn-danger',
      safe: true,
      value: '<span class="glyphicon glyphicon-remove"></span>',
    },
  ];

  ngOnInit() {
    // inserting a line break inside HighCharts legend is not supported via API,
    // but is requirement in this plot. The below code is a 'hack' to accomplish
    // this. It adds a fake new property called 'newLine', which when found in the
    // series options will create a line break in the legend.
    // More information: https://jsfiddle.net/BlackLabel/zbcLeyat
    Highcharts.wrap(Highcharts.Legend.prototype, 'layoutItem', function (proceed, item) {
      const options = this.options,
        padding = this.padding,
        horizontal = options.layout === 'horizontal',
        itemHeight = item.itemHeight,
        itemMarginBottom = options.itemMarginBottom || 0,
        itemMarginTop = this.itemMarginTop,
        itemDistance = horizontal ? Highcharts.pick(options.itemDistance, 20) : 0,
        maxLegendWidth = this.maxLegendWidth,
        itemWidth = options.alignColumns && this.totalItemWidth > maxLegendWidth ? this.maxItemWidth : item.itemWidth;

      // If the item exceeds the width, start a new line
      if (horizontal && (this.itemX - padding + itemWidth > maxLegendWidth || item.userOptions.newLine)) {
        this.itemX = padding;
        this.itemY += itemMarginTop + this.lastLineHeight + itemMarginBottom;
        this.lastLineHeight = 0; // reset for next line (#915, #3976)
      }

      // Set the edge positions
      this.lastItemY = itemMarginTop + this.itemY + itemMarginBottom;
      this.lastLineHeight = Math.max(
        // #915
        itemHeight,
        this.lastLineHeight,
      );
      // cache the position of the newly generated or reordered items
      item._legendItemPos = [this.itemX, this.itemY];

      // advance
      if (horizontal) {
        this.itemX += itemWidth;
      } else {
        this.itemY += itemMarginTop + itemHeight + itemMarginBottom;
        this.lastLineHeight = itemHeight;
      }

      // the width of the widest item
      this.offsetWidth =
        this.widthOption ||
        Math.max(
          (horizontal
            ? this.itemX -
              padding -
              (item.checkbox
                ? // decrease by itemDistance only when no checkbox #4853
                  0
                : itemDistance)
            : itemWidth) + padding,
          this.offsetWidth,
        );
    });
  }

  /**
   * returns true in case the selected measure is numeric
   * @return boolean
   */
  isMeasureNumeric(): boolean {
    return this.selectedMeasure.datatype === 'numeric';
  }

  /**
   * sets series data opacity: used on legend hovering functionality
   * @param opacity {number} - opacity value between 0 and 1
   * @param entry {any} - Highcharts series object
   * @param itemName {string} - legend item label/name
   */
  setSeriesOpacity(opacity: number, entry: any, itemName: string): void {
    // with 2-factor selection, series naming format is - Factor1:Factor2
    const nameParts = entry.name.split(':');
    // only applied with 2-factor selection
    if (nameParts.length === 2 && nameParts[0] !== itemName && nameParts[1] !== itemName) {
      entry.update({ opacity: opacity });
    }
  }

  /**
   * sets the 'plot options' combobox value
   * @param {string} value - the value to be set to
   */
  setPlotOptions(value: string): void {
    this.selectedSerie = value;
  }

  /**
   * clears both 'info variables' combo boxes
   */
  clearInfoVars(): void {
    this.selectedInfoVar1Value = '--';
    this.selectedInfoVar2Value = '--';
  }

  /**
   * updates the table's data source based on the current points selection
   */
  updateTable(): void {
    this.selectedTableData = [...this.selectedTableData];
  }

  /**
   * deselects the point with the same ID as the argument
   * @param {any} tableRow - table row object
   */
  deselectPoint(tableRow: any): void {
    const points = this.chart.getSelectedPoints();

    for (let i = 0; i < points.length; i++) {
      if (points[i].id === tableRow.id) {
        points[i].select(null, true);
        break;
      }
    }
  }

  /**
   * handles events changing the 'information variable' select options
   * @param {string} val - selected option value
   * @param {number} index = 1 for 'info variable 1' and 2 for 'info variable 2'
   */
  oninfoVarChange(val: string, index: number): void {
    index === 1 ? (this.selectedInfoVar1Value = val) : (this.selectedInfoVar2Value = val);
    if (this.selectedInfoVar1Value === '--') {
      this.selectedInfoVar2Value = '--';
    }
    this.setplotData();
    this.render();
  }

  /**
   * handles series measure label dropdown change events:
   * the dropdown is only available/visible for series measures
   * @param {string} val - selected option value
   */
  onseriesChange(val: string): void {
    this.selectedSerie = val;
    this.setplotCategories();
    this.setplotData();
    this.render();
  }

  /**
   * Inverts the chart based on the specific checkbox selection
   * @param {any} event - an object representing the triggered event
   */
  onChartInverted(event: any): void {
    const strainsOnX = event.target.checked;
    this.chart.update({
      chart: { inverted: !strainsOnX },
    });
    this.chart.redraw();
  }

  /**
   * Upon selecting/unselecting points, updates the table records
   * @param {any[]} selection - selected points array
   */
  onPointSelectionChange(selection: any[]): void {
    this.selectedTableData.length = 0;

    selection.forEach((e: any) => {
      this.selectedTableData.push({
        id: e.options.id,
        projsym: e.options.projsym,
        measnum: e.options.measnum,
        sex: e.options.sex,
        strain: e.options.strain,
        value: e.y,
        varname: e.options.varname,
        varname_tooltip: e.options.varname,
        zscore: e.options.zscore,
      });
    });
    this.updateTable();
  }

  /**
   * Shows or hides the mean/sd errorbars depending on the selection
   * @param {any} event - an object representing the triggered event
   */
  showMeanStdDev(event: any): void {
    const showMeanStdDev = event.target.checked;

    if (showMeanStdDev) {
      const means = this.setmeanAndStdDev();

      // chart.series[0] is used for Female mean & SD values
      this.chart.series[0].setData(means[0]);
      // chart.series[1] is used for Male mean & SD values
      this.chart.series[1].setData(means[1]);
    } else {
      // chart.series[0] is used for Female mean & SD values
      this.chart.series[0].setData([]);
      // chart.series[1] is used for Male mean & SD values
      this.chart.series[1].setData([]);
    }
  }

  /**
   * Sets the data point properties needed to visualize the
   * mean and SD linees per strain
   */
  setmeanAndStdDev(): any[] {
    const meansStdDevFemales = [];
    const meansStdDevMales = [];

    // populate the 'series' array (and property)
    for (const p in this.strainIdToDataMap) {
      if (Object.prototype.hasOwnProperty.call(this.strainIdToDataMap, p)) {
        const mapping = this.strainIdToDataMap[p];

        if (mapping.data.length) {
          const meansStdDev = {
            x: mapping.data[0].x,
            low: mapping.meanStdDev.mean - mapping.meanStdDev.stdDev,
            high: mapping.meanStdDev.mean + mapping.meanStdDev.stdDev,
            custom: {
              mean: mapping.meanStdDev.mean.toFixed(3),
              se: '+/-' + mapping.meanStdDev.stdDev.toFixed(3),
            },
          };

          if (p.substring(p.length - 2, p.length - 1) === 'F') {
            meansStdDevFemales.push(meansStdDev);
          } else {
            meansStdDevMales.push(meansStdDev);
          }
        }
      }
    }

    return [meansStdDevFemales, meansStdDevMales];
  }

  /**
   * accomplishes the following vis preparation tasks:
   * 1) sets data categories - each (strain. sex) form one category:
   * such as 'C57BL/6J(M)', 'C57BL/6J(F)', 'LP/J(M)', 'LP/J(F)', etc.
   * 2) creates a fast category data look-up object/structure
   * 3) converts raw data objects into visualization data object
   */
  setplotCategories(): void {
    // empty collections
    this.categoriesY = [];
    this.categoriesX0 = [];
    this.categoriesX1 = [];

    this.plotData = [];

    this.strainIdToDataMap = {};

    // each sample has many attributes: only assign the one used in the current vis
    let sampleDataAttribute = this.selectedMeasure.varname;

    if (this.selectedMeasure.seriestype) {
      if (this.selectedSerie === '') {
        this.selectedSerie = this.selectedMeasure.seriesvarnames[0];
      }

      sampleDataAttribute = this.selectedSerie;
    }

    this.qcData.forEach((sample) => {
      if (sample[sampleDataAttribute] !== null) {
        const sex = sample.sex.toUpperCase() || '';
        const strain = sample.strain || '';
        // categories are formed from (strain, sex) pairs
        const sampleStrainSex = `${strain}(${sex})`;
        const xindex = this.categoriesX0.indexOf(sampleStrainSex);

        if (xindex < 0) {
          // new (strain, sex) category
          // x0 axis is used to plot the data
          this.categoriesX0.push(strain + '(F)');
          this.categoriesX0.push(strain + '(M)');
          // x1 axis is used to display axis labels (strains)
          this.categoriesX1.push(strain);

          this.strainIdToDataMap[strain + '(F)'] = {
            name: strain + '(F)',
            data: [],
            meanStdDev: {},
          };
          this.strainIdToDataMap[strain + '(M)'] = {
            name: strain + '(M)',
            data: [],
            meanStdDev: {},
          };
        }

        // categorical measures need yAxis categories
        if (!this.isMeasureNumeric()) {
          const cat = sample[sampleDataAttribute];

          const yIndex = this.categoriesY.indexOf(cat);
          if (yIndex < 0) {
            this.categoriesY.push(cat);
            sample.y = this.categoriesY.length - 1;
          } else {
            sample.y = yIndex;
          }
        } else {
          sample.y = sample[sampleDataAttribute];
        }

        sample.x = this.categoriesX0.indexOf(sampleStrainSex);

        sample.id = sample.animal_id;
        sample.projsym = this.selectedMeasure.projsym;
        sample.measnum = this.selectedMeasure.id;
        sample.varname = sampleDataAttribute;

        this.plotData.push(sample);
      }
    });
  }

  /**
   * based on the selected plot options, instantiates an object with all
   * necessary parameters and values to build and renter the visualization;
   * also binds add-ons to the plot builder objec
   */
  render(): void {
    // Working with Highcharts - not familiar so allowing this for now
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    // existing chart is destroyed
    if (this.chart !== null) {
      this.chart.destroy();
    }

    const clickedLegendItems: string[] = [];
    const series: any = [];
    // numeric measures: series[0] and series[1] hold MEAN and SE errorbars
    if (this.isMeasureNumeric()) {
      const means = this.setmeanAndStdDev();

      series.push({
        name: 'FemaleMean',
        color: 'grey',
        xAxis: 0,
        type: 'errorbar',
        stemWidth: 3,
        data: means[0],
        allowPointSelect: false,
        tooltip: {
          pointFormat: 'Mean: <b>{point.options.custom.mean}</b><br> ' + 'SD: <b>{point.options.custom.se}</b>',
        },
      });
      series.push({
        name: 'MaleMean',
        color: 'grey',
        xAxis: 0,
        type: 'errorbar',
        stemWidth: 3,
        data: means[1],
        allowPointSelect: false,
        tooltip: {
          pointFormat: 'Mean: <b>{point.options.custom.mean}</b><br> ' + 'SD: <b>{point.options.custom.se}</b>',
        },
      });
    }

    // add measures series
    for (const s of that.measureSeries) {
      series.push(s);
    }

    // "hidden" series: used to show strain labels
    series.push({
      xAxis: 1,
      visible: false,
      name: 'mock',
      data: [],
      showInLegend: false,
    });

    let subtitleText = that.selectedMeasure.varname ? that.selectedMeasure.varname : null;
    if (that.selectedInfoVar2Value !== '--') {
      subtitleText += '<br>Variable 2: ' + that.selectedInfoVar2Value;
      subtitleText += '<br><br>Variable 1: ' + that.selectedInfoVar1Value;
    } else if (that.selectedInfoVar1Value !== '--') {
      subtitleText += '<br><br>Variable 1: ' + that.selectedInfoVar1Value;
    }

    const yAxis = {
      gridLineWidth: 1,
      lineColor: 'black',
      lineWidth: 1,
      labels: {
        enabled: true,
      },
      title: {
        text: that.selectedMeasure.units ? `[${that.selectedMeasure.units}]` : that.selectedMeasure.varname,
      },
      tickWidth: 1,
    };

    if (!this.isMeasureNumeric()) {
      yAxis.categories = this.categoriesY;
    }

    // plot options/properties
    const options: any = {
      chart: {
        height: 600,
        ignoreHiddenSeries: false,
        inverted: false,
        panning: true,
        panKey: 'shift',
        renderTo: 'container-ss',
        type: 'scatter',
        zoomType: 'xy',
      },
      title: {
        text: that.selectedMeasure.id ? that.selectedMeasure.id : null,
      },
      subtitle: {
        text: subtitleText,
      },
      xAxis: [
        {
          categories: this.categoriesX0,
          lineWidth: 0,
          labels: {
            enabled: true,
            formatter: function () {
              const label = this.axis.defaultLabelFormatter.call(this);
              return label.substring(label.length - 2, label.length - 1);
            },
            style: {
              fontSize: '16px',
            },
          },
          title: { text: null },
          min: 0,
          max: this.categoriesX0.length - 1,
          visible: true,
          opposite: true,
          gridLineWidth: 0,
        },
        {
          lineColor: 'black',
          lineWidth: 1,
          categories: this.categoriesX1,
          labels: {
            enabled: true,
            rotation: -90,
          },
          min: 0,
          max: this.categoriesX1.length - 1,
          tickWidth: 1,
          tickColor: '#000000',
          tickmarkPlacement: 'between',
          gridLineWidth: 1,
        },
      ],
      yAxis: yAxis,
      tooltip: {
        useHTML: true,
        headerFormat: '<small>{point.key}</small><table>',
        pointFormat: '<tr><td>{point.options.id}: </td>' + '<td style="text-align: right"><b>{point.y}</b></td></tr>',
        footerFormat: '</table>',
        valueDecimals: 2,
      },
      plotOptions: {
        series: {
          turboThreshold: 5000,
          allowPointSelect: true,
          marker: {
            states: {
              select: {
                fillColor: 'yellow',
                lineColor: 'yellow',
              },
            },
          },
          stickyTracking: false,
          point: {
            events: {
              select: function () {
                const selection = that.chart.getSelectedPoints();
                that.onPointSelectionChange(selection);
              },
              unselect: function () {
                const selection = that.chart.getSelectedPoints();
                that.onPointSelectionChange(selection);
              },
            },
          },
          events: {
            legendItemClick: function () {
              if (that.selectedInfoVar1Value !== '--' && that.selectedInfoVar2Value !== '--') {
                const series = this.yAxis.series;
                // legend item's current visiblity
                const isVisible = this.visible;

                if (isVisible) {
                  clickedLegendItems.push(this.name);
                } else {
                  const index = clickedLegendItems.indexOf(this.name);
                  if (index > -1) {
                    clickedLegendItems.splice(index, 1);
                  }
                }

                for (const entry of series) {
                  const nameParts = entry.name.split(':');
                  if (
                    isVisible &&
                    nameParts.length === 2 &&
                    (clickedLegendItems.indexOf(nameParts[0]) > -1 || clickedLegendItems.indexOf(nameParts[1]) > -1)
                  ) {
                    entry.setVisible(false, false);
                  }

                  if (
                    !isVisible &&
                    nameParts.length === 2 &&
                    clickedLegendItems.indexOf(nameParts[0]) === -1 &&
                    clickedLegendItems.indexOf(nameParts[1]) === -1
                  ) {
                    entry.setVisible(true, false);
                  }
                }
              } // end conditional statement
            },
            // the default Highcharts behavior is when hovering on a series in
            // the legend to dim all other series, so the hovered on series is
            // 'highlighted': this behavior is not applicable with two-factor selection
            // due to the way that the legend items are linked with the series;
            // the below block makes possible the hover effect with two factor selection
            afterAnimate: function () {
              const legend = this.chart.legend;
              const series = this.chart.series;

              for (const item of legend.allItems) {
                item.legendItem
                  .on('mouseover', function () {
                    for (const entry of series) {
                      that.setSeriesOpacity(0.2, entry, item.name);
                    }
                  })
                  .on('mouseout', function () {
                    for (const entry of series) {
                      that.setSeriesOpacity(1, entry, item.name);
                    }
                  });
              }
            },
          },
        },
        scatter: {
          jitter: {
            x: 0.4,
            y: this.isMeasureNumeric() ? 0 : 0.4,
          },
        },
      },
      legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'top',
        floating: false,
      },
      series: series,
      credits: {
        enabled: false,
      },
    };

    this.chart = new Highcharts.Chart(options);
  }

  /**
   * selected informational variables, such as 'strain', will have various values:
   * such as 'CAST/EiJ', 'NZO/HlLtJ', and 'C57BL/6J';
   * this function identifies those values and returns the values themesleves
   */
  getInfoVarValues(selectedOptionVal: string): string[] {
    let prop = '';
    const infoVarVals: string[] = [];

    // cases when there are factors
    if (this.selectedMeasure.seriesvarsuffix.length > 0) {
      if (selectedOptionVal === '--') {
        prop = this.selectedSerie;
      } else if (selectedOptionVal !== '--') {
        const index = this.selectedMeasure.seriesvarsuffix.filter((e: string) => this.selectedSerie.endsWith(e));
        prop = `${selectedOptionVal}_${index[0]}`; // ex. bedding_1_8
      }
    } else {
      if (selectedOptionVal !== '--') {
        prop = selectedOptionVal;
      }
    }

    if (selectedOptionVal !== '--') {
      this.plotData.forEach((d) => {
        const val = d[prop] !== null ? d[prop] : 'N/A';
        if (infoVarVals.indexOf(val) < 0) {
          infoVarVals.push(val);
        }
      });
    }
    return infoVarVals;
  }

  /**
   * sets the data into the appropriate Highchart series structures and
   * calculates mean and standard deviation on each (strain, sex) category
   */
  setplotData(): void {
    this.measureSeries = [];

    const infoVar1Vals = this.getInfoVarValues(this.selectedInfoVar1Value);
    const infoVar2Vals = this.getInfoVarValues(this.selectedInfoVar2Value);

    const selectedFactorsIndex = this.selectedMeasure.seriesvarsuffix.filter((suffix: string) => {
      return this.selectedSerie.endsWith(suffix);
    });

    const selectedFactors = selectedFactorsIndex[0] ? `_${selectedFactorsIndex[0]}` : '';

    // setup data series
    if (this.selectedInfoVar1Value && this.selectedInfoVar2Value) {
      for (const i in infoVar1Vals) {
        for (const j in infoVar2Vals) {
          const m = +j + 1; // +j to type cast to numeric
          const index: number = +i % colorCodes.length;
          this.measureSeries.push({
            name: infoVar1Vals[i] + ':' + infoVar2Vals[j],
            xAxis: 0,
            data: [],
            showInLegend: false,
            color: colorCodes[index],
            marker: {
              symbol: symbols[m % symbols.length],
              lineColor: colorCodes[index],
              lineWidth: 1,
            },
          });
        }
      }
    }

    // legend: setup legend entries based on factor2 selection
    if (this.selectedInfoVar2Value !== '--') {
      for (const f in infoVar2Vals) {
        const index = +f + 1;
        this.measureSeries.push({
          name: infoVar2Vals[f],
          color: 'black',
          xAxis: 0,
          marker: {
            symbol: symbols[index % symbols.length],
            lineColor: 'black',
            lineWidth: 1,
          },
          data: [],
        });
      }
    }

    // legend: setup legend entries based on factor1 selection
    if (this.selectedInfoVar1Value !== '--') {
      for (const f in infoVar1Vals) {
        const newLine = +f < 1;
        const index: number = +f % colorCodes.length;
        this.measureSeries.push({
          name: infoVar1Vals[+f],
          color: colorCodes[index],
          xAxis: 0,
          newLine: newLine,
          marker: {
            symbol: symbols[0],
            lineColor: colorCodes[index],
            lineWidth: 1,
          },
          data: [],
        });
      }
    } else {
      // no factor is selected yet, so just have 'Male'/'Female' series,
      // but don't show those as legend labels
      this.measureSeries.push(
        {
          name: 'Female',
          color: 'grey',
          xAxis: 0,
          marker: {
            symbol: 'circle',
          },
          data: [],
          showInLegend: false,
        },
        {
          name: 'Male',
          color: 'grey',
          xAxis: 0,
          marker: {
            symbol: 'circle',
          },
          data: [],
          showInLegend: false,
        },
      );
    }

    this.plotData.forEach((d: any) => {
      const sampleStrainSex = `${d.strain}(${d.sex.toUpperCase()})`;
      let selDataValue: string;
      if (this.selectedInfoVar1Value !== '--') {
        let p = this.selectedInfoVar1Value + selectedFactors; // ex. "bedding" + "_1_3"
        selDataValue = d[p] !== null ? d[p] : 'N/A';
        if (this.selectedInfoVar2Value !== '--') {
          p = `${this.selectedInfoVar2Value}${selectedFactors}`;
          selDataValue = selDataValue + ':' + (d[p] !== null ? d[p] : 'N/A');
        }
      } else {
        selDataValue = d.sex.toUpperCase() === 'F' ? 'Female' : 'Male';
      }

      for (const s of this.measureSeries) {
        if (s.name === selDataValue) {
          s.data.push(d);
          break;
        }
      }

      this.strainIdToDataMap[sampleStrainSex].data.push(d);
    });

    // mean and standard error calculation (when data is available)
    if (this.isMeasureNumeric()) {
      for (const p in this.strainIdToDataMap) {
        if (Object.prototype.hasOwnProperty.call(this.strainIdToDataMap, p)) {
          if (this.strainIdToDataMap[p].data.length > 0) {
            this.strainIdToDataMap[p].meanStdDev = meanStddev(this.strainIdToDataMap[p].data.map((e: any) => e.y));
          }
        }
      }
    }
  }
}
