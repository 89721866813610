// dialog for adding/editing/viewing largecollabs on-the-fly
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'center-dialog',
  template: `
    <div mat-dialog-content style="min-width:800px;">
      <edit-center
        [(collab)]="data.collab"
        [embed]="true"
        (cancel)="dialogRef.close()"
        (collabChange)="dialogRef.close($event)"
      ></edit-center>
    </div>
  `,
})
export class CenterDialogComponent {
  constructor(public dialogRef: MatDialogRef<CenterDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}
}
