<p>
  Select the genotyping platform and the files for the new array below. An array should include a
  <span matTooltip="Must be named 'Sample_Map' (no spaces) and be in txt/zip format"> sample map file </span>
  and a
  <span
    matTooltip="Must follow the naming convention of '[array_name]_FinalReport' (no spaces) and be in txt/zip format"
  >
    final report file </span
  >.
</p>

<form [formGroup]="genotypeArrayForm" class="align-left row">
  <!-- Platform select -->
  <div class="col-sm-10 col-md-3 col-lg-3 align-left">
    <label for="platform">Genotyping Platform</label>
    <select id="platform" class="form-control" formControlName="platform">
      <option [value]="''">Select a platform</option>
      <option value="muga">MUGA</option>
      <option value="megamuga">MegaMUGA</option>
      <option value="gigamuga">GigaMUGA</option>
    </select>
    <div *ngIf="platform?.invalid && (platform?.touched || platform?.dirty)">
      <p *ngIf="platform.hasError('required')" class="error">Please select a genotyping platform</p>
    </div>
  </div>

  <!-- Sample map file input -->
  <div class="col-sm-10 col-md-3 col-lg-4 align-left">
    <label for="sample-map">Sample Map</label>
    <div class="input-group">
      <!-- Shows the placeholder or name of selected file -->
      <input
        id="sample-map-filename"
        class="form-control"
        placeholder="Choose a sample map file"
        formControlName="sampleMap"
        readonly
        (click)="sampleMapFile.click()"
      />

      <!-- Hidden file input (because they're ugly) -->
      <input
        type="file"
        #sampleMapFile
        id="sample-map"
        class="form-control file-select"
        (change)="sampleMap?.setValue(sampleMapFile.files[0].name)"
        (click)="sampleMap?.markAsTouched()"
      />

      <!-- Browse for file button -->
      <span class="input-group-btn">
        <button class="btn btn-default" type="button" (click)="sampleMapFile.click()">Browse</button>
      </span>
    </div>

    <!-- Validation error message (if there is one) -->
    <div *ngIf="sampleMap?.invalid && (sampleMap?.touched || sampleMap?.dirty)">
      <p class="error">{{ getSampleMapErrorMsg() }}</p>
    </div>
  </div>

  <!-- Final report file input -->
  <div class="col-sm-10 col-md-4 col-lg-4 align-left">
    <label for="final-report">Final Report</label>
    <div class="input-group">
      <!-- Shows the placeholder or name of selected file -->
      <input
        id="final-report-filename"
        class="form-control"
        placeholder="Choose a final report file"
        formControlName="finalReport"
        readonly
        (click)="finalReportFile.click()"
      />

      <!-- Hidden file input (because they're ugly) -->
      <input
        type="file"
        #finalReportFile
        id="final-report"
        class="form-control file-select"
        (change)="finalReport?.setValue(finalReportFile.files[0].name)"
        (click)="finalReport?.markAsTouched()"
      />

      <!-- Browse for file button -->
      <span class="input-group-btn">
        <button class="btn btn-default" type="button" (click)="finalReportFile.click()">Browse</button>
      </span>
    </div>

    <!-- Validation error message (if there is one) -->
    <div *ngIf="finalReport?.invalid && (finalReport?.touched || finalReport?.dirty)">
      <p class="error">{{ getFinalReportErrorMsg() }}</p>
    </div>
  </div>

  <!-- Buttons -->
  <div id="button-container" class="col-sm-2 col-lg-1">
    <!-- Upload button -->
    <button
      *ngIf="!uploadStatus"
      mat-icon-button
      id="upload"
      aria-label="Upload"
      matTooltip="Upload genotype array"
      color="accent"
      [disabled]="genotypeArrayForm.invalid"
      (click)="uploadFiles(sampleMapFile, finalReportFile)"
    >
      <mat-icon>cloud_upload</mat-icon>
    </button>

    <!-- Uploading indicator -->
    <mat-spinner *ngIf="uploadStatus === 'processing'" diameter="20"></mat-spinner>

    <!-- Successful upload icon -->
    <button
      *ngIf="uploadStatus === 'done'"
      mat-icon-button
      id="success"
      aria-label="Upload success"
      matTooltip="Upload finished successfully!"
    >
      <mat-icon style="color: #5cb85c">cloud_done</mat-icon>
    </button>

    <!-- Retry upload button -->
    <button
      *ngIf="uploadStatus === 'error'"
      mat-icon-button
      id="upload-error"
      aria-label="Retry"
      matTooltip="Retry upload"
      color="warn"
      (click)="uploadFiles(sampleMapFile, finalReportFile)"
    >
      <mat-icon>replay</mat-icon>
    </button>

    <!-- Remove array button (before it's been saved) -->
    <button
      *ngIf="!uploadStatus"
      mat-icon-button
      id="remove"
      aria-label="Remove"
      matTooltip="Remove genotype array"
      (click)="remove.emit()"
    >
      <mat-icon>delete_outline</mat-icon>
    </button>
  </div>

  <!-- HTTP error message if there is one -->
  <div *ngIf="uploadError" class="col-lg-12 align-center top-margin-1">
    <mat-icon color="warn">warning</mat-icon>
    <p class="error upload-error">{{ uploadError }}</p>
  </div>
</form>
