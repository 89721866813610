<div *ngIf="!isCurator && !embed" class="row">
  <div class="col-sm-12">
    <div class="form-horizontal">
      <div>
        <br />
        <h4>You do not have access to this page.</h4>
      </div>
    </div>
  </div>
</div>

<div [style.display]="isCurator || embed ? 'block' : 'none'">
  <mat-toolbar *ngIf="!embed">
    <h2>Edit Institutions Information</h2>
  </mat-toolbar>
  <div class="row">
    <div class="col-sm-12">
      <div *ngIf="!embed">
        &nbsp;&nbsp;&nbsp;
        <b><a class="text9" routerLink="/">Home</a></b>
        <span class="glyphicon glyphicon-chevron-right text9"></span>
        <b><a class="text9" routerLink="/tasks">Tasks</a></b>
        <span class="glyphicon glyphicon-chevron-right text9"></span>
        <b><span class="text9">Edit Institutions Information</span></b>

        <br />
        <br />
        <br />
      </div>
      <div class="form-horizontal">
        <div [style.display]="embed ? 'none' : 'block'" class="form-group">
          <label class="col-sm-3 control-label">Search Institutions</label>
          <div class="col-sm-6">
            <institution-search
              [(institution)]="institution"
              (institutionChange)="onInstitutionChange()"
              [showDialog]="false"
            >
            </institution-search>
          </div>
        </div>

        <br />
        <div class="text-center">
          <span class="text16">
            <b>
              <span *ngIf="newInstitution">Create New</span>
              <span *ngIf="!newInstitution && canEdit()">Edit</span>
              <span *ngIf="!canEdit()">View</span>
              Institution
            </b>
          </span>
        </div>
        <br />
        <fieldset [disabled]="!canEdit()">
          <div class="form-group has-feedback">
            <label for="inst-name" class="col-sm-3 control-label required" required>Name</label>
            <div class="col-md-6">
              <input
                type="text"
                id="inst-name"
                name="inst-name"
                maxlength="255"
                class="form-control"
                [disabled]="!newInstitution"
                [(ngModel)]="institution.name"
                (keyup)="validateInstitutionName()"
              />
              <span *ngIf="newInstitution && institution.name" class="form-control-feedback">
                <span class="glyphicon glyphicon-ok green" *ngIf="!nameTaken"></span>
                <span class="glyphicon glyphicon-warning-sign red" *ngIf="nameTaken"></span>
              </span>
              <span class="red">
                <small *ngIf="nameTaken">
                  This <b>Name</b> is unavailable! It has already been used for another institution.
                </small>
              </span>
            </div>
          </div>

          <div class="form-group">
            <label for="inst-preword" class="col-sm-3 control-label">Preword</label>
            <div class="col-md-3">
              <input
                id="inst-preword"
                name="inst-preword"
                class="form-control"
                [(ngModel)]="institution.preword"
                maxlength="20"
              />
            </div>
          </div>

          <div class="form-group">
            <label for="inst-city" class="col-sm-3 control-label required">City</label>
            <div class="col-md-6">
              <input
                id="inst-city"
                name="inst-city"
                class="form-control"
                [(ngModel)]="institution.city_no_state"
                maxlength="39"
              />
            </div>
          </div>

          <div class="form-group">
            <label for="inst-state" class="col-sm-3 control-label"> State </label>
            <div class="col-md-6">
              <input
                id="inst-state"
                name="inst-state"
                class="form-control"
                [(ngModel)]="institution.state"
                maxlength="39"
              />
            </div>
          </div>

          <div class="form-group">
            <label for="inst-country" class="col-sm-3 control-label required"> Country </label>
            <div class="col-md-6">
              <input
                id="inst-country"
                name="inst-country"
                class="form-control"
                [(ngModel)]="institution.country"
                maxlength="80"
              />
            </div>
          </div>

          <div class="form-group">
            <label for="inst-abbrev" class="col-sm-3 control-label"> Abbreviation </label>
            <div class="col-md-6">
              <input
                id="inst-abbrev"
                name="inst-abbrev"
                class="form-control"
                [(ngModel)]="institution.abbrev"
                maxlength="40"
              />
            </div>
          </div>
          <br />
        </fieldset>

        <!-- information about people associated with this institution
             (don't show to regular users for now, since this display is kind of ugly...) -->
        <div *ngIf="!newInstitution && isCurator">
          <div class="text-center">
            <span class="text10"
              ><b>{{ institution.peoplecount }} people are associated with this institution</b></span
            >
            [
            <a *ngIf="!showPeople && institution.peoplecount > 0" (click)="showPeople = true">show</a>
            <a *ngIf="showPeople" (click)="showPeople = false">hide</a>]
          </div>
          <div *ngIf="showPeople">
            <br />
            <p>{{ displayPeople() }}</p>
          </div>
        </div>
        <!-- END information about people associated with this institution section -->
        <br />

        <!-- save and delete buttons section -->
        <div class="form-group">
          <div [class]="canEdit() ? (embed || !newInstitution ? 'col-sm-3' : 'col-sm-4') : 'col-sm-4'"></div>
          <div *ngIf="canEdit()">
            <div
              [class]="newInstitution ? (embed ? 'col-sm-3' : 'col-sm-4') : embed ? 'col-sm-2' : 'col-sm-3'"
              [matTooltip]="!institution.name ? missingInfoTooltip : ''"
            >
              <button class="btn btn-success btn-block" [disabled]="saveDisabled" (click)="saveInstitutionRecord()">
                <strong>Save</strong>
              </button>
            </div>
            <div
              *ngIf="!newInstitution"
              [class]="embed ? 'col-sm-2' : 'col-sm-3'"
              [matTooltip]="!canDelete() ? 'Cannot delete institution with associated people' : ''"
            >
              <button class="btn btn-danger btn-block" [disabled]="!canDelete()" (click)="deleteInstitutionRecord()">
                <strong>Delete</strong>
              </button>
            </div>
          </div>
          <div [class]="canEdit() ? (newInstitution ? 'col-sm-3' : 'col-sm-2') : 'col-sm-4'" *ngIf="embed">
            <button class="btn btn-default btn-block" (click)="cancel.emit(true)">
              <strong>{{ canEdit() ? 'Cancel' : 'Close' }}</strong>
            </button>
          </div>
        </div>
        <!-- END save and delete buttons section -->
      </div>
    </div>
  </div>
</div>
