import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Validates that the extension of an filename is one of the accepted types (e.g.
 * 'txt', 'zip', 'csv', 'tsv', 'html', etc). If the extension is valid, the
 * function will emit null, indicating that no errors were found. If the extension
 * is invalid, a key/value pair will be emitted indicating that the value has
 * failed the file type validation
 * @param {string[]} validTypes - a list of valid extensions a file can have
 */
export function FileTypeValidator(validTypes: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (validTypes.length) {
      const ext = control.value.split('.').pop();
      const hasValidExt = validTypes.indexOf(ext) > -1;
      return hasValidExt ? null : { invalidType: { value: control.value } };
    }

    return null;
  };
}
