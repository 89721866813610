import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable()
export class MetadataService {
  // behavioursubject for building an observable
  public types = new BehaviorSubject<any[]>([]);

  // api url
  private api: string = environment.securedURLs.sip;

  // declare http client
  constructor(private http: HttpClient) {}

  /**
   * Get a metadata object by type and id
   *
   * @param {string} type: type of object to fetch
   * @param {number} id: accession id of object to fetch
   * @returns {Observable<any>} Observable
   */
  getObjectDetails(type: string = '', id: string = ''): Observable<any> {
    let url = `${this.api}metadata`;
    if (type && id) {
      url += `?object_type=${type}&object_id=${id}`;
    }

    return this.http.get<any>(url).pipe(
      tap((result) => {
        if (id) {
          this.types.next(result);
        }
      }),
    );
  }
}
