import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

// dialog for adding/editing/viewing institutions on-the-fly
@Component({
  selector: 'institution-dialog',
  template: `
    <div mat-dialog-content style="min-width:800px;">
      <edit-institution
        [(institution)]="data.institution"
        [embed]="true"
        (cancel)="dialogRef.close()"
        (institutionChange)="dialogRef.close($event)"
      ></edit-institution>
    </div>
  `,
})
export class InstitutionDialogComponent {
  constructor(public dialogRef: MatDialogRef<InstitutionDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}
}
