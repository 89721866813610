import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// dialog for confirming an action (overloaded to allow of giving the user a message)
@Component({
  selector: 'confirmation-dialog',
  template: `
    <h1 mat-dialog-title>{{ data.header ? data.header : 'Are you sure?' }}</h1>
    <div mat-dialog-content>
      <p [innerHtml]="(data.message ? data.message : '') | SafeHTML"></p>
      <br />
    </div>
    <div class="form-group" mat-dialog-actions>
      <button
        *ngIf="!data.hidefalsebtn"
        [class]="'col-xs-5 btn ' + (data.falsebtn ? data.falsebtn : 'btn-default')"
        mat-dialog-close
      >
        {{ data.falselabel ? data.falselabel : 'No' }}
      </button>
      <div [class]="data.hidefalsebtn ? 'col-xs-3' : 'col-xs-2'"></div>
      <button
        [class]="
          (data.hidefalsebtn ? 'col-xs-6' : 'col-xs-5') + ' btn ' + (data.truebtn ? data.truebtn : 'btn-default')
        "
        [mat-dialog-close]="true"
      >
        {{ data.truelabel ? data.truelabel : 'Yes' }}
      </button>
    </div>
  `,
})
export class ConfirmationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData,
  ) {}
}

export interface ConfirmationDialogData {
  header: string;
  message: string;
  hidefalsebtn: boolean; // TODO: camelCase this
  falsebtn: string; // TODO: camelCase this
  falselabel: string; // TODO: camelCase this
  truebtn: string; // TODO: camelCase this
  truelabel: string; // TODO: camelCase this
}
