import { Component, OnInit, ViewChild } from '@angular/core';
import { GroupService } from '../../services/group.service';
import { Router } from '@angular/router';
import { groupMembersTooltip } from '../../shared/utils';
import { MatDialog } from '@angular/material/dialog';
import { PersonService } from '../../services/person.service';
import { GroupSearchComponent } from '../../shared/entity-searching/group-search.component';
import { ConfirmationDialogComponent } from '../../shared/dialogs/confirmation-dialog.component';

@Component({
  selector: 'group-listing',
  templateUrl: './group-listing.component.html',
})
export class GroupListingComponent implements OnInit {
  // make this utils function usable in the html
  groupMembersTooltip = groupMembersTooltip;
  // list of groups to which the current user belongs
  groups: any[] = [];
  // name used when creating a new group
  newName = '';
  // error message below new group name
  message = '';

  // true if the current user is a curator, false if they are not
  isCurator = false;

  // groupSearch object selected by the curator for the group-search
  groupSearch: any = {};

  // used to determine whether there is a current user, and whether they can create groups based
  // on whether they are restricted (not on the whitelist), or their email isn't verified
  userUnrestricted = false;

  // need to be able to clear the selection in the group-search component
  @ViewChild(GroupSearchComponent) groupLookup!: GroupSearchComponent;

  constructor(
    private groupService: GroupService,
    public dialog: MatDialog,
    public router: Router,
    private personService: PersonService,
  ) {}

  // oninit, get the list of groups for the current user and determine whether the current user is a curator
  ngOnInit() {
    this.refreshGroupList();

    this.personService.currentUser$.subscribe(() => {
      this.userUnrestricted = this.personService.userUnrestricted();
      this.isCurator = this.personService.isCurator();
    });
  }

  // get the group list for the current user
  refreshGroupList() {
    this.groupService.getGroup('', 'current').subscribe((data) => {
      this.groups = data;
    });
  }

  // create a new group based with the name from newName. The current user will be automatically added as an admin and
  // the view/edit group page will be opened
  createNewGroup() {
    if (this.newName) {
      this.groupService.saveGroup({ name: this.newName }).subscribe(
        (data) => {
          if (data.id) {
            this.router.navigate(['/group/' + String(data.id)]);
          }
        },
        (err) => (this.message = err.error ? err.error.message : 'Unknown error occurred creating group'),
      );
    } else {
      this.message = 'Please enter a name for your new group before creating it.';
    }
  }

  /**
   * Determines if the current user can edit the group
   *
   * @param {any} group: group dict
   * @returns {boolean | any} true if the current user can edit the group, false if not
   */
  canEditGroup(group: any) {
    return this.userUnrestricted && group.current_user_role;
  }

  /**
   * Determines if the current user can delete the group
   *
   * @param {any} group: group dict
   * @returns {boolean | any} true if the current user can delete the group, false if not
   */
  canDeleteGroup(group: any) {
    return this.userUnrestricted && group.current_user_role === 'Admin';
  }

  /**
   * Deletes a group (only an Admin for the group can do this)
   *
   * @param {number} id: id of the group to delete
   * @param {string} name: name of the group to delete
   * @param {boolean} clearLookup: true to clear the group-search on Delete (if the selected group is deleted)
   */
  deleteGroup(id: number, name: string, clearLookup: boolean = false) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        header: 'Confirm Delete Group',
        message:
          'Are you sure that you wish to delete the group, "' +
          name +
          '"?' +
          '<br>You may lose access to any projects that have been shared with this group.',
        falselabel: 'Cancel',
        truelabel: 'Delete',
        truebtn: 'btn-danger',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.groupService.deleteGroup(id).subscribe(() => {
          this.refreshGroupList();
          if (clearLookup) {
            this.groupLookup.clearSelection();
          }
        });
      }
    });
  }
}
