<div class="row">
  <div [id]="'table-div-' + id" class="col-xs-12 col-sm-12 col-md-12 table-editable text-center">
    <table [id]="'table-' + id" class="table table-condensed table-striped no-border" cellpadding="2" border="1">
      <thead>
        <tr *ngIf="title || (allowChanges && allowAdd)" class="dark-cerulean no-border">
          <th colspan="30" class="no-border">
            <div class="form-horizontal">
              <div class="form-group" style="margin-bottom: 0">
                <div class="col-xs-10">
                  <h4 style="margin: 0">{{ title }}</h4>
                </div>
                <div *ngIf="allowChanges && allowAdd" align="right" style="vertical-align: bottom" class="col-xs-2">
                  <span class="table-add noselect" matTooltip="Add new row" (click)="rows.push({}); emitRows()">
                    <span class="glyphicon glyphicon-plus"></span>
                    <span style="font-size: 12px">Add Row</span>
                  </span>
                </div>
              </div>
            </div>
          </th>
        </tr>
        <tr class="bg-dark-cerulean white">
          <th class="width5-percent"></th>
          <th style="vertical-align: middle" *ngFor="let col of columns" [class]="col.class + ' text-center'">
            {{ col.name }}
          </th>
          <th *ngIf="allowChanges && allowRemove" class="width5-percent"></th>
          <th *ngIf="allowChanges" class="width5-percent"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of rows; let i = index" [class]="rowSelected === i ? 'row-highlight' : ''">
          <td
            style="cursor: pointer; vertical-align: middle"
            [matTooltip]="(rowSelected === i ? 'De-s' : 'S') + 'elect this row'"
            (click)="rowSelected = rowSelected === i ? null : i"
          >
            <span [style.position]="rowSelected === i ? 'absolute' : 'relative'">{{ i + 1 }}</span>
            <span *ngIf="i === rowSelected" style="color: green; padding: 5px" class="glyphicon glyphicon-ok"></span>
          </td>
          <td *ngFor="let col of columns">
            <textarea
              rows="1"
              class="form-control table-input"
              (input)="fixRowHeight(i)"
              (focus)="rowSelected = i"
              [disabled]="col.disabled"
              [(ngModel)]="rows[i][col.key]"
              (change)="emitRows()"
              [maxlength]="col.maxlength ? col.maxlength : -1"
            ></textarea>
          </td>
          <td *ngIf="allowChanges && allowRemove" style="vertical-align: middle; padding: 5px">
            <span matTooltip="Remove row" (click)="deleteRow(i)" class="table-remove glyphicon glyphicon-remove"></span>
          </td>
          <td *ngIf="allowChanges" style="white-space: nowrap; vertical-align: middle; padding: 5px">
            <span matTooltip="Move row up" (click)="moveRow(i)" class="table-up glyphicon glyphicon-arrow-up"></span>
            <span
              matTooltip="Move row down"
              (click)="moveRow(i, true)"
              class="table-down glyphicon glyphicon-arrow-down"
            ></span>
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <div class="form-horizontal" *ngIf="(rowSelected || rowSelected === 0) && fields.length > 0">
      <div class="form-group">
        <div class="col-xs-1">
          <mat-icon>subdirectory_arrow_right</mat-icon>
        </div>
        <div class="col-xs-10">
          <div class="panel panel-primary">
            <div align="left" class="panel-heading">&nbsp;&nbsp;Row {{ rowSelected + 1 }} Additional Information</div>
            <div class="panel-body">
              <div class="form-horizontal">
                <div class="form-group" *ngFor="let field of fields">
                  <label class="col-sm-3 control-label">
                    {{ field.label }}
                    <span
                      *ngIf="field.tooltip"
                      [matTooltip]="field.tooltip"
                      style="font-size: 14px"
                      class="glyphicon glyphicon-question-sign"
                    ></span>
                  </label>
                  <div class="col-sm-8">
                    <textarea
                      rows="3"
                      class="form-control"
                      [(ngModel)]="rows[rowSelected][field.key]"
                      (change)="emitRows()"
                      [maxlength]="field.maxlength ? field.maxlength : -1"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
