import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../../shared/dialogs/confirmation-dialog.component';
import { DatasetStatus } from '../dataset-details/dataset-status';

@Component({
  selector: 'dataset-processing',
  templateUrl: './dataset-processing.component.html',
  styleUrls: ['./dataset-processing.component.scss'],
})
export class DatasetProcessingComponent {
  // true if the current user is allowed to make edits
  @Input() editable!: boolean;

  // current dataset status
  @Input() status!: DatasetStatus;

  // number of inventory animals selected
  @Input() numSelections!: number;

  // error message relating to an issue with the inventory submission
  submissionError = '';

  // emits when the user submits the dataset for processing
  @Output() process: EventEmitter<null> = new EventEmitter<null>();

  // emits when the user wants to run the HR pipeline
  @Output() runHaplotypeReconstruction: EventEmitter<null> = new EventEmitter<null>();

  constructor(private dialog: MatDialog) {}

  /**
   * Returns true if all the following are true:
   * 1) the current user has permission to make edits
   * 2) the dataset isn't currently processing
   * 3) the dataset has successfully processed
   * 4) haplotype reconstruction isn't running (aka the haplotype status color isn't blue)
   */
  get canRunHaplotypeReconstruction(): boolean {
    return (
      this.editable &&
      !this.status.isProcessing &&
      this.status.statusDisplay === 'COMPLETED' &&
      this.status.haplotypeStatusColor !== '#0277bd'
    );
  }

  /**
   * Returns true if all the following are true:
   * 1) inventory selections have been made and saved
   * 2) the current user has permission to make edits
   * 3) the dataset isn't currently processing
   * 4) haplotype reconstruction isn't running (aka the haplotype status color isn't blue)
   */
  get canProcess(): boolean {
    return Boolean(
      this.numSelections &&
        this.editable &&
        !this.status.isProcessing &&
        this.status.haplotypeStatusColor !== '#0277bd',
    );
  }

  /**
   * Shows the submission confirmation dialog box ensuring that the user knows that by pressing
   * the "submit" button, they won't be able to make changes to the dataset until all the
   * processing has completed (which either means finished successfully or failed). If the user
   * clicks submit, the dialog emits true and the inventory is submitted for processing
   */
  getConfirmation(): void {
    const selections = `${this.numSelections} ${this.numSelections === 1 ? 'sample has' : 'samples have'}`;
    const data = {
      header: 'Confirm Inventory Submission',
      message:
        'Currently, ' +
        selections +
        ' been selected to be included in the dataset. ' +
        'By clicking the button below you will submit these as the official set of samples for ' +
        'the genotype data, the genotype array data will go through preprocessing, be loaded into the ' +
        'Diversity Database. Once finished, you will be able to download genotypes from Diversity DB.' +
        '<br><br>' +
        'You will be unable to make changes to this dataset while the data is processing so please ' +
        'ensure that your selections are correct before submitting.',
      hidefalsebtn: false,
      truelabel: 'Submit',
      falselabel: 'Cancel',
      truebtn: 'btn-success',
    };
    const confirmDialog = this.dialog.open(ConfirmationDialogComponent, { data });
    confirmDialog.afterClosed().subscribe((confirm) => {
      if (confirm === true) {
        this.process.emit();
      }
    });
  }

  /**
   * Returns tooltip content for the processing button if it is disabled for any reason. The order of
   * the string determination conditions is from the most broad to the most specific. For example, if
   * the user can't edit the dataset, it's not helpful to tell them that they can't submit due to no
   * saved inventory selections
   */
  processButtonTooltip(): string {
    if (!this.editable) {
      return 'You do not have the ability to process this dataset as you are not allowed to edit it.';
    } else if (this.status.isProcessing) {
      return (
        'This dataset is already being processed. If you wish to make edits and reprocess, ' +
        'please cancel processing or wait until the current processing has finished.'
      );
    } else if (this.status.haplotypeStatusColor === '#0277bd') {
      return (
        'This dataset is currently running a haplotype reconstruction pipeline and cannot be ' +
        'reprocessed at this time. Please wait for the pipeline to finish or you can cancel the pipeline run.'
      );
    } else if (!this.numSelections) {
      return (
        'This dataset cannot be submitted for processing prior to selecting the samples that ' +
        'should be included in it and saving those selections (step 3). Please select the desired samples ' +
        'from the previous step.'
      );
    } else {
      return '';
    }
  }

  /**
   * Returns tooltip content for the haplotype reconstruction button if it is disabled for any reason
   */
  runHaplotypeReconstructionTooltip(): string {
    if (!this.editable) {
      return (
        'You do not have the ability to run the haplotype reconstruction for this dataset as ' +
        'you are not allowed to edit it.'
      );
    } else if (this.status.isProcessing) {
      return (
        'This dataset is currently being processed. If you wish to run the haplotype reconstruction pipeline, ' +
        'processing must first finish successfully.'
      );
    } else if (this.status.statusDisplay !== 'COMPLETED') {
      return (
        'The haplotype reconstruction pipeline cannot be run on this datset because it has not been ' +
        'successfully processed.'
      );
    } else if (this.status.haplotypeStatusColor === '#0277bd') {
      return (
        'This dataset is already running the haplotype reconstruction pipeline.' +
        'Please wait for the pipeline to finish or you can cancel the pipeline run.'
      );
    } else {
      return '';
    }
  }
}
