// Angular imports
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Guards
import { AuthGuard } from './auth.guard';

// Components
import { AdminComponent } from './components/admin/admin.component';
import { AnimalInfoComponent } from './components/animal-info/animal-info.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DataDefinitionsComponent } from './components/data-definitions/data-definitions.component';
import { EditCenterComponent } from './shared/entity-editing/edit-center/edit-center.component';
import { EditInstitutionComponent } from './shared/entity-editing/edit-institution/edit-institution.component';
import { EditInterventionComponent } from './shared/entity-editing/edit-intervention/edit-intervention.component';
import { EditInvestigatorComponent } from './shared/entity-editing/edit-investigator/edit-investigator.component';
import { EditMethodologyComponent } from './shared/entity-editing/edit-methodology/edit-methodology.component';
import { EditPanelComponent } from './shared/entity-editing/edit-panel/edit-panel.component';
import { EditProcedureComponent } from './shared/entity-editing/edit-procedure/edit-procedure.component';
import { EditPublicationComponent } from './shared/entity-editing/edit-publication/edit-publication.component';
import { EditStrainComponent } from './shared/entity-editing/edit-strain/edit-strain.component';
import { GenotypeIntakeComponent } from './components/genotype-intake/genotype-intake.component';
import { GroupComponent } from './components/group/group.component';
import { GroupListingComponent } from './components/grouplisting/group-listing.component';
import { HelpComponent } from './components/help/help.component';
import { ImportProjectComponent } from './components/import-project/import-project.component';
import { ProceduresComponent } from './components/procedures/procedures.component';
import { ProjectCurationComponent } from './components/project-curation/project-curation.component';
import { ProjectDetailsComponent } from './components/project-details/project-details.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { QCDashboardComponent } from './components/data-validation/dashboard.component';
import { QCGeneralMessagesComponent } from './components/data-validation/general/qc-general.component';
import { QCStatisticalComponent } from './components/data-validation/statistical/qc-statistical.component';
import { QCTechnicalComponent } from './components/data-validation/technical/qc-technical.component';
import { RestrictedAccessComponent } from './shared/restricted-access.component';
import { SettingsComponent } from './components/settings/settings.component';
import { ShareProjectComponent } from './components/share-project/share-project.component';
import { TasksComponent } from './components/tasks/tasks.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'animal-info/:id', component: AnimalInfoComponent },
  { path: 'appsettings', component: AdminComponent, canActivate: [AuthGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'definitions/:id', component: DataDefinitionsComponent },
  { path: 'help', component: HelpComponent },
  { path: 'genotypes/:id', component: GenotypeIntakeComponent, canActivate: [AuthGuard] },
  { path: 'groups', component: GroupListingComponent, canActivate: [AuthGuard] },
  { path: 'group/:id', component: GroupComponent },
  { path: 'edit-institutions', component: EditInstitutionComponent, canActivate: [AuthGuard] },
  { path: 'edit-interventions', component: EditInterventionComponent, canActivate: [AuthGuard] },
  { path: 'edit-investigators', component: EditInvestigatorComponent, canActivate: [AuthGuard] },
  { path: 'edit-centers', component: EditCenterComponent, canActivate: [AuthGuard] },
  { path: 'edit-methodologies', component: EditMethodologyComponent, canActivate: [AuthGuard] },
  { path: 'edit-procedure-templates', component: EditProcedureComponent, canActivate: [AuthGuard] },
  { path: 'edit-strain', component: EditStrainComponent, canActivate: [AuthGuard] },
  { path: 'project/import', component: ImportProjectComponent, canActivate: [AuthGuard] },
  { path: 'panels', component: EditPanelComponent, canActivate: [AuthGuard] },
  { path: 'procedures/:id', component: ProceduresComponent },
  { path: 'projects', component: ProjectsComponent },
  { path: 'proj-details', component: ProjectDetailsComponent, canActivate: [AuthGuard] },
  { path: 'proj-details/:id', component: ProjectDetailsComponent },
  { path: 'publications', component: EditPublicationComponent, canActivate: [AuthGuard] },
  { path: 'user-profile', component: UserProfileComponent, canActivate: [AuthGuard] },
  { path: 'tasks', component: TasksComponent, canActivate: [AuthGuard] },
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] },
  { path: 'welcome', component: RestrictedAccessComponent },
  { path: 'restricted', component: RestrictedAccessComponent },
  { path: 'verifyemail', component: RestrictedAccessComponent },
  { path: 'share-project/:id', component: ShareProjectComponent },
  { path: 'curator-only/:id', component: ProjectCurationComponent },
  { path: 'project/qc-dashboard/:id', component: QCDashboardComponent },
  { path: 'project/data-information/details/:id', component: QCGeneralMessagesComponent },
  { path: 'project/technical-qc/details/:id', component: QCTechnicalComponent },
  { path: 'project/statistical-qc/details/:id', component: QCStatisticalComponent },
  { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
