import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PanelSearchComponent } from '../../entity-searching/panel-search.component';
import { PersonService } from '../../../services/person.service';
import { PanelService } from '../../../services/panel.service';
import { flashMessage } from '../../utils';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog.component';

@Component({
  selector: 'edit-panel',
  templateUrl: './edit-panel.component.html',
  styleUrls: ['../editing.scss'],
})
export class EditPanelComponent implements OnInit {
  // true to embed this component, false to not
  @Input() embed = false;

  // panel object we're creating/modifying
  @Input() panel: any = {};

  // emit the change when a panel is saved or deleted
  @Output() panelChange: EventEmitter<any> = new EventEmitter<any>();

  // event for cancel button click (only displayed on embed)
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();

  // true if we're creating a new panel, false if we're editing an existing one
  newPanel = true;
  // true if the current user is a curator, false if they are not
  isCurator = false;

  // need to be able to clear the selection in the panel-search component
  @ViewChild(PanelSearchComponent) panelSearchComponent!: PanelSearchComponent;

  // true to show the extra details about strains/projects, false to not
  showStrains = false;
  showProjects = false;

  // flags as to whether the current values in panelsym and name are already taken by another panel
  panelsymTaken = false;
  // populated with the panelsym of the panel that has the name
  nameTakenBy = '';

  // constructors
  constructor(
    private panelService: PanelService,
    private personService: PersonService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {}

  // determine if the current user is a curator
  ngOnInit() {
    if (this.panel.panelsym) {
      this.panelService.getPanel(this.panel.panelsym).subscribe((data) => {
        this.panel = data;
        this.newPanel = false;
        // don't emit here! this is the initial retreive for when this component is embedded
      });
    }
    this.personService.currentUser$.subscribe(() => {
      this.isCurator = this.personService.isCurator();
    });
  }

  // save the panel if the name and panelsym are populated and available
  savePanel() {
    if (this.panel.panelsym && this.panel.name && !this.nameTakenBy && !this.panelsymTaken) {
      this.panelService.savePanel(this.panel).subscribe(
        (result) => {
          this.panel = result;
          this.panelChange.emit(this.panel);
          this.newPanel = false;
          flashMessage(this.snackBar, 'Save successful!', 'alert-success', 2000);
        },
        () => {
          flashMessage(this.snackBar, 'Save failed.', 'alert-danger', 5000);
        },
      );
    }
  }

  // on change of the name and panelsym, check the cached
  checkPanelsymAndName() {
    this.panelsymTaken = false;
    this.nameTakenBy = '';
    const cachedPanels = this.panelService.panels.getValue();
    for (let i = 0; i < cachedPanels.length; i++) {
      if (this.panel.panelsym && cachedPanels[i].panelsym === this.panel.panelsym) {
        if (this.newPanel) {
          this.panelsymTaken = true;
        } else if (this.panel.name && this.panel.name === cachedPanels[i].name) {
          // if we're modifying then we know panelsym is valid and if the name hasn't changed then it's valid
          break;
        }
      }
      if (this.panel.name && this.panel.name === cachedPanels[i].name) {
        this.nameTakenBy = cachedPanels[i].panelsym;
        // time saver, if we're modifying then we already know the projsym is valid
        if (!this.newPanel) {
          break;
        }
      }
    }
  }

  // delete this panel
  deletePanel() {
    if (!this.newPanel) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          header: 'Confirm Delete Panel',
          message:
            'Are you sure that you wish to delete this panel?' +
            (this.panel.straincount > 0 || this.panel.projcount > 0
              ? '<br>This panel is associated with one or more strains or projects, ' +
                '<br>so deleting it will result in it being removed from those strains and projects.'
              : ''),
          falselabel: 'Cancel',
          truelabel: 'Delete',
          truebtn: 'btn-danger',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.panelService.deletePanel(this.panel.panelsym).subscribe(() => {
            this.panelSearchComponent.clearSelection();
            this.panelChange.emit(this.panel);
          });
        }
      });
    }
  }

  // on change of the panel, update various flags and get extra details about projects and
  // strains if a panel was selected
  onPanelChange() {
    this.showProjects = false;
    this.showStrains = false;
    this.panelsymTaken = false;
    this.nameTakenBy = '';
    if (this.panel.panelsym) {
      // get information about this individual panel to get the extra details about strains and projects
      this.panelService.getPanel(this.panel.panelsym).subscribe((data) => {
        this.panel = data;
        this.panelChange.emit(this.panel);
        this.newPanel = false;
      });
    } else {
      this.newPanel = true;
    }
  }

  /**
   * Figure out if the current user is allowed to edit the panel
   *
   * @returns {any}: true if the current user can edit the panel, false if not
   */
  canEdit(): boolean {
    // curators can edit anything
    if (this.isCurator) {
      return true;
    }
    // this is a new panel, so can edit
    if (this.newPanel) {
      return true;
    }
    // creator can edit until a curator approves
    return this.personService.isCurrentUser(this.panel.user_creator.id);
  }

  /**
   * Figure out if the current user is allowed to delete the panel
   *
   * @returns {any}: true if the current user can delete the panel, false if not
   */
  canDelete(): boolean {
    // can't delete something that doesn't exist
    if (this.newPanel) {
      return false;
    }
    // can't delete if can't edit
    if (!this.canEdit()) {
      return false;
    }
    // if there are any associated projects, then can't delete
    if (this.panel.projcount > 1) {
      return false;
    }
    // can only delete if there are also no associated strains
    return this.panel.straincount === 0;
  }

  // simple comma-separated json-type view of the strains associated with the current panel
  strainDisplay() {
    const strainArray = [];
    for (const strain of this.panel.strains) {
      strainArray.push('{id: ' + strain.id + ', aname: ' + strain.aname + '}');
    }
    return strainArray.join(', ');
  }

  // simple comma-separated json-type view of the panels associated with the current panel
  projectDisplay() {
    const projectArray = [];
    for (const project of this.panel.projects) {
      projectArray.push(
        '{projid: ' + project.projid + ', projsym: ' + project.projsym + ', title: ' + project.title + '}',
      );
    }
    return projectArray.join(', ');
  }
}
