import { SelectionModel } from '@angular/cdk/collections';
import { Component, ViewChild, Input, OnChanges, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements AfterViewInit, OnChanges {
  // array of rows received from the parent
  @Input() rows!: any[];

  // array of columns received from the parent
  @Input() columns!: any[];

  // default value of the pagesize
  @Input() defPageSize = 10;

  // available formats for downloading table data: "xlsx", "csv", "txt", "json"
  @Input() exportFormats?: string[];

  // true to enable row selection (along with rowIdKey)
  @Input() rowSelect = false;

  // true (along with rowSelect and rowIdKey) to allow multiple rows to be selected
  @Input() rowMultiSelect = false;

  // unique id in a row that can be used to identify the row
  @Input() rowIdKey = '';

  // optional table title
  @Input() title = '';

  // used for specialized content
  @Input() objType = '';

  // event that is emitted when something happens that needs to trigger a refresh of the table on to the parent
  @Output() triggerTableRefresh: EventEmitter<any> = new EventEmitter<any>();

  // event for letting the parent know when a click event occurred,
  // emits {'event': column.click string, 'row': row object}
  @Output() actionClick: EventEmitter<any> = new EventEmitter<any>();

  // when a row is selected, the event is emitted
  @Output() rowSelected: EventEmitter<any> = new EventEmitter<any>();

  // array of the displayed column names
  displayedColumns: string[] = [];

  // used for keeping track ot table rows
  dataSource = new MatTableDataSource();

  // keeps track of which rows are selected (if rowSelect is enabled and rowIdKey is set)
  selection = new SelectionModel<any>(this.rowMultiSelect, []);

  // used to put timestamp to the exported files
  currentTimeInMilliseconds = Date.now();

  // vairables for the paginator and sort
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  // setup the table columns and rows on changes
  ngOnChanges() {
    this.displayedColumns = (this.rowSelect && this.rowIdKey ? ['select'] : []).concat(this.columns.map((e) => e.key));
    this.dataSource.data = this.rows;
  }

  /**
   * Set the paginator after the view init since this component will
   * be able to query its view for the initialized paginator.
   */
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  /**
   * updates the table's datasource filter with the specified value
   * @param {string} filterValue - string filtering the table by
   */
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    // MatTableDataSource defaults to lowercase matches
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  /**
   * clears the value from the specified filtering input element
   * and sets the table's datasource filter value to empty string
   * @param {HTMLInputElement} filter - filter element to clear
   */
  clearFilter(filter: HTMLInputElement) {
    filter.value = '';
    this.dataSource.filter = '';
  }

  /**
   * On click of a row, emits an event if rowSelect is enabled and rowIdKey is set, and highlights the row
   * if rowSelect is enabled and rowIdKey is included
   *
   * @param {any} row: row dict
   */
  onRowClick(row: any) {
    if (this.rowSelect && this.rowIdKey) {
      this.selection.toggle(row[this.rowIdKey]);
      const selectedRows = [];
      for (const row of this.rows) {
        if (this.selection.isSelected(row[this.rowIdKey])) {
          selectedRows.push(row);
        }
      }
      this.rowSelected.emit(selectedRows);
    }
  }

  /**
   * Calculates the row's background (used for row selection)
   * @param {any} row: row dict
   * @returns {any}: background color string, or '' for unchanged
   */
  rowBackground(row: any) {
    if (this.rowSelect && this.rowIdKey) {
      if (this.selection.isSelected(row[this.rowIdKey])) {
        return '#ddddff';
      }
    }
    return '';
  }

  /**
   * Open an external link in a new tab
   * @param {string} url: external url to open
   */
  openExternalLink(url: string) {
    window.open(url, '_blank');
  }
}
