import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AboutComponent } from './about/about.component';

@Component({
  selector: 'side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent {
  // tracks whether the sidebar should be open or closed
  @Input() open?: boolean;

  // current user profile data
  @Input() user?: any;

  // current url
  @Input() route?: string;

  constructor(public dialog: MatDialog) {}

  /**
   * Opens a dialog box containing information about the development of the app
   */
  openAboutDialog(): void {
    this.dialog.open(AboutComponent, { width: '600px' });
  }
}
