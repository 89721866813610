<div *ngIf="!isCurator && !embed" class="row">
  <div class="col-sm-12">
    <div class="form-horizontal">
      <div>
        <br />
        <h4>You do not have access to this page.</h4>
      </div>
    </div>
  </div>
</div>

<div [style.display]="isCurator || embed ? 'block' : 'none'">
  <mat-toolbar *ngIf="!embed">
    <h2>Edit Publications</h2>
  </mat-toolbar>
  <div class="row">
    <div class="col-sm-12">
      <div class="form-horizontal">
        <div *ngIf="!embed">
          &nbsp;&nbsp;&nbsp;
          <b><a class="text9" routerLink="/">Home</a></b>
          <span class="glyphicon glyphicon-chevron-right text9"></span>
          <b><a class="text9" routerLink="/tasks">Tasks</a></b>
          <span class="glyphicon glyphicon-chevron-right text9"></span>
          <b><span class="text9">Edit Publications</span></b>

          <br />
          <br />
          <br />
        </div>

        <div [style.display]="embed && !embedShowSearch ? 'none' : 'block'" class="form-group">
          <label class="col-sm-3 control-label">Search Publications</label>
          <div class="col-sm-6">
            <publication-search
              [(publication)]="publication"
              [showPMID]="true"
              [showDialog]="false"
              [disabled]="loading"
              (publicationChange)="onPublicationChange()"
            >
            </publication-search>
          </div>
        </div>

        <div align="center" *ngIf="loading">
          <br />
          <b>{{ loading }}</b>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          <br />
        </div>

        <div align="center">
          <span class="text16">
            <b>
              <span *ngIf="embed && embedShowSearch">Or </span>
              <span *ngIf="canEdit()">
                <span *ngIf="newPublication">Create New</span>
                <span *ngIf="!newPublication">Edit</span>
              </span>
              <span *ngIf="!canEdit()">View</span>
              Publication
            </b>
          </span>
        </div>
        <br />
        <fieldset [disabled]="!canEdit()">
          <div class="form-group">
            <label for="pmid" class="col-sm-3 control-label required disabled"
              >PubMedID
              <a
                *ngIf="publication.pmid && !newPublication ? publication.pmid.slice(0, 4) !== 'nopm' : false"
                href="http://www.ncbi.nlm.nih.gov/pubmed/{{ publication.pmid }}?dopt=Abstract"
                target="_blank"
                matTooltip="View in PubMed"
              >
                <span class="glyphicon glyphicon-link"></span>
              </a>
            </label>
            <div class="col-sm-6 has-feedback">
              <div class="input-group">
                <input
                  [(ngModel)]="publication.pmid"
                  type="text"
                  maxlength="30"
                  [disabled]="!newPublication || publication.pmid === 'NOPMID'"
                  class="form-control"
                  id="pmid"
                  name="pmid"
                  (keyup)="checkPmidAndTitle()"
                  (blur)="validatePmId()"
                  placeholder="Enter PMID & hit refresh OR select generate button"
                />
                <span *ngIf="newPublication && publication.pmid" class="form-control-feedback" id="pub-pmid-feedback">
                  <span class="glyphicon glyphicon-ok green" *ngIf="!pmidTaken && isNotEmpty(publication.pmid)"></span>
                  <span
                    class="glyphicon glyphicon-warning-sign red"
                    *ngIf="pmidTaken && isNotEmpty(publication.pmid)"
                  ></span>
                </span>

                <span *ngIf="!publication.pmid || publication.pmid === 'NOPMID'" class="input-group-btn">
                  <button
                    (click)="newNoPmid()"
                    type="button"
                    matTooltip="Generate 'NOPMID'"
                    [disabled]="publication.pmid === 'NOPMID'"
                    class="btn btn-success"
                  >
                    <span class="glyphicon glyphicon-new-window clear-icon"></span>
                  </button>
                </span>
                <span *ngIf="publication.pmid && publication.pmid !== 'NOPMID'" class="input-group-btn">
                  <button
                    type="button"
                    matTooltip="Load from Pubmed"
                    class="btn btn-success"
                    (click)="refreshPmid(true)"
                  >
                    <span class="glyphicon glyphicon-refresh clear-icon"></span>
                  </button>
                </span>
              </div>
              <span class="red margin-top-bottom-2">
                <small *ngIf="pmidTaken && isNotEmpty(publication.pmid)"> This <b>PubMedID</b> is already used. </small>
              </span>
            </div>
          </div>

          <div class="form-group has-feedback">
            <label for="publicationTitle" class="col-sm-3 control-label required">Title</label>
            <div class="col-sm-6">
              <input
                [(ngModel)]="publication.title"
                type="text"
                maxlength="300"
                class="form-control"
                id="publicationTitle"
                name="publicationTitle"
                (keyup)="checkPmidAndTitle()"
              />

              <span *ngIf="publication.title" class="form-control-feedback">
                <span
                  class="glyphicon glyphicon-ok green"
                  *ngIf="!titleTakenBy && isNotEmpty(publication.title)"
                ></span>
                <span
                  class="glyphicon glyphicon-warning-sign red"
                  *ngIf="titleTakenBy && isNotEmpty(publication.title)"
                ></span>
              </span>
              <span class="red margin-top-bottom-2">
                <small *ngIf="titleTakenBy && isNotEmpty(publication.title)">
                  This <b>Title</b> is already used in publication with PMID: {{ titleTakenBy }}
                </small>
              </span>
            </div>
          </div>

          <div class="form-group">
            <label for="authorList" class="col-sm-3 control-label required">Authors</label>
            <div class="col-sm-6">
              <input
                [(ngModel)]="publication.authorlist"
                type="text"
                class="form-control"
                id="authorList"
                name="authorList"
              />
            </div>
          </div>

          <div class="form-group">
            <label for="journal" class="col-sm-3 control-label required">Journal</label>
            <div class="col-sm-6">
              <input
                [(ngModel)]="publication.journal"
                type="text"
                maxlength="300"
                class="form-control"
                id="journal"
                name="journal"
              />
            </div>
          </div>

          <div class="form-group">
            <label for="year" class="col-sm-3 control-label required">Year</label>
            <div class="col-sm-6">
              <input
                [(ngModel)]="publication.year"
                type="text"
                maxlength="10"
                class="form-control"
                id="year"
                name="year"
                (keyup)="validateYear()"
              />
              <span class="red margin-top-bottom-2">
                <small *ngIf="!yearValid"> Fix year: {{ yearMessage }} </small>
              </span>
            </div>
          </div>

          <div class="form-group">
            <label for="pmcid" class="col-sm-3 control-label">PMCID</label>
            <div class="col-sm-6">
              <input
                [(ngModel)]="publication.pmcid"
                type="text"
                maxlength="30"
                class="form-control"
                id="pmcid"
                name="pmcid"
                placeholder="for full text"
              />
            </div>
          </div>

          <div class="form-group">
            <label for="capture_reason" class="col-sm-3 control-label">Reason for Capture</label>
            <div class="col-sm-6">
              <select
                [(ngModel)]="publication.capreason"
                class="form-control"
                id="capture_reason"
                name="capture_reason"
              >
                <option value=""></option>
                <option value="methodology">Methodology</option>
                <option value="usedmpd">Used MPD</option>
                <option value="hasdata1">Future data prospect</option>
                <option value="hasdata2">Future data prospect (alt)</option>
                <option value="other">Other</option>
              </select>
            </div>
          </div>

          <div class="form-group">
            <label for="notes" class="col-sm-3 control-label">Curator Notes</label>
            <div class="col-sm-6">
              <textarea
                [(ngModel)]="publication.ournotes"
                class="form-control"
                maxlength="300"
                rows="3"
                id="notes"
                name="notes"
              ></textarea>
            </div>
          </div>

          <div class="form-group">
            <label for="curator" class="col-sm-3 control-label">Curator</label>
            <div class="col-sm-6">
              <input
                [(ngModel)]="publication.annotator"
                type="text"
                maxlength="20"
                class="form-control"
                id="curator"
                name="curator"
              />
            </div>
          </div>
        </fieldset>

        <!-- information about projects associated with this publication -->
        <div *ngIf="!newPublication && isCurator">
          <div class="text-center">
            <span class="text10"
              ><b>{{ publication.projcount }}</b> projects are associated with this publication</span
            >
            [
            <a *ngIf="!showProjects && publication.projcount > 0" style="cursor: pointer" (click)="showProjects = true"
              >show</a
            >
            <a *ngIf="showProjects" style="cursor: pointer" (click)="showProjects = false">hide</a>]
          </div>
          <div *ngIf="showProjects">
            <br />
            <p>{{ projectDisplay() }}</p>
          </div>
        </div>
        <!-- END information about projects associated with this publication section -->

        <br />

        <!-- save and delete buttons section -->
        <div class="form-group">
          <div [class]="(newPublication && !embed) || !canEdit() ? 'col-sm-4' : 'col-sm-2'"></div>
          <div [matTooltip]="!canSave() ? disableSaveMessage : ''" *ngIf="canEdit()" class="col-sm-4">
            <button class="btn btn-success btn-block" [disabled]="!canSave()" (click)="savePublication()">
              <strong>Save</strong>
            </button>
          </div>
          <div
            [matTooltip]="!canDelete() ? 'Cannot delete a publication with associated projects' : ''"
            *ngIf="!newPublication && !embed"
            class="col-sm-4"
          >
            <button class="btn btn-danger btn-block" [disabled]="!canDelete()" (click)="deletePublication()">
              <strong>Delete</strong>
            </button>
          </div>
          <div class="col-sm-4" *ngIf="embed">
            <button class="btn btn-default btn-block" (click)="cancel.emit(true)">
              <strong>{{ canEdit() ? 'Cancel' : 'Close' }}</strong>
            </button>
          </div>
        </div>
        <!-- END save and delete buttons section -->
      </div>
    </div>
  </div>
</div>
