import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { escapeHtml } from './utils';

@Component({
  selector: 'combobox',
  template: `
    <!--Using fieldset as a wrapper to make disabling work (it works differently...and not as well...for
        inputs with the formControl attribute, but a wrapping fieldset seems to work fine)-->
    <fieldset [disabled]="disabled">
      <input
        class="form-control"
        [name]="id"
        autocomplete="off"
        (focus)="onFocus()"
        [maxlength]="maxlength"
        [id]="id"
        [matAutocomplete]="auto"
        [formControl]="valueCtrl"
        [placeholder]="placeholder"
      />
      <small *ngIf="htmlOptions && valueCtrl.value && showHtmlPreview">
        &nbsp;<b>Preview: </b>
        <span [innerHtml]="htmlReplace(valueCtrl.value)"></span>
      </small>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected()" [displayWith]="displayFn">
        <mat-option
          *ngFor="let option of filteredOptions"
          [matTooltip]="tooltipKey ? option[tooltipKey] : ''"
          [value]="option"
        >
          <span
            *ngIf="htmlOptions"
            [innerHtml]="htmlReplace(displayKey ? option[displayKey] : option) | SafeHTML"
          ></span>
          <span *ngIf="!htmlOptions">{{ displayKey ? option[displayKey] : option }}</span>
        </mat-option>
      </mat-autocomplete>
    </fieldset>
  `,
  styles: [
    `
      input {
        margin-top: 0;
        background-color: white;
      }
      .mat-option {
        font-size: 14px;
        line-height: 18px;
        height: auto;
        white-space: normal;
        border-top: 1px solid lightgray;
        padding: 6px 10px;
      }
      .mat-option[aria-disabled='true'] {
        background: #e4e4e4;
        cursor: not-allowed;
      }
      .mat-option.show-all {
        background: inherit;
        cursor: default;
      }
    `,
  ],
})
export class ComboboxComponent implements OnChanges {
  // 2-way binding value in the field (combobox)
  @Input() value: any;

  // id and name attribute for the input html component
  @Input() id = '';

  // full list of options (suggestions) for the value, either a list of string or list of objects
  @Input() options: any[] = [];

  // flag that the options are in html format, so display them as html and then
  // show a preview of them as html
  @Input() htmlOptions = false;

  // list of html characters to allow instead of escaping...
  // current possibilities are 'ALL' to just pass through
  // and not escape anything if input is trusted, '&' and 'sup' (for sup or sub) right now
  @Input() htmlAllow: string[] = [];

  // true to show a preview of selected/entered option looks in html, false to not
  @Input() showHtmlPreview = true;

  // true to disable this field, false to not
  @Input() disabled = false;

  // placeholder texts for the input
  @Input() placeholder = '';

  // number of characters that can be entered in the combobox (negative number allows unlimited characters)
  @Input() maxlength = -1;

  // true to always update filtered options on focus
  @Input() updateOptionsOnFocus = false;

  // If the options are a list of objects, this is the key that should be displayed in the autocomplete options
  // and after a value is selected from the autocomplete.
  // NOTE: Currently not in use anywhere because of some difficulty getting it working quite right for institutions.
  @Input() displayKey = '';

  // If the options are a list of objects,
  // this is the key that should be displayed in the tooltip for the option
  @Input() tooltipKey = '';

  // event for emitting value changes to the parent (from the user typing OR when an option is selected)
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  // event that is emitted only when a specific option is selected from the autocomplete options
  // (not when the value changes from the user typing)
  @Output() optionSelected: EventEmitter<any> = new EventEmitter<any>();

  // formControl for the input
  valueCtrl: FormControl;

  // flag that the change to the value came from the parent, so don't emit it back to the parent
  parentValueChange = false;

  // filtered list of options that are calculated as an observable as a sublit of options
  // on change of the value and appear in the list of options for the autocomplete
  filteredOptions: any[] = [];

  // declare the formControl and set up the observable to filter options on change of the value (user typing)
  constructor() {
    this.valueCtrl = new FormControl();
    this.valueCtrl.valueChanges.subscribe((newValue) => {
      this.filteredOptions = this.onValueChange(newValue);
    });
  }

  /**
   * Emit the change and filter the options available in the autocomplete
   * @param newValue: New value for the input, either a string or an object which is converted to a string
   *                  if an object option was selected (can be undefined)
   * @returns {any[]} filtered list of options
   */
  onValueChange(newValue: any) {
    if (!this.parentValueChange) {
      this.emitChange();
    }
    if (typeof newValue === 'object' && this.displayKey) {
      newValue = newValue[this.displayKey];
    }
    const unsortedOptions =
      newValue && newValue !== '' ? this.filterOptions(newValue) : this.parentValueChange ? [] : this.options.slice();

    return unsortedOptions.sort((a, b) => {
      const x = a ? (this.displayKey ? a[this.displayKey].toLowerCase() : a.toLowerCase()) : '';
      const y = b ? (this.displayKey ? b[this.displayKey].toLowerCase() : b.toLowerCase()) : '';
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }

  /**
   * Filter options available in the autocomplete based on the input string
   * @param {string} inputString: String to match options with
   * @returns {any[]}: filtered list of options
   */
  filterOptions(inputString: string) {
    return this.options.filter((option) =>
      option
        ? this.displayKey
          ? option[this.displayKey].toLowerCase().indexOf(inputString.toLowerCase()) !== -1
          : option.toLowerCase().indexOf(inputString.toLowerCase()) !== -1
        : false,
    );
  }

  // on change from the parent of the value, set the value to it,
  // unless it's currently not set. In order to avoid too much heavy up-front loading,
  // we are now handling initial creation of the filtering options for blank fields
  // when you focus on the field
  ngOnChanges() {
    // only change the value if they are not equal and one is set (sometimes one is undefined and the other is null,
    // so we don't want to bother to bog down up front loading times in that case
    if ((this.value || this.valueCtrl.value) && this.valueCtrl.value !== this.value) {
      this.parentValueChange = true;
      this.valueCtrl.setValue(this.value);
      this.parentValueChange = false;
    }
  }

  // upon focusing on a field, if the current value is blank and we have no options, then create them
  onFocus() {
    if (this.updateOptionsOnFocus || ((!this.value || this.value === '') && this.filteredOptions.length === 0)) {
      this.filteredOptions = this.onValueChange(this.value);
    }
  }

  /**
   * Display function for if an option is selected
   * @param option: string or object (use displayKey if it's an object), or undefined
   * @returns {string}: Display for the option
   */
  displayFn(option?: any): string | undefined {
    return option ? (this.displayKey ? option[this.displayKey] : option) : undefined;
  }

  // if an option is selected, emit a special event for that (in additon to the regular valueChange event)
  onOptionSelected() {
    this.emitChange();
    this.optionSelected.emit(this.value);
  }

  /**
   * Convert the html string into a safe value by escaping characters
   *
   * @param {string} val: input value
   * @returns {string}: transformed output value
   */
  htmlReplace(val: string): string {
    return escapeHtml(val, this.htmlAllow);
  }

  // update the value to match the value in valueCtrl and emit it to the parent
  emitChange() {
    this.value = this.valueCtrl.value;
    this.valueChange.emit(this.value);
  }
}
