import { Component, EventEmitter, Input, Output } from '@angular/core';
import { R2File } from '../../../services/r2.service';

/**
 * This component is responsible for displaying file information on an array that has been
 * uploaded in a previous session. It displays a readonly platform select and two buttons
 * containing the names of the two array files that were uploaded which, when clicked open a
 * new browser tab that will allow the user to directly access the files they uploaded. The
 * component allows project editors to remove the array but not to edit it. Non-editors can
 * only view, not remove arrays.
 */
@Component({
  selector: 'restored-array',
  templateUrl: './restored-array.component.html',
  styleUrls: ['./restored-array.component.scss'],
})
export class RestoredArrayComponent {
  // informtion about the previously uploaded array
  @Input() arrayData!: RestoredArrayData;

  // whether the current user is able to edit the project
  @Input() editable!: boolean;

  // emits when user clicks the remove/cancel button for the array
  @Output() remove: EventEmitter<null> = new EventEmitter<null>();
}

export interface RestoredArrayData {
  platform: string;
  sampleMap: R2File | { location: string; name: string };
  finalReport: R2File | { location: string; name: string };
}
