<mat-toolbar>
  <h2>Share Project</h2>
</mat-toolbar>
<br />
<div class="row">
  <div class="col-sm-12">
    <div class="form-horizontal" style="padding-left: 18px">
      <a
        routerLink="/projects"
        [queryParams]="user ? { userid: 'current' } : { status: 'Public' }"
        class="dark-cerulean text10"
      >
        <mat-icon class="v-bottom" inline="true"> arrow_back </mat-icon>Back to
        <b>{{ user ? 'My' : 'Public' }} Projects</b>
      </a>
      <br />
      <div *ngIf="noProject">
        <br />
        <h4>Either unable to find or unable to access project id: {{ projid }}</h4>
      </div>
      <div *ngIf="!noProject">
        <div style="margin-left: -2px">
          <span class="title-css">{{ project.projsym }}</span
          >&nbsp;
          <span
            class="glyphicon glyphicon-question-sign"
            matTooltip="This is an identifier for the project. A SIP curator will update
                            this when the project is ready for release."
            matTooltipPosition="after"
            matTooltipHideDelay="2000"
          ></span>
        </div>
        <div style="margin-left: -2px" *ngIf="!editingTitle">
          <span (click)="editTitle()" class="title-css" [ngClass]="{ 'admin-title-css': project.canEdit }">
            {{ project.title }}
          </span>
          &nbsp;<span
            *ngIf="project.canEdit"
            (click)="editTitle()"
            class="glyphicon glyphicon-pencil edit-name-icon"
          ></span>
        </div>
        <div style="margin-left: -2px" *ngIf="editingTitle">
          <input
            [(ngModel)]="newTitle"
            type="text"
            class="form-control title-css"
            id="new_project_title"
            name="new_project_title"
          />
          <span (click)="saveTitle()" matTooltip="Save Changes" class="glyphicon glyphicon-ok edit-name-icon"></span
          >&nbsp;
          <span
            (click)="editingTitle = false; newTitle = project.title"
            matTooltip="Discard Changes"
            class="glyphicon glyphicon-remove edit-name-icon"
          ></span>
        </div>
        <div style="color: red" *ngIf="project.deletedtime">
          <br />
          <b>
            NOTE: This project was deleted. A project owner or SIP curator can use the blue trash can action to restore
            it.
          </b>
        </div>
        <div *ngIf="project.current_user_permission">
          <br />
          <label>Project Actions</label>
          <br />
          <project-actions
            [project]="project"
            [noSharing]="true"
            (projectDeleted)="router.navigateByUrl('/projects?userid=current')"
            (projectRestored)="getProject()"
            (projectChanged)="getProject()"
            (clickExternalLink)="openExternalLink($event)"
          >
          </project-actions>
        </div>
        <br />
        <span *ngIf="project.current_user_permission">
          <span style="color: red" *ngIf="!userUnrestricted && project.current_user_permission !== 'Public'">
            {{ permissionNote() }}<br />
          </span>
          You have <b>{{ project.current_user_permission }}</b> permissions for this project.
        </span>
        <span *ngIf="!project.current_user_permission">You do not have access to this project.</span>&nbsp;
        <a *ngIf="!showDescription" style="cursor: pointer" (click)="showDescription = true">
          View permission descriptions...
        </a>
        <a *ngIf="showDescription" style="cursor: pointer" (click)="showDescription = false">
          Hide permission descriptions...
        </a>
        <div style="color: #777777" *ngIf="showDescription">
          <p>
            <br />
            <b>Owner</b>: Users with the Owner permission are able to edit any of the content of the project. Also, they
            have full control over adding, modifying, or revoking permissions to access the project for other users
            (including other Owners). Owners are also able to delete the project unless it has been released. The user
            who created the project is automatically made the first Owner, and there must always be at least one Owner
            for the project. <br /><br />
            <b>Edit</b>: Users with the Edit permission (and people who are an Editor or Admin in a group with Edit
            permission) are able to edit any of the content of the project. Also, they can add other people or groups to
            the project with either the Edit or View permission. Unlike an Owner, they may not revoke access to the
            project from other users or groups, and they may not delete the project. <br /><br />
            <b>View</b>: Users with the View permission may only view, but not edit any of the content within the
            project. A user can also have the View permission if they are a member of a group the View permission, or if
            they are a Viewer in a group with the Edit permission. <br /><br />
            <b>Public</b>: The same as View permission, but because the project has been made Public, rather than being
            given explicit access. If there are private files associated with the project, then someone with View
            permission will be able to view them, but someone with Public access will not.
          </p>
        </div>

        <br /><br />
        <div align="center"><span style="font-size: 20px; font-weight: 500">Users</span></div>
        <br /><br />
        <div class="form-group" *ngFor="let userPerm of project.userpermissions">
          <label class="col-sm-2 control-label">User</label>
          <div style="padding-top: 7px" class="col-sm-5">
            &nbsp;<person-display [person]="userPerm"></person-display>
          </div>
          <label class="col-sm-2 control-label" [for]="'permission_' + userPerm.id">Permission</label>
          <div
            [matTooltip]="
              project.isOwner && userPerm.isOnlyOwner
                ? 'You cannot change your permission because you are the only Owner'
                : ''
            "
            class="col-sm-2"
          >
            <select
              *ngIf="
                (project.isOwner || (project.canEdit && userPerm.permission === 'View')) &&
                !userPerm.isOnlyOwner &&
                !project.deletedtime
              "
              class="form-control"
              [(ngModel)]="userPerm.permission"
              [id]="'permission_' + userPerm.id"
              [name]="'permission_' + userPerm.id"
              (change)="addOrModifyUserPermissionConfirm(userPerm, userPerm.permission)"
            >
              <option *ngFor="let option of userPermOptions" [value]="option">{{ option }}</option>
            </select>
            <div
              *ngIf="
                !(
                  (project.isOwner || (project.canEdit && userPerm.permission === 'View')) &&
                  !userPerm.isOnlyOwner &&
                  !project.deletedtime
                )
              "
              style="padding-top: 7px"
            >
              &nbsp;&nbsp;{{ userPerm.permission }}
            </div>
          </div>
          <div
            *ngIf="showRemoveUserPermission(userPerm)"
            class="col-sm-1"
            [matTooltip]="removeUserPermissionDisabledTooltip(userPerm)"
          >
            <button
              type="button"
              class="btn btn-danger"
              [disabled]="disableRemoveUserPermission(userPerm)"
              [matTooltip]="removeUserPermissionTooltip(userPerm)"
              (click)="removeUserPermissionConfirm(userPerm)"
            >
              <span class="glyphicon glyphicon-remove"></span>
            </button>
          </div>
        </div>
        <div *ngIf="project.canEdit" class="form-group">
          <label class="col-sm-2 control-label">Select User</label>
          <div class="col-sm-5">
            <person-search
              #person_lookup
              [(person)]="personSearch"
              [user]="true"
              [inviteByEmail]="true"
              [showDialog]="true"
              [disableList]="project.userpermissions"
            ></person-search>
          </div>
          <label class="col-sm-2 control-label" for="new_permission">Permission</label>
          <div class="col-sm-2">
            <select class="form-control" [(ngModel)]="newPermission" id="new_permission" name="new_permission">
              <option *ngFor="let option of userPermOptions" [value]="option">{{ option }}</option>
            </select>
          </div>
          <div class="col-sm-1">
            <button
              type="button"
              matTooltip="Share with User"
              *ngIf="personSearch.id || personSearch.email"
              (click)="
                addOrModifyUserPermission(personSearch.id, newPermission, personSearch.email);
                person_lookup.clearSelection()
              "
              class="btn btn-primary"
            >
              <span class="glyphicon glyphicon-plus"></span>
            </button>
          </div>
        </div>
        <br /><br /><br />

        <div align="center"><span style="font-size: 20px; font-weight: 500">Groups</span></div>
        <br /><br />
        <div class="form-group" *ngFor="let groupPerm of project.grouppermissions">
          <label class="col-sm-2 control-label">Group</label>
          <div style="padding-top: 7px" class="col-sm-5">
            &nbsp;
            <span style="cursor: default" [matTooltip]="groupMembersTooltip(groupPerm)">
              <b>{{ groupPerm['members'].length }}</b>
              <span style="color: blue" class="glyphicon glyphicon-user"></span>
            </span>
            |
            {{ groupPerm.name }}
            <a matTooltip="Go to group" [routerLink]="'/group/' + groupPerm.id">
              <span class="glyphicon glyphicon-link"></span>
            </a>
          </div>
          <label class="col-sm-2 control-label" [for]="'group_permission_' + groupPerm.id">Permission</label>
          <div class="col-sm-2">
            <select
              *ngIf="(project.isOwner || (project.canEdit && groupPerm.permission === 'View')) && !project.deletedtime"
              class="form-control"
              [name]="'group_permission_' + groupPerm.id"
              [(ngModel)]="groupPerm.permission"
              [id]="'group_permission_' + groupPerm.id"
              (change)="addOrModifyGroupPermission(groupPerm.id, groupPerm.permission)"
            >
              <option *ngFor="let option of ['Edit', 'View']" [value]="option">{{ option }}</option>
            </select>
            <div
              *ngIf="
                !((project.isOwner || (project.canEdit && groupPerm.permission === 'View')) && !project.deletedtime)
              "
              style="padding-top: 7px"
            >
              &nbsp;&nbsp;{{ groupPerm.permission }}
            </div>
          </div>
          <div
            *ngIf="(project.isOwner && !project.deletedtime) || groupPerm.current_user_role === 'Admin'"
            class="col-sm-1"
          >
            <button
              type="button"
              class="btn btn-danger"
              matTooltip="Revoke group's access to the project"
              (click)="removeGroupPermissionConfirm(groupPerm)"
            >
              <span class="glyphicon glyphicon-remove"></span>
            </button>
          </div>
        </div>
        <div *ngIf="project.canEdit" class="form-group">
          <label class="col-sm-2 control-label">Select Group</label>
          <div class="col-sm-5">
            <group-search [disableList]="project.grouppermissions" #group_lookup [(group)]="groupSearch"></group-search>
          </div>
          <label class="col-sm-2 control-label" for="new_group_permission">Permission</label>
          <div class="col-sm-2">
            <select
              class="form-control"
              [(ngModel)]="newGroupPermission"
              id="new_group_permission"
              name="new_group_permission"
            >
              <option *ngFor="let option of ['Edit', 'View']" [value]="option">{{ option }}</option>
            </select>
          </div>
          <div class="col-sm-1">
            <button
              type="button"
              matTooltip="Share with Group"
              *ngIf="groupSearch.id"
              (click)="addOrModifyGroupPermission(groupSearch.id, newGroupPermission); group_lookup.clearSelection()"
              class="btn btn-primary"
            >
              <span class="glyphicon glyphicon-plus"></span>
            </button>
          </div>
        </div>
        <br /><br />
      </div>
    </div>
  </div>
</div>
