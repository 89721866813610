<div *ngIf="!isCurator" class="row">
  <div class="col-sm-12">
    <div class="form-horizontal">
      <div>
        <br />
        <h4>You do not have access to this page.</h4>
      </div>
    </div>
  </div>
</div>

<div [style.display]="isCurator ? 'block' : 'none'">
  <mat-toolbar>
    <h2>Edit Investigators Information</h2>
  </mat-toolbar>
  <div class="row">
    <div class="col-sm-12">
      <div>
        &nbsp;&nbsp;&nbsp;
        <b><a class="text9" routerLink="/">Home</a></b>
        <span class="glyphicon glyphicon-chevron-right text9"></span>
        <b><a class="text9" routerLink="/tasks">Tasks</a></b>
        <span class="glyphicon glyphicon-chevron-right text9"></span>
        <b><span class="text9">Edit Investigators Information</span></b>

        <br />
        <br />
        <br />
      </div>
      <div class="form-horizontal">
        <div class="form-group">
          <label class="col-sm-3 control-label">Filters</label>
          <div class="col-sm-6">
            <label style="cursor: pointer; padding-top: 7px; white-space: nowrap">
              <input [(ngModel)]="onlyUsers" id="onlyUsers" name="onlyUsers" type="checkbox" />
              &nbsp;Users only
            </label>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-3 control-label">Search Investigators</label>
          <div class="col-sm-6">
            <person-search
              #person_search_form
              [(person)]="personSearch"
              [showDialog]="false"
              (personChange)="onInvestigatorChange()"
              [showCanDelete]="true"
              [showCuratorIcon]="true"
              [user]="onlyUsers"
            ></person-search>
          </div>
        </div>
        <br />
        <br />
        <div align="center">
          <span class="text16">
            <b>{{ profileID ? 'Edit Investigator' : 'Create New Investigator' }}</b>
          </span>
        </div>
        <br />
        <edit-person #person_form [(id)]="profileID" [noAutosave]="true" [uniqueTag]="'edit_person_form'"></edit-person>

        <br />
        <div *ngIf="person_form.profile.allow_changes" class="form-group">
          <div [class]="profileID ? 'col-sm-3' : 'col-sm-4'"></div>
          <div
            [matTooltip]="person_form.saveDisabledTooltip() ? 'First Name and Last Name are required to save' : ''"
            [class]="profileID ? 'col-sm-3' : 'col-sm-4'"
          >
            <button
              [disabled]="person_form.saveDisabled()"
              class="btn btn-success btn-block"
              (click)="saveInvestigator()"
              id="person-save"
            >
              <strong>Save</strong>
            </button>
          </div>
          <div
            *ngIf="profileID"
            class="col-sm-3"
            [matTooltip]="!person_form.profile.candelete ? 'Cannot delete this investigator' : ''"
          >
            <button
              class="btn btn-danger btn-block"
              [disabled]="!person_form.profile.candelete"
              (click)="deleteInvestigator()"
            >
              <strong>Delete</strong>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
