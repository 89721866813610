<div *ngIf="!isCurator && !embed" class="row">
  <div class="col-sm-12">
    <div class="form-horizontal" style="padding-left: 18px">
      <div>
        <br />
        <h4>You do not have access to this page.</h4>
      </div>
    </div>
  </div>
</div>

<div [style.display]="isCurator || embed ? 'block' : 'none'">
  <mat-toolbar *ngIf="!embed">
    <h2>Edit Panels</h2>
  </mat-toolbar>
  <div class="row">
    <div class="col-sm-12">
      <div *ngIf="!embed">
        &nbsp;&nbsp;&nbsp;
        <b><a class="text9" routerLink="/">Home</a></b>
        <span class="glyphicon glyphicon-chevron-right text9"></span>
        <b><a class="text9" routerLink="/tasks">Tasks</a></b>
        <span class="glyphicon glyphicon-chevron-right text9"></span>
        <b><span class="text9">Edit Panel Information</span></b>

        <br /><br /><br />
      </div>
      <div class="form-horizontal" style="padding-left: 18px">
        <div [style.display]="embed ? 'none' : 'block'" class="form-group">
          <label class="col-sm-3 control-label">Search Panels</label>
          <div class="col-sm-6">
            <panel-search [(panel)]="panel" [showPanelSym]="true" (panelChange)="onPanelChange()" [showDialog]="false">
            </panel-search>
          </div>
        </div>
        <div align="center">
          <span class="text16">
            <b>
              <span *ngIf="newPanel">Create New</span>
              <span *ngIf="!newPanel && canEdit()">Edit</span>
              <span *ngIf="!canEdit()">View</span>
              Panel
            </b>
          </span>
        </div>
        <br />
        <fieldset [disabled]="!canEdit()">
          <div class="form-group">
            <label for="panelsym" class="col-sm-3 control-label required" required>
              Panel Symbol
              <span *ngIf="newPanel && panel.panelsym">
                <span
                  matTooltip="This panelsym is available"
                  style="color: green"
                  class="glyphicon glyphicon-ok"
                  *ngIf="!panelsymTaken"
                ></span>
                <span
                  matTooltip="This panelsym is taken"
                  style="color: red"
                  class="glyphicon glyphicon-warning-sign"
                  *ngIf="panelsymTaken"
                ></span>
              </span>
            </label>
            <div class="col-sm-6">
              <!--34 length limit because paneldesc is currently limited to 40 and it is panelsym + up to 6 chars-->
              <input
                [(ngModel)]="panel.panelsym"
                type="text"
                maxlength="34"
                [disabled]="!newPanel"
                class="form-control"
                id="panelsym"
                name="panelsym"
                (keyup)="checkPanelsymAndName()"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="panelname" class="col-sm-3 control-label required" required>
              Name
              <span *ngIf="panel.name">
                <span
                  matTooltip="This name is available"
                  style="color: green"
                  class="glyphicon glyphicon-ok"
                  *ngIf="!nameTakenBy"
                ></span>
                <span
                  [matTooltip]="'This name is taken by panelsym: ' + nameTakenBy"
                  style="color: red"
                  class="glyphicon glyphicon-warning-sign"
                  *ngIf="nameTakenBy"
                ></span>
              </span>
            </label>
            <div class="col-sm-6">
              <input
                [(ngModel)]="panel.name"
                type="text"
                maxlength="128"
                class="form-control"
                id="panelname"
                name="panelname"
                (keyup)="checkPanelsymAndName()"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="paneltype" class="col-sm-3 control-label">Panel Type</label>
            <div class="col-sm-6">
              <select [(ngModel)]="panel.pantype" class="form-control" id="paneltype" name="paneltype">
                <option value=""></option>
                <option value="IN">inbred</option>
                <option value="IWD">wild-derived inbred</option>
                <option value="F1">F1 hybrid</option>
                <option value="RI">recombinant inbred</option>
                <option value="CG">congenic</option>
                <option value="RC">recombinant congenic</option>
                <option value="CI">coisogenic</option>
                <option value="CS">consomic (chromosome substitution)</option>
                <option value="OU">outbred</option>
                <option value="SB">selectively bred line</option>
                <option value="MU">mutant stock</option>
                <option value="subset">subset</option>
                <option value="mixture">mixture</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="paneldata_avail" class="col-sm-3 control-label">Data Available</label>
            <div class="col-sm-6">
              <select [(ngModel)]="panel.data_avail" class="form-control" id="paneldata_avail" name="paneldata_avail">
                <option value=""></option>
                <option value="hidden">hidden</option>
                <option value="pheno">pheno</option>
                <option value="pheno,snp">pheno,snp</option>
              </select>
            </div>
          </div>
        </fieldset>
        <div *ngIf="!newPanel && isCurator">
          <br />
          <div align="center">
            There are {{ panel.straincount }} strains associated with this panel.
            <a *ngIf="!showStrains && panel.straincount > 0" style="cursor: pointer" (click)="showStrains = true"
              >Show</a
            >
            <a *ngIf="showStrains" style="cursor: pointer" (click)="showStrains = false">Hide</a>
          </div>
          <div *ngIf="showStrains">
            <br />
            <p>{{ strainDisplay() }}</p>
          </div>
          <br />
          <div align="center">
            There are {{ panel.projcount }} projects associated with this panel.
            <a *ngIf="!showProjects && panel.projcount > 0" style="cursor: pointer" (click)="showProjects = true"
              >Show</a
            >
            <a *ngIf="showProjects" style="cursor: pointer" (click)="showProjects = false">Hide</a>
          </div>
          <div *ngIf="showProjects">
            <br />
            <p>{{ projectDisplay() }}</p>
          </div>
        </div>
        <br />
        <div class="form-group">
          <div [class]="(newPanel && !embed) || !canEdit() ? 'col-sm-4' : 'col-sm-2'"></div>
          <div
            [matTooltip]="
              !panel.panelsym || !panel.name || panelsymTaken || nameTakenBy
                ? 'panelsym and name must be set and available to save'
                : ''
            "
            class="col-sm-4"
            *ngIf="canEdit()"
          >
            <button
              class="btn btn-success btn-block"
              [disabled]="!panel.panelsym || !panel.name || panelsymTaken || nameTakenBy"
              (click)="savePanel()"
            >
              <strong>Save</strong>
            </button>
          </div>
          <div
            [matTooltip]="
              panel.straincount || panel.projcount ? 'Cannot delete a panel with associated strains or projects' : ''
            "
            *ngIf="!newPanel && !embed"
            class="col-sm-4"
          >
            <button
              class="btn btn-danger btn-block"
              [disabled]="panel.straincount > 0 || panel.projcount > 0"
              (click)="deletePanel()"
            >
              <strong>Delete</strong>
            </button>
          </div>
          <div class="col-sm-4" *ngIf="embed">
            <button class="btn btn-default btn-block" (click)="cancel.emit(true)">
              <strong>{{ canEdit() ? 'Cancel' : 'Close' }}</strong>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
