<mat-toolbar>
  <h2 class="text-right">Data Definitions</h2>
</mat-toolbar>
<br />

<timeline
  #timeline_component
  [projid]="projid"
  [invalidID]="invalidID"
  [loading]="loadingProject"
  [(projsym)]="project.projsym"
  [(title)]="project.title"
  [project]="project"
  (clickExternalLink)="state_buttons_component.openExternalUrlAfterSave($event)"
  (clickPageLink)="state_buttons_component.openPageLinkAfterSave($event)"
  (clickReleaseProject)="state_buttons_component.releaseProject()"
  (clickReimportProject)="state_buttons_component.reimportProject()"
  (clickChangeStatus)="state_buttons_component.changeProjectStatus($event)"
  (clickImportExport)="state_buttons_component.onClickProjectImportExport(timeline_component.projectActionsComponent)"
  (projectChanged)="getProject()"
  (projectRestored)="state_buttons_component.projectRestored()"
  [actionLoadingBar]="state_buttons_component.loadingBar"
></timeline>

<div [style.display]="invalidID || loadingProject ? 'none' : 'block'">
  <mat-vertical-stepper #stepper>
    <mat-step [label]="'Upload ' + (project.pheno_measures.length > 0 ? 'Additional ' : '') + 'Phenotype Data'">
      <br />
      <div class="form-horizontal">
        <div class="form-group">
          <div class="col-sm-1"></div>
          <div class="col-sm-9">
            <p>
              The data file (csv) should have one row per mouse (or strain and sex if submitting means only) where
              columns are measured values. The file may also contain values for strain, sex, mouse id, and other
              parameters. Only one data file may be uploaded at a time (in order to upload a new file, the current file
              needs to be removed first). Be sure your file has a descriptive name such as 'Smith3_submit.csv'.
              <span *ngIf="project.pheno_measures.length > 0">
                <br /><br />
                Additionally, you can modify or remove the
                <button style="padding: 0; border: 0; margin-bottom: 2px" class="btn btn-link" matStepperNext>
                  data already associated with this project</button
                >.
              </span>
            </p>
          </div>
        </div>
      </div>
      <file-upload
        [allowEdit]="project.canEdit"
        [pointertype]="'projid'"
        [optional]="false"
        [pointer]="project.projid"
        [tag]="'data'"
        [imageOnly]="false"
        [showDescription]="false"
        [fileTypes]="'.txt, .csv, .tsv, .xls, .xlsx'"
        [fileNumLimit]="1"
        [title]="'Data File'"
        (fileUpdated)="onFileUpdate()"
        (fileUploaded)="onFileUpload()"
        (filesLoaded)="onFilesLoaded()"
      ></file-upload>
      <div *ngIf="fileUploaded()">
        <hr />
        <div align="center">
          <span style="font-size: 20px; font-weight: 500">Data Dictionary</span>
        </div>
        <br />
        <div class="form-horizontal">
          <div class="form-group">
            <label style="padding-top: 0" class="required col-sm-3 control-label" for="measGran"
              >Measure values are</label
            >
            <div class="col-sm-8">
              <mat-radio-group
                id="measGran"
                name="measType"
                (change)="granularityChange()"
                [(ngModel)]="project.dataformat.granularity"
                [disabled]="!project.canEdit"
              >
                <mat-radio-button value="animal"> From Individual Animals </mat-radio-button>
                &nbsp;&nbsp;
                <mat-radio-button value="strainsex"> Strain Means </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label"
              >Measures Method (optional)
              <span
                class="glyphicon glyphicon-question-sign"
                matTooltip="If all of your measures use the same method, then you can enter it here, and
                                method will be populated on all of your measures when you click 'Process Data'."
              ></span>
            </label>
            <div class="col-sm-8">
              <methodology-search
                [(methodology)]="project.dataformat.methodology"
                [disabled]="!project.canEdit"
              ></methodology-search>
            </div>
          </div>
        </div>
        <fieldset [disabled]="!project.canEdit">
          <button
            class="btn btn-success"
            *ngIf="project.canEdit"
            (click)="buildDataDefs()"
            style="margin-bottom: 5px"
            matTooltip="This will reset the below table to its default state for the uploaded file."
          >
            <span class="glyphicon glyphicon-refresh"></span> Reset Table
          </button>
          <table
            [class]="
              'table table-bordered header-fixed' + (clickDrag || setCheckZero(orderSwitchIndex) ? ' noselect' : '')
            "
            style="background-color: white"
          >
            <thead>
              <tr>
                <th style="width: 22%">Column</th>
                <th class="check-cell-header">Data Type</th>
                <th class="check-cell-header">Variable Type</th>
                <th class="check-cell-header">Measure</th>
                <th class="check-cell-header">
                  Series
                  <span
                    class="glyphicon glyphicon-question-sign"
                    matTooltip="For a measure series, group them together in order and click-drag down from the first
                                    column in the series (populating the number of the first column in the series)."
                  ></span>
                </th>
                <th *ngIf="project.dataformat.granularity === 'strainsex'" class="check-cell-header"># of Mice</th>
                <th *ngIf="project.dataformat.granularity === 'strainsex'" class="check-cell-header">Std Err</th>
                <th *ngIf="project.dataformat.granularity === 'strainsex'" class="check-cell-header">Std Dev</th>
              </tr>
            </thead>
            <tbody
              (mouseleave)="clickDrag = ''; orderSwitchIndex = null; clickDragLastHandled = null"
              (mouseup)="clickDrag = ''; orderSwitchIndex = null; clickDragLastHandled = null"
            >
              <tr
                *ngFor="let def of project.datadefs; let i = index"
                (mouseenter)="handleClickDrag(i)"
                [class]="'check-row' + (checkNumbersEqual(orderSwitchIndex, i) ? ' row-highlight' : '')"
                [style.pointer-events]="!project.canEdit ? 'none' : 'auto'"
                [style.background]="!project.canEdit ? '#eee' : ''"
              >
                <td style="width: 22%" class="row-hover-highlight">
                  <span *ngIf="setCheckZero(project.datadefs[i].series_parent_column)">
                    <span *ngIf="isSeriesParent(i)">
                      Series:
                      <input
                        [id]="'datadef_s_varname_' + i"
                        [(ngModel)]="project.datadefs[i].s_varname"
                        [style.display]="checkNumbersEqual(editingSVarname, i) ? 'block' : 'none'"
                        type="text"
                        (keyup)="project.datadefs[i].s_varname = varnameClean(project.datadefs[i].s_varname)"
                        (change)="checkChangeMatchingVarname(project.datadefs[i].s_varname, 'datadef_s_varname', i)"
                        class="form-control"
                        maxlength="40"
                        (focusout)="editingSVarname = null"
                      />
                      <span *ngIf="!checkNumbersEqual(editingSVarname, i)">{{ project.datadefs[i].s_varname }}</span>
                      <span
                        class="glyphicon glyphicon-pencil hover-icon"
                        *ngIf="dataDefSeriesVarnameShowPencil(i)"
                        matTooltip="Click to edit series variable name."
                        (click)="editingSVarname = i; focusVarnameInput('datadef_s_varname_' + i)"
                      ></span>
                      <br />
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                  <span
                    class="glyphicon glyphicon-resize-vertical hover-icon"
                    style="cursor: grab"
                    matTooltip="Click and drag to re-order rows (this may be needed to get measures in a series
                                    grouped together)"
                    (mousedown)="orderSwitchIndex = i; clickDragLastHandled = i"
                  ></span>
                  {{ project.datadefs[i].column + 1 + ': ' }}
                  <input
                    [id]="'datadef_varname_' + i"
                    [(ngModel)]="project.datadefs[i].varname"
                    [style.display]="checkNumbersEqual(editingVarname, i) ? 'block' : 'none'"
                    type="text"
                    (keyup)="project.datadefs[i].varname = varnameClean(project.datadefs[i].varname)"
                    (change)="checkChangeMatchingVarname(project.datadefs[i].varname, 'datadef_varname', i)"
                    class="form-control"
                    maxlength="40"
                    (focusout)="editingVarname = null"
                  />
                  <span *ngIf="!checkNumbersEqual(editingVarname, i)">{{ project.datadefs[i].varname }}</span>
                  <span
                    class="glyphicon glyphicon-pencil hover-icon"
                    *ngIf="dataDefVarnameShowPencil(i)"
                    matTooltip="Click to edit variable name."
                    (click)="editingVarname = i; focusVarnameInput('datadef_varname_' + i)"
                  ></span>
                </td>
                <td
                  [class]="'check-cell' + (propDisabled(i, 'datatype') ? ' disabled' : '')"
                  [matTooltip]="
                    propDisabled(i, 'datatype') ? def.param + ' columns are required to be ' + def.datatype + '.' : ''
                  "
                  (mousedown)="handleMouseDown(i, 'datatype')"
                >
                  <span style="color: green"
                    ><b>{{ project.datadefs[i].datatype }}</b></span
                  >
                </td>
                <td [class]="'check-cell check-cell-select'" style="padding: 0">
                  <select
                    class="form-control"
                    [(ngModel)]="project.datadefs[i].param"
                    (change)="paramSelect(i)"
                    style="cursor: pointer"
                  >
                    <option [value]=""></option>
                    <option
                      *ngIf="project.dataformat.granularity !== 'strainsex'"
                      [disabled]="setCheckZero(project.dataformat.idcol)"
                      [value]="'ID'"
                    >
                      ID
                    </option>
                    <option [disabled]="setCheckZero(project.dataformat.straincol)" [value]="'Strain'">Strain *</option>
                    <option [disabled]="setCheckZero(project.dataformat.sexcol)" [value]="'Sex'">Sex *</option>
                    <option [value]="'Factor'">Factor</option>
                    <option [value]="'Covariate'">Covariate</option>
                    <option [value]="'Informational'">Informational</option>
                  </select>
                </td>
                <td
                  [class]="'check-cell' + (propDisabled(i, 'is_phenotype') ? ' disabled' : '')"
                  (mousedown)="handleMouseDown(i, 'is_phenotype')"
                >
                  <span
                    *ngIf="project.datadefs[i].is_phenotype"
                    style="color: green"
                    class="glyphicon glyphicon-ok"
                  ></span>
                </td>
                <td
                  [class]="'check-cell' + (propDisabled(i, 'series_parent_column') ? ' disabled' : '')"
                  (mousedown)="handleMouseDown(i, 'series_parent_column')"
                >
                  <span *ngIf="setCheckZero(project.datadefs[i].series_parent_column)">
                    <span style="color: green">
                      <b>{{ project.datadefs[i].series_parent_column + 1 }}</b>
                    </span>
                    <span
                      matTooltip="Click and drag here to copy this value down to other rows."
                      class="glyphicon glyphicon-arrow-down hover-icon"
                      (mousedown)="seriesArrowClick = true"
                      style="cursor: grab"
                    ></span>
                  </span>
                </td>
                <td
                  *ngIf="project.dataformat.granularity === 'strainsex'"
                  style="padding: 0"
                  [class]="'check-cell check-cell-select' + (propDisabled(i, 'n_mice') ? ' disabled' : '')"
                >
                  <div *ngIf="!propDisabled(i, 'n_mice')" style="display: table">
                    <div style="display: table-row">
                      <div style="display: table-cell">
                        <select
                          style="cursor: pointer"
                          (change)="
                            project.datadefs[i].n_mice = setCheckZero(project.datadefs[i].n_mice)
                              ? project.datadefs[i].n_mice
                              : null
                          "
                          class="form-control"
                          [(ngModel)]="project.datadefs[i].n_mice"
                        >
                          <option value=""></option>
                          <option *ngFor="let col of sortedDatadefs(); let c = index" [value]="c">
                            {{ addOne(c) + ': ' + col.varname }}
                          </option>
                        </select>
                      </div>
                      <div style="display: table-cell">
                        <span
                          class="glyphicon glyphicon-resize-vertical hover-icon"
                          style="cursor: grab"
                          matTooltip="Click and drag here to copy this value to other rows."
                          (mousedown)="handleMouseDown(i, 'n_mice')"
                        ></span>
                      </div>
                    </div>
                  </div>
                </td>
                <td
                  *ngIf="project.dataformat.granularity === 'strainsex'"
                  style="padding: 0"
                  [class]="'check-cell check-cell-select' + (propDisabled(i, 'std_err') ? ' disabled' : '')"
                >
                  <select
                    *ngIf="!propDisabled(i, 'std_err')"
                    (change)="
                      project.datadefs[i].std_err = setCheckZero(project.datadefs[i].std_err)
                        ? project.datadefs[i].std_err
                        : null
                    "
                    class="form-control"
                    [(ngModel)]="project.datadefs[i].std_err"
                  >
                    <option value=""></option>
                    <option *ngFor="let col of sortedDatadefs(); let c = index" [value]="c">
                      {{ addOne(c) + ': ' + col.varname }}
                    </option>
                  </select>
                </td>
                <td
                  *ngIf="project.dataformat.granularity === 'strainsex'"
                  style="padding: 0"
                  [class]="'check-cell check-cell-select' + (propDisabled(i, 'std_dev') ? ' disabled' : '')"
                >
                  <select
                    *ngIf="!propDisabled(i, 'std_dev')"
                    style="cursor: pointer"
                    (change)="
                      project.datadefs[i].std_dev = setCheckZero(project.datadefs[i].std_dev)
                        ? project.datadefs[i].std_dev
                        : null
                    "
                    class="form-control"
                    [(ngModel)]="project.datadefs[i].std_dev"
                  >
                    <option value=""></option>
                    <option *ngFor="let col of sortedDatadefs(); let c = index" [value]="c">
                      {{ addOne(c) + ': ' + col.varname }}
                    </option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </fieldset>
        <br />
        <div *ngIf="setCheckZero(project.dataformat.straincol)" class="form-horizontal">
          <div class="form-group">
            <div class="col-sm-12">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title> Strain Value Mappings </mat-panel-title>
                    <mat-panel-description>
                      {{ project.strainmaps.length }} mappings
                      <span *ngIf="unpopulatedStrainMapsCount() > 0"
                        >, {{ unpopulatedStrainMapsCount() }} not populated</span
                      >
                      <span
                        style="color: red"
                        *ngIf="project.strainmaps.length > fileColVals(project.dataformat.straincol).length"
                      >
                        &nbsp;({{ project.strainmaps.length - fileColVals(project.dataformat.straincol).length }} for
                        values not in the column)
                      </span>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <hr />
                  <div
                    class="form-group"
                    *ngIf="
                      project.canEdit && project.strainmaps.length > fileColVals(project.dataformat.straincol).length
                    "
                  >
                    <label class="col-md-12">
                      <span
                        matTooltip="Remove mappings for all values not in column"
                        class="def-icon def-remove glyphicon glyphicon-remove"
                        (click)="removeExtraStrainMappings()"
                      ></span>
                      Remove mappings for all values not in column
                    </label>
                  </div>
                  <div class="form-group" *ngFor="let mapping of project.strainmaps; let i = index">
                    <label class="control-label col-md-2">
                      <span
                        *ngIf="
                          project.canEdit &&
                          fileColVals(project.dataformat.straincol).indexOf(project.strainmaps[i].input) === -1
                        "
                        matTooltip="Remove mapping (this value is not currently found in this column)"
                        class="def-icon def-remove glyphicon glyphicon-remove"
                        (click)="project.strainmaps.splice(i, 1)"
                      ></span>
                      Data Value
                    </label>
                    <div style="padding-top: 7px" class="col-md-4">
                      <span>{{ project.strainmaps[i].input }}</span>
                    </div>
                    <label
                      [for]="'map_' + project.dataformat.straincol"
                      class="required no-wrap control-label col-md-2"
                    >
                      MPD Strain
                    </label>
                    <div class="col-md-4">
                      <strain-search
                        [(strain)]="project.strainmaps[i].strain"
                        [showDialog]="true"
                        [disabled]="!project.canEdit"
                      ></strain-search>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <br /><br />
          </div>
        </div>
        <div *ngIf="setCheckZero(project.dataformat.sexcol)" class="form-horizontal">
          <div class="form-group">
            <div class="col-sm-12">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title> Sex Value Mappings </mat-panel-title>
                    <mat-panel-description>
                      {{ project.sexmaps.length }} mappings
                      <span
                        style="color: red"
                        *ngIf="project.sexmaps.length > fileColVals(project.dataformat.sexcol).length"
                      >
                        &nbsp;({{ project.sexmaps.length - fileColVals(project.dataformat.sexcol).length }} for values
                        not in the column)
                      </span>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <fieldset [disabled]="!project.canEdit">
                    <hr />
                    <div
                      class="form-group"
                      *ngIf="project.canEdit && project.sexmaps.length > fileColVals(project.dataformat.sexcol).length"
                    >
                      <label class="col-md-12">
                        <span
                          matTooltip="Remove mappings for all values not in column"
                          class="def-icon def-remove glyphicon glyphicon-remove"
                          (click)="removeExtraSexMappings()"
                        ></span>
                        Remove mappings for all values not in column
                      </label>
                    </div>

                    <div class="form-group" *ngFor="let mapping of project.sexmaps; let i = index">
                      <label class="control-label col-md-2">
                        <span
                          *ngIf="
                            project.canEdit &&
                            fileColVals(project.dataformat.sexcol).indexOf(project.sexmaps[i].input) === -1
                          "
                          matTooltip="Remove mapping (this value is not currently found in this column)"
                          class="def-icon def-remove glyphicon glyphicon-remove"
                          (click)="project.sexmaps.splice(i, 1)"
                        ></span>
                        Data Value
                      </label>
                      <div style="padding-top: 7px" class="col-md-4">
                        <span>{{ project.sexmaps[i].input }}</span>
                      </div>
                      <label [for]="'map_sexes'" class="required no-wrap control-label col-md-2">MPD Sex Value</label>
                      <div class="col-md-4">
                        <select [(ngModel)]="project.sexmaps[i].sex" class="form-control" [id]="'map_sexes'">
                          <option value="f">f (female)</option>
                          <option value="m">m (male)</option>
                          <option *ngIf="project.dataformat.granularity === 'strainsex'" value="p">p (pooled)</option>
                        </select>
                      </div>
                    </div>
                  </fieldset>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <br /><br />
          </div>
        </div>
      </div>
      <div *ngIf="fileUploaded()">
        <hr />
        <div align="center">
          <b>Data Last Processed:</b>&nbsp;&nbsp;
          <span
            *ngIf="project.dataformat.changesinceprocessed || !project.dataformat.lastprocessed"
            class="glyphicon glyphicon-warning-sign"
            style="color: red"
            [matTooltip]="
              project.dataformat.changesinceprocessed
                ? 'The data processed is not up-to-date with the data definitions.'
                : ''
            "
          ></span>
          <span
            *ngIf="!(project.dataformat.changesinceprocessed || !project.dataformat.lastprocessed)"
            class="glyphicon glyphicon-ok-circle"
            style="color: green"
            matTooltip="The data processed is up-to-date with the data definitions."
          ></span>
          &nbsp;
          <span
            [innerHtml]="
              project.dataformat.lastprocessed
                ? (buildDateTimeHtml(project.dataformat.lastprocessed) | SafeHTML)
                : 'Never'
            "
          ></span>
          &nbsp;&nbsp;
          <a
            *ngIf="project.dataformat.lastprocessed"
            style="cursor: pointer"
            (click)="state_buttons_component.openExternalUrlAfterSave(previewURL + 'projects/' + project.projsym)"
          >
            Preview Data
          </a>
          <br /><br />
        </div>
        <div class="form-horizontal">
          <div class="form-group">
            <div class="col-md-4 col-sm-3"></div>
            <div class="col-md-8 col-sm-9">
              <mat-radio-group
                *ngIf="project.pheno_measures.length > 0"
                id="current_measures"
                name="current_measures"
                [disabled]="!project.canEdit"
                [(ngModel)]="currentmeasures"
              >
                <mat-radio-button [value]="''">
                  Only add new variables (don't modify existing variables or their data)
                  <span
                    class="glyphicon glyphicon-question-sign"
                    matTooltip="'Add function'. Re-upload a revised data file with new columns added,
                                    or process new columns in the current data file. Ignores any changes
                                    made to the previously uploaded columns or rows. Ignores added or deleted
                                    rows for existing variables."
                  ></span>
                </mat-radio-button>
                <br />
                <mat-radio-button value="update">
                  Replace data for existing variables with same name (also adds new variables)
                  <span
                    class="glyphicon glyphicon-question-sign"
                    matTooltip="'Update function'. Re-upload the data file with changes.
                                    New rows and columns can be added or deleted. Changes can be made to
                                    previously uploaded rows. The metadata for existing variables,
                                    such as description, method, treatment, etc, is maintained."
                  ></span>
                </mat-radio-button>
                <br />
                <mat-radio-button
                  [disabled]="!project.createdtime || !project.status || project.status === 'Public'"
                  [matTooltip]="
                    !project.createdtime || !project.status || project.status === 'Public'
                      ? 'Since this project is Public, if you wish to delete any measures, you' +
                        ' must do it manually on the Current Phenotype Data step of this page.'
                      : ''
                  "
                  value="delete"
                >
                  Delete all existing phenotype variables and data before creating new data
                  <span
                    class="glyphicon glyphicon-question-sign"
                    matTooltip="'Delete function'. Removes everything and starts over
                                    with a new data file. Delete might need to be used if factor values are
                                    changed, added, or removed, since that results in different variable names
                                    being generated."
                  ></span>
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>
        <div align="center">
          <div
            *ngIf="project.canEdit"
            [matTooltip]="
              !project.dataformat.changesinceprocessed
                ? 'There have been no changes to the data definitions' +
                  (project.dataformat.lastprocessed ? ' since the data was last processed' : '') +
                  '. Note: If you have made changes, then you must save them for them to be detected.'
                : ''
            "
          >
            <button class="btn btn-primary" [disabled]="processingData || analyzeNum" (click)="processData()">
              Process Data
            </button>
          </div>
          <div *ngIf="processingData || analyzeNum">
            <br />
            <b>{{
              processingData ? 'Processing...' : 'Analyzing (' + analyzeCompletedNum + '/' + analyzeNum + ')...'
            }}</b>
            <mat-progress-bar
              [mode]="processingData ? 'indeterminate' : 'determinate'"
              [value]="analyzeNum ? (analyzeCompletedNum * 100) / analyzeNum : 50"
            ></mat-progress-bar>
          </div>
          <br />
          <span *ngIf="processErrorMessage" style="color: red"><b>Error:</b>&nbsp;&nbsp;{{ processErrorMessage }}</span>
        </div>
        <ul *ngIf="processMessages.length > 0">
          <li
            *ngFor="let msg of processMessages; let i = index"
            [style.color]="i === 0 ? 'green' : '#AAAA00'"
            [style.display]="i === 0 || showProcessMessages ? 'list-item' : 'none'"
          >
            {{ msg }}
            <a
              *ngIf="i === 0 && processMessages.length > 1"
              style="cursor: pointer"
              (click)="showProcessMessages = !showProcessMessages"
            >
              {{
                showProcessMessages ? 'Hide Other Messages' : 'Show ' + (processMessages.length - 1) + ' Other Messages'
              }}
            </a>
            <span *ngIf="i === 0 && project.pheno_measures.length > 0">
              &nbsp;&nbsp;
              <button style="padding: 0; border: 0; margin-bottom: 2px" class="btn btn-link" matStepperNext>
                View/Edit Current Data
              </button>
            </span>
          </li>
        </ul>
      </div>
      <br />
      <div class="form-horizontal">
        <div class="form-group">
          <div class="col-sm-4 col-md-5"></div>
          <div class="col-sm-3 col-md-2">
            <button class="btn btn-info btn-block" matStepperNext id="step-1-next"><strong>Next</strong></button>
          </div>
        </div>
      </div>
    </mat-step>
    <mat-step *ngIf="project.pheno_measures.length > 0">
      <ng-template matStepLabel>
        <span>Current Phenotype Data ({{ project.pheno_measures.length }})</span>
      </ng-template>
      <br />
      <div class="form-horizontal">
        <div class="form-group">
          <div class="col-sm-1"></div>
          <div class="col-sm-9">
            <p>
              This step is for viewing, editing, and deleting already existing measures, factors, and covariates for the
              project. New factors can also be entered and associated with measures here. You can upload and add new
              measures and covariates by
              <button style="padding: 0; border: 0; margin-bottom: 2px" class="btn btn-link" matStepperPrevious>
                uploading a data file</button
              >, filling out data definitions for the data file on the following steps, and then processing the data
              file.
            </p>
          </div>
        </div>
      </div>
      <!--Factors section-->
      <table class="table">
        <thead>
          <tr>
            <th style="border: 1px solid #ddd"><label>Factors</label></th>
            <th *ngIf="project.canEdit" width="1" style="border: 1px solid #ddd; width: 85px">
              <span
                matTooltip="Add new factor"
                (click)="createFactor()"
                class="def-icon def-add glyphicon glyphicon-plus"
              ></span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let factor of project.factors; let i = index">
            <td style="border: 1px solid #ddd">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <b
                        >Factor No. {{ i + 1 }}:
                        <span [style.color]="project.factors[i].name ? 'black' : 'red'">
                          {{ project.factors[i].name ? project.factors[i].name : '(Unnamed)' }}</span
                        >
                      </b>
                    </mat-panel-title>
                    <mat-panel-description>
                      <span [style.color]="project.factors[i].values.length === 0 ? 'red' : 'black'">
                        <b>Number of Values:</b>&nbsp;{{ project.factors[i].values.length }}</span
                      >&nbsp;
                      <span *ngIf="numFactorValuesBlank(i) > 0" style="color: red">
                        ({{ numFactorValuesBlank(i) }} blank)</span
                      >
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <hr />
                  <div class="form-horizontal">
                    <div class="form-group">
                      <label class="control-label col-md-2">Factor Info</label>
                      <div class="col-md-10" style="padding-top: 7px">
                        <span class="no-wrap">
                          &nbsp;&nbsp;&nbsp;<b>Number of Linked Variables: </b>{{ project.factors[i].num_measures }}
                        </span>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="required control-label col-md-2" [for]="'factor_name_' + i"> Name </label>
                      <div class="col-md-10">
                        <input
                          [(ngModel)]="project.factors[i].name"
                          type="text"
                          [disabled]="!project.canEdit"
                          [id]="'factor_name_' + i"
                          class="form-control"
                          maxlength="120"
                        />
                      </div>
                    </div>
                    <table class="table">
                      <thead>
                        <tr>
                          <th style="border: 1px solid #ddd"><label>Values</label></th>
                          <th width="1" style="border: 1px solid #ddd"><label># of Linked Variables</label></th>
                          <th *ngIf="project.canEdit" width="1" style="border: 1px solid #ddd; width: 85px">
                            <span
                              matTooltip="Add new value"
                              (click)="createFactorValue(i)"
                              class="def-icon def-add glyphicon glyphicon-plus"
                            ></span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let factor of project.factors[i].values; let j = index">
                          <td style="border: 1px solid #ddd">
                            <div class="form-horizontal">
                              <div class="form-group" style="margin-bottom: 0">
                                <label
                                  class="required control-label col-sm-2"
                                  [for]="'factor_' + i + '_value_' + j"
                                  style="white-space: nowrap"
                                >
                                  No. {{ j + 1 }}
                                  <span
                                    *ngIf="!project.factors[i].values[j].value"
                                    class="glyphicon glyphicon-warning-sign"
                                    style="color: red"
                                    matTooltip="Factor value is blank, please populate before saving or it will
                                                    not be saved."
                                  ></span>
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    [(ngModel)]="project.factors[i].values[j].value"
                                    type="text"
                                    [disabled]="!project.canEdit"
                                    [id]="'factor_' + i + '_value_' + j"
                                    class="form-control"
                                    maxlength="500"
                                  />
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>{{ project.factors[i].values[j].num_measures }}</td>
                          <td
                            *ngIf="project.canEdit"
                            style="border: 1px solid #ddd; vertical-align: middle; white-space: nowrap"
                          >
                            <span
                              matTooltip="Delete factor"
                              (click)="deleteFactorValue(i, j)"
                              class="def-icon def-remove glyphicon glyphicon-trash"
                            ></span>
                            <span
                              matTooltip="Move factor up"
                              (click)="swapFactorValues(i, j, j - 1)"
                              class="def-icon def-up glyphicon glyphicon-arrow-up"
                            ></span>
                            <span
                              matTooltip="Move factor down"
                              (click)="swapFactorValues(i, j, j + 1)"
                              class="def-icon def-down glyphicon glyphicon-arrow-down"
                            ></span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </td>
            <td *ngIf="project.canEdit" style="border: 1px solid #ddd; vertical-align: middle; white-space: nowrap">
              <span
                matTooltip="Delete factor"
                (click)="deleteFactor(i)"
                class="def-icon def-remove glyphicon glyphicon-trash"
              ></span>
              <span
                matTooltip="Move factor up"
                (click)="swapFactors(i, i - 1)"
                class="def-icon def-up glyphicon glyphicon-arrow-up"
              ></span>
              <span
                matTooltip="Move factor down"
                (click)="swapFactors(i, i + 1)"
                class="def-icon def-down glyphicon glyphicon-arrow-down"
              ></span>
            </td>
          </tr>
        </tbody>
      </table>
      <br /><br />
      <table *ngIf="project.pheno_measures.length > 0" class="table">
        <thead>
          <tr>
            <th style="border: 1px solid #ddd">
              <label>Variables (Measurements, Covariates, and Informational Data)</label>
              <mat-accordion *ngIf="project.canEdit">
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <b>
                        Update Variable Metadata in Bulk
                        <span
                          class="glyphicon glyphicon-question-sign"
                          matTooltip="Use this section to update metadata on several variables at once. This section
                                          cannot be used to clear out metadata values (blank values will be ignored).
                                          Any metadata fields that are not included in this section (Variable Names,
                                          Descriptions, Factors, etc) must be updated on each variable individually."
                        ></span>
                      </b>
                    </mat-panel-title>
                    <mat-panel-description> </mat-panel-description>
                  </mat-expansion-panel-header>
                  <hr />
                  <div class="form-horizontal">
                    <div class="form-group">
                      <label class="required control-label col-md-2"> Variable Type(s) </label>
                      <div class="col-md-10">
                        <label style="cursor: pointer; padding-top: 7px; white-space: nowrap">
                          <input [(ngModel)]="bulkUpdate.is_measure" type="checkbox" />
                          &nbsp;Measure </label
                        >&nbsp;&nbsp;&nbsp;
                        <label style="cursor: pointer; padding-top: 7px; white-space: nowrap">
                          <input [(ngModel)]="bulkUpdate.is_covar" type="checkbox" />
                          &nbsp;Covariate
                          <span
                            class="glyphicon glyphicon-question-sign"
                            matTooltip="Covariates must be numeric."
                          ></span>
                        </label>
                        &nbsp;&nbsp;&nbsp;
                        <label style="cursor: pointer; padding-top: 7px; white-space: nowrap">
                          <input [(ngModel)]="bulkUpdate.private" type="checkbox" />
                          &nbsp;Private
                          <span
                            class="glyphicon glyphicon-question-sign"
                            matTooltip="Check this to keep the measure private after the project is released.
                                        Use this if you want to release measures in the project in stages."
                          ></span>
                        </label>
                      </div>
                    </div>
                    <div class="form-group">
                      <label [for]="'bulk_seriestype'" class="required control-label col-md-2">
                        Series Type
                        <span
                          class="glyphicon glyphicon-question-sign"
                          matTooltip="Only applies to measures in a series."
                        ></span>
                      </label>
                      <div class="col-md-10">
                        <select class="form-control" [(ngModel)]="bulkUpdate.seriestype" [id]="'bulk_seriestype'">
                          <option value=""></option>
                          <option *ngFor="let seriestype of seriesTypeOptions" [value]="seriestype.tag">
                            {{ seriestype.descrip }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="required control-label col-md-2"> Method </label>
                      <div class="col-md-4">
                        <methodology-search
                          #bulk_method
                          [showDialog]="true"
                          [(methodology)]="bulkUpdate.methodology"
                          (methodologyChange)="bulkUpdate.method = $event.term ? $event.term : null"
                        >
                        </methodology-search>
                      </div>
                      <label class="control-label col-md-2"> Treatment </label>
                      <div class="col-md-4">
                        <intervention-search
                          #bulk_treatment
                          [showDialog]="true"
                          [(intervention)]="bulkUpdate.intervention_rel"
                          (interventionChange)="bulkUpdate.intervention = $event.term ? $event.term : null"
                        >
                        </intervention-search>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="required control-label col-md-2" [for]="'bulk_mpdsector'"> Data Sector </label>
                      <div class="col-md-4">
                        <select
                          [(ngModel)]="bulkUpdate.mpdsector"
                          class="form-control"
                          [id]="'bulk_mpdsector'"
                          [name]="'bulk_mpdsector'"
                        >
                          <option value=""></option>
                          <option value="css">Strain survey phenotype</option>
                          <option value="doutbred">Diversity Outbred phenotype</option>
                          <option value="komp">Mutant/control phenotype</option>
                          <option value="survival">Survival phenotype</option>
                          <!--This is a legacy value, so commenting it out for now
                              (but leaving it here in case we want it later)-->
                          <!--<option disabled value="qtla">QTL phenotype</option>-->
                        </select>
                      </div>
                      <label class="required control-label col-md-2" [for]="'bulk_panel'"> Panel </label>
                      <div class="col-md-4">
                        <combobox
                          [options]="panelOptions"
                          [(value)]="bulkUpdate.paneldesc"
                          [placeholder]="'Begin typing to narrow down options or enter custom value'"
                          [id]="'bulk_panel'"
                          [maxlength]="40"
                        ></combobox>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="control-label col-md-2" [for]="'bulk_units'"> Units </label>
                      <div class="col-md-4">
                        <combobox
                          [htmlOptions]="true"
                          [options]="unitsOptions"
                          [(value)]="bulkUpdate.units"
                          [id]="'bulk_units'"
                          [maxlength]="40"
                          [placeholder]="'Begin typing to narrow down options or enter custom value'"
                          [htmlAllow]="['&', 'sup']"
                        ></combobox>
                      </div>
                      <label class="control-label col-md-2" [for]="'bulk_unitmagnitude'">
                        Axis Label Suffix (Magnitude)
                        <span
                          class="glyphicon glyphicon-question-sign"
                          matTooltip='e.g., for (x10^3), enter "3"'
                          [matTooltipPosition]="position"
                          matTooltipHideDelay="2000"
                        ></span>
                      </label>
                      <div class="col-md-4">
                        <input
                          type="number"
                          [(ngModel)]="bulkUpdate.magnitude"
                          (change)="bulkUpdate.magnitude = forceRange(bulkUpdate.magnitude, -9999, 9999)"
                          [id]="'bulk_unitmagnitude'"
                          class="form-control"
                          min="-9999"
                          max="9999"
                        />
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="control-label col-md-2" [for]="'bulk_ageweeks'"> Age (wks) </label>
                      <div class="col-md-4">
                        <input
                          [(ngModel)]="bulkUpdate.ageweeks"
                          type="text"
                          [id]="'bulk_ageweeks'"
                          class="form-control"
                          maxlength="40"
                        />
                      </div>
                      <label class="required control-label col-md-2" [for]="'bulk_projyear'"> Project Year </label>
                      <div class="col-md-4">
                        <input
                          [(ngModel)]="bulkUpdate.projyear"
                          type="text"
                          [id]="'bulk_projyear'"
                          class="form-control"
                          maxlength="10"
                        />
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="control-label col-md-2" [for]="'buld_download_file'">
                        Download File
                        <span
                          class="glyphicon glyphicon-question-sign"
                          matTooltip="File to download when viewing the measure in MPD.
                                      Selection list comes from supplementary files"
                          [matTooltipPosition]="position"
                          matTooltipHideDelay="2000"
                        ></span>
                      </label>
                      <div class="col-md-4">
                        <select class="form-control" [(ngModel)]="bulkUpdate.download_fileid">
                          <option value=""></option>
                          <option
                            *ngFor="let file of supplementaryFileUpload.uploadedFiles;let v = index"
                            [value]="file.id"
                          >
                            {{ file.filename }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <!--Ontologies section-->
                    <div class="form-group">
                      <div class="col-sm-12">
                        <mat-accordion>
                          <mat-expansion-panel>
                            <mat-expansion-panel-header>
                              <mat-panel-title>
                                <label>Ontology Mappings</label>
                              </mat-panel-title>
                              <mat-panel-description>
                                {{ buildOntologiesDescrip(bulkUpdate.ontologies) }}
                              </mat-panel-description>
                            </mat-expansion-panel-header>
                            <hr />
                            <div class="form-group" *ngFor="let ontology of bulkUpdate.ontologies; let o = index">
                              <label class="control-label col-md-2">Ontology</label>
                              <div class="col-md-10">
                                <ontology-search
                                  [(ontology)]="bulkUpdate.ontologies[o]"
                                  (ontologyChange)="removeEmptyOntology(bulkUpdate.ontologies, o)"
                                ></ontology-search>
                              </div>
                            </div>
                            <div class="form-group">
                              <label class="control-label col-md-2">Add Ontology</label>
                              <div class="col-md-10">
                                <ontology-search
                                  #ont_search
                                  [disableList]="bulkUpdate.ontologies"
                                  (ontologyChange)="
                                    ontologyChange($event, bulkUpdate.ontologies) ? ont_search.clearSelection() : false
                                  "
                                >
                                </ontology-search>
                              </div>
                            </div>
                          </mat-expansion-panel>
                        </mat-accordion>
                      </div>
                    </div>
                    <!--Procedures section-->
                    <div class="form-group">
                      <div class="col-sm-12">
                        <mat-accordion>
                          <mat-expansion-panel>
                            <mat-expansion-panel-header>
                              <mat-panel-title>
                                <label>Procedures</label>
                              </mat-panel-title>
                              <mat-panel-description>
                                {{ buildMeasProcsDescrip(bulkUpdate.meas_procs) }}
                              </mat-panel-description>
                            </mat-expansion-panel-header>
                            <hr />
                            <div class="form-group" *ngFor="let meas_proc of bulkUpdate.meas_procs; let o = index">
                              <label class="control-label col-md-2">Procedure</label>
                              <div class="col-md-10">
                                <procedure-search
                                  [(procedure)]="bulkUpdate.meas_procs[o]"
                                  [inputOptions]="project.procedures"
                                  (procedureChange)="removeEmptyMeasProc(bulkUpdate.meas_procs, o)"
                                ></procedure-search>
                              </div>
                            </div>
                            <div class="form-group">
                              <label class="control-label col-md-2">Add Procedure</label>
                              <div class="col-md-10">
                                <procedure-search
                                  #proc_search
                                  [disableList]="bulkUpdate.meas_procs"
                                  [inputOptions]="project.procedures"
                                  (procedureChange)="
                                    measProcChange($event, bulkUpdate.meas_procs) ? proc_search.clearSelection() : false
                                  "
                                >
                                </procedure-search>
                              </div>
                            </div>
                          </mat-expansion-panel>
                        </mat-accordion>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="control-label col-md-2" [for]="'bulk_applyTo'">
                        Select variables to apply these updates to
                      </label>
                      <div class="col-md-2">
                        <select
                          [(ngModel)]="bulkUpdate.applyTo"
                          class="form-control"
                          [id]="'bulk_applyTo'"
                          [name]="'bulk_applyTo'"
                        >
                          <option value="all">All ({{ project.pheno_measures.length }})</option>
                          <option value="selected">Selected</option>
                        </select>
                      </div>
                      <div class="col-md-8">
                        <select
                          [(ngModel)]="bulkUpdate.measnums"
                          class="form-control"
                          [disabled]="bulkUpdate.applyTo !== 'selected'"
                          multiple
                          style="height: 128px; resize: vertical"
                          [id]="'bulk_measnums'"
                          [name]="'bulk_measnums'"
                        >
                          <option *ngFor="let meas of project.pheno_measures; let i = index" [value]="meas.measnum">
                            {{ 'No. ' + (i + 1) + ': ' + meas.varname + ' (ID: ' + meas.measnum + ')' }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="col-md-3"></div>
                      <div
                        class="col-md-3"
                        [matTooltip]="
                          applyBulkUpdateDisabled()
                            ? 'Metadata values and variables to apply them to must both be selected to apply the update'
                            : ''
                        "
                      >
                        <button
                          class="btn btn-primary btn-block"
                          [disabled]="applyBulkUpdateDisabled()"
                          (click)="applyBulkUpdate()"
                        >
                          Apply Update
                        </button>
                      </div>
                      <div class="col-md-3">
                        <button
                          class="btn btn-danger btn-block"
                          [disabled]="clearBulkUpdateDisabled()"
                          (click)="
                            bulk_method.clearSelection();
                            bulk_treatment.clearSelection();
                            bulkUpdate = {
                              methodology: {},
                              intervention_rel: {},
                              ontologies: [],
                              meas_procs: [],
                              measnums: [],
                              applyTo: 'all'
                            }
                          "
                        >
                          Clear Selections
                        </button>
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
              <div
                *ngIf="project.pheno_measures.length > 5"
                id="data_pager"
                class="pager"
                style="display: inline-block"
              >
                <br />
                <button
                  type="button"
                  class="first"
                  id="data_firstPage"
                  title="First Page"
                  (click)="currentPageIn = currentPage = 1"
                >
                  <<
                </button>
                <button
                  type="button"
                  class="prev"
                  id="data_prevPage"
                  title="Previous Page"
                  (click)="currentPageIn = currentPage = Math.max(1, currentPage - 1)"
                >
                  <
                </button>
                Page
                <input
                  type="number"
                  [(ngModel)]="currentPageIn"
                  [max]="maxPage"
                  min="1"
                  style="width: 50px; display: inline-block"
                  (change)="currentPageIn = currentPage = Math.max(1, Math.min(currentPageIn, maxPage))"
                />
                / {{ maxPage }}
                <button
                  type="button"
                  class="next"
                  id="data_nextPage"
                  title="Next Page"
                  (click)="currentPageIn = currentPage = Math.min(currentPage + 1, maxPage)"
                >
                  >
                </button>
                <button
                  type="button"
                  class="last"
                  id="data_lastPage"
                  title="Last Page"
                  (click)="currentPageIn = currentPage = maxPage"
                >
                  >>
                </button>
                &nbsp;(Showing
                {{
                  (currentPage - 1) * pageSize +
                    1 +
                    ' - ' +
                    (pageSizeIn === 'all'
                      ? project.pheno_measures.length
                      : Math.min(currentPage * pageSize, project.pheno_measures.length)) +
                    ' of ' +
                    project.pheno_measures.length
                }}) &nbsp;
                <select id="data_pagesize" [(ngModel)]="pageSizeIn" (change)="recalcPages()">
                  <option [value]="5">5</option>
                  <option [value]="10">10</option>
                  <option [value]="25">25</option>
                  <option [value]="'all'">all</option>
                </select>
              </div>
            </th>
            <th *ngIf="project.canEdit" width="1" style="border: 1px solid #ddd"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let i of range(
                (currentPage - 1) * pageSize,
                (pageSizeIn === 'all'
                  ? project.pheno_measures.length
                  : Math.min(currentPage * pageSize, project.pheno_measures.length)) - 1
              )
            "
          >
            <td style="border: 1px solid #ddd">
              <mat-accordion>
                <mat-expansion-panel [expanded]="false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <b>No. {{ i + 1 }}: {{ variableCategories(i) }}, ID: {{ project.pheno_measures[i].measnum }}</b
                      >&nbsp;
                      <a
                        *ngIf="project.pheno_measures[i].is_measure"
                        matTooltip="Preview this measure in MPD"
                        (click)="
                          state_buttons_component.openExternalUrlAfterSave(
                            previewURL +
                              (project.pheno_measures[i].seriestype ? 'measureset/' : 'measures/') +
                              project.pheno_measures[i].measnum
                          )
                        "
                      >
                        <span class="glyphicon glyphicon-link"></span>
                      </a>
                      <mat-icon
                        *ngIf="project.pheno_measures[i].private"
                        class="def-icon"
                        [matTooltip]="
                          'This ' +
                          variableCategories(i, true, true) +
                          ' is currently private. ' +
                          'It will remain private after the rest of the project is released to the public.'
                        "
                        >lock</mat-icon
                      >
                    </mat-panel-title>
                    <mat-panel-description>
                      <span *ngIf="!project.pheno_measures[i].seriestype"><b>Simple</b></span>
                      <span *ngIf="project.pheno_measures[i].seriestype">
                        <b>Series</b>, {{ project.pheno_measures[i].pheno_series.length }} individual variable{{
                          project.pheno_measures[i].pheno_series.length !== 1 ? 's' : ''
                        }}
                      </span>
                      &nbsp;&nbsp;&nbsp;
                      <span><b>Name:</b>&nbsp;{{ project.pheno_measures[i].varname }}</span>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <hr />
                  <ng-template matExpansionPanelContent>
                    <div class="form-horizontal">
                      <fieldset [disabled]="!project.canEdit">
                        <div class="form-group">
                          <label class="control-label col-md-2">{{ variableCategories(i, true) }} Info</label>
                          <div class="col-md-10" style="padding-top: 7px">
                            &nbsp;&nbsp;
                            <span class="no-wrap">
                              <b>Type: </b>
                              {{ project.pheno_measures[i].seriestype ? 'Series' : 'Simple' }} </span
                            >&nbsp;&nbsp;&nbsp;
                            <span class="no-wrap">
                              <b>Granularity: </b>
                              {{ project.pheno_measures[i].granularity === 'animal' ? 'Animals' : 'Strain Means' }}
                            </span>
                            &nbsp;&nbsp;&nbsp;
                            <span class="no-wrap">
                              <b>Data Type: </b>
                              {{ project.pheno_measures[i].datatype === 'categorical' ? 'Categorical' : 'Numeric' }}
                            </span>
                            <span class="pre-wrap" *ngIf="project.pheno_measures[i].native_method"
                              >&nbsp;&nbsp;&nbsp;</span
                            >
                            <span class="no-wrap" *ngIf="project.pheno_measures[i].native_method">
                              <b>Native Method: </b>{{ project.pheno_measures[i].native_method }}
                            </span>
                            <span class="pre-wrap" *ngIf="project.pheno_measures[i].sourceparm">
                              &nbsp;&nbsp;&nbsp;
                            </span>
                            <span class="no-wrap" *ngIf="project.pheno_measures[i].sourceparm">
                              <b>Source Parameter: </b>{{ project.pheno_measures[i].sourceparm }}
                            </span>
                            <span class="pre-wrap" *ngIf="project.pheno_measures[i].sextested">&nbsp;&nbsp;&nbsp;</span>
                            <span class="no-wrap" *ngIf="project.pheno_measures[i].sextested">
                              <b>Sexes Tested: </b>
                              {{
                                project.pheno_measures[i].sextested === 'f'
                                  ? 'Females'
                                  : project.pheno_measures[i].sextested === 'm'
                                  ? 'Males'
                                  : 'Both'
                              }}
                            </span>
                            <span class="pre-wrap" *ngIf="project.pheno_measures[i].nstrainstested"
                              >&nbsp;&nbsp;&nbsp;</span
                            >
                            <span class="no-wrap" *ngIf="project.pheno_measures[i].nstrainstested">
                              <b># of Strains Tested: </b>{{ project.pheno_measures[i].nstrainstested }}
                            </span>
                            <span class="pre-wrap" *ngIf="project.pheno_measures[i].avgsampsize">
                              &nbsp;&nbsp;&nbsp;
                            </span>
                            <span class="no-wrap" *ngIf="project.pheno_measures[i].avgsampsize">
                              <b>Average Sample Size: </b>{{ project.pheno_measures[i].avgsampsize }} </span
                            >&nbsp;&nbsp;&nbsp;
                            <span class="no-wrap">
                              <b># of Data Points: </b>{{ project.pheno_measures[i].datapointcount }}
                            </span>
                            <span class="pre-wrap" *ngIf="project.pheno_measures[i].f_do_animals_count"
                              >&nbsp;&nbsp;&nbsp;</span
                            >
                            <span class="no-wrap" *ngIf="project.pheno_measures[i].f_do_animals_count">
                              <b># of DO Females: </b>{{ project.pheno_measures[i].f_do_animals_count }}
                            </span>
                            <span class="pre-wrap" *ngIf="project.pheno_measures[i].m_do_animals_count"
                              >&nbsp;&nbsp;&nbsp;</span
                            >
                            <span class="no-wrap" *ngIf="project.pheno_measures[i].m_do_animals_count">
                              <b># of DO Males: </b>{{ project.pheno_measures[i].m_do_animals_count }}
                            </span>
                          </div>
                        </div>
                        <div class="form-group">
                          <label [for]="'pmeas_vartype_' + i" class="required control-label col-md-2">
                            Variable Type(s)
                          </label>
                          <div class="col-md-10">
                            <label style="cursor: pointer; padding-top: 7px; white-space: nowrap">
                              <input [(ngModel)]="project.pheno_measures[i].is_measure" type="checkbox" />
                              &nbsp;Measure </label
                            >&nbsp;&nbsp;&nbsp;
                            <label
                              *ngIf="project.pheno_measures[i].datatype !== 'categorical'"
                              style="cursor: pointer; padding-top: 7px; white-space: nowrap"
                            >
                              <input [(ngModel)]="project.pheno_measures[i].is_covar" type="checkbox" />
                              &nbsp;Covariate
                            </label>
                            &nbsp;&nbsp;&nbsp;
                            <label style="cursor: pointer; padding-top: 7px; white-space: nowrap">
                              <input [(ngModel)]="project.pheno_measures[i].private" type="checkbox" />
                              &nbsp;Private
                              <span
                                class="glyphicon glyphicon-question-sign"
                                matTooltip="Check this to keep the measure private after the project is released.
                                            Use this if you want to release measures in the project in stages."
                              ></span>
                            </label>
                          </div>
                        </div>
                        <div *ngIf="project.pheno_measures[i].seriestype" class="form-group">
                          <label [for]="'pmeas_seriestype_' + i" class="required control-label col-md-2">
                            Series Type
                          </label>
                          <div class="col-md-10">
                            <select
                              class="form-control"
                              [(ngModel)]="project.pheno_measures[i].seriestype"
                              [id]="'pmeas_seriestype_' + i"
                            >
                              <option *ngFor="let seriestype of seriesTypeOptions" [value]="seriestype.tag">
                                {{ seriestype.descrip }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </fieldset>
                      <div class="form-group">
                        <label
                          *ngIf="project.pheno_measures[i].seriestype"
                          class="required control-label col-md-2"
                          [for]="'pmeas_varname_' + i"
                        >
                          Series Variable Name
                          <span
                            class="glyphicon glyphicon-question-sign"
                            matTooltip="Variable to identify a series of individual measures as a whole."
                            [matTooltipPosition]="position"
                            matTooltipHideDelay="2000"
                          ></span>
                        </label>
                        <label
                          *ngIf="!project.pheno_measures[i].seriestype"
                          class="required control-label col-md-2"
                          [for]="'pmeas_varname_' + i"
                        >
                          Variable Name
                        </label>
                        <div class="col-md-4">
                          <input
                            [(ngModel)]="project.pheno_measures[i].varname"
                            type="text"
                            [disabled]="currentPhenotypeVarnameDisabled(i)"
                            (keyup)="
                              project.pheno_measures[i].varname = varnameClean(project.pheno_measures[i].varname)
                            "
                            (change)="
                              checkChangeMatchingVarname(project.pheno_measures[i].varname, 'pheno_measures', i)
                            "
                            [id]="'pmeas_varname_' + i"
                            class="form-control"
                            maxlength="40"
                          />
                        </div>
                        <label class="required control-label col-md-2" [for]="'pmeas_method_' + i">
                          Method
                          <span
                            *ngIf="!project.pheno_measures[i].method && dbProject.pheno_measures[i].method"
                            class="glyphicon glyphicon-warning-sign"
                            style="color: red"
                            matTooltip="You have cleared out the Method. Since this is a required field, when you
                                            save it will be restored to its previous value."
                          ></span>
                        </label>
                        <div class="col-md-4">
                          <methodology-search
                            [showDialog]="true"
                            [disabled]="!project.canEdit"
                            [(methodology)]="project.pheno_measures[i].methodology"
                            (methodologyChange)="project.pheno_measures[i].method = $event.term ? $event.term : null"
                          >
                          </methodology-search>
                        </div>
                      </div>
                      <fieldset [disabled]="!project.canEdit">
                        <div class="form-group">
                          <label
                            *ngIf="project.pheno_measures[i].seriestype"
                            class="required control-label col-md-2"
                            [for]="'pmeas_descrip_' + i"
                          >
                            Series Description
                            <span
                              class="glyphicon glyphicon-question-sign"
                              matTooltip="Short description used to identify a series of individual measures as a
                                              whole.  Individual measure descriptions use this value as a prefix; unique
                                              postfixes for individual measures are entered in a panel below."
                              [matTooltipPosition]="position"
                              matTooltipHideDelay="2000"
                            ></span>
                            <span
                              *ngIf="!project.pheno_measures[i].descrip"
                              class="glyphicon glyphicon-warning-sign"
                              style="color: red"
                              matTooltip="Since this is a required field, when you
                                              save it will be restored to its previous value."
                            ></span>
                          </label>
                          <label
                            *ngIf="!project.pheno_measures[i].seriestype"
                            class="required control-label col-md-2"
                            [for]="'pmeas_descrip_' + i"
                          >
                            Description
                            <span
                              *ngIf="!project.pheno_measures[i].descrip"
                              class="glyphicon glyphicon-warning-sign"
                              style="color: red"
                              matTooltip="Since this is a required field, when you
                                              save it will be restored to its previous value."
                            ></span>
                          </label>
                          <div class="col-md-10">
                            <input
                              [(ngModel)]="project.pheno_measures[i].descrip"
                              type="text"
                              [id]="'pmeas_descrip_' + i"
                              class="form-control"
                              maxlength="255"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="required control-label col-md-2" [for]="'pmeas_mpdsector_' + i">
                            Data Sector
                          </label>
                          <div class="col-md-4">
                            <select
                              [(ngModel)]="project.pheno_measures[i].mpdsector"
                              class="form-control"
                              [id]="'pmeas_mpdsector_' + i"
                              [name]="'pmeas_mpdsector_' + i"
                            >
                              <option value="css">Strain survey phenotype</option>
                              <option value="doutbred">Diversity Outbred phenotype</option>
                              <option value="komp">Mutant/control phenotype</option>
                              <option value="survival">Survival phenotype</option>
                              <!--This is a legacy value, so making it only appear
                                  if it's what the mpdsector is already set to-->
                              <option *ngIf="project.pheno_measures[i].mpdsector === 'qtla'" value="qtla">
                                QTL phenotype
                              </option>
                            </select>
                          </div>
                          <label class="required control-label col-md-2" [for]="'pmeas_panel_' + i">
                            Panel
                            <span
                              *ngIf="!project.pheno_measures[i].paneldesc"
                              class="glyphicon glyphicon-warning-sign"
                              style="color: red"
                              matTooltip="Since this is a required field, when you
                                              save it will be restored to its previous value."
                            ></span>
                          </label>
                          <div class="col-md-4">
                            <combobox
                              [options]="panelOptions"
                              [(value)]="project.pheno_measures[i].paneldesc"
                              [placeholder]="'Begin typing to narrow down options or enter custom value'"
                              [id]="'pmeas_panel_' + i"
                              [maxlength]="40"
                            ></combobox>
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="control-label col-md-2" [for]="'pmeas_units_' + i"> Units </label>
                          <div class="col-md-4">
                            <combobox
                              [htmlOptions]="true"
                              [options]="unitsOptions"
                              [(value)]="project.pheno_measures[i].units"
                              [id]="'pmeas_units_' + i"
                              [maxlength]="40"
                              [placeholder]="'Begin typing to narrow down options or enter custom value'"
                              [htmlAllow]="['&', 'sup']"
                            ></combobox>
                          </div>
                          <label class="control-label col-md-2" [for]="'pmeas_unitmagnitude_' + i">
                            Axis Label Suffix (Magnitude)
                            <span
                              class="glyphicon glyphicon-question-sign"
                              matTooltip='e.g., for (x10^3), enter "3"'
                              [matTooltipPosition]="position"
                              matTooltipHideDelay="2000"
                            ></span>
                          </label>
                          <div class="col-md-4">
                            <input
                              type="number"
                              [(ngModel)]="project.pheno_measures[i].magnitude"
                              (change)="
                                project.pheno_measures[i].magnitude = forceRange(
                                  project.pheno_measures[i].magnitude,
                                  -9999,
                                  9999
                                )
                              "
                              [id]="'pmeas_unitmagnitude_' + i"
                              class="form-control"
                              min="-9999"
                              max="9999"
                            />
                          </div>
                        </div>
                      </fieldset>
                      <div class="form-group">
                        <label class="control-label col-md-2" [for]="'pmeas_intervention_' + i"> Treatment </label>
                        <div class="col-md-4">
                          <intervention-search
                            [showDialog]="true"
                            [disabled]="!project.canEdit"
                            [(intervention)]="project.pheno_measures[i].intervention_rel"
                            (interventionChange)="
                              project.pheno_measures[i].intervention = $event.term ? $event.term : null
                            "
                          >
                          </intervention-search>
                        </div>
                        <label class="control-label col-md-2" [for]="'pmeas_reference_' + i">
                          Reference ID
                          <span
                            class="glyphicon glyphicon-question-sign"
                            matTooltip="e.g., ID for external resource"
                            [matTooltipPosition]="position"
                            matTooltipHideDelay="2000"
                          ></span>
                        </label>
                        <div class="col-md-4">
                          <input
                            [(ngModel)]="project.pheno_measures[i].reference"
                            type="text"
                            maxlength="40"
                            [disabled]="!project.canEdit"
                            [id]="'pmeas_reference_' + i"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <fieldset [disabled]="!project.canEdit">
                        <div class="form-group">
                          <label class="control-label col-md-2" [for]="'pmeas_ageweeks_' + i"> Age (wks) </label>
                          <div class="col-md-4">
                            <input
                              [(ngModel)]="project.pheno_measures[i].ageweeks"
                              type="text"
                              [id]="'pmeas_ageweeks_' + i"
                              class="form-control"
                              maxlength="40"
                            />
                          </div>
                          <label class="required control-label col-md-2" [for]="'pmeas_projyear_' + i">
                            Project Year
                            <span
                              *ngIf="!project.pheno_measures[i].projyear"
                              class="glyphicon glyphicon-warning-sign"
                              style="color: red"
                              matTooltip="Since this is a required field, when you
                                              save it will be restored to its previous value."
                            ></span>
                          </label>
                          <div class="col-md-4">
                            <input
                              [(ngModel)]="project.pheno_measures[i].projyear"
                              type="text"
                              [id]="'pmeas_projyear_' + i"
                              class="form-control"
                              maxlength="10"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="control-label col-md-2" [for]="'pmeas_download_file_' + i">
                            Download File
                            <span
                              class="glyphicon glyphicon-question-sign"
                              matTooltip="File to download when viewing the measure in MPD.
                                          Selection list comes from supplementary files"
                              [matTooltipPosition]="position"
                              matTooltipHideDelay="2000"
                            ></span>
                          </label>
                          <div class="col-md-4">
                            <select class="form-control" [(ngModel)]="project.pheno_measures[i].download_fileid">
                              <option value=""></option>
                              <option
                                *ngFor="let file of supplementaryFileUpload.uploadedFiles;let v = index"
                                [value]="file.id"
                              >
                                {{ file.filename }}
                              </option>
                            </select>
                          </div>
                          <label *ngIf="project.pheno_measures[i].seriestype && project.pheno_measures[i].is_measure"
                            class="control-label col-md-2" [for]="'pmeas_seriesprefix_' + i">
                            Individual Measure Description List Prefix
                            <span
                              class="glyphicon glyphicon-question-sign"
                              matTooltip="A prefix, which is applied in front of a listing of all Individual Measure
                                              Descriptions in a series.  For example, if the Individual Measure
                                              Descriptions are just numbers representing dose, then you might want to
                                              prefix those descriptions with ‘mg/kg:’, where ‘mg/kg: X, Y, Z’ will
                                              appear in the listing for the series (displayed in series headers and on
                                              plots)."
                              [matTooltipPosition]="position"
                              matTooltipHideDelay="2000"
                            ></span>
                          </label>
                          <div *ngIf="project.pheno_measures[i].seriestype && project.pheno_measures[i].is_measure"
                            class="col-md-4">
                            <input
                              [(ngModel)]="project.pheno_measures[i].seriespre"
                              type="text"
                              [id]="'pmeas_seriesprefix_' + i"
                              class="form-control"
                              maxlength="80"
                            />
                          </div>
                        </div>
                        <div
                          *ngIf="project.pheno_measures[i].seriestype && project.pheno_measures[i].is_measure"
                          class="form-group"
                        >
                          <label class="control-label col-md-2" [for]="'pmeas_labelprefix_' + i">
                            Individual Measure Description Prefix
                            <span
                              class="glyphicon glyphicon-question-sign"
                              matTooltip="A prefix, which is applied in front of all Individual Measure Descriptions
                                              in a series.  For example, if the Individual Measure Descriptions are ages
                                              with units, then you might want to prefix those descriptions with ‘at
                                              age’, where ‘at age X wks’ will appear after the Series Description for
                                              each measure in the series. "
                              [matTooltipPosition]="position"
                              matTooltipHideDelay="2000"
                            ></span>
                          </label>
                          <div class="col-md-4">
                            <input
                              [(ngModel)]="project.pheno_measures[i].labelpre"
                              type="text"
                              [id]="'pmeas_labelprefix_' + i"
                              class="form-control"
                              maxlength="80"
                            />
                          </div>
                          <label class="control-label col-md-2" [for]="'pmeas_labelpostfix_' + i">
                            Individual Measure Description Postfix
                            <span
                              class="glyphicon glyphicon-question-sign"
                              matTooltip="A postfix, which is applied at the end of all Individual Measure
                                              Descriptions in a series.  For example, if the Individual Measure
                                              Descriptions are just numbers representing ages, then you might want to
                                              postfix those descriptions with ‘wks’, where ‘X wks’ will appear after the
                                              Series Description for each measure in the series."
                              [matTooltipPosition]="position"
                              matTooltipHideDelay="2000"
                            ></span>
                          </label>
                          <div class="col-md-4">
                            <input
                              [(ngModel)]="project.pheno_measures[i].labelpost"
                              type="text"
                              [id]="'pmeas_labelpostfix_' + i"
                              class="form-control"
                              maxlength="80"
                            />
                          </div>
                        </div>
                        <!--Measure factors section (only available for individual measures)-->
                        <div *ngIf="!project.pheno_measures[i].seriestype" class="form-group">
                          <div class="col-sm-12">
                            <mat-accordion>
                              <mat-expansion-panel [expanded]="false">
                                <mat-expansion-panel-header>
                                  <mat-panel-title>
                                    <label>Factors</label>
                                  </mat-panel-title>
                                  <mat-panel-description>
                                    <b>Number:</b>&nbsp;{{ project.pheno_measures[i].factors.length }}
                                    <span style="color: red" *ngIf="measFactorBlankCount(i) > 0">
                                      &nbsp;({{ measFactorBlankCount(i) }} blank)
                                    </span>
                                  </mat-panel-description>
                                </mat-expansion-panel-header>
                                <ng-template matExpansionPanelContent>
                                  <hr />
                                  <div
                                    class="form-group"
                                    *ngIf="project.canEdit && project.pheno_measures[i].upload_factors"
                                  >
                                    <label class="control-label col-md-2">
                                      Upload
                                      <span style="white-space: nowrap">
                                        Factors
                                        <span
                                          class="glyphicon glyphicon-question-sign"
                                          matTooltip="The factor values for this variable from when it was uploaded and
                                                          processed. This value is informational only, for reference
                                                          while modifying factors."
                                        ></span>
                                      </span>
                                    </label>
                                    <div class="col-md-10" style="padding-top: 7px">
                                      &nbsp;&nbsp;&nbsp;{{ project.pheno_measures[i].upload_factors }}
                                    </div>
                                  </div>
                                  <div
                                    class="form-group"
                                    *ngFor="let meas_factor of project.pheno_measures[i].factors; let o = index"
                                  >
                                    <label class="control-label col-md-2" style="white-space: nowrap">
                                      <span
                                        matTooltip="Remove factor"
                                        *ngIf="project.canEdit"
                                        class="def-icon def-remove glyphicon glyphicon-remove"
                                        (click)="removeMeasFactor(i, o)"
                                      ></span>
                                      Factor
                                    </label>
                                    <div class="col-md-4">
                                      <select
                                        class="form-control"
                                        [(ngModel)]="project.pheno_measures[i].factors[o].factor_id"
                                        [disabled]="true"
                                      >
                                        <option
                                          *ngFor="let factor of project.factors; let f = index"
                                          [value]="factor.id"
                                        >
                                          {{
                                            'No. ' + factor.listorder + ': ' + (factor.name ? factor.name : '(Unnamed)')
                                          }}
                                        </option>
                                      </select>
                                    </div>
                                    <label class="control-label col-md-2">Value</label>
                                    <div class="col-md-4">
                                      <select
                                        class="form-control"
                                        [disabled]="!project.canEdit"
                                        [(ngModel)]="project.pheno_measures[i].factors[o].factor_value_id"
                                        (change)="changeMeasFactorValue(i, o, $event.target.value)"
                                      >
                                        <option value=""></option>
                                        <option
                                          *ngFor="
                                            let value of getFactor(project.pheno_measures[i].factors[o].factor_id)
                                              .values;
                                            let v = index
                                          "
                                          [value]="value.id"
                                        >
                                          {{
                                            'No. ' + value.listorder + ': ' + (value.value ? value.value : '(Blank)')
                                          }}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                  <div *ngIf="project.canEdit" class="form-group">
                                    <label class="control-label col-md-2">Add Factor</label>
                                    <div class="col-md-4">
                                      <select class="form-control" (change)="addMeasFactor(i, $event.target.value)">
                                        <option value=""></option>
                                        <option
                                          *ngFor="let factor of untakenMeasFactors(i); let f = index"
                                          [value]="factor.id"
                                        >
                                          {{
                                            'No. ' + factor.listorder + ': ' + (factor.name ? factor.name : '(Unnamed)')
                                          }}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </ng-template>
                              </mat-expansion-panel>
                            </mat-accordion>
                          </div>
                        </div>
                        <!--Ontologies section-->
                        <div class="form-group">
                          <div class="col-sm-12">
                            <mat-accordion>
                              <mat-expansion-panel [expanded]="false">
                                <mat-expansion-panel-header>
                                  <mat-panel-title>
                                    <label>Ontology Mappings</label>
                                  </mat-panel-title>
                                  <mat-panel-description>
                                    {{ buildOntologiesDescrip(project.pheno_measures[i].ontologies) }}
                                  </mat-panel-description>
                                </mat-expansion-panel-header>
                                <ng-template matExpansionPanelContent>
                                  <hr />
                                  <div
                                    class="form-group"
                                    *ngFor="let ontology of project.pheno_measures[i].ontologies; let o = index"
                                  >
                                    <label class="control-label col-md-2">Ontology</label>
                                    <div class="col-md-10">
                                      <ontology-search
                                        [(ontology)]="project.pheno_measures[i].ontologies[o]"
                                        (ontologyChange)="removeEmptyOntology(project.pheno_measures[i].ontologies, o)"
                                      ></ontology-search>
                                    </div>
                                  </div>
                                  <div *ngIf="project.canEdit" class="form-group">
                                    <label class="control-label col-md-2">Add Ontology</label>
                                    <div class="col-md-10">
                                      <ontology-search
                                        #ont_search
                                        [disableList]="project.pheno_measures[i].ontologies"
                                        (ontologyChange)="
                                          ontologyChange($event, project.pheno_measures[i].ontologies)
                                            ? ont_search.clearSelection()
                                            : false
                                        "
                                      >
                                      </ontology-search>
                                    </div>
                                  </div>
                                </ng-template>
                              </mat-expansion-panel>
                            </mat-accordion>
                          </div>
                        </div>
                      </fieldset>
                      <!--Procedures section-->
                      <div class="form-group">
                        <div class="col-sm-12">
                          <mat-accordion>
                            <mat-expansion-panel [expanded]="false">
                              <mat-expansion-panel-header>
                                <mat-panel-title>
                                  <label>Procedures</label>
                                </mat-panel-title>
                                <mat-panel-description>
                                  {{ buildMeasProcsDescrip(project.pheno_measures[i].meas_procs) }}
                                </mat-panel-description>
                              </mat-expansion-panel-header>
                              <ng-template matExpansionPanelContent>
                                <hr />
                                <div
                                  class="form-group"
                                  *ngFor="let meas_proc of project.pheno_measures[i].meas_procs; let o = index"
                                >
                                  <label class="control-label col-md-2">Procedure</label>
                                  <div class="col-md-10">
                                    <procedure-search
                                      [(procedure)]="project.pheno_measures[i].meas_procs[o]"
                                      [inputOptions]="project.procedures"
                                      (procedureChange)="removeEmptyMeasProc(project.pheno_measures[i].meas_procs, o)"
                                      [disabled]="!project.canEdit"
                                    ></procedure-search>
                                  </div>
                                </div>
                                <div *ngIf="project.canEdit" class="form-group">
                                  <label class="control-label col-md-2">Add Procedure</label>
                                  <div class="col-md-10">
                                    <procedure-search
                                      #proc_search
                                      [disableList]="project.pheno_measures[i].meas_procs"
                                      [inputOptions]="project.procedures"
                                      (procedureChange)="
                                        measProcChange($event, project.pheno_measures[i].meas_procs)
                                          ? proc_search.clearSelection()
                                          : false
                                      "
                                    >
                                    </procedure-search>
                                  </div>
                                </div>
                              </ng-template>
                            </mat-expansion-panel>
                          </mat-accordion>
                        </div>
                      </div>
                      <fieldset [disabled]="!project.canEdit">
                        <!--Table for entering individual measures in the series-->
                        <div *ngIf="project.pheno_measures[i].seriestype" class="form-group">
                          <div class="col-sm-12">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th style="border: 1px solid #ddd"><label>Series Individual Variables</label></th>
                                  <th *ngIf="project.canEdit" width="1" style="border: 1px solid #ddd"></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let imeas of project.pheno_measures[i].pheno_series; let j = index">
                                  <td style="border: 1px solid #ddd">
                                    <mat-accordion>
                                      <mat-expansion-panel [expanded]="false">
                                        <mat-expansion-panel-header>
                                          <mat-panel-title>
                                            <b
                                              >No. {{ j + 1 }}: Individual Variable, ID:
                                              {{ project.pheno_measures[i].pheno_series[j].measnum }}</b
                                            >
                                            &nbsp;
                                            <a
                                              *ngIf="project.pheno_measures[i].is_measure"
                                              matTooltip="Preview this measure in MPD"
                                              (click)="
                                                stateButtons.openExternalUrlAfterSave(
                                                  previewURL +
                                                    'measures/' +
                                                    project.pheno_measures[i].pheno_series[j].measnum
                                                )
                                              "
                                            >
                                              <span class="glyphicon glyphicon-link"></span>
                                            </a>
                                          </mat-panel-title>
                                          <mat-panel-description>
                                            <span
                                              ><b>Name:</b>&nbsp;{{
                                                project.pheno_measures[i].pheno_series[j].indiv_varname
                                              }}</span
                                            >
                                          </mat-panel-description>
                                        </mat-expansion-panel-header>
                                        <ng-template matExpansionPanelContent>
                                          <hr />
                                          <div class="form-horizontal">
                                            <div class="form-group">
                                              <label class="control-label col-md-2"
                                                >{{ variableCategories(i, true) }} Info</label
                                              >
                                              <div class="col-md-10" style="padding-top: 7px">
                                                &nbsp;&nbsp;
                                                <span
                                                  class="no-wrap"
                                                  *ngIf="project.pheno_measures[i].pheno_series[j].sextested"
                                                >
                                                  <b>Sexes Tested: </b>
                                                  {{
                                                    project.pheno_measures[i].pheno_series[j].sextested === 'f'
                                                      ? 'Females'
                                                      : project.pheno_measures[i].pheno_series[j].sextested === 'm'
                                                      ? 'Males'
                                                      : 'Both'
                                                  }} </span
                                                >&nbsp;&nbsp;&nbsp;
                                                <span
                                                  class="no-wrap"
                                                  *ngIf="project.pheno_measures[i].pheno_series[j].nstrainstested"
                                                >
                                                  <b># of Strains Tested: </b
                                                  >{{ project.pheno_measures[i].pheno_series[j].nstrainstested }} </span
                                                >&nbsp;&nbsp;&nbsp;
                                                <span class="no-wrap">
                                                  <b># of Data Points: </b
                                                  >{{ project.pheno_measures[i].pheno_series[j].datapointcount }}
                                                </span>
                                                <span
                                                  class="pre-wrap"
                                                  *ngIf="project.pheno_measures[i].pheno_series[j].f_do_animals_count"
                                                  >&nbsp;&nbsp;&nbsp;</span
                                                >
                                                <span
                                                  class="no-wrap"
                                                  *ngIf="project.pheno_measures[i].pheno_series[j].f_do_animals_count"
                                                >
                                                  <b># of DO Females: </b
                                                  >{{ project.pheno_measures[i].pheno_series[j].f_do_animals_count }}
                                                </span>
                                                <span
                                                  class="pre-wrap"
                                                  *ngIf="project.pheno_measures[i].pheno_series[j].m_do_animals_count"
                                                  >&nbsp;&nbsp;&nbsp;</span
                                                >
                                                <span
                                                  class="no-wrap"
                                                  *ngIf="project.pheno_measures[i].pheno_series[j].m_do_animals_count"
                                                >
                                                  <b># of DO Males: </b
                                                  >{{ project.pheno_measures[i].pheno_series[j].m_do_animals_count }}
                                                </span>
                                              </div>
                                            </div>
                                            <div class="form-group">
                                              <label
                                                class="required control-label col-md-2"
                                                [for]="'pmeas_varname_' + i + 'i' + j"
                                              >
                                                Variable Name
                                                <span
                                                  class="glyphicon glyphicon-question-sign"
                                                  matTooltip="This Variable Name value is used to identify
                                                                    this individual measure within the series."
                                                  [matTooltipPosition]="position"
                                                  matTooltipHideDelay="2000"
                                                ></span>
                                              </label>
                                              <div class="col-md-4">
                                                <input
                                                  [(ngModel)]="project.pheno_measures[i].pheno_series[j].indiv_varname"
                                                  type="text"
                                                  [disabled]="currentPhenotypeIndivVarnameDisabled()"
                                                  (keyup)="
                                                    project.pheno_measures[i].pheno_series[j].indiv_varname =
                                                      varnameClean(
                                                        project.pheno_measures[i].pheno_series[j].indiv_varname
                                                      )
                                                  "
                                                  (change)="
                                                    checkChangeMatchingVarname(
                                                      project.pheno_measures[i].pheno_series[j].indiv_varname,
                                                      'pheno_series',
                                                      i,
                                                      j
                                                    )
                                                  "
                                                  [id]="'pmeas_varname_' + i + 'i' + j"
                                                  class="form-control"
                                                  maxlength="40"
                                                />
                                              </div>
                                              <label
                                                class="control-label col-md-2"
                                                [for]="'pmeas_ageweeks_' + i + 'i' + j"
                                              >
                                                Age (wks)
                                              </label>
                                              <div class="col-md-4">
                                                <input
                                                  [(ngModel)]="project.pheno_measures[i].pheno_series[j].indiv_ageweeks"
                                                  type="text"
                                                  [id]="'pmeas_ageweeks_' + i + 'i' + j"
                                                  class="form-control"
                                                  maxlength="40"
                                                />
                                              </div>
                                            </div>
                                            <div class="form-group">
                                              <label
                                                class="required control-label col-md-2"
                                                [for]="'pmeas_descrip_' + i + 'i' + j"
                                              >
                                                Description
                                                <span
                                                  class="glyphicon glyphicon-question-sign"
                                                  matTooltip="This Description value is added to the
                                                                  end of the Series Description to form the
                                                                  full Description for this Individual Measure."
                                                  [matTooltipPosition]="position"
                                                  matTooltipHideDelay="2000"
                                                ></span>
                                                <span
                                                  *ngIf="!project.pheno_measures[i].pheno_series[j].label"
                                                  class="glyphicon glyphicon-warning-sign"
                                                  style="color: red"
                                                  matTooltip="Since this is a required field, when you
                                                                  save it will be restored to its previous value."
                                                ></span>
                                              </label>
                                              <div class="col-md-10">
                                                <input
                                                  [(ngModel)]="project.pheno_measures[i].pheno_series[j].label"
                                                  type="text"
                                                  [id]="'pmeas_descrip_' + i + 'i' + j"
                                                  class="form-control"
                                                  maxlength="255"
                                                />
                                              </div>
                                            </div>
                                            <div class="form-group">
                                              <label class="no-wrap control-label col-md-2">Full Description</label>
                                              <div class="col-md-10" style="padding-top: 7px">
                                                <span>
                                                  &nbsp;&nbsp;&nbsp;
                                                  {{
                                                    project.pheno_measures[i].descrip +
                                                      ' ' +
                                                      (project.pheno_measures[i].labelpre
                                                        ? project.pheno_measures[i].labelpre + ' '
                                                        : '') +
                                                      project.pheno_measures[i].pheno_series[j].label +
                                                      (project.pheno_measures[i].labelpost
                                                        ? ' ' + project.pheno_measures[i].labelpost
                                                        : '')
                                                  }}
                                                </span>
                                              </div>
                                            </div>
                                            <!--Measure factors section (only available for individual measures)-->
                                            <div class="form-group">
                                              <div class="col-sm-12">
                                                <mat-accordion>
                                                  <mat-expansion-panel>
                                                    <mat-expansion-panel-header>
                                                      <mat-panel-title>
                                                        <label>Factors</label>
                                                      </mat-panel-title>
                                                      <mat-panel-description>
                                                        <b>Number:</b>&nbsp;{{
                                                          project.pheno_measures[i].pheno_series[j].factors.length
                                                        }}
                                                        <span style="color: red" *ngIf="measFactorBlankCount(i, j) > 0">
                                                          &nbsp;({{ measFactorBlankCount(i, j) }} blank)
                                                        </span>
                                                      </mat-panel-description>
                                                    </mat-expansion-panel-header>
                                                    <hr />
                                                    <div
                                                      class="form-group"
                                                      *ngIf="
                                                        project.canEdit &&
                                                        project.pheno_measures[i].pheno_series[j].upload_factors
                                                      "
                                                    >
                                                      <label class="control-label col-md-2">
                                                        Upload
                                                        <span style="white-space: nowrap">
                                                          Factors
                                                          <span
                                                            class="glyphicon glyphicon-question-sign"
                                                            matTooltip="The factor values for this variable from when
                                                                            it was uploaded and processed. This value is
                                                                            informational only, for reference while
                                                                            modifying factors."
                                                          ></span>
                                                        </span>
                                                      </label>
                                                      <div class="col-md-10" style="padding-top: 7px">
                                                        &nbsp;&nbsp;&nbsp;{{
                                                          project.pheno_measures[i].pheno_series[j].upload_factors
                                                        }}
                                                      </div>
                                                    </div>
                                                    <div
                                                      class="form-group"
                                                      *ngFor="
                                                        let meas_factor of project.pheno_measures[i].pheno_series[j]
                                                          .factors;
                                                        let o = index
                                                      "
                                                    >
                                                      <label class="control-label col-md-2" style="white-space: nowrap">
                                                        <span
                                                          matTooltip="Remove factor"
                                                          *ngIf="project.canEdit"
                                                          class="def-icon def-remove glyphicon glyphicon-remove"
                                                          (click)="removeMeasFactor(i, o, j)"
                                                        ></span>
                                                        Factor
                                                      </label>
                                                      <div class="col-md-4">
                                                        <select
                                                          class="form-control"
                                                          [(ngModel)]="
                                                            project.pheno_measures[i].pheno_series[j].factors[o]
                                                              .factor_id
                                                          "
                                                          [disabled]="true"
                                                        >
                                                          <option
                                                            *ngFor="let factor of project.factors; let f = index"
                                                            [value]="factor.id"
                                                          >
                                                            {{
                                                              'No. ' +
                                                                factor.listorder +
                                                                ': ' +
                                                                (factor.name ? factor.name : '(Unnamed)')
                                                            }}
                                                          </option>
                                                        </select>
                                                      </div>
                                                      <label class="control-label col-md-2">Value</label>
                                                      <div class="col-md-4">
                                                        <select
                                                          class="form-control"
                                                          [disabled]="!project.canEdit"
                                                          [(ngModel)]="
                                                            project.pheno_measures[i].pheno_series[j].factors[o]
                                                              .factor_value_id
                                                          "
                                                          (change)="changeMeasFactorValue(i, o, $event.target.value, j)"
                                                        >
                                                          <option value=""></option>
                                                          <option
                                                            *ngFor="
                                                              let value of getFactor(
                                                                project.pheno_measures[i].pheno_series[j].factors[o]
                                                                  .factor_id
                                                              ).values;
                                                              let v = index
                                                            "
                                                            [value]="value.id"
                                                          >
                                                            {{
                                                              'No. ' +
                                                                value.listorder +
                                                                ': ' +
                                                                (value.value ? value.value : '(Blank)')
                                                            }}
                                                          </option>
                                                        </select>
                                                      </div>
                                                    </div>
                                                    <div *ngIf="project.canEdit" class="form-group">
                                                      <label class="control-label col-md-2">Add Factor</label>
                                                      <div class="col-md-4">
                                                        <select
                                                          class="form-control"
                                                          (change)="addMeasFactor(i, $event.target.value, j)"
                                                        >
                                                          <option value=""></option>
                                                          <option
                                                            *ngFor="
                                                              let factor of untakenMeasFactors(i, j);
                                                              let f = index
                                                            "
                                                            [value]="factor.id"
                                                          >
                                                            {{
                                                              'No. ' +
                                                                factor.listorder +
                                                                ': ' +
                                                                (factor.name ? factor.name : '(Unnamed)')
                                                            }}
                                                          </option>
                                                        </select>
                                                      </div>
                                                    </div>
                                                  </mat-expansion-panel>
                                                </mat-accordion>
                                              </div>
                                            </div>
                                          </div>
                                        </ng-template>
                                      </mat-expansion-panel>
                                    </mat-accordion>
                                  </td>
                                  <td
                                    *ngIf="project.canEdit"
                                    style="border: 1px solid #ddd; vertical-align: middle; white-space: nowrap"
                                  >
                                    <span
                                      matTooltip="Delete variable"
                                      (click)="deleteMeasure(i, j)"
                                      class="def-icon def-remove glyphicon glyphicon-trash"
                                    ></span>
                                    <span
                                      matTooltip="Move variable up"
                                      (click)="swapPhenoIndivMeasurements(i, j, j - 1)"
                                      class="def-icon def-up glyphicon glyphicon-arrow-up"
                                    ></span>
                                    <span
                                      matTooltip="Move variable down"
                                      (click)="swapPhenoIndivMeasurements(i, j, j + 1)"
                                      class="def-icon def-down glyphicon glyphicon-arrow-down"
                                    ></span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </ng-template>
                </mat-expansion-panel>
              </mat-accordion>
            </td>
            <td *ngIf="project.canEdit" style="border: 1px solid #ddd; vertical-align: middle; white-space: nowrap">
              <span
                matTooltip="Delete measure"
                (click)="deleteMeasure(i)"
                class="def-icon def-remove glyphicon glyphicon-trash"
              ></span>
              <span
                matTooltip="Move measure up"
                (click)="swapPhenoMeasurements(i, i - 1)"
                class="def-icon def-up glyphicon glyphicon-arrow-up"
              ></span>
              <span
                matTooltip="Move measure down"
                (click)="swapPhenoMeasurements(i, i + 1)"
                class="def-icon def-down glyphicon glyphicon-arrow-down"
              ></span>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <div class="form-horizontal">
        <div class="form-group">
          <div class="col-sm-3 col-md-4"></div>
          <div class="col-sm-3 col-md-2">
            <button class="btn btn-info btn-block" matStepperPrevious id="step-2-prev">
              <strong>Previous</strong>
            </button>
          </div>
          <div class="col-sm-3 col-md-2">
            <button class="btn btn-info btn-block" matStepperNext id="step-2-next"><strong>Next</strong></button>
          </div>
        </div>
      </div>
    </mat-step>
    <mat-step *ngIf="showDataNodeNetworkFeature" label="Data Nodes Network">
      <div class="form-horizontal" style="padding-top: 20px; padding-bottom: 20px">
        <project-isa-data
          [projectIsaData]="project.isa_data"
          [project]="project"
          (triggerSave)="state_buttons_component.saveProject().subscribe()"
          [projectId]="project.projid"
          [canEdit]="project.canEdit"
        >
        </project-isa-data>
      </div>
      <div class="form-horizontal">
        <div class="form-group">
          <div class="col-sm-3 col-md-4"></div>
          <div class="col-sm-3 col-md-2">
            <button class="btn btn-info btn-block" matStepperPrevious id="step-3-prev">
              <strong>Previous</strong>
            </button>
          </div>
          <div class="col-sm-3 col-md-2">
            <button class="btn btn-info btn-block" matStepperNext id="step-3-next"><strong>Next</strong></button>
          </div>
        </div>
      </div>
    </mat-step>
    <mat-step label="Supplementary Files">
      <fieldset [disabled]="!project.canEdit">
        <br />
        <div class="form-horizontal">
          <div class="form-group">
            <div class="col-sm-1"></div>
            <div class="col-sm-9">
              <p>
                This file upload can be used to upload any kind of supplementary file, whether it's supplementary data
                or a supplementary file meant to be used as a reference for project editors or SIP curators.
                <br /><br />
                For these supplementary files, you have the option of indicating whether or not they should be private.
                If a file is marked as private, then it will only be accessible to people who are given explicit access
                to this project through the Share Project page and SIP curators. If a file is not marked as private,
                then it is public and will be available for download by the general public after this project is
                released. The file description/caption will be included with the download link for public files.
              </p>
            </div>
          </div>
        </div>
        <file-upload #supplementaryFileUpload
          [allowEdit]="project.canEdit"
          [pointertype]="'projid'"
          [title]="'Supplementary Files'"
          [pointer]="project.projid"
          [tag]="'supplementary'"
          [showPrivateOption]="true"
        ></file-upload>
      </fieldset>
      <div class="form-horizontal">
        <div class="form-group">
          <div class="col-sm-4 col-md-5"></div>
          <div class="col-sm-3 col-md-2">
            <button class="btn btn-info btn-block" matStepperPrevious id="step-4-prev">
              <strong>Previous</strong>
            </button>
          </div>
        </div>
      </div>
    </mat-step>
  </mat-vertical-stepper>
  <br />
  <br />
  <state-buttons
    [(project)]="project"
    [(dbProject)]="dbProject"
    #state_buttons_component
    (clickTitleLink)="timeline_component.titleInput.nativeElement.focus()"
    (saveFail)="timeline_component.setActive()"
    [currentPage]="'/definitions/'"
    [nextPage]="'/genotypes/'"
  ></state-buttons>
</div>
