import { ChangeDetectorRef, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ArrayData, ArrayUploadComponent } from './array-upload/array-upload.component';
import { FileTypeValidator } from './validators/file-format.directive';
import { FileNameValidator } from './validators/file-name.directive';
import { R2Service } from '../../services/r2.service';
import {
  ArrayFileUploadUrl,
  arrayNameFromFinalReportFilename,
  DivdbDataset,
  DivDBService,
  formatGenotypingPlatform,
  Inventory,
  InventoryPost,
  newArrayFileset,
} from '../../services/divdb.service';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import { combineLatest, EMPTY, forkJoin, of, throwError } from 'rxjs';
import { RestoredArrayData } from './restored-array/restored-array.component';
import { PersonService } from '../../services/person.service';
import {
  AnimalInfoUploadComponent,
  SavedAnimal,
  UnsavedAnimal,
} from './animal-info-upload/animal-info-upload.component';
import {
  InventoryAnimal,
  InventoryReviewComponent,
  MissingInventoryAnimal,
} from './inventory-review/inventory-review.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from '../../services/project.service';
import { DatasetDetails, DatasetDetailsComponent } from './dataset-details/dataset-details.component';
import { WhitespaceValidator } from './validators/whitespace.directive';
import { NoDuplicatesValidator } from './validators/no-duplicates.directive';
import { PublicationLink } from './publication-links/publication-links.component';
import { Publication } from '../../services/publication.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'genotype-intake',
  templateUrl: './genotype-intake.component.html',
  styleUrls: ['./genotype-intake.component.scss'],
})
export class GenotypeIntakeComponent implements OnInit {
  // current user
  user: any;

  // SIP project ID
  projectID = '';

  // SIP project object
  project: any = {};

  // dataset associated with the current SIP study
  dataset: DivdbDataset | null = null;

  // true if SIP project data is loading
  isProjectLoading = false;

  // true if trying to access an invalid SIP project
  isProjectIDInvalid = false;

  // true if an issue was encountered while trying to connect with DivDB
  connectionError = false;

  // error message related to creating a new dataset
  creationError = '';

  // error message related to updating a dataset
  updateError = '';

  // error message related to dataset delete
  deletionError = '';

  // list of genotype arrays that have been uploaded in previous sessions
  previouslyUploadedArrays: RestoredArrayData[] = [];

  // list of genotype array forms for all new uploads
  arrayUploadForms: FormGroup[] = [];

  // temporary storage of R2-related information about an array in case an error is encountered
  incompleteArrayUploadData: null | { sampleMap: any, finalReport: any } = null;

  // list of currently linked or available publications to link for the dataset
  publicationLinks: PublicationLink[] | null = null;

  // dataset details component
  @ViewChild(DatasetDetailsComponent) details!: DatasetDetailsComponent;

  // query list of all rendered genotype array upload components
  @ViewChildren(ArrayUploadComponent) arrayUploadComponents!: QueryList<ArrayUploadComponent>;

  // animal info upload component
  @ViewChild(AnimalInfoUploadComponent) animalUpload!: AnimalInfoUploadComponent;

  // inventory review/selection component
  @ViewChild(InventoryReviewComponent) inventory!: InventoryReviewComponent;

  constructor(
    private r2: R2Service,
    private divdb: DivDBService,
    private cdr: ChangeDetectorRef,
    private people: PersonService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private projects: ProjectService,
  ) {}

  ngOnInit() {
    this.projectID = this.route.snapshot.paramMap.get('id') || '';
    this.isProjectLoading = true;

    if (Number(this.projectID)) {
      // get SIP study information
      this.getProject();

      this.people.currentUser$.subscribe((user) => {
        this.user = user;
      });
    } else {
      this.isProjectIDInvalid = true;
    }
  }

  /**
   * Returns the ID for the current DivDB dataset or 0 if there isn't one yet
   */
  get datasetID(): number {
    if (this.dataset) {
      return this.dataset.dataset_id;
    } else if (this.project.genotypes.length) {
      return this.project.genotypes[0].genotype_dataset_id;
    }
    return 0;
  }

  /**
   * Returns true if the current user should be able to edit the project
   */
  get userCanEdit(): boolean {
    if (this.details && this.details.status) {
      return this.project.canEdit && !this.details.status.isProcessing;
    }

    return this.project.canEdit;
  }

  /**
   * Returns a list of extension-less filenames for the purposes of genotype array final report
   * filename comparison
   */
  get uploadedFinalReportNames(): string[] {
    if (this.dataset) {
      return this.dataset.arrays.map((a) => a.final_report_name.replace(/\.[^/.]+$/, ''));
    }

    return [];
  }

  /**
   * Gets the current SIP project
   */
  getProject(): void {
    this.isProjectLoading = true;
    this.projects.getProject(this.projectID).subscribe(
      (resp) => {
        this.isProjectIDInvalid = resp.table_form;
        if (!this.isProjectIDInvalid) {
          this.project = resp;
          this.cdr.detectChanges();

          // extract genotype dataset info if there is any
          if (this.project.genotypes.length) {
            this.getDataset(this.project.genotypes[0].genotype_dataset_id);
          } else {
            // else stop loading the project
            this.isProjectLoading = false;
          }
        }
      },
      () => {
        this.isProjectIDInvalid = true;
        this.isProjectLoading = false;
      },
    );
  }

  /**
   * Gets the DivDB dataset associated with the current dataset ID and sets the current dataset to
   * the result, distributes some of the core dataset values to display in dataset fields, loads
   * information about any previously uploaded arrays
   * @param datasetID - ID of the dataset to pull info for
   */
  getDataset(datasetID: number): void {
    // if the specified dataset ID is 0, this means that somehow we got here but there
    // isn't a dataset, so don't attempt to get one
    if (datasetID) {
      // clear out these arrays; if this isn't done and connection errors are encountered,
      // if the error is resolved after a time, this array may accumulate redundant restored
      // arrays
      this.previouslyUploadedArrays = [];
      this.arrayUploadForms = [];

      if (this.animalUpload) {
        this.animalUpload.apiErrorMsg = '';
      }

      if (this.inventory) {
        this.inventory.apiErrorMsg = '';
      }

      this.divdb.getDataset(datasetID).subscribe(
        (dataset) => {
          // grab the dataset
          this.dataset = dataset;
          this.connectionError = false;
          this.isProjectLoading = false;

          this.cdr.detectChanges();

          // ARRAY DATA
          // for each uploaded array, get array file URLs to display to the user
          if (dataset?.arrays && dataset.arrays.length) {
            // clear out these arrays again, just in case since there's a rare case that
            // causes these API calls to trigger twice doubling the length of both arrays
            // and that's whack
            this.previouslyUploadedArrays = [];
            this.arrayUploadForms = [];

            // the new array objects have the GS URLs for locations and file names so there's no reason to
            // make calls out to R2 to get the file information
            this.previouslyUploadedArrays = dataset.arrays.map((a) => {
              return {
                platform: a.platform,
                sampleMap: { name: a.sample_map_name, location: a.sample_map_url },
                finalReport: { name: a.final_report_name, location: a.final_report_url },
              };
            });
          } else {
            this.addNewGenotypeArray();
          }

          // ANIMAL INFO
          this.divdb.getAnimalInfo(datasetID).subscribe(
            (animalInfo) => {
              this.cdr.detectChanges();
              if (!this.animalUpload) {
                setTimeout(() => {
                  this.animalUpload.saveAnimals(animalInfo);
                }, 2000);
              } else {
                this.animalUpload.saveAnimals(animalInfo);
              }

              // PUBLICATIONS
              this.divdb.getPublications(datasetID).subscribe((publicationLinks) => {
                const linkedPMIDs = publicationLinks.map((p) => String(p.publication.pmid));

                // collect any inherited publications from the study that may not have been selected
                const unlinkedInherited: PublicationLink[] = this.project.primarypublicationsinfo
                  .filter((p: Publication) => linkedPMIDs.indexOf(p.pmid) < 0)
                  .map((p: Publication) => {
                    return {
                      selected: false,
                      publication: p,
                      animals: [],
                      initial: {
                        selected: false,
                        animals: [],
                      },
                    };
                  });

                // create links from the publication info gathered from API
                const linkedSaved: PublicationLink[] = publicationLinks.map((link) => {
                  const animals = link.animals.map((a: SavedAnimal) => a.animal_id);
                  const allAnimalsSelected = animals.length === this.animalUpload?.savedAnimals.data.length;
                  return {
                    selected: true,
                    publication: link.publication,
                    animals: !allAnimalsSelected ? animals : [],
                    initial: {
                      selected: true,
                      animals: !allAnimalsSelected ? animals.slice() : [],
                    },
                  };
                });

                if (unlinkedInherited.length) {
                  this.publicationLinks = linkedSaved.concat(...unlinkedInherited);
                } else {
                  this.publicationLinks = linkedSaved;
                }
              });
            },
            (error) => {
              console.log(error);
              this.animalUpload.saveAnimals([]);
              this.animalUpload.apiErrorMsg = `We ran into a ${error.status} error while getting saved animals :(`;
            },
          );

          // INVENTORY DATA
          // uses the platforms listed in the dataset to query for available inventory animals
          // based on array platform
          // TODO: platforms seems to be populated as a side-effect of arrays being uploaded and since we don't
          //  want to get inventory until both arrays AND animal info has been submitted, these two extra conditions
          //  have been added for now
          if (dataset.platforms.length && dataset.arrays.length && dataset.animal_count) {
            combineLatest(dataset.platforms.map((p) => this.divdb.getInventory(datasetID, p))).subscribe(
              (inventories) => {
                if (inventories.length) {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore - Typescript complains about this; something about ConcatArray<never> which is odd
                  const available = [].concat(...inventories.map((inv) => inv.inventory));
                  const missing = this.getTrueMissingAnimals(inventories);

                  this.inventory.displayInventory(available, missing);

                  // INVENTORY FILE DATA
                  // gets existing inventory files (each is generated from a submission of inventory
                  // selections) and uses the files to get the raw inventory file content from the
                  // cloud bucket to read for restoring selection of inventory
                  const files = dataset.inventory_files;
                  if (files.length) {
                    // get all of the user-submitted inventory files
                    const mostRecentSubmissionFiles = files.filter((f) => f.type === 'preliminary');
                    const getInventorySelections = mostRecentSubmissionFiles.map((f) =>
                      this.r2.getRawFileFromBucket(f.url),
                    );

                    // get the raw data for the files we've identified and return that
                    combineLatest(getInventorySelections).subscribe(
                      (rawFileData) => {
                        if (files.length) {
                          // mark only inventory animals from the most recent inventory file as selected
                          this.inventory.displaySubmittedInventory(rawFileData);
                          this.inventory.waiting = false;
                        }
                      },
                      (error) => {
                        this.inventory.waiting = false;

                        if (error.status === 0) {
                          this.inventory.apiErrorMsg =
                            'We ran into an unexpected error while getting previous inventory selections :(';
                        } else {
                          this.inventory.apiErrorMsg = `We ran into a ${error.status} error while getting previous inventory selections :(`;
                        }
                        console.log(error);
                      },
                    );
                  } else {
                    this.inventory.waiting = false;
                  }
                }
              },
              (error) => {
                this.inventory.waiting = false;
                this.inventory.displayEmptyInventory();

                if (error.status === 0) {
                  this.inventory.apiErrorMsg =
                    'We ran into an unexpected error while getting available inventory animals :(';
                  console.log('FOILED AGAIN BY AN INCONSISTENT "CORS" ERROR!');
                } else {
                  this.inventory.apiErrorMsg = `We ran into a ${error.status} error while getting available inventory animals :(`;
                }
                console.log(error);
              },
            );
          } else {
            this.inventory.waiting = false;
            this.inventory.displayEmptyInventory();
          }
        },
        (error) => {
          this.isProjectLoading = false;

          // TODO: incorporate more error types as we encounter them
          if (error.status === 0 || error.status === 500 || error.status === 401) {
            this.connectionError = true;
          }
        },
      );
    }
  }

  /**
   * Creates a new dataset in DivDB and adds a blank array input
   */
  createDataset(details: DatasetDetails): void {
    this.creationError = '';
    this.divdb
      .createNewDataset(
        details.name,
        details.description,
        details.investigatorID,
        details.secondaryContactID < 0 ? null : Number(details.secondaryContactID), // send null rather than -1
      )
      .subscribe(
        (dataset) => {
          this.dataset = dataset;

          // store the dataset status information
          this.details.updateStatus(dataset.status);

          // mark investigators in the details as all enabled since the select is be disabled after
          // creating a dataset and we don't want to continue showing warning message
          this.details.investigators.forEach((i) => (i.disabled = false));
          this.projects.saveProjectGenotypeDataset(this.projectID, this.datasetID);
          // update the project known values for now
          this.project.genotypes = [{ genotype_dataset_id: dataset.dataset_id, projid: this.projectID }];
          this.project.has_datatypes.push('Genotypes');
          this.project.has_genotypes = true;

          this.addNewGenotypeArray();

          // Get rid of the spinners that will be present otherwise
          this.animalUpload.saveAnimals([]);
          this.inventory.waiting = false;
          this.inventory.displayEmptyInventory();
        },
        (err) => {
          const msg = err.error.message || err.message;

          if (msg.toLowerCase().includes("'nonetype' object has no attribute 'lower'")) {
            this.creationError =
              "The user selected as the primary investigator and/or secondary contact doesn't have a last " +
              'name which is required. Please select a different investigator';
          } else {
            this.creationError = msg;
          }
        },
      );
  }

  /**
   * Updates the dataset details to the specified values in DivDB (which are the
   * dataset name, description, and secondary contact)
   * The patch does not return a usable dataset, so the dataset is re-fetched after save
   * @param details - all dataset detail values, not just the changes
   */
  updateDataset(details: DatasetDetails): void {
    this.updateError = '';
    this.divdb
      .updateDataset(this.datasetID, details.name, details.description, Number(details.secondaryContactID))
      .pipe(switchMap(() => this.divdb.getDataset(this.datasetID)))
      .subscribe(
        (dataset: DivdbDataset) => {
          this.dataset = dataset;
        },
        (err) => {
          this.updateError = err.error.message || err.message;
        },
      );
  }

  /**
   * Kicks off the processing for the dataset and starts polling status
   */
  processDataset(): void {
    this.divdb
      .getDataset(this.datasetID)
      .pipe(
        tap((dataset) => (this.dataset = dataset)),
        switchMap(() => this.divdb.startProcessing(this.datasetID)),
      )
      .subscribe(
        () => {
          this.details.status.submitted = true;
          this.details.getStatus();
          // scroll so the top of the screen hits the top of the dataset details which should bring
          // the processing status into view
          document.getElementById('scroll-top')?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        },
        // if it errors, still try to grab the status, worst case it stops trying if the process doesn't exist
        () => this.details.getStatus(),
      );
  }

  /**
   * Delete the dataset from SIP and DivDB
   */
  deleteDataset(): void {
    // Delete the dataset from the SIP project
    this.projects
      .deleteProjectGenotypeDataset(this.projectID, this.datasetID)
      .pipe(switchMap(() => this.divdb.deleteDataset(this.datasetID)))
      .subscribe(
        () => {
          console.log(`Dataset ${this.datasetID} deleted from DivDB`);
          this.dataset = null;
          this.previouslyUploadedArrays = [];
          this.arrayUploadForms = [];
          this.project.genotypes = [];
          this.project.has_datatypes.splice(this.project.has_datatypes.indexOf('Gentoypes'), 1);
          this.project.has_genotypes = false;
        },
        (error) => {
          console.log(error);
          this.deletionError = `We ran into a ${error.status} error while deleting the dataset :(`;
        },
      );
  }

  /**
   * Finds all active process statuses and makes API calls to cancel each of them
   */
  cancelRunningProcesses(): void {
    // get all of the run ids of the non-halted processes
    const processesToCancel = this.details.status.statusHistory
      .filter((s) => this.details.status.isRunning(s))
      .map((s) => s.run_id);

    // cancel them and get the updated statuses
    combineLatest(processesToCancel.map((p) => this.divdb.cancelProcess(this.datasetID, p)))
      .pipe(switchMap(() => this.divdb.getProcessingStatus(this.datasetID)))
      .subscribe((statuses) => {
        this.details.updateStatus(statuses);
        this.cdr.detectChanges();

        // TODO: we probably want to do this, but only when cancelation handles even defunct processes
        // if (this.details.pollingInterval) {
        //   this.details.pollingInterval.unsubscribe();
        // }
      });
  }

  /**
   * Adds a new blank genotype array form to the list with appropriate validation
   */
  addNewGenotypeArray(): void {
    this.arrayUploadForms.push(
      this.fb.group({
        platform: ['', Validators.required],
        sampleMap: [
          '',
          [
            Validators.required,
            FileTypeValidator(['zip', 'txt']),
            FileNameValidator(['Sample_Map'], true), // strict filename check
            WhitespaceValidator(),
          ],
        ],
        finalReport: [
          '',
          [
            Validators.required,
            FileTypeValidator(['zip', 'txt']),
            FileNameValidator(['_FinalReport']), // flexible filename check for substring
            WhitespaceValidator(),
            NoDuplicatesValidator(this.uploadedFinalReportNames),
          ],
        ],
      }),
    );

    // pushing is going to generate a new component so detecting the changes will allow
    // the application the time it needs for the new component to render and stabilize
    this.cdr.detectChanges();
  }

  /**
   * Uses the file URLs returned from DivDB for a dataset array to retrieve the file objects for the
   * array's sample map and final report files and adds the resulting file objects as a part of a
   * RestoredArrayData object to previouslyUploadedArrays
   * @param array - array belonging to a dataset that contains information about files and platform
   */
  getRestoredArrayData(array: any): void {
    combineLatest([this.r2.getFile(array.sample_map_url), this.r2.getFile(array.final_report_url)]).subscribe(
      ([sampleMap, finalReport]) => {
        const platform = array.platform.toLowerCase();
        this.previouslyUploadedArrays.push({ platform, sampleMap, finalReport });
      },
      (err) => console.log(err),
    );
  }

  /**
   * Creates an R2 data object to upload files to, sends the data object and platform information
   * to DivDB and starts the uploading of the files to R2
   * @param arrayData - sample map and final report file information for the array
   * @param arrayIndex - the index of the new array in those being displayed in the UI
   */
  uploadGenotypeArray(arrayData: ArrayData, arrayIndex: number): void {
    const array = this.arrayUploadComponents.toArray()[arrayIndex];
    const sampleMapFile = arrayData.sampleMap;
    const finalReportFile = arrayData.finalReport;
    const catchSaveError = (error: HttpErrorResponse) => {
      array.uploadStatus = 'error';
      array.uploadError = error.status === 0 ? 'An issue occurred during the post-upload process' : error.message;

      throwError(error);

      return EMPTY;
    };

    let uploadFiles$;

    // if there's data flagged as incompletely uploaded array data, it means it made its
    // way into R2 but not to DivDB so the only part that needs to be retried is the
    // communication with DivDB
    if (this.incompleteArrayUploadData) {
      const upload = this.incompleteArrayUploadData;

      uploadFiles$ = forkJoin({
        upload: of(upload),
        updates: this.divdb.addArrayToDataset(
          this.datasetID,
          arrayData.platform, finalReportFile.name,
          upload.sampleMap.urls.gs_url, upload.finalReport.urls.gs_url,
        )
      }).pipe(catchError(catchSaveError));
    } else {

      const sampleMapUpload = this.divdb.getArrayUploadURLS(
        this.datasetID, sampleMapFile, arrayData.platform, sampleMapFile.type
      ).pipe(
        mergeMap((sampleMap: ArrayFileUploadUrl) => {
          return forkJoin({
            urls: of(sampleMap),
            uploadResult: this.divdb.putFileInCloudStorage(sampleMap.upload_url, sampleMapFile)
          });
        })
      );

      const finalReportUpload = this.divdb.getArrayUploadURLS(
        this.datasetID, finalReportFile, arrayData.platform, finalReportFile.type
      ).pipe(
        mergeMap((finalReport: ArrayFileUploadUrl) => {
          return forkJoin({
            urls: of(finalReport),
            uploadResult: this.divdb.putFileInCloudStorage(finalReport.upload_url, finalReportFile)
          });
        })
      );

      uploadFiles$ = forkJoin({
        sampleMap: sampleMapUpload,
        finalReport: finalReportUpload,
      }).pipe(
        catchError(error => {
          array.uploadStatus = 'error';
          array.uploadError = error.status === 0 ? 'An issue occurred during the upload process' : error.message;

          throwError(error);

          return of(error);
        }),
        tap((upload: any) => {
          // if we've gotten here, that means that the actual upload succeeded so
          // store this in case the next step in notifying DivDB fails
          this.incompleteArrayUploadData = upload;
        }),
        mergeMap((upload: any) => {
          return forkJoin({
            upload: of(upload),
            updates: this.divdb.addArrayToDataset(
              this.datasetID,
              arrayData.platform, finalReportFile.name,
              upload.finalReport.urls.gs_url, upload.sampleMap.urls.gs_url,
            )
          })
        }),
        catchError(catchSaveError),
      );
    }

    // get the result of the observable chain
    uploadFiles$.subscribe((result: { upload: any; updates: any; }) => {
      if (result.upload && result.updates) {
        this.incompleteArrayUploadData = null;
      }

      if (this.dataset) {
        this.dataset.arrays = result.updates.map((array: any) => {
          const finalReport = array.files.find((i: any) => i.type == 'final_report');
          const sampleMap = array.files.find((i: any) => i.type == 'sample_map');
          return {
            name: array.name,
            platform: array.platforms,
            final_report_name: finalReport.name,
            final_report_url: finalReport.url,
            sample_map_name: sampleMap.name,
            sample_map_url: sampleMap.url
          }
        });
      }

      this.finishUpload(
        array,
        result.updates.find((item: any) => item.name == arrayNameFromFinalReportFilename(finalReportFile.name)),
        arrayIndex
      );

    }, catchError(catchSaveError));
  }

  /**
   * Removes the genotype array at the specified index in the list of arrays
   * @param index - index of the genotype array that needs to be removed
   * @param restored - if true, array to remove has been uploaded to R2
   */
  deleteGenotypeArray(index: number, restored = false): void {
    if (restored) {
      this.previouslyUploadedArrays.splice(index, 1);

      // TODO: POST to DivDB to remove the array in question from the dataset;
      //  if this includes DivDB interacting with R2 to delete the actual file,
      //  then we just need to get updated inventory (and potentially the
      //  inventory files endpoint if selections need to be restored). If files
      //  must be deleted through R2 as well, that needs to happen in this block
      //  as well
    } else if (this.arrayUploadForms[index]) {
      // remove array from the list of arrays not yet uploaded to R2
      this.arrayUploadForms.splice(index, 1);
    }

    // removing an array is going to change the rendered items so we need to give the
    // application the time it needs for view to render the updated and stabilize
    this.cdr.detectChanges();
  }

  /**
   * Adds the new animal info to the current dataset in DivDB
   * @param animals - list of animal info dictionaries to add to the dataset
   */
  saveAnimals(animals: UnsavedAnimal[]): void {
    this.animalUpload.disableSave = true;
    this.animalUpload.apiErrorMsg = '';

    this.divdb
      .addAnimalInfo(this.datasetID, animals)
      .pipe(
        tap((animalInfo) => {
          this.animalUpload.disableSave = false;

          if (animalInfo.failures.length) {
            // if there are failures, show them in the table with the error message(s)
            this.animalUpload.showSaveFailures(animalInfo.failures);
          } else {
            // else clear the table and ensure the list of columns to show are the default
            // (if animals previously failed to upload, there will be an error column as well)
            this.animalUpload.newAnimals = null;
            this.animalUpload.animalInfo.get('file')?.setValue('');
            this.animalUpload.displayedColumns = ['id', 'dob', 'sex', 'doGen', 'delete'];
          }
        }),
        switchMap(() => this.divdb.getAnimalInfo(this.datasetID)),
      )
      .subscribe(
        (animalInfo) => {
          this.animalUpload.saveAnimals(animalInfo);
        },
        (err) => {
          console.log(err);
          this.animalUpload.disableSave = false;

          if (err.status === 400) {
            this.animalUpload.apiErrorMsg = `Error saving animals: ${err.error.message}`;
          } else {
            this.animalUpload.apiErrorMsg = `We ran into a ${err.status} error while saving animal info :(`;
          }
        },
        () => {
          this.getInventory();
          this.animalUpload.disableSave = false;
        },
      );
  }

  /**
   * Removes the specified animals from the animal info associated with dataset
   * @param animals - animal array to be removed from the dataset
   */
  deleteAnimals(animals: SavedAnimal[]): void {
    // TODO: POST to DivDB to remove the specified animals from the dataset and
    //  then get updated inventory
  }

  /**
   * Gets inventories for each genotyping platform selected in the uploaded arrays. Inventories
   * are compiled and displayed in the inventory review section of the intake
   */
  getInventory(): void {
    this.inventory.waiting = true;
    if (this.dataset?.arrays) {
      const arrays =
        this.previouslyUploadedArrays.length >= this.dataset.arrays.length
          ? this.previouslyUploadedArrays
          : this.dataset.arrays;

      const animals = this.dataset.animal_count || this.animalUpload.savedAnimals.data.length;
      if (animals && arrays.length) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore for some reason tslint doesn't care for the array mapping here
        const platforms: string[] = Array.from(new Set(arrays.map((a) => a.platform)));

        forkJoin(platforms.map((p) => this.divdb.getInventory(this.datasetID, p))).subscribe(
          (inventories) => {
            // compile a total list of both missing and available animals
            const missing = this.getTrueMissingAnimals(inventories);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore - Typescript complains about this; something about ConcatArray<never> which is odd
            const available = [].concat(...inventories.map((inv) => inv.inventory));

            this.inventory.waiting = false;
            this.inventory.displayInventory(available, missing);
          },
          (error) => {
            this.inventory.waiting = false;
            this.inventory.displayEmptyInventory();

            if (error.status === 0) {
              this.inventory.apiErrorMsg =
                'We ran into an unexpected error while getting available inventory animals :(';
              console.log('FOILED AGAIN BY AN INCONSISTENT "CORS" ERROR!');
            } else {
              this.inventory.apiErrorMsg = `We ran into a ${error.status} error while getting available inventory animals :(`;
            }
            console.log(error);
          },
        );
      } else {
        this.inventory.waiting = false;
        this.inventory.displayEmptyInventory();
      }
    }
  }

  /**
   * Submits the selected inventory animals to DivDB and starts the processing pipelines
   * @param inventoryAnimals - list of inventory animal selections by the user
   */
  saveInventorySelections(inventoryAnimals: InventoryAnimal[]): void {
    // clear out any error messages there may be from a prior submission
    this.inventory.saveError = '';
    this.inventory.apiErrorMsg = '';

    const arrays = Array.from(new Set(inventoryAnimals.map((a) => a.array)));
    const inventoryArrays = Array.from(
      new Set(arrays.map((a) => this.previouslyUploadedArrays.find((ar) => ar.finalReport.name.includes(a)))),
    );

    // group selected inventory animals by platform
    const inventoriesByPlatform: any = {};
    inventoryArrays.forEach((a) => {
      if (a) {
        const arrayName = arrayNameFromFinalReportFilename(a.finalReport.name);
        const animals = inventoryAnimals.filter((an) => an.array === arrayName);
        if (inventoriesByPlatform[a.platform]) {
          inventoriesByPlatform[a.platform].push(...animals);
        } else {
          inventoriesByPlatform[a.platform] = animals;
        }
      }
    });

    const inventories: InventoryPost[] = Object.keys(inventoriesByPlatform).map((p) => ({
      platform: formatGenotypingPlatform(p),
      inventory: inventoriesByPlatform[p],
    }));

    this.divdb
      .submitFinalInventory(this.datasetID, inventories)
      .pipe(switchMap(() => this.divdb.getInventoryFiles(this.datasetID)))
      .subscribe(
        (files) => {
          if (this.dataset) {
            this.dataset.inventory_files = files;
            this.inventory.showSaveSuccess = true;

            // update the saved selections so that the inventory review can look for new unsaved changes
            this.inventory.savedSelections = inventoryAnimals;

            // remove the message 5 seconds later
            setTimeout(() => (this.inventory.showSaveSuccess = false), 5000);
          }
        },
        (err) => (this.inventory.saveError = err.error.message || err.message),
      );
  }

  /**
   * Starts the haplotype reconstruction pipeline on the current dataset for each included platforms
   */
  runHaplotypeReconstruction(): void {
    this.divdb
      .getDataset(this.datasetID)
      .pipe(
        switchMap((dataset) => {
          this.dataset = dataset;
          return combineLatest(
            dataset.platforms.map((p) => this.divdb.startHaplotypeReconstruction(this.datasetID, p)),
          );
        }),
      )
      .subscribe(
        () => {
          this.details.getStatus();
          // scroll so the top of the screen hits the top of the dataset details which should bring
          // the processing status into view
          document.getElementById('scroll-top')?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        },
        // if it errors, still try to grab the status, worst case it stops trying if the process doesn't exist
        () => this.details.getStatus(),
      );
  }

  /**
   * Picks out publication links that have been changed whether it be deselected, new publications, and publications
   * that have had their linked animals changed and makes API calls to log those changes
   */
  updateLinkedPublications(): void {
    if (this.publicationLinks) {
      const pubmedIDs = this.publicationLinks.map((pub) => Number(pub.publication.pmid));

      const removed = this.publicationLinks.filter((p) => !p.selected && p.initial.selected);

      // remove all removed publications
      combineLatest(removed.map((p) => this.divdb.deletePublication(this.datasetID, p.publication.pmid))).subscribe(
        (deleteMessages) => {
          deleteMessages.forEach((msg) => {
            // TODO this may not be a permanent way to get the deleted publication's pubmed ID
            const pubmedID = Number(msg[0].split(/\s/g)[2]);
            const idx = pubmedIDs.indexOf(pubmedID);

            // print out the deletion message to the console like we do for deleting a dataset
            console.log(`Publication ${pubmedID} removed`);

            // change the initial state to match current state
            if (idx >= 0 && this.publicationLinks) {
              const pub = this.publicationLinks[idx];
              this.publicationLinks[idx] = {
                selected: false,
                animals: pub.animals,
                publication: pub.publication,
                initial: {
                  selected: false,
                  animals: pub.animals,
                },
              };
            }
          });
        },
      );

      const added = this.publicationLinks
        .filter((p) => p.selected && !p.initial.selected)
        .map((add) => {
          let animals: string[] = [];
          if (add.animals.length !== this.animalUpload.savedAnimals.data.length) {
            animals = this.animalUpload.savedAnimals.data
              .filter((a) => add.animals.indexOf(a.animal_id) >= 0)
              .map((a) => a.unique_id);
          }

          return {
            pubmed_id: add.publication.pmid,
            animals: animals,
          };
        });

      // add all of the new publications
      combineLatest(added.map((p) => this.divdb.linkPublication(this.datasetID, p))).subscribe((publications) => {
        publications.forEach((pub) => {
          this.publicationLinks =
            this.publicationLinks?.map((p) => {
              if (String(p.publication.pmid) === String(pub.publication.pmid)) {
                const newAnimals = pub.animals.map((a) => a.animal_id);
                const allAnimalsSelected = newAnimals.length === this.animalUpload.savedAnimals.data.length;
                return {
                  selected: true,
                  animals: !allAnimalsSelected ? newAnimals : [],
                  publication: pub.publication,
                  initial: {
                    selected: true,
                    animals: !allAnimalsSelected ? newAnimals : [],
                  },
                };
              }
              return p;
            }) || []; // this.publicationLinks will always be present here but Typescript is thinking it might not
        });
      });

      const animalsChanged = this.publicationLinks.filter(
        (p) =>
          p.selected &&
          p.initial.selected &&
          (p.animals.length !== p.initial.animals.length || !p.animals.every((a, i) => a === p.initial.animals[i])),
      );
      // TODO: for each publication that had changes to selected animals, make a call to update it
    }
  }

  /**
   * Mark the upload status of the specified array upload component as finished and then convert it to
   * a static resored array component
   * @param array - array component that needs to be updated
   * @param arrayPair - The legacy style array file pair returned from the add to dataset call
   * @param arrayIndex - index of the specified array in the array
   */
  private finishUpload(array: ArrayUploadComponent, arrayPair: newArrayFileset, arrayIndex: number): void {
    array.uploadStatus = 'done';

    // after 2 seconds, convert the completed upload component into a restored array component
    setTimeout(() => {

      const finalReport = arrayPair.files.find(item => item.type == 'final_report');
      const sampleMap = arrayPair.files.find(item => item.type == 'sample_map');

      if (sampleMap && finalReport) {
        this.previouslyUploadedArrays.push({
          platform: array.platform?.value.toLowerCase(),
          sampleMap: { name: sampleMap.name, location: sampleMap.url },
          finalReport: { name: finalReport.name, location: finalReport.url },
        });

        this.arrayUploadForms.splice(arrayIndex, 1);
      }

      // if there is saved animal info, being displayed, then query the inventory just in case
      // it has changed now that another sample map is available
      if (this.animalUpload.savedAnimals.data.length) {
        this.getInventory();
      }
    }, 2000);
  }


  /**
   * Generates a list of "truly" missing animals. For each platform in the dataset, a different Inventory is
   * returned, each with an array of inventory animals and an array of missing animals for that platform.
   * Due to the way these Inventories are generated by platform, if a dataset has multiple platforms, animals
   * from one platform will be considered missing from another platform, which is not necessarily true if
   * those animals are not missing from the platform they were genotyped with
   * @param inventories - inventories to compile a list of truly missing animals from all platforms
   * @private
   */
  private getTrueMissingAnimals(inventories: Inventory[]): MissingInventoryAnimal[] {
    // check if all of the arrays of missing animals have something in them, otherwise there aren't any truly
    // missing animals (due to the nature of how the missing animals arrays are generated for each platform)
    const allPlatformsMissingAnimals = inventories.map((i) => i.missing).every((arr) => arr.length > 0);

    // only take this extra step of double-checking missing mice for datasets that include more
    // than one platform as discrepancies on missing and available mice are not present in
    // datasets with only one platform due to the nature of the discrepancy
    if (inventories.length > 1 && allPlatformsMissingAnimals) {
      // use the first list of missing animals as the "reference"
      const refAnimals: MissingInventoryAnimal[] = inventories[0].missing;

      // other lists of missing animals to compare against, reduced down to mouse ids
      const compIDs: string[][] = inventories.map((i) => i.missing.map((a) => a.mouse_id.trim())).slice(1);

      // search for animals in the reference list that are present in all of the other lists of missing
      // animals aka: search for animals that are missing from ALL platforms (if the animal is only
      // present in the reference array or there's animals in the other arrays that aren't in the
      // reference, this means that animal is only missing from THAT platform but is valid in another
      // and therefore shouldn't be considered "missing")
      return refAnimals.filter((a) => compIDs.every((arr) => arr.indexOf(a.mouse_id.trim()) >= 0));
    } else {
      // compile a total list of both missing animals if there's only one inventory (implying a single platform)
      // or if one of the inventory's array of missing animals is empty
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - Typescript complains about this; something about ConcatArray<never> which is odd
      return [].concat(...inventories.map((inv) => inv.missing));
    }
  }
}
