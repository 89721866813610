<form [id]="'uploadform-' + tag" [style.display]="allowEdit && underFileLimit() ? 'block' : 'none'">
  <input type="hidden" name="pointertype" [value]="pointertype" />
  <input type="hidden" name="pointer" [value]="pointer" />
  <input type="hidden" name="tag" [value]="tag" />
  <div align="center">
    <span style="font-size: 20px; font-weight: 500">
      Upload {{ (title ? title : imageOnly ? 'Image' : 'File') + (optional ? ' (optional)' : '') }}
    </span>
    <br /><br />
  </div>
  <div class="form-horizontal">
    <div class="form-group">
      <label [for]="'upload_method_' + tag" style="padding-top: 0" class="col-sm-3 control-label">
        Upload Method
      </label>
      <div class="col-sm-8">
        <mat-radio-group
          [id]="'upload_method_' + tag"
          [name]="'upload_method_' + tag"
          [(ngModel)]="uploadMethod"
          (change)="uploadMethodOnChange()"
          [disabled]="this.uploadingFile"
        >
          <mat-radio-button value="upload"> Upload Local File </mat-radio-button>
          &nbsp;&nbsp;
          <mat-radio-button value="downloadurl"> Enter Download URL </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div [style.display]="showDescription ? 'block' : 'none'" class="form-group">
      <label [for]="'descrip-' + tag" class="col-sm-3 control-label">
        {{ imageOnly ? 'Image Caption (optional)' : 'File Description (optional)' }}
      </label>
      <div class="col-sm-6">
        <input type="text" [id]="'descrip-' + tag" class="form-control" name="descrip" ngModel maxlength="160" />
      </div>
    </div>
    <div *ngIf="!uploadingFile" class="form-group">
      <div align="right" class="col-sm-3">
        <label *ngIf="showPrivateOption" style="cursor: pointer; padding-top: 7px; white-space: nowrap">
          <input name="private" type="checkbox" />
          &nbsp;Private File
          <span
            *ngIf="pointertype === 'projid'"
            class="glyphicon glyphicon-question-sign"
            matTooltip="Private files are only accessible to SIP Curators and people who have direct access to
                        the project (through the Share Project page), even after the project has been released."
          >
          </span>
        </label>
      </div>
      <div *ngIf="uploadMethod === 'downloadurl'" align="right" class="col-sm-3">
        <label style="cursor: pointer; padding-top: 7px; white-space: nowrap">
          <input name="downpubperm" type="checkbox" />
          &nbsp;Download URL is public and permanent
          <span
            class="glyphicon glyphicon-question-sign"
            matTooltip="Check this if the download URL you are using can be accessed by anyone and does not expire.
                        If this is checked, then SIP will just store the download url and use that for file downloads.
                        If this is not checked, then SIP will download the file and store it for its own use."
          >
          </span>
        </label>
      </div>
      <div *ngIf="uploadMethod === 'upload'" class="col-sm-6">
        <div class="input-group">
          <span class="input-group-btn">
            <span class="btn btn-primary btn-file">
              <b>Browse...</b>
              <input
                (change)="uploadFile()"
                name="file"
                type="file"
                [id]="'file-' + tag"
                [disabled]="uploadingFile"
                [accept]="
                  fileTypes
                    ? fileTypes
                    : '.png, .jpg, .jpeg, .svg, .tiff, .tif, .pdf' +
                      (imageOnly ? '' : ', .txt, .csv, .tsv, .xls, .xlsx')
                "
                [multiple]="multiple"
              />
            </span>
          </span>
          <input
            [value]="selectedFileName"
            readonly="readonly"
            placeholder=""
            class="form-control"
            name="filename"
            type="text"
          />
          <span class="input-group-btn" [matTooltip]="!selectedFileName ? 'Use Browse to select a file to upload' : ''">
            <button
              [disabled]="!selectedFileName || uploadingFile"
              class="btn btn-primary"
              type="button"
              (click)="uploadFile()"
            >
              <b>Upload</b>
            </button>
          </span>
        </div>
      </div>
    </div>
    <div *ngIf="uploadMethod === 'downloadurl'" class="form-group">
      <label [for]="'downloadurl-' + tag" class="col-sm-3 control-label required"> Download URL </label>
      <div class="col-sm-6">
        <input
          type="text"
          [id]="'downloadurl-' + tag"
          class="form-control"
          [(ngModel)]="downloadUrl"
          name="downloadurl"
          maxlength="500"
        />
      </div>
    </div>
    <div *ngIf="uploadMethod === 'downloadurl'" class="form-group">
      <label [for]="'filename-' + tag" class="col-sm-3 control-label required">
        File Name
        <span
          class="glyphicon glyphicon-question-sign"
          matTooltip="File Name that shows for the file in SIP. If a user downloads the file, this is what
                          the name of the file will be.
                          Please include the correct file type, such as .csv or .png, etc."
        ></span>
      </label>
      <div class="col-sm-6">
        <input
          type="text"
          [id]="'filename-' + tag"
          class="form-control"
          name="filename"
          [(ngModel)]="downloadFileName"
          maxlength="160"
        />
      </div>
      <div class="col-sm-3">
        <button
          [disabled]="!downloadUrl || !downloadFileName || uploadingFile"
          class="btn btn-primary"
          type="button"
          (click)="uploadFile()"
        >
          <b>Import File in SIP</b>
        </button>
      </div>
    </div>
  </div>
</form>
<div align="center" *ngIf="errorMessage">
  <span style="color: red">{{ errorMessage }}</span>
  <br /><br />
</div>
<div align="center" *ngIf="uploadingFile">
  <b>Uploading {{ selectedFileName }}...</b>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  <br />
</div>
<div align="center" *ngIf="loadingFiles">
  <b>Loading Uploaded Files...</b>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<div *ngIf="!loadingFiles" align="center">
  <span style="font-size: 20px; font-weight: 500">
    <span *ngIf="uploadedFiles.length > 0">
      <a style="cursor: pointer" (click)="hideFiles = !hideFiles">{{ hideFiles ? 'Show' : 'Hide' }}</a>
      {{ uploadedFiles.length }}
    </span>
    Uploaded {{ title ? title : imageOnly ? 'Image' : 'File' }}
    {{ rowNumsArray.length === 0 ? ' (None)' : '' }}
    <span *ngIf="allowEdit && allowShowArchived">
      <mat-icon
        *ngIf="!showArchivedFiles"
        class="clickable-gray-icon"
        (click)="showArchivedFiles = !showArchivedFiles; areFilesLoaded = false; loadFiles()"
        matTooltip="Show recently deleted files"
        >delete</mat-icon
      >
      <mat-icon
        *ngIf="showArchivedFiles"
        class="clickable-gray-icon"
        (click)="showArchivedFiles = !showArchivedFiles; areFilesLoaded = false; loadFiles()"
        matTooltip="Hide deleted files"
        >delete_forever</mat-icon
      >
    </span>
  </span>
  <br />
  <br />
</div>
<div *ngIf="!hideFiles">
  <div class="row" *ngFor="let nrow of rowNumsArray">
    <!--spacer-->
    <div
      *ngIf="uploadedFiles.length - nrow * 4 < 4"
      [class]="
        uploadedFiles.length - nrow * 4 === 1
          ? 'col-sm-3 col-md-3 col-lg-4'
          : uploadedFiles.length - nrow * 4 === 2
          ? 'col-lg-3'
          : 'col-lg-1'
      "
    ></div>
    <div
      class="col-sm-6 col-md-6 col-lg-3"
      *ngFor="let file of uploadedFiles.slice(nrow * 4, nrow * 4 + 4); let i = index"
    >
      <div class="thumbnail">
        <div align="right">
          <span *ngIf="allowEdit">
            <mat-icon
              *ngIf="!file.deletedtime"
              class="remove-file-icon"
              (click)="removeFile(file)"
              matTooltip="Remove this file"
              >delete</mat-icon
            >
            <!--For some reason, mat-icon doesn't like the dynamic class attribute, so I wrapped it in a span-->
            <span
              *ngIf="file.deletedtime"
              [class]="underFileLimit() ? 'restore-file' : 'restore-file-disabled'"
              [matTooltip]="
                underFileLimit()
                  ? 'Restore this file'
                  : 'Unable to restore this file because you are already at the file limit of ' +
                    fileNumLimit +
                    ' for this type of file.'
              "
              (click)="underFileLimit() ? restoreFile(file) : ''"
            >
              <mat-icon style="font-size: 20px">restore_from_trash</mat-icon>
            </span>
            <span *ngIf="showPrivateOption">
              <mat-icon
                *ngIf="!file.private"
                class="clickable-gray-icon"
                (click)="changePrivate(file, true)"
                matTooltip="File is currently public. Click to make this file private"
                >lock_open</mat-icon
              >
              <mat-icon
                *ngIf="file.private"
                class="clickable-gray-icon"
                (click)="changePrivate(file, false)"
                matTooltip="File is currently private. Click to make this file public"
                >lock</mat-icon
              >
            </span>
          </span>
          <mat-icon class="clickable-gray-icon" (click)="downloadFile(file)" matTooltip="Download this file"
            >file_download</mat-icon
          >
          <mat-icon
            *ngIf="fileIsImage(file)"
            class="clickable-gray-icon"
            (click)="openPreviewDialog(file)"
            matTooltip="Preview this file"
            >fullscreen</mat-icon
          >
          <mat-icon
            *ngIf="fileIsImage(file) && showFileLink && !file.private"
            class="clickable-gray-icon"
            (click)="openFileLink(file)"
            matTooltip="Open link to this file"
            >link</mat-icon
          >
          <mat-icon
            *ngIf="file.filetype === 'pdf'"
            class="clickable-gray-icon"
            (click)="fullscreenPdf(file)"
            matTooltip="Preview this file"
            >fullscreen</mat-icon
          >
        </div>
        <div
          align="center"
          *ngIf="file.filetype === 'pdf' || fileIsImage(file)"
          style="height: 160px; border-bottom: 1px solid lightgrey"
        >
          <img
            *ngIf="fileIsImage(file) && fileBlobCache(file)"
            [src]="buildFileUrl(file) | SafeResourceURL"
            style="cursor: pointer; max-height: 160px; max-width: 100%; border: 1px solid lightgrey"
            (click)="openPreviewDialog(file)"
          />
          <iframe
            *ngIf="file.filetype === 'pdf' && fileBlobCache(file)"
            [src]="buildFileUrl(file) | SafeResourceURL"
            style="width: 100%; height: 160px"
            allowfullscreen="true"
          ></iframe>
        </div>
        <div class="caption" style="overflow-y: auto" [style.height]="showDescription ? '140px' : '105px'">
          <mat-icon
            *ngIf="fileIsImage(file)"
            class="icon"
            style="color: blue"
            [matTooltip]="'Image (' + file.filetype + ')'"
            >image</mat-icon
          >
          <mat-icon *ngIf="file.filetype === 'pdf'" class="icon" style="color: red" [matTooltip]="'PDF'"
            >picture_as_pdf</mat-icon
          >
          <mat-icon
            *ngIf="file.filetype !== 'pdf' && !fileIsImage(file)"
            class="icon"
            style="color: green"
            [matTooltip]="'Spreadsheet or Data (' + file.filetype + ')'"
            >table_chart</mat-icon
          >
          <strong>{{ file.filename }}</strong
          ><br />
          <p *ngIf="showDescription">
            <input
              [id]="'file_caption_' + tag + '_' + (nrow * 4 + i)"
              [(ngModel)]="captionValue"
              [style.display]="checkNumbersEqual(editingCaption, nrow * 4 + i) ? 'block' : 'none'"
              type="text"
              class="form-control"
              maxlength="160"
              (focusout)="editingCaption = null; updateCaption(nrow * 4 + i, captionValue)"
            />
            <span *ngIf="!checkNumbersEqual(editingCaption, nrow * 4 + i)">
              {{ file.descrip ? file.descrip : '(No Caption)' }}
            </span>
            <mat-icon
              *ngIf="!checkNumbersEqual(editingCaption, nrow * 4 + i) && allowEdit"
              class="clickable-gray-icon"
              matTooltip="Edit the caption"
              (click)="focusCaptionInput(nrow * 4 + i)"
              >edit</mat-icon
            >
          </p>
          <small *ngIf="file.deletedtime" style="color: red">
            Deleted: {{ file.deletedtime.slice(5, 22) }}<br />
          </small>
          <small style="color: darkgray">Uploaded: {{ file.uploadedtime.slice(5, 22) }}</small
          ><br />
          <small style="color: darkgray">Uploader: {{ file.uploader.name_or_email }}</small>
          <span *ngIf="file.filesize">
            <br />
            <small style="color: darkgray">File Size: {{ file.filesize }}</small>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
