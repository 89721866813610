// Angular imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// Modules
import { MaterialModule } from '../../shared/material.module';

// Components
import { AboutComponent } from './about/about.component';
import { SideNavComponent } from './side-nav.component';

@NgModule({
  imports: [CommonModule, MaterialModule, RouterModule],
  declarations: [AboutComponent, SideNavComponent],
  exports: [SideNavComponent],
})
export class SideNavModule {}
