import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class StrainService {
  // api url
  private api: string = environment.securedURLs.sip;

  // strains behavioursubject for building an observable
  public strains = new BehaviorSubject<any[]>([]);
  // observable, which can be subscribed to in order to get changes
  // to the strain list any time it is updated
  public strains$ = this.strains.asObservable();
  // flag as to whether the strains list has been initialized yet
  private strainsInitialized = false;
  // lets calling component know when strains are done being loaded
  public strainsLoaded = false;

  // declare http client
  constructor(private http: HttpClient) {
    if (!this.strainsInitialized) {
      this.strainsInitialized = true;
      this.getStrain().subscribe();
    }
  }

  /**
   * Get either a specific strain by id, do a search for a list, or a list of all of them
   *
   * @param {number} id (optional): specific strain id to retrieve
   * @param {number} strainsList (optional): search for a list of strains matching this strainsList
   * @returns {Observable<any>} Observable: observable for the strains get endpoint
   */
  getStrain(id: string = '', strainsList: string = ''): Observable<any> {
    let url = this.api + 'strains';
    if (id) {
      url += '?id=' + id;
    } else if (strainsList) {
      url += '?search_str=' + strainsList;
    }
    return this.http.get<any>(url).pipe(
      tap((result) => {
        if (!id && !strainsList) {
          this.strainsInitialized = true;
          this.strainsLoaded = true;
          // cache the list and trigger the strains$ observable
          // so that all subscribers get the updated list
          this.strains.next(result);
        }
      }),
    );
  }

  /**
   * Sends a http request to create/update a strain's information
   *
   * @param {any} strain - object representing a strain
   * @return {Observable<any>} - updated strain object (on success)
   */
  saveStrain(strain: any): Observable<any> {
    const url = this.api + 'strains';

    return this.http
      .put<any>(url, strain ? strain : {}, { headers: new HttpHeaders().set('Content-Type', 'application/json') })
      .pipe(
        tap((result) => {
          // update the cached strain in case someone else is working on strains
          this.getStrain().subscribe();
          // keeping the manual update because getting the whole list of strains is pretty slow
          const strainsList = this.strains.getValue();
          let strainFound = false;
          for (let i = 0; i < strainsList.length; i++) {
            if (result.pmid === strainsList[i].pmid) {
              strainFound = true;
              strainsList[i] = result;
              break;
            }
          }
          if (!strainFound) {
            strainsList.push(result);
          }
          this.strains.next(strainsList);
        }),
      );
  }

  /**
   * Sends http request to delete a strain and updates cache
   *
   * @param {number} id - ID of the strain to delete
   * @return {Observable<any>} - an observable
   */
  deleteStrain(id: number): Observable<any> {
    const url = this.api + 'strains?id=' + id;

    return this.http.delete<any>(url).pipe(
      tap(() => {
        const strainsList = this.strains.getValue();

        for (let i = 0; i < strainsList.length; i++) {
          if (id === strainsList[i].id) {
            strainsList.splice(i, 1);
            this.strains.next(strainsList);
            break;
          }
        }

        this.getStrain().subscribe();
      }),
    );
  }
}
