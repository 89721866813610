import { Component, OnInit, ViewChild } from '@angular/core';
import { ProjectService } from '../../services/project.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { forceRange } from '../../shared/utils';
import { EditPersonComponent } from '../../shared/entity-editing/edit-person/edit-person.component';
import { InterventionSearchComponent } from '../../shared/entity-searching/intervention-search.component';
import { PersonDialogComponent } from '../../shared/dialogs/person-dialog.component';
import { PublicationDialogComponent } from '../../shared/dialogs/publication-dialog.component';
import { StateButtonsComponent } from '../../shared/state-buttons/state-buttons.component';
import { TooltipPosition } from '@angular/material/tooltip';

@Component({
  selector: 'project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss'],
})
export class ProjectDetailsComponent implements OnInit {
  position: TooltipPosition = 'after';

  private api: string = environment.securedURLs.sip;

  // used to trigger saving of data definitions before going to the process data step
  @ViewChild(StateButtonsComponent) stateButtons!: StateButtonsComponent;

  // used to trigger saving of data definitions before going to the process data step
  @ViewChild(EditPersonComponent) editProfile!: EditPersonComponent;

  // needed to clear the add treatment search
  @ViewChild('addTreatment') addTreatmentComponent!: InterventionSearchComponent;

  // current profile selected by the person-search for pis
  piSearch: any = {};

  // search and add new treatments
  treatmentSearch: any = {};

  // define expgroups editable-table columns
  expgroupsColumns: any[] = [{ key: 'descrip', name: 'Group', class: 'width50-percent', maxlength: 500 }];

  // used to determine whether the wparentals tag should be an option
  riPanels: string[] = [
    'AXB, BXA, BXH, CXB, AKXL',
    'B6.129 and 129.B6 consomic',
    'B6.A consomic',
    'B6.PWD consomic',
    'BXD',
    'CC',
    'CC diallel',
    'CC pre',
    'DO population',
    'ILSXISS',
    'LGXSM',
  ];

  // project id in the route
  projid = '';
  // flag that the project id was invalid or the user doesn't have access
  invalidID = false;

  // hide entry fields until project is done loading
  loadingProject = false;

  // project object that is displayed in the page (and edited if the user make modifications)
  project: any = {
    canEdit: true,
    mpdsector: 'pheno',
    current_user_permission: 'Owner',
    otherpis: [],
    largecollab_rel: {},
    correspondingpi: {},
    panel: {},
    primarypublicationsinfo: [],
    expgroups: [{}],
    userpermissions: [],
    popcount_calc: [],
    treatments: [],
    projlinks: [],
  };
  // project object that represents the state of the project as of the last import (user to compare with
  // the project object when saving/exporting in order to determine what has changed)
  dbProject: any = {
    canEdit: true,
    mpdsector: 'pheno',
    current_user_permission: 'Owner',
    otherpis: [],
    largecollab_rel: {},
    correspondingpi: {},
    panel: {},
    primarypublicationsinfo: [],
    expgroups: [{}],
    userpermissions: [],
    popcount_calc: [],
    treatments: [],
    projlinks: [],
  };

  // makes this function useable in html
  forceRange = forceRange;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private projectService: ProjectService,
  ) {}

  // oninit of the component, load the project from the id in the route. if there is no id in the route, then
  // the user is creating a new project
  ngOnInit() {
    this.projid = this.route.snapshot.paramMap.get('id') || '';
    if (Number(this.projid)) {
      this.getProject();
    } else if (this.projid) {
      this.invalidID = true;
    }
  }

  // get the project from the api
  getProject() {
    this.loadingProject = true;
    this.projectService.getProject(this.projid).subscribe(
      (data) => {
        this.invalidID = data.table_form;
        if (!this.invalidID) {
          this.project = data;
          // I'm not sure if setting both to data would result in them just pointing to the same object,
          // so I'm making sure that it's a deep copy just in case
          this.dbProject = JSON.parse(JSON.stringify(data));
          this.onNcohortsChange();
        }
        this.loadingProject = false;
      },
      () => {
        this.invalidID = true;
        this.loadingProject = false;
      },
    );
  }

  /**
   * Add the pi to the bottom of the current list of pis.
   * Make them corrpi if there are curently no pis, add to other pis if there is a corrpi.
   *
   * @param {any} newPI: person object for the ou to add
   */
  addPi(newPI: any) {
    if (typeof newPI === 'object') {
      if (newPI.id) {
        if (!this.piAlreadyAdded(newPI.id)) {
          newPI.permission = 'None';
          newPI.isOnlyOwner = false;
          for (const userPerm of this.project.userpermissions) {
            if (userPerm.id === newPI.id) {
              newPI.permission = userPerm.permission;
              newPI.isOnlyOwner = userPerm.isOnlyOwner;
            }
          }
          if (!this.project.corrpi) {
            this.project.correspondingpi = JSON.parse(JSON.stringify(newPI));
            this.project.corrpi = newPI.id;
          } else {
            this.project.otherpis.push(JSON.parse(JSON.stringify(newPI)));
          }
        }
      }
    }
  }

  /**
   * Change the permission of the passed-in person id to the passed-in permission.
   * @param {number} id: person id for whom we are changing permission
   * @param {string} permission: string for the permission we're changing to ('Owner', 'Edit', 'View', or 'None').
   *                             since none isn't really a permission, we remove the person's permission in that case
   */
  changePermission(id: number, permission: string) {
    if (id && permission) {
      this.project.userpermissions = [
        {
          id: id,
          permission: permission === 'None' ? null : permission,
          remove: permission === 'None' ? 'true' : null,
        },
      ];
      this.stateButtons.saveProject().subscribe();
    }
  }

  /**
   * Checks whether the permission dropdown should be disabled or not for the passed-in pi.
   * @param {any} pi: pi object for which we are checking whether the permission dropdown should be disabled
   * @returns {boolean}: true if the dropdown should be disabled, false if not
   */
  piPermissionDisabled(pi: any) {
    // if the project doesn't exist, current user can't edit, pi is the only owner,
    // or the pi doesn't have an email, then don't allow permission to be modified
    if (!this.project.projid || !this.project.canEdit || pi.isOnlyOwner || !pi.email) {
      return true;
    }
    // owner level can modify any permission, non-owners can only modify up 'None' or 'View'
    if (this.project.isOwner || ['None', 'View'].indexOf(pi.permission) !== -1) {
      return false;
    }
    // note: could write above if as return !(if condition), but left this way for readability
    return true;
  }

  /**
   * Remove the pi with the passed-in person id from the list of pis.
   *
   * @param {number} id: person id to remove
   */
  removePi(id: number) {
    if (this.piAlreadyAdded(id)) {
      if (id === this.project.corrpi) {
        if (this.project.otherpis.length > 0) {
          // make current top otherpi the new corrpi and shift the other otherpis up
          this.project.corrpi = this.project.otherpis[0].id;
          this.project.correspondingpi = JSON.parse(JSON.stringify(this.project.otherpis[0]));
          this.project.otherpis.splice(0, 1);
        } else {
          this.project.corrpi = null;
          this.project.correspondingpi = {};
        }
      } else {
        const index = this.getOtherPIIndex(id);
        this.project.otherpis.splice(index, 1);
      }
    }
  }

  /**
   * Get the index of the pi within the otherpis array who has a person id matching the one passed in.
   * Note: This function should only really be used in cases where we know the passed-in id is an id for an otherpi.
   *
   * @param {number} id: person id for the otherpi
   * @returns {number} index of the otherpi with person.id === id (99999 if they aren't in the array)
   */
  getOtherPIIndex(id: number): number {
    let index = 99999;
    for (let i = 0; i < this.project.otherpis.length; i++) {
      if (id === this.project.otherpis[i].id) {
        index = i;
        break;
      }
    }
    return index;
  }

  /**
   * Move the pi up or down in the order of pis (switch them with the pi above or below)
   *
   * @param {number} id: person id of the pi we're moving
   * @param {boolean} up: true if we're moving the pi up in the order, false if we're moving them down
   */
  movePi(id: number, up: boolean = true) {
    if (this.piAlreadyAdded(id)) {
      if (id === this.project.corrpi) {
        if (!up && this.project.otherpis.length > 0) {
          // switching corrpi with top otherpi
          const corrpi = JSON.parse(JSON.stringify(this.project.correspondingpi));
          this.project.corrpi = this.project.otherpis[0].id;
          this.project.correspondingpi = JSON.parse(JSON.stringify(this.project.otherpis[0]));
          this.project.otherpis[0] = corrpi;
        }
      } else {
        const index = this.getOtherPIIndex(id);
        const indexTwo = up ? index - 1 : index + 1;
        if (!up && index > this.project.otherpis.length - 2) {
          // can't move bottom one down
        } else if (up && index === 0) {
          // switching with corrpi
          const corrpi = JSON.parse(JSON.stringify(this.project.correspondingpi));
          this.project.corrpi = this.project.otherpis[0].id;
          this.project.correspondingpi = JSON.parse(JSON.stringify(this.project.otherpis[0]));
          this.project.otherpis[0] = corrpi;
        } else {
          // switching 2 otherpis
          const tempPI = JSON.parse(JSON.stringify(this.project.otherpis[index]));
          this.project.otherpis[index] = JSON.parse(JSON.stringify(this.project.otherpis[indexTwo]));
          this.project.otherpis[indexTwo] = tempPI;
        }
      }
    }
  }

  /**
   * Opens the person dialog to view or modify the passed-in profile object
   *
   * @param {any} profile: person/profile object
   */
  openPersonDialog(profile: any): void {
    const personCopy = profile ? JSON.parse(JSON.stringify(profile)) : {};
    const dialogRef = this.dialog.open(PersonDialogComponent, {
      data: { person: personCopy },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.id) {
          this.updateProfile(result);
        }
      }
    });
  }

  /**
   * Function that is executed when the dbProfile in the edit-profile-form changes (is saved/exported or imported)
   * If they are a pi, update their info in the pi displays to match. Also, if 'Save & Add' as clicked, then
   * we add them as a pi.
   *
   * @param {any} profile: latest person object
   */
  updateProfile(profile: any) {
    if (typeof profile === 'object') {
      if (this.piAlreadyAdded(profile.id)) {
        if (profile.id === this.project.corrpi) {
          this.project.correspondingpi = profile;
        } else {
          const index = this.getOtherPIIndex(profile.id);
          if (index < this.project.otherpis.length) {
            this.project.otherpis[index] = profile;
          }
        }
      }
    }
  }

  /**
   * Determines whether or not the passed-in person is is currently a corrpi or otherpi
   *
   * @param {number} id: person id
   * @returns {boolean}: true if the person is a pi, false if they are not
   */
  piAlreadyAdded(id: number): boolean {
    if (!id) {
      return false;
    }
    let piFound = id === this.project.corrpi;
    if (!piFound) {
      for (let i = 0; i < this.project.otherpis.length; i++) {
        if (id === this.project.otherpis[i].id) {
          piFound = true;
          break;
        }
      }
    }
    return piFound;
  }

  // when the authorship radio button changes, updates the pistring accordingly
  onAuthorshipTypeChange() {
    if (this.project.instauth) {
      if (this.project.largecollab && this.project.centerauth) {
        this.project.pistring = this.project.largecollab_rel.name;
      } else {
        this.project.pistring = '';
      }
    }
  }

  // when the largecollab changes, make sure that the pistring matches
  onCollabChange() {
    if (this.project.largecollab_rel.tag) {
      if (this.project.instauth && !this.project.centerauth) {
        this.project.pistring = this.project.largecollab_rel.name;
      }
      this.project.largecollab = this.project.largecollab_rel.tag;
    } else {
      this.project.largecollab = null;
    }
  }

  // on change of number of experimental groups, enforce range and update # of rows in
  // the experimental group descriptions table
  onNcohortsChange() {
    this.project.ncohorts = this.forceRange(this.project.ncohorts, 1, 9999);
    const n = this.project.ncohorts ? this.project.ncohorts : 1;
    if (n > this.project.expgroups.length) {
      for (let i = this.project.expgroups.length; i < n; i++) {
        this.project.expgroups.push({ listingorder: i });
      }
    } else if (n < this.project.expgroups.length) {
      for (let i = this.project.expgroups.length - n; i > 0; i--) {
        this.project.expgroups.pop();
      }
    }
  }

  /**
   * On clearing of a linked treatment, remove it from the list
   * @param {number} index: index of the changed treatment in project.treatment
   */
  onChangeTreatment(index: number) {
    if (index < this.project.treatments.length) {
      if (!this.project.treatments[index].term) {
        this.project.treatments.splice(index, 1);
      }
    }
  }

  /**
   * When a treatment is selected to add, add it to the list of treatments
   * and clear the search so new ones can be added
   */
  onAddTreatment() {
    if (this.treatmentSearch ? this.treatmentSearch.term : false) {
      this.project.treatments.push(this.treatmentSearch);
      this.addTreatmentComponent.clearSelection();
    }
  }

  /**
   * On change of the primary or related publications, trim and sort them and attempt to retrieve
   * and display (preview) the publication info for them
   * @param {string} pubtype: type of publication ('primary', or 'reference')
   */
  pubsChange(pubtype: string) {
    if (this.project[pubtype + 'publications']) {
      const trimmedList = [];
      for (const pmid of this.project[pubtype + 'publications'].split(',')) {
        const trimmedPMID = pmid.trim();
        if (trimmedPMID) {
          if (trimmedList.indexOf(trimmedPMID) === -1) {
            trimmedList.push(trimmedPMID);
          }
        }
      }
      const infoPMIDs = this.project[pubtype + 'publicationsinfo'].map(function (a: any) {
        return a.pmid;
      });
      // remove pmids from the info that are not in the new trimedList
      for (const pmid of infoPMIDs) {
        if (trimmedList.indexOf(pmid) === -1) {
          this.project[pubtype + 'publicationsinfo'].splice(infoPMIDs.indexOf(pmid), 1);
        }
      }
      // the sorting will also remove duplicates from publications, since it re-maps the list of pmids based
      // on the list of publications info
      this.sortPublications(pubtype);
      // add pmids to the info that are in the new trimedList and not in the info
      for (const pmid of trimmedList) {
        if (infoPMIDs.indexOf(pmid) === -1) {
          this.http.get<any>(this.api + 'publications?pmid=' + pmid).subscribe((result) => {
            // when we get the publication info back, add it and sort the preview by authorlist
            this.project[pubtype + 'publicationsinfo'].push(result);
            this.sortPublications(pubtype);
          });
        }
      }
    } else {
      this.project[pubtype + 'publicationsinfo'] = [];
    }
  }

  /**
   * Sort the given publication type by author (also removes duplicates)
   *
   * @param {string} pubtype: type of publication ('primary', or 'reference')
   * */
  sortPublications(pubtype: string) {
    this.project[pubtype + 'publicationsinfo'].sort(function (a: any, b: any) {
      if (a.error || !a.authorlist) {
        return 1;
      } else if (b.error || !b.authorlist) {
        return -1;
      }
      const x = a.authorlist.toLowerCase();
      const y = b.authorlist.toLowerCase();
      return x > y ? 1 : -1;
    });
    // map to the comma-separed string of the pmids in the same order
    this.project[pubtype + 'publications'] = this.project[pubtype + 'publicationsinfo']
      .map(function (a: any) {
        return a.pmid;
      })
      .join(', ');
  }

  // open a dialog to search or create a publication
  openPubDialog(pubtype: string): void {
    const dialogRef = this.dialog.open(PublicationDialogComponent, {
      data: { publication: {}, embedShowSearch: true },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.pmid) {
          if (this.project[pubtype + 'publications'].indexOf(result.pmid) === -1) {
            this.project[pubtype + 'publicationsinfo'].push(result);
            this.sortPublications(pubtype);
          }
        }
      }
    });
  }
}
