import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Person } from '../../services/person.service';

// dialog for adding/editing/viewing people/investigators on-the-fly
@Component({
  selector: 'person-dialog',
  template: `
    <div mat-dialog-content style="min-width:800px;">
      <div align="center">
        <span class="text16">
          <b>
            <span *ngIf="pi_form.profile.allow_changes && data.person.id">Edit </span>
            <span *ngIf="!data.person.id">Create </span>
            <span *ngIf="!pi_form.profile.allow_changes && data.person.id">View </span>
            <span>Investigator</span>
          </b>
        </span>
      </div>
      <div align="center" *ngIf="!pi_form.profile.allow_changes && data.person.id">
        <br />
        Contact SIP curator to update this information.
        <br />
      </div>
      <br />
      <edit-person
        #pi_form
        [(id)]="data.person.id"
        [uniqueTag]="'PersonDialogue'"
        [noAutosave]="true"
        [requireEmail]="data.requireEmail"
        [(dbProfile)]="data.person"
        (save)="dialogRef.close($event)"
      >
      </edit-person>
    </div>
    <br />
    <div class="form-horizontal" mat-dialog-actions>
      <div class="form-group">
        <div [class]="pi_form.profile.allow_changes ? 'col-sm-2' : 'col-sm-4'"></div>
        <div [matTooltip]="pi_form.saveDisabledTooltip()" *ngIf="pi_form.profile.allow_changes" class="col-sm-4">
          <button
            type="button"
            (click)="pi_form.saveProfile().subscribe()"
            [disabled]="pi_form.saveDisabled()"
            class="btn btn-success btn-block"
          >
            <strong>Save</strong>
          </button>
        </div>
        <div class="col-sm-4">
          <button type="button" (click)="dialogRef.close()" class="btn btn-default btn-block">
            <strong *ngIf="!pi_form.profile.allow_changes">Close</strong>
            <strong *ngIf="pi_form.profile.allow_changes">Cancel</strong>
          </button>
        </div>
      </div>
    </div>
  `,
})
export class PersonDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<PersonDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PersonDialogData,
  ) {}
}

export interface PersonDialogData {
  person: Person;
  requireEmail: boolean;
}
