import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class InstitutionService {
  // api url
  private api: string = environment.securedURLs.sip;

  // institution behavioursubject for building an observable
  public institutions = new BehaviorSubject<any[]>([]);
  // observable, which can be subscribed to in order to get changes
  // to the institution list any time it is updated
  public institutions$ = this.institutions.asObservable();
  // flag as to whether the institutions list has been initialized yet
  private institutionsListInitialized = false;

  // declare http client
  constructor(private http: HttpClient) {
    if (!this.institutionsListInitialized) {
      this.institutionsListInitialized = true;
      this.getInstitution().subscribe();
    }
  }

  /**
   * Sends a http request to GET either a specific institution or a list of all of them
   *
   * @param {number} id: specific institution to retrieve or null to get a list of all institutions
   * @returns {Observable<any>} Observable
   */
  getInstitution(id: number = 0): Observable<any> {
    let url = this.api + 'institutions';

    if (id) {
      url += '?id=' + String(id);
    }
    return this.http.get<any>(url).pipe(
      tap((result) => {
        if (!id) {
          this.institutionsListInitialized = true;
          // cache the list and trigger the institutions$ observable
          // so that all subscribers get the updated list
          this.institutions.next(result);
        }
      }),
    );
  }

  /**
   * Sends a http request to create/update an institution's information
   *
   * @param {any} institution - object representing an institution
   * @return {Observable<any>} - updated institution object (on success)
   */
  saveInstitution(institution: any): Observable<any> {
    const url = this.api + 'institutions';

    return this.http
      .put<any>(url, institution ? institution : {}, {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
      })
      .pipe(
        tap(() => {
          // update the cached institutions in case someone else is working on institutions
          this.getInstitution().subscribe();
        }),
      );
  }

  /**
   * Sends http request to delete an institution and updates cache
   *
   * @param {number} id - ID of the institution to delete
   * @return {Observable<any>} - an observable
   */
  deleteInstitution(id: number): Observable<any> {
    const url = this.api + 'institutions?id=' + id;

    return this.http.delete<any>(url).pipe(
      tap(() => {
        const institutionsList = this.institutions.getValue();

        for (let i = 0; i < institutionsList.length; i++) {
          if (id === institutionsList[i].id) {
            institutionsList.splice(i, 1);
            this.institutions.next(institutionsList);
            break;
          }
        }

        this.getInstitution().subscribe();
      }),
    );
  }
}
