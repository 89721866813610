<!-- Project Links Header Row, hidden on tablet and mobile view-->
<div *ngIf="projectLinks?.length" class="form-group">
  <div class="row hidden-sm hidden-xs">
    <div class="col-md-1"></div>
    <div class="col-md-3">
      <label>Data Type</label>
    </div>
    <div class="col-md-3">
      <label>Data URL</label>
    </div>
    <div class="col-md-4">
      <label>Description</label>
    </div>
    <div class="col-md-1"></div>
  </div>
</div>

<!-- Project Link Data Rows-->
<div *ngFor="let link of projectLinks">
  <!-- Begin Parent Row -->
  <div class="row">
    <div class="col-md-1 form-group action-icon-add-container">
      <span
        *ngIf="canEdit"
        matTooltip="Add derived data"
        (click)="addChildLink(link)"
        class="glyphicon glyphicon-plus btn action-icon-add"
      ></span>
    </div>
    <div class="col-md-3 form-group">
      <label for="datatype" class="visible-sm visible-xs">Data Type</label>
      <select [(ngModel)]="link.datatype" class="form-control" id="datatype">
        <!-- TODO:  AA 05/20/2020
                        Hard coding these for TCI 45, in a future ticket they
                        will be populated from the metadata service -->
        <option value="3D nucleome">3D nucleome</option>
        <option value="Bulk ATAC-Seq">Bulk ATAC-Seq</option>
        <option value="Bulk RNA-Seq">Bulk RNA-Seq</option>
        <option value="Microbiome">Microbiome</option>
        <option value="qPCR">qPCR</option>
        <option value="scATAC-Seq">scATAC-Seq</option>
        <option value="scRNA-Seq">scRNA-Seq</option>
        <option value="Structural Variants">Structural Variants</option>
        <option value="Other">Other</option>
      </select>
    </div>
    <div class="col-md-3 form-group">
      <label for="url" class="visible-sm visible-xs">Data URL</label>
      <input type="text" [(ngModel)]="link.url" class="form-control" maxlength="500" id="url" />
    </div>
    <div class="col-md-4 form-group">
      <label for="description" class="visible-sm visible-xs">Description</label>
      <input type="text" [(ngModel)]="link.descrip" class="form-control" maxlength="500" id="description" />
    </div>
    <div *ngIf="canEdit" class="col-md-1 action-icon-delete-container">
      <span
        *ngIf="canEdit"
        matTooltip="Delete link"
        (click)="removeProjectLink(link.id)"
        class="glyphicon glyphicon-trash btn action-icon-delete"
      ></span>
    </div>
    <!-- End Parent Row -->
  </div>
  <div *ngFor="let childlink of link.children">
    <!-- Begin Child Row -->
    <div class="row child-row">
      <div class="col-md-1 child-row-icon-container">
        <mat-icon class="child-row-icon">subdirectory_arrow_right</mat-icon>
      </div>
      <div class="col-md-3 form-group">
        <label for="child-datatype" class="visible-sm visible-xs">Data Type</label>
        <select [(ngModel)]="childlink.datatype" class="form-control" id="child-datatype">
          <option value="Alignments">Alignments</option>
          <option value="eQTL">eQTL</option>
          <option value="Expression Counts">Expression Counts</option>
          <option value="Expression Normalized">Expression Normalized</option>
          <option value="Gene Counts">Gene Counts</option>
          <option value="Module Eigengenes">Module Eigengenes</option>
          <option value="Peak Calls">Peak Calls</option>
          <option value="Raw Reads">Raw Reads</option>
          <option value="Signal">Signal</option>
          <option value="Splice Junctions">Splice Junctions</option>
          <option value="Transcript Counts">Transcript Counts</option>
          <option value="Other">Other</option>
        </select>
      </div>
      <div class="col-md-3 form-group">
        <label for="child-url" class="visible-sm visible-xs">Data URL</label>
        <input type="text" [(ngModel)]="childlink.url" class="form-control" maxlength="500" id="child-url" />
      </div>
      <div class="col-md-4 form-group">
        <label for="child-description" class="visible-sm visible-xs">Description</label>
        <input
          type="text"
          [(ngModel)]="childlink.descrip"
          class="form-control"
          maxlength="500"
          id="child-description"
        />
      </div>
      <div class="col-md-1 action-icon-delete-container">
        <span
          *ngIf="canEdit"
          matTooltip="Delete link"
          (click)="removeChildLink(link, childlink.id)"
          class="glyphicon glyphicon-trash btn action-icon-delete"
        ></span>
      </div>
    </div>
    <!-- End Child Row -->
  </div>
</div>
<hr *ngIf="projectLinks?.length" />

<!-- Add Project Link Button-->
<div *ngIf="canEdit" class="row">
  <div class="col-md-1 action-icon-add-container">
    <button (click)="addProjectLink()" type="button" class="btn btn-success" matTooltip="Add link">
      <span class="glyphicon glyphicon-plus clear-icon"></span>
    </button>
  </div>
  <div class="col-md-11">
    <span *ngIf="!projectLinks?.length" class="button-message"> Use this button to add links to the project </span>
  </div>
</div>
