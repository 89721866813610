<!-- only visible when the 'chart' object is instantiated -->
<div class="row">
  <div class="col-md-12 bg-white">
    <div class="col-md-6 text-left">
      <fieldset class="plot-control-panel-border">
        <legend class="plot-control-panel-border dark-cerulean">
          Informational Variables
          <span
            class="glyphicon glyphicon-question-sign"
            matTooltip="Select first filter criteria from Variable 1 list and
              add second filter criteria from Variable 2 list."
            matTooltipPosition="after"
            matTooltipHideDelay="1000"
          >
          </span>
        </legend>
        <div class="row">
          <form class="form-horizontal">
            <div class="form-group col-md-6 text-left">
              <label for="series-infovar1-sel" class="col-sm-4 control-label noh-padding">Variable 1</label>
              <div class="col-sm-8">
                <select
                  id="series-infovar1-sel"
                  name="series-infovar1-sel"
                  class="form-control input-sm"
                  [ngModel]="selectedFactorOption"
                  (ngModelChange)="oninfoVarChange($event, 1)"
                >
                  <option value="--">--</option>
                  <option
                    *ngFor="let infoVar of phenoInfoVariables"
                    [value]="infoVar"
                    [disabled]="selectedFactor2Option === infoVar"
                  >
                    {{ infoVar }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group col-md-6 text-left">
              <label for="series-infovar2-sel" class="col-sm-4 control-label noh-padding">Variable 2</label>
              <div class="col-sm-8">
                <select
                  id="series-infovar2-sel"
                  name="series-infovar2-sel"
                  class="form-control input-sm"
                  [ngModel]="selectedFactor2Option"
                  (ngModelChange)="oninfoVarChange($event, 2)"
                  [disabled]="selectedFactorOption === '--'"
                >
                  <option value="--">--</option>
                  <option
                    *ngFor="let infoVar of phenoInfoVariables"
                    [value]="infoVar"
                    [disabled]="selectedFactorOption === infoVar"
                  >
                    {{ infoVar }}
                  </option>
                </select>
              </div>
            </div>
          </form>
        </div>
      </fieldset>
    </div>

    <div class="col-md-6 text-left">
      <fieldset class="plot-control-panel-border">
        <legend class="plot-control-panel-border dark-cerulean">Plot Options</legend>
        <label for="over-time-mean-std-show" class="mpd-checkbox col-sm-3">
          <input
            id="over-time-mean-std-show"
            type="checkbox"
            (change)="onShowMeanStdDevChange($event.target.checked)"
          />
          <span></span>
          Mean +/- 1 SD
        </label>
        <label for="series-sort-alphanumeric" class="mpd-checkbox col-sm-4">
          <input id="series-sort-alphanumeric" type="checkbox" (change)="onSortAlphaNumericChange($event)" />
          <span></span>
          Sort X-Axis Values
        </label>
      </fieldset>
    </div>
  </div>
</div>
<!-- END PLOT CONTROLS DIV -->

<div class="row">
  <div class="col-md-12 text-center font-09em bg-white">
    <b>Tip</b>: Brush with the mouse over points to select them and click twice on an empty plot аrеа to deselect all
    selected points.
    <br />
    Hold down Shift key to pan.
    <span *ngIf="seriesThresholdWarning"> <b>Warning</b>: {{ seriesThresholdWarning }} </span>
  </div>
</div>

<div class="row">
  <div class="col-md-12 bg-white">
    <div id="container-lt-female"></div>
    <div id="container-lt-male"></div>
  </div>
</div>
<br />
<div *ngIf="chartF || chartM">
  <app-table
    [rows]="selectedTableData"
    [columns]="visTableCols"
    [defPageSize]="5"
    [exportFormats]="exportFormats"
    title="Data Table"
    (actionClick)="deselectPoint($event.row)"
  >
  </app-table>
</div>
<br />
