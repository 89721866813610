<div [style.display]="project.canEdit && !noSave ? 'block' : 'none'" class="form-horizontal">
  <div class="form-group">
    <div [class]="'col-xs-0 col-md-' + (nextPage ? '2' : '3')"></div>
    <div class="col-xs-6 col-md-2 margin-top-bottom-">
      <button class="btn btn-success btn-block" id="btn-save" (click)="saveProject().subscribe()">
        <strong>Save</strong>
      </button>
    </div>
    <div *ngIf="nextPage" class="col-xs-6 col-md-2 margin-top-bottom-2">
      <button class="btn btn-success btn-block" id="btn-save-next" (click)="saveProject(true).subscribe()">
        <strong>Save &amp; Next</strong>
      </button>
    </div>
    <div class="col-xs-6 col-md-2 margin-top-bottom-2">
      <button class="btn btn-success btn-block" id="btn-save-exit" (click)="saveProject(false, true).subscribe()">
        <strong>Save &amp; Exit</strong>
      </button>
    </div>
    <!--NOTE: Currently, the exit button sets noAutosave to true so that we can exit
              without autosaving in order to discard unsaved changes-->
    <div class="col-xs-6 col-md-2 margin-top-bottom-2">
      <a
        (click)="noAutosave = true"
        routerLink="/projects"
        [queryParams]="{ userid: 'current' }"
        class="btn btn-danger btn-block"
        id="btn-exit"
        ><strong>Exit</strong></a
      >
    </div>
    <div class="col-xs-0 col-md-2"></div>
  </div>
  <div [style.display]="noTitleMessage ? 'block' : 'none'" align="center" style="color: red">
    <b
      >Please enter a <a style="cursor: pointer" (click)="clickTitleLink.emit(true)">Project Title</a> before saving
      this project.
    </b>
  </div>
  <div [style.display]="errorMessage ? 'block' : 'none'" align="center" style="color: red">
    <b>{{ errorMessage }}</b>
  </div>
</div>
<div *ngIf="!project.canEdit || noSave" class="form-horizontal">
  <div class="form-group">
    <div [class]="'col-xs-0 col-md-' + (nextPage ? '4' : '5')"></div>
    <div *ngIf="nextPage" class="col-xs-6 col-md-2 margin-top-bottom-2">
      <a [routerLink]="nextPage + project.projid" class="btn btn-info btn-block" id="btn-view-next"
        ><strong>Next</strong></a
      >
    </div>
    <div class="col-xs-6 col-md-2 margin-top-bottom-2">
      <a
        routerLink="/projects"
        [queryParams]="{ userid: 'current' }"
        class="btn btn-danger btn-block"
        id="btn-view-exit"
        ><strong>Exit</strong></a
      >
    </div>
  </div>
</div>
