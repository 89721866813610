import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class PersonService {
  // api url
  private api: string = environment.securedURLs.sip;

  // current user behavior subject
  public currentUser = new BehaviorSubject<any>(null);

  // current user observable; subscribe to get the current user after updates
  public currentUser$ = this.currentUser.asObservable();

  // indicates if currentUser has been initialized
  private currentUserInitialized = false;

  constructor(private http: HttpClient) {
    if (!this.currentUserInitialized) {
      this.currentUserInitialized = true;
      this.getPerson().subscribe();
    }
  }

  /**
   * Determine if the current user is a curator
   * NOTE: The personService must have been initialized before calling this function
   *
   * @returns {boolean}: true if the current user is a curator,
   *                     false if they are not (or we failed to get the current user)
   */
  isCurator() {
    if (this.currentUserInitialized) {
      const user = this.currentUser.getValue();
      if (!user) {
        return false;
      }
      return ['Curator', 'Reviewer'].indexOf(user.approle) !== -1;
    }
    return false;
  }

  /**
   * Determine if the current user is a reviewer
   * NOTE: The personService must have been initialized before calling this function
   *
   * @returns {boolean}: true if the current user is a reviewer,
   *                     false if they are not (or we failed to get the current user)
   */
  isReviewer() {
    if (this.currentUserInitialized) {
      const user = this.currentUser.getValue();
      if (!user) {
        return false;
      }
      return user.approle === 'Reviewer';
    }
    return false;
  }

  /**
   * Determines whether there is a user, and if they are allowed to edit anything other than their profile
   * based on whether they are restricted (based on the whitelist in the app settings)
   * or if their email isn't verified yet.
   *
   * NOTE: This doesn't determine whether or not the user has permission to edit any particular entity.
   *
   * @returns {boolean} true if the  user can edit
   */
  userUnrestricted(): boolean {
    if (this.currentUserInitialized) {
      const user = this.currentUser.getValue();
      if (!user) {
        return false;
      }
      return user.emailverified && !user.restricted;
    }
    return false;
  }

  /**
   * Determine if the passed-in id is the current user
   * NOTE: The personService must have been initialized before calling this function
   *
   * @param {number} id: person id
   * @returns {boolean}: true if the passed-in id is the current user,
   *                     false if it is not (or we failed to get the current user)
   */
  isCurrentUser(id: number = 0) {
    if (!id) {
      return false;
    }
    if (this.currentUserInitialized) {
      const user = this.currentUser.getValue();
      if (!user) {
        return false;
      }
      return Number(id) === Number(user.id);
    }
    return false;
  }

  /**
   * Observable, which can be subscribed to in order to get a list of people (array of objects)
   * matching the inputs in the result.
   *
   * @param {string} searchStr: String to partial match any columns in the person row. Words separated by commas and/or
   *                             spaces are separately filtered in the query and the filtered results are ANDED together
   * @param {string} user: True to filter out people who are not user...
   *                         'email' if we are only requiring an email (since we can create and link a user
   *                         if there is an email),
   *                         otherwise, then we are requiring an already linked userid,
   *                       false to include non-users
   * @param {number} limit: limit on the number of results (optional), mutually exclusive with offset
   * @param {number} offset: offset to the returned results (optional), mutually exclusive with limit
   * @param {boolean} nameRequired: True to only include records with a first and last name, False or null to still
   *                                 include records if they are missing a first and/or last name
   * @returns {Observable<any>}: Result is an array of person objects that match the inputs on success
   */
  getPeople(
    searchStr: string = '',
    user: string = '',
    limit: number = 0,
    offset: number = 0,
    nameRequired: boolean = false,
  ): Observable<any[]> {
    let url = `${this.api}profile?search_str=${searchStr}`;
    if (user) {
      url += '&user=' + user;
    }

    if (nameRequired) {
      url += '&name_required=true';
    }

    if (limit) {
      url += '&limit=' + String(limit);
    } else if (offset) {
      url += '&offset=' + String(offset);
    }

    return this.http.get<any[]>(url);
  }

  /**
   * Observable for getting a person object
   *
   * @param {number} id: id of the person to get or null to get the current_user
   * @returns {Observable<any>}: Result is a person object on success
   */
  getPerson(id: number = 0): Observable<Person> {
    let url = this.api + 'profile';
    if (id) {
      url += '?id=' + String(id);
    }
    return this.http.get<Person>(url).pipe(
      tap((result) => {
        const currentUser = this.currentUser.getValue();
        if (!id || (currentUser ? Number(result.id) === Number(currentUser.id) : false)) {
          this.currentUserInitialized = true;
          this.currentUser.next(result);
        }
      }),
    );
  }

  /**
   * Observable for saving a person
   *
   * @param {any} profile: any containing person data to save
   *                          - id included for updating a person, not included to create a new person
   *                          - verifycode = '' to send a new email verification code
   *                          - verifycode = non-blank string to attempt to verify email with the code
   * @returns {Observable<any>}: the updated person object on success (not all changes sent may have been allowed)
   */
  savePerson(profile: any): Observable<any> {
    const url = this.api + 'profile';
    return this.http
      .put<any>(url, profile ? profile : {}, { headers: new HttpHeaders().set('Content-Type', 'application/json') })
      .pipe(
        tap((result) => {
          if (Number(result.id) === Number(this.currentUser.getValue().id)) {
            this.currentUser.next(result);
          }
        }),
      );
  }

  /**
   * Observable for deleting a person
   *
   * @param {number} id: id of the person to get or null to get the current_user
   * @returns {Observable<any>}: Result is a 204 code on success
   */
  deletePerson(id: number): Observable<any> {
    const url = this.api + 'profile?id=' + String(id);
    return this.http.delete<any>(url);
  }
}
// defining the structure of data received from the API
/* eslint-disable camelcase */
export interface Person {
  allowChanges?: boolean;
  approle: string;
  candelete?: boolean;
  caneditpeople?: Person[];
  citname?: string;
  degree: string;
  display_str: string;
  editors: Person[];
  email: string;
  emailverified: boolean;
  firstname: string;
  fullname: string;
  groups?: any[]; // TODO: need a Group interface
  id: number;
  inst: number;
  institution: any; // TODO: need an Institution interface
  isOnlyOwner?: boolean;
  is_current_user?: boolean;
  is_sso_user: boolean;
  isuser: boolean;
  lastname: string;
  middlename: string;
  name_or_email: string;
  orcid: number;
  owner?: boolean;
  permission?: string;
  restricted?: boolean;
  search_str?: string;
  suffix: string;
}
/* eslint-enable camelcase */
