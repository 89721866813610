import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// components
import { StateButtonsComponent } from '../../shared/state-buttons/state-buttons.component';
// services
import { ProjectService } from '../../services/project.service';

@Component({
  selector: 'qc-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./data-validation.scss'],
})
export class QCDashboardComponent implements OnInit {
  // used to trigger saving of data definitions before going to the process data step
  @ViewChild(StateButtonsComponent) stateButtons!: StateButtonsComponent;

  // project id (from the route)
  projectId: string | null = '';
  // 'false' when 'project id' is either invalid or when user does not have permission to access the project
  isIdInvalid = false;
  // project object that is displayed in the page (and edited when user makes modifications)
  project: any = { projdoc: {} };
  // project object that represents the state of the project as of the last import (user to compare with
  // the project object when saving/exporting in order to determine what has changed)
  dbProject: any = { projdoc: {} };

  importMsgs: any[] = [];

  summary: any = {};

  constructor(private route: ActivatedRoute, private projectService: ProjectService, public router: Router) {}

  // on component initiation, load the QC information using the id in the route;
  // if there is no id in the route, then the user is creating a new project
  ngOnInit() {
    this.projectId = this.route.snapshot.paramMap.get('id') || null;

    if (Number(this.projectId)) {
      this.pullProjectData();
    } else {
      this.isIdInvalid = true;
    }
  }

  /**
   * subscribes to a service, which returns an observable containing project information;
   * populates the 'import_msgs' array with data that will be presented in a summary.
   */
  pullProjectData() {
    if (this.projectId) {
      this.projectService.getProject(this.projectId).subscribe(
        (data) => {
          this.isIdInvalid = data.table_form;

          if (!this.isIdInvalid) {
            this.project = data;

            this.dbProject = JSON.parse(JSON.stringify(data));

            if (data.import_msgs) {
              this.importMsgs = data.import_msgs;
            }

            this.createSummary();
          }
        },
        () => {
          this.isIdInvalid = true;
        },
      );
    }
  }

  /**
   * when projects are imported from external resources messages reporting on certain
   * data characteristics might be logged: this method scans any messages and creates
   * a summary of the message types and counts.
   */
  createSummary(): void {
    // clear possible pre-existing properties
    for (const member in this.summary) {
      delete this.summary[member];
    }
    // populate new properties
    for (const msg of this.importMsgs) {
      // each message is structured 'message category: message content', so split the
      // message string at the first '':" and check if the first element is a single word
      // [gik 5/5/20] - data model is currently being changed to separate message category
      // from message text, which will eliminate larger portion of  the below code.
      const cat = msg.category;

      if (this.summary[cat]) {
        this.summary[cat] = this.summary[cat] + 1;
      } else {
        this.summary[cat] = 1;
      }
    }
  }
}
