import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class GroupService {
  // api url
  private api: string = environment.securedURLs.sip;

  // declare http client
  constructor(private http: HttpClient) {}

  /**
   * Observable, which can be subscribed to in order to get a group or list of groups matching the inputs in the result.
   * The parameters are currently all optional and mutually exclusive (we only use one at a time).
   * If none of the arguments are used, then you get a list of groups that the current user is in.
   *
   * @param {string} id: id of the (single) group to get
   * @param {string} userid: userid for which to get a list of groups that they are in
   * @returns {Observable<any>}: either a single group, or an array of groups, depending on the input
   */
  getGroup(id: string = '', userid: string = ''): Observable<any> {
    let url = this.api + 'groups';
    if (id) {
      url += '?id=' + String(id);
    } else if (userid) {
      url += '?userid=' + String(userid);
    }
    return this.http.get<any[]>(url);
  }

  /**
   * Observable, which can be subscribed to in order to get a list of groups matching the strainsList
   * (or all groups if strainsList is a blank string)
   *
   * @param {string} searchStr: String to partial match any the group name. Words separated by commas and/or
   *                            spaces are separately filtered in the query and the filtered results are ANDED together.
   * @param {number} limit: limit on the number of results (optional), mutually exclusive with offset
   * @param {number} offset: offset to the returned results (optional), mutually exclusive with limit
   * @returns {Observable<any>}: Result is json with an array of groups matching strainsList
   */
  getGroups(searchStr: string = '', limit: number = 0, offset: number = 0): Observable<any> {
    let url = this.api + 'groups?search_str=' + searchStr;
    if (limit) {
      url += '&limit=' + String(limit);
    } else if (offset) {
      url += '&offset=' + String(offset);
    }
    return this.http.get<any[]>(url);
  }

  /**
   * Observable for saving a group
   *
   * @param {any} group: any containing group data to save
   * @returns {Observable<any>}: updated group object on success (not all changes sent may have been allowed)
   */
  saveGroup(group: any): Observable<any> {
    const url = this.api + 'groups';
    return this.http.put<any>(url, group ? group : {}, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    });
  }

  /**
   * Observable for deleting a group
   *
   * @param {number} id: id of the group to delete
   * @returns {Observable<any>}: Result is a 204 code on success
   */
  deleteGroup(id: number): Observable<any> {
    const url = this.api + 'groups?id=' + String(id);
    return this.http.delete<any>(url);
  }
}
