import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'about',
  template: `
    <h2>
      About Study Intake Platform<br />
      <small style="color:darkgray;">Version: {{ version }}</small>
    </h2>
    <br />
    <p>
      The Study Intake Platform (SIP) was created by the
      <a href="https://phenome.jax.org/" target="_blank">Mouse Phenome Database (MPD)</a> team for data curation. The
      team has recently expanded SIP to be a general-purpose data curation and study intake interface.
    </p>
    <p>
      More information about the project can be found in the <a (click)="navigate()">documentation</a> and the team can
      be reached by emailing <a href="mailto:sipsupport@jax.org">sipsupport@jax.org</a>.
    </p>
    <br />
    <h4>Team</h4>
    <div style="display: flex;justify-content: space-between">
      <ul>
        <li>Molly Bogue, PhD<br /><i>Principal Investigator</i></li>
        <li>Elissa Chesler, PhD<br /><i>Co-Investigator</i></li>
        <li>Vivek Philip, PhD<br /><i>Co-Investigator and Analytic Lead</i></li>
        <li>Robyn Ball, PhD<br /><i>Computational Scientist</i></li>
        <li>Dave Walton, BS<br /><i>Software Technical Lead for MPD Functionality</i></li>
        <li>Jake Emerson, MS<br /><i>Software Technical Lead for Cube Functionality</i></li>
        <li>Matt Dunn, BS<br /><i>SIP Lead Developer</i></li>
        <li>Stephen Grubb, MS<br /><i>Developer</i></li>
      </ul>

      <ul>
        <li>Georgi Kolishovski, MS<br /><i>Developer</i></li>
        <li>Alex Berger, BS<br /><i>Developer</i></li>
        <li>Abigail Ames, BS<br /><i>Metadata Support Functionality</i></li>
        <li>Anna Lamoureux, BS<br /><i>User Experience and Interface Design</i></li>
        <li>Beth Sundberg, MS<br /><i>Software Quality Assurance</i></li>
        <li>Gaurab Mukherjee, PhD<br /><i>Biocuration Scientist</i></li>
        <li>Beena Kadakkuzha, PhD<br /><i>Project Manager</i></li>
      </ul>
    </div>
    <br />
    <p>
      <i>
        This resource is supported by NIH NIDA Grants DA028420, DA045401, and The Jackson Laboratory Cube Initiative.
      </i>
    </p>
  `,
  styles: ['li { padding: 3px }'],
})
export class AboutComponent {
  // version of the app located in the utilized environment file which is pulled from the package.json file
  version = environment.version;

  constructor(private ref: MatDialogRef<AboutComponent>, private router: Router) {}

  /**
   * Go to the 'help' page and close the dialog
   */
  navigate(): void {
    this.router.navigate(['/help']);
    this.ref.close();
  }
}
