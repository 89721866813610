import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InterventionSearchComponent } from '../../entity-searching/intervention-search.component';
import { InterventionService } from '../../../services/intervention.service';
import { PersonService } from '../../../services/person.service';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog.component';
import { flashMessage } from '../../utils';

@Component({
  selector: 'edit-intervention',
  templateUrl: 'edit-intervention.component.html',
  styleUrls: ['../editing.scss'],
})
export class EditInterventionComponent implements OnInit {
  // true to embed this component, false to not
  @Input() embed = false;

  // intervention object we're creating/modifying
  @Input() intervention: any = {};

  // emit the change when an intervention is saved or deleted
  @Output() interventionChange: EventEmitter<any> = new EventEmitter<any>();

  // event for cancel button click (only displayed on embed)
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();

  newIntervention = true;

  // TRUE, if current user has curation permissions = True
  isCurator = false;

  disableSaveMessage =
    'Treatment Term must be available and all required fields must be populated to be ' + 'able to save this treatment';

  disableDeleteMessage = 'Cannot delete a treatment if it has associated projects and measures';

  @ViewChild(InterventionSearchComponent) interventionSearchComponent!: InterventionSearchComponent;

  showProjects = false;
  showMeasures = false;
  termTaken = false;
  termTakenBy = '';

  /**
   * Class constructor.
   */
  constructor(
    private interventionService: InterventionService,
    private personService: PersonService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit() {
    if (this.intervention.term) {
      this.interventionService.getIntervention(this.intervention.term).subscribe((data) => {
        this.intervention = data;
        this.newIntervention = false;
        // don't emit here! this is the initial retreive for when this component is embedded
      });
    }
    this.personService.currentUser$.subscribe(() => {
      this.isCurator = this.personService.isCurator();
    });
  }

  /**
   * Returns 'true' if all conditions to save this record have been met and
   * it is possible to save this record.
   * Checks that 'term' and 'intervclass' are set and that the value for 'term' is available.
   * @return {boolean} - 'true' if OK to save, 'false' if not OK
   */
  canSave(): boolean {
    // check all required inputs have values;
    // 'term' has already been trimmed and validated.
    const intervclass: string = (this.intervention.intervclass || '').trim();

    return !!(this.intervention.term && intervclass && (!this.termTaken || !this.newIntervention));
  }

  // simple comma-separated json-type view of the measures associated with the current intervention
  measureDisplay() {
    const measureArray = [];
    for (const measure of this.intervention.measures) {
      measureArray.push(
        '{projsym: ' + measure.projsym + ', measnum: ' + measure.measnum + ', varname: ' + measure.varname + '}',
      );
    }
    return measureArray.join(', ');
  }

  // simple comma-separated json-type view of the projects associated with the current intervention
  projectDisplay() {
    const projectArray = [];
    for (const project of this.intervention.projects) {
      projectArray.push(
        '{projid: ' + project.projid + ', projsym: ' + project.projsym + ', title: ' + project.title + '}',
      );
    }
    return projectArray.join(', ');
  }

  /**
   * Deletes the specified intervention. User is prompted to confirm the deletion.
   *
   * @return {none}
   */
  deleteInterventionRecord(): void {
    if (!this.newIntervention) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          header: 'Treatment Deletion Confirmation',
          message: 'Are you sure that you wish to delete this intervention?',
          falselabel: 'Cancel',
          truelabel: 'Delete',
          truebtn: 'btn-danger',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.interventionService.deleteIntervention(this.intervention.term).subscribe(() => {
            this.interventionSearchComponent.clearSelection();
            this.interventionChange.emit(this.intervention);
          });
        }
      });
    }
  }

  /**
   * Returns true when the value passed is not only whitespaces.
   * @param {string} val - an alphanumeric character string (could be all whitespaces)
   * @return {boolean} - true or false
   */
  isNotEmpty(val: string): boolean {
    if (!val) {
      return false;
    }
    return val.trim() !== '';
  }

  /**
   * On selecting and loading another intervention in the autocomplete,
   * update and set values related to this intervention.
   * @return {none}
   */
  onInterventionChange(): void {
    this.showProjects = false;
    this.showMeasures = false;

    if (this.intervention.term) {
      this.interventionService.getIntervention(this.intervention.term).subscribe((data) => {
        this.intervention = data;
        this.interventionChange.emit(this.intervention);
        this.newIntervention = false;
      });
    } else {
      this.newIntervention = true;
    }
  }

  /**
   * Figure out if the current user is allowed to edit the intervention
   *
   * @returns {any}: true if the current user can edit the intervention, false if not
   */
  canEdit(): boolean {
    // curators can edit anything
    if (this.isCurator) {
      return true;
    }
    // this is a new intervention, so can edit
    if (this.newIntervention) {
      return true;
    }
    // creator can edit until a curator approves
    return this.personService.isCurrentUser(this.intervention.user_creator.id);
  }

  /**
   * Figure out if the current user is allowed to delete the intervention
   *
   * @returns {any}: true if the current user can delete the intervention, false if not
   */
  canDelete(): boolean {
    // can't delete something that doesn't exist
    if (this.newIntervention) {
      return false;
    }
    // can't delete if can't edit
    if (!this.canEdit()) {
      return false;
    }
    // if there are any associated projects, then can't delete
    if (this.intervention.projectscount > 1) {
      return false;
    }
    // can only delete if there are also no associated measures
    return this.intervention.measurescount === 0;
  }

  /**
   * Sends request to the service responsible for saving this intervention's
   * information in the database.
   *
   * Validates that 'term' and 'intervclass' argument values are set and valid.
   * @return {none}
   */
  saveInterventionRecord(): void {
    if (this.canSave()) {
      this.interventionService.saveIntervention(this.intervention).subscribe(
        (result) => {
          this.intervention = result;
          this.interventionChange.emit(this.intervention);
          this.newIntervention = false;
          flashMessage(this.snackBar, 'Save successful!', 'alert-success', 2000);
        },
        () => {
          flashMessage(this.snackBar, 'Save failed.', 'alert-danger', 5000);
        },
      );
    }
  }

  /**
   * Checks that the value currently entered in the 'Term' input field is available.
   * Values in the 'Term' input field must be unique and are required.
   * @return {none}
   */
  validateInterventionTerm(): void {
    this.termTaken = false;
    this.termTakenBy = '';

    const term: string = (this.intervention.term || '').trim();
    // it is possible that when whitespaces are trimmed, 'term'
    // becomes an empty string, in which case 'this.termTaken'
    // is set to TRUE, since empty values are not valid.
    this.termTaken = !term;

    if (term !== '') {
      const cachedInterventions = this.interventionService.interventions.getValue();

      for (let i = 0; i < cachedInterventions.length; i++) {
        if (term === cachedInterventions[i].term) {
          if (this.newIntervention) {
            this.termTaken = true;
            this.termTakenBy = cachedInterventions[i].term;
            break;
          }
        }
      }
    }
  }
}
