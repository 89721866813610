import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../../shared/dialogs/confirmation-dialog.component';

@Component({
  selector: 'inventory-review',
  templateUrl: './inventory-review.component.html',
  styleUrls: ['./inventory-review.component.scss'],
})
export class InventoryReviewComponent {
  // true if the current user should be able to select animals
  @Input() editable = false;

  // columns to be displayed on the inventory animal table (and a subset for the missing animal table)
  displayedColumns: string[] = ['select', 'id', 'sex', 'doGen', 'platform', 'runDate', 'array'];

  // data source for the table containing inventory animal selections
  inventory?: MatTableDataSource<InventoryAnimal>;

  // selection model to track current inventory animal table selections
  selections = new SelectionModel<InventoryAnimal>(true, []);

  // selections that have been saved in DivDB
  savedSelections: InventoryAnimal[] = [];

  // data source for the table containing the missing inventory animals
  missingAnimals?: MatTableDataSource<MissingInventoryAnimal>;

  // error message relating to an issue with the inventory submission
  saveError = '';

  // true if saving was successful and UI should display a message indicating such
  showSaveSuccess = false;

  // true if we're waiting for inventories or inventory file data to come back
  waiting = true;

  // error sourced from getting data from an API
  apiErrorMsg = '';

  // emits when the user is ready to save their inventory selections
  @Output() save: EventEmitter<InventoryAnimal[]> = new EventEmitter<InventoryAnimal[]>();

  // request another attempt to get inventory to generate the tables
  @Output() retryGet: EventEmitter<null> = new EventEmitter<null>();

  constructor(private dialog: MatDialog) {}

  /**
   * Returns the list of current selections from the inventory table
   */
  get currentSelections(): InventoryAnimal[] {
    return this.selections.selected;
  }

  /**
   * Returns the number of current selections from the inventory table
   */
  get numSelections(): number {
    return this.selections.selected.length;
  }

  /**
   * Returns the number of selections that have been saved in DivDB (in the form of
   * the contents of a written inventory file which will be the source for the
   * samples run through processing)
   */
  get numSavedSelections(): number {
    return this.savedSelections.length;
  }

  /**
   * Returns true if there is a difference in selections saved in DivDB and unsaved selections
   */
  get selectionsChanged(): boolean {
    // check that the number of current selections mismatches the number of saved
    if (!this.numSavedSelections && this.numSelections) {
      return true;
    }

    let changeFound = false;

    // check for any new deselections that haven't been saved
    for (let i = 0; i < this.savedSelections.length; i++) {
      const sel = this.savedSelections[i];
      if (!this.currentSelections.find((a) => this.matches(a, sel))) {
        changeFound = true;
        break;
      }
    }

    // if no changes have been found yet
    if (!changeFound) {
      // check for any new selections that haven't been saved
      for (let i = 0; i < this.currentSelections.length; i++) {
        const sel = this.currentSelections[i];
        if (!this.savedSelections.find((a) => this.matches(a, sel))) {
          changeFound = true;
          break;
        }
      }
    }

    return changeFound;
  }

  /**
   * Filters the specified table by the filter string value
   * @param {MatTableDataSource<InventoryAnimal|MissingInventoryAnimal>} table - table to filter
   * @param {string} filterValue - string filtering the table by
   */
  applyFilter(table: MatTableDataSource<InventoryAnimal | MissingInventoryAnimal>, filterValue: string): void {
    table.filter = filterValue.trim().toLowerCase();
  }

  /**
   * Clears the filtering value from the specified input and table
   * @param {MatTableDataSource<InventoryAnimal|MissingInventoryAnimal>} table - table the filter is for
   * @param {HTMLInputElement} filter - filter element to clear
   */
  clearFilter(table: MatTableDataSource<InventoryAnimal | MissingInventoryAnimal>, filter: HTMLInputElement) {
    filter.value = '';
    table.filter = '';
  }

  /**
   * Returns true if all table rows are selected
   */
  isAllSelected(): boolean {
    const numSelected = this.selections.selected.length;
    const numRows = this.inventory?.data.length;
    return numSelected === numRows;
  }

  /**
   * Shows a dialog containing more information about why mice might be listed in the missing
   * mice table and how to move mice from that table into the available animals table
   */
  showMissingMiceInfo(): void {
    const data = {
      header: 'Missing Genotype Data for Animals',
      message:
        'This table contains only animals that were located within any of the files ' +
        'containing animal information (identified by the animal or mouse IDs) but were not ' +
        'found in any of the uploaded sample maps in the submitted genotyping arrays.<br><br> ' +
        'If you believe this is in error, you can review the submitted sample maps by returning ' +
        'to the first step in the process and clicking on the sample map file - this will ' +
        'either open the file in a new tab if it was in the form of a txt or download the file. ' +
        'Make sure that the animal ID used in the animal information file matches that in the ' +
        'sample map. Uploading new animal information or genotype arrays will be reflected in ' +
        'the available and missing animal tables.',
      truelabel: 'Okay',
      hidefalsebtn: true,
    };
    const dialog = this.dialog.open(ConfirmationDialogComponent, { data });
    dialog.afterClosed().subscribe();
  }

  /**
   * Shows a dialog listing all samples that were either selected or deselected during this
   * session but have not been saved
   */
  showChangelog(): void {
    const newDeselections = [];

    for (let i = 0; i < this.savedSelections.length; i++) {
      const sel = this.savedSelections[i];
      if (!this.currentSelections.find((a) => this.matches(a, sel))) {
        newDeselections.push(`<b>- ${sel.mouse_id}</b> (${sel.sex}, ${sel.array})<br>`);
      }
    }

    const newSelections = [];
    // check for any new selections that haven't been saved
    for (let i = 0; i < this.currentSelections.length; i++) {
      const sel = this.currentSelections[i];
      if (!this.savedSelections.find((a) => this.matches(a, sel))) {
        newSelections.push(`<b>+ ${sel.mouse_id}</b> (${sel.sex}, ${sel.array})<br>`);
      }
    }

    const data = {
      header: 'Inventory Selection Changes',
      message:
        "The following samples' selection status changed. To have these changes " +
        'reflected in the processed and loaded data, please save them:<br><br>' +
        `<span style="color: #0c0">${newSelections.join('')}</span><br>` +
        `<span style="color: #c00">${newDeselections.join('')}</span><br>`,
      truelabel: 'Okay',
      hidefalsebtn: true,
    };
    const dialog = this.dialog.open(ConfirmationDialogComponent, { data });
    dialog.afterClosed().subscribe();
  }

  /**
   * Selects all rows if they are not all selected; otherwise clear selection.
   */
  masterToggle(): void {
    if (this.isAllSelected()) {
      this.selections.clear();
    } else {
      this.selections.clear();
      this.inventory?.data.forEach((row) => this.selections.select(row));
    }
  }

  /**
   * SelectionModel .isSelected() override so that we are able to restore selections
   * from inventory files
   * TODO: unfortunately if identical versions of animal info were submitted (and
   *   therefore multiple identical animals are in the inventory table), this will
   *   mark all versions as selected rather than just one
   * @param {InventoryAnimal} animal - the animal to check the selected status of
   */
  isSelected(animal: InventoryAnimal): boolean {
    return this.selections.isSelected(animal);
  }

  /**
   * An alternate version of displayInventory() but is intended for the specific case of
   * dismissing the loading spinners and rendering an empty table. This may be useful in
   * cases where an error has been encountered in the app or if there isn't any animals
   * to display
   */
  displayEmptyInventory(): void {
    this.inventory = new MatTableDataSource<InventoryAnimal>([]);
    this.missingAnimals = new MatTableDataSource<MissingInventoryAnimal>([]);
  }

  /**
   * Loads data from DivDB into inventory and missing animals tables
   * @param {InventoryAnimal[]} inventory - array of available inventory animals
   * @param {MissingInventoryAnimal[]} missing - array of animals missing data
   */
  displayInventory(inventory: InventoryAnimal[], missing: MissingInventoryAnimal[]): void {
    // Sort the animals by mouse_id before setting the table data sources
    inventory = inventory.sort((a, b) => a.mouse_id.localeCompare(b.mouse_id));
    missing = missing.sort((a, b) => a.mouse_id.localeCompare(b.mouse_id));

    this.inventory = new MatTableDataSource<InventoryAnimal>(inventory);
    this.missingAnimals = new MatTableDataSource<MissingInventoryAnimal>(missing);

    // Override the default filters so that only mouse ids are included
    this.inventory.filterPredicate = (data: InventoryAnimal, filter: string): boolean => {
      return data.mouse_id.trim().toLowerCase().includes(filter);
    };
    this.missingAnimals.filterPredicate = (data: MissingInventoryAnimal, filter: string): boolean => {
      return data.mouse_id.trim().toLowerCase().includes(filter);
    };
  }

  /**
   * Takes the raw text content from an inventory file, parses it into inventory animal objects and
   * loads them into the table; this content is coming from an autogenerated file so there is very
   * little flexibility needed to do this
   * @param {string[]} rawInventoryFiles - inventory file content for the most recent submission
   */
  displaySubmittedInventory(rawInventoryFiles: string[]): void {
    // clear the list of saved selections just in case
    this.savedSelections = [];

    rawInventoryFiles.forEach((content) => {
      const lines = content.replace(/\n+$/, '').replace(/["]+/g, '').split('\n');
      // TODO: if the format of the inventory files changes, this array might change
      const header = ['dataset', 'array', 'mouse_id', 'sex', 'dob', 'do_generation', 'correct_id'];

      // parse out info, create dicts from them and add them to the list
      lines.splice(1).forEach((line) => {
        const data = line.split(',');
        const animal = <InventoryAnimal>data.reduce((arr, val, i) => ({ ...arr, [header[i]]: val }), {});

        // if DOB isn't present, it's parsed as an empty string and then compared against null of the
        // inventory animal so set it to null manually
        if (animal.dob === '') {
          animal.dob = null;
        }

        // editing files sometimes adds non-newline characters and appends a return character
        // on the end of the IDs when they shouldn't have them I don't feel great about doing
        // this because it feels like I'm patching a very particular situation. I just don't
        // know how else to get rid of the weird extra string bits
        if (animal.mouse_id.endsWith('\r') || animal.correct_id.endsWith('\r')) {
          animal.mouse_id = animal.mouse_id.replace('\r', '');
          animal.correct_id = animal.correct_id.replace('\r', '');
        }

        const match = this.inventory?.data.find((a) => this.matches(a, animal));

        if (match) {
          this.selections.select(match);

          // mark this as a saved selection too
          this.savedSelections.push(match);
        }
      });
    });
  }

  /**
   * Returns true if all five primary attributes of the animals match which implies that the two animals
   * are the same
   * @param {InventoryAnimal} refAnimal - reference animal
   * @param {InventoryAnimal} compAnimal - comparison animal
   * @private
   */
  private matches(refAnimal: InventoryAnimal, compAnimal: InventoryAnimal): boolean {
    return (
      refAnimal.mouse_id === compAnimal.mouse_id &&
      // TODO: removing this condition for now as it's causing potential mismatches
      //  between animals pre and post processing
      // && refAnimal.sex.toLowerCase() === compAnimal.sex.toLowerCase()
      refAnimal.do_generation === compAnimal.do_generation &&
      refAnimal.dob === compAnimal.dob &&
      refAnimal.array === compAnimal.array
    );
  }
}

// defining the structure of data received from the API
/* eslint-disable camelcase */
export interface InventoryAnimal {
  dataset: string;
  investigator?: string;
  array: string;
  mouse_id: string;
  sex: string;
  do_generation: string;
  dob: string | null;
  run_date?: string;
  geneseek_plate?: string;
  geneseek_well?: string;
  correct_id: string;
  geneseek_sample_id?: string;
  include?: string;
  platform?: string;
}

export interface MissingInventoryAnimal {
  dataset: string;
  investigator: string;
  mouse_id: string;
  sex: string;
  do_generation: string;
  dob: string;
  correct_id: string;
  platform?: string;
}
/* eslint-enable camelcase */
