import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

// dialog for adding/editing/viewing publications on-the-fly
@Component({
  selector: 'publication-dialog',
  template: `
    <div mat-dialog-content style="min-width:800px;">
      <edit-publication
        [(publication)]="data.publication"
        [embed]="true"
        [embedShowSearch]="data.embedShowSearch"
        (cancel)="dialogRef.close()"
        (publicationChange)="dialogRef.close($event)"
      ></edit-publication>
    </div>
  `,
})
export class PublicationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<PublicationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PublicationDialogData,
  ) {}
}

export interface PublicationDialogData {
  publication: any; // TODO: create an interface for Publication
  embedShowSearch: any; // TODO: what is this?
}
