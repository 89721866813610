<div class="row">
  <mat-toolbar>
    <h4 class="gray7">Statistical QC: {{ project.projsym }}</h4>
  </mat-toolbar>
</div>
<br />
<div class="row">
  <div class="col-sm-12">
    <a [routerLink]="['/project/technical-qc/details', projectId]" class="dark-cerulean text10">
      <mat-icon class="v-bottom" inline="true"> arrow_back </mat-icon>Technical QC</a
    >
    &nbsp;|&nbsp;
    <a [routerLink]="['/project/qc-dashboard', projectId]" class="dark-cerulean text10"> Dashboard</a>
  </div>
</div>
<br />
<div class="row">
  <div class="col-md-12">
    <app-table
      [rows]="phenoMeasures"
      [columns]="measTableColumns"
      [rowSelect]="true"
      [defPageSize]="5"
      (rowSelected)="handleRowSelection($event)"
      [rowIdKey]="'measnum'"
    >
    </app-table>
  </div>
</div>

<br />
<br />

<div class="row" *ngIf="chart">
  <div class="col-md-12">
    <form class="form-inline text10">
      <div class="form-group col-md-6 text-left">
        <fieldset class="plot-control-panel-border">
          <legend class="plot-control-panel-border dark-cerulean">Plot Options</legend>
          <label for="strains-on-x-check" class="mpd-checkbox">
            <input id="strains-on-x-check" type="checkbox" checked="checked" (change)="chartInvert($event)" />
            <span></span>
            &nbsp;&nbsp;&nbsp;&nbsp;Strains on X-axis&nbsp;&nbsp;&nbsp;&nbsp;
          </label>
        </fieldset>
      </div>
    </form>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div id="container"></div>
  </div>
</div>

<br />

<div *ngIf="chart !== null" class="row">
  <div class="col-md-12">
    <app-table [rows]="selectedTableData" [columns]="myColumns" [defPageSize]="5" [exportFormats]="exportFormats">
    </app-table>
  </div>
</div>
<br />
