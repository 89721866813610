import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

// dialog for previewing images in a larger window
@Component({
  selector: 'image-preview-dialog',
  template: `
    <h1 mat-dialog-title>{{ data.file.filename }}</h1>
    <div mat-dialog-content>
      <div>
        <p>{{ data.file.descrip ? data.file.descrip : '(No Caption)' }}</p>
        <small *ngIf="data.file.deletedtime" style="color:red;">
          Deleted: {{ data.file.deletedtime.slice(5).slice(0, -7) }}<br />
        </small>
        <small style="color:darkgray;">Uploaded: {{ data.file.uploadedtime.slice(5).slice(0, -7) }}</small
        ><br />
        <small style="color:darkgray;">Uploader: {{ data.file.uploader.name_or_email }}</small>
        <span *ngIf="data.file.filesize">
          <br />
          <small style="color:darkgray;">File Size: {{ data.file.filesize }}</small>
        </span>
      </div>
      <img [src]="getFileUrl() | SafeResourceURL" />
    </div>
    <div align="center" mat-dialog-actions>
      <button class="btn btn-default" (click)="onClose()">Close</button>
    </div>
  `,
})
export class ImagePreviewDialogComponent {
  // keep track of if we had to create an object url for the image preview
  urlCreated = false;

  constructor(public dialogRef: MatDialogRef<ImagePreviewDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  // close the dialog
  onClose(): void {
    // clean up url if one was created
    if (this.urlCreated && this.data.file.url) {
      URL.revokeObjectURL(this.data.file.url);
    }
    this.dialogRef.close();
  }

  // get the file url
  getFileUrl() {
    if (this.data.file.url) {
      return this.data.file.url;
    }
    // I don't think we shgould be able to get here
    // (there should already be a file url if we're here...), but just in case
    this.urlCreated = true;
    const newUrl = URL.createObjectURL(this.data.file_blob);
    this.data.file.url = newUrl;
    return newUrl;
  }
}
