<mat-toolbar>
  <h2>My Profile</h2>
</mat-toolbar>
<br /><br />
<edit-person #profile_form [(id)]="id" [uniqueTag]="'profile'" [currentUser]="true"></edit-person>
<br />
<div class="form-horizontal">
  <div class="form-group">
    <div class="col-sm-5"></div>
    <div class="col-sm-2" [matTooltip]="profile_form.saveDisabledTooltip()">
      <button
        class="btn btn-success"
        (click)="profile_form.saveProfile().subscribe()"
        [disabled]="profile_form.saveDisabled()"
        id="profile-save"
        style="width: 100%"
      >
        <strong>Save</strong>
      </button>
    </div>
  </div>
</div>
<div align="center" *ngIf="profile.restricted">
  <br /><span style="color: red"
    >Edit access on SIP is currently restricted. You must be given edit access by a Curator in order to modify your
    profile or make other changes.
  </span>
</div>
<div *ngIf="!profile.restricted">
  <div align="center" *ngIf="!profile.allow_changes && !profile.emailverified && profile.email">
    <br /><span style="color: red">Please verify your email before modifying your profile.</span>
  </div>
  <div *ngIf="!profile.emailverified && profile.email" class="form-horizontal">
    <br />
    <b style="padding-left: 20px; color: red">
      Your email is not verified. Your edit access will be restricted until you verify it. In order to verify your
      email, you can:
    </b>
    <ol>
      <li>"Sign Out" from the menu option on the top right (read the rest of these instructions before doing so).</li>
      <li>After signing out, click "Sign Up/Login" button on the top right.</li>
      <li>Instead of entering your Email and Password, click the "Forgot Password?" option in the sign-in screen.</li>
      <li>Follow the provided instructions to reset your password.</li>
      <li>After you reset your password, your email should be verified.</li>
      <li>NOTE: It may take up to 5 minutes for Study Intake Platform to receive your new verification status.</li>
    </ol>
  </div>
</div>
<br />
