<mat-toolbar>
  <h2 class="text-right">Project Details</h2>
</mat-toolbar>
<br />

<timeline
  #timeline_component
  [projid]="projid"
  [invalidID]="invalidID"
  [loading]="loadingProject"
  [(projsym)]="project.projsym"
  [(title)]="project.title"
  [project]="project"
  (clickExternalLink)="state_buttons_component.openExternalUrlAfterSave($event)"
  (clickPageLink)="state_buttons_component.openPageLinkAfterSave($event)"
  (clickReleaseProject)="state_buttons_component.releaseProject()"
  (clickReimportProject)="state_buttons_component.reimportProject()"
  (clickChangeStatus)="state_buttons_component.changeProjectStatus($event)"
  (clickImportExport)="state_buttons_component.onClickProjectImportExport(timeline_component.projectActionsComponent)"
  (projectChanged)="getProject()"
  (projectRestored)="state_buttons_component.projectRestored()"
  [actionLoadingBar]="state_buttons_component.loadingBar"
></timeline>

<div [style.display]="invalidID || loadingProject ? 'none' : 'block'">
  <mat-vertical-stepper>
    <mat-step label="Data Contributors">
      <br />
      <div class="form-horizontal">
        <div class="form-group">
          <label style="padding-top: 0" class="col-sm-4 control-label required" for="authorship">
            Data Contributor Type
          </label>
          <div class="col-sm-8">
            <mat-radio-group
              id="authorship"
              name="authorship"
              (change)="onAuthorshipTypeChange()"
              [(ngModel)]="project.instauth"
              [disabled]="!project.canEdit"
            >
              <mat-radio-button [value]="null"> Investigator(s) </mat-radio-button>
              &nbsp;&nbsp;
              <mat-radio-button value="instauth"> Institutional </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div *ngIf="project.instauth" class="form-group">
          <label style="padding-top: 0" class="col-sm-4 control-label required" for="centerauth">
            Data Contributor is a Center?
          </label>
          <div class="col-sm-8">
            <mat-radio-group
              id="centerauth"
              name="centerauth"
              (change)="onAuthorshipTypeChange()"
              [(ngModel)]="project.centerauth"
              [disabled]="!project.canEdit"
            >
              <mat-radio-button value="true"> Yes </mat-radio-button>
              &nbsp;&nbsp;
              <mat-radio-button [value]="null"> No </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <hr />
        <div>
          <div *ngIf="project.instauth && !project.centerauth" class="form-group">
            <label for="inst-pistring" class="col-sm-3 control-label"> Institution </label>
            <div class="col-sm-5">
              <input
                type="text"
                [(ngModel)]="project.pistring"
                id="inst-pistring"
                class="form-control"
                name="inst-pistring"
                required
                maxlength="255"
                [disabled]="!project.canEdit"
              />
            </div>
          </div>
          <div class="form-group">
            <label [for]="'institution_author'" class="col-sm-3 control-label">
              <span *ngIf="!project.instauth || !project.centerauth">Affiliated</span>
              Center <span *ngIf="!project.instauth || !project.centerauth">(optional)&nbsp;</span>
            </label>
            <div class="col-sm-5">
              <center-search
                [(collab)]="project.largecollab_rel"
                (collabChange)="onCollabChange()"
                [disabled]="!project.canEdit"
              >
              </center-search>
            </div>
          </div>
        </div>
        <div *ngIf="project.otherpis.length > 0" class="form-group">
          <label style="padding-top: 0" class="col-sm-3 control-label required" for="corrlast">
            List Contact
            <span
              class="glyphicon glyphicon-question-sign"
              matTooltip="This choice affects where the contact is listed in the list of investigators
                              in other displays, but it will remain first in the list regardless in this entry display."
              [matTooltipPosition]="position"
              matTooltipHideDelay="2000"
            ></span>
          </label>
          <div class="col-sm-5">
            <mat-radio-group id="corrlast" name="corrlast" [(ngModel)]="project.corrlast" [disabled]="!project.canEdit">
              <mat-radio-button [value]="null"> First </mat-radio-button>
              &nbsp;&nbsp;
              <mat-radio-button [value]="'last'"> Last </mat-radio-button>
            </mat-radio-group>
          </div>
          <label class="col-sm-2"
            >Permission
            <span
              class="glyphicon glyphicon-question-sign"
              matTooltip="If this investigator has a populated email address, then you can use this dropdown
                              to view or update their permissions on this project. For more details on project
                              permissions, go to the Share Project page (the + user icon next to the project title)."
              [matTooltipPosition]="position"
              matTooltipHideDelay="2000"
            ></span>
          </label>
        </div>
        <div class="form-group" *ngIf="!(project.otherpis.length > 0) && project.corrpi">
          <div class="col-sm-8"></div>
          <label class="col-sm-2"
            >Permission
            <span
              class="glyphicon glyphicon-question-sign"
              matTooltip="If this investigator has a populated email address, then you can use this dropdown
                              to view or update their permissions on this project. For more details on project
                              permissions, go to the Share Project page (the + user icon next to the project title)."
              [matTooltipPosition]="position"
              matTooltipHideDelay="2000"
            ></span>
          </label>
        </div>
        <div *ngIf="project.corrpi" class="form-group">
          <label class="col-sm-3 control-label">
            Investigator (Contact)
            <span
              class="glyphicon glyphicon-question-sign"
              matTooltip="Usually the Principal Investigator for a study and/or the corresponding
                              author on a publication"
              [matTooltipPosition]="position"
              matTooltipHideDelay="2000"
            ></span>
            <img
              *ngIf="project.bioconnect_id"
              src="./assets/bioconnect_icon.ico"
              alt=""
              [matTooltip]="'Controlled by linked Bioconnect Study: ' + project.bioconnect_identifier"
            />
          </label>
          <div class="col-sm-5">
            <person-search
              [(person)]="project.correspondingpi"
              [showDialog]="true"
              [disabled]="!project.canEdit || project.bioconnect_id"
              [nameRequired]="true"
              (personChange)="removePi(project.corrpi)"
            >
            </person-search>
          </div>
          <div class="col-sm-2">
            <select
              class="form-control"
              [(ngModel)]="project.correspondingpi.permission"
              (change)="changePermission(project.corrpi, $event.target.value)"
              [disabled]="piPermissionDisabled(project.correspondingpi)"
            >
              <option [disabled]="!project.isOwner" value="None">
                None{{ !project.correspondingpi.email ? ' (no email)' : '' }}
              </option>
              <option value="View">View</option>
              <option value="Edit">Edit</option>
              <option [disabled]="!project.isOwner" value="Owner">Owner</option>
            </select>
          </div>
          <div class="col-sm-2">
            <span *ngIf="project.canEdit && !project.bioconnect_id">
              <button
                *ngIf="project.otherpis.length > 0"
                matTooltip="Move this investigator down"
                type="button"
                (click)="movePi(project.corrpi, false)"
                class="btn btn-primary"
                id="corrpi-move-down"
              >
                <span class="glyphicon glyphicon-arrow-down"></span>
              </button>
            </span>
          </div>
        </div>
        <div>
          <div *ngFor="let otherpi of project.otherpis; let i = index" class="form-group">
            <label class="col-sm-3 control-label">
              Investigator
              <img
                *ngIf="project.bioconnect_id"
                src="./assets/bioconnect_icon.ico"
                alt=""
                [matTooltip]="'Controlled by linked Bioconnect Study: ' + project.bioconnect_identifier"
              />
            </label>
            <div class="col-sm-5">
              <person-search
                [(person)]="project.otherpis[i]"
                [showDialog]="true"
                [disabled]="!project.canEdit || project.bioconnect_id"
                (personChange)="project.otherpis.splice(i, 1)"
              >
              </person-search>
            </div>
            <div class="col-sm-2">
              <select
                class="form-control"
                [(ngModel)]="project.otherpis[i].permission"
                (change)="changePermission(project.otherpis[i].id, $event.target.value)"
                [disabled]="piPermissionDisabled(project.otherpis[i])"
              >
                <option [disabled]="!project.isOwner" value="None">
                  None{{ !project.otherpis[i].email ? ' (no email)' : '' }}
                </option>
                <option value="View">View</option>
                <option value="Edit">Edit</option>
                <option [disabled]="!project.isOwner" value="Owner">Owner</option>
              </select>
            </div>
            <div class="col-sm-2">
              <span *ngIf="project.canEdit && !project.bioconnect_id">
                <button
                  *ngIf="getOtherPIIndex(otherpi.id) !== project.otherpis.length - 1"
                  matTooltip="Move this investigator down"
                  type="button"
                  (click)="movePi(otherpi.id, false)"
                  class="btn btn-primary"
                  [id]="'otherpi-' + otherpi.id + '-move-down'"
                >
                  <span class="glyphicon glyphicon-arrow-down"></span>
                </button>
                <button
                  matTooltip="Move this investigator up"
                  type="button"
                  [style.margin-left]="getOtherPIIndex(otherpi.id) === project.otherpis.length - 1 ? '44px' : '4px'"
                  (click)="movePi(otherpi.id)"
                  class="btn btn-primary"
                  [id]="'otherpi-' + otherpi.id + '-move-up'"
                >
                  <span class="glyphicon glyphicon-arrow-up"></span>
                </button>
              </span>
            </div>
          </div>
        </div>
        <div *ngIf="project.canEdit && !project.bioconnect_id" class="form-group">
          <label class="col-sm-3 control-label"
            >Add Investigator{{ project.corrpi ? '' : ' (Contact)' }}
            <span
              *ngIf="!project.corrpi"
              class="glyphicon glyphicon-question-sign"
              matTooltip="Usually the Principal Investigator for a study and/or the corresponding
                              author on a publication"
              [matTooltipPosition]="position"
              matTooltipHideDelay="2000"
            ></span>
          </label>
          <div class="col-sm-5">
            <person-search
              #pi_search_form
              [(person)]="piSearch"
              [showDialog]="true"
              (personChange)="$event.id ? addPi($event) : ''; $event.id ? pi_search_form.clearSelection() : ''"
              [disableList]="project.correspondingpi?.id || project.otherpis.concat([project.correspondingpi]) || []"
            >
            </person-search>
          </div>
        </div>
        <div class="form-group">
          <label for="additional-proj-participants" class="col-sm-3 control-label">
            Other Participants
            <span
              class="glyphicon glyphicon-question-sign"
              matTooltip="Use this field to list participants in a comma-separated
                          list if you don't want them listed individually."
              [matTooltipPosition]="position"
              matTooltipHideDelay="2000"
            ></span>
            <img
              *ngIf="project.bioconnect_id"
              src="./assets/bioconnect_icon.ico"
              alt=""
              [matTooltip]="'Controlled by linked Bioconnect Study: ' + project.bioconnect_identifier"
            />
          </label>
          <div class="col-sm-5">
            <input
              type="text"
              [(ngModel)]="project.participants"
              id="additional-proj-participants"
              class="form-control"
              name="additional-proj-participants"
              required
              maxlength="255"
              placeholder="e.g., Smith AB, Jones CD"
              [disabled]="!project.canEdit || project.bioconnect_id"
            />
          </div>
        </div>
      </div>
      <br />
      <div class="form-horizontal">
        <div class="form-group">
          <div class="col-sm-4 col-md-5"></div>
          <div class="col-sm-3 col-md-2">
            <button class="btn btn-info btn-block" matStepperNext id="step-1-next"><strong>Next</strong></button>
          </div>
        </div>
      </div>
    </mat-step>

    <mat-step label="Project Details">
      <fieldset [disabled]="!project.canEdit">
        <br />
        <div class="form-horizontal">
          <div class="form-group">
            <label for="proj-pub-year" class="col-sm-3 control-label required">
              Year of Completion /
              <span style="white-space: nowrap">
                Publication
                <span
                  class="glyphicon glyphicon-question-sign"
                  matTooltip="Enter the year you would like to display next to the project title"
                  [matTooltipPosition]="position"
                  matTooltipHideDelay="2000"
                ></span>
              </span>
            </label>
            <div class="col-sm-3">
              <input
                type="text"
                [(ngModel)]="project.projyear"
                id="proj-pub-year"
                maxlength="10"
                class="form-control"
                name="proj-pub-year"
                required
              />
            </div>
          </div>
          <div class="form-group">
            <label for="proj-funding" class="col-sm-3 control-label">Funding Provided By</label>
            <div class="col-sm-8">
              <input
                type="text"
                [(ngModel)]="project.funding"
                id="proj-funding"
                class="form-control"
                name="proj-funding"
                required
                maxlength="255"
                placeholder="enter funding sources (comma separated list)"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="proj-acknowledgements" class="col-sm-3 control-label">Acknowledgements</label>
            <div class="col-sm-8">
              <input
                type="text"
                [(ngModel)]="project.acknowledgements"
                id="proj-acknowledgements"
                class="form-control"
                name="proj-acknowledgements"
                required
                maxlength="255"
                placeholder="enter acknowledgements (comma separated list)"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="proj-url" class="col-sm-3 control-label">URL</label>
            <div class="col-sm-8">
              <input
                type="text"
                [(ngModel)]="project.url"
                id="proj-url"
                class="form-control"
                name="proj-url"
                required
                maxlength="255"
                placeholder="URL for principal investigator or project group"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="proj-primary-pub" class="col-sm-3 control-label">
              Primary Publications
              <img
                *ngIf="project.bioconnect_id"
                src="./assets/bioconnect_icon.ico"
                alt=""
                [matTooltip]="'Controlled by linked Bioconnect Study: ' + project.bioconnect_identifier"
              />
            </label>
            <div class="col-sm-8">
              <div class="input-group">
                <input
                  type="text"
                  [(ngModel)]="project.primarypublications"
                  [disabled]="project.bioconnect_id"
                  id="proj-primary-pub"
                  autocomplete="off"
                  class="form-control"
                  name="proj-primary-pub"
                  maxlength="255"
                  (change)="pubsChange('primary')"
                  required
                  placeholder="enter comma separated primary PubMed IDs"
                />
                <div class="input-group-btn">
                  <button
                    (click)="openPubDialog('primary')"
                    [disabled]="project.bioconnect_id"
                    type="button"
                    class="btn btn-primary"
                    matTooltip="Lookup or Create Publication"
                  >
                    <span class="glyphicon glyphicon-search"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="project.primarypublicationsinfo ? project.primarypublicationsinfo.length > 0 : false"
            class="form-group"
          >
            <label class="col-sm-3 control-label preview-label">Primary Publications Preview</label>
            <div class="col-sm-8">
              <pub-display *ngFor="let pubinfo of project.primarypublicationsinfo" [pubinfo]="pubinfo"></pub-display>
            </div>
          </div>
        </div>
        <br />
      </fieldset>
      <div class="form-horizontal">
        <div class="form-group">
          <div class="col-sm-3 col-md-4"></div>
          <div class="col-sm-3 col-md-2">
            <button class="btn btn-info btn-block" matStepperPrevious id="step-2-prev">
              <strong>Previous</strong>
            </button>
          </div>
          <div class="col-sm-3 col-md-2">
            <button class="btn btn-info btn-block" matStepperNext id="step-2-next"><strong>Next</strong></button>
          </div>
        </div>
      </div>
    </mat-step>

    <mat-step label="Project Description">
      <fieldset [disabled]="!project.canEdit || project.bioconnect_id">
        <br />
        <div class="row">
          <div class="col-sm-12">
            <h4>
              Introduction / Objectives
              <img
                *ngIf="project.bioconnect_id"
                src="./assets/bioconnect_icon.ico"
                alt=""
                [matTooltip]="'Controlled by linked Bioconnect Study: ' + project.bioconnect_identifier"
              />
            </h4>
            <textarea
              [(ngModel)]="project.description"
              class="form-control"
              rows="5"
              maxlength="750"
              placeholder="enter a project description (limited to 750 characters including spaces)"
            ></textarea>
          </div>
        </div>
        <br />
      </fieldset>
      <div class="form-horizontal">
        <div class="form-group">
          <div class="col-sm-3 col-md-4"></div>
          <div class="col-sm-3 col-md-2">
            <button class="btn btn-info btn-block" matStepperPrevious id="step-3-prev">
              <strong>Previous</strong>
            </button>
          </div>
          <div class="col-sm-3 col-md-2">
            <button class="btn btn-info btn-block" matStepperNext id="step-3-next"><strong>Next</strong></button>
          </div>
        </div>
      </div>
    </mat-step>
    <mat-step label="Subject Information">
      <div class="form-horizontal" style="padding-top: 20px; padding-bottom: 20px">
        <fieldset [disabled]="!project.canEdit">
          <div class="form-group">
            <label for="subject-design-type" class="col-sm-3 control-label required"> Type </label>
            <div class="col-sm-6">
              <select
                [(ngModel)]="project.mpdsector"
                id="subject-design-type"
                name="subject-design-type"
                class="form-control"
                required
              >
                <!--TODO: move this to curator check... really, this is a legacy field that was used for different
                        project types before SIP. However, in SIP is should never really be anything other than
                        phenotype strain survey... we will probably want to add a separate list of types to replace
                        it for users... or maybe not, we just keep it at the measure, genotype, or other data level-->
                <option value="pheno">Phenotype strain survey</option>
                <!--Disabling other options for now because curation doesn't really work for uploading other types
                    of projects yet. (NOTE: Since they are still here, they should still show the correct value
                    for legacy projects)-->
                <option disabled *ngIf="project.mpdsector === 'snp'" value="snp">SNP/variation strain survey</option>
                <option disabled *ngIf="project.mpdsector === 'qtla'" value="qtla">QTL Archive</option>
                <option disabled value="onestrain">One-strain study</option>
                <option disabled value="phenoarchive">Phenotype archive study</option>
                <option disabled value="komp">Knockout mouse project (KOMP)</option>
                <option [disabled]="!project.isCurator" value="itp">Interventions Testing Program (ITP)</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label"> Tags </label>
            <div class="col-sm-6">
              <label style="cursor: pointer; padding-top: 7px; white-space: nowrap">
                <input [(ngModel)]="project.aging" type="checkbox" />
                &nbsp;Aging Study </label
              >&nbsp;&nbsp;&nbsp;
              <label style="cursor: pointer; padding-top: 7px; white-space: nowrap">
                <input [(ngModel)]="project.molecular" type="checkbox" />
                &nbsp;Molecular Data
              </label>
              <label
                *ngIf="project.isCurator || project.suppdata"
                style="cursor: pointer; padding-top: 7px; white-space: nowrap"
              >
                &nbsp;&nbsp;
                <input [disabled]="!project.isCurator" [(ngModel)]="project.suppdata" type="checkbox" />
                &nbsp;Legacy Supplemental Files
              </label>
            </div>
          </div>
          <div class="form-group">
            <label for="proj-species" class="col-sm-3 control-label">
              Species
              <span
                class="glyphicon glyphicon-question-sign"
                matTooltip="If left blank, this is assumed to be mouse."
              ></span>
            </label>
            <div class="col-sm-6">
              <input
                type="text"
                [(ngModel)]="project.species"
                id="proj-species"
                class="form-control"
                name="proj-species"
                required
                maxlength="80"
              />
            </div>
          </div>
        </fieldset>
        <div class="form-group">
          <label class="col-sm-3 control-label required"> Panel </label>
          <div class="col-sm-6">
            <panel-search
              [(panel)]="project.panel"
              [disableList]="[{ panelsym: 'CC8' }]"
              [showDialog]="true"
              [disableMessage]="
                'For CC 8 founders, select \'classical inbred strains\' and then ' +
                'select the radio button that apperas for the 8 CC founder strains.'
              "
              (panelChange)="project.panelsym = project.panel.panelsym || null; project.paneldesctag = null"
              [disabled]="!project.canEdit"
            >
            </panel-search>
          </div>
        </div>
        <fieldset [disabled]="!project.canEdit">
          <div
            *ngIf="project.panelsym && (project.panelsym === 'inbred' || riPanels.indexOf(project.panelsym) !== -1)"
            class="form-group"
          >
            <label style="padding-top: 0" class="col-sm-3 control-label">Additional Panel Info</label>
            <div class="col-sm-9">
              <mat-radio-group
                id="paneldesctag-radio"
                [disabled]="!project.canEdit"
                name="paneldesctag"
                [(ngModel)]="project.paneldesctag"
              >
                <mat-radio-button [value]="null"> (None) </mat-radio-button>
                <br />
                <mat-radio-button *ngIf="project.panelsym === 'inbred'" value="hascc8">
                  Inbred strain survey covering all 8 CC founder strains
                </mat-radio-button>
                <br *ngIf="project.panelsym === 'inbred'" />
                <mat-radio-button *ngIf="project.panelsym === 'inbred'" value="hascc7">
                  Inbred strain survey covering 7 of 8 CC founder strains
                </mat-radio-button>
                <mat-radio-button *ngIf="riPanels.indexOf(project.panelsym) !== -1" value="wparentals">
                  RI panel (or similar) strain survey that includes parental (progenitor) strains (not HMDP)
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

          <div class="form-group">
            <label for="pophints" class="col-sm-3 control-label"
              >Population Notes
              <span
                class="glyphicon glyphicon-question-sign"
                matTooltip="If project involves a heterogeneous population (such as DO or a QTL cross), enter DO
                                generation(s) or QTL cross information if applicable."
                [matTooltipPosition]="position"
                matTooltipHideDelay="1000"
              ></span>
            </label>
            <div class="col-sm-6">
              <input
                type="text"
                [(ngModel)]="project.pophints"
                id="pophints"
                class="form-control"
                name="pophints"
                required
                maxlength="120"
              />
            </div>
          </div>

          <div class="form-group">
            <label class="col-sm-3 control-label required">
              Number of Animals
              <span
                class="glyphicon glyphicon-question-sign"
                matTooltip="If project involves a heterogeneous population (such as DO or a QTL cross),
                                enter the population's number of females and males."
                [matTooltipPosition]="position"
                matTooltipHideDelay="1000"
              ></span>
            </label>
            <label for="popcount_f" class="col-sm-1 control-label"> Females </label>
            <div class="col-sm-2">
              <input
                type="number"
                [(ngModel)]="project.popcount_f"
                id="popcount_f"
                (change)="project.popcount_f = forceRange(project.popcount_f, 0, 999999)"
                class="form-control"
                name="popcount_f"
                min="0"
                max="999999"
              />
            </div>
            <label for="popcount_m" class="col-sm-1 control-label"> Males </label>
            <div class="col-sm-2">
              <input
                type="number"
                [(ngModel)]="project.popcount_m"
                id="popcount_m"
                (change)="project.popcount_m = forceRange(project.popcount_m, 0, 999999)"
                class="form-control"
                name="popcount_m"
                min="0"
                max="999999"
              />
            </div>
          </div>

          <div *ngIf="project.popcount_calc.length > 0" class="form-group">
            <label class="col-sm-3 control-label preview-label">Number of Animals in Uploaded Phenotype Data</label>
            <div style="padding-top: 7px" class="col-sm-6 preview-label">
              <span style="padding-right: 15px" *ngFor="let popcount of project.popcount_calc">
                <b>{{ popcount.label }}:</b> {{ popcount.count }}
              </span>
            </div>
          </div>
          <div class="form-group">
            <label for="subject-design-num-strains" class="col-sm-3 control-label required">Number of Strains</label>
            <div class="col-sm-2">
              <input
                type="number"
                [(ngModel)]="project.nstrains"
                id="subject-design-num-strains"
                (change)="project.nstrains = forceRange(project.nstrains, 0, 9999)"
                class="form-control"
                name="subject-design-num-strains"
                min="0"
                max="9999"
                required
              />
            </div>
            <label *ngIf="project.straincount > 0" class="col-sm-2 control-label preview-label">
              Number of Strains in Uploaded Data
            </label>
            <div *ngIf="project.straincount > 0" style="padding-top: 7px" class="col-sm-2 preview-label">
              {{ project.straincount }}
            </div>
          </div>

          <div class="form-group">
            <label for="subject-design-sex" class="col-sm-3 control-label required">Sex</label>
            <div class="col-sm-6">
              <select
                [(ngModel)]="project.sexes"
                id="subject-design-sex"
                class="form-control"
                name="subject-design-sex"
              >
                <option value=""></option>
                <option value="f">Female only</option>
                <option value="m">Male only</option>
                <option value="both">Female + male (both)</option>
                <option value="pooled">Female + male (pooled)</option>
              </select>
            </div>
          </div>

          <div class="form-group">
            <label for="subject-design-testing-age" class="col-sm-3 control-label required">Age at Testing (wks)</label>
            <div class="col-sm-6">
              <input
                type="text"
                [(ngModel)]="project.ages"
                id="subject-design-testing-age"
                placeholder="e.g., 8-12"
                class="form-control"
                name="subject-design-testing-age"
                required
                maxlength="40"
              />
            </div>
          </div>

          <div class="form-group">
            <label for="subject-design-num-cohorts" class="col-sm-3 control-label required">
              Number of Experimental
              <span style="white-space: nowrap">
                Groups
                <span
                  class="glyphicon glyphicon-question-sign"
                  matTooltip="For example, baseline only would be one experimental group; control and treated would
                                  be two experimental groups"
                  [matTooltipPosition]="position"
                  matTooltipHideDelay="2000"
                ></span>
              </span>
            </label>
            <div class="col-sm-2">
              <input
                type="number"
                [(ngModel)]="project.ncohorts"
                id="subject-design-num-cohorts"
                (change)="onNcohortsChange()"
                class="form-control"
                name="subject-design-num-cohorts"
                min="1"
                max="9999"
                required
              />
            </div>
          </div>
          <div class="form-group">
            <label for="subject-design-num-cohorts" class="col-sm-3 control-label required">
              Experimental Groups
            </label>
            <div class="col-sm-6">
              <editable-table
                [columns]="expgroupsColumns"
                [(rows)]="project.expgroups"
                [id]="'expgroups'"
                [allowAdd]="false"
                [allowChanges]="project.canEdit"
                [allowRemove]="false"
              ></editable-table>
            </div>
          </div>
        </fieldset>
        <div class="form-group">
          <label class="col-sm-3 control-label">
            Treatments
            <span
              class="glyphicon glyphicon-question-sign"
              matTooltip="These treatments are used as general tags for searching and filtering projects."
              [matTooltipPosition]="position"
              matTooltipHideDelay="2000"
            ></span>
          </label>
          <div class="col-sm-6">
            <div style="padding-bottom: 10px" *ngFor="let treatment of project.treatments; let i = index">
              <intervention-search
                [(intervention)]="project.treatments[i]"
                [showDialog]="true"
                (interventionChange)="onChangeTreatment(i)"
                [disabled]="!project.canEdit"
              >
              </intervention-search>
            </div>
            <div *ngIf="project.canEdit">
              <intervention-search
                #addTreatment
                [(intervention)]="treatmentSearch"
                [showDialog]="true"
                (interventionChange)="onAddTreatment()"
                [disableList]="project.treatments"
              >
              </intervention-search>
            </div>
          </div>
        </div>
      </div>
      <div class="form-horizontal">
        <div class="form-group">
          <div class="col-sm-3 col-md-4"></div>
          <div class="col-sm-3 col-md-2">
            <button class="btn btn-info btn-block" matStepperPrevious id="step-4-prev">
              <strong>Previous</strong>
            </button>
          </div>
          <div class="col-sm-3 col-md-2">
            <button class="btn btn-info btn-block" matStepperNext id="step-4-next"><strong>Next</strong></button>
          </div>
        </div>
      </div>
    </mat-step>
    <mat-step label="Project Links">
      <div class="form-horizontal" style="padding-top: 20px; padding-bottom: 20px">
        <fieldset [disabled]="!project.canEdit">
          <project-links [projectLinks]="project.projlinks" [projectId]="project.projid" [canEdit]="project.canEdit">
          </project-links>
        </fieldset>
      </div>
      <div class="form-horizontal">
        <div class="form-group">
          <div class="col-sm-4 col-md-5"></div>
          <div class="col-sm-3 col-md-2">
            <button class="btn btn-info btn-block" matStepperPrevious id="step-5-prev">
              <strong>Previous</strong>
            </button>
          </div>
        </div>
      </div>
    </mat-step>
  </mat-vertical-stepper>
  <br />
  <br />
  <state-buttons
    #state_buttons_component
    [(project)]="project"
    [(dbProject)]="dbProject"
    [currentPage]="'/proj-details/'"
    [nextPage]="'/animal-info/'"
    (clickTitleLink)="timeline_component.titleInput.nativeElement.focus()"
    (saveFail)="timeline_component.setActive()"
  ></state-buttons>
</div>
