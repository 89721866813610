import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PublicationSearchComponent } from '../../entity-searching/publication-search.component';
import { PublicationService } from '../../../services/publication.service';
import { PersonService } from '../../../services/person.service';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog.component';
import { flashMessage } from '../../utils';

@Component({
  selector: 'edit-publication',
  templateUrl: 'edit-publication.component.html',
  styleUrls: ['../editing.scss'],
})
export class EditPublicationComponent implements OnInit {
  // true to embed this component, false to not
  @Input() embed = false;

  // true to show the search even though this is embededded
  @Input() embedShowSearch = false;

  // publication object we're creating/modifying
  @Input() publication: any = {};

  // emit the change when a publication is saved or deleted
  @Output() publicationChange: EventEmitter<any> = new EventEmitter<any>();

  // event for cancel button click (only displayed on embed)
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();

  // true if we're creating a new publication, false if we're editing an existing one
  newPublication = true;
  // true if the current user is a curator, false if they are not
  isCurator = false;

  // need to be able to clear the selection in the publication-search component
  @ViewChild(PublicationSearchComponent) publicationSearchComponent!: PublicationSearchComponent;

  // true to show the extra details about projects, false to not
  showProjects = false;

  // flags as to whether the current values in pmid and title are already taken by another publication
  pmidTaken = false;
  // flag also for tracking if this is a valid pmid (or NOPMID)
  pmidValid = false;

  titleTaken = false;
  // populated with the pmid of the publication that has the title
  titleTakenBy = '';

  // assume year is valid until it is not
  yearValid = true;
  yearMessage = '';

  // true to show loading bar... contains string describing what's loading
  loading = '';

  disableSaveMessage = 'PMID and title must be available and all required fields populated to save';

  // class constructor
  constructor(
    private publicationService: PublicationService,
    private personService: PersonService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit() {
    if (this.publication.pmid) {
      this.refreshPmid();
    }

    this.personService.currentUser$.subscribe(() => {
      this.isCurator = this.personService.isCurator();
    });
  }

  /**
   * Function to return 'true' if all conditions to save this publication have been met.
   *
   * Checks all of the relevant variables including mandatory
   * fields, if the pmid and/or title are already taken or if the year is
   * valid or invalid.
   *
   * @return {boolean}: True if can Save; False if cannot
   */
  canSave(): boolean {
    // check all required inputs have values;
    // 'name' and 'tag' have already been trimmed and validated
    const authorlist = (this.publication.authorlist || '').trim();
    const journal = (this.publication.journal || '').trim();

    return (
      this.publication.pmid &&
      this.publication.title &&
      authorlist &&
      journal &&
      this.publication.year &&
      !this.pmidTaken &&
      !this.titleTaken &&
      this.pmidValid &&
      this.yearValid &&
      !this.loading
    );
  }

  /**
   * on change of the title and pmid, check the cache
   */
  checkPmidAndTitle(): void {
    this.pmidTaken = false;
    this.titleTaken = false;
    this.titleTakenBy = '';

    // 'pmid' and 'title' with stripped whitespaces
    const pmid: string = (this.publication.pmid || '').trim();
    const title: string = (this.publication.title || '').trim();

    // it is possible that once whitespaces are trimmed, 'pmid' or/and 'title'
    // become empty strings, in which case 'this.pmid_taken' or/and 'this.title_taken'
    // is set to TRUE, since empty values are invalid.
    this.pmidTaken = !pmid;
    this.titleTaken = !title;

    const cachedPublications = this.publicationService.publications.getValue();

    for (let i = 0; i < cachedPublications.length; i++) {
      if (pmid && cachedPublications[i].pmid === pmid) {
        if (this.newPublication) {
          this.pmidTaken = true;
        } else if (title && title === cachedPublications[i].title) {
          // if we're modifying then we know pmid is valid and if the title hasn't changed then it's valid
          break;
        }
      }
      if (title && title === cachedPublications[i].title) {
        this.titleTakenBy = cachedPublications[i].pmid;
        // time saver, if we're modifying then we already know the pmid is valid
        if (!this.newPublication) {
          break;
        }
      }
    }
  }

  /**
   * deletes this publication;
   * users are prompted to confirm the deletion
   */
  deletePublication(): void {
    if (this.canDelete()) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          header: 'Confirm Delete Publication',
          message:
            'Are you sure that you wish to delete this publication?' +
            (this.publication.projcount > 0
              ? '<br>This publication is associated with one or more projects, ' +
                '<br>so deleting it will result in it being removed from those projects.'
              : ''),
          falselabel: 'Cancel',
          truelabel: 'Delete',
          truebtn: 'btn-danger',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.publicationService.deletePublication(this.publication.pmid).subscribe(() => {
            this.publicationSearchComponent.clearSelection();
            this.publicationChange.emit(this.publication);
          });
        }
      });
    }
  }

  /**
   * Returns true when the value passed is not only whitespaces.
   * @param {string} val - an alphanumeric character string (could be all whitespaces)
   * @return {boolean} - true or false
   */
  isNotEmpty(val: string): boolean {
    if (!val) {
      return false;
    }
    return val.trim() !== '';
  }

  /**
   * Actions for changes in publication values
   *
   * on change of the publication, update various flags and get extra details
   * about projects if a publication was selected
   *
   * This effects this.show_projects, this.pmid_taken, this.title_taken_by,
   * this. new_publication and potentially all the other visible publication
   * fields.
   *
   * @return : None
   */
  onPublicationChange(): void {
    this.showProjects = false;
    this.pmidTaken = false;
    this.titleTaken = false;
    this.titleTakenBy = '';
    this.yearValid = true;
    this.yearMessage = '';
    if (this.publication.pmid) {
      // get information about this individual publication to get the extra details about projects
      this.loading = 'Loading Publication...';
      this.publicationService.getPublication(this.publication.pmid).subscribe(
        (data) => {
          this.publication = data;
          this.publicationChange.emit(this.publication);
          this.publication.show_pmid = true;
          this.pmidValid = true;
          this.newPublication = false;
          this.loading = '';
        },
        () => {
          this.showErrorDialog('An error occurred attempting to update publication info from pubmed.');
          this.loading = '';
        },
      );
    } else {
      this.newPublication = true;
    }
  }

  /**
   * Figure out if the current user is allowed to edit the publication
   *
   * @returns {any}: true if the current user can edit the publication, false if not
   */
  canEdit(): boolean {
    if (this.loading) {
      return false;
    }
    // curators can edit anything
    if (this.isCurator) {
      return true;
    }
    // this is a new publication, so can edit
    if (this.newPublication) {
      return true;
    }
    // creator can edit until a curator approves
    return this.personService.isCurrentUser(this.publication.user_creator.id);
  }

  /**
   * Figure out if the current user is allowed to delete the publication
   *
   * @returns {any}: true if the current user can delete the publication, false if not
   */
  canDelete(): boolean {
    // can't delete something that doesn't exist
    if (this.newPublication) {
      return false;
    }
    // can't delete if can't edit
    if (!this.canEdit()) {
      return false;
    }
    // can only delete if there are also no associated projects
    return this.publication.projcount === 0;
  }

  /**
   * Validate the pubmed id in the publication['pmid'] field
   *
   * This effects pmid_valid and possibly all fields associated with pmid
   *   pmid_valid will be set to true if the pmid is NOPMID or found in pubmed
   *   pmid_valid will be false otherwise
   *   If pmid_valid then system will pull the pubmed record and update title,
   *   author list, journal, year and pmcid if available.
   * @return : None
   */
  validatePmId(): void {
    // Confirm that it's a valid PMID or 'NOPMID'
    if (this.publication.pmid && !this.pmidTaken && this.newPublication) {
      // Confirm that it's a valid pmid or 'NOPMID'
      if (this.publication.pmid === 'NOPMID') {
        this.pmidValid = true;
      } else {
        this.refreshPmid();
      }
    }
  }

  /**
   * Generate simple comma-separated view of projects associated with publication
   *
   * Should ultimately be replace by a nice table format which is within an
   * accordion.
   *
   * @returns {string}: a project array where for each project it contains
   *   JSON structure of projid, projsym, and title
   */
  projectDisplay(): string {
    const projectArray = [];
    for (const project of this.publication.projects) {
      projectArray.push(
        '{projid: ' + project.projid + ', projsym: ' + project.projsym + ', title: ' + project.title + '}',
      );
    }
    return projectArray.join(', ');
  }

  /**
   * Sets publication's pmid with NOPMID flag so that when saved a new
   * "nopm" identifier will be generated.
   *
   *  This effects this.publication['pmid'] and this.pmid_valid
   *   'pmid' will be set to NOPMID to tell the backend to generate a new id at save
   *   this.pmid_valid will be set to True.
   *
   * @return : None
   */
  newNoPmid(): void {
    this.publication.pmid = 'NOPMID';
    this.pmidValid = true;
    this.newPublication = true;
    this.pmidTaken = false;
  }

  /**
   * Refresh Pubmed ID
   *
   * Uses the publication service to poll pubmed and see if the pmid set in
   * this.publication['pmid'] exists in pubmed.
   *
   * This effects this.publication and this.pmid_valid
   *   this.publication will be updated with the title, author list, journal
   *   and year of the publication if a valid publication is found.
   *   this.pmid_valid is True if the pmid exists in pubmed, False otherwise.
   *
   * @param {boolean} forceRefresh: True to force an updated from pubmed. even if the publication is already in MPD
   * @return : None
   */
  refreshPmid(forceRefresh: boolean = false): void {
    // Force application to pull the PMID data from our system if we have it, from
    // pubmed if we don't
    if (!this.loading) {
      this.loading = 'Loading from Pubmed...';
      this.publicationService.getPublication(this.publication.pmid, forceRefresh).subscribe(
        (data) => {
          if ('error' in data && data.error) {
            if (this.newPublication) {
              this.pmidValid = false;
              const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                data: {
                  header: 'Invalid PubMedID',
                  message:
                    'The PubMedID you entered ' +
                    this.publication.pmid +
                    ' cannot be found in PubMed.' +
                    '<br>If you believe this is a valid id, hit Cancel and try loading again. ' +
                    "<br>If you need a placeholder id of the format 'nopm1234', select 'Generate'",
                  falselabel: 'Cancel',
                  truelabel: 'Generate',
                  truebtn: 'btn-danger',
                },
              });

              dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                  this.newNoPmid();
                }
              });
            } else {
              this.showErrorDialog(
                `An error occurred attempting to update publication info from pubmed: ${data.error}`,
              );
            }
          } else {
            if (forceRefresh) {
              // if force refresh, don't remove the user-entered values for these fields
              const capreason = this.publication.capreason;
              const annotator = this.publication.annotator;
              const ournotes = this.publication.ournotes;
              this.publication = data;
              this.publication.capreason = capreason;
              this.publication.annotator = annotator;
              this.publication.ournotes = ournotes;
            } else {
              this.publication = data;
            }
            this.pmidTaken = false;
            this.titleTaken = false;
            this.yearValid = true;
            this.yearMessage = '';
            this.titleTakenBy = '';
            this.newPublication = false;
            this.pmidValid = true;
            if (!this.canEdit() && this.embed && this.embedShowSearch) {
              this.publicationChange.emit(this.publication);
            }
            this.publication.show_pmid = true;
          }
          this.loading = '';
        },
        () => {
          this.showErrorDialog('An error occurred attempting to update publication info from pubmed.');
          this.loading = '';
        },
      );
    }
  }

  /**
   * Shows a sinmple dialog with an error message
   * @param {string} message: error message to display
   */
  showErrorDialog(message: string) {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        header: 'Error',
        message: message,
        hidefalsebtn: true,
        truelabel: 'Close',
        truebtn: 'btn-default',
      },
    });
  }

  /**
   * Save the publication to the database
   *
   * Requires that all mandatory fields are populated, that the title and
   * pmid are not already used and that the pmid and year are valid.
   */
  savePublication() {
    if (this.canSave()) {
      this.loading = 'Saving...';
      this.publicationService.savePublication(this.publication).subscribe(
        (result) => {
          this.publication = result;
          this.publicationChange.emit(this.publication);
          this.publication.show_pmid = true;
          this.newPublication = false;
          this.pmidValid = true;
          flashMessage(this.snackBar, 'Save successful!', 'alert-success', 2000);
          this.loading = '';
        },
        () => {
          flashMessage(this.snackBar, 'Save failed.', 'alert-danger', 5000);
          this.loading = '';
        },
      );
    } else {
      // Because of the disabling of the save button, in theory we should
      // never get here.  If there turns out to be a case I've missed
      console.log('Unable to save due to unknown condition.  Examine publication object below:');
      console.log(this.publication);
      console.log(this.pmidValid);
    }
  }

  /**
   * Validate year
   *
   * Validates that a year is of 4 numeric characters and is
   * between the years 1920 and the current year.  The start year is somewhat
   * arbitrary and can be changed if we think there are somehow older articles
   * we need to enter.  Also, if we want to be able to put in a year when an
   * article is expected to be published, I could do something like current
   * year + 2
   *
   * This effects the this.year_valid and this.year_message
   *   this.year_valid indicates if the year is valid and can be referenced
   *   without calling the method again.
   *   this.year_message is defaulted to blank and updated in the case
   *   where year_valid is false.
   * @returns : None
   */
  validateYear(): void {
    this.yearMessage = '';
    this.yearValid = true;
    const year = this.publication.year;
    const text = /^[0-9]+$/;
    const currentYear = new Date().getFullYear();
    // If even that calls this is other than "blur" then will show error
    // message until 4 characters present.
    if ((year !== '' && !text.test(year)) || year.length !== 4) {
      this.yearValid = false;
      this.yearMessage = 'Year must be in valid 4 digit format, no whitespaces.';
    } else if (year < 1920 || year > currentYear) {
      this.yearValid = false;
      this.yearMessage = 'Year should be in range 1920 to current year.';
    }
  }
}
