import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class StrainTypeService {
  // api url
  private api: string = environment.securedURLs.sip;

  // center behavioursubject for building an observable
  public types = new BehaviorSubject<any[]>([]);
  // observable, which can be subscribed to in order to get changes to the center list any time it is updated
  public types$ = this.types.asObservable();
  // flag as to whether the centers list has been initialized yet
  private typesListInitialized = false;

  // declare http client
  constructor(private http: HttpClient) {
    if (!this.typesListInitialized) {
      this.typesListInitialized = true;
      this.getType().subscribe();
    }
  }

  /**
   * Get either a specific strain or a list of all of them
   *
   * @param {number} code: code for specific strain type to retrieve or null to get a list of all strain types
   * @returns {Observable<any>} Observable
   */
  getType(code: string | null = null): Observable<any> {
    let url = this.api + 'strains/strain-types';
    if (code) {
      url += '?code=' + code;
    }

    return this.http.get<any>(url).pipe(
      tap((result) => {
        if (!code) {
          this.typesListInitialized = true;
          // cache the list and trigger the types$ observable
          // so that all subscribers get the updated list
          this.types.next(result);
        }
      }),
    );
  }
}
