import { Observable, from, of } from 'rxjs';
import { catchError, concatMap } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpUserEvent,
} from '@angular/common/http';

import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  // constructor declarations
  constructor(public auth: AuthService, private injector: Injector, private router: Router) {}

  /**
   * If there is a token, adds it as a an Authorization: Bearer header to the request
   *
   * @param {HttpRequest<any>} req: http request we're adding a token to
   * @param {string} token: access token string
   * @returns {HttpRequest<any>}: request with auth header added
   */
  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    if (!token) {
      return req;
    } else {
      return req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
  }

  /**
   * Intercept all requests to the api and add the auth0 authorization: bearer token to the request headers.
   * * if angular fails to get a token and returns an error, if it's SIP or MPD then pass the request through without a
   * token (and the backend/api will determine whether it's information that can be accessed without auth),
   * or don't pass the request though and just return the auth error.
   *
   * NOTE: The front-end should have error handling on request subscribe() calls that lets users know if an error
   *       occurred with a request, regardless of whether the error was caused by no auth token or returned from
   *       the api/backend.
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    if (
      stringContainsStringFromList(request.url, [
        environment.securedURLs.sip,
        environment.securedURLs.mpd,
        environment.securedURLs.mpdPreview,
      ])
    ) {
      const getTokenNoError$ = this.auth.getToken$.pipe(catchError(() => of('')));
      return getTokenNoError$.pipe(concatMap((token: string) => from(next.handle(this.addToken(request, token)))));
    } else if (isRequestingAnAuthorizedURL(request)) {
      // catch errors with getting auth0 access token and just turn them into a null token
      return this.auth.getToken$.pipe(concatMap((token: string) => from(next.handle(this.addToken(request, token)))));
    } else {
      return next.handle(request);
    }
  }
}

/**
 * A helper function for the interceptor to allow it to check API calls against URLs stored in the environment file
 * instead of manually needing to add checks against each one in the interceptor code
 * @param {HttpRequest} request - request to check the URL of to see if it should be handled with an auth token
 */
export function isRequestingAnAuthorizedURL(request: HttpRequest<any>): boolean {
  // get the URLs set in the environment file
  const URLs: string[] = Object.values(environment.securedURLs);

  return stringContainsStringFromList(request.url, URLs);
}

/**
 * A helper function that checks the that the specified string is included in at least one string in the specified array
 * @param {string} input
 * @param {string[]} containsList
 */
export function stringContainsStringFromList(input: string, containsList: string[]): boolean {
  for (let i = 0; i < containsList.length; i++) {
    // if URL is found, return true
    if (containsList[i] && input.includes(containsList[i])) {
      return true;
    }
  }

  // if nothing is found, return false
  return false;
}
