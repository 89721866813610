import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GroupService } from '../../services/group.service';
import { PersonService } from '../../services/person.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../shared/dialogs/confirmation-dialog.component';

@Component({
  selector: 'group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss'],
})
export class GroupComponent implements OnInit {
  // groupid from the url
  groupId: string | null = null;

  // group dict
  group: any = { members: [] };

  // currently selected person object in the person search for editors (users only)
  personSearch: any = {};

  // used to determine whether there is a current user, and whether they can edit groups based
  // on whether they are restricted (not on the whitelist), or their email isn't verified
  userUnrestricted = false;

  // used to determine the reason that the user cannot edit (email not verified or restricted)
  user: any = null;

  // role to assign to a new person
  newRole = 'Editor';

  // variables and flags for ease of reference in the UI
  roleOptions: string[] = [];
  isAdmin = false;
  isOnlyAdmin = false;
  isEditor = false;
  editingName = false;
  newName = '';
  showDescription = false;
  noGroup = true;

  constructor(
    private route: ActivatedRoute,
    private groupService: GroupService,
    private personService: PersonService,
    private router: Router,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.groupId = this.route.snapshot.paramMap.get('id') || null;
    if (Number(this.groupId)) {
      this.refreshGroup();
    }

    this.personService.currentUser$.subscribe((result) => {
      this.user = result;
      this.userUnrestricted = this.personService.userUnrestricted();
    });
  }

  // get the group
  refreshGroup() {
    if (this.groupId) {
      this.groupService.getGroup(this.groupId).subscribe((data) => {
        this.noGroup = false;
        this.group = data;
        this.setFlags();
      });
    }
  }

  // set access flags based the role of the current user within the group
  setFlags() {
    this.newName = this.group.name;
    this.editingName = false;
    this.roleOptions = [];
    this.isAdmin = false;
    this.isOnlyAdmin = false;
    this.isEditor = false;
    if (this.group.current_user_role === 'Admin') {
      this.roleOptions = ['Admin', 'Editor', 'Viewer'];
      this.isAdmin = true;
      this.isOnlyAdmin = true;
      for (let i = 0; i < this.group.members.length; i++) {
        if (!this.group.members[i].is_current_user && this.group.members[i].role === 'Admin') {
          this.isOnlyAdmin = false;
        }
      }
    } else if (this.group.current_user_role === 'Editor') {
      this.roleOptions = ['Editor', 'Viewer'];
      this.isEditor = true;
    }
  }

  /**
   * Tells the user the reason that they can't modify the group if they have a role
   *
   * @returns {string}: note about why the user can't edit the group if they're restricted
   */
  permissionNote(): string {
    if (!this.user) {
      return '';
    }
    const note = 'Note: You are unable to modify this group ';
    if (this.user.restricted) {
      return note + 'at this time because edit access to SIP is restricted.';
    }
    if (!this.user.emailverified) {
      return note + 'until you verify your email on your profile.';
    }
    return '';
  }

  // start editing the name and focus on the input
  editName() {
    if (this.userUnrestricted && this.isAdmin) {
      this.editingName = true;
      setTimeout(() => {
        document.getElementById('new_group_name')?.focus();
      }, 10);
    }
  }

  /**
   * Determines whether the current user can modify a member's role
   *
   * @param {any} member: member dict
   * @returns {any}: true to allow editing the role, false to not
   */
  canEditMemberRole(member: any) {
    if (!this.userUnrestricted) {
      return false;
    }
    // don't allow an admin to change their own role if they're the only one
    if (this.isOnlyAdmin && member.is_current_user) {
      return false;
    }
    // admin can change any role and edits can change upgrade view roles to editors
    return this.isAdmin || (member.role && this.isEditor);
  }

  /**
   * Add a new member with the role, or modify the role of an existing member
   * Confirm that the user is sure before changing (reducing) their own role
   *
   * @param {any} member: member dict of the member we are adding or modifying
   * @param {string} role: role of the member we are adding or modifying
   */
  addOrModifyMemberConfirm(member: any, role: string) {
    if ((member.id || member.email) && role && this.userUnrestricted) {
      if (member.is_current_user) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          data: {
            header: 'Confirm Role Change',
            message:
              'Are you sure that you wish change your role to ' +
              role +
              '?' +
              '<br>You will be unable to undo this change on your own.' +
              (role === 'Viewer'
                ? '<br>Also, you may lose the ability to edit projects for which this group has edit rights.'
                : ''),
            falselabel: 'Cancel',
            truelabel: 'Change',
            truebtn: 'btn-danger',
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.addOrModifyMember(member.id, role);
          } else {
            if (this.isAdmin) {
              member.role = 'Admin';
            } else {
              member.role = 'Editor';
            }
          }
        });
      } else {
        this.addOrModifyMember(member.id, role, member.email);
      }
    }
  }

  /**
   * Add a new member with the role, or modify the role of an existing member
   *
   * @param {any} id: person id of the member we are adding or modifying
   * @param {string} role: role of the member we are adding or modifying
   * @param {string} email (optional): an email can be provided in place of an id if we want to invite a new user to MPD
   */
  addOrModifyMember(id: number, role: string, email: string = '') {
    if ((id || email) && role && this.userUnrestricted) {
      this.groupService
        .saveGroup({
          id: this.group.id,
          members: [{ id: id, role: role, email: email }],
        })
        .subscribe((data) => {
          this.group = data;
          this.setFlags();
        });
    }
  }

  /**
   * Determines whether the option to remove a member should be shown
   *
   * @param {any} member: member dict
   * @returns {boolean}: true to show button to remove member
   */
  showRemoveMember(member: any) {
    // admins can remove any user and users can remove themselves (unless it's disabled... see disableRemoveMember),
    // as long as the current is is unrestricted (verified email and app settings allows them)
    return this.userUnrestricted && (this.isAdmin || member.is_current_user);
  }

  /**
   * Determines whether the option to remove a member should be disabled
   * NOTE: This won't be checked unless showRemoveMember passes
   *
   * @param {any} member: member dict
   * @returns {boolean}: true to disable button to remove member
   */
  disableRemoveMember(member: any) {
    // an admin can't remove themselves if they are the only admin
    return this.isOnlyAdmin && member.is_current_user;
  }

  /**
   * Tooltip that shows on the remove member button
   * NOTE: This won't be used/displayed unless showRemoveMember passes
   *
   * @param {any} member: member dict
   * @returns {boolean}: tooltip string
   */
  removeMemberTooltip(member: any) {
    // if the button is disabled, don't show this tooltip
    if (this.disableRemoveMember(member)) {
      return '';
    }
    return 'Remove this member from group';
  }

  /**
   * Tooltip that shows if the remove member button is disabled
   * (put on the div that the button is in so that it shows in chrome even though the button is disabled)
   * NOTE: This won't be used/displayed unless showRemoveMember passes
   *
   * @param {any} member: member dict
   * @returns {boolean}: tooltip string
   */
  removeMemberDisabledTooltip(member: any) {
    if (this.disableRemoveMember(member)) {
      return 'You cannot remove yourself from this group because you are the only Admin';
    }
    return '';
  }

  /**
   * Remove the member (only an Admin or the user themself can do this).
   * Confirm that the user is sure before removing themself.
   *
   * @param {any} member: member dict of the member we are removing
   */
  removeMemberConfirm(member: any) {
    if (member.id && this.userUnrestricted) {
      if (member.is_current_user) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          data: {
            header: 'Confirm Removal',
            message:
              'Are you sure that you wish remove yourself from this group?' +
              '<br>You may lose access to any projects that have been shared with this group.',
            falselabel: 'Cancel',
            truelabel: 'Remove',
            truebtn: 'btn-danger',
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.removeMember(member);
          }
        });
      } else {
        this.removeMember(member);
      }
    }
  }

  /**
   * Remove the member (only an Admin or the user themself can do this)
   *
   * @param {any} member: member dict of the member we are removing
   */
  removeMember(member: any) {
    if (member.id && this.userUnrestricted) {
      this.groupService
        .saveGroup({
          id: this.group.id,
          members: [{ id: member.id, remove: 'true' }],
        })
        .subscribe((data) => {
          this.group = data;
          this.setFlags();
        });
    }
  }

  // save a new name for this group (only Admins can do this)
  saveName() {
    if (this.newName && this.userUnrestricted) {
      this.groupService.saveGroup({ id: this.group.id, name: this.newName }).subscribe(
        (data) => {
          this.group = data;
          this.setFlags();
        },
        (err) => {
          const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
              header: 'Error',
              message: err.error ? err.error.message : 'Unknown error occurred saving group name',
              hidefalsebtn: true,
              truelabel: 'Close',
              truebtn: 'btn-default',
            },
          });
          dialogRef.afterClosed().subscribe();
        },
      );
    }
  }

  // delete this group (only Admins can do this)
  deleteGroup() {
    if (this.userUnrestricted) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          header: 'Confirm Delete Group',
          message:
            'Are you sure that you wish to delete the group, "' +
            this.group.name +
            '"?' +
            '<br>You may lose access to any projects that have been shared with this group.',
          falselabel: 'Cancel',
          truelabel: 'Delete',
          truebtn: 'btn-danger',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.groupService.deleteGroup(this.group.id).subscribe(() => {
            this.router.navigate(['/groups']);
          });
        }
      });
    }
  }
}
