// Angular imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// External imports
import { MatTableExporterModule } from 'mat-table-exporter';

// Modules
import { MaterialModule } from './material.module';
import { PipesModule } from './pipes/pipes.module';

// Components
import { BioconnectAssaySearchComponent } from './entity-searching/bioconnect-assay-search.component';
import { BioconnectStudySearchComponent } from './entity-searching/bioconnect-study-search.component';
import { CenterDialogComponent } from './dialogs/center-dialog.component';
import { CenterSearchComponent } from './entity-searching/center-search.component';
import { ComboboxComponent } from './combobox.component';
import { ConfirmationDialogComponent } from './dialogs/confirmation-dialog.component';
import { DynamicButtonDialogComponent } from './dialogs/dynamic-button-dialog.component';
import { EditableTableComponent } from './editable-table/editable-table.component';
import { EditCenterComponent } from './entity-editing/edit-center/edit-center.component';
import { EditInstitutionComponent } from './entity-editing/edit-institution/edit-institution.component';
import { EditInterventionComponent } from './entity-editing/edit-intervention/edit-intervention.component';
import { EditInvestigatorComponent } from './entity-editing/edit-investigator/edit-investigator.component';
import { EditMethodologyComponent } from './entity-editing/edit-methodology/edit-methodology.component';
import { EditPanelComponent } from './entity-editing/edit-panel/edit-panel.component';
import { EditPersonComponent } from './entity-editing/edit-person/edit-person.component';
import { EditProcedureComponent } from './entity-editing/edit-procedure/edit-procedure.component';
import { EditPublicationComponent } from './entity-editing/edit-publication/edit-publication.component';
import { EditStrainComponent } from './entity-editing/edit-strain/edit-strain.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FlashMessageComponent } from './flash-message.component';
import { GroupSearchComponent } from './entity-searching/group-search.component';
import { ImagePreviewDialogComponent } from './dialogs/image-preview-dialog.component';
import { InstitutionDialogComponent } from './dialogs/institution-dialog.component';
import { InstitutionSearchComponent } from './entity-searching/institution-search.component';
import { InterventionDialogComponent } from './dialogs/intervention-dialog.component';
import { InterventionSearchComponent } from './entity-searching/intervention-search.component';
import { LogViewerDialogComponent } from './dialogs/log-viewer-dialog.component';
import { MethodologyDialogComponent } from './dialogs/methodology-dialog.component';
import { MethodologySearchComponent } from './entity-searching/methodology-search.component';
import { OntologySearchComponent } from './entity-searching/ontology-search.component';
import { PanelDialogComponent } from './dialogs/panel-dialog.component';
import { PanelSearchComponent } from './entity-searching/panel-search.component';
import { PersonDialogComponent } from './dialogs/person-dialog.component';
import { PersonDisplayComponent } from './entity-displays/person-display.component';
import { PersonSearchComponent } from './entity-searching/person-search.component';
import { ProcedureDialogComponent } from './dialogs/procedure-dialog.component';
import { ProcedureSearchComponent } from './entity-searching/procedure-search.component';
import { ProjectActionsComponent } from './project-actions/project-actions.component';
import { ProjectImportExportDialogComponent } from './dialogs/project-import-export.component';
import { ProtocolIOSearchComponent } from './entity-searching/protocols-io-search.component';
import { PublicationDialogComponent } from './dialogs/publication-dialog.component';
import { PublicationDisplayComponent } from './entity-displays/publication-display.component';
import { PublicationSearchComponent } from './entity-searching/publication-search.component';
import { RestrictedAccessComponent } from './restricted-access.component';
import { StateButtonsComponent } from './state-buttons/state-buttons.component';
import { StrainDialogComponent } from './dialogs/strain-dialog.component';
import { StrainSearchComponent } from './entity-searching/strain-search.component';
import { StrainTypeSearchComponent } from './entity-searching/strain-type-search.component';
import { StrainVendorSearchComponent } from './entity-searching/strain-vendor-search.component';
import { TableComponent } from './table/table.component';
import { TimelineComponent } from './timeline/timeline.component';
import { SelectionTreeComponent } from './selection-tree/selection-tree.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    MatTableExporterModule,
    PipesModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  declarations: [
    BioconnectAssaySearchComponent,
    BioconnectStudySearchComponent,
    CenterDialogComponent,
    CenterSearchComponent,
    ComboboxComponent,
    ConfirmationDialogComponent,
    DynamicButtonDialogComponent,
    EditableTableComponent,
    EditCenterComponent,
    EditInstitutionComponent,
    EditInterventionComponent,
    EditInvestigatorComponent,
    EditMethodologyComponent,
    EditPanelComponent,
    EditPersonComponent,
    EditProcedureComponent,
    EditPublicationComponent,
    EditStrainComponent,
    FileUploadComponent,
    FlashMessageComponent,
    GroupSearchComponent,
    ImagePreviewDialogComponent,
    InstitutionDialogComponent,
    InstitutionSearchComponent,
    InterventionDialogComponent,
    InterventionSearchComponent,
    LogViewerDialogComponent,
    MethodologyDialogComponent,
    MethodologySearchComponent,
    OntologySearchComponent,
    PanelDialogComponent,
    PanelSearchComponent,
    PersonDialogComponent,
    PersonDisplayComponent,
    PersonSearchComponent,
    ProcedureDialogComponent,
    ProcedureSearchComponent,
    ProjectActionsComponent,
    ProjectImportExportDialogComponent,
    ProtocolIOSearchComponent,
    PublicationDialogComponent,
    PublicationDisplayComponent,
    PublicationSearchComponent,
    RestrictedAccessComponent,
    SelectionTreeComponent,
    StateButtonsComponent,
    StrainDialogComponent,
    StrainSearchComponent,
    StrainTypeSearchComponent,
    StrainVendorSearchComponent,
    TableComponent,
    TimelineComponent,
  ],
  exports: [
    BioconnectAssaySearchComponent,
    BioconnectStudySearchComponent,
    CenterDialogComponent,
    CenterSearchComponent,
    ComboboxComponent,
    ConfirmationDialogComponent,
    DynamicButtonDialogComponent,
    EditableTableComponent,
    EditCenterComponent,
    EditInstitutionComponent,
    EditInterventionComponent,
    EditInvestigatorComponent,
    EditMethodologyComponent,
    EditPanelComponent,
    EditPersonComponent,
    EditProcedureComponent,
    EditPublicationComponent,
    EditStrainComponent,
    FileUploadComponent,
    FlashMessageComponent,
    GroupSearchComponent,
    ImagePreviewDialogComponent,
    InstitutionDialogComponent,
    InstitutionSearchComponent,
    InterventionDialogComponent,
    InterventionSearchComponent,
    LogViewerDialogComponent,
    MethodologyDialogComponent,
    MethodologySearchComponent,
    OntologySearchComponent,
    PanelDialogComponent,
    PanelSearchComponent,
    PersonDialogComponent,
    PersonDisplayComponent,
    PersonSearchComponent,
    ProcedureDialogComponent,
    ProcedureSearchComponent,
    ProjectActionsComponent,
    ProjectImportExportDialogComponent,
    ProtocolIOSearchComponent,
    PublicationDialogComponent,
    PublicationDisplayComponent,
    PublicationSearchComponent,
    RestrictedAccessComponent,
    SelectionTreeComponent,
    StateButtonsComponent,
    StrainDialogComponent,
    StrainSearchComponent,
    StrainTypeSearchComponent,
    StrainVendorSearchComponent,
    TableComponent,
    TimelineComponent,
  ],
})
export class SharedModule {}
