import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'array-upload',
  templateUrl: './array-upload.component.html',
  styleUrls: ['./array-upload.component.scss'],
})
export class ArrayUploadComponent {
  // genotype array that needs to be displayed
  @Input() genotypeArrayForm?: FormGroup;

  // status of the array file uploads
  uploadStatus = '';

  // error message for HTTP calls
  uploadError = '';

  // emits when user clicks the remove/cancel button for the array
  @Output() remove: EventEmitter<null> = new EventEmitter<null>();

  // emits when user clicks the upload button to save the files they selected
  @Output() upload: EventEmitter<ArrayData> = new EventEmitter<ArrayData>();

  /**
   * Sets the array upload status to processing to make the spinner visible and emits an object with
   * the selected platform, sample map file and final report file
   * @param sampleMap - sample map file input element
   * @param finalReport - final report file input element
   */
  uploadFiles(sampleMap: HTMLInputElement, finalReport: HTMLInputElement): void {
    this.uploadStatus = 'processing';
    this.uploadError = '';

    if (sampleMap.files && finalReport.files) {
      this.upload.emit({
        platform: this.platform?.value,
        sampleMap: sampleMap.files[0],
        finalReport: finalReport.files[0],
      });
    }
  }

  /**
   * Returns the appropriate validation error message for the sample map file input
   * based on error priority and to reduce the error messages down to one at a time
   * (otherwise multiple can render at the same time)
   */
  getSampleMapErrorMsg(): string {
    if (this.sampleMap?.hasError('required')) {
      return 'Please select a sample map file to upload';
    } else if (this.sampleMap?.hasError('invalidType')) {
      return 'Sample map files should have a .zip or .txt extension';
    } else if (this.sampleMap?.hasError('invalidName')) {
      return "Neogen sample map files should be named 'Sample_Map.zip'";
    } else if (this.sampleMap?.hasError('cannotContainSpaces')) {
      return 'Filenames cannot contain spaces. Please replace spaces with underscores';
    }
    // if more validators are added, add a condition for each
    return '';
  }

  /**
   * Returns the appropriate validation error message for the final report file input
   * based on error priority and to reduce the error messages down to one at a time
   * (otherwise multiple can render at the same time)
   */
  getFinalReportErrorMsg(): string {
    if (this.finalReport?.hasError('required')) {
      return 'Please select a final report file to upload';
    } else if (this.finalReport?.hasError('invalidType')) {
      return 'Final report files should have a .zip or .txt extension';
    } else if (this.finalReport?.hasError('invalidName')) {
      return "Neogen final report files should be named '[array name]_FinalReport.zip'";
    } else if (this.finalReport?.hasError('cannotContainSpaces')) {
      return 'Filenames cannot contain spaces. Please replace spaces with underscores';
    } else if (this.finalReport?.hasError('duplicateFilename')) {
      return 'A final report with this name has already been uploaded';
    }
    // if more validators are added, add a condition for each
    return '';
  }

  /**
   * Getter function that returns the platform form control
   */
  get platform() {
    return this.genotypeArrayForm?.get('platform');
  }

  /**
   * Getter function that returns the sample map form control
   */
  get sampleMap() {
    return this.genotypeArrayForm?.get('sampleMap');
  }

  /**
   * Getter function that returns the final report form control
   */
  get finalReport() {
    return this.genotypeArrayForm?.get('finalReport');
  }
}

export interface ArrayData {
  platform: string;
  sampleMap: File;
  finalReport: File;
}
