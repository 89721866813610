<form>
  <!-- Genotyping platform -->
  <div class="col-sm-12 col-md-3 col-lg-2">
    <button mat-button disableRipple="true">Platform: {{ arrayData.platform | MUGACase }}</button>
  </div>

  <!-- Link to sample map file -->
  <div class="col-sm-12 col-md-2 col-lg-2">
    <a mat-stroked-button color="accent" [href]="arrayData.sampleMap.location" target="_blank">
      {{ arrayData.sampleMap.name }}
    </a>
  </div>

  <div class="col-sm-12 col-md-6 col-lg-8">
    <!-- Link to final report file -->
    <a mat-stroked-button color="accent" [href]="arrayData.finalReport.location" target="_blank">
      {{ arrayData.finalReport.name }}
    </a>

    <!-- TODO: Deletion of uploaded/saved arrays is not yet supported by DivDB API -->
    <button
      *ngIf="editable"
      mat-icon-button
      style="display: none"
      id="remove"
      aria-label="Remove"
      matTooltip="Remove genotype array"
      (click)="remove.emit()"
    >
      <mat-icon>delete_outline</mat-icon>
    </button>
  </div>
</form>
