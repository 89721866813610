import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

// dialog for adding/editing/viewing panels on-the-fly
@Component({
  selector: 'panel-dialog',
  template: `
    <div mat-dialog-content style="min-width:800px;">
      <edit-panel
        [(panel)]="data.panel"
        [embed]="true"
        (cancel)="dialogRef.close()"
        (panelChange)="dialogRef.close($event)"
      ></edit-panel>
    </div>
  `,
})
export class PanelDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<PanelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { panel: any },
  ) {}
}
