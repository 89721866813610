// Angular imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// Modules
import { MaterialModule } from '../../shared/material.module';
import { PipesModule } from '../../shared/pipes/pipes.module';
import { SharedModule } from '../../shared/shared.module';

// Components
import { AnimalInfoUploadComponent } from './animal-info-upload/animal-info-upload.component';
import { ArrayUploadComponent } from './array-upload/array-upload.component';
import { DatasetDetailsComponent } from './dataset-details/dataset-details.component';
import { GenotypeIntakeComponent } from './genotype-intake.component';
import { InventoryReviewComponent } from './inventory-review/inventory-review.component';
import { RestoredArrayComponent } from './restored-array/restored-array.component';
import { DatasetProcessingComponent } from './dataset-processing/dataset-processing.component';
import { PublicationLinksComponent } from './publication-links/publication-links.component';
import { AnimalLinksComponent } from './publication-links/animal-links.component';

@NgModule({
  imports: [CommonModule, FormsModule, MaterialModule, PipesModule, ReactiveFormsModule, RouterModule, SharedModule],
  declarations: [
    AnimalInfoUploadComponent,
    ArrayUploadComponent,
    DatasetDetailsComponent,
    DatasetProcessingComponent,
    GenotypeIntakeComponent,
    InventoryReviewComponent,
    PublicationLinksComponent,
    RestoredArrayComponent,
    AnimalLinksComponent,
  ],
})
export class GenotypeIntakeModule {}
