import { Component, OnInit, ViewChild } from '@angular/core';
import { HeaderComponent } from './components/header/header.component';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Person, PersonService } from './services/person.service';
import { AuthService } from './services/auth.service';
import { SideNavComponent } from './components/side-nav/side-nav.component';

@Component({
  selector: 'root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  // navigation sidebar child component
  @ViewChild(SideNavComponent) sideNav!: SideNavComponent;

  // header child component
  @ViewChild(HeaderComponent) header!: HeaderComponent;

  // current user information
  user?: Person;

  // status of whether the sidebar is open or closed
  sideNavOpen = false;

  constructor(
    public http: HttpClient,
    public router: Router,
    public personService: PersonService,
    public auth: AuthService,
  ) {
    this.auth.localAuthSetup();
  }

  ngOnInit() {
    // get user information
    this.personService.currentUser$.subscribe((user) => {
      this.user = user;
      // if the user is signed in, redirect them to the dashboard page
      if (this.user?.id && this.router.url.startsWith('/welcome')) {
        this.router.navigate(['/dashboard']);
      }
    });
    this.sideNavOpen = this.header?.sideNavOpen || false;
  }
}
