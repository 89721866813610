import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'help',
  templateUrl: 'help.component.html',
})
export class HelpComponent implements OnInit {
  // true to embed this component, false to not
  @Input() embed = false;

  // loading bar boolean
  loading = true;

  // faq file text
  faqFile: any = null;

  // api URL
  api: string = environment.securedURLs.sip;

  // declare http and dialog
  constructor(public http: HttpClient) {}

  ngOnInit() {
    this.getFaqFile();
  }

  // load the the faq files list to get the file id, then get the faq file text
  getFaqFile() {
    const faqListUrl = this.api + 'files?pointertype=app&pointer=1&tag=faq';
    this.http.get<any[]>(faqListUrl).subscribe(
      (result) => {
        if (result.length > 0) {
          const faqFileUrl = this.api + 'files?mode=file&id=' + result[0].id;
          this.http.get(faqFileUrl, { responseType: 'text', observe: 'response' }).subscribe(
            (fileResult) => {
              this.faqFile = fileResult.body;
              this.loading = false;
            },
            () => {
              this.loading = false;
            },
          );
        } else {
          this.loading = false;
        }
      },
      () => {
        this.loading = false;
      },
    );
  }
}
