<div id="edit-current-node-div" *ngIf="currentNode">
  <!-- Project ISA Data Header Row, hidden on tablet and mobile view-->
  <div class="my-form-group">
    <div class="row hidden-sm hidden-xs">
      <div class="col-md-2"></div>
      <div class="col-md-4">
        <label>
          Node Type
          <span
            matTooltip="Material nodes can also be used as a generic structure to describe materials consumed or
                            produced during an experimental workflow. Process nodes represent the application of a
                            protocol to some input material (e.g. a Source) to produce some output (e.g.a Sample).
                            Data nodes represent outputs resulting from a protocol application that corresponds to
                            some process that produces data, typically in the form of data files."
            class="glyphicon glyphicon-question-sign"
          ></span>
        </label>
      </div>
      <div class="col-md-4">
        <label>Name</label>
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>
  <div class="row">
    <!-- add child node -->
    <div class="col-md-2 my-form-group action-icon-add-container">
      <span
        *ngIf="canEdit"
        matTooltip="Add Child Node"
        (click)="addChild(currentNode.id)"
        class="glyphicon glyphicon-plus btn action-icon-add"
      ></span>
    </div>
    <!-- node type -->
    <div class="col-md-4 my-form-group">
      <label [for]="'isa_type_node_' + currentNode.id" class="visible-sm visible-xs">
        Node Type
        <span
          matTooltip="Material nodes can also be used as a generic structure to describe materials consumed or
                          produced during an experimental workflow. Process nodes represent the application of a
                          protocol to some input material (e.g. a Source) to produce some output (e.g.a Sample).
                          Data nodes represent outputs resulting from a protocol application that corresponds to
                          some process that produces data, typically in the form of data files."
          class="glyphicon glyphicon-question-sign"
        ></span>
      </label>
      <select
        [(ngModel)]="currentNode.isa_type"
        [disabled]="!canEdit"
        class="form-control"
        [id]="'isa_type_node_' + currentNode.id"
        (change)="isaTypeChange()"
      >
        <option value="Data">Data</option>
        <option value="Material">Material</option>
        <option value="Process">Process</option>
      </select>
    </div>
    <!-- node name -->
    <div class="col-md-4 my-form-group">
      <label [for]="'name_node_' + currentNode.id" class="visible-sm visible-xs">Name</label>
      <input
        type="text"
        [(ngModel)]="currentNode.name"
        [disabled]="!canEdit"
        class="form-control"
        maxlength="150"
        [id]="'name_node_' + currentNode.id"
        (change)="plotIsaNodes()"
      />
    </div>
    <!-- button to show/hide the rest of the fields-->
    <div class="col-md-1 my-form-group">
      <div *ngIf="!showExtraFields">
        <span
          (click)="showExtraFields = true"
          *ngIf="canEdit"
          matTooltip="Edit Node Details"
          class="glyphicon glyphicon-pencil btn btn-primary"
        ></span>
        <span
          (click)="showExtraFields = true"
          *ngIf="!canEdit"
          matTooltip="View Node Details"
          class="glyphicon glyphicon-eye-open btn btn-primary"
        ></span>
      </div>
      <div *ngIf="showExtraFields">
        <span
          (click)="showExtraFields = false"
          matTooltip="Hide Node Details"
          class="glyphicon glyphicon-eye-close btn btn-default"
        ></span>
      </div>
    </div>
    <!-- delete node button -->
    <div *ngIf="canEdit" class="col-md-1 my-form-group">
      <button *ngIf="canEdit" matTooltip="Delete Node" (click)="removeNode(currentNode.id)" class="btn btn-danger">
        <span class="glyphicon glyphicon-trash"></span>
      </button>
    </div>
  </div>
  <fieldset [disabled]="!canEdit">
    <!-- Description (shows for all node types) -->
    <div *ngIf="showExtraFields" class="row my-form-group whitesmoke top-radius">
      <label class="col-md-2 label-padding" [for]="'description_node_' + currentNode.id"> Description </label>
      <div class="col-md-10">
        <input
          type="text"
          [(ngModel)]="currentNode.descrip"
          class="form-control"
          maxlength="500"
          [id]="'description_node_' + currentNode.id"
          (change)="plotIsaNodes()"
        />
      </div>
    </div>
  </fieldset>
  <!-- link root node to a procedure (doesn't show if node has parents)-->
  <div *ngIf="showExtraFields && !currentNode?.parents.length" class="row my-form-group whitesmoke">
    <label class="col-md-2 label-padding">Procedure</label>
    <div class="col-md-10">
      <procedure-search
        [(procedure)]="currentNode.procedure"
        (procedureChange)="plotIsaNodes()"
        [inputOptions]="project.procedures"
        [disabled]="!canEdit"
      ></procedure-search>
    </div>
  </div>
  <fieldset [disabled]="!canEdit">
    <!-- links to parent nodes of current node (doesn't show if procedure is linked) -->
    <div
      *ngIf="showExtraFields && !currentNode?.procedure?.proc_id"
      [class]="'row my-form-group whitesmoke' + (currentNode.isa_type ? '' : ' bottom-radius')"
    >
      <label class="col-md-2 label-padding">Parents</label>
      <div class="col-md-5 label-padding">
        <span *ngFor="let parent of parents">
          <b>ID:</b> {{ parent.id }} <b>Node Type:</b> {{ parent.isa_type ? parent.isa_type : '(None)' }} <b>Name:</b>
          {{ parent.name ? parent.name : '(None)' }}
          <span
            *ngIf="canEdit"
            style="color: red"
            class="glyphicon glyphicon-remove"
            matTooltip="Remove Parent"
            (click)="unlinkFromParent(currentNode.id, parent.id)"
          ></span>
          &nbsp;&nbsp;
        </span>
      </div>
      <label class="col-md-2 label-padding">Link to Parent</label>
      <div class="col-md-3">
        <select [(ngModel)]="parentLinkId" class="form-control" (change)="onParentLinkChange()">
          <option [value]="null"></option>
          <option *ngFor="let option of parentOptions" [value]="option.id">
            ID: {{ option.id }} Node Type: {{ option.isa_type ? option.isa_type : '(None)' }} Name:
            {{ option.name ? option.name : '(None)' }}
          </option>
        </select>
      </div>
    </div>
    <!-- data node datatype, link url, and button to show/hide file upload form -->
    <div *ngIf="showExtraFields && currentNode.isa_type === 'Data'" class="row my-form-group whitesmoke bottom-radius">
      <label class="col-md-2 label-padding" [for]="'datatype_node_' + currentNode.id">Data Type</label>
      <div class="col-md-3">
        <select [(ngModel)]="currentNode.datatype" class="form-control" [id]="'datatype_node_' + currentNode.id">
          <!-- TODO:  AA 05/20/2020
                          Hard coding these for TCI 45, in a future ticket they
                          will be populated from the metadata service -->
          <option value="3D nucleome">3D nucleome</option>
          <option value="Bulk ATAC-Seq">Bulk ATAC-Seq</option>
          <option value="Bulk RNA-Seq">Bulk RNA-Seq</option>
          <option value="Microbiome">Microbiome</option>
          <option value="qPCR">qPCR</option>
          <option value="scATAC-Seq">scATAC-Seq</option>
          <option value="scRNA-Seq">scRNA-Seq</option>
          <option value="Structural Variants">Structural Variants</option>
          <option value="Other">Other</option>
          <!--Below here was previously only available in "Child" project links-->
          <option value="Alignments">Alignments</option>
          <option value="eQTL">eQTL</option>
          <option value="Expression Counts">Expression Counts</option>
          <option value="Expression Normalized">Expression Normalized</option>
          <option value="Gene Counts">Gene Counts</option>
          <option value="Module Eigengenes">Module Eigengenes</option>
          <option value="Peak Calls">Peak Calls</option>
          <option value="Raw Reads">Raw Reads</option>
          <option value="Signal">Signal</option>
          <option value="Splice Junctions">Splice Junctions</option>
          <option value="Transcript Counts">Transcript Counts</option>
          <!-- NOTE: other appeared as an option in project links for both parents and children -->
          <!--<option value="Other">Other</option>-->
        </select>
      </div>
      <label class="col-md-2 label-padding" [for]="'url_node_' + currentNode.id">Data URL</label>
      <div class="col-md-3">
        <input
          type="text"
          [(ngModel)]="currentNode.url"
          class="form-control"
          maxlength="500"
          [id]="'url_node_' + currentNode.id"
        />
      </div>
      <div class="col-md-2">
        <div *ngIf="!filesUploaded()">
          <b>OR</b>&nbsp;&nbsp;
          <button
            *ngIf="!showFileUpload"
            [disabled]="uploadButtonDisabled"
            (click)="uploadClick()"
            class="btn btn-success"
          >
            Upload <span class="glyphicon glyphicon-cloud-upload"></span>
          </button>
          <button *ngIf="showFileUpload" (click)="showFileUpload = false" class="btn btn-default">
            Hide Upload <span class="glyphicon glyphicon-cloud-upload"></span>
          </button>
        </div>
      </div>
    </div>
    <!-- process performer and date -->
    <div *ngIf="showExtraFields && currentNode.isa_type === 'Process'" class="row my-form-group whitesmoke">
      <label class="col-md-2 label-padding" [for]="'performer_node_' + currentNode.id">Performer</label>
      <div class="col-md-4">
        <input
          type="text"
          [(ngModel)]="currentNode.performer"
          class="form-control"
          maxlength="100"
          [id]="'performer_node_' + currentNode.id"
        />
      </div>
      <label class="col-md-2 label-padding" [for]="'date_node_' + currentNode.id">Date</label>
      <div class="col-md-4">
        <input
          type="text"
          [(ngModel)]="currentNode.date"
          class="form-control"
          maxlength="50"
          [id]="'date_node_' + currentNode.id"
        />
      </div>
    </div>
    <!-- material type -->
    <div *ngIf="showExtraFields && currentNode.isa_type === 'Material'" class="row my-form-group whitesmoke">
      <label class="col-md-2 label-padding" [for]="'mat_datatype_node_' + currentNode.id">Material Type</label>
      <div class="col-md-4">
        <input
          type="text"
          [(ngModel)]="currentNode.datatype"
          class="form-control"
          maxlength="100"
          [id]="'mat_datatype_node_' + currentNode.id"
        />
      </div>
      <div class="col-md-6"></div>
    </div>
    <!--material characteristics-->
    <div
      *ngIf="showExtraFields && currentNode.isa_type === 'Material'"
      class="row my-form-group whitesmoke bottom-radius"
    >
      <label class="col-md-2 label-padding">Characteristics</label>
      <div class="col-md-4 label-padding">
        <span *ngFor="let characteristic of currentNode.characteristics; let i = index"
          >{{ characteristic }}
          <span
            style="color: red"
            class="glyphicon glyphicon-remove"
            matTooltip="Remove Characteristic"
            (click)="currentNode.characteristics.splice(i, 1)"
          ></span>
          &nbsp;&nbsp;
        </span>
      </div>
      <label class="col-md-2 label-padding">Add Characteristic</label>
      <div class="col-md-3">
        <input type="text" [(ngModel)]="characteristicInput" class="form-control" maxlength="100" />
      </div>
      <div class="col-md-1 label-padding">
        <span
          *ngIf="canEdit"
          style="color: green; cursor: pointer"
          class="glyphicon glyphicon-plus"
          matTooltip="Add Characteristic"
          (click)="addCharacteristic()"
        ></span>
      </div>
    </div>
    <!--process parameters-->
    <div
      *ngIf="showExtraFields && currentNode.isa_type === 'Process'"
      class="row my-form-group whitesmoke bottom-radius"
    >
      <label class="col-md-2 label-padding">Parameters</label>
      <div class="col-md-4 label-padding">
        <span *ngFor="let parameter of currentNode.parameters; let i = index"
          >{{ parameter }}
          <span
            style="color: red"
            class="glyphicon glyphicon-remove"
            matTooltip="Remove Characteristic"
            (click)="currentNode.parameters.splice(i, 1)"
          ></span>
          &nbsp;&nbsp;
        </span>
      </div>
      <label class="col-md-2 label-padding">Add Parameter</label>
      <div class="col-md-3">
        <input type="text" [(ngModel)]="parameterInput" class="form-control" maxlength="100" />
      </div>
      <div class="col-md-1 label-padding">
        <span
          *ngIf="canEdit"
          style="color: green; cursor: pointer"
          class="glyphicon glyphicon-plus"
          matTooltip="Add Parameter"
          (click)="addParameter()"
        ></span>
      </div>
    </div>
  </fieldset>

  <hr />
</div>

<!-- file upload for isa data nodes...
     doesn't show by default unless the node already has files uploaded-->
<div [style.display]="fileUploadDisplay()">
  <file-upload
    [allowEdit]="canEdit"
    [pointertype]="'projid'"
    [title]="'Node Data Files'"
    [pointer]="projectId"
    [tag]="'isa_node_' + (currentNode ? currentNode.id : '')"
    [multiple]="true"
    (fileUploaded)="updateCurrentNodeNumFiles()"
    (fileRemoved)="updateCurrentNodeNumFiles()"
  ></file-upload>
  <hr />
</div>

<!-- Add Node Button-->
<div *ngIf="canEdit" class="row">
  <div class="col-md-1 action-icon-add-container">
    <button (click)="addNode()" type="button" class="btn btn-success" matTooltip="Add Node">
      <span class="glyphicon glyphicon-plus clear-icon"></span>
    </button>
  </div>
  <div class="col-md-11">
    <span class="button-message"> Use this button to add Nodes to the project </span>
    <span *ngIf="projectIsaData?.length" class="button-message">
      OR click on a Material, Process, Data, or Unspecified Type node below to view or modify its details.
    </span>
  </div>
</div>
<div *ngIf="!canEdit" class="row">
  <div class="col-md-12">
    <span *ngIf="projectIsaData?.length" class="button-message">
      Click on a Material, Process, Data, or Unspecified Type node below to view its details.
    </span>
    <span *ngIf="!projectIsaData?.length" class="button-message">
      There is currently no data nodes for this project.
    </span>
  </div>
</div>

<!-- Network Graph -->
<div id="project-isa-nodes-graph"></div>
