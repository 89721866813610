import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DivdbDataset, DivdbDatasetStatus, DivDBService } from '../../../services/divdb.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../../shared/dialogs/confirmation-dialog.component';
import { DatasetStatus } from './dataset-status';
import { startWith, switchMap, takeWhile, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'dataset-details',
  templateUrl: './dataset-details.component.html',
  styleUrls: ['./dataset-details.component.scss'],
})
export class DatasetDetailsComponent implements OnInit, OnDestroy {
  // true if the current user should be able to edit fields
  @Input() editable = false;

  // current DivDB dataset, if one has been created
  @Input() dataset?: DivdbDataset;

  // current SIP project
  @Input() project: any;

  userTimezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // keeps track of current status and status history for the current dataset
  status!: DatasetStatus;

  // url for the associated version of the DivDB UI which will list datasets and studies
  wookie = environment.unsecuredURLs.wookie;

  // name of the dataset
  datasetName = '';

  // description for the dataset
  description = '';

  // SIP ID for the dataset's investigator
  investigatorID = -1;

  // list of SIP investigators listed for the current project
  investigators: any[] = [];

  // SIP ID for the dataset's secondary contact person
  secondaryContactID = -1;

  // list of SIP users/investigators that can edit the project
  secondaryContacts: any[] = [];

  pollingInterval: Subscription | null = null;

  // emits if a process errors and the user clicks the 'retry processing' button
  @Output() retry: EventEmitter<null> = new EventEmitter<null>();

  // emits when user saves the new dataset details
  @Output() save: EventEmitter<DatasetDetails> = new EventEmitter<DatasetDetails>();

  // emits when user saves the new dataset details
  @Output() delete: EventEmitter<null> = new EventEmitter<null>();

  // emits when user cancels processing
  @Output() cancel: EventEmitter<null> = new EventEmitter<null>();

  constructor(private divdb: DivDBService, private cdr: ChangeDetectorRef, private dialog: MatDialog) {}

  ngOnInit(): void {
    if (this.dataset) {
      this.datasetName = this.dataset.name;
      this.description = this.dataset.description;
      this.investigatorID = this.dataset.investigator_id;
      this.secondaryContactID = this.dataset.contact2_id || -1;

      // store the dataset status information
      this.updateStatus(this.dataset.status);

      this.getStatus();

      this.cdr.detectChanges();
    }

    this.investigators = [].concat(this.project.correspondingpi, ...this.project.otherpis);

    if (!this.datasetID) {
      // only disable investigators based on first and last name if the dataset hasn't been
      // created since the select is readonly after creation
      this.investigators.forEach((i) => {
        i.disabled = !i.firstname || !i.lastname;
      });

      // set the default investigator to the first valid investigator if there's at least one
      const validInvestigators = this.investigators.filter((i) => !i.disabled);
      this.investigatorID = validInvestigators.length ? validInvestigators[0].id : -1;
    }

    // set the list of options for secondary contact to all those who own or can edit the project
    this.secondaryContacts = this.project.userpermissions.filter(
      (u: any) => u.permission !== 'View' && u.permission !== 'None',
    );

    this.secondaryContacts.forEach((i) => {
      i.disabled = !i.firstname || !i.lastname;
    });

    // setting these variables are going to change what's rendered, so let the template stabilize
    this.cdr.detectChanges();
  }

  // if user navigates away from the genotype intake, we don't want to keep polling in the background
  ngOnDestroy() {
    if (this.pollingInterval) {
      this.pollingInterval.unsubscribe();
    }
  }

  /**
   * Returns the dataset id of the current dataset or null if there isn't a current dataset
   */
  get datasetID(): number {
    return this.dataset ? this.dataset.dataset_id : 0;
  }

  /**
   * Returns true if at least one investigator is marked as disabled
   */
  get hasInvalidInvestigators(): boolean {
    return this.investigators.some((i) => i.disabled) || this.secondaryContacts.some((i) => i.disabled);
  }

  /**
   * Creates a new DatasetStatus from the specified list of status objects
   * @param statuses - set of dataset status objects to use to create the DatasetStatus class
   */
  updateStatus(statuses: DivdbDatasetStatus[]) {
    this.status = new DatasetStatus(statuses);
    this.cdr.detectChanges();
  }

  /**
   * Polls for status updates on the current dataset
   */
  getStatus(): void {
    // disconnect any previous polling
    if (this.pollingInterval) {
      this.pollingInterval.unsubscribe();
    }

    // continue to poll every 10 seconds until all process statuses are complete (takeWhile's
    // second argument needs to be set to 'true' so that when the statuses are complete it
    // emits; the default is 'false which won't emit the final time when the takeWhile()
    // returns true)
    this.pollingInterval = interval(10000)
      .pipe(
        startWith(0),
        switchMap(() => this.divdb.getProcessingStatus(this.datasetID)),
        takeWhile(() => true, true), // TODO: for now, poll always until I can track down why polling sometimes stops
        retry(5), // if an HTTP error is encountered try a maximum of 5 times before finally failing
      )
      .subscribe(
        (statuses) => {
          this.updateStatus(statuses);
        },
        (error) => {
          console.log(error);
          this.updateStatus([
            {
              status: 'DISCONNECTED',
              run_id: '',
              messages: [],
              platform: '',
              start_time: '',
              type: 'ALL',
              start_time_date: new Date(),
            },
          ]);
          this.status.disconnected = true;

          // disconnect from polling
          if (this.pollingInterval) {
            this.pollingInterval.unsubscribe();
          }
        },
      );
  }

  /**
   * Toggles the dataset name value to that of the current project's title if the checkbox has been
   * selected or the previous value if the checkbox is deselected
   * @param checkbox - checkbox input object for using the project name
   */
  toggleProjectName(checkbox: any): void {
    // if the dataset is getting updated, toggling away from inheriting name should give them
    // the current dataset name
    const otherToggle = this.dataset ? this.dataset.name : '';
    this.datasetName = checkbox.checked ? this.project.title : otherToggle;
  }

  /**
   * Toggles the dataset description value to that of the current project's description if the
   * checkbox has been selected or the previous value if the checkbox is deselected
   * @param checkbox - checkbox input object for using the project description
   */
  toggleProjectDesc(checkbox: any): void {
    // if the dataset is getting updated, toggling away from inheriting description should
    // give them the current dataset description
    const otherToggle = this.dataset ? this.dataset.description : '';
    this.description = checkbox.checked ? this.project.description : otherToggle;
  }

  /**
   * Shows a dialog box with status history for the dataset
   */
  showSubmissionHistory(): void {
    let history = '';

    if (this.status.processingHistory.length) {
      history += '<h2>Processing</h2>';
    }
    this.status.processingHistory.forEach((attempt, i) => {
      const isLatestAttempt = i === this.status.processingHistory.length - 1;

      if (!isLatestAttempt) {
        attempt.forEach((s) => {
          const type = s.type.replace(/_/g, ' ');
          const stat = s.status.replace(/_/g, ' ');
          const startTime = this.getDateTimestamp(s.start_time_date);
          const platform = s.platform || 'unknown platform';

          history += `<span style="color: #777"><b>${type} (${platform})</b> - ${stat} (${startTime})</span><br/>`;
        });
        history += '<hr>';
      } else {
        attempt.forEach((s) => {
          const type = s.type.replace(/_/g, ' ');
          const stat = s.status.replace(/_/g, ' ');
          const startTime = this.getDateTimestamp(s.start_time_date);
          const platform = s.platform || 'unknown platform';

          history += `<b>${type} (${platform})</b> - ${stat} (${startTime})<br/>`;
        });
      }
    });

    if (this.status.haplotypeReconstructionHistory.length) {
      history += '<br /><br /><h2>Haplotype Reconstruction</h2>';
    }
    this.status.haplotypeReconstructionHistory.forEach((attempt, i) => {
      const isLatestAttempt = i === this.status.haplotypeReconstructionHistory.length - 1;

      if (!isLatestAttempt) {
        attempt.forEach((s) => {
          const type = s.type.replace(/_/g, ' ');
          const stat = s.status.replace(/_/g, ' ');
          const startTime = this.getDateTimestamp(s.start_time_date);
          const platform = s.platform || 'unknown platform';

          history += `<span style="color: #777"><b>${type} (${platform})</b> - ${stat} (${startTime})</span><br/>`;
        });
        history += '<hr>';
      } else {
        attempt.forEach((s) => {
          const type = s.type.replace(/_/g, ' ');
          const stat = s.status.replace(/_/g, ' ');
          const startTime = this.getDateTimestamp(s.start_time_date);
          const platform = s.platform || 'unknown platform';

          history += `<b>${type} (${platform})</b> - ${stat} (${startTime})<br/>`;
        });
      }
    });

    const data = {
      header: 'Submission History for Dataset ' + this.dataset?.symbol,
      message: history,
      truelabel: 'Okay',
      hidefalsebtn: true,
    };
    const dialog = this.dialog.open(ConfirmationDialogComponent, { data });
    dialog.afterClosed().subscribe();
  }

  /**
   * Emits the entered dataset details to save or update
   */
  saveDetails(): void {
    this.save.emit({
      name: this.datasetName,
      description: this.description,
      investigatorID: this.investigatorID,
      secondaryContactID: this.secondaryContactID,
    });
  }

  /**
   * Opens a confirmation dialog to ensure that the user wishes to delete the dataset.
   * If the user confirms the deletion, the delete event is emitted
   */
  deleteDataset(): void {
    const data = {
      header: 'Confirm Dataset Deletion',
      message:
        'By continuing to delete this dataset you will be removing the dataset ' +
        'in Diversity DB along with all files that may have been uploaded or ' +
        'generated as a result of this dataset. <b>This action cannot be undone.</b>',
      hidefalsebtn: false,
      truelabel: 'Yes, I want to delete this dataset',
      falselabel: 'Cancel',
      truebtn: 'btn-danger',
    };
    const confirmDialog = this.dialog.open(ConfirmationDialogComponent, { data });
    confirmDialog.afterClosed().subscribe((confirm) => {
      if (confirm === true) {
        this.resetDetails();
        this.delete.emit();
      }
    });
  }

  /**
   * Shows the dialog box with processing details for the latest processing attempt
   */
  showProcessingReport(): void {
    let report = '';

    this.status.currentProcessAttempt.forEach((s) => {
      const type = s.type.replace(/_/g, ' ');
      const stat = s.status.replace(/_/g, ' ');
      const startTime = this.getDateTimestamp(s.start_time_date);
      const platform = s.platform || 'unknown platform';

      if (stat === 'COMPLETE') {
        report += `<span style="color: #0c0"><b>${type} (${platform})</b> - ${stat} (${startTime})<br/>`;

        if (s.report) {
          const loaded = s.report.loaded;
          const toLoad = s.report.to_load;

          report +=
            `&ensp;Loaded ${loaded} ${loaded === 1 ? 'sample' : 'samples'} of ${toLoad.samples} ` +
            `total across ${toLoad.arrays} ${toLoad.arrays === 1 ? 'array' : 'arrays'}, ${toLoad.skipped} ` +
            `${toLoad.skipped === 1 ? 'sample' : 'samples'} skipped<br/>`;
        }

        report += '</span><br/>';
      } else if (this.status.taskErrored(s)) {
        report += `<span style="color: #c00"><b>${type} (${platform})</b> - ${stat} (${startTime})<br/>`;

        if (s.messages) {
          s.messages
            .filter((m) => m.stderr)
            .forEach((m) => {
              // filter out lines that are really really long, like potentially super long lists of
              // IDs or "lines" of empty strings
              let errorMsg = m.stderr.split('Error')[1];

              if (errorMsg) {
                errorMsg.split('\n').filter((m: string) => m.length && m.length < 1000);

                if (typeof errorMsg === 'string') {
                  errorMsg = [errorMsg];
                }
              } else {
                errorMsg = [m.stderr];
              }

              report +=
                '&ensp;Stacktrace:<br />' +
                `<span style="color: #c00">&ensp;Error ${errorMsg.join('<br />&ensp;&ensp;')}</span><br/>`;
            });
        } else {
          report += '&ensp;This task ran into an error but no error messages were provided<br/>';
        }

        report += '</span><br/>';
      } else if (stat === 'CANCELED') {
        report += `<span style="color: #777"><b>${type} (${platform})</b> - ${stat} (${startTime})</span><br/><br/>`;
      }
    });

    const data = {
      header: `Processing Report for ${this.dataset?.symbol} - ${this.status.statusDisplay}`,
      message: report,
      truelabel: 'Okay',
      hidefalsebtn: true,
    };
    const dialog = this.dialog.open(ConfirmationDialogComponent, { data });
    dialog.afterClosed().subscribe();
  }

  /**
   * Shows a dialog box with the output for all the haplotype reconstruction pipelines run for the dataset
   */
  showHaplotypeReconstructionReport(): void {
    let report = '';

    this.status.haplotypeReconstructionHistory.forEach((attempt, i, arr) => {
      attempt.forEach((s) => {
        const type = s.type.replace(/_/g, ' ');
        let stat = s.status.replace(/_/g, ' ');

        if (this.status.taskErrored(s)) {
          stat = `&emsp;Status: <span style="color: #c00">${stat}</span><br />`;
        } else if (this.status.isRunning(s)) {
          stat = `&emsp;Status: <span style="color: #0277bd">${stat}</span><br />`;
        } else if (s.status === 'COMPLETE') {
          stat = `&emsp;Status: <span style="color: #0c0">${stat}</span><br />`;
        } else {
          stat = `&emsp;Status: <span style="color: #999">${stat}</span><br />`;
        }

        report += `<b>${type} (${s.platform})</b><br />` +
          `&emsp;Start Time: ${this.getDateTimestamp(s.start_time_date)}<br />` +
          stat;

        const validMessages = s.messages.filter((m) => m.stderr);

        if (this.status.taskErrored(s) && validMessages.length) {
          // TODO: this is NOT good a robust way of grabbing relevant information but it's the only way I've found
          //  works to trim the fat from these sometimes really long and/or encoded messages
          validMessages.forEach(msg => {
            if (msg.stderr) {
              if (msg.stderr.includes('Error')) {
                const errorMsg = msg.stderr
                  .split('Error')[1]
                  .split('\n')
                  .filter((m: string) => m.length && m.length < 1000);

                report +=
                  '&emsp;Stacktrace:<br />' +
                  `<span style="color: #c00">&emsp;&emsp;Error ${errorMsg.join('<br />&emsp;&emsp;')}</span>`;
              } else {
                let errorMsg = msg.stderr.slice(0, -1).replace("b'", '').trim();

                if (errorMsg.endsWith('n')) {
                  errorMsg = errorMsg.slice(0, -2);
                }

                report +=
                  '&emsp;Stacktrace:<br />' +
                  `<span style="color: #c00">&emsp;&emsp;${errorMsg}</span>`;
              }
            }
          })
        } else if (this.status.taskErrored(s) && !validMessages.length) {
          report += '<span style="color: #c00">&emsp;&emsp;Sorry, no stacktrace is available for this error</span>';
        }
      });

      if (i < arr.length - 1) {
        report += '<hr>';
      }
    });

    const data = {
      header: 'Haplotype Reconstruction History for Dataset ' + this.dataset?.symbol,
      message: report,
      truelabel: 'Okay',
      hidefalsebtn: true,
    };
    const dialog = this.dialog.open(ConfirmationDialogComponent, { data });
    dialog.afterClosed().subscribe();
  }

  /**
   * Reverts dataset details back to their default values
   */
  resetDetails(): void {
    this.datasetName = '';
    this.description = '';
    this.investigatorID = this.project.corrpi;
    this.secondaryContactID = -1;
  }

  /**
   * Returns true if any of the four variable values have been changed
   */
  get detailsChanged(): boolean {
    if (this.dataset) {
      // the dataset stores a nonexistent secondary contact as null but this causes
      // problems in selects so it's handled as -1 in the template
      const datasetSecondaryContact = this.dataset.contact2_id || -1;

      return (
        this.datasetName !== this.dataset.name ||
        this.description !== this.dataset.description ||
        Number(this.investigatorID) !== this.dataset.investigator_id ||
        Number(this.secondaryContactID) !== datasetSecondaryContact
      );
    }

    return !!(this.datasetName && this.description && this.investigatorID && this.secondaryContactID !== -1);
  }

  get datasetCreated(): boolean {
    if (this.dataset && this.status) {
      return Boolean(this.status.currentProcessAttempt.length || !this.status.submitted);
    }

    return false;
  }

  private getDateTimestamp(statusDateTime: Date): string {
    return `${statusDateTime.toLocaleString('en-US', { timeZone: this.userTimezone, timeZoneName: 'short' })}` || 'unknown time';
  }
}

export interface DatasetDetails {
  name: string;
  description: string;
  investigatorID: number;
  secondaryContactID: number;
}
