import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AnimalInfoPostFailure } from '../../../services/divdb.service';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { FileTypeValidator } from '../validators/file-format.directive';
import { ConfirmationDialogComponent } from '../../../shared/dialogs/confirmation-dialog.component';

@Component({
  selector: 'animal-info-upload',
  templateUrl: './animal-info-upload.component.html',
  styleUrls: ['./animal-info-upload.component.scss'],
})
export class AnimalInfoUploadComponent {
  // true if the current user should be able to add new animal info
  @Input() editable = false;

  // form control for the animal information file
  animalInfo = new FormGroup({
    file: new FormControl('', [FileTypeValidator(['csv', 'tsv', 'txt'])]),
  });

  // error message for the file validation or encountered file parsing issues
  fileImportErrorMsg = '';

  // error sourced from getting data from an API
  apiErrorMsg = '';

  // columns to be displayed on the new animal info table
  displayedColumns = ['id', 'dob', 'sex', 'doGen', 'delete'];

  // data source for the new animal info that can be edited before saving
  newAnimals?: MatTableDataSource<UnsavedAnimal> | null;

  // data source for the saved animal info that is not editable
  savedAnimals: MatTableDataSource<SavedAnimal> = new MatTableDataSource<SavedAnimal>();

  // true if animal info is uploading or user should not be able to fire an upload event
  disableSave = false;

  // warning displayed for DOB that if they're entered incompletely they may be saved incorrectly
  dobWarning = 'Incomplete dates may be interpreted incorrectly. Please ensure DOBs have month, day, and year values';

  // list of current duplicate IDs for new animals table
  duplicateIDs: string[] = [];

  // upload event that gets triggered when the user is ready to submit animal info to DivDB
  @Output() upload: EventEmitter<any> = new EventEmitter<any>();

  // remove event that gets triggered when the user wants to remove an animal from saved animals
  @Output() removeSaved: EventEmitter<SavedAnimal[]> = new EventEmitter<SavedAnimal[]>();

  constructor(private dialog: MatDialog, private cdr: ChangeDetectorRef) {}

  /**
   * Returns the animal IDs for all animals with missing information
   */
  get incompleteAnimals(): string[] {
    return this.newAnimals
      ? this.newAnimals.data
          .filter((a) => this.isAnimalIncomplete(a))
          .map((a) => a.animal_id || `Table Row ${a.id ? a.id + 1 : 1} (No animal ID available)`)
      : [];
  }

  /**
   * Returns the number of animals with missing information
   */
  get numIncompleteAnimals(): number {
    return this.incompleteAnimals.length;
  }

  /**
   * Returns the stringified list of duplicated IDs for the warning tooltip
   */
  get duplicateIDsString(): string {
    return this.duplicateIDs.join(', ');
  }

  /**
   * Returns true if there are values in the badDates array (generated by 422 error from the API) and the ID of the
   * @param animal - animal in question to evaluate the date from
   */
  hasBadDate(animal: UnsavedAnimal): boolean {
    return animal.dob !== null && animal.dob.trim() !== '' ? new Date(animal.dob).toString() === 'Invalid Date' : false;
  }

  /**
   * Adds a blank animal to the new animals table for the user to manually enter data for
   */
  addAnimal(): void {
    const newAnimal = {
      animal_id: '',
      sex: 'NA',
      do_generation: null,
      dob: null,
      id: 0, // id is properly assigned in this.setData()
    };

    // load animal info into the table
    if (this.newAnimals) {
      this.setData(this.newAnimals.data.concat(newAnimal));
    } else {
      this.setData([newAnimal]);
    }

    this.validateNewAnimalIDs();
    this.scrollTable();
  }

  /**
   * Shows a dialog box containing information about the number of animals missing information,
   * which animals are missing fields, and potentially how to resolve large swaths of missing data
   */
  showMissingAnimalInfoDetails(): void {
    const incomplete = `${this.numIncompleteAnimals} ${
      this.numIncompleteAnimals === 1 ? 'animal has' : 'animals have'
    }`;
    const data = {
      header: 'Missing Animal Information Fields',
      message:
        `${incomplete} missing information. While animal date of birth is optional, ` +
        'please make sure for parsing purposes that it has representation in the header.<br><br>' +
        'If you know that missing data is contained in the file you selected, the field ' +
        'names in the header may have not been recognized by the parsing process. If there ' +
        'are large batches of missing fields, please check that the required four fields are ' +
        'stored under any of the following header strings:<br><br>' +
        '<ul>' +
        '<li><b>Animal ID (required):</b> mouse, mouse_id, mouse id, animal, animal_id, animal id</li>' +
        '<li><b>Date of birth:</b> dob, birthdate, date_of_birth, date of birth</li>' +
        '<li><b>Sex (required):</b> sex</li>' +
        '<li><b>DO generation (required):</b> dogen, do_gen, do gen, do_generation, do generation</li>' +
        '</ul><br>' +
        'The following animals have missing information:<br>' +
        `<b>${this.incompleteAnimals.join(', ')}</b>`,
      truelabel: 'Okay',
      hidefalsebtn: true,
    };
    const dialog = this.dialog.open(ConfirmationDialogComponent, { data });
    dialog.afterClosed().subscribe();
  }

  /**
   * Shows a dialog box containing information about how the file parser looks for data fields
   * for each animal
   */
  showMissingFieldDetails(): void {
    const data = {
      header: 'Required Fields',
      message:
        'Please check that the requested four fields are stored under any of the ' +
        'following header strings:<br><br>' +
        '<ul>' +
        '<li><b>Animal ID:</b> mouse, mouse_id, mouse id, animal, animal_id, animal id</li>' +
        '<li><b>Date of birth:</b> dob, birthdate, date_of_birth, date of birth</li>' +
        '<li><b>Sex:</b> sex</li>' +
        '<li><b>DO generation:</b> dogen, do_gen, do gen, do_generation, do generation</li>' +
        '</ul> ' +
        'Even though animal date of birth is optional, for parsing purposes, please ensure ' +
        'there is a header value to represent it.',
      truelabel: 'Okay',
      hidefalsebtn: true,
    };
    const dialog = this.dialog.open(ConfirmationDialogComponent, { data });
    dialog.afterClosed().subscribe();
  }

  /**
   * Filters the specified table by the filter string value
   * @param table - table to filter (SavedAnimal | UnsavedAnimal, not type any)
   * @param filterValue - string filtering the table by
   */
  applyFilter(table: MatTableDataSource<any>, filterValue: string): void {
    table.filter = filterValue.trim().toLowerCase();
  }

  /**
   * Clears the filtering value from the specified input and table
   * @param table - table the filter is for
   * @param filter - filter element to clear
   */
  clearFilter(table: MatTableDataSource<SavedAnimal | UnsavedAnimal>, filter: HTMLInputElement) {
    filter.value = '';
    table.filter = '';
  }

  /**
   * Parses the needed data points from the uploaded file into dictionaries/objects,
   * which are then rendered in a table in the template
   * @param event - event resulting from the change of file input
   */
  readFile(event: any): void {
    const newFile = event.target.files[0];

    if (!newFile) {
      return;
    }

    this.animalInfo.get('file')?.setValue(newFile.name);
    this.displayedColumns = ['id', 'dob', 'sex', 'doGen', 'delete'];

    // check that the file extension matches one that we're ready to handle
    if (!this.animalInfo.get('file')?.hasError('invalidType')) {
      this.fileImportErrorMsg = '';

      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const animals: UnsavedAnimal[] = [];

        // do some routine cleaning like removing trailing newlines and getting rid of extra quotes
        // since those just add to clutter in this case
        const lines = (<string>e.target?.result).replace(/\n+$/, '').replace(/["]+/g, '').split('\n');

        // check for file content
        if (lines.length === 1 && !lines[0]) {
          this.fileImportErrorMsg = 'The file appears to be empty';
          return;
        }

        let commaSep = true;
        let headers = lines[0]
          .trim()
          .toLowerCase()
          .split(',')
          .map((h) => h.trim());

        // if the values don't appear to be comma-separated, try tabs
        if (headers.length === 1) {
          headers = lines[0].trim().toLowerCase().split('\t');

          commaSep = false;

          // if the values don't appear to be tab-separated either, throw error about formatting
          if (headers.length === 1) {
            this.fileImportErrorMsg = 'Unable to parse header; make sure the file is either comma- or tab-separated';
            return;
          }
        }

        // get the index of the needed headers based on a series of keywords to check for (in order of preference)
        const animIDIdx = this.getIndex(headers, ['mouse', 'mouse_id', 'mouse id', 'animal', 'animal_id', 'animal id']);
        const doGenIdx = this.getIndex(headers, ['dogen', 'do_gen', 'do gen', 'do_generation', 'do generation']);
        const sexIdx = this.getIndex(headers, ['sex']);
        const dobIdx = this.getIndex(headers, ['dob', 'birthdate', 'date_of_birth', 'date of birth']);

        // if any of the field indices are -1 (not found), point user to more info where
        // they can see what header values are being searched for
        if ([animIDIdx, doGenIdx, sexIdx].indexOf(-1) >= 0) {
          this.fileImportErrorMsg = 'Unable to locate fields: ';

          const missing = [];
          if (animIDIdx < 0) {
            missing.push('animal ID');
          }

          if (doGenIdx < 0) {
            missing.push('DO generation');
          }

          if (sexIdx < 0) {
            missing.push('sex');
          }

          this.fileImportErrorMsg += missing.join(', ');
          return;
        }

        // parse out the desired animal info, create dicts from them and add them to the list
        lines.splice(1).forEach((line) => {
          const data = line.split(commaSep ? ',' : '\t').map((d) => d.trim());

          animals.push({
            animal_id: this.valueExists(data[animIDIdx]) ? data[animIDIdx] : '',
            sex: this.valueExists(data[sexIdx]) ? this.getCorrectSexValue(data[sexIdx]) : 'NA',
            do_generation: this.valueExists(data[doGenIdx]) ? this.getCorrectDOGenValue(data[doGenIdx]) : null,
            dob: this.valueExists(data[dobIdx]) ? data[dobIdx] : '',
            id: 0, // id get's properly assigned in this.setDate()
          });
        });

        // load animal info into the table
        if (this.newAnimals) {
          this.setData(this.newAnimals.data.concat(...animals));
        } else {
          this.setData(animals);
        }

        this.validateNewAnimalIDs();
        this.scrollTable();
      };

      // read the input file
      fileReader.readAsText(newFile);

      // reset the input value so that change event fires if they select the same file again
      event.target.value = '';
    } else {
      // inform the user about accepted file extensions
      this.fileImportErrorMsg = 'A file containing animal information should have a .csv, .tsv, or .txt file extension';
    }
  }

  /**
   * Checks for any new animal ID conflicts and if any are found, update the table with an error column and
   * flag animals that have the naming conflicts (and remove any animals that may have had animal ID
   * conflicts resolved) TODO: this could use some performance enhancements
   */
  validateNewAnimalIDs(): void {
    this.newAnimals?.data.forEach((a) => (a.error = ''));

    const animalsWithIDs = this.newAnimals?.data.filter((a) => a.animal_id !== '') || [];
    const uniqueIDs = new Set(animalsWithIDs.map((a) => a.animal_id));

    if (uniqueIDs.size < animalsWithIDs.length) {
      const newIDs: string[] = [];
      const duplicatedIDs: Set<string> = new Set<string>();

      animalsWithIDs.forEach((animal) => {
        if (newIDs.indexOf(animal.animal_id) < 0) {
          newIDs.push(animal.animal_id);
        } else {
          this.newAnimals?.data
            .filter((a) => a.animal_id === animal.animal_id)
            .forEach((a) => {
              a.error = 'Duplicate Animal ID';
              duplicatedIDs.add(a.animal_id);
            });
        }
      });

      this.duplicateIDs = [...duplicatedIDs];
      this.displayedColumns = ['id', 'dob', 'sex', 'doGen', 'delete', 'error'];
      this.disableSave = true;
    } else {
      this.duplicateIDs = [];
      this.displayedColumns = ['id', 'dob', 'sex', 'doGen', 'delete'];
      this.disableSave = false;
    }

    // get the table to recognize the change to the data
    this.newAnimals?._updateChangeSubscription();
  }

  /**
   * Returns true if one of the necessary animal information fields is missing
   * @param animal - animal dictionary to check for missing values
   */
  isAnimalIncomplete(animal: UnsavedAnimal): boolean {
    if (!this.isSexValid(animal.sex) || !this.isDOGenValid(animal.do_generation)) {
      return true;
    }

    if (animal.dob !== null && animal.dob.trim() !== '') {
      return this.hasBadDate(animal);
    }

    return !animal.animal_id;
  }

  /**
   * Returns true if the specified sex is a valid sex type
   * @param sex - sex value
   */
  isSexValid(sex: string | null): boolean {
    return sex ? ['M', 'F', 'XO', 'NA'].indexOf(sex.toUpperCase()) >= 0 : false;
  }

  /**
   * Returns true if the specified DO generation is valid
   * @param gen - generation value
   */
  isDOGenValid(gen: number | null): boolean {
    return !!(gen && gen > 0);
  }

  /**
   * Removes any unsaved animals and clears out the file displayed in the file input
   * @param fileInput - input element to clear while clearing the table
   */
  clearNewAnimalTable(fileInput: HTMLInputElement): void {
    fileInput.value = '';
    this.newAnimals = null;
    this.displayedColumns = ['id', 'dob', 'sex', 'doGen', 'delete'];
    this.animalInfo.get('file')?.setValue('');
    this.disableSave = false;
  }

  /**
   * Removes all saved animals
   * Emit removeSaved for all saved animals so that DivDB can delete them
   * and set the collection to null to clear them from the grid
   */
  clearSavedAnimalTable(): void {
    this.removeSaved.emit(this.savedAnimals.data);
    this.savedAnimals = new MatTableDataSource<SavedAnimal>([]);
  }

  /**
   * Loads the list of specified animals that failed to save in DivDB into the list of unsaved animals (and
   * clear the table of all the successfully-saved animals) and adding a column to the table to show save
   * errors for each animal
   * @param failures - list of animals that failed to save in DivDB
   */
  showSaveFailures(failures: AnimalInfoPostFailure[]) {
    this.displayedColumns = ['id', 'dob', 'sex', 'doGen', 'delete', 'error'];
    failures = failures.sort((f1, f2) => f1.animal.animal_id.localeCompare(f2.animal.animal_id));

    this.setData(failures.map((f) => ({ ...f.animal, error: f.message })));
    this.disableSave = false;
  }

  /**
   * Loads the list of specified animals into the table containing animals that have been loaded into DivDB
   * @param animals - list of animals to load into the table of saved animals
   */
  saveAnimals(animals: UnsavedAnimal[]) {
    if (animals.length) {
      // Sort animals by animal_id before setting table data source
      animals = animals.sort((a, b) => a.animal_id.localeCompare(b.animal_id));

      this.setData(animals, true);
      this.disableSave = false;
    }
  }

  /**
   * Removes the specified animal from the respective table (specified by the saved boolean)
   * and if the animal is from the saved table, emit the removed animal as it will need to
   * be passed to DivDB for updating
   * @param animal - animal to be removed, of type SavedAnimal or UnsavedAnimal
   * @param saved - indication of whether the animal to be removed is from the saved
   *                          animals table or new animals
   */
  removeAnimal(animal: any, saved = false) {
    if (saved) {
      if (animal.id !== null && animal.id !== undefined) {
        this.savedAnimals.data.splice(animal.id, 1);

        // emit the animal
        this.removeSaved.emit([animal]);

        // reset the ids of the animals
        this.savedAnimals.data.forEach((a, idx) => (a.id = idx));
      }
    } else {
      if (this.newAnimals && animal.id !== null && animal.id !== undefined) {
        this.newAnimals.data.splice(animal.id, 1);

        // reset the ids of the animals
        this.newAnimals.data.forEach((a, idx) => (a.id = idx));

        // if there are no animals left, remove the table
        if (!this.newAnimals.data.length) {
          this.newAnimals = null;
        }
      }
    }

    // clear any possible resolved duplicate animal errors and update the table
    this.validateNewAnimalIDs();
  }

  /**
   * Returns a guaranteed lowercase sex value and an additionally converted value if
   * passed values were 'male' or 'female'
   * @param sexValue - raw sex value from the file
   * @private
   */
  private getCorrectSexValue(sexValue: string): string {
    let value = sexValue.toUpperCase();

    if (sexValue === 'male') {
      value = 'M';
    } else if (sexValue === 'female') {
      value = 'F';
    }

    // if sex value doesn't fall into one of the known values, set it to the default catch-all N/A value
    if (['M', 'F', 'XO', 'NA'].indexOf(value) < 0) {
      value = 'NA';
    }

    return value;
  }

  /**
   * Returns a corrected DO generation value where if the value is not a number or 0,
   * label it as null to flag for manual input
   * @param genValue - raw DO generation value from the file
   * @private
   */
  private getCorrectDOGenValue(genValue: string): number | null {
    if (!Number(genValue) || genValue === '0') {
      return null;
    }

    return Number(genValue);
  }

  /**
   * Returns the index of the first key that is found in the list of strings or -1 for no
   * match for any of the keys
   * @param listOfStrings - unordered list of strings (preferably all lowercase)
   * @param keysToLookFor - ordered list of keys to look for matches by preference
   *                        (the first key is the ideal and the last is a last
   *                        ditch effort to find something within range)
   * @private
   */
  private getIndex(listOfStrings: string[], keysToLookFor: string[]): number {
    let idx;
    // search for a match for each of the possible keys
    for (let i = 0; i < keysToLookFor.length; i++) {
      const key = keysToLookFor[i].toLowerCase();
      // if we haven't already found the index, and we found a match with this key, assume this is it
      if (idx === undefined && listOfStrings.indexOf(key.trim()) >= 0) {
        idx = listOfStrings.indexOf(key);
        break;
      }
    }

    return idx === undefined ? -1 : idx;
  }

  /**
   * Returns true if the specified value doesn't match any of the potential values
   * that would equate to a missing value
   * @param value - string to check against potential missing values
   * @private
   */
  private valueExists(value: string): boolean {
    return Boolean(value) && ['na', '-', '--', 'none', 'null'].indexOf(value.toLowerCase()) < 0;
  }

  /**
   * Sets the specified animal data into the proper table and set the filter predicate
   * @param animals - animal data to load into the table of type SavedAnimal or UnsavedAnimal
   * @param saved - flag indicating which table to load (saved = true for loading animals into the saved table)
   * @private
   */
  private setData(animals: any[], saved: boolean = false): void {
    animals.forEach((a, idx) => (a.id = idx));

    if (saved) {
      this.savedAnimals = new MatTableDataSource<SavedAnimal>(animals);
      // Override the default filter so that only animal ids are included
      this.savedAnimals.filterPredicate = (data: any, filter: string): boolean => {
        return data.animal_id.trim().toLowerCase().includes(filter);
      };
    } else {
      this.newAnimals = new MatTableDataSource<UnsavedAnimal>(animals);
      // Override the default filter so that only animal ids are included
      this.newAnimals.filterPredicate = (data: any, filter: string): boolean => {
        return data.animal_id.trim().toLowerCase().includes(filter);
      };
    }
  }

  /**
   * Scrolls the specified new animals table to the bottom of the table
   * @private
   */
  private scrollTable(): void {
    this.cdr.detectChanges();

    // scroll by the height to make sure it's at the bottom. If somehow table doesn't exist
    // (this should never be able to happen), scroll by 10000
    document.querySelector('#new-animals-table')?.scrollBy(0, 35 * (this.newAnimals?.data.length || 1));
  }
}

// defining the structure of data received from the API
/* eslint-disable camelcase */
export interface UnsavedAnimal {
  animal_id: string;
  dob: string | null;
  sex: string;
  do_generation: number | null;
  id: number;
  error?: string;
}

export interface SavedAnimal {
  animal_id: string;
  dob: string | null;
  sex: string;
  do_generation: number | null;
  dataset_id: number;
  id: number;
  unique_id: string;
}
/* eslint-enable camelcase */
