import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'MUGACase',
})
export class MUGACasePipe implements PipeTransform {
  /**
   * Returns the properly-capitalized versions of a genotyping platform
   * @param {string} platformName - genotyping platform string to transform
   */
  transform(platformName: string): string {
    if (platformName.toLowerCase() === 'muga') {
      return 'MUGA';
    } else if (platformName.toLowerCase() === 'megamuga') {
      return 'MegaMUGA';
    } else if (platformName.toLowerCase() === 'gigamuga') {
      return 'GigaMUGA';
    }

    return '';
  }
}
