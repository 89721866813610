import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'restricted-access',
  template: `
    <mat-toolbar>
      <h2>{{ restricted ? 'Access Restricted' : verifyemail ? 'Please Verify your Email' : 'Welcome' }}</h2>
    </mat-toolbar>
    <br />
    <p style="padding-left: 18px;">
      <b *ngIf="!restricted && !verifyemail">
        You must be logged in to upload data to the Study Intake Platform. Please
        <a (click)="auth.login()">sign in</a> or <a (click)="auth.login()">register</a>. <br /><br />
        Alternatively, you can
        <a [queryParams]="{ status: 'Public' }" [routerLink]="['/projects']">browse public projects</a>
        without logging in.
      </b>
      <b *ngIf="restricted">
        Edit access to the Study Intake Platform is currently restricted and your currently logged-in account does not
        have access.
        <br /><br />
        If you have an account with access, please
        <a (click)="auth.login()">sign in</a> to that account. <br /><br />
        Otherwise you will not be able to access the Study Intake Platform at this time. If you would like to be granted
        access please contact molly.bogue@jax.org.
      </b>
      <b *ngIf="verifyemail">
        Please verify your email to access edit rights on Study Intake Platform.<br /><br />
        Email verification instructions are on the <a routerLink="/user-profile">user profile page</a>.
      </b>
      <b *ngIf="errormessage" style="color:red;">
        <br /><br />
        {{ errormessage }}
      </b>
    </p>
  `,
})
export class RestrictedAccessComponent implements OnInit {
  constructor(private router: Router, private route: ActivatedRoute, private auth: AuthService) {}

  // true if there is a logged-in user who doesn't have access,
  // so we are on the 'Restricted' version of this page,
  // false if there is no user and we are viewing the 'Welcome' page
  restricted = false;

  // true if the logged-in user attempted to edit something,
  // but was unable to because they didn't verify their email yet
  verifyemail = false;

  // error message in the url args
  errormessage = '';

  // on init, check whether we are in the restricted version, or the Welcome version
  ngOnInit() {
    if (this.router.url === '/restricted') {
      this.restricted = true;
    } else if (this.router.url === '/verifyemail') {
      this.verifyemail = true;
    }
    this.route.queryParams.subscribe((result) => {
      this.errormessage = result.errormessage;
    });
  }
}
