import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// dialog building a dialog with a dynamic number of buttons containing options
@Component({
  selector: 'dynamic-button-dialog',
  template: `
    <h1 mat-dialog-title>{{ data.header ? data.header : '' }}</h1>
    <div mat-dialog-content>
      <p [innerHtml]="(data.message ? data.message : '') | SafeHTML"></p>
      <br />
    </div>
    <div class="form-group" align="center" mat-dialog-actions>
      <span style="padding: 10px;" *ngFor="let button of data.buttons">
        <button
          [style.min-width]="data.buttonMinWidth ? data.buttonMinWidth : '75px'"
          [class]="'btn ' + (button.class ? button.class : 'btn-default')"
          [mat-dialog-close]="button.value"
        >
          {{ button.value }}
        </button>
      </span>
    </div>
  `,
})
export class DynamicButtonDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DynamicButtonDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DynamicButtonDialogData,
  ) {}
}

export interface DynamicButtonDialogData {
  header: string;
  message: string;
  buttonMinWidth: string;
  buttons: any[];
}
