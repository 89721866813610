import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { FlashMessageComponent } from '../flash-message.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProjectService } from '../../services/project.service';
import { BioconnectStudySearchComponent } from '../entity-searching/bioconnect-study-search.component';
import { environment } from '../../../environments/environment';

// dialog for importing or exporting procedure from/to bioconnect
@Component({
  selector: 'project-import-export-dialog',
  template: `
    <h1 mat-dialog-title>{{ project.projsym }} Import and Export</h1>
    <div mat-dialog-content class="form-horizontal" style="min-width:1000px;">
      <div *ngIf="project.external_id" class="form-group">
        <label class="col-sm-3 control-label">Linked CBA PFS ID</label>
        <div class="col-sm-6" style="padding-top: 7px;">
          {{ project.external_id }}
        </div>
        <div class="col-sm-3" style="padding-top: 7px;">
          <a
            matTooltip="To re-import data from PFS for this CBA project,
                         please edit this project and click the Re-Import from PFS button
                         at the top of the page next to the Project Status"
            >Re-Import?</a
          >
        </div>
      </div>
      <div *ngIf="project.bioconnect_id" class="form-group">
        <label class="col-sm-3 control-label">
          Linked BioConnect Study
          <img src="./assets/bioconnect_icon.ico" alt="" />
        </label>
        <div class="col-sm-6" style="padding-top: 7px;">
          {{ project.bioconnect_identifier }}
          <a
            *ngIf="bioconnectUi"
            target="_blank"
            [href]="bioconnectUi + 'search/metadata-search-browse/detail-view/study/' + project.bioconnect_id"
            matTooltip="Link to BioConnect"
          >
            <b><span class="glyphicon glyphicon-link"></span></b>
          </a>
        </div>
        <div class="col-sm-3">
          <button type="button" [disabled]="loadingBar" class="btn btn-primary" (click)="reImportBioconnectStudy()">
            Re-Import
          </button>
          &nbsp;&nbsp;
          <button
            type="button"
            [disabled]="loadingBar"
            matTooltip="Unlink this Bioconnect Study from this SIP project"
            class="btn btn-danger"
            (click)="unlinkBioconnectStudy()"
          >
            X
          </button>
        </div>
      </div>

      <div [style.display]="project.bioconnect_id ? 'none' : 'block'" class="form-group">
        <label class="col-sm-3 control-label">
          Link BioConnect Study
          <img src="./assets/bioconnect_icon.ico" alt="" />
        </label>
        <div class="col-sm-6">
          <bioconnect-study-search
            [disabled]="loadingBar"
            [(study)]="study"
            (studyChange)="dialogRef.disableClose = !!$event.id"
          ></bioconnect-study-search>
        </div>
        <div class="col-sm-3">
          <button
            type="button"
            [disabled]="loadingBar || !study?.id"
            class="btn btn-primary"
            (click)="linkBioconnectStudy()"
          >
            Import & Link
          </button>
        </div>
      </div>

      <div [style.display]="project.bioconnect_id ? 'none' : 'block'" class="form-group">
        <label class="col-sm-3 control-label">
          or Export to Bioconnect
          <img src="./assets/bioconnect_icon.ico" alt="" />
        </label>
        <!--         TODO: optionally select an investigation to link to?-->
        <div class="col-sm-6">
          <!--          <bioconnect-study-search-->
          <!--            [disabled]="loadingBar"-->
          <!--            [(study)]="study"-->
          <!--            (studyChange)="dialogRef.disableClose = !!$event.id;"-->
          <!--          ></bioconnect-study-search>-->
        </div>
        <div class="col-sm-3">
          <button type="button" [disabled]="loadingBar" class="btn btn-primary" (click)="exportToBioconnect()">
            Export
          </button>
        </div>
      </div>

      <br />
      <div align="center" *ngIf="loadingBar">
        <br />
        <b>{{ loadingBar }}...</b>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
    </div>
    <br />
    <div class="form-group" mat-dialog-actions>
      <div class="col-xs-4"></div>
      <div
        class="col-xs-4"
        [matTooltip]="
          this.study.id
            ? 'Selected study not yet linked... please either click ' + 'Import and Link or clear the selected study'
            : ''
        "
      >
        <button
          class="btn btn-default"
          style="width:100%;"
          [disabled]="this.loadingBar || this.study.id"
          mat-dialog-close
        >
          Close
        </button>
      </div>
    </div>
  `,
})
export class ProjectImportExportDialogComponent {
  // variable for accessing the bioconnect study search
  @ViewChild(BioconnectStudySearchComponent) bioconnectStudySearchComponent!: BioconnectStudySearchComponent;

  change = false;
  loadingBar = '';
  study: any = {};
  project: any = {};
  // used to link out to the selected study
  bioconnectUi = environment.unsecuredURLs.bioconnectUi;

  constructor(
    public dialogRef: MatDialogRef<ProjectImportExportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ProjectImportData,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private projectService: ProjectService,
  ) {
    this.project = JSON.parse(JSON.stringify(this.data.project));
    dialogRef.beforeClosed().subscribe(() =>
      dialogRef.close({
        project: this.project,
        change: this.change,
      }),
    );
  }

  /**
   * Call on start of an import or export to show loading bar and temporarily disable other actions or exiting
   *
   * @param message: message to show with loading bar
   */
  startLoading(message: string) {
    this.loadingBar = message;
    this.dialogRef.disableClose = true;
  }

  // call on end loading
  endLoading() {
    this.loadingBar = '';
    this.dialogRef.disableClose = false;
  }

  /**
   * Use the "snackbar" to flash the passed-in message for the passed-in duration in
   * this passed-in css class (bootstrap panel).
   *
   * @param {string} message: message to show
   * @param {string} divClass: panel class to display the message in
   * @param {number} duration: duration to show the message
   * @param {string[]} warnings: list of warning strings to show
   */
  flashMessage(message: string, divClass: string = '', duration: number = 2000, warnings: string[] = []) {
    this.snackBar.openFromComponent(FlashMessageComponent, {
      duration: duration,
      data: {
        text: message,
        class: divClass,
        snackbar: this.snackBar,
        listcolor: '#CCCC00',
        listmessages: warnings,
      },
    });
  }

  // clear selected study to unlink project from bioconnect study
  unlinkBioconnectStudy() {
    this.startLoading('Unlinking BioConnect Study');
    this.bioconnectStudySearchComponent.clearSelection();
    this.projectService
      .saveProject({
        projid: this.project.projid,
        bioconnect_id: null,
        log_descrip: 'Unlink BioConnect Study',
      })
      .subscribe(
        (data) => {
          this.bioconnectStudySearchComponent.updateDisableList();
          this.flashMessage('BioConnect Study Unlinked', 'alert-success');
          this.project = data;
          this.change = true;
          this.endLoading();
        },
        (error) => {
          this.flashMessage('Unlinking Failed', 'alert-danger');
          this.endLoading();
        },
      );
  }

  // link an existing project with a bioconnect study (same thing as import to create a project, but updating an
  // existing project instead of creating a new one)
  linkBioconnectStudy() {
    if (this.study.id) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          header: 'Confirm Link Bioconnect Study "' + this.study.identifier + '"',
          message:
            'Importing and linking a BioConnect Study to an existing SIP Project will overwrite some fields ' +
            '(Title, Investigator(s), Intro, Publications, etc) and will lock them to become uneditable in ' +
            'SIP. ' +
            '<br><br>An alternative option would be separately importing a Bioconnect Study as a new SIP project ' +
            'from the "Import Project" button on the project browsing page.' +
            '<br><br> Are you sure you want to go ahead with importing and linking Bioconnect Study "' +
            this.study.identifier +
            '" to SIP project "' +
            this.project.projsym +
            '"',
          falselabel: 'Cancel',
          truelabel: 'Import',
          truebtn: 'btn-primary',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.importBioconnectStudy(this.study.id);
        }
      });
    }
  }

  // re-import a study to get updated data from bioconnect
  reImportBioconnectStudy() {
    if (this.project.bioconnect_id) {
      this.importBioconnectStudy(this.project.bioconnect_id);
    }
  }

  /**
   * Import a bioconnect study and link it with the project
   *
   * @param studyId: bioconnectstudy id to import into the project
   */
  importBioconnectStudy(studyId: number) {
    const reImport = studyId === this.project.bioconnect_id;
    this.startLoading((reImport ? 'Re-' : '') + 'Importing BioConnect Study...');
    this.projectService.importRequest('BioConnect', String(studyId), this.project.projid).subscribe(
      (data) => {
        if (!data.id) {
          this.flashMessage('BioConnect Study Import Failed: ' + data.message, 'alert-danger');
        } else {
          if (!reImport) {
            // NOTE: DON'T clear search component before this...
            this.project.bioconnect_id = this.study.id;
            this.project.bioconnect_identifier = this.study.identifier;
          }
          this.change = true;
          this.flashMessage('BioConnect Study Imported', 'alert-success');
        }
        this.bioconnectStudySearchComponent.clearSelection();
        this.endLoading();
      },
      (error) => {
        this.flashMessage('BioConnect Study Import Failed', 'alert-danger');
        this.bioconnectStudySearchComponent.clearSelection();
        this.endLoading();
      },
    );
  }

  // export the project to bioconnect, linking them together in the process
  exportToBioconnect() {
    this.startLoading('Exporting to BioConnect...');
    this.projectService.exportRequest('BioConnect', this.project.projid).subscribe(
      (data) => {
        this.project.bioconnect_id = data.investigation.study_set[0].id;
        this.project.bioconnect_identifier = data.investigation.study_set[0].identifier;
        this.change = true;
        this.flashMessage('Export to BioConnect Successful!', 'alert-success');
        this.bioconnectStudySearchComponent.clearSelection();
        this.endLoading();
      },
      (error) => {
        const message = error?.error?.error ? ': ' + error.error.error : '';
        this.flashMessage('Export to BioConnect Failed' + message, 'alert-danger');
        this.bioconnectStudySearchComponent.clearSelection();
        this.endLoading();
      },
    );
  }
}

export interface ProjectImportData {
  project: any;
}
