import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { buildDateTimeHtml, escapeHtml } from '../utils';

// dialog for viewing a series of log entries for changes made by users to 1 or more projects
@Component({
  selector: 'log-viewer-dialog',
  template: `
    <h1 mat-dialog-title>{{ data.header ? data.header : 'Log Viewer' }}</h1>
    <div mat-dialog-content>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Date & Time</th>
            <th *ngIf="data.logtype === 'multiproj'">Project Symbol</th>
            <th *ngIf="data.logtype === 'multiproj'">Project Title</th>
            <th *ngIf="data.logtype === 'ontload'">Ontology Type</th>
            <th>User</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let entry of data.entries">
            <tr>
              <td><span [innerHtml]="buildDateTimeHtml(entry.datetime) | SafeHTML"></span></td>
              <td *ngIf="data.logtype === 'multiproj'">{{ entry.projsym }}</td>
              <td *ngIf="data.logtype === 'multiproj'">{{ entry.title }}</td>
              <th *ngIf="data.logtype === 'ontload'">{{ entry.ont }}</th>
              <td *ngIf="entry.user?.id">
                <person-display [person]="entry.user" [nameOrEmail]="true" [showCuratorIcon]="true"></person-display>
              </td>
              <td *ngIf="!entry.user?.id">Command Line</td>
              <td>
                {{ entry.descrip }}
                <small *ngIf="entry.changes">
                  <a (click)="entry.showing = !entry.showing" style="cursor: pointer;">
                    <mat-icon>{{ entry.showing ? 'arrow_drop_up' : 'arrow_drop_down' }}</mat-icon>
                  </a>
                </small>
              </td>
            </tr>
            <tr *ngIf="entry.showing">
              <td colspan="20"><pre [innerHtml]="getChangesDisplay(entry)"></pre></td>
            </tr>
          </ng-container>
        </tbody>
      </table>
      <br />
    </div>
    <div class="form-group" mat-dialog-actions>
      <div class="col-xs-3"></div>
      <button class="col-xs-6 btn btn-default" mat-dialog-close>Close</button>
    </div>
  `,
})
export class LogViewerDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<LogViewerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LogViewerDialogData,
  ) {}

  // so that we can use it in the html...
  buildDateTimeHtml = buildDateTimeHtml;

  /**
   * Displays the changes made to the project in this entry in html as formatted json
   *
   * @param {any} entry: log entry we're displaying changes for
   * @returns {string}: formatted html string to display the json of the changes made to the project in this entry
   */
  getChangesDisplay(entry: any): string {
    if (!entry.changes_display) {
      entry.changes_display = JSON.stringify(entry.changes, undefined, 2);
    }
    return escapeHtml(entry.changes_display);
  }
}

export interface LogViewerDialogData {
  entries: any[]; // TODO: what are these
  oneproj: boolean; // TODO: camelCase this
  header: string;
  logtype: string;
}
