import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class R2Service {
  r2 = environment.securedURLs.r2;

  constructor(private http: HttpClient) {}

  /**
   * Creates a new data object with the specified name and returns the JSON object that
   * comes back from R2 describing the created data object
   * @param dataObjectName - the name the new data object should have
   */
  createNewDataObject(dataObjectName: string): Observable<R2DataObject> {
    const formData = new FormData();
    formData.append('name', dataObjectName);

    return this.http.post<R2DataObject>(`${this.r2}dataobjects/`, formData);
  }

  /**
   * Returns information about the data object identified in the specified URL
   * @param dataObjectURL - a GET URL for information about the data object identified within the URL
   */
  getDataObject(dataObjectURL: string): Observable<R2DataObject> {
    return this.http.get<R2DataObject>(dataObjectURL);
  }

  /**
   * Returns the R2 file object for the specified file in the URL
   * @param r2FileURL - GET URL for information about the file located in R2
   */
  getFile(r2FileURL: string): Observable<R2File> {
    return this.http.get<R2File>(r2FileURL);
  }

  /**
   * Returns contents of the raw file located in the Google Cloud bucket (ideally
   * this URL passed is the location attribute from an R2 file object, such as
   * what's returned from getFile())
   * @param fileGCPURL - GCP location of the specified file
   */
  getRawFileFromBucket(fileGCPURL: string): Observable<string> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: TSLint doesn't like the responseType argument here and affects startup
    //             compilation if left to its own devices
    return this.http.get<string>(fileGCPURL, { responseType: 'text' });
  }

  /**
   * Uploads the formData object created from the specified file to the data object located at the specified URL
   * @param dataObjectURL - the location to where the file should be uploaded to
   * @param file - the file that needs to get uploaded
   */
  uploadFile(dataObjectURL: string, file: File): Observable<R2File> {
    const formData = new FormData();
    formData.append('location', file, file.name);
    formData.append('name', file.name);
    formData.append('dataobject', dataObjectURL);

    return this.http.post<R2File>(`${this.r2}files/`, formData);
  }
}

// defining the structure of data received from the API
/* eslint-disable camelcase */
export interface R2DataObject {
  url: string;
  id: number;
  uuid: string;
  owner: string;
  created: string;
  name: string;
  file_set: string[];
}

export interface R2File {
  created: string;
  dataobject: string;
  id: number;
  location: string;
  name: string;
  url: string;
}
/* eslint-enable camelcase */
