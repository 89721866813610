<span *ngFor="let action_icon of actionIcons">
  <a *ngIf="links[action_icon]" [routerLink]="links[action_icon] + project.projid" [matTooltip]="tooltips[action_icon]">
    <mat-icon>{{ action_icon }}</mat-icon>
  </a>
  <a *ngIf="action_icon === 'import_export'" (click)="onClickImportExport()" [matTooltip]="tooltips[action_icon]">
    <mat-icon>{{ action_icon }}</mat-icon>
  </a>
  <a
    *ngIf="action_icon === 'delete'"
    (click)="deleteProject()"
    [matTooltip]="tooltips[action_icon]"
    class="delete-icon"
  >
    <mat-icon>{{ action_icon }}</mat-icon>
  </a>
  <a
    *ngIf="action_icon === 'restore_from_trash'"
    (click)="restoreProject()"
    [matTooltip]="tooltips[action_icon]"
    class="restore-icon"
  >
    <mat-icon>{{ action_icon }}</mat-icon>
  </a>
  <a
    *ngIf="action_icon === 'delete_forever'"
    (click)="deleteProject(true)"
    [matTooltip]="tooltips[action_icon]"
    class="delete-icon"
  >
    <mat-icon>{{ action_icon }}</mat-icon>
  </a>
  <a
    *ngIf="action_icon === 'bar_chart'"
    (click)="clickExternalLink.emit(previewURL + 'projects/' + project.projsym)"
    [matTooltip]="(!project.status || project.status === 'Public' ? 'View' : 'Preview') + ' this project in MPD'"
  >
    <mat-icon>{{ action_icon }}</mat-icon>
  </a>
</span>
