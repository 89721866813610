<mat-nav-list>
  <mat-list-item
    *ngIf="user"
    id="nav-dashboard"
    [routerLink]="['/dashboard']"
    routerLinkActive="active"
    [matTooltip]="!open ? 'Dashboard' : null"
  >
    <mat-icon mat-list-icon>dashboard</mat-icon>
    <p matLine *ngIf="open">Dashboard</p>
  </mat-list-item>
  <mat-list-item
    id="nav-browse"
    [routerLink]="['/projects']"
    routerLinkActive="active"
    [matTooltip]="!open ? 'Browse Projects' : null"
  >
    <mat-icon mat-list-icon>explore</mat-icon>
    <p matLine *ngIf="open">Browse Projects</p>
  </mat-list-item>
  <mat-list-item
    *ngIf="user"
    id="nav-my-projects"
    [routerLink]="['/projects']"
    [queryParams]="{ userid: 'current' }"
    routerLinkActive="active"
    [matTooltip]="!open ? 'My Projects' : null"
  >
    <mat-icon mat-list-icon>folder</mat-icon>
    <p matLine *ngIf="open">My Projects</p>
  </mat-list-item>
  <mat-list-item
    *ngIf="user && user.emailverified"
    id="nav-contribute"
    [routerLink]="['/proj-details']"
    routerLinkActive="active"
    [matTooltip]="!open ? 'Create Project' : null"
  >
    <mat-icon mat-list-icon>add_circle</mat-icon>
    <p matLine *ngIf="open">Create Project</p>
  </mat-list-item>
  <mat-list-item
    *ngIf="user ? ['Curator', 'Reviewer'].indexOf(user.approle) !== -1 : false"
    id="nav-tasks"
    [routerLink]="['/tasks']"
    routerLinkActive="active"
    [matTooltip]="!open ? 'Tasks' : null"
  >
    <mat-icon mat-list-icon>list_alt</mat-icon>
    <p matLine *ngIf="open">Tasks</p>
  </mat-list-item>
  <mat-list-item
    *ngIf="user ? ['Curator', 'Reviewer'].indexOf(user.approle) !== -1 : false"
    id="nav-appsettings"
    [routerLink]="['/appsettings']"
    routerLinkActive="active"
    [matTooltip]="!open ? 'Application Settings' : null"
  >
    <mat-icon mat-list-icon>settings_application</mat-icon>
    <p matLine *ngIf="open">Application Settings</p>
  </mat-list-item>
  <mat-list-item
    id="nav-help"
    [routerLink]="['/help']"
    routerLinkActive="active"
    [matTooltip]="!open ? 'Help and FAQs' : null"
  >
    <mat-icon mat-list-icon>help</mat-icon>
    <p matLine *ngIf="open">Help and FAQs</p>
  </mat-list-item>
</mat-nav-list>
<mat-nav-list>
  <mat-list-item id="app-info" [matTooltip]="!open ? 'About Study Intake Platform' : null" (click)="openAboutDialog()">
    <mat-icon mat-list-icon>info</mat-icon>
    <p matLine *ngIf="open">About</p>
  </mat-list-item>
</mat-nav-list>
