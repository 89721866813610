<mat-toolbar>
  <h2>My Groups</h2>
</mat-toolbar>
<br />
<a style="padding-left: 18px" routerLink="/user-profile">Go to My Profile</a>
<br />
<div class="form-horizontal">
  <div class="col-sm-12">
    <div *ngIf="userUnrestricted">
      <div align="center"><span style="font-size: 20px; font-weight: 500">Create New Group</span></div>
      <br />
      <div class="form-group">
        <label for="new_group_name" class="col-sm-2 control-label">Group Name</label>
        <div class="col-sm-8">
          <input [(ngModel)]="newName" type="text" class="form-control" id="new_group_name" name="new_group_name" />
          <div *ngIf="message">
            <span style="color: red">{{ message }}</span>
          </div>
        </div>
        <div class="col-sm-2">
          <button
            *ngIf="newName"
            type="button"
            matTooltip="Create New Group"
            (click)="createNewGroup()"
            class="btn btn-primary"
          >
            <span class="glyphicon glyphicon-plus"></span>
          </button>
        </div>
      </div>
    </div>
    <br /><br />
    <div align="center"><span style="font-size: 20px; font-weight: 500">My Groups</span></div>
    <br /><br />
    <div class="form-group" *ngIf="groups.length === 0">
      <label class="col-sm-2"></label>
      <div style="padding-top: 7px" class="col-sm-8">You currently are not in any groups.</div>
    </div>
    <div class="form-group" *ngIf="groups.length !== 0">
      <div class="col-sm-1"></div>
      <label class="col-sm-1">My Role</label>
      <label class="col-sm-6">Group Name</label>
      <label class="col-sm-2">Members</label>
      <label class="col-sm-2">Actions</label>
    </div>
    <div class="form-group" *ngFor="let group of groups">
      <div class="col-sm-1"></div>
      <div style="padding-top: 7px" class="col-sm-1">{{ group.current_user_role }}</div>
      <div style="padding-top: 7px" class="col-sm-6">{{ group.name }}</div>
      <div style="padding-top: 7px" class="col-sm-2">
        <span style="cursor: default" [matTooltip]="groupMembersTooltip(group)">
          <b>{{ group.members.length }}</b>
          &nbsp;<span style="color: blue" class="glyphicon glyphicon-user"></span>
        </span>
      </div>
      <div class="col-sm-2">
        <a
          class="btn btn-success"
          [routerLink]="['/group/' + group.id]"
          [matTooltip]="'View' + (canEditGroup(group) ? '/Edit' : '') + ' this group'"
        >
          <span [class]="'glyphicon glyphicon-' + (canEditGroup(group) ? 'pencil' : 'eye-open')"></span> </a
        >&nbsp;
        <button
          type="button"
          class="btn btn-danger"
          *ngIf="canDeleteGroup(group)"
          matTooltip="Delete this group"
          (click)="deleteGroup(group.id, group.name)"
        >
          <span class="glyphicon glyphicon-trash"></span>
        </button>
      </div>
    </div>
    <br /><br />
    <div align="center"><span style="font-size: 20px; font-weight: 500">Search Groups</span></div>
    <br /><br />
    <div class="form-group">
      <label class="col-sm-2 control-label">Select Group</label>
      <div class="col-sm-8">
        <group-search [(group)]="groupSearch"></group-search>
      </div>
      <div *ngIf="groupSearch.id" class="col-sm-2">
        <a
          class="btn btn-success"
          [routerLink]="['/group/' + groupSearch.id]"
          [matTooltip]="'View' + (canEditGroup(groupSearch) ? '/Edit' : '') + ' this group'"
        >
          <span [class]="'glyphicon glyphicon-' + (canEditGroup(groupSearch) ? 'pencil' : 'eye-open')"></span> </a
        >&nbsp;
        <button
          type="button"
          class="btn btn-danger"
          *ngIf="canDeleteGroup(groupSearch)"
          matTooltip="Delete this group"
          (click)="deleteGroup(groupSearch.id, groupSearch.name, true)"
        >
          <span class="glyphicon glyphicon-trash"></span>
        </button>
      </div>
    </div>
  </div>
  <br />
</div>
