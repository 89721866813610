import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Validates that the filename (the extension should not be part of this) matches or contains
 * any of the specified valid names based on the strictness level. If 'strict' is false, the
 * validator will run a check that the filename contains at least one of the valid names (for
 * example final report files where we want to check that the name at least contains the
 * '_FinalReport' substring. If 'strict' is true, the validator runs a check for an exact match.
 * If the filename is valid, the function will emit null, indicating that no errors were found.
 * If the extension is invalid, a key/value pair will be emitted indicating that the value has
 * failed the file name validation
 * @param {string[]} validNames - a list of file names (or substrings if 'strict' is false) to
 *                                check a filename against
 * @param {boolean} strict - flag indicating if the validator is looking for an exact match
 *                           (default false)
 */
export function FileNameValidator(validNames: string[], strict: boolean = false): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (validNames.length) {
      const filename = control.value.replace(/\.[^/.]+$/, ''); // extract the extension out

      let hasValidName;

      if (strict) {
        // search the valid names for an exact match
        hasValidName = validNames.indexOf(filename) > -1;
      } else {
        // search the valid names to see if the current file's name contains any of the valid names
        hasValidName = validNames.filter((f) => filename.includes(f)).length > 0;
      }

      return hasValidName ? null : { invalidName: { value: control.value } };
    }

    return null;
  };
}
