import { Component, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';

import { CenterService } from '../../services/center.service';
import { PersonService } from '../../services/person.service';
import { environment } from '../../../environments/environment';
import { FormControl } from '@angular/forms';
import { CenterDialogComponent } from '../dialogs/center-dialog.component';
import { buildOpenDialogTooltip } from '../utils';

@Component({
  selector: 'center-search',
  template: `
    <!--using fieldset as a wrapper to make disabling work (it works differently...and not as well...for
    inputs with the formControl attribute, but a wrapping fieldset seems to work fine)-->
    <div [class]="optionSelected() || showDialog ? 'input-group' : ''">
      <fieldset [disabled]="optionSelected() || disabled">
        <span [matTooltip]="inputTooltip()">
          <input
            id="collab-search-input"
            placeholder="Begin typing a center name"
            class="form-control left-bord-rad-4px"
            autocomplete="off"
            [matAutocomplete]="auto"
            [formControl]="valueCtrl"
            (focus)="onFocus()"
            (focusout)="inputFocused = false"
            [style.border-color]="inputNoSelection() ? 'red' : ''"
          />
        </span>
        <span class="form-control-feedback">
          <span
            *ngIf="optionSelected() && !optionNotApproved(collab)"
            style="color:green;"
            [style.right]="showDialog ? '80px' : '40px'"
            class="glyphicon glyphicon-ok"
          ></span>
          <span
            *ngIf="optionNotApproved(collab)"
            style="color:#cccc00;"
            [style.right]="showDialog ? '80px' : '40px'"
            class="glyphicon glyphicon-flag"
          ></span>
          <span
            *ngIf="inputNoSelection()"
            style="color:red"
            [style.right]="showDialog ? '40px' : '20px'"
            class="glyphicon glyphicon-warning-sign"
          ></span>
        </span>
      </fieldset>
      <div
        *ngIf="optionSelected() || showDialog"
        class="input-group-btn"
        [matTooltip]="
          !this.valueCtrl.value && !disabled
            ? 'Type in a name and if the center is not already in the system, ' +
              'then click this button to create them'
            : ''
        "
      >
        <button
          *ngIf="showDialog"
          (click)="openEditDialog()"
          type="button"
          class="btn btn-success"
          [disabled]="!this.valueCtrl.value"
          [matTooltip]="openDialogTooltip()"
        >
          <span [class]="'glyphicon glyphicon-' + (!optionSelected() ? 'plus' : canEdit() ? 'pencil' : 'eye-open')">
          </span>
        </button>
        <button
          *ngIf="optionSelected()"
          (click)="clearSelection()"
          type="button"
          [disabled]="disabled"
          matTooltip="Clear selected center"
          class="btn btn-danger"
        >
          <span class="glyphicon glyphicon-remove clear-icon"></span>
        </button>
      </div>
    </div>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected()" [displayWith]="displayFn">
      <mat-option disabled *ngIf="filteredOptions.length === 0" style="color:red;">
        No matches.
        <span *ngIf="!showDialog"> Please select a center from the options. </span>
        <button *ngIf="showDialog" (click)="openEditDialog()" class="btn btn-success">
          <span class="glyphicon glyphicon-plus"></span> Create new center
        </button>
      </mat-option>
      <!-- this is a performance optimization, which allows the user to see a few matches/options,
            while telling them that more are available if they narrow down their search.
            This pretty effectively fixes the performance issues I've seen from generating too many options at once. -->
      <mat-option
        *ngFor="let option of options"
        [value]="option"
        [disabled]="disableOption(option)"
        [matTooltip]="disableOption(option) ? disableMessage : ''"
      >
        <span
          *ngIf="optionNotApproved(option)"
          style="color:#cccc00;"
          class="glyphicon glyphicon-flag"
          [matTooltip]="optionNotApprovedTooltip(option)"
        ></span>
        {{ option.name }}
        <small>| {{ option.tag }}</small>
      </mat-option>
      <mat-option
        disabled
        [style.display]="!showAllResults ? 'block' : 'none'"
        *ngIf="filteredOptions.length > 50"
        class="mat-option show-all"
      >
        <a (click)="showAllResults = true" style="cursor:pointer;">Show all results</a>
        or type more to narrow down results...
      </mat-option>
    </mat-autocomplete>
  `,
  styleUrls: ['searching.scss'],
})
export class CenterSearchComponent implements OnInit, OnChanges {
  // Two way binding for the selected large collaborator.
  // This component will emit the selected collaborator to the parent
  // when a collaborator is selected, so having a variable to bind to
  // the parent is easier than going off of an event alone.
  @Input() collab: any = { contact: {} };

  // true to show the button to open a dialog to create, edit, or view largecollabs, false to not
  @Input() showDialog = true;

  // true to disable the search box (including preventing it from being cleared out)
  @Input() disabled = false;
  // list of center objects to exclude (compare 'tag')
  @Input() disableList: any[] = [];
  // optional argument to change the message for disabled options
  @Input() disableMessage = '(Already Selected)';

  // event emitter to send the selected collaborator to the parent
  // when one is selected from the autocomplete options
  @Output() collabChange: EventEmitter<any> = new EventEmitter<any>();

  // api URL
  api: string = environment.securedURLs.sip;

  // true if the current user is a curator, false if not
  isCurator = false;

  // formControl for the input value
  valueCtrl: FormControl;

  // true if the input is currently focused, false otherwise
  inputFocused = false;

  // true to show all results, false to only show 6 or 7 results if the number of results is above 50
  showAllResults = false;

  // filtered autocomplete options, list of collaborators (any because typescript complains otherwise)
  filteredOptions: any[] = [];

  // set up the onchange observable with calls the centerService.getCollab() observable (http call)
  // to get filtered options in real time.
  constructor(
    public http: HttpClient,
    public dialog: MatDialog,
    public centerService: CenterService,
    private personService: PersonService,
  ) {
    this.valueCtrl = new FormControl();
    this.valueCtrl.valueChanges.subscribe((result) => {
      const val = result ? result : '';
      if (typeof val !== 'string') {
        this.filteredOptions = [];
      } else {
        this.filterValues(val);
      }
    });
  }

  // on init, do an initial load of options
  ngOnInit() {
    this.centerService.collabs$.subscribe(() => {
      let val = this.valueCtrl.value;
      val = val ? val : '';
      // Note: don't need to change filteredOptions right now if the type isn't a string
      if (typeof val === 'string') {
        this.filterValues(val);
      }
    });

    this.personService.currentUser$.subscribe(() => {
      this.isCurator = this.personService.isCurator();
    });
  }

  // on change of the collaborator from the parent side, set the current collaborator to it if it's valid
  ngOnChanges() {
    if (this.collab) {
      if (this.collab.tag) {
        this.valueCtrl.setValue(this.collab);
      }
    }
  }

  get options(): any[] {
    return this.filteredOptions.length > 50 && !this.showAllResults
      ? this.filteredOptions.slice(0, 7)
      : this.filteredOptions;
  }

  /**
   * Filter the available collaborators in the dropdown to those for which the
   * user-entered string matches the collaborator name
   *
   * @param {string} newValue: string entered by the user
   */
  filterValues(newValue: string) {
    this.showAllResults = false;
    if (newValue) {
      const newValueSplit = newValue.split(' ');
      let tempOptions = this.centerService.collabs.getValue();
      for (let i = 0; i < newValueSplit.length; i++) {
        tempOptions = tempOptions.filter((option) =>
          option ? option.name.toLowerCase().indexOf(newValueSplit[i].toLowerCase()) !== -1 : false,
        );
      }

      this.filteredOptions = tempOptions;
    } else {
      this.filteredOptions = this.centerService.collabs.getValue();
    }
  }

  // return true if the current user can modify the collab, false if not
  canEdit() {
    if (this.isCurator) {
      return true;
    }
    if (!this.collab.tag) {
      return true;
    }
    if (!this.collab.user_creator) {
      return false;
    }
    return this.personService.isCurrentUser(this.collab.user_creator.id);
  }

  /**
   * Determine if the option for selecting this largecollab should be disabled because it's in the disableList
   * @param {Object} collab: largecollab object option
   * @returns {boolean}: true to disable this largecollab option, false to not
   */
  disableOption(collab: any) {
    for (let i = 0; i < this.disableList.length; i++) {
      if (this.disableList[i].tag === collab.tag) {
        return true;
      }
    }
    return false;
  }

  // determines whether an option was selected
  optionSelected(): boolean {
    return this.collab && typeof this.collab === 'object' ? this.collab.tag : false;
  }

  // determines whether the current user has input a value without selecting an option
  inputNoSelection(): boolean {
    return !this.optionSelected() && this.valueCtrl.value && !this.inputFocused;
  }

  // determines whether the selected option is not yet approved by a curator
  optionNotApproved(option: any): boolean {
    return option ? (option.user_creator ? option.user_creator.id : false) : false;
  }

  // builds a string describing the flag for an option that is not yet approved by a curator
  optionNotApprovedTooltip(option: any): string {
    return (
      'This center was added by the user, ' +
      option.user_creator.name_or_email +
      ', and is pending SIP Curator approval.'
    );
  }

  // builds the tooltip for the input if there's an issue
  inputTooltip(): string {
    if (this.inputNoSelection()) {
      return 'Must select option for this field to be populated (click in field to see options)';
    } else if (this.optionNotApproved(this.collab)) {
      return this.optionNotApprovedTooltip(this.collab);
    }
    return '';
  }

  /**
   * display function for the collab object
   *
   * @param {any} option: collab object or undefined
   * @returns {string}: collab name
   */
  displayFn(option?: any): string | undefined {
    if (!option) {
      return undefined;
    }
    if (typeof option === 'object') {
      return option.name;
    }
    return option;
  }

  // if an option is selected, emit a collabChange event so that the parent knows who is selected
  onOptionSelected() {
    this.collab = this.valueCtrl.value;
    this.collabChange.emit(this.collab);
  }

  // clear currently selected collaborator
  clearSelection() {
    this.valueCtrl.setValue('');
    this.filterValues('');
    this.collab = { contact: {} };
    this.collabChange.emit(this.collab);
  }

  // upon focusing on a field, if the current value is blank
  // and we have no options, then create them
  onFocus() {
    this.inputFocused = true;
    const value = this.valueCtrl.value;
    if ((!value || value === '') && this.filteredOptions.length === 0) {
      this.filterValues(value ? value : '');
    }
  }

  // tooltip for opening the dialog for viewing/editing/creating centers
  openDialogTooltip() {
    return buildOpenDialogTooltip(this.valueCtrl.value, this.collab, 'tag', this.canEdit(), 'center');
  }

  // open a dialog to create, edit, or view a largecollab
  openEditDialog(): void {
    const collabCopy = JSON.parse(JSON.stringify(this.collab));
    // default in what the user typed in as the largecollab tag
    if (!collabCopy.tag) {
      collabCopy.name = this.valueCtrl.value;
    }
    const dialogRef = this.dialog.open(CenterDialogComponent, {
      data: { collab: collabCopy },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.tag) {
          this.collab = result;
          this.collabChange.emit(this.collab);
        } else {
          this.clearSelection();
        }
      }
    });
  }
}
