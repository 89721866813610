<div *ngIf="!isCurator && !embed" class="row">
  <div class="col-sm-12">
    <div class="form-horizontal">
      <div>
        <br />
        <h4>You do not have access to this page.</h4>
      </div>
    </div>
  </div>
</div>

<div [style.display]="isCurator || embed ? 'block' : 'none'">
  <mat-toolbar *ngIf="!embed">
    <h2>Edit Treatments Information</h2>
  </mat-toolbar>
  <div class="row">
    <div class="col-sm-12">
      <div *ngIf="!embed">
        &nbsp;&nbsp;&nbsp;
        <b><a class="text9" routerLink="/">Home</a></b>
        <span class="glyphicon glyphicon-chevron-right text9"></span>
        <b><a class="text9" routerLink="/tasks">Tasks</a></b>
        <span class="glyphicon glyphicon-chevron-right text9"></span>
        <b><span class="text9">Edit Treatments Information</span></b>

        <br />
        <br />
        <br />
      </div>

      <div class="form-horizontal">
        <div [style.display]="embed ? 'none' : 'block'" class="form-group">
          <label class="col-sm-3 control-label">Search Treatments</label>
          <div class="col-sm-6">
            <intervention-search
              [(intervention)]="intervention"
              [showDialog]="false"
              (interventionChange)="onInterventionChange()"
            >
            </intervention-search>
          </div>
        </div>

        <br />
        <div class="text-center">
          <span class="text16">
            <b>
              <span *ngIf="newIntervention">Create New</span>
              <span *ngIf="!newIntervention && canEdit()">Edit</span>
              <span *ngIf="!canEdit()">View</span>
              Treatment</b
            >
          </span>
        </div>
        <br />

        <fieldset [disabled]="!canEdit()">
          <div class="form-group has-feedback">
            <label for="int-term" class="col-sm-3 control-label required">Term</label>
            <div class="col-sm-6">
              <input
                type="text"
                id="int-term"
                name="int-term"
                maxlength="80"
                class="form-control"
                [disabled]="!newIntervention"
                [(ngModel)]="intervention.term"
                (keyup)="validateInterventionTerm()"
              />

              <span *ngIf="newIntervention && intervention.term" class="form-control-feedback">
                <span class="glyphicon glyphicon-ok green" *ngIf="!termTaken && isNotEmpty(intervention.term)"></span>
                <span class="glyphicon glyphicon-warning-sign red" *ngIf="termTaken && isNotEmpty(intervention.term)">
                </span>
              </span>
              <span class="red margin-top-bottom-2">
                <small *ngIf="termTaken && isNotEmpty(intervention.term) && newIntervention">
                  Treatment term is required and must be unique: '{{ intervention.term }}' is already taken.
                </small>
              </span>
            </div>
          </div>
          <div class="form-group">
            <label for="int-intervclass" class="col-sm-3 control-label required">Class</label>
            <div class="col-sm-6">
              <select
                id="int-intervclass"
                name="int-intervclass"
                class="form-control"
                [(ngModel)]="intervention.intervclass"
              >
                <option value="surgery">Surgery</option>
                <option value="diets">Diets</option>
                <option value="environment">Environment</option>
                <option value="toxicity">Toxicity</option>
                <option value="pathogens">Pathogens</option>
                <option value="drugs">Drugs</option>
                <option value="hidden">Hidden</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="int-shortname" class="col-sm-3 control-label">
              Short Name
              <span
                class="glyphicon glyphicon-question-sign"
                matTooltip="Short name or code for the treatment.
                            For example, 17aE2 for 17-a-estradiol or ACA for acarbose"
              ></span>
            </label>
            <div class="col-sm-6">
              <input
                id="int-shortname"
                name="int-shortname"
                class="form-control"
                [(ngModel)]="intervention.shortname"
                maxlength="30"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="int-description" class="col-sm-3 control-label">
              Long Description
              <span
                class="glyphicon glyphicon-question-sign"
                matTooltip="Longer description of the treatment"
              ></span>
            </label>
            <div class="col-sm-6">
              <textarea
                rows="3"
                id="int-description"
                name="int-description"
                class="form-control"
                [(ngModel)]="intervention.description"
                maxlength="1000"
              ></textarea>
            </div>
          </div>
          <div class="form-group">
            <label for="int-helpdesc" class="col-sm-3 control-label">Help Description</label>
            <div class="col-sm-6">
              <input
                id="int-helpdesc"
                name="int-helpdesc"
                class="form-control"
                [(ngModel)]="intervention.helpdesc"
                maxlength="100"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="int-wikiname" class="col-sm-3 control-label">
              <span style="white-space: nowrap">
                Wikipedia Name
                <span
                  class="glyphicon glyphicon-question-sign"
                  matTooltip="Name of the related page on wikipedia. For example, for aspirin, if you enter
                                  Aspirin as the Wikipedia name, this link will bring you to
                                  https://www.wikipedia.org/wiki/Aspirin"
                ></span>
              </span>
              <span class="text8">
                (<a
                  target="_blank"
                  [href]="'https://www.wikipedia.org' + (intervention.wikiname ? '/wiki/' + intervention.wikiname : '')"
                >
                  https://www.wikipedia.org</a
                >)
              </span>
            </label>
            <div class="col-sm-6">
              <input
                id="int-wikiname"
                name="int-wikiname"
                class="form-control"
                [(ngModel)]="intervention.wikiname"
                maxlength="80"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="int-alias" class="col-sm-3 control-label">Alias</label>
            <div class="col-sm-6">
              <input id="int-alias" name="int-alias" class="form-control" [(ngModel)]="intervention.alias" />
            </div>
          </div>
        </fieldset>

        <!-- information about projects and measures associated with this intervention -->
        <div *ngIf="!newIntervention && isCurator">
          <br />
          <div class="text-center">
            <span class="text10">
              <b>{{ intervention.projectscount }} projects are associated with this treatment</b>
            </span>
            [
            <a *ngIf="!showProjects && intervention.projectscount > 0" (click)="showProjects = true">show</a>
            <a *ngIf="showProjects" (click)="showProjects = false">hide</a>]
          </div>
          <div *ngIf="showProjects">
            <br />
            <p>{{ projectDisplay() }}</p>
          </div>
          <br />
          <div class="text-center">
            <span class="text10">
              <b>{{ intervention.measurescount }} measures are associated with this treatment</b>
            </span>
            [
            <a *ngIf="!showMeasures && intervention.measurescount > 0" (click)="showMeasures = true">show</a>
            <a *ngIf="showMeasures" (click)="showMeasures = false">hide</a>]
          </div>
          <div *ngIf="showMeasures">
            <br />
            <p>{{ measureDisplay() }}</p>
          </div>
        </div>
        <!-- END information about projects and measures associated with this intervention section -->
        <br />

        <!-- save and delete buttons section -->
        <div class="form-group">
          <div [class]="(newIntervention && !embed) || !canEdit() ? 'col-sm-4' : 'col-sm-2'"></div>
          <div *ngIf="canEdit()" class="col-sm-4" [matTooltip]="!canSave() ? disableSaveMessage : ''">
            <button class="btn btn-success btn-block" [disabled]="!canSave()" (click)="saveInterventionRecord()">
              <strong>Save</strong>
            </button>
          </div>
          <div
            *ngIf="!newIntervention && !embed"
            class="col-sm-4"
            [matTooltip]="!canDelete() ? disableDeleteMessage : ''"
          >
            <button class="btn btn-danger btn-block" [disabled]="!canDelete()" (click)="deleteInterventionRecord()">
              <strong>Delete</strong>
            </button>
          </div>
          <div class="col-sm-4" *ngIf="embed">
            <button class="btn btn-default btn-block" (click)="cancel.emit(true)">
              <strong>{{ canEdit() ? 'Cancel' : 'Close' }}</strong>
            </button>
          </div>
        </div>
        <!-- END save and delete buttons section -->
      </div>
    </div>
  </div>
</div>
