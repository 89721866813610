import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProcedureService } from '../../services/procedure.service';
import { MatDialog } from '@angular/material/dialog';

// used to remove unused/unneeded keys
import { removeAllKeysExcept } from '../utils';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'protocols-io-search',
  template: `
    <!--Using fieldset as a wrapper to make disabling work (it works differently...and not as well...for
        inputs with the formControl attribute, but a wrapping fieldset seems to work fine)-->
    <label style="padding-right:10px;">Sort By</label>
    <select (change)="filterValues(valueCtrl.value)" [(ngModel)]="orderField">
      <option value="activity">Activity</option>
      <option value="relevance">Relevance</option>
      <option value="date">Date</option>
      <option value="name">Name</option>
    </select>
    <label style="padding-right:10px;padding-left:10px;">Order</label>
    <select (change)="filterValues(valueCtrl.value)" [(ngModel)]="orderDir">
      <option value="asc">Ascending</option>
      <option value="desc">Descending</option>
    </select>
    <div [class]="optionSelected() ? 'input-group' : ''">
      <fieldset [disabled]="optionSelected() || disabled">
        <span [matTooltip]="inputTooltip()">
          <input
            placeholder="Begin typing a protocol title or author"
            class="form-control left-bord-rad-4px"
            autocomplete="off"
            [matAutocomplete]="auto"
            [formControl]="valueCtrl"
            (focus)="inputFocused = true"
            (focusout)="inputFocused = false"
            [style.border-color]="inputNoSelection() ? 'red' : ''"
          />
        </span>
        <span class="form-control-feedback">
          <span *ngIf="optionSelected()" style="color:green;right:80px;" class="glyphicon glyphicon-ok"></span>
          <span
            *ngIf="inputNoSelection()"
            style="color:red;right:20px;margin-top:32px;"
            class="glyphicon glyphicon-warning-sign"
          ></span>
        </span>
      </fieldset>
      <div *ngIf="optionSelected()" class="input-group-btn">
        <a
          target="_blank"
          [href]="'https://www.protocols.io/view/' + protocol.uri"
          matTooltip="Link to Protocols.io"
          class="btn btn-primary"
        >
          <span class="glyphicon glyphicon-link clear-icon"></span>
        </a>
        <button
          (click)="clearSelection()"
          type="button"
          [disabled]="disabled"
          matTooltip="Clear selected protocol"
          class="btn btn-danger"
        >
          <span class="glyphicon glyphicon-remove clear-icon"></span>
        </button>
      </div>
    </div>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected()" [displayWith]="displayFn">
      <mat-option disabled *ngIf="filteredOptions.length === 0" style="color:red;">
        <span style="color:black;" *ngIf="loading">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>Loading results...
        </span>
        <span *ngIf="!loading">
          <span *ngIf="valueCtrl.value"> No matches. </span>
          <span *ngIf="!valueCtrl.value"> Begin typing a protocol title or author to see results. </span>
        </span>
      </mat-option>
      <mat-option *ngFor="let option of filteredOptions" [value]="option">
        <div style="padding-left:30px;" *ngIf="option.old_version">
          <b>(V.{{ option.version_id + 1 }})</b> {{ option.title }}
        </div>
        <div *ngIf="!option.old_version">
          <b *ngIf="option.has_versions === 1">(V.{{ option.version_id + 1 }})</b> {{ option.title }} |
          <small>{{ authorsString(option) }}</small>
        </div>
      </mat-option>
      <mat-option disabled [style.display]="!nextPage ? 'none' : 'block'" class="mat-option show-all">
        <span [style.display]="loadingMore ? 'none' : 'block'">
          <a (click)="getNextPage()" style="cursor:pointer;">Show more results</a>
        </span>
        <span [style.display]="!loadingMore ? 'none' : 'block'">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>Loading more results...
        </span>
      </mat-option>
    </mat-autocomplete>
  `,
  styleUrls: ['searching.scss'],
})
export class ProtocolIOSearchComponent implements OnChanges {
  // ordering argument for querying the protocols.io listing endpoint,
  // input is just the defaulted value, can be changed in the dropdown,
  // 'relevance', 'activity', 'date', 'name', or 'id'
  @Input() orderField = 'relevance';

  // order direction field for the protocols.io listing endpoint,
  // input is just the defaulted value, can be changed in the dropdown,
  // 'asc' or 'desc'
  @Input() orderDir = 'desc';

  // true if selection and clearing should be disabled, false if not
  @Input() disabled = false;

  // protocols.io protocol object 2-way binding
  @Input() protocol: any = {};

  // event emitter to send the selected protocol to the parent when one is selected from the autocomplete options
  @Output() protocolChange: EventEmitter<any> = new EventEmitter<any>();

  // formControl for the input value
  valueCtrl: FormControl;

  // true if the input is currently focused, false otherwise
  inputFocused = false;

  // true while initial results are being loaded from the api
  loading = false;

  // true while more results are being loaded from the api
  loadingMore = false;

  // filtered autocomplete options, list of people (any because typescript complains otherwise)
  filteredOptions: any[] = [];

  // nextPage url received from the results, is the full protocols.io get url
  nextPage = '';

  // sets up a subscription on change of the filering value to query the protocols.io listing endpoint
  // to build a list of results. Includes a debounceTime so that we're not flooding their endpoints.
  constructor(public http: HttpClient, public dialog: MatDialog, public procedureService: ProcedureService) {
    this.valueCtrl = new FormControl();
    this.valueCtrl.valueChanges.pipe(debounceTime(500)).subscribe((result) => {
      this.filterValues(result);
    });
  }

  // on change of the protocol by the parent, populate it on this search component
  ngOnChanges(changes: SimpleChanges) {
    if (this.protocol && changes.protocol ? this.protocol.id : false) {
      this.valueCtrl.setValue(this.protocol);
    }
  }

  // determines whether an option was selected
  optionSelected(): boolean {
    return this.protocol && typeof this.protocol === 'object' ? this.protocol.id : false;
  }

  // determines whether the current user has input a value without selecting an option
  inputNoSelection(): boolean {
    return !this.optionSelected() && this.valueCtrl.value && !this.inputFocused;
  }

  // determines whether the selected option is not yet approved by a curator
  optionNotApproved(option: any): boolean {
    return option ? (option.user_creator ? option.user_creator.id : false) : false;
  }

  // builds a string describing the flag for an option that is not yet approved by a curator
  optionNotApprovedTooltip(option: any): string {
    return (
      'This protocol was added by the user, ' +
      option.user_creator.name_or_email +
      ', and is pending SIP Curator approval.'
    );
  }

  // builds the tooltip for the input if there's an issue
  inputTooltip(): string {
    if (this.inputNoSelection()) {
      return 'Must select option for this field to be populated (click in field to see options)';
    } else if (this.optionNotApproved(this.protocol)) {
      return this.optionNotApprovedTooltip(this.protocol);
    }
    return '';
  }

  /**
   * Build a list of protocols.io based on user-entered value and selected arguments to the
   * get endpoint. Don't bother querying unless the user has entered something because their listing
   * endpoint doesn't return anything unless there is a filter value.
   *
   * @param {any} newValue: string entered by the user or selected object
   */
  filterValues(newValue: any) {
    newValue = newValue ? newValue : '';
    this.filteredOptions = [];
    this.nextPage = '';
    if (typeof newValue !== 'string' || !newValue) {
      // pass
    } else {
      this.loading = true;
      this.procedureService.getProtocolsIOList(newValue, this.orderField, this.orderDir).subscribe((result) => {
        this.loading = false;
        this.filteredOptions = this.buildVersionOptions(result.items);
        this.nextPage = result.pagination.nextPage;
      });
    }
  }

  // queries the protocols.io api for the next page of results and appends them to the options
  getNextPage() {
    if (this.nextPage) {
      this.loadingMore = true;
      this.procedureService.getProtocolsIONextPage(this.nextPage).subscribe((result) => {
        this.loadingMore = false;
        this.filteredOptions = this.filteredOptions.concat(this.buildVersionOptions(result.items));
        this.nextPage = result.pagination.nextPage;
      });
    }
  }

  /**
   * Because their listing endpoint only returns the latest version of public protocols at the top level,
   * and older public versions of the same protocol as a list of object in that object (under the key "versions"),
   * this function builds a list of options that includes older versions, which are identified by adding a key
   * 'old_version' and tabbed-over beneath the lastest version in the listed options.
   *
   * @param {any[]} options: list of protocol objects
   * @returns {any[]}: list of protocol objects that includes older versions just below the latest version
   *
   */
  buildVersionOptions(options: any[]): any[] {
    const newOptions: any[] = [];
    for (const option of options) {
      newOptions.push(option);
      if (option.has_versions === 1) {
        // order by descending version number
        for (let i = option.versions.length - 1; i >= 0; i--) {
          const oldVersionOption = option.versions[i];
          // NOTE: the older versions aren't always public, and when that happens, the id is 0, so
          //       don't add the older version if that's the case
          if (oldVersionOption.id !== 0 && option.id !== oldVersionOption.id) {
            oldVersionOption.old_version = true;
            newOptions.push(oldVersionOption);
          }
        }
      }
    }
    return newOptions;
  }

  /**
   * display function for the protocol object
   * @param {any} option: protocol object or undefined
   * @returns {string}: display str
   */
  displayFn(option?: any): string | undefined {
    if (!option) {
      return undefined;
    }
    if (typeof option === 'object') {
      return option.title;
    }
    return option;
  }

  // if an option is selected, emit a protocolChange event so that the parent knows who is selected
  onOptionSelected() {
    this.protocol = this.valueCtrl.value;
    this.protocolChange.emit(this.protocol);
    // if this is an older version of a public protocol, for some reason the DOI
    // isn't included, so get the individual protocol value from their api to populate it in the object
    this.procedureService.getProtocolsIOByID(this.protocol.id).subscribe((result) => {
      // we may want to do something with them later... but for now, the protocols.io values can trigger security
      // warnings from cleaning the args on save from the backend... and we only save and use these 4 from now on...
      // so removing the others for now
      this.protocol = removeAllKeysExcept(result.protocol, ['title', 'id', 'doi', 'uri']);
      this.valueCtrl.setValue(this.protocol);
      this.protocolChange.emit(this.protocol);
    });
  }

  /**
   * Builds a string containing comma-separated author names for the protocol's authors
   *
   * @param {any} option: protocols.io protocol dict
   * @returns {string}: string containing comma-separated author names
   */
  authorsString(option: any): string {
    const authors: any[] = option.authors;
    return authors
      .map((value) => {
        return value.name;
      })
      .join(', ');
  }

  // clear currently selected object
  clearSelection() {
    this.valueCtrl.setValue('');
    this.filterValues('');
    this.protocol = {};
    this.protocolChange.emit(this.protocol);
  }
}
