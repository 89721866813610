import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

// dialog for adding/editing/viewing interventions on-the-fly
@Component({
  selector: 'intervention-dialog',
  template: `
    <div mat-dialog-content style="min-width:800px;">
      <edit-intervention
        [(intervention)]="data.intervention"
        [embed]="true"
        (cancel)="dialogRef.close()"
        (interventionChange)="dialogRef.close($event)"
      ></edit-intervention>
    </div>
  `,
})
export class InterventionDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<InterventionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { intervention: any },
  ) {}
}
