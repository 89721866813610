import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class MethodologyService {
  // api url
  private api: string = environment.securedURLs.sip;

  // methodology behavioursubject for building an observable
  public methodologies = new BehaviorSubject<any[]>([]);
  // observable, which can be subscribed to in order to get changes
  // to the methodologies list any time it is updated
  public methodologies$ = this.methodologies.asObservable();
  // flag as to whether the methodologies list has been initialized yet
  private methodologiesListInitialized = false;

  // declare http client
  constructor(private http: HttpClient) {
    if (!this.methodologiesListInitialized) {
      this.methodologiesListInitialized = true;
      this.getMethodology().subscribe();
    }
  }

  /**
   * Sends a http request to GET either a specific methodologies or a list of all of them
   *
   * @param {string} term: specific methodology to retrieve or null to get a list of all methodologies
   * @returns {Observable<any>} Observable
   */
  getMethodology(term: string | null = null): Observable<any> {
    let url = this.api + 'methodologies';

    if (term) {
      url += '?term=' + String(term);
    }
    return this.http.get<any>(url).pipe(
      tap((result) => {
        if (!term) {
          this.methodologiesListInitialized = true;
          // cache the list and trigger the methodologies$ observable
          // so that all subscribers get the updated list
          this.methodologies.next(result);
        }
      }),
    );
  }

  /**
   * Sends a http request to create/update a methodology's information
   *
   * @param {any} methodology - object representing a methodology
   * @return {Observable<any>} - updated methodology object (on success)
   */
  saveMethodology(methodology: any): Observable<any> {
    const url = this.api + 'methodologies';

    return this.http
      .put<any>(url, methodology ? methodology : {}, {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
      })
      .pipe(
        tap(() => {
          // update the cached methodology in case someone else is working on methodologies
          this.getMethodology().subscribe();
        }),
      );
  }

  /**
   * Sends http request to delete a methodology and updates cache
   *
   * @param {number} term - term of the methodology to delete
   * @return {Observable<any>} - an observable
   */
  deleteMethodology(term: string): Observable<any> {
    const url = this.api + 'methodologies?term=' + term;

    return this.http.delete<any>(url).pipe(
      tap(() => {
        const methodologiesList = this.methodologies.getValue();

        for (let i = 0; i < methodologiesList.length; i++) {
          if (term === methodologiesList[i].term) {
            methodologiesList.splice(i, 1);
            this.methodologies.next(methodologiesList);
            break;
          }
        }

        this.getMethodology().subscribe();
      }),
    );
  }
}
