import { Component, Input, OnChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { FileUploadComponent } from '../../../shared/file-upload/file-upload.component';
import { ConfirmationDialogComponent } from '../../../shared/dialogs/confirmation-dialog.component';
import { ProcedureSearchComponent } from '../../../shared/entity-searching/procedure-search.component';

import * as Highcharts from 'highcharts';

declare let require: any;
const NetworkGraph = require('highcharts/modules/networkgraph');

NetworkGraph(Highcharts);

/* eslint-disable camelcase */
interface ProjectIsaData {
  id: number;
  projid: number;
  isa_type: string;
  datatype: string;
  url: string;
  name: string;
  descrip: string;
  characteristics: Array<string>;
  parameters: Array<string>;
  performer: string;
  date: string;
  parents: Array<number>;
  children: Array<number>;
  proc_id: number | null;
  procedure: any;
  numfiles: number;
}
/* eslint-enable camelcase */

@Component({
  selector: 'project-isa-data',
  templateUrl: './project-isa-data.component.html',
  styleUrls: ['./project-isa-data.component.scss'],
})
export class ProjectIsaDataComponent implements OnChanges {
  // full project object (currently read-only for getting project procedures)
  @Input() project: any = { procedures: [] };
  // flat list of isa data, process, and material nodes for the project
  @Input() projectIsaData: ProjectIsaData[] = [];
  // project id
  @Input() projectId!: number;
  // true if the current user can edit, false if not
  @Input() canEdit!: boolean;

  // triggers a save in the parent component
  @Output() triggerSave: EventEmitter<null> = new EventEmitter<null>();

  // variable for accessing the fileupload child component in this parent component's typescript
  @ViewChild(FileUploadComponent) fileUpload?: FileUploadComponent;
  // variable for accessing the fileupload child component in this parent component's typescript
  @ViewChild(ProcedureSearchComponent) procedureSearch?: ProcedureSearchComponent;

  // used to start from 0 and go into negative #s for ids to prevent duplicates...
  // the backend DB will assign an id when the record is saved
  newIds: number[] = [];

  // node that's currently being edited
  currentNode: ProjectIsaData | undefined = undefined;
  // hide most fields by default to prevent extra clutter
  showExtraFields = false;

  // variables holding text values for material characteristic and process parameter text inputs
  characteristicInput = '';
  parameterInput = '';

  // used for the dropdown for selecting parent nodes
  parentLinkId: string | null = null;
  // list of parents of the current node
  parents: ProjectIsaData[] = [];
  // list of options in the dropdown when selecting parents
  parentOptions: ProjectIsaData[] = [];

  // true to show file upload for data node, false to not
  // (note: file upload will still show regardless if there are uploade files)
  showFileUpload = false;
  // If a user wants to upload files to a new node that hasn't been saved yet (and therefore still has a temp id),
  // disable the upload button while we save the project so that we can get an id from the backend
  uploadButtonDisabled = false;

  // saves the rendered chart object
  chart: any = null;

  constructor(public dialog: MatDialog) {}

  ngOnChanges() {
    // selecting/editing/viewing current nodes is driven by the plot/graph/chart,
    // re-plot it any time the data from the parent component changes
    if (this.projectIsaData.length > 0) {
      setTimeout(() => {
        this.plotIsaNodes();
      }, 1000);
    }
    // if a save resulted in a temporary id being assigned and it was the node currently being edited, then
    // set the currentNode to the new instance of the node
    if (this.currentNode) {
      const node = this.findNodeById(this.currentNode.id);
      if (node) {
        this.currentNode = node;
      } else {
        // otherwise, have to clear out the selected node... it can be re-selected
        this.currentNode = undefined;
      }
    }
  }

  // add a new node, set it as current, and replot the graph
  addNode(): void {
    const newProjData = this.createNode();
    this.projectIsaData.push(newProjData);
    this.editNode(newProjData.id);
    this.plotIsaNodes();
  }

  /**
   * Add a child node to the passed-in node id, set it as current, and replot the graph
   *
   * @param {number} parentId: parent id child is being added to
   */
  addChild(parentId: number) {
    const newProjData = this.createNode();
    newProjData.parents = [parentId];
    this.projectIsaData.push(newProjData);
    const parent = this.findNodeById(parentId);
    if (parent) {
      parent.children.push(newProjData.id);
    }
    this.editNode(newProjData.id);
    this.plotIsaNodes();
  }

  /**
   * Create a new ProjectIsaData object
   * Initial values are set to empty strings, but in the future
   * we may want to set value defaults
   *
   */
  createNode(): ProjectIsaData {
    // assign a temporary id that won't clash with saved records
    // so that this can be safely removed if there are multiple unsaved links
    const newId = 0 - this.newIds.length;
    this.newIds.push(newId);

    return <ProjectIsaData>{
      id: newId,
      projid: this.projectId,
      datatype: '',
      isa_type: '',
      url: '',
      descrip: '',
      name: '',
      characteristics: [],
      parameters: [],
      performer: '',
      date: '',
      proc_id: null,
      procedure: {},
      parents: [],
      children: [],
      numfiles: 0,
    };
  }

  /**
   * Remove a isa node based on the id
   *
   * @param {number} id: primary key of the node  to remove
   */
  removeNode(id: number) {
    const index = this.findNodeIndexById(id);
    const node = this.findNodeById(id);
    if (index > -1 && node && this.canEdit) {
      // promote children (creates a copy of the children on all parents)
      this.promoteChildren(node);
      const parents = this.findParents(node);
      for (let i = 0; i < parents.length; i++) {
        const parent = parents[i];
        for (let j = parent.children.length - 1; j > -1; j--) {
          if (parent.children[j] === id) {
            parent.children.splice(j, 1);
          }
        }
      }
      if (this.currentNode && this.currentNode.id === id) {
        this.currentNode = undefined;
      }
      // finally remove the node
      this.projectIsaData.splice(index, 1);
      // replot to show change
      this.plotIsaNodes();
    }
  }

  /**
   * For a collection of child nodes belonging to a parent that is
   * being deleted, promote them to be children of the parents of the node being deleted
   *
   * @param {ProjectIsaData} parent: parent node for which we are promoting the children
   */
  promoteChildren(parent: ProjectIsaData) {
    const grandparents = this.findParents(parent);
    const children = this.findChildren(parent);
    children.forEach((child) => {
      // inherit (grand)parent ids
      child.parents = parent.parents;
      // add children to grandparents child ids
      for (let i = 0; i < grandparents.length; i++) {
        if (grandparents[i].children.indexOf(child.id) === -1) {
          grandparents[i].children.push(child.id);
        }
      }
    });
  }

  /**
   * Find the ProjectIsaData objects of the parents of the passed-in node
   * based on the list of ids for the node's parents
   *
   * @param {ProjectIsaData} node: node for which we are finding parents
   * @returns {ProjectIsaData[]} list of parent nodes
   */
  findParents(node: ProjectIsaData): ProjectIsaData[] {
    const parents = [];
    for (let i = 0; i < node.parents.length; i++) {
      const parent = this.findNodeById(node.parents[i]);
      if (parent) {
        parents.push(parent);
      }
    }
    return parents;
  }

  /**
   * Find the ProjectIsaData objects of the children of the passed-in node
   * based on the list of ids for the node's children
   *
   * @param {ProjectIsaData} node: node for which we are finding children
   * @returns {ProjectIsaData[]} list of child nodes
   */
  findChildren(node: ProjectIsaData): ProjectIsaData[] {
    const children = [];
    for (let i = 0; i < node.children.length; i++) {
      const child = this.findNodeById(node.children[i]);
      if (child) {
        children.push(child);
      }
    }
    return children;
  }

  /**
   * Find the index of a node in this.projectIsaData from an input id
   *
   * @param {number} id: node id
   * @returns {number} index of node in this.projectIsaData ... -1 if it's not found
   */
  findNodeIndexById(id: number): number {
    for (let i = 0; i < this.projectIsaData.length; i++) {
      if (this.projectIsaData[i].id === id) {
        return i;
      }
    }
    return -1;
  }

  /**
   * Find the ProjectIsaData node object from an input id
   *
   * @param {number} id: node id
   * @returns {number} ProjectIsaData node object
   */
  findNodeById(id: number): ProjectIsaData | null {
    const index = this.findNodeIndexById(id);
    if (index > -1) {
      return this.projectIsaData[index];
    }
    return null;
  }

  // on change of the parent links dropdown, the current node to the selected parent and clear the dropdown
  onParentLinkChange() {
    if (this.parentLinkId && this.currentNode && this.canEdit) {
      this.linkToParent(this.currentNode.id, Number(this.parentLinkId));
      this.parentLinkId = null;
    }
  }

  /**
   * Link a child node to a parent node, rebuild parents list, and replot
   *
   * @param {number} childId: node id of child
   * @param {number} parentId: node id of parent
   */
  linkToParent(childId: number, parentId: number) {
    const child = this.findNodeById(childId);
    const parent = this.findNodeById(parentId);
    if (child && parent && this.canEdit) {
      if (child.parents.indexOf(parentId) === -1) {
        child.parents.push(parentId);
      }
      if (parent.children.indexOf(childId) === -1) {
        parent.children.push(childId);
      }
      this.plotIsaNodes();
      if (this.currentNode && this.currentNode.id === child.id) {
        this.currentNode = child;
        this.buildParentInfo();
      }
    }
  }

  /**
   * Unlink a child node from a parent node, rebuild parents list, and replot
   *
   * @param {number} childId: node id of child
   * @param {number} parentId: node id of parent
   */
  unlinkFromParent(childId: number, parentId: number) {
    const child = this.findNodeById(childId);
    const parent = this.findNodeById(parentId);
    if (child && parent && this.canEdit) {
      if (child.parents.indexOf(parentId) !== -1) {
        child.parents.splice(child.parents.indexOf(parentId), 1);
      }
      if (parent.children.indexOf(childId) !== -1) {
        parent.children.splice(parent.children.indexOf(childId), 1);
      }
      this.plotIsaNodes();
      if (this.currentNode === child) {
        this.currentNode = child;
        this.buildParentInfo();
      }
    }
  }

  /**
   * Find the node for the id and set it as the node we are currently editing
   *
   * @param {number} id: node id
   */
  editNode(id: number) {
    const node = this.findNodeById(id);
    if (node) {
      this.currentNode = node;
      if (!node.procedure.proc_id && this.procedureSearch) {
        this.procedureSearch.clearSelection();
      }
      this.buildParentInfo();
      // if we switched to a data node without the ability to upload files (because it hasn't been saved yet), then
      // hide it so that we can trigger saving if the user wants to upload files
      if (this.currentNode?.isa_type === 'Data' && this.currentNode.id < 1) {
        this.showFileUpload = false;
      }
      setTimeout(() => {
        const editDiv = document.getElementById('edit-current-node-div');
        const nameElement = document.getElementById('name_node_' + id);
        if (nameElement && editDiv) {
          // focus the name and scroll the whole edit div into view
          nameElement.focus();
          editDiv.scrollIntoView();
        }
      }, 300);
      if (id > 0) {
        setTimeout(() => {
          this.fileUpload?.reloadFiles();
        }, 500);
      }
    }
  }

  // build the above 2 variables for the node that is currently being viewed/edited
  buildParentInfo() {
    if (this.currentNode) {
      this.parents = this.findParents(this.currentNode);
      this.parentOptions = [];
      const excludeIdFromParentOptions = this.currentNode.parents.slice();
      excludeIdFromParentOptions.push(this.currentNode.id);
      // loop through children to make sure no children of the currentNode (direct or indirect)
      // are listed as an option to select as a parent
      let children = this.findChildren(this.currentNode).slice();
      while (children.length > 0) {
        const child = children.shift();
        if (child) {
          if (excludeIdFromParentOptions.indexOf(child.id) === -1) {
            excludeIdFromParentOptions.push(child.id);
            children = children.concat(this.findChildren(child).slice());
          }
        }
      }
      for (let i = 0; i < this.projectIsaData.length; i++) {
        if (excludeIdFromParentOptions.indexOf(this.projectIsaData[i].id) === -1) {
          this.parentOptions.push(this.projectIsaData[i]);
        }
      }
    }
  }

  // add a characteristic in the text field to the current node
  addCharacteristic() {
    if (this.currentNode && this.currentNode.isa_type === 'Material' && this.characteristicInput) {
      this.currentNode.characteristics.push(this.characteristicInput);
      this.characteristicInput = '';
    }
  }

  // add a paremeter in the text field to the current node
  addParameter() {
    if (this.currentNode && this.currentNode.isa_type === 'Process' && this.parameterInput) {
      this.currentNode.parameters.push(this.parameterInput);
      this.parameterInput = '';
    }
  }

  // on change of isa type, clear datatype and replot
  isaTypeChange() {
    if (this.currentNode) {
      this.currentNode.datatype = '';
      // if we switched to a data node without the ability to upload files (because it hasn't been saved yet), then
      // hide it so that we can trigger saving if the user wants to upload files
      if (this.currentNode?.isa_type === 'Data' && this.currentNode.id < 1) {
        this.showFileUpload = false;
      }
      this.plotIsaNodes();
    }
  }

  // on click of the show/hide button for the file upload
  uploadClick() {
    if (this.currentNode && this.currentNode.id < 1) {
      // save project before showing file upload
      // (want to make sure there's a real isa data id associated with the uploaded file)
      this.uploadButtonDisabled = true;
      this.triggerSave.emit();
      setTimeout(() => {
        this.uploadButtonDisabled = false;
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          data: {
            header: 'Data Node Saved',
            message:
              'The data node was saved so that files can be uploaded for it.<br><br>' +
              'Please re-select the node in the graph to upload files.',
            hidefalsebtn: true,
            truelabel: 'Close',
            truebtn: 'btn-default',
          },
        });
        dialogRef.afterClosed().subscribe();
      }, 3000);
    } else {
      this.showFileUpload = true;
    }
  }

  // only show the file upload if there is a currnet node, it has been saved and assigned an id, and either
  // it's a data node and the user selecte to show the upload, or there are files uploaded already for this node
  fileUploadDisplay() {
    return this.currentNode &&
      this.currentNode.id > 0 &&
      ((this.currentNode?.isa_type === 'Data' && this.showFileUpload) || this.filesUploaded())
      ? 'block'
      : 'none';
  }

  // check if any files are uploaded on the file-upload component
  filesUploaded(): boolean {
    if (this.fileUpload) {
      return this.fileUpload?.uploadedFiles?.filter((value) => !value.deletedtime).length > 0;
    }
    return false;
  }

  // when a file is deleted or a new file is uploaded on the current node, make sure the number of files is updated
  updateCurrentNodeNumFiles() {
    if (this.currentNode) {
      if (this.fileUpload?.uploadedFiles) {
        this.currentNode.numfiles = this.fileUpload?.uploadedFiles?.filter((value) => !value.deletedtime).length;
      } else {
        this.currentNode.numfiles = 0;
      }
      this.plotIsaNodes();
    }
  }

  // plot the isa study, assay, process, data, and material nodes in a network graph, org chart, or sankey diagram
  plotIsaNodes() {
    const data = [];
    const nodes: any[] = [];
    // create nodes for the project (study in isa model)
    nodes.push({
      id: 'Project',
      color: '#DDDDDD',
      marker: {
        radius: 20,
      },
    });
    // create nodes for the procedures (assays in the isa model)
    for (let i = 0; i < this.project.procedures.length; i++) {
      const title = this.project.procedures[i].title;
      const id = 'proc_id_' + this.project.procedures[i].proc_id;
      nodes.push({
        id: id,
        name: 'Procedure #' + (i + 1) + ': ' + (title ? title : '(No Title)'),
        color: 'darkred',
        marker: {
          radius: 10,
        },
      });
      const link = ['Project', id];
      data.push(link);
    }
    // loop over isa nodes and create highcharts nodes objects and data links between the nodes
    for (let i = 0; i < this.projectIsaData.length; i++) {
      const node = this.projectIsaData[i];
      const id = node.id;
      const name =
        (node.name ? node.name : node.descrip ? node.descrip : ' ') +
        (node.numfiles > 0 ? ` (${node.numfiles} file` + (node.numfiles > 1 ? 's' : '') + ')' : '');
      const color =
        node.isa_type === 'Data'
          ? 'blue'
          : node.isa_type === 'Material'
          ? 'orange'
          : node.isa_type === 'Process'
          ? 'green'
          : 'grey';
      nodes.push({ id: id, name: name, color: color });
      if (node.parents.length > 0) {
        for (let i = 0; i < node.parents.length; i++) {
          const parentId = node.parents[i];
          const link = [parentId, id];
          data.push(link);
        }
      } else {
        let link;
        if (node.procedure.proc_id) {
          link = ['proc_id_' + node.procedure.proc_id, id];
        } else {
          link = ['Project', id];
        }
        data.push(link);
      }
    }
    const keys = ['from', 'to'];
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    const options: any = {
      chart: {
        type: 'networkgraph',
        height: 600,
        renderTo: 'project-isa-nodes-graph',
      },
      title: {
        text: 'Nodes Network Graph',
      },
      plotOptions: {
        networkgraph: {
          layoutAlgorithm: {
            enableSimulation: false,
          },
        },
      },
      legend: {
        enabled: true,
        verticalAlign: 'top',
      },
      series: [
        {
          showInLegend: false,
          dataLabels: {
            enabled: true,
            linkFormat: '',
          },
          marker: {
            symbol: 'circle',
          },
          id: 'isa-nodes',
          point: {
            events: {
              click: function (e: any) {
                // on click of a node, set it as the current node to view/edit
                if (
                  e &&
                  e.point &&
                  (e.point.id || e.point.id === 0) &&
                  String(e.point.id).indexOf('proc_id') === -1 &&
                  String(e.point.id).indexOf('Project') === -1
                ) {
                  that.editNode(e.point.id);
                }
              },
            },
          },
          keys: keys,
          data: data,
          nodes: nodes,
        },
        // the following series are for building the legend
        {
          color: '#DDDDDD',
          id: 'Project Node',
          events: {
            legendItemClick: function () {
              return false;
            },
          },
          marker: {
            symbol: 'circle',
          },
          name: 'Project Node',
          showInLegend: true,
        },
        {
          color: 'darkred',
          id: 'Procedure Nodes',
          events: {
            legendItemClick: function () {
              return false;
            },
          },
          marker: {
            symbol: 'circle',
          },
          name: 'Procedure Nodes',
          showInLegend: true,
        },
        {
          color: 'orange',
          id: 'Material Nodes',
          events: {
            legendItemClick: function () {
              return false;
            },
          },
          marker: {
            symbol: 'circle',
          },
          name: 'Material Nodes',
          showInLegend: true,
        },
        {
          color: 'green',
          id: 'Process Nodes',
          events: {
            legendItemClick: function () {
              return false;
            },
          },
          marker: {
            symbol: 'circle',
          },
          name: 'Process Nodes',
          showInLegend: true,
        },
        {
          color: 'blue',
          id: 'Data Nodes',
          events: {
            legendItemClick: function () {
              return false;
            },
          },
          marker: {
            symbol: 'circle',
          },
          name: 'Data Nodes',
          showInLegend: true,
        },
        {
          color: 'grey',
          id: 'Unspecified Type Nodes',
          events: {
            legendItemClick: function () {
              return false;
            },
          },
          marker: {
            symbol: 'circle',
          },
          name: 'Unspecified Type Nodes',
          showInLegend: true,
        },
      ],
    };
    this.chart = new Highcharts.Chart(options);
  }
}
