import { Component } from '@angular/core';

@Component({
  selector: 'account-settings',
  template: `
    <mat-toolbar>
      <h2>Account Settings</h2>
    </mat-toolbar>
  `,
})
export class SettingsComponent {}
