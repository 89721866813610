import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class InterventionService {
  // api url
  private api: string = environment.securedURLs.sip;

  // intervention behavioursubject for building an observable
  public interventions = new BehaviorSubject<any[]>([]);
  // observable, which can be subscribed to in order to get changes
  // to the interventions list any time it is updated
  public interventions$ = this.interventions.asObservable();
  // flag as to whether the interventions list has been initialized yet
  private interventionsListInitialized = false;

  // declare http client
  constructor(private http: HttpClient) {
    if (!this.interventionsListInitialized) {
      this.interventionsListInitialized = true;
      this.getIntervention().subscribe();
    }
  }

  /**
   * Sends a http request to GET either a specific interventions or a list of all of them
   *
   * @param {string} term: specific intervention to retrieve or null to get a list of all interventions
   * @returns {Observable<any>} Observable
   */
  getIntervention(term: string = ''): Observable<any> {
    let url = this.api + 'interventions';

    if (term) {
      url += '?term=' + String(term);
    }
    return this.http.get<any>(url).pipe(
      tap((result) => {
        if (!term) {
          this.interventionsListInitialized = true;
          // cache the list and trigger the interventions$ observable
          // so that all subscribers get the updated list
          this.interventions.next(result);
        }
      }),
    );
  }

  /**
   * Sends a http request to create/update an intervention's information
   *
   * @param {any} intervention - object representing an intervention
   * @return {Observable<any>} - updated intervention object (on success)
   */
  saveIntervention(intervention: any): Observable<any> {
    const url = this.api + 'interventions';
    // TODO: fix nested observables
    return this.http
      .put<any>(url, intervention ? intervention : {}, {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
      })
      .pipe(
        tap(() => {
          // update the cached intervention in case someone else is working on interventions
          this.getIntervention().subscribe();
        }),
      );
  }

  /**
   * Sends http request to delete an intervention and updates cache
   *
   * @param {number} term - term of the intervention to delete
   * @return {Observable<any>} - an observable
   */
  deleteIntervention(term: string): Observable<any> {
    const url = this.api + 'interventions?term=' + term;
    // TODO: fix nested observables
    return this.http.delete<any>(url).pipe(
      tap(() => {
        const interventionsList = this.interventions.getValue();

        for (let i = 0; i < interventionsList.length; i++) {
          if (term === interventionsList[i].term) {
            interventionsList.splice(i, 1);
            this.interventions.next(interventionsList);
            break;
          }
        }

        this.getIntervention().subscribe();
      }),
    );
  }
}
