<div class="row">
  <mat-toolbar>
    <h4 class="gray7">DATA VALIDATION</h4>
  </mat-toolbar>
</div>
<br />

<timeline
  #timeline_component
  [projid]="projectId"
  [invalidID]="isIdInvalid"
  [(projsym)]="project.projsym"
  [(title)]="project.title"
  [project]="project"
  (clickExternalLink)="state_buttons_component.openExternalUrlAfterSave($event)"
  (clickPageLink)="state_buttons_component.openPageLinkAfterSave($event)"
  (clickReleaseProject)="state_buttons_component.releaseProject()"
  (clickReimportProject)="state_buttons_component.reimportProject()"
  (clickChangeStatus)="state_buttons_component.changeProjectStatus($event)"
  (clickImportExport)="state_buttons_component.onClickProjectImportExport(timeline_component.projectActionsComponent)"
  (projectChanged)="pullProjectData()"
  (projectRestored)="state_buttons_component.projectRestored()"
  [actionLoadingBar]="state_buttons_component.loadingBar"
></timeline>

<br /><br />
<hr style="width: 90%" />

<div [style.display]="isIdInvalid ? 'none' : 'block'">
  <div class="row bg-white">
    <div class="col-md-4 bg-white">
      <h4 class="dark-cerulean">QC Summary</h4>
      <div class="bg-dark-cerulean padding-5">
        <button
          class="margin-top-bottom-2 white btn btn-default bg-dark-cerulean"
          [routerLink]="['/project/data-information/details', projectId]"
          *ngIf="importMsgs.length > 0"
        >
          View Details
          <mat-icon class="v-bottom" inline="true"> arrow_forward </mat-icon>
        </button>
      </div>
      <div class="text11 w-100 padding-5">
        <table class="table" *ngIf="importMsgs.length > 0">
          <thead>
            <tr>
              <td><b>Level</b></td>
              <td class="text-center"><b>Count</b></td>
            </tr>
            <tr *ngFor="let elm of summary | keyvalue" class="padding-5">
              <td>{{ elm.key | titlecase }}</td>
              <td class="text-center">{{ elm.value }}</td>
            </tr>
          </thead>
        </table>
      </div>
    </div>

    <div class="col-md-4 bg-white">
      <h4 class="dark-cerulean">Technical QC</h4>
      <div class="bg-dark-cerulean padding-5">
        <button
          class="margin-top-bottom-2 white btn btn-default bg-dark-cerulean"
          [routerLink]="['/project/technical-qc/details', projectId]"
        >
          View Details<mat-icon class="v-bottom" inline="true"> arrow_forward </mat-icon>
        </button>
      </div>
      <div class="w-100 text11 padding-5 bg-white">
        <h4>Technical Review and Visualization of the Data:</h4>
        <p>- flag and report data points that appear erroneous upon visual inspection</p>
        <p>- identify technical failures due to an issue that occurred or skipped attention during data capture</p>
        <p>- visually inspect for impossible values (gaps, values out of range)</p>

        <ul>
          <li>
            use the <b>strain-survey view</b> to QC <b>cross-sectional</b> data using standard box and whisker summary
            per group
          </li>
          <li>use the <b>time-series view</b> to QC <b>time-course</b> data</li>
        </ul>
      </div>
    </div>

    <div class="col-md-4 bg-white">
      <h4 class="dark-cerulean">Statistical QC</h4>
      <div class="bg-dark-cerulean padding-5">
        <button
          class="margin-top-bottom-2 white btn btn-default bg-dark-cerulean"
          [routerLink]="['/project/statistical-qc/details', projectId]"
        >
          View Details<mat-icon class="v-bottom" inline="true"> arrow_forward </mat-icon>
        </button>
      </div>
      <div class="w-100 text11 padding-5 bg-white">
        <h4>Statistical Review and Visualization of the Data:</h4>
        <p>- flag and report data points highlighted as outliers using robust statistical modeling</p>

        <p>- examine statistical outliers to determine remaining technical QC failure data points</p>
        <ul>
          <li>univariate assessment considers parameters in isolation</li>
          <li>
            multivariate analysis assesses trends in related measures and may result in the whole mouse being excluded
            (all measures)
          </li>
          <li>
            when appropriate, longitudinal analysis assesses the parameter per mouse and per strain to identify
            measurements that substantially deviate from a priori thresholds
          </li>
        </ul>
        <h3>Work is still In Progress</h3>
      </div>
    </div>
  </div>

  <br />

  <div class="row">
    <state-buttons
      #state_buttons_component
      [(project)]="project"
      [(dbProject)]="dbProject"
      [currentPage]="'/project/qc-dashboard/'"
      [nextPage]="'/curator-only/'"
      (saveFail)="timeline_component.setActive()"
      (clickTitleLink)="timeline_component.titleInput.nativeElement.focus()"
    ></state-buttons>
  </div>
</div>
