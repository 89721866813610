<div class="row">
  <div class="text-center col-md-12">
    <h4 class="gray7">IMPORT PROJECT</h4>
    <br />
  </div>
</div>

<div class="row">
  <div class="text-center col-md-12">
    <button
      class="btn btn-default"
      [routerLink]="['/proj-details']"
      title="Create a New Project"
      style="margin: 5px"
      [disabled]="isInProgress"
    >
      <span class="dark-cerulean">Create Project</span>
    </button>
    <button
      class="btn btn-default"
      [routerLink]="['/project/import']"
      title="Import an Existing Project"
      style="margin: 5px"
      [disabled]="true"
    >
      <span class="dark-cerulean">Import Project</span>
    </button>
  </div>
</div>

<div class="row">
  <div class="text-center col-sm-offset-1 col-sm-10 col-md-offset-3 col-md-6">
    <br />
    <br />
    <p class="well well-sm">
      To attempt an import from another, connected to the Study Intake Platform, system
      <br />
      select the associated group and input a vaild record ID from the remote system
    </p>
  </div>
</div>

<div class="row">
  <div class="text-center col-sm-offset-1 col-sm-10 col-md-offset-3 col-md-6">
    <br />
    <div class="form-horizontal">
      <div class="form-group">
        <label for="import-group-selector" class="control-label col-sm-2">Group</label>
        <div class="col-sm-8">
          <select
            id="import-group-selector"
            class="form-control"
            [disabled]="isInProgress"
            (change)="onGroupChange($event.target.value)"
            name="import-group-selector"
          >
            <option value="" disabled>-- select group --</option>
            <option *ngFor="let group of groups" value="{{ group.id }}">
              {{ group.group }}
            </option>
          </select>
        </div>
      </div>

      <!-- this is visible only when an option from above is selected -->
      <div class="form-group" *ngIf="selected['name'] !== ''">
        <div class="col-sm-offset-2 col-sm-8 text-left">
          <small><b>imports from:</b></small> {{ selected.resource }}
        </div>
      </div>

      <div *ngIf="this.selected.service !== 'BioConnect'" class="form-group">
        <label for="project-id-input" class="control-label col-sm-2">ID</label>
        <div class="col-sm-8">
          <input
            id="project-id-input"
            type="text"
            class="form-control"
            [disabled]="isInProgress"
            [(ngModel)]="requestId"
            placeholder="e.g. CBA120"
          />
        </div>
      </div>

      <div [style.display]="this.selected.service === 'BioConnect' ? 'block' : 'none'" class="form-group">
        <label class="control-label col-sm-2">Study</label>
        <div class="col-sm-8">
          <bioconnect-study-search
            [disabled]="isInProgress"
            [(study)]="selectedStudy"
            (studyChange)="onStudyChange()"
          ></bioconnect-study-search>
        </div>
      </div>

      <div class="form-group">
        <button
          class="btn btn-default"
          style="margin: 5px"
          (click)="initiateImport()"
          [disabled]="isInProgress || !canImport()"
        >
          Import
        </button>

        <button class="btn btn-default" style="margin: 5px" (click)="cancelImport()" [disabled]="!isInProgress">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>

<!-- The section below becomes visible when an import operation begins; If the
  import completes successfuly, a messegae is displayed and the user is redirected. -->
<div id="import-progress-status" class="row">
  <br />
  <div
    id="import-progress-bar"
    class="text-center col-sm-offset-1 col-sm-10 col-md-offset-3 col-md-6"
    *ngIf="isInProgress"
  >
    <b>Importing...</b>
    <br />
    <br />
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</div>
<div class="row">
  <div id="import-status-msgs" class="text-center col-sm-offset-1 col-sm-10 col-md-offset-3 col-md-6 well well-sm">
    <div *ngIf="status.message" class="dark-cerulean text11 text-center">
      Importing couldn't complete successfully..
      <br /><br />
      {{ response.message }}
    </div>

    <div *ngIf="status.completed" class="dark-cerulean text11 text-center">
      The import has successfuly been completed.
      <br /><br />
      You will shortly be redirected to proceed with the next step - check the imported data.
    </div>

    <div *ngIf="status.cancelled" class="dark-cerulean text11 text-center">
      The import has been successfuly cancelled.
      <br /><br />
      Thank you.
    </div>

    <div *ngIf="status.importing" class="dark-cerulean text11 text-center">
      Importing in progress.. once the import completes,
      <br /><br />
      additional information will be avaialable in the QC dashboard panel (check step 5).
    </div>

    <div *ngIf="alreadyExists">
      Request with ID - {{ requestId }} - has already been imported into the Study Intake Platform. <br /><br />
      On the <a role="button" [routerLink]="['/definitions', projectId]">project page</a> you can look at the data (and
      reload).
    </div>
  </div>
</div>
<br />
