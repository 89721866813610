<mat-toolbar>
  <h2 class="text-right">Procedures</h2>
</mat-toolbar>
<br />

<timeline
  #timeline_component
  [projid]="projid"
  [invalidID]="invalidID"
  [loading]="loadingProject"
  [(projsym)]="project.projsym"
  [(title)]="project.title"
  [project]="project"
  (clickExternalLink)="state_buttons_component.openExternalUrlAfterSave($event)"
  (clickPageLink)="state_buttons_component.openPageLinkAfterSave($event)"
  (clickReleaseProject)="state_buttons_component.releaseProject()"
  (clickReimportProject)="state_buttons_component.reimportProject()"
  (clickChangeStatus)="state_buttons_component.changeProjectStatus($event)"
  (clickImportExport)="state_buttons_component.onClickProjectImportExport(timeline_component.projectActionsComponent)"
  (projectChanged)="getProject()"
  (projectRestored)="state_buttons_component.projectRestored()"
  [actionLoadingBar]="state_buttons_component.loadingBar"
></timeline>

<div [style.display]="invalidID || loadingProject ? 'none' : 'block'">
  <mat-vertical-stepper #proc_stepper>
    <mat-step>
      <ng-template matStepLabel>
        <div class="form-horizontal">
          <div class="form-group" style="margin-bottom: 0">
            <div class="col-sm-9" style="margin-top: 5px">
              <span>General Information for All Procedures</span>
            </div>
            <div class="col-sm-3">
              <span
                *ngIf="project.canEdit"
                class="proc-icon proc-add noselect"
                matTooltip="Add new procedure"
                (click)="addProcedure()"
              >
                <span class="glyphicon glyphicon-plus"></span>
                <span style="font-size: 12px">Add Procedure</span>
              </span>
            </div>
          </div>
        </div>
      </ng-template>
      <fieldset [disabled]="!project.canEdit">
        <br />
        <div class="form-horizontal">
          <div class="form-group">
            <label for="general-notes" class="col-sm-3 control-label">General Information</label>
            <div class="col-sm-8">
              <textarea
                rows="5"
                id="general-notes"
                class="form-control"
                name="general-notes"
                [(ngModel)]="project.projdoc.procedurenotes"
                maxlength="1000"
              ></textarea>
            </div>
          </div>
          <div class="form-group">
            <label for="proj-reference-pub" class="col-sm-3 control-label">
              Reference Publications
              <img
                *ngIf="project.bioconnect_id"
                src="./assets/bioconnect_icon.ico"
                alt=""
                [matTooltip]="
                  'Controlled by linked Bioconnect Study: ' +
                  project.bioconnect_identifier +
                  ' (Locked down as blank, since BioConnect Publications are put into Primary' +
                  ' Publications on the Project Details page)'
                "
              />
            </label>
            <div class="col-sm-8">
              <div class="input-group">
                <input
                  type="text"
                  [(ngModel)]="project.referencepublications"
                  [disabled]="project.bioconnect_id"
                  id="proj-reference-pub"
                  autocomplete="off"
                  class="form-control"
                  name="proj-reference-pub"
                  maxlength="255"
                  (change)="pubsChange('reference')"
                  required
                  placeholder="enter comma separated reference PubMed IDs"
                />
                <div class="input-group-btn">
                  <button
                    (click)="openPubDialog('reference')"
                    type="button"
                    class="btn btn-primary"
                    matTooltip="Lookup or Create Publication"
                  >
                    <span class="glyphicon glyphicon-search"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="project.referencepublicationsinfo ? project.referencepublicationsinfo.length > 0 : false"
            class="form-group"
          >
            <label class="col-sm-3 control-label preview-label">Reference Publications Preview</label>
            <div class="col-sm-8">
              <pub-display *ngFor="let pubinfo of project.referencepublicationsinfo" [pubinfo]="pubinfo"></pub-display>
            </div>
          </div>
        </div>
        <br />
      </fieldset>
      <div class="form-horizontal">
        <div class="form-group">
          <div class="col-sm-4 col-md-5"></div>
          <div class="col-sm-3 col-md-2">
            <button class="btn btn-info btn-block" matStepperNext id="step-1-next"><strong>Next</strong></button>
          </div>
        </div>
      </div>
    </mat-step>
    <mat-step *ngFor="let procedure of project.procedures; let i = index">
      <ng-template matStepLabel>
        <div class="form-horizontal">
          <div class="form-group" style="margin-bottom: 0">
            <div class="col-sm-9" style="margin-top: 5px">
              <span *ngIf="project.procedures[i].bc_assay_id">
                <img
                  src="./assets/bioconnect_icon.ico"
                  alt=""
                  [matTooltip]="'Linked to Bioconnect Assay: ' + project.procedures[i].bc_assay_identifier"
                />
                <span style="vertical-align: middle">{{ project.procedures[i].bc_assay_identifier + ': ' }}</span>
              </span>
              <span>
                {{ 'Procedure ' + (i + 1) + (project.procedures[i].title ? ': ' + project.procedures[i].title : '') }}
              </span>
            </div>
            <div class="col-sm-3" *ngIf="project.canEdit">
              <span
                matTooltip="Remove procedure"
                (click)="removeProc(i); proc_stepper.reset()"
                class="proc-icon proc-remove glyphicon glyphicon-remove"
              ></span>
              <span
                matTooltip="Move procedure up"
                (click)="swapProcedures(i, i - 1)"
                class="proc-icon proc-up glyphicon glyphicon-arrow-up"
              ></span>
              <span
                matTooltip="Move procedure down"
                (click)="swapProcedures(i, i + 1)"
                class="proc-icon proc-down glyphicon glyphicon-arrow-down"
              ></span>
            </div>
          </div>
        </div>
      </ng-template>
      <edit-procedure
        [(procedure)]="project.procedures[i]"
        [disabled]="!project.canEdit"
        [embed]="true"
        [projectEmbed]="true"
        [bioconnectStudyId]="project.bioconnect_id"
        [projectProcedures]="project.procedures"
        [i]="i"
        [isCurator]="project.isCurator"
        (saveTemplate)="saveProcedureAsTemplate($event)"
      ></edit-procedure>
      <br />
      <div class="form-horizontal">
        <div class="form-group">
          <div
            [class]="
              'col-sm-' +
              (i + 1 === project.procedures.length ? '4' : '3') +
              ' col-md-' +
              (i + 1 === project.procedures.length ? '5' : '4')
            "
          ></div>
          <div class="col-sm-3 col-md-2">
            <button class="btn btn-info btn-block" matStepperPrevious [id]="'step-' + (i + 1 + 1) + '-prev'">
              <strong>Previous</strong>
            </button>
          </div>
          <div *ngIf="i + 1 !== project.procedures.length" class="col-sm-3 col-md-2">
            <button class="btn btn-info btn-block" matStepperNext [id]="'step-' + (i + 1 + 1) + '-next'">
              <strong>Next</strong>
            </button>
          </div>
        </div>
      </div>
    </mat-step>
  </mat-vertical-stepper>
  <br />
  <br />
  <state-buttons
    [(project)]="project"
    [(dbProject)]="dbProject"
    #state_buttons_component
    (clickTitleLink)="timeline_component.titleInput.nativeElement.focus()"
    (saveFail)="timeline_component.setActive()"
    [currentPage]="'/procedures/'"
    [nextPage]="'/definitions/'"
  ></state-buttons>
</div>
