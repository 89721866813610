import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class PublicationService {
  // api url
  private api: string = environment.securedURLs.sip;

  // publication behavioursubject for building an observable
  public publications = new BehaviorSubject<Publication[]>([]);
  // observable, which can be subscribed to in order to get changes to the publication list any time it is updated
  public publications$ = this.publications.asObservable();

  // declare http client
  constructor(private http: HttpClient) {
    this.getPublications().subscribe();
  }

  /**
   * Returns all known publications
   */
  getPublications(): Observable<Publication[]> {
    return this.http.get<Publication[]>(`${this.api}publications`).pipe(
      tap((publications) => {
        this.publications.next(publications);
      }),
    );
  }

  /**
   * Returns the publication associated with the specified PMID if if forceRefresh is true, updates publication data
   * in the database
   * @param {string} pmid: pubmed id for specific publication to retrieve or null to get a list of all publications
   * @param {boolean} forceRefresh: true to update data from pubmed (doesn't stay updated unless the user hits save)
   */
  getPublication(pmid: string, forceRefresh: boolean = false): Observable<Publication> {
    let url = this.api + 'publications';

    if (pmid) {
      url += '?pmid=' + pmid;
      if (forceRefresh) {
        url += '&force_refresh=true';
      }
    }

    return this.http.get<Publication>(url).pipe(
      tap((result) => {
        if (!result.error) {
          // for publications, a GET can actually create a new publication if it was a new pmid, so
          // update the cache with the new publication if it's not in the initialized list
          const publicationsList = this.publications.getValue();
          let publicationFound = false;
          for (let i = 0; i < publicationsList.length; i++) {
            if (result.pmid === publicationsList[i].pmid) {
              publicationFound = true;
              break;
            }
          }
          if (!publicationFound) {
            publicationsList.push(result);
            this.publications.next(publicationsList);
          }
        }
      }),
    );
  }

  /**
   * Sends http request to create/update a publication's information
   *
   * @param {Publication} publication - an object representing a publication
   * @returns {Observable<Publication>} - result is the updated publication object
   *     on success (not all changes sent may have been allowed)
   */
  savePublication(publication: Publication): Observable<Publication> {
    const url = this.api + 'publications';
    return this.http
      .put<any>(url, publication ? publication : {}, {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
      })
      .pipe(
        tap((result) => {
          // update the cached publications in case someone else is working on publications
          this.getPublications().subscribe();
          // keeping the manual update because getting the whole list of publications is pretty slow
          const publicationsList = this.publications.getValue();
          let publicationFound = false;
          for (let i = 0; i < publicationsList.length; i++) {
            if (result.pmid === publicationsList[i].pmid) {
              publicationFound = true;
              publicationsList[i] = result;
              break;
            }
          }
          if (!publicationFound) {
            publicationsList.push(result);
          }
          this.publications.next(publicationsList);
        }),
      );
  }

  /**
   * Sends http request to delete a publication and updates cache
   *
   * @param {number} pmid - PubMed id of the publication to delete
   * @returns {Observable<any>}: Result is a 204 code on success
   */
  deletePublication(pmid: string): Observable<any> {
    const url = this.api + 'publications?pmid=' + pmid;

    return this.http.delete<any>(url).pipe(
      tap(() => {
        // make extra sure the deleted publication gets removed from the cached list
        // note: leaving this manual removal in here because there is a race
        // condition on updating the search list (which isn't an issue for the save)
        this.publications.next(this.publications.getValue().filter((pub) => pub.pmid !== pmid));

        // also update the cached publications in case someone else is working on publications
        this.getPublications().subscribe();
      }),
    );
  }
}

// defining the structure of data received from the API
/* eslint-disable camelcase */
export interface Publication {
  annotator: string;
  authorlist: string;
  capreason: string;
  journal: string;
  ournotes: string;
  pmcid: string;
  pmid: string;
  title: string;
  updatedtime: string;
  user_creator: any;
  year: number;
  show_pmid?: boolean;
  error?: string;
}
/* eslint-enable camelcase */
