import { Component, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
// services
import { StrainService } from '../../services/strain.service';
import { PersonService } from '../../services/person.service';
import { StrainDialogComponent } from '../dialogs/strain-dialog.component';
import { environment } from '../../../environments/environment';
import { FormControl } from '@angular/forms';
import { buildOpenDialogTooltip } from '../utils';

@Component({
  selector: 'strain-search',
  template: `
    <!-- using fieldset as a wrapper to make disabling work (it works differently...and not as well...for
    inputs with the formControl attribute, but a wrapping fieldset seems to work fine)-->
    <div style="height:100%;" [class]="optionSelected() || showDialog ? 'input-group' : ''">
      <fieldset [disabled]="optionSelected() || disabled">
        <span [matTooltip]="inputTooltip()">
          <span
            *ngIf="optionSelected()"
            class="form-control left-bord-rad-4px"
            [innerHtml]="strainDisplayHtml(strain)"
            style="height:auto;overflow-wrap:anywhere;"
          ></span>
          <input
            *ngIf="!optionSelected()"
            placeholder="Begin typing a strain name"
            class="form-control left-bord-rad-4px"
            autocomplete="off"
            [matAutocomplete]="auto"
            [formControl]="valueCtrl"
            (focus)="onFocus()"
            (focusout)="inputFocused = false"
            [style.border-color]="inputNoSelection() ? 'red' : ''"
          />
        </span>
        <span class="form-control-feedback">
          <span
            *ngIf="optionSelected() && !optionNotApproved(strain)"
            style="color:green;"
            [style.right]="showDialog ? '80px' : '40px'"
            class="glyphicon glyphicon-ok"
          ></span>
          <span
            *ngIf="optionNotApproved(strain)"
            style="color:#cccc00;"
            [style.right]="showDialog ? '80px' : '40px'"
            class="glyphicon glyphicon-flag"
          ></span>
          <span
            *ngIf="inputNoSelection()"
            style="color:red"
            [style.right]="showDialog ? '40px' : '20px'"
            class="glyphicon glyphicon-warning-sign"
          ></span>
        </span>
      </fieldset>
      <div
        *ngIf="optionSelected() || showDialog"
        class="input-group-btn"
        style="height:inherit;"
        [matTooltip]="
          !this.valueCtrl.value && !disabled
            ? 'Type in a name and if the strain is not already in the system, ' +
              'then click this button to create them'
            : ''
        "
      >
        <button
          *ngIf="showDialog"
          (click)="openEditDialog()"
          type="button"
          class="btn btn-success"
          [disabled]="!this.valueCtrl.value"
          style="height:inherit;"
          [matTooltip]="openDialogTooltip()"
        >
          <span
            style="height:inherit;display:contents;"
            [class]="'glyphicon glyphicon-' + (!optionSelected() ? 'plus' : canEdit() ? 'pencil' : 'eye-open')"
          >
          </span>
        </button>
        <button
          *ngIf="optionSelected()"
          (click)="clearSelection()"
          [disabled]="disabled"
          type="button"
          matTooltip="Clear selected strain"
          class="btn btn-danger"
          style="height:inherit;"
        >
          <span style="height:inherit;display:contents;" class="glyphicon glyphicon-remove clear-icon"></span>
        </button>
      </div>
    </div>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected()" [displayWith]="displayFn">
      <mat-option disabled *ngIf="filteredOptions.length === 0">
        <span *ngIf="strainService.strains.getValue().length !== 0" style="color:red;">
          No matches.
          <span *ngIf="!showDialog"> Please select an strain from the options. </span>
          <button *ngIf="showDialog" (click)="openEditDialog()" class="btn btn-success">
            <span class="glyphicon glyphicon-plus"></span> Create new strain
          </button>
        </span>
        <span *ngIf="strainService.strains.getValue().length === 0">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>Loading results...
        </span>
      </mat-option>
      <!-- this is a performance optimization, which allows the user to see a few matches/options,
          while telling them that more are available if they narrow down their search.
          This pretty effectively fixes the performance issues I've seen from generating too many options at once.-->
      <mat-option
        *ngFor="let option of options"
        [value]="option"
        [disabled]="disableOption(option)"
        [matTooltip]="disableOption(option) ? '(Already Selected)' : ''"
      >
        <span
          *ngIf="optionNotApproved(option)"
          style="color:#cccc00;"
          class="glyphicon glyphicon-flag"
          [matTooltip]="optionNotApprovedTooltip(option)"
        ></span>
        <div [innerHTML]="strainDisplayHtml(option)"></div>
      </mat-option>
      <mat-option
        disabled
        [style.display]="!showAllResults ? 'block' : 'none'"
        *ngIf="filteredOptions.length > 50"
        class="mat-option show-all"
      >
        <a (click)="showAllResults = true" style="cursor:pointer;">Show all results</a>
        or type more to narrow down results...
      </mat-option>
    </mat-autocomplete>
  `,
  styleUrls: ['searching.scss'],
})
export class StrainSearchComponent implements OnInit, OnChanges {
  // 2-way binding for the selected strain. This component
  // will emit the selected strain to the parent when a strain is selected, so having a variable to bind
  // to in the parent is easier than going off of an event alone.
  @Input() strain: any = { strainvendor: {}, panel: {}, straintype_dict: {} };

  // true to show the button to open a dialog to create, edit, or view strains, false to not
  @Input() showDialog = true;

  // true to disable the search box (including preventing it from being cleared out)
  @Input() disabled = false;
  // list of strains objects to exclude (compare 'id')
  @Input() disableList: any[] = [];

  // event emitter to send the selected strain to the parent when one is selected from the autocomplete options
  @Output() strainChange: EventEmitter<any> = new EventEmitter<any>();

  // api URL
  api: string = environment.securedURLs.sip;

  // true if the current user is a curator, false if not
  isCurator = false;

  // formControl for the input value
  valueCtrl: FormControl;

  // true if the input is currently focused, false otherwise
  inputFocused = false;

  // true to show all results, false to only show 6 or 7 results if the number of results is above 50
  showAllResults = false;

  // filtered autocomplete options, list of strains (any because typescript complains otherwise)
  filteredOptions: any[] = [];

  // set up the onchange observable with calls the strainService.getStrain() observable (http call)
  // to get filtered options in real time.
  constructor(
    public http: HttpClient,
    public dialog: MatDialog,
    public strainService: StrainService,
    private personService: PersonService,
  ) {
    this.valueCtrl = new FormControl();
    this.valueCtrl.valueChanges.subscribe(() => {
      let val = this.valueCtrl.value;
      val = val ? val : '';
      // Note: don't need to change filteredOptions right now if the type isn't a string
      if (typeof val === 'string') {
        this.filterValues(val);
      }
    });
  }

  // on init, do an initial load of options
  ngOnInit() {
    this.strainService.strains$.subscribe(() => {
      let val = this.valueCtrl.value;
      val = val ? val : '';
      // Note: don't need to change filteredOptions right now if the type isn't a string
      if (typeof val === 'string') {
        this.filterValues(val);
      }
    });

    this.personService.currentUser$.subscribe(() => {
      this.isCurator = this.personService.isCurator();
    });
  }

  // on change of the strain from the parent side, set the current strain to it if it's valid
  ngOnChanges() {
    if (this.strain) {
      if (this.strain.id) {
        this.valueCtrl.setValue(this.strain);
      }
    }
  }

  get options(): any[] {
    return this.filteredOptions.length > 50 && !this.showAllResults
      ? this.filteredOptions.slice(0, 7)
      : this.filteredOptions;
  }

  /**
   * Filter the available strains in the dropdown to those for which the
   * user-entered string matches the strain aname
   *
   * @param {string} newValue: user entered search string
   */
  filterValues(newValue: string) {
    this.showAllResults = false;
    if (newValue) {
      let options = this.strainService.strains.getValue();
      for (const word of newValue.split(' ')) {
        if (options.length === 0) {
          break;
        }
        options = options.filter((option) =>
          option ? this.filterByValue(option).indexOf(word.toLowerCase()) !== -1 : false,
        );
      }
      this.filteredOptions = options;
    } else {
      this.filteredOptions = this.strainService.strains.getValue();
    }
  }

  /**
   * Build the a string for the strain option, which can be used to filter user input
   * by strain (short) name ... and soon longname and stocknum
   *
   * @param {any} option: strain option dict
   */
  filterByValue(option: any) {
    const shortname = option.strainvendor.shortname || '';
    const val = option.aname + ' ' + option.longname + ' (' + shortname + ' ' + String(option.stocknum) + ')';
    return val.toLowerCase();
  }

  // return true if the current user can modify the strain, false if not
  canEdit() {
    if (this.isCurator) {
      return true;
    }
    if (!this.strain.id) {
      return true;
    }
    if (!this.strain.user_creator) {
      return false;
    }
    return this.personService.isCurrentUser(this.strain.user_creator.id);
  }

  // determines whether an option was selected
  optionSelected(): boolean {
    return this.strain && typeof this.strain === 'object' ? this.strain.id : false;
  }

  // determines whether the current user has input a value without selecting an option
  inputNoSelection(): boolean {
    return !this.optionSelected() && this.valueCtrl.value && !this.inputFocused;
  }

  // determines whether the selected option is not yet approved by a curator
  optionNotApproved(option: any): boolean {
    return option ? (option.user_creator ? option.user_creator.id : false) : false;
  }

  // builds a string describing the flag for an option that is not yet approved by a curator
  optionNotApprovedTooltip(option: any): string {
    return (
      'This strain was added by the user, ' +
      option.user_creator.name_or_email +
      ', and is pending SIP Curator approval.'
    );
  }

  // builds the tooltip for the input if there's an issue
  inputTooltip(): string {
    if (this.inputNoSelection()) {
      return 'Must select option for this field to be populated (click in field to see options)';
    } else if (this.optionNotApproved(this.strain)) {
      return this.optionNotApprovedTooltip(this.strain);
    }
    return '';
  }

  /**
   * Determine if the option for selecting this object should be disabled because it's in the disableList
   * @param {Object} option: object option
   * @returns {boolean}: true to disable this object option, false to not
   */
  disableOption(option: any) {
    for (let i = 0; i < this.disableList.length; i++) {
      if (this.disableList[i].id === option.id) {
        return true;
      }
    }
    return false;
  }

  /**
   * Builds strain display html
   *
   * @param {any} strain: strain object
   * @returns {string}: html for displaying strain info
   */
  strainDisplayHtml(strain: any): string {
    let html = strain.anamehtml;
    if (strain.longname && strain.longname !== strain.aname) {
      html += '<small> | ' + strain.longnamehtml + '</small>';
    }
    if (strain.strainvendor ? strain.strainvendor.vendorcode : false) {
      html += '<small> (' + strain.strainvendor.shortname;
      if (strain.stocknum) {
        html += ' ' + strain.stocknum;
      }
      html += ')</small>';
    }
    return html;
  }

  // if an option is selected, emit a objectChange event so that the parent knows who is selected
  onOptionSelected() {
    this.strain = this.valueCtrl.value;
    this.strainChange.emit(this.strain);
  }

  // clear currently selected object
  clearSelection() {
    this.valueCtrl.setValue('');
    this.filterValues('');
    this.strain = {};
    this.strainChange.emit(this.strain);
  }

  // upon focusing on a field, if the current value is blank and we have no options, then create them
  onFocus() {
    this.inputFocused = true;
    const value = this.valueCtrl.value;
    if ((!value || value === '') && this.filteredOptions.length === 0) {
      this.filterValues(value ? value : '');
    }
  }

  /**
   * display function for the strain object
   * @param {any} option: strain object or undefined
   * @returns {string}: strain aname
   */
  displayFn(option?: any | string): string | undefined {
    if (!option) {
      return undefined;
    }
    if (typeof option === 'object') {
      return option.aname;
    }
    return option;
  }

  // tooltip for opening the dialog for viewing/editing/creating strains
  openDialogTooltip() {
    return buildOpenDialogTooltip(this.valueCtrl.value, this.strain, 'id', this.canEdit(), 'strain');
  }

  // open a dialog to create, edit, or view a strain
  openEditDialog(): void {
    const strainCopy = JSON.parse(JSON.stringify(this.strain));
    // default in what the user typed in as the strain aname
    if (!strainCopy.id) {
      strainCopy.aname = this.valueCtrl.value;
      strainCopy.strainvendor = {};
      strainCopy.straintype_dict = {};
      strainCopy.panel = {};
    }
    const dialogRef = this.dialog.open(StrainDialogComponent, {
      data: { strain: strainCopy },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.id) {
          this.strain = result;
          this.strainChange.emit(this.strain);
        } else {
          this.clearSelection();
        }
      }
    });
  }
}
