// This file uses Highcharts and because I'm not familiar, I don't feel
// confident in resolving linting issues for it
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Component, Input, OnInit } from '@angular/core';

import * as Highcharts from 'highcharts';

// utils
import { colorCodes, doFounderColors, lineDashStyles, meanStddev, symbols, sortAlphanumeric } from '../utils';

declare let require: any;
const Boost = require('highcharts/modules/boost');
const More = require('highcharts/highcharts-more');

Boost(Highcharts);
More(Highcharts);

@Component({
  selector: 'time-series-plot',
  templateUrl: './time-series-plot.component.html',
  styleUrls: ['../data-validation.scss'],
})
export class TimeSeriesPlotComponent implements OnInit {
  // Highchart's hack to
  @Input() qcData: any[] = [];

  @Input() phenoInfoVariables: string[] = [];

  @Input() selectedMeasure: {
    datatype: string;
    id: string;
    seriesvarsuffix: string[];
    varname: string;
    units: string;
    projsym: string;
    seriestype: string;
    serieslabels: string[];
    seriesvarnames: string[];
  } = {
    datatype: '',
    id: '',
    seriesvarsuffix: [],
    varname: '',
    units: '',
    projsym: '',
    seriestype: '',
    serieslabels: [],
    seriesvarnames: [],
  };

  // Highchart's chart object
  chartF: any = null;
  chartM: any = null;

  // trait values ONLY (no other data)
  traitVals: any[] = [];

  // in case the trait data is categorical, this array will contain these categories
  sampleIDs: any[] = [];
  measureVarNames: string[];
  measSuffix: string[];

  // available formats to export the table data:
  // check valid options in TableComponent class
  exportFormats: string[] = ['xlsx', 'csv', 'txt', 'json'];

  // map between sample ID and values at time points for that id
  seriesMap: any = {};

  // dynamic subset (of the complete data set), which is updated according to the current
  // options selection and that contains just the data needed for rendering
  plotData: any[] = [];

  measureSeries: any[] = [];

  populationMeanStd: any = null;

  // data "removed" from plotting during the current session;
  // contains ids of the records to be removed from the plot
  removedData: number[] = [];
  // selected informational option
  selectedFactorOption = '--';
  selectedFactor2Option = '--';
  selectedFactorValues: string[] = [];
  isXAxisSorted = false;

  // plot option variables
  showMeanStd = false;
  seriesThresholdWarning = '';
  seriesThreshold = 1200;

  // an array with objects to polulate the visualization selection table
  selectedTableData: {
    id: string;
    projsym: string;
    measnum: number;
    sex: string;
    strain: string;
    value: number;
    date: string;
  }[] = [];

  // column definition for the table displaying selected data points
  visTableCols: any[] = [
    { key: 'id', label: 'ID', width: '4', inline_label: 'id' },
    { key: 'projsym', label: 'Symbol', width: '2', inline_label: 'projsym' },
    { key: 'measnum', label: 'Measure', width: '2', inline_label: 'measnum' },
    { key: 'sex', label: 'Sex', width: '2', inline_label: 'sex' },
    { key: 'strain', label: 'Strain', width: '2', inline_label: 'strain' },
    { key: 'y', label: 'Value', width: '2', padding: '0 2px', inline_label: 'value' },
    { key: 'date', label: 'Period', width: '2', inline_label: 'date' },
    {
      key: 'remove',
      label: 'Deselect',
      width: '1',
      click: 'remove',
      type: 'button',
      classes: 'btn btn-danger',
      safe: true,
      value: '<span class="glyphicon glyphicon-remove"></span>',
    },
  ];

  ngOnInit() {
    // inserting a line break inside HighCharts legend is not supported via API,
    // but is requirement in this plot. The below code is a 'hack' to accomplish
    // this. It adds a fake new property called 'newLine', which when found in the
    // series options will create a line break in the legend.
    // More information: https://jsfiddle.net/BlackLabel/zbcLeyat
    Highcharts.wrap(Highcharts.Legend.prototype, 'layoutItem', function (proceed, item) {
      const options = this.options,
        padding = this.padding,
        horizontal = options.layout === 'horizontal',
        itemHeight = item.itemHeight,
        itemMarginBottom = options.itemMarginBottom || 0,
        itemMarginTop = this.itemMarginTop,
        itemDistance = horizontal ? Highcharts.pick(options.itemDistance, 20) : 0,
        maxLegendWidth = this.maxLegendWidth,
        itemWidth = options.alignColumns && this.totalItemWidth > maxLegendWidth ? this.maxItemWidth : item.itemWidth;

      // If the item exceeds the width, start a new line
      if (horizontal && (this.itemX - padding + itemWidth > maxLegendWidth || item.userOptions.newLine)) {
        this.itemX = padding;
        this.itemY += itemMarginTop + this.lastLineHeight + itemMarginBottom;
        this.lastLineHeight = 0; // reset for next line (#915, #3976)
      }

      // Set the edge positions
      this.lastItemY = itemMarginTop + this.itemY + itemMarginBottom;
      this.lastLineHeight = Math.max(
        // #915
        itemHeight,
        this.lastLineHeight,
      );
      // cache the position of the newly generated or reordered items
      item._legendItemPos = [this.itemX, this.itemY];

      // advance
      if (horizontal) {
        this.itemX += itemWidth;
      } else {
        this.itemY += itemMarginTop + itemHeight + itemMarginBottom;
        this.lastLineHeight = itemHeight;
      }

      // the width of the widest item
      this.offsetWidth =
        this.widthOption ||
        Math.max(
          (horizontal
            ? this.itemX -
              padding -
              (item.checkbox
                ? // decrease by itemDistance only when no checkbox #4853
                  0
                : itemDistance)
            : itemWidth) + padding,
          this.offsetWidth,
        );
    });
  }

  /**
   * handles events changing the 'information variable' select options
   * @param {string} val - selected option value
   * @param {number} index = 1 for 'info variable 1' and 2 for 'info variable 2'
   */
  oninfoVarChange(val: string, index: number): void {
    index === 1 ? (this.selectedFactorOption = val) : (this.selectedFactor2Option = val);
    if (this.selectedFactorOption === '--') {
      this.selectedFactor2Option = '--';
    }
    this.setplotDataSeries();
    this.render();
  }

  /**
   * updates the visualization by showing/hiding the meand/SE lines
   * @param {boolean} isChecked - whether the checkbox is checked
   */
  onShowMeanStdDevChange(isChecked: boolean): void {
    this.showMeanStd = isChecked;
    this.showMeanStdDevSeries();
  }

  /**
   * updates the visualization by ordering the x-axis (time) categories
   * alphanumerically or leaving the original order in place
   * @param {any} event - object representing the triggered checkbox event
   */
  onSortAlphaNumericChange(event: any) {
    this.isXAxisSorted = event.target.checked;
    this.initVis();
    this.setplotCategories();
    this.setplotDataSeries();

    this.render();
  }

  /**
   * creats a data point object with parameters:
   * @param id - sample id
   * @param sex - sex
   * @param xAxisBin - x-axis bin value
   * @param val - y-axis value
   * @param date - x-axis tick label
   * @return {any} data point object
   */
  createDataPoint(id: string, sex: string, xAxisBin: number, val: number | string, date: string): any {
    return {
      id: id,
      sex: sex,
      projsym: this.selectedMeasure.projsym,
      measnum: this.selectedMeasure.id,
      x: xAxisBin,
      y: val,
      date: date,
      date_tooltip: date,
    };
  }

  /**
   * returns true in case the selected measure is numeric
   * @return boolean
   */
  isMeasureNumeric(): boolean {
    return this.selectedMeasure.datatype === 'numeric';
  }

  /**
   * Initial visualization setup: run some checks and data point setup
   */
  initVis(): void {
    this.plotData = [];
    this.measureVarNames = [];
    this.measSuffix = [];
    // x-axis labels
    this.measureVarNames = this.selectedMeasure.seriesvarnames;
    this.measSuffix = this.selectedMeasure.seriesvarsuffix;

    if (this.isXAxisSorted) {
      const combinedList = [];
      for (let i = 0; i < this.measureVarNames.length; i++) {
        combinedList.push({
          sortKey: this.measureVarNames[i],
          varsuffix: this.measSuffix[i],
        });
      }
      combinedList.sort(sortAlphanumeric);
      this.measureVarNames = [];
      this.measSuffix = [];
      for (let k = 0; k < combinedList.length; k++) {
        this.measureVarNames[k] = combinedList[k].sortKey;
        this.measSuffix[k] = combinedList[k].varsuffix;
      }
    }

    let numFactors = 0;
    // due to the data model's structure, series can have factors represented
    // as: ['1_1', '1_2', '1_3'], ['1_2', '1_2', '1_2'], or no factors at all.
    let isAllFactorsEqual = false;
    if (this.measSuffix.length > 0) {
      numFactors = this.measSuffix[0].split('_').length;
      isAllFactorsEqual = this.measSuffix.join(',').split(this.measSuffix[0]).length === this.measSuffix.length + 1;
    }

    // loop through all samples and sample properties
    this.qcData.forEach((sample) => {
      for (const sampleDataAttribute in sample) {
        // attributes have structure like: 'procedure__name_factor1_factor2_fac..'
        const numAttributeParts: number = sampleDataAttribute.split('_').length;
        const sampleDataAttributeNoFactors: string = sampleDataAttribute
          .split('_')
          .splice(0, numAttributeParts - numFactors)
          .join('_');
        // string like '1_14', '2_3_4'
        const attributeFactors: string = sampleDataAttribute
          .split('_')
          .splice(numAttributeParts - numFactors)
          .join('_');

        if (
          numFactors === 0 &&
          sample[sampleDataAttribute] !== null &&
          this.measureVarNames.indexOf(sampleDataAttribute) > -1
        ) {
          const xAxisBin: number = this.measureVarNames.indexOf(sampleDataAttribute);
          const dataPoint = this.createDataPoint(
            sample.animal_id,
            sample.sex,
            xAxisBin,
            sample[sampleDataAttribute],
            this.measureVarNames[xAxisBin],
          );

          // add associated informational variable values
          for (const v of this.phenoInfoVariables) {
            dataPoint[v] = sample[v] !== null ? sample[v] : 'N/A';
          }
          this.plotData.push(dataPoint);
          // values to calculate overall mean and SE
          this.traitVals.push(dataPoint.y);
        } else if (
          sampleDataAttributeNoFactors === this.selectedMeasure.varname &&
          !isAllFactorsEqual &&
          sample[sampleDataAttribute] !== null
        ) {
          const xAxisBin: number = this.measSuffix.indexOf(attributeFactors);
          const dataPoint = this.createDataPoint(
            sample.animal_id,
            sample.sex,
            xAxisBin,
            sample[sampleDataAttribute],
            this.measureVarNames[xAxisBin],
          );

          // add associated informational variable values
          for (const v of this.phenoInfoVariables) {
            const key = `${v}_${this.measSuffix[xAxisBin]}`;
            if (Object.prototype.hasOwnProperty.call(sample, key)) {
              dataPoint[v] = sample[key] !== null ? sample[key] : 'N/A';
            } else if (Object.prototype.hasOwnProperty.call(sample, v)) {
              dataPoint[v] = sample[v] !== null ? sample[v] : 'N/A';
            }
          }
          this.plotData.push(dataPoint);
          // values to calculate overall mean and SE
          this.traitVals.push(dataPoint.y);
        } else if (
          sample[sampleDataAttribute] !== null &&
          isAllFactorsEqual &&
          attributeFactors === this.measSuffix[0] &&
          this.measureVarNames.indexOf(sampleDataAttribute) > -1
        ) {
          const xAxisBin: number = this.measureVarNames.indexOf(sampleDataAttribute);
          const dataPoint = this.createDataPoint(
            sample.animal_id,
            sample.sex,
            xAxisBin,
            sample[sampleDataAttribute],
            this.measureVarNames[xAxisBin],
          );

          // add associated informational variable values
          for (const v of this.phenoInfoVariables) {
            const key = `${v}_${this.measSuffix[xAxisBin]}`;
            if (Object.prototype.hasOwnProperty.call(sample, key)) {
              dataPoint[v] = sample[key] !== null ? sample[key] : 'N/A';
            } else if (Object.prototype.hasOwnProperty.call(sample, v)) {
              dataPoint[v] = sample[v] !== null ? sample[v] : 'N/A';
            }
          }
          this.plotData.push(dataPoint);
          // values to calculate overall mean and SE
          this.traitVals.push(dataPoint.y);
        }
      }
    });
  }

  /**
   * calls the method to calculate the mean and standard error, and
   * stores the values into a global component property for use as needed
   */
  setpopulationMeanStdDev(): void {
    this.populationMeanStd = this.isMeasureNumeric() ? meanStddev(this.traitVals) : null;
  }

  /**
   * determines the brushed data points and updates the
   * data table accordingly to display only this brushed data
   * @param {any[]} coords - an array containing brush coordinates on the plot canvas
   * @param {string} sex - either "F" or "M"
   */
  brushUpdated(coords: any[], sex: string) {
    for (let i = 0; i < this.plotData.length; i++) {
      const date = this.plotData[i].x;
      if (this.isMeasureNumeric()) {
        if (
          date < coords[0][1] &&
          date > coords[0][0] &&
          this.plotData[i].y < coords[1][1] &&
          this.plotData[i].y > coords[1][0] &&
          this.plotData[i].sex === sex
        ) {
          if (
            this.selectedTableData.filter((o) => o.id === this.plotData[i].id && o.date === this.plotData[i].date)
              .length < 1
          ) {
            this.selectedTableData.push(this.plotData[i]);
          }
        }
      } else if (!this.isMeasureNumeric()) {
        const index = this.sampleIDs.indexOf(this.plotData[i][this.selectedMeasure.varname]);
        // if that category exists
        if (index > -1) {
          if (date < coords[0][1] && date > coords[0][0] && index < coords[1][1] && index > coords[1][0]) {
            if (
              this.selectedTableData.filter((o) => o.id === this.plotData[i].id && o.date === this.plotData[i].date)
                .length < 1
            ) {
              this.selectedTableData.push(this.plotData[i]);
            }
          }
        }
      }
    }
    this.updateTable();
  }

  /*
   * Depending on the checkbox value, sets the series data
   * needed to display the mean and +/- 1 SD lines
   */
  showMeanStdDevSeries(): void {
    for (const sex of ['female', 'male']) {
      const firstChar = sex.slice(0, 1).toUpperCase();

      const chart: any = firstChar === 'F' ? this.chartF : this.chartM;

      if (this.showMeanStd) {
        const lineXCoords = [
          this.populationMeanStd.mean - this.populationMeanStd.stdDev,
          this.populationMeanStd.mean,
          this.populationMeanStd.mean + this.populationMeanStd.stdDev,
        ];
        const n = this.measureVarNames.length;
        chart.series[0].setData(Array(n).fill(lineXCoords[0]));
        chart.series[1].setData(Array(n).fill(lineXCoords[1]));
        chart.series[2].setData(Array(n).fill(lineXCoords[2]));
      } else {
        chart.series[0].setData([]);
        chart.series[1].setData([]);
        chart.series[2].setData([]);
      }
    }
  }

  /**
   * updates the table's data source based on the current points selection
   */
  updateTable(): void {
    this.selectedTableData = [...this.selectedTableData];
  }

  /**
   * deselects the point with the same ID as the argument
   * @param {any} tableRow - table row object
   */
  deselectPoint(tableRow: any): void {
    const points = tableRow.sex === 'f' ? this.chartF.getSelectedPoints() : this.chartM.getSelectedPoints();
    for (let i = 0; i < points.length; i++) {
      if (points[i].id === tableRow.id && points[i].date === tableRow.date) {
        points[i].select(false, true);
        for (let j = 0; j < this.selectedTableData.length; j++) {
          if (this.selectedTableData[j].id === tableRow.id && this.selectedTableData[j].date === tableRow.date) {
            this.selectedTableData.splice(j, 1);
            break;
          }
        }
      }
    }
    this.updateTable();
  }

  /**
   * selected informational variables have varied values -
   * "CAST/EiJ", "NZO/HlLtJ", "C57BL/6J", etc.
   * this function identifies and returns these values
   * @param {string} selectedVariable - selected variable name
   * @return {string[]} factorVals - an array with values
   */
  getinfoVariableValues(selectedVariable: string): string[] {
    const variableVals: string[] = [];

    if (selectedVariable !== '--') {
      this.plotData.forEach((d) => {
        const val = d[selectedVariable] !== null ? d[selectedVariable] : 'N/A';

        if (variableVals.indexOf(val) < 0) {
          variableVals.push(val);
        }
      });
    }
    return variableVals;
  }

  /**
   * in Highcharts, the legend is tied to the data series names, which means
   * that each data series will have its own legend item; however,
   * this behavior is undesired in this visualizaton, so custom legend
   * labels need to be created separately
   * @param {string[]} infoVarVals - an array containing values
   * @param {string} visType - legend item could be either 'color' or 'symbol'-based
   */
  createLegendSeries(infoVarVals: string[], visType: string) {
    let colorIndex = 0;

    for (const i in infoVarVals) {
      let color = '';
      let symbol = '';
      let dashLine = '';
      let type = 'scatter';
      let newLine = false;

      if (visType === 'color') {
        // DO strains use predefined colors - when strain has been selected
        // as the factor, check and assign the proper colors to the DO strains
        if (this.selectedFactorOption === 'strain') {
          color = doFounderColors[infoVarVals[i]] || colorCodes[colorIndex % colorCodes.length];
        } else {
          color = colorCodes[colorIndex % colorCodes.length];
        }

        symbol = symbols[0];
        // dashLine = lineDashStyles[0];
        newLine = Number(i) < 1;
        if (this.selectedFactorOption === 'strain' && !doFounderColors[infoVarVals[i]]) {
          colorIndex = colorIndex + 1;
        } else if (this.selectedFactorOption !== 'strain') {
          colorIndex = colorIndex + 1;
        }
      } else if (visType === 'symbol') {
        color = '#000000';
        symbol = symbols[colorIndex];
        // I don't know what this does so I'm not going to screw with it
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        dashLine = lineDashStyles[colorIndex];
        colorIndex = colorIndex + 1;
        type = 'spline';
      }

      // this series will show in the legend, but has no data and
      // thus will not have any display in the plot data area
      this.measureSeries.push({
        name: infoVarVals[i],
        type: type,
        color: color,
        dashStyle: dashLine,
        data: [],
        marker: {
          symbol: symbol,
          lineColor: '#000000',
          lineWidth: 0,
        },
        newLine: newLine,
      });
    }
  }

  /**
   * creates the data series, which plot the mean and standard error lines
   */
  createMeanStdDevSeries() {
    // line series are used to plot the (mean-1sd, mean, mean+1sd) values
    for (let i = 0; i < 3; i++) {
      this.measureSeries.push({
        name: 'mean_' + i,
        color: '#181818',
        data: [],
        dashStyle: 'Dash',
        enableMouseTracking: false,
        type: 'line',
        lineWidth: 1,
        marker: {
          enabled: false,
          states: {
            hover: { enabled: false },
          },
        },
        states: {
          hover: { enabled: false },
          select: { enabled: false },
        },
        showInLegend: false,
      });
    }
  }

  /**
   * sets the series with the appropriate properties and data to be displayed
   * on the plot based on the selected plot options
   */
  setplotDataSeries() {
    // cleanup component variables
    this.measureSeries = [];
    for (const index in this.seriesMap) {
      this.seriesMap[index].data = [];
    }

    this.createMeanStdDevSeries();

    const factor1Vals = this.getinfoVariableValues(this.selectedFactorOption);
    const factor2Vals = this.getinfoVariableValues(this.selectedFactor2Option);

    if (this.selectedFactor2Option !== '--') {
      this.createLegendSeries(factor2Vals, 'symbol');
    }

    if (this.selectedFactorOption !== '--') {
      this.createLegendSeries(factor1Vals, 'color');
    }

    // each data point is added as a serie, for which the different time measurements
    // are the data values - x-axis is date and y-axis is measurement at that date
    this.plotData.forEach((d) => {
      const sampleIdSex = d.id + '(' + d.sex.toUpperCase() + ')';

      // if set, first factor select option set the appropriate color coding
      if (this.selectedFactorOption !== '--') {
        let colorIndex = 0;

        for (const i in factor1Vals) {
          if (d[this.selectedFactorOption] === factor1Vals[i]) {
            this.seriesMap[sampleIdSex].color =
              this.selectedFactorOption === 'strain'
                ? doFounderColors[factor1Vals[i]]
                  ? doFounderColors[factor1Vals[i]]
                  : colorCodes[colorIndex % colorCodes.length]
                : colorCodes[colorIndex % colorCodes.length];
          }

          if (this.selectedFactorOption === 'strain' && !doFounderColors[factor1Vals[i]]) {
            colorIndex = colorIndex + 1;
          } else if (this.selectedFactorOption !== 'strain') {
            colorIndex = colorIndex + 1;
          }
        }
      }
      // if set, second factor select option sets symbols
      if (this.selectedFactor2Option !== '--') {
        for (const i in factor2Vals) {
          if (d[this.selectedFactor2Option] === factor2Vals[i]) {
            this.seriesMap[sampleIdSex].marker.symbol = symbols[i];
            this.seriesMap[sampleIdSex].dashStyle = lineDashStyles[i];
          }
        }
      }

      this.seriesMap[sampleIdSex].data.push(d);
    });

    // fill in the data into the series
    for (const k of Object.keys(this.seriesMap)) {
      // sort the data points
      this.seriesMap[k].data.sort(function (a: any, b: any) {
        return a.x - b.x;
      });
      this.measureSeries.push(this.seriesMap[k]);
    }
  }

  /**
   * loop through and categorize all data points per sample - each sample
   * is an individual category with all time points in that category
   */
  setplotCategories(): void {
    let len = this.plotData.length;
    while (len--) {
      if (this.sampleIDs.indexOf(this.plotData[len].id) < 0) {
        this.sampleIDs.push(this.plotData[len].id);

        const strain = this.plotData[len].strain;
        const sex = this.plotData[len].sex.toUpperCase();
        // looks like '3984_0(F)', which is sample id and sex
        const key = this.plotData[len].id + '(' + sex + ')';
        // each animal has a line representing its measurements at time points;
        // seriesMap is a quick lookup to all the information and measurements
        // for a particular sample/animal
        this.seriesMap[key] = {
          name: key,
          custom: {
            strain: strain,
          },
          color: '#D3D3D3',
          doColor: doFounderColors[strain] ? doFounderColors[strain] : null,
          data: [],
          marker: {
            symbol: 'circle',
          },
          showInLegend: false,
        };
      }
    }
  }

  /**
   * handles legend items clicks, which should set series visibility
   * @param {object} chart - chart object
   * @param {object[]} series - all series in this chart
   * @param {boolean} show - to show or hide the series
   * @param {string} label - clicked legend item label
   * @param {string[]} hiddenLegendItems - an array containing legend items marked as hidden
   */
  setseriesVisibility(chart: any, series: any[], show: boolean, label: string, hiddenLegendItems: string[]): void {
    // Working with Highcharts - not familiar so allowing this for now
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    series.forEach(function (s, i) {
      const n = s.name;
      // regex to match series names ending with '(F)' or '(M)'
      const e = /\([M|F]\)$/;

      if (n.match(e)) {
        const serieData = s.data[0];
        // informational variable values
        const valInfoVar1 = serieData[that.selectedFactorOption];
        const valInfoVar2 = serieData[that.selectedFactor2Option];

        if (valInfoVar1 === label || valInfoVar2 === label) {
          if (show) {
            // in case two informational variables are selected, it is possible that the series should
            // still remain hidden depending whether the other legend is still in non-visible state
            if (hiddenLegendItems.indexOf(valInfoVar1) < 0 && hiddenLegendItems.indexOf(valInfoVar2) < 0) {
              chart.series[i].update({ visible: true });
            }
          } else {
            chart.series[i].update({ visible: false });
          }
        }
      }
    });
  }

  /**
   * based on the selected plot options, instantiates an object with all
   * necessary parameters and values to build and renter a visualization;
   * also binds add-ons to the plot building object
   */
  render() {
    // Working with Highcharts - not familiar so allowing this for now
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;

    for (const sex of ['female', 'male']) {
      let chart: any = {};
      const hiddenLegendItems: string[] = [];
      const abbrevSex = sex.slice(0, 1).toUpperCase();
      let selectionEvent = false;

      const series: any[] = [];
      // append measure series
      this.measureSeries.forEach((s) => {
        const sexRegExp = /\((M|F)\)$/;
        const sex: string = sexRegExp.test(s.name) ? s.name.slice(-2, -1) : '';
        // some series are general and apply to both sexes, and some are sex specific
        if ((sex !== 'F' && sex !== 'M') || abbrevSex === sex) {
          series.push(s);
        }
      });

      if (Object.keys(that.seriesMap).length > that.seriesThreshold) {
        that.seriesThresholdWarning =
          'this dataset is large and certain functionalities, ' +
          'such as brushing and table population might not work well. Reducing the ' +
          'data set size should resolve the issue. Contact our support team for more help.';
      }

      let subtitleText = that.selectedMeasure.varname
        ? that.selectedMeasure.varname + '<br>' + (abbrevSex === 'F' ? 'Female' : 'Male')
        : null;
      if (that.selectedFactor2Option !== '--') {
        subtitleText += '<br>Variable 2: ' + that.selectedFactor2Option;
        subtitleText += '<br><br>Variable 1: ' + that.selectedFactorOption;
      } else if (that.selectedFactorOption !== '--') {
        subtitleText += '<br><br>Variable 1: ' + that.selectedFactorOption;
      }
      // HighCharts plot options objects
      const options: any = {
        chart: {
          inverted: false,
          renderTo: 'container-lt-' + sex,
          height: 600,
          ignoreHiddenSeries: false,
          events: {
            selection: function (e: any) {
              selectionEvent = true;
              if (!e.resetSelection && Object.keys(that.seriesMap).length < that.seriesThreshold) {
                that.brushUpdated(
                  [
                    [e.xAxis[0].min, e.xAxis[0].max],
                    [e.yAxis[0].min, e.yAxis[0].max],
                  ],
                  abbrevSex.toLowerCase(),
                );
                // identifies selected points
                chart.series.forEach((s: any) => {
                  s.points.forEach((point: any) => {
                    if (
                      point.x >= e.xAxis[0].min &&
                      point.x <= e.xAxis[0].max &&
                      point.y >= e.yAxis[0].min &&
                      point.y <= e.yAxis[0].max
                    ) {
                      point.select(true, true);
                    }
                  });
                });
              }
              // return false; // don't zoom
            },
            // I don't know what this does so I don't want to screw with it
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            click: function (e: any) {
              const points = this.getSelectedPoints();
              if (points.length > 0 && !selectionEvent) {
                points.forEach((point: any) => {
                  point.select(false);
                });

                for (let i = that.selectedTableData.length - 1; i >= 0; i--) {
                  if (that.selectedTableData[i].sex === abbrevSex.toLowerCase()) {
                    that.selectedTableData.splice(i, 1);
                  }
                }

                // reload the table
                that.updateTable();
              }
              selectionEvent = false;
            },
          },
          zoomType: 'xy',
          panning: {
            enabled: true,
            type: 'xy',
          },
          panKey: 'shift',
        },
        boost: {
          seriesThreshold: that.seriesThreshold,
        },
        tooltip: {
          formatter: function () {
            return (
              '<b>' +
              this.series.name +
              '</b><br/>' +
              'category: <b>' +
              this.x +
              '</b><br>' +
              'value: <b>' +
              this.y +
              '</b><br>' +
              'strain: <b>' +
              this.series.options.custom.strain +
              '</b>'
            );
          },
        },
        title: {
          text: that.selectedMeasure.id,
        },
        subtitle: {
          text: subtitleText,
        },
        credits: {
          enabled: false,
        },
        xAxis: [
          {
            startOnTick: true,
            endOnTick: true,
            showLastLabel: true,
            tickWidth: 1,
            type: 'categorical',
            gridLineWidth: 1,
            categories: this.measureVarNames,
            labels: {
              rotation: -90,
            },
          },
        ],
        yAxis: {
          lineWidth: 1,
          tickWidth: 1,
          title: {
            text: that.selectedMeasure.units ? '[' + that.selectedMeasure.units + ']' : that.selectedMeasure.varname,
          },
          startOnTick: true,
          endOnTick: true,
          minPadding: 0.2,
          maxPadding: 0.2,
        },
        legend: {
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'top',
          floating: false,
          itemStyle: {
            fontSize: '16px',
          },
        },
        plotOptions: {
          series: {
            turboThreshold: 500,
            stickyTracking: false,
            events: {
              legendItemClick: function () {
                // this.visible returns pre-click (on legend item) state
                const isLegendLabelActive = !this.visible;

                if (isLegendLabelActive) {
                  const i = hiddenLegendItems.indexOf(this.name);
                  if (i > -1) {
                    hiddenLegendItems.splice(i, 1);
                  }
                } else {
                  hiddenLegendItems.push(this.name);
                }
                that.setseriesVisibility(chart, series, isLegendLabelActive, this.name, hiddenLegendItems);
              },
            },
            marker: {
              states: {
                select: {
                  fillColor: 'yellow',
                  lineColor: 'yellow',
                },
              },
            },
          },
          line: {
            softThreshold: false,
          },
        },
        series: series,
        exporting: {
          buttons: {
            contextButton: {
              menuItems: ['printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG'],
            },
          },
        },
      };
      chart = new Highcharts.Chart(options);
      if (abbrevSex === 'F') {
        that.chartF = chart;
      } else {
        that.chartM = chart;
      }
    } // end sex loop
  }
}
