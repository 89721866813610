import { Component, OnInit, Input, OnDestroy, OnChanges, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import * as FileSaver from 'file-saver';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog.component';
import { ImagePreviewDialogComponent } from '../dialogs/image-preview-dialog.component';

declare let $: any;

@Component({
  selector: 'file-upload',
  templateUrl: 'file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit, OnChanges, OnDestroy {
  // pointertype, 'projid', 'proc_id', or 'app'
  @Input() pointertype = '';
  // pointer of pointertype (Project.projid for pointertype = 'projid')
  @Input() pointer = '';
  // tag associated the files we're viewing/uploading (ex: 'animalinfo', 'worflow', etc)
  @Input() tag = '';
  // true to allow uploading and removing files, false to not allow uploading and removing
  // (downloading and previewing are still available, though)
  @Input() allowEdit = true;
  // false to not allow archived files to be shown, true to allow it
  @Input() allowShowArchived = true;
  // true to only allow jpg images to be uploaded
  @Input() imageOnly = false;
  // true to allow multiple files to be uploaded at once
  @Input() multiple = false;
  // optional title (in place of 'Image(s)' or 'File(s)')
  @Input() title = '';
  // false to exclude (Optional) tag in title
  @Input() optional = true;
  // true to show the checkbox to indicate whether a file is private
  @Input() showPrivateOption = false;
  // true to show links to images (only make this true if this is a file that can be accessed without a token)
  @Input() showFileLink = false;

  // comma-separated list in a string of file types to allow. Otherwise allow all file or image types
  @Input() fileTypes = '';
  // limit to the number of files allowed to be uploaded
  @Input() fileNumLimit = 0;
  // flag to show the description/caption field in the file upload
  @Input() showDescription = true;

  // event emitted when a file is uploaded. post response is emitted
  @Output() fileUploaded: EventEmitter<any> = new EventEmitter<any>();
  // event emitted when the uploaded file is updated, by retrieval of column values
  // emits the updated file object
  @Output() fileUpdated: EventEmitter<any> = new EventEmitter<any>();
  // event emitted when a file is removed. true is emitted (ignored)
  @Output() fileRemoved: EventEmitter<any> = new EventEmitter<any>();
  // event emitted when the uploaded files are loaded or the values for a file's column are
  // retrieved. emits an array of all uploadedFiles (file objects)
  @Output() filesLoaded: EventEmitter<any> = new EventEmitter<any>();

  // "upload" for the upload form, or "downloadurl" for the form to enter a download url
  uploadMethod = 'upload';

  // used for downloading a file from a url
  downloadFileName = '';
  downloadUrl = '';

  // array of file dicts for already uploaded files
  uploadedFiles: any[] = [];

  // cached file blobs from uploaded files which have been downloaded, keyed by file id.
  // (NOTE: these blobs are stored separately from the file dicts so that we don't inadvertently send them to the
  //  api when a 'put' is eventually implemented to allow modifying for file table rows)
  fileBlobs: any = {};

  // file name of the file currently selected by the file input
  selectedFileName = '';

  // true if we have already BEGUN loading the files for the pointer, pointertype, and tag, false if not
  // (used to prevent make sure we don't double or triple-load)
  areFilesLoaded = false;

  // since the uploaded file panels vary in height (because of image thumbnails), and we are showing them in rows of 4
  // using the bootstrap grid system...there was a need to force them to group together nicely. This array is basically
  // a range array of length uploadedFiles.length / 4. So, for example, if uploadedFiles length is 10, rowNumsArray
  // is [0, 1, 2] (for 3 rows). In each row, we loop over indexes row_i * 4 through (row_i + 1) * 4 in uploadedFiles.
  // Unfortunately, I couldn't find a good way to build this range in-line in the html in angular, so I'm just storing
  // it as a variable and updating it any time that uploadedFiles changes.
  rowNumsArray: number[] = [];

  // true to show archived files
  showArchivedFiles = false;

  // error message to display below the file upload if the was an issue with the upload
  errorMessage = '';

  // api URL
  api: string = environment.securedURLs.sip;

  // files url with pointertype, pointer, and tag added on
  getURL = '';

  // true while files are being loaded from the api
  // (shows a loading bar and disables filters while we are loading)
  loadingFiles = true;

  // true while a file is being uploaded
  // (disables the Browse and Upload buttons to prevent duplicate files from multiple clicks)
  uploadingFile = false;

  captionValue = '';
  editingCaption: any = null;

  // true to hide uploaded files
  hideFiles = false;

  // declare http and dialog
  constructor(public http: HttpClient, public dialog: MatDialog) {}

  // on init, do an initial load of already uploaded files
  ngOnInit() {
    this.loadFiles();
  }

  // also load files on change if they haven't been loaded yet
  // (pointer is probably dynamic, so it might not have been set yet when ngOnInit was called)
  ngOnChanges() {
    this.loadFiles();
  }

  // on destroy, revoke the object urls (I tested it without this logic, it seems that they get revoked when you
  // navigate away from the curation site, so this isn't needed, but it just frees up some memory for the client while
  // they're on the curation site)
  ngOnDestroy() {
    for (let i = 0; i < this.uploadedFiles.length; i++) {
      if (this.uploadedFiles[i].url) {
        URL.revokeObjectURL(this.uploadedFiles[i].url);
      }
    }
  }

  // load the files for the pointertype, pointer, tag combination, if they haven't been loaded yet
  loadFiles() {
    if (this.pointer && this.pointertype && this.tag && !this.areFilesLoaded) {
      this.getURL =
        this.api + 'files' + '?pointertype=' + this.pointertype + '&pointer=' + this.pointer + '&tag=' + this.tag;
      if (this.showArchivedFiles) {
        this.getURL = this.getURL + '&showarchived=true';
      }
      this.http.get<any[]>(this.getURL).subscribe(
        (result) => {
          // cache the file blob for any images
          for (const file of result) {
            if (this.fileIsImage(file) || file.filetype === 'pdf') {
              this.downloadFile(file, true);
            }
          }
          // set uploadedFiles and rowNumsArray
          this.uploadedFiles = result;
          this.rowNumsArray = Array.from(Array(Math.ceil(this.uploadedFiles.length / 4)).keys());
          this.filesLoaded.emit(result);
          this.loadingFiles = false;
        },
        () => {
          this.errorMessage = 'An error occurred loading uploaded files';
          this.loadingFiles = false;
        },
      );
      this.areFilesLoaded = true;
    }
  }

  // load files again, regardless of whether they were previously loaded
  reloadFiles() {
    this.areFilesLoaded = false;
    this.loadFiles();
  }

  // make sure that we are currently under the file limit
  underFileLimit() {
    return this.fileNumLimit
      ? this.uploadedFiles.filter((value) => {
          return !value.deletedtime;
        }).length < this.fileNumLimit && !this.loadingFiles
      : true;
  }

  // logic that occurs on change of the upload method... just clear out some things
  uploadMethodOnChange() {
    this.selectedFileName = '';
    this.errorMessage = '';
    this.downloadFileName = '';
    this.downloadUrl = '';
  }

  // update the file text to match the file(s) selected in the file input or the file name for the download url
  updateFileText() {
    this.errorMessage = '';
    const file = $('#file-' + this.tag);
    if (this.uploadMethod === 'upload') {
      if (file) {
        if (file[0].files.length > 1) {
          this.selectedFileName = `${file[0].files.length} files`;
        } else if (file.val()) {
          this.selectedFileName = file
            .val()
            .split(/(\\|\/)/g)
            .pop();
        } else {
          this.selectedFileName = '';
        }
      } else {
        this.selectedFileName = '';
      }
    } else if (this.uploadMethod === 'downloadurl') {
      if (file) {
        // clear the file input if we're doing download url
        file.val('');
      }
      if (this.downloadUrl && this.downloadFileName) {
        const allowedFileTypes = (
          this.fileTypes
            ? this.fileTypes
            : '.png, .jpg, .jpeg, .svg, .tiff, .tif, .pdf' + (this.imageOnly ? '' : ', .txt, .csv, .tsv, .xls, .xlsx')
        ).split(', ');
        const fileType = `.${this.downloadFileName.split('.').pop()}`;
        if (allowedFileTypes.indexOf(fileType) !== -1) {
          this.selectedFileName = this.downloadFileName;
        } else {
          this.selectedFileName = '';
          this.errorMessage = `Please enter a filename with one of the following file types: ${allowedFileTypes.join(
            '  ',
          )}`;
        }
      } else {
        this.selectedFileName = '';
      }
    }
  }

  // upload the currently selected file in the file input
  uploadFile() {
    if (!this.uploadingFile) {
      this.uploadingFile = true;
      this.errorMessage = '';
      this.updateFileText();
      const form = $('#uploadform-' + this.tag)[0];
      if (form && this.selectedFileName) {
        const formData = new FormData(form);
        this.http.post(this.api + 'files', formData).subscribe(
          (result: any) => {
            // if there was an error, then display that
            this.errorMessage = result.error;
            const files = result.files;
            for (let i = 0; i < files.length; i++) {
              // if the upload was successful, cache the file blob if it's an image
              if (this.fileIsImage(files[i]) || files[i].filetype === 'pdf') {
                this.downloadFile(files[i], true);
              }
              // also, add the file dict to the uploadedFiles and update rowNumsArray
              this.uploadedFiles.push(files[i]);
            }
            this.rowNumsArray = Array.from(Array(Math.ceil(this.uploadedFiles.length / 4)).keys());
            this.fileUploaded.emit(result);
            this.uploadingFile = false;
            this.selectedFileName = '';
            $('#file-' + this.tag).val(null);
          },
          (error) => {
            if (error instanceof HttpErrorResponse) {
              if ((<HttpErrorResponse>error).status === 413) {
                // 413 error means the request exceeded the API's MAX_CONTENT_LENGTH, which is currently 10MB, but
                // the api endpoint is actually setting a limit at file size limit at 1 MB currently
                // (since there is more content in a upload POST request besides just the ifle)
                this.errorMessage =
                  'File is too large. Files ' + environment.maxFileSize + ' and larger are not permitted.';
              } else if (error.error ? error.error.message : false) {
                this.errorMessage = error.error.message;
              } else {
                this.errorMessage = 'Failed to upload file: unknown error.';
              }
            }
            this.uploadingFile = false;
            this.selectedFileName = '';
            $('#file-' + this.tag).val(null);
          },
        );
      } else {
        this.uploadingFile = false;
        this.selectedFileName = '';
        $('#file-' + this.tag).val(null);
      }
    }
  }

  /**
   * Download the file (caches the file blob so that we only have to download each file once...also image files are
   * automatically downloaded and cached immediately so that we can show the thumbnail and preview).
   *
   * @param {any} file: file dict
   * @param {boolean} caching: true if we're just caching the file and don't want to download it to the client right now
   */
  downloadFile(file: any, caching: boolean = false) {
    if (this.fileBlobCache(file)) {
      if (!caching) {
        FileSaver.saveAs(this.fileBlobCache(file), file.filename);
      }
    } else {
      this.http
        .get(this.buildFileUrl(file, true), { responseType: 'blob', observe: 'response' })
        .subscribe((result: any) => {
          // saving the file blob separately from the file dicts so that we don't inadvertently send them to the
          // api when a 'put' is eventually implemented to allow modifying for file table rows
          this.fileBlobs[file.id] = result.body;
          if (!caching) {
            FileSaver.saveAs(result.body, file.filename);
          }
        });
    }
  }

  /**
   * Go into fullscreen mode for the pdf iframe (opens it in a new tab for display)
   *
   * @param {object} file: file object we are full-screening
   */
  fullscreenPdf(file: any) {
    window.open(this.buildFileUrl(file), '_blank');
  }

  /**
   * Get the file blob for the file if it's downloaded and cached, otherwise false
   *
   * @param {any} file: file object
   * @returns {any}: file blob if there is one, else false
   */
  fileBlobCache(file: any): any {
    return this.fileBlobs[file.id];
  }

  // delete/remove all uploaded files in the fileupload
  removeAllFiles() {
    for (let i = this.uploadedFiles.length - 1; i >= 0; i--) {
      this.removeFileNoConfirm(this.uploadedFiles[i]);
    }
  }

  /**
   * Change all of the file tags of uploaded files in the fileupload to match the new tag
   *
   * @param {string} newTag: new files tag for this file upload
   */
  changeFilesTag(newTag: string) {
    for (let i = this.uploadedFiles.length - 1; i >= 0; i--) {
      this.http.put(this.api + 'files', { id: String(this.uploadedFiles[i].id), tag: newTag }).subscribe((result) => {
        this.uploadedFiles[i] = result;
      });
    }
    this.tag = newTag;
    this.getURL =
      this.api + 'files' + '?pointertype=' + this.pointertype + '&pointer=' + this.pointer + '&tag=' + this.tag;
  }

  /**
   * Get the the unique values in the columns at the indicated indexes. (Assuming this is a
   * compatible file type, such as csv, excel, etc.
   *
   * @param {number} fileindex: index of the file for which we're getting column values
   * @param {number[]} indexvals: array of column indexes that we want to get a list of values for
   */
  getColValues(fileindex: number, indexvals: number[]) {
    if (fileindex < this.uploadedFiles.length) {
      this.http
        .put(this.api + 'files', { id: String(this.uploadedFiles[fileindex].id), indexvals: indexvals })
        .subscribe((result) => {
          this.uploadedFiles[fileindex] = result;
          this.fileUpdated.emit(result);
        });
    }
  }

  /**
   * Update the caption for the passed-in file index to the passed-in caption
   *
   * @param {number} fileindex: index of the file for which we're updating the caption
   * @param {string} caption: new caption for the file
   */
  updateCaption(fileindex: number, caption: string) {
    if (this.allowEdit && fileindex < this.uploadedFiles.length) {
      this.http
        .put(this.api + 'files', { id: String(this.uploadedFiles[fileindex].id), descrip: caption })
        .subscribe((result) => {
          this.uploadedFiles[fileindex] = result;
          this.fileUpdated.emit(result);
        });
    }
  }

  /**
   * Focus on the caption modification input for the passed-in input id after a momentary delay.
   * (For some reason it doesn't work without the delay, either because it's a part of click logic, which
   *  inherently is already focusing on the thing you're clicking, or because we need to wait a second for the
   *  input to become visible.)
   *
   * @param {number} fileindex: file index
   */
  focusCaptionInput(fileindex: number) {
    if (this.allowEdit && fileindex < this.uploadedFiles.length) {
      this.captionValue = this.uploadedFiles[fileindex].descrip;
      this.editingCaption = fileindex;
      setTimeout(() => {
        document.getElementById('file_caption_' + this.tag + '_' + fileindex)?.focus();
      }, 10);
    }
  }

  /**
   * Check if the values (should be numbers) are equal or both equal to 0
   *
   * @param val1: value 1 to compare
   * @param val2: value 2 to compare
   * @returns {boolean}: True if they're equal, false if they're not
   */
  checkNumbersEqual(val1: any, val2: any) {
    return val1 === val2 || (val1 === 0 && val2 === 0);
  }

  /**
   * Delete the file with a confirmation dialog
   *
   * @param {any} file: file object
   */
  removeFile(file: any) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        header: 'Confirm Delete File',
        message:
          'Are you sure that you wish to delete the file, <b>' +
          file.filename +
          '</b>?<br><br>' +
          'The file will be archived for ' +
          environment.fileArchiveDays +
          ' days, during which time it can ' +
          'be recovered.<br> However, after that it will be deleted permanently.',
        falselabel: 'Cancel',
        truelabel: 'Delete',
        truebtn: 'btn-danger',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.removeFileNoConfirm(file);
      }
    });
  }

  /**
   * Delete the file with NO confirmation dialog
   *
   * @param {any} file: file object
   */
  removeFileNoConfirm(file: any) {
    this.http.delete(this.api + 'files' + '?id=' + file.id).subscribe((result) => {
      const index = this.getFileIndex(file);
      if (!this.showArchivedFiles) {
        // if delete was successful, remove it from the uploadedFiles and update rowNumsArray
        if (index !== -1) {
          this.uploadedFiles.splice(index, 1);
        }
        this.rowNumsArray = Array.from(Array(Math.ceil(this.uploadedFiles.length / 4)).keys());
        // also, clear up some memory by removing the cached file blob and url
        this.fileBlobs[file.id] = null;
        if (file.url) {
          URL.revokeObjectURL(file.url);
        }
      } else {
        if (index !== -1) {
          this.uploadedFiles[index] = result;
        }
      }
      this.fileRemoved.emit(true);
    });
  }

  /**
   * Change the private status of the file
   *
   * @param {any} file: file object
   * @param {Boolean} privatefile: true to make file private, false to not
   */
  changePrivate(file: any, privatefile: boolean) {
    if (this.allowEdit && file.private !== privatefile) {
      this.http.put(this.api + 'files', { id: String(file.id), private: privatefile }).subscribe((result) => {
        const index = this.getFileIndex(result);
        if (index !== -1) {
          this.uploadedFiles[index] = result;
        }
        this.fileUpdated.emit(result);
      });
    }
  }

  /**
   * Restore the file from being deleted
   *
   * @param {any} file: file object
   */
  restoreFile(file: any) {
    if (this.allowEdit && file.deletedtime) {
      this.http.put(this.api + 'files', { id: String(file.id), deletedtime: null }).subscribe((result) => {
        const index = this.getFileIndex(result);
        if (index !== -1) {
          this.uploadedFiles[index] = result;
        }
        this.fileUpdated.emit(result);
      });
    }
  }

  /**
   * Get the file index of the passed-in file object within the uploadedFiles list
   *
   * @param {any} file: file object
   * @returns {number}: index of the file object, -1 if not found
   */
  getFileIndex(file: any): number {
    for (let i = 0; i < this.uploadedFiles.length; i++) {
      if (this.uploadedFiles[i].id === file.id) {
        return i;
      }
    }
    return -1;
  }

  /**
   * Determine if the file is an image
   *
   * @param {any} file: file object
   * @returns {boolean} true if the file is an image (and should show a thumbnail and the preview dialog on click),
   *                    false if it is not an image
   */
  fileIsImage(file: any = {}): boolean {
    if (!file) {
      return false;
    }
    if (typeof file !== 'object') {
      return false;
    }
    return (
      file.filetype === 'png' ||
      file.filetype === 'jpg' ||
      file.filetype === 'jpeg' ||
      file.filetype === 'svg' ||
      file.filetype === 'tif' ||
      file.filetype === 'tiff'
    );
  }

  /**
   * Build either an inline (preview) or download url for this file...also depends on whether or not the file blob has
   * already been downloaded and cached.
   *
   * @param {any} file: file object
   * @param {boolean} download: true for a download url, false for an inline url
   * @param {boolean} notCached: true to get the api url, not the cached blob url
   * @returns {string} url for the inline file or file download
   */
  buildFileUrl(file: any, download: boolean = false, notCached: boolean = false): string {
    if (!download && !notCached && this.fileBlobCache(file)) {
      // if an inline file url has already been created from the blob, use that
      if (file.url) {
        return file.url;
      }
      // if the file blob is already downloaded & cached, create an inline file url for the file
      const newUrl = URL.createObjectURL(this.fileBlobCache(file));
      // cache the inline file url
      for (let i = 0; i < this.uploadedFiles.length; i++) {
        if (this.uploadedFiles[i].id === file.id) {
          this.uploadedFiles[i].url = newUrl;
        }
      }
      return newUrl;
    } else {
      // if this is a download or the file blob isn't downloaded and cached, then we need to go out to the api
      return this.getURL + '&id=' + file.id + '&mode=' + (download ? 'download' : 'file');
    }
  }

  /**
   * Open a dialog with a larger display of the image for the file
   *
   * @param {any} file: file object for the image we want to preview
   */
  openPreviewDialog(file: any): void {
    const dialogRef = this.dialog.open(ImagePreviewDialogComponent, {
      data: { file: file, file_blob: this.fileBlobCache(file) },
    });

    dialogRef.afterClosed().subscribe();
  }

  // opens the link to the api endpoint that gets the file
  openFileLink(file: any): void {
    window.open(this.buildFileUrl(file, false, true), '_blank');
  }
}
