import { Component, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProcedureService } from '../../services/procedure.service';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { PersonService } from '../../services/person.service';
import { flashMessage } from '../utils';
import { environment } from '../../../environments/environment';
import { FormControl } from '@angular/forms';
import { ProcedureDialogComponent } from '../dialogs/procedure-dialog.component';

@Component({
  selector: 'procedure-search',
  template: `
    <!--Using fieldset as a wrapper to make disabling work (it works differently...and not as well...for
        inputs with the formControl attribute, but a wrapping fieldset seems to work fine)-->
    <div [class]="optionSelected() ? 'input-group' : ''">
      <fieldset [disabled]="optionSelected() || disabled">
        <span [matTooltip]="inputTooltip()">
          <input
            placeholder="Begin typing a procedure title or other procedure details"
            class="form-control left-bord-rad-4px"
            autocomplete="off"
            [matAutocomplete]="auto"
            [formControl]="valueCtrl"
            (focus)="onFocus()"
            (focusout)="inputFocused = false"
            [style.border-color]="inputNoSelection() ? 'red' : ''"
          />
        </span>
        <span class="form-control-feedback">
          <span
            *ngIf="optionSelected()"
            style="color:green;"
            [style.right]="showDialog ? '80px' : '40px'"
            class="glyphicon glyphicon-ok"
          ></span>
          <span
            *ngIf="inputNoSelection()"
            style="color:red"
            [style.right]="showDialog ? '40px' : '20px'"
            class="glyphicon glyphicon-warning-sign"
          ></span>
        </span>
      </fieldset>
      <div *ngIf="optionSelected()" class="input-group-btn">
        <button
          *ngIf="showDialog"
          (click)="openEditDialog()"
          type="button"
          class="btn btn-success"
          [disabled]="!this.valueCtrl.value"
          [matTooltip]="openDialogTooltip()"
        >
          <span [class]="'glyphicon glyphicon-' + (canEdit() ? 'pencil' : 'eye-open')"></span>
        </button>
        <button
          (click)="clearSelection()"
          type="button"
          [disabled]="disabled"
          matTooltip="Clear selected procedure"
          class="btn btn-danger"
        >
          <span class="glyphicon glyphicon-remove clear-icon"></span>
        </button>
      </div>
    </div>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected()" [displayWith]="displayFn">
      <mat-option disabled *ngIf="filteredOptions.length === 0" style="color:red;">
        No matches. Please select a procedure from the options.
      </mat-option>
      <!--This is a performance optimization, which allows the user to see a few matches/options,
          while telling them that more are available if they narrow down their search.
          This pretty effectively fixes the performance issues I've seen from generating too many options at once.-->
      <mat-option
        *ngFor="let option of options"
        [value]="option"
        [disabled]="disableOption(option)"
        [matTooltip]="disableOption(option) ? disableMessage : ''"
      >
        {{ titleStr(option.title) }} | <small>{{ optionDisplaySmall(option) }}</small>
      </mat-option>
      <mat-option
        disabled
        [style.display]="!showAllResults ? 'block' : 'none'"
        *ngIf="filteredOptions.length > 50"
        class="mat-option show-all"
      >
        <a (click)="showAllResults = true" style="cursor:pointer;">Show all results</a>
        or type more to narrow down results...
      </mat-option>
    </mat-autocomplete>
  `,
  styleUrls: ['searching.scss'],
})
export class ProcedureSearchComponent implements OnInit, OnChanges {
  // 2-way binding for the selected procedure. This component
  // will emit the selected procedure to the parent when a procedure is selected, so having a variable to bind
  // to in the parent is easier than going off of an event alone. Defaults to private=true for when we're
  // creating new procedure templates in the stand-alone page
  @Input() procedure: any = { private: true };

  // true to only show editable procedures (also forces templates only)
  @Input() onlyEditable = false;

  // true to only show templates
  @Input() onlyTemplates = false;

  // true to show the button to open a dialog to create, edit, or view procedures, false to not
  @Input() showDialog = true;

  // true to disable the search box (including preventing it from being cleared out)
  @Input() disabled = false;
  // list of procedure objects to exclude (compare 'proc_id')
  @Input() disableList: any[] = [];
  // optional argument to change the message for disabled options
  @Input() disableMessage = '(Already Selected)';

  // input options overrides using the cached list from the server
  @Input() inputOptions!: any[];

  // event emitter to send the selected procedure to the parent when one is selected from the autocomplete options
  @Output() procedureChange: EventEmitter<any> = new EventEmitter<any>();

  // api URL
  api: string = environment.securedURLs.sip;

  // true if the current user is a curator, false if not
  isCurator = false;

  // formControl for the input value
  valueCtrl: FormControl;

  // true if the input is currently focused, false otherwise
  inputFocused = false;

  // true to show all results, false to only show 6 or 7 results if the number of results is above 50
  showAllResults = false;

  // filtered autocomplete options, list of procedures (any because typescript complains otherwise)
  filteredOptions: any[] = [];

  // set up the onchange observable with calls the procedureService.getProcedure() observable (http call)
  // to get filtered options in real time.
  constructor(
    public http: HttpClient,
    public dialog: MatDialog,
    public procedureService: ProcedureService,
    private snackBar: MatSnackBar,
    private personService: PersonService,
  ) {
    this.valueCtrl = new FormControl();
    this.valueCtrl.valueChanges.subscribe((result) => {
      const val = result ? result : '';
      if (typeof val !== 'string') {
        this.filteredOptions = [];
      } else {
        this.filterValues(val);
      }
    });
  }

  // on init, do an initial load of options
  ngOnInit() {
    if (!this.inputOptions) {
      this.procedureService.procedures$.subscribe(() => {
        let val = this.valueCtrl.value;
        val = val ? val : '';
        // Note: don't need to change filteredOptions right now if the type isn't a string
        if (typeof val === 'string') {
          this.filterValues(val);
        }
      });
    }

    this.personService.currentUser$.subscribe(() => {
      this.isCurator = this.personService.isCurator();
    });
  }

  // on change of the procedure from the parent side, set the current procedure to it if it's valid
  ngOnChanges() {
    if (this.procedure) {
      if (this.procedure.proc_id) {
        this.valueCtrl.setValue(this.procedure);
      }
    }
  }

  get options(): any[] {
    return this.filteredOptions.length > 50 && !this.showAllResults
      ? this.filteredOptions.slice(0, 7)
      : this.filteredOptions;
  }

  /**
   * Filter the available procedures in the dropdown to those for which the
   * user-entered string matches the procedure title
   *
   * @param {string} newValue: string entered by the user
   */
  filterValues(newValue: string) {
    this.showAllResults = false;
    let tempOptions = this.inputOptions ? this.inputOptions : this.procedureService.procedures.getValue();
    if (this.onlyEditable) {
      tempOptions = tempOptions.filter((option) => ['Edit', 'Owner'].indexOf(option.permission) !== -1);
    }
    if (this.onlyEditable || this.onlyTemplates) {
      tempOptions = tempOptions.filter((option) => !option.projid);
    }
    if (newValue) {
      const newValueSplit = newValue.split(' ');
      for (let i = 0; i < newValueSplit.length; i++) {
        tempOptions = tempOptions.filter((option) =>
          option ? this.optionFilterBy(option).indexOf(newValueSplit[i].toLowerCase()) !== -1 : false,
        );
      }
    }
    this.filteredOptions = tempOptions;
  }

  // return true if the current user can modify the procedure, false if not
  canEdit() {
    // use the project canEdit (which can actually be false even for Curators if the project is archived)
    if (this.procedure.projid) {
      return false;
    }
    // curators can edit anything... except archived projects
    if (this.isCurator) {
      return true;
    }
    // permission sent with object lets us know if we can edit
    return ['Edit', 'Owner'].indexOf(this.procedure.permission) !== -1;
  }

  /**
   * Builds a string containing all information about the procedure that we might want to filter by based
   * on the user-entered values. Slightly more extensive and less formatted than what's displayed in the option
   * (full display_str on the user_creator instead of name_or_email),
   * which is why this is a separate function from the optionDislaySmall
   *
   * @param {any} option: procedure object
   * @returns {string}: string containing lots of info in the procedure to filter by
   */
  optionFilterBy(option: any) {
    let value = this.titleStr(option.title) + ' ';
    if (option.projid) {
      value += String(option.listingorder) + ' project ' + option.projsym + ' ' + option.projtitle;
    } else {
      value +=
        option.user_creator.display_str + (option.private ? ' Private' : '') + ' template ' + option.template_name;
    }

    if (option.external_id) {
      value += ' External ID ' + option.external_id;
    }
    return value.toLowerCase();
  }

  // determines whether an option was selected
  optionSelected(): boolean {
    return this.procedure && typeof this.procedure === 'object' ? this.procedure.proc_id : false;
  }

  // determines whether the current user has input a value without selecting an option
  inputNoSelection(): boolean {
    return !this.optionSelected() && this.valueCtrl.value && !this.inputFocused;
  }

  // determines whether the selected option is not yet approved by a curator
  optionNotApproved(option: any): boolean {
    return option ? (option.user_creator ? option.user_creator.id : false) : false;
  }

  // builds a string describing the flag for an option that is not yet approved by a curator
  optionNotApprovedTooltip(option: any): string {
    return (
      'This procedure was added by the user, ' +
      option.user_creator.name_or_email +
      ', and is pending SIP Curator approval.'
    );
  }

  // builds the tooltip for the input if there's an issue
  inputTooltip(): string {
    if (this.inputNoSelection()) {
      return 'Must select option for this field to be populated (click in field to see options)';
    } else if (this.optionNotApproved(this.procedure)) {
      return this.optionNotApprovedTooltip(this.procedure);
    }
    return '';
  }

  /**
   * Builds the information displayed in the <small> element in the option display,
   * which doesn't include the title.
   *
   * @param {any} option: protocol objet
   * @returns {string} string containing procedure info to display in option (other than title)
   */
  optionDisplaySmall(option: any) {
    let value = '';
    if (option.projid) {
      value = 'Procedure #' + option.listingorder + ' in Project "' + option.projsym + ': ' + option.projtitle + '"';
    } else {
      value =
        (option.private ? 'Private ' : '') +
        'Template "' +
        option.template_name +
        '" created by ' +
        option.user_creator.name_or_email;
    }

    if (option.external_id) {
      value += ' ... External ID: ' + option.external_id;
    }
    return value;
  }

  /**
   * Returns the trimmed title string or (No Title) if it's not populated
   *
   * @param {string} title: title string
   * @returns {string}: trimmed title string or '(No Title)'
   */
  titleStr(title: string) {
    return title ? (title.trim() ? title : '(No Title)') : '(No Title)';
  }

  /**
   * Display function for the procedure object
   *
   * @param {any} option: procedure object or undefined
   * @returns {string}: display_str ('first middle last, degree | email')
   */
  displayFn(option?: any): string | undefined {
    if (!option) {
      return undefined;
    }
    // NOTE: Not using the titleStr function because this. functions aren't accessible in this function
    let value = option.title ? (option.title.trim() ? option.title : '(No Title)') : '(No Title)';
    value += ' | ';
    if (option.projid) {
      value += '#' + option.listingorder + ' in project "' + option.projsym + '"';
    } else {
      value += 'template "' + option.template_name + '"';
    }
    return value;
  }

  // clear currently selected procedure
  clearSelection() {
    this.valueCtrl.setValue('');
    this.filterValues('');
    this.procedure = { private: true };
    this.procedureChange.emit(this.procedure);
  }

  // tooltip for opening the dialog for viewing/editing procedures
  // NOTE: This is different from other dialog tooltips because you can't enter/create new procedures
  //       in the dialog, just view or edit existing ones
  openDialogTooltip() {
    if (!this.valueCtrl.value || !this.optionSelected()) {
      return '';
    }
    const action = this.canEdit() ? 'Edit' : 'View';
    return action + ' procedure';
  }

  /**
   * Determine if the option for selecting this object should be disabled because it's in the disableList
   * @param {Object} option: object option
   * @returns {boolean}: true to disable this object option, false to not
   */
  disableOption(option: any) {
    for (let i = 0; i < this.disableList.length; i++) {
      if (this.disableList[i].proc_id === option.proc_id) {
        return true;
      }
    }
    return false;
  }

  // if an option is selected, emit a objectChange event so that the parent knows who is selected
  onOptionSelected() {
    this.procedure = this.valueCtrl.value;
    this.procedureChange.emit(this.procedure);
  }

  // upon focusing on a field, if the current value is blank and we have no options, then create them
  onFocus() {
    this.inputFocused = true;
    const value = this.valueCtrl.value;
    if ((!value || value === '') && this.filteredOptions.length === 0) {
      this.filterValues(value ? value : '');
    }
  }

  // open a dialog to edit or view a procedure
  openEditDialog(): void {
    if (this.optionSelected()) {
      // get the full details about the procedure before opening the dialog
      this.procedureService.getProcedure(this.procedure.proc_id).subscribe(
        (result) => {
          const dialogRef = this.dialog.open(ProcedureDialogComponent, {
            data: { procedure: result },
            autoFocus: false,
          });

          dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              if (result.proc_id) {
                this.procedure = result;
                this.procedureChange.emit(this.procedure);
              } else {
                this.clearSelection();
              }
            }
          });
        },
        (error2) => {
          flashMessage(
            this.snackBar,
            'Failed to get procedure',
            'alert-danger',
            5000,
            error2.error ? [error2.error.message] : [],
          );
        },
      );
    }
  }
}
