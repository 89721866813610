import { AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * Ensures that the selected file for the field doesn't match any of the specified filenames which
 * prevents duplicate files from entering the system. This version is extension blind so filenames
 * with the same filename but different extensions will still get flagged
 * @param {string[]} filenamesToPreventReUploading - list of filenames to flag if a match is found
 */
export function NoDuplicatesValidator(filenamesToPreventReUploading: string[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const filename = control.value.replace(/\.[^/.]+$/, '');

    if (filenamesToPreventReUploading.indexOf(filename) >= 0) {
      return { duplicateFilename: { value: control.value } };
    }

    return null;
  };
}
