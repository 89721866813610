import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Injectable } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { BehaviorSubject } from 'rxjs';

/**
 * The following is a slightly altered version of the selection tree example from
 * https://material.angular.io/components/tree/examples#tree-checklist
 */

/**
 * Node for to-do item
 */
export class Node {
  children: any[];
  item: string;

  constructor() {
    this.children = [];
    this.item = '';
  }
}

/** Flat to-do item node with expandable and level information */
export class FlatNode {
  item: string;
  level: number;
  expandable: boolean;

  constructor() {
    this.item = '';
    this.level = 0;
    this.expandable = false;
  }
}

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable()
export class ChecklistDatabase {
  dataChange = new BehaviorSubject<Node[]>([]);

  get data(): Node[] {
    return this.dataChange.value;
  }

  initialize(data: any) {
    // Build the tree nodes from Json object. The result is a list of `Node` with nested
    //     file node as children.
    const builtData = this.buildFileTree(data, 0);

    // Notify the change.
    this.dataChange.next(builtData);
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `Node`.
   */
  buildFileTree(obj: { [key: string]: any }, level: number): Node[] {
    return Object.keys(obj).reduce<Node[]>((accumulator, key) => {
      const value = obj[key];
      const node = new Node();
      node.item = key;

      if (value != null) {
        if (typeof value === 'object') {
          node.children = this.buildFileTree(value, level + 1);
        } else {
          node.item = value;
        }
      }

      return accumulator.concat(node);
    }, []);
  }
}

@Component({
  selector: 'selection-tree',
  templateUrl: 'selection-tree.component.html',
  styles: ['.mat-tree-node { min-height: 32px; }'],
  providers: [ChecklistDatabase],
})
export class SelectionTreeComponent {
  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<FlatNode, Node>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<Node, FlatNode>();

  treeControl: FlatTreeControl<FlatNode>;

  treeFlattener: MatTreeFlattener<Node, FlatNode>;

  dataSource: MatTreeFlatDataSource<Node, FlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<FlatNode>(true /* multiple */);

  // handles any parent node display text transformation
  parentDisplayFn: ((val: string) => string) | null = null;

  // handles any leaf node display text transformation
  leafDisplayFn: ((val: string) => string) | null = null;

  constructor(private _database: ChecklistDatabase) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<FlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    this._database.dataChange.subscribe((data) => {
      this.dataSource.data = data;
    });
  }

  getLevel = (node: FlatNode) => node.level;

  isExpandable = (node: FlatNode) => node.expandable;

  getChildren = (node: Node): Node[] => node.children;

  hasChild = (_: number, _nodeData: FlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: FlatNode) => _nodeData.item === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: Node, level: number): FlatNode => {
    const existingNode: FlatNode | undefined = this.nestedNodeMap.get(node);
    const flatNode: FlatNode = existingNode && existingNode.item === node.item ? existingNode : new FlatNode();

    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.children?.length;

    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /**
   * Loads the data into the tree
   * IMPORTANT! The data needs to be strucutred in JSON-like format where the values are either string
   * arrays or a nested key/value pair. For example:
   * {
   *   parent1: ['child1', 'child2', 'child3'], // parent with children
   *   parent2: { // parent with sub-tree
   *     parent3: ['child4', 'child5'],
   *   },
   *   parent4: null, // parent with no children or sub-tree
   * }
   * @param data - combination of parent/child information to load into the tree following the above format
   * @param parentDisplayFn - a custom callback used for displaying/formatting parent node values if they need to be
   *                          different from the values being used (default is null and no transformation needed)
   * @param leafDisplayFn - a custom callback used for displaying/formatting leaf node values if they need to be
   *                        different from the values being used (default is null and no transformation needed)
   * @param selections - an array of keys that match with the string values of the leaf nodes (value is null if no
   *                     selections need to be restored)
   */
  loadTree(
    data: any,
    parentDisplayFn: ((val: string) => string) | null = null,
    leafDisplayFn: ((val: string) => string) | null = null,
    selections: string[] | null = null,
  ): void {
    this._database.initialize(data);

    if (parentDisplayFn) {
      this.parentDisplayFn = parentDisplayFn;
    }

    if (leafDisplayFn) {
      this.leafDisplayFn = leafDisplayFn;
    }

    if (selections) {
      selections.forEach((s) => {
        const selectedNode = this.treeControl.dataNodes.find((node) => node.item === s);

        if (selectedNode) {
          this.selectionToggle(selectedNode);
        }
      });
    }
  }

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: FlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return (
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.checklistSelection.isSelected(child);
      })
    );
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: FlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the item selection. Select/deselect all the descendants node */
  selectionToggle(node: FlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach((child) => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: FlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: FlatNode): void {
    let parent: FlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: FlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.checklistSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: FlatNode): FlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }
}
