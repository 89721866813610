<div *ngIf="waiting" class="spinner-container">
  <mat-spinner color="accent" strokeWidth="5" diameter="50"></mat-spinner>
</div>

<ng-container *ngIf="(inventory || missingAnimals) && !waiting">
  <div class="col-sm-12 col-lg-8 bottom-margin-1">
    <div class="table-container mat-elevation-z2">
      <div class="top-1 align-center">
        <h4>Dataset Sample Inventory ({{ inventory?.data.length || 0 }})</h4>

        <p>
          The samples in this table have had all required data submitted. You may encounter more than one sample from
          the same animal (the animal IDs are the same) which indicates that the animal was genotyped multiple times,
          probably in different arrays or genotyping platforms.
        </p>

        <p *ngIf="editable">
          Please review and select all samples you wish to include in the genotype dataset. If you aren't seeing a
          specific animal/sample in this table, check the table containing animals with missing genotype data.
        </p>
      </div>

      <ng-container *ngIf="inventory">
        <div class="filter">
          <mat-form-field>
            <input
              matInput
              #inventoryFilter
              [placeholder]="'Search ' + inventory.data.length + ' animals'"
              (keyup)="applyFilter(inventory, $event.target.value)"
            />
            <button
              *ngIf="inventoryFilter.value"
              mat-icon-button
              matSuffix
              aria-label="clear"
              (click)="clearFilter(inventory, inventoryFilter)"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>

        <mat-table id="inventory-animals-table" [dataSource]="inventory">
          <!-- Checkbox Column -->
          <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef [ngClass]="'narrow-col'">
              <input
                type="checkbox"
                (change)="$event ? masterToggle() : null"
                [disabled]="!editable"
                [checked]="selections.hasValue() && isAllSelected()"
                [indeterminate]="selections.hasValue() && !isAllSelected()"
              />
            </mat-header-cell>
            <mat-cell *matCellDef="let row" [ngClass]="'narrow-col'">
              <input
                type="checkbox"
                (click)="$event.stopPropagation()"
                (change)="$event ? selections.toggle(row) : null"
                [disabled]="!editable"
                [checked]="isSelected(row)"
              />
            </mat-cell>
          </ng-container>

          <!-- ID Column -->
          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef [ngClass]="'id-col'"> Animal ID </mat-header-cell>
            <mat-cell *matCellDef="let animal" [ngClass]="'id-col'">
              {{ animal.mouse_id }}
              <span *ngIf="animal.mouse_id !== animal.correct_id" matTooltip="The correct animal ID for this sample">
                ({{ animal.correct_id }})
              </span>
            </mat-cell>
          </ng-container>

          <!-- Sex Column -->
          <ng-container matColumnDef="sex">
            <mat-header-cell *matHeaderCellDef [ngClass]="'narrow-col'"> Sex </mat-header-cell>
            <mat-cell *matCellDef="let animal" [ngClass]="'narrow-col'">{{ animal.sex }}</mat-cell>
          </ng-container>

          <!-- DO Generation Column -->
          <ng-container matColumnDef="doGen">
            <mat-header-cell *matHeaderCellDef [ngClass]="'narrow-col'"> DO Gen </mat-header-cell>
            <mat-cell *matCellDef="let animal" [ngClass]="'narrow-col'">{{ animal.do_generation }}</mat-cell>
          </ng-container>

          <!-- Array Column -->
          <ng-container matColumnDef="array">
            <mat-header-cell *matHeaderCellDef> Array </mat-header-cell>
            <!-- Very likely, the array name will extend past the extent of the row width so include a tooltip -->
            <mat-cell
              *matCellDef="let animal"
              [matTooltip]="animal.array"
              [matTooltipClass]="{ 'array-tooltip': true }"
            >
              {{ animal.array }}
            </mat-cell>
          </ng-container>

          <!-- Run Date Column -->
          <ng-container matColumnDef="runDate">
            <mat-header-cell *matHeaderCellDef [ngClass]="'med-col'"> Run Date </mat-header-cell>
            <mat-cell *matCellDef="let animal" [ngClass]="'med-col'">{{ animal.run_date }}</mat-cell>
          </ng-container>

          <!-- Platform Column -->
          <ng-container matColumnDef="platform">
            <mat-header-cell *matHeaderCellDef [ngClass]="'med-col'"> Platform </mat-header-cell>
            <mat-cell *matCellDef="let animal" [ngClass]="'med-col'">{{ animal.platform }}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns" (click)="editable ? selections.toggle(row) : null">
          </mat-row>
        </mat-table>

        <div id="info-container">
          <h5 *ngIf="!inventory.data.length && !missingAnimals.data.length && !apiErrorMsg" style="padding: 0 2em">
            It appears that this dataset is missing at least one genotype array (step 1) and/or animal info (step 2). If
            you've uploaded both, please ensure they are the correct files. Available inventory is dependent on matching
            animal IDs in both the array data and animal info.
          </h5>
          <p *ngIf="saveError" class="top-margin-1 error">{{ saveError }}</p>
          <ng-container *ngIf="apiErrorMsg">
            <p class="top-margin-1 error api-error">
              {{ apiErrorMsg }}
              <button
                mat-icon-button
                aria-label="Retry"
                matTooltip="Retry inventory retrieval"
                color="warn"
                (click)="retryGet.emit()"
              >
                <mat-icon>replay</mat-icon>
              </button>
            </p>
          </ng-container>
          <p *ngIf="showSaveSuccess" class="top-margin-1 success">Selections were saved successfully!</p>
          <button
            *ngIf="selectionsChanged"
            mat-icon-button
            color="primary"
            matTooltip="See what changed"
            (click)="showChangelog()"
          >
            <mat-icon>info_outline</mat-icon>
          </button>
          <div
            *ngIf="editable && inventory.data.length"
            class="button-tooltip-container"
            [matTooltip]="!selectionsChanged ? 'No changes to save' : ''"
          >
            <button
              mat-flat-button
              color="primary"
              [disabled]="!selectionsChanged"
              (click)="save.emit(currentSelections)"
            >
              <mat-icon>check</mat-icon> Save Selections ({{ numSelections || 0 }})
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="col-sm-12 col-lg-4 bottom-margin-1">
    <div class="table-container mat-elevation-z2">
      <div class="top-1 align-center">
        <h4>Animals with Missing Genotype Data ({{ missingAnimals?.data.length || 0 }})</h4>

        <p>
          Any mice listed in this table were found in the submitted animal information but did not have entries in any
          of the submitted sample maps.
          <span (click)="showMissingMiceInfo()">More Info</span>
        </p>
      </div>

      <ng-container *ngIf="missingAnimals">
        <div class="filter">
          <mat-form-field>
            <input
              matInput
              #missingFilter
              [placeholder]="'Search ' + missingAnimals.data.length + ' animals'"
              (keyup)="applyFilter(missingAnimals, $event.target.value)"
            />
            <button
              *ngIf="missingFilter.value"
              mat-icon-button
              matSuffix
              aria-label="clear"
              (click)="clearFilter(missingAnimals, missingFilter)"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>

        <mat-table id="missing-animals-table" [dataSource]="missingAnimals">
          <!-- ID Column -->
          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef> Animal ID </mat-header-cell>
            <mat-cell *matCellDef="let animal">
              {{ animal.mouse_id }}
              <span *ngIf="animal.mouse_id !== animal.correct_id" matTooltip="The correct animal ID for this mouse">
                ({{ animal.correct_id }})
              </span>
            </mat-cell>
          </ng-container>

          <!-- Sex Column -->
          <ng-container matColumnDef="sex">
            <mat-header-cell *matHeaderCellDef> Sex </mat-header-cell>
            <mat-cell *matCellDef="let animal">
              {{ animal.sex }}
            </mat-cell>
          </ng-container>

          <!-- DO Generation Column -->
          <ng-container matColumnDef="doGen">
            <mat-header-cell *matHeaderCellDef> DO Gen </mat-header-cell>
            <mat-cell *matCellDef="let animal">
              {{ animal.do_generation }}
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns.slice(1, 4); sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns.slice(1, 4)"></mat-row>
        </mat-table>
      </ng-container>
    </div>
  </div>
</ng-container>
