// This file uses Highcharts and because I'm not familiar, I don't feel
// confident in resolving linting issues for it
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import * as Highcharts from 'highcharts';
import { Observable, Subscription } from 'rxjs';

// utils
import { meanStddev } from '../utils';

// environments
import { environment } from '../../../../environments/environment';

// services
import { ProjectService } from '../../../services/project.service';

declare let require: any;
const Boost = require('highcharts/modules/boost');
const More = require('highcharts/highcharts-more');

Boost(Highcharts);
More(Highcharts);

@Component({
  selector: 'qc-statistical',
  templateUrl: './qc-statistical.component.html',
  styleUrls: ['../data-validation.scss'],
})
export class QCStatisticalComponent implements OnInit {
  projectId: string | null = '';
  // 'false' when 'project id' is either invalid or when user does not have permission to access the project
  isIdInvalid = false;

  // project object that is displayed in the page (and edited when user makes modifications)
  project: any = { projdoc: {} };
  // project object that represents the state of the project as of the last import (user to compare with
  // the project object when saving/exporting in order to determine what has changed)
  dbProject: any = { projdoc: {} };

  phenoMeasures: any[] = [];

  // available formats to export the table data:
  // check valid options in TableComponent class
  exportFormats: string[] = ['xlsx', 'csv', 'txt', 'json'];

  measTableColumns = [
    { key: 'measnum', label: 'Measure ID', width: '2', inline_label: 'measure' },
    { key: 'varname', label: 'Name', width: '2', inline_label: 'varname' },
    { key: 'descrip', label: 'Description', width: '4', inline_label: 'descrip' },
    { key: 'intervention', label: 'Intervention', width: '2', inline_label: 'intervention' },
    { key: 'units', label: 'Units', width: '2', inline_label: 'units' },
    { key: 'datatype', label: 'Data Type', width: '2', inline_label: 'datatype' },
    { key: 'nstrainstested', label: 'Strains #', width: '2', inline_label: 'nstrainstested' },
    { key: 'granularity', label: 'Granularity', width: '2', inline_label: 'granularity' },
  ];

  myColumns = [
    { key: 'id', label: 'ID', width: '4', inline_label: 'id' },
    { key: 'projsym', label: 'Symbol', width: '2', inline_label: 'projsym' },
    { key: 'measnum', label: 'Measure', width: '2', inline_label: 'measnum' },
    { key: 'sex', label: 'Sex', width: '2', inline_label: 'sex' },
    { key: 'strain', label: 'Strain', width: '2', inline_label: 'strain' },
    { key: 'varname', label: 'Varname', width: '2', inline_label: 'varname' },
    { key: 'value', label: 'Value', width: '2', inline_label: 'value' },
    { key: 'zscore', label: 'Z-score', width: '2', inline_label: 'zscore' },
  ];

  // Highchart's chart object
  chart: any = null;

  subscription: Subscription | undefined;
  // plot categories on the first xAxis
  categoriesX0: string[] = [];
  // plot categories on the second xAxis
  categoriesX1: string[] = [];
  // quick look-up mapping between plot categoris and their data
  strainsMapping: any = {};
  // measure data
  qcdata: any[] = [];

  selectedTableData: {
    id: string;
    projsym: string;
    measnum: number;
    sex: string;
    strain: string;
    value: number;
    varname: string;
    zscore: number;
  }[] = [];

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private projectService: ProjectService,
  ) {}

  ngOnInit() {
    this.projectId = this.route.snapshot.paramMap.get('id') || null;

    if (Number(this.projectId)) {
      this.pullProjectData();
    } else {
      this.isIdInvalid = true;
    }
  }

  /**
   * subscribes to an observable service, which returns project information;
   * populates the 'import_msgs' array with data that will populate a table.
   */
  pullProjectData(): void {
    if (this.projectId) {
      this.projectService.getProject(this.projectId).subscribe(
        (data) => {
          this.isIdInvalid = data.table_form;
          if (!this.isIdInvalid) {
            this.project = data;

            this.dbProject = JSON.parse(JSON.stringify(data));

            if (data.pheno_measures) {
              this.phenoMeasures = data.pheno_measures;
            }
          }
        },
        () => {
          this.isIdInvalid = true;
        },
      );
    }
  }

  /**
   * handles row selection event: captures the measure ID and
   * subscribes to an observable to receive this measure's data.
   * @param event - the triggered event object
   */
  handleRowSelection(event?: any): void {
    if (event.length > 0) {
      const measure = {
        id: event[0].measnum,
        name: event[0].varname,
        units: event[0].units,
      };

      // clear collection
      this.qcdata.length = 0;
      const apiLink = environment.securedURLs.sip + 'projects/phenotypes/measure/continuousanimaldata/' + measure.id;

      this.subscription = this.getApiResponse(apiLink).subscribe(
        (data) => {
          data.animaldata.forEach((element: any[]) => {
            this.qcdata.push(element);
          });
          this.setplotCategories();
          this.setplotData();

          if (this.chart !== null) {
            this.chart.destroy();
          }

          this.render(measure);
        },
        () => {
          console.log('Something went wrong.');
        },
      );
    }
  }

  /**
   * Inverts the chart based on the specific checkbox selection
   * @param {any} event - an object representing the triggered event
   */
  chartInvert(event: any) {
    const strainsOnX = event.target.checked;
    this.chart.update({
      chart: { inverted: !strainsOnX },
    });
    this.chart.redraw();
  }

  /**
   * Orders the strain categories alphabetically or based on mean values
   */
  orderStrains() {
    // [gik 09/15/20] this is low priority, optional feature
    // to be possibly added in future
  }

  getApiResponse(url: string): Observable<any> {
    return this.http.get<any[]>(url, {});
  }

  /**
   * sets the data categories - each strain and sex  have a separate
   * category: such as C57BL/6J(M), C57BL/6J(F), LP/J(M), LP/J(F), ...
   * also, an object for fast category data look-up is populated.
   */
  setplotCategories(): void {
    // clear collections before re-populating them
    this.categoriesX0 = [];
    this.categoriesX1 = [];
    this.strainsMapping = {};

    this.qcdata.forEach((sample) => {
      const sex = sample.sex.toUpperCase();
      const strain = sample.strain;

      const name = strain + '(' + sex + ')';

      if (this.categoriesX0.indexOf(name) < 0) {
        // the categories on the first xAxis are used to plot the data
        this.categoriesX0.push(strain + '(F)');
        this.categoriesX0.push(strain + '(M)');
        // the categories on the second xAxis are used to display the axis labels
        this.categoriesX1.push(strain);

        this.strainsMapping[strain + '(F)'] = {
          name: strain + '(F)',
          data: [],
          meanStdDev: {},
        };

        this.strainsMapping[strain + '(M)'] = {
          name: strain + '(M)',
          data: [],
          meanStdDev: {},
        };
      }
    });
  }

  /**
   * sets the data into the appropriate categories structures and
   * calculates the mean and standard deviation for each category
   */
  setplotData(): void {
    // distribute data into plotting categories (based on strains)
    this.qcdata.forEach((d: any) => {
      const name = d.strain + '(' + d.sex.toUpperCase() + ')';
      // category index
      const index = this.categoriesX0.indexOf(name);

      if (index > -1) {
        this.strainsMapping[name].data.push({
          x: index,
          y: d.value,
          id: d.animal_id,
          projsym: d.projsym,
          measnum: d.measnum,
          sex: d.sex,
          strain: d.strain,
          varname: d.varname,
          zscore: d.zscore,
        });
      }
    });

    // calculate mean and standard error where data is available
    for (const p in this.strainsMapping) {
      if (Object.prototype.hasOwnProperty.call(this.strainsMapping, p)) {
        if (this.strainsMapping[p].data.length > 0) {
          this.strainsMapping[p].meanStdDev = meanStddev(this.strainsMapping[p].data.map((e: any) => e.y));
        }
      }
    }
  }

  /**
   * creates an object specifying this plot's properties and
   * calls Highchart's charting method with that object as argument
   */
  render(measure?: any): void {
    const series: any[] = [
      {
        name: 'Female',
        color: 'red',
        xAxis: 0,
        marker: {
          symbol: 'circle',
        },
        data: [],
      },
      {
        name: 'Male',
        color: 'blue',
        xAxis: 0,
        marker: {
          symbol: 'circle',
        },
        data: [],
      },
      {
        xAxis: 1,
        visible: false,
        name: '',
        data: [],
        showInLegend: false,
      },
    ];

    // populate the 'series' array (and property)
    for (const p in this.strainsMapping) {
      if (Object.prototype.hasOwnProperty.call(this.strainsMapping, p)) {
        if (p.substring(p.length - 2, p.length - 1) === 'F') {
          for (let i = 0; i < this.strainsMapping[p].data.length; i++) {
            series[0].data.push(this.strainsMapping[p].data[i]);
          }
        } else {
          for (let i = 0; i < this.strainsMapping[p].data.length; i++) {
            series[1].data.push(this.strainsMapping[p].data[i]);
          }
        }
      }
    }

    // Working with Highcharts - not familiar so allowing this for now
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;

    const selectedIds: string[] = [];

    // plot options/properties
    const options: any = {
      chart: {
        type: 'scatter',
        zoomType: 'xy',
        ignoreHiddenSeries: false,
        inverted: false,
        panning: true,
        panKey: 'shift',
        renderTo: 'container',
      },
      title: {
        text: measure.id ? measure.id : null,
      },
      subtitle: {
        text: measure.name ? measure.name : null,
      },
      xAxis: [
        {
          lineColor: 'black',
          lineWidth: 1,
          title: {
            text: null,
          },
          categories: this.categoriesX0,
          labels: {
            enabled: true,
            rotation: -90,
          },
          min: 0,
          max: this.categoriesX0.length - 1,
          visible: false,
          tickWidth: 1,
          tickColor: '#000000',
          tickmarkPlacement: 'between',
          gridLineWidth: 1,
        },
        {
          lineColor: 'black',
          lineWidth: 1,
          categories: this.categoriesX1,
          labels: {
            enabled: true,
            rotation: -90,
          },
          min: 0,
          max: this.categoriesX1.length - 1,
          tickWidth: 1,
          tickColor: '#000000',
          tickmarkPlacement: 'between',
          gridLineWidth: 1,
        },
      ],
      yAxis: {
        lineColor: 'black',
        lineWidth: 1,
        gridLineWidth: 1,
        labels: {
          enabled: true,
        },
        title: {
          text: measure.units ? measure.units : measure.name,
        },
        tickWidth: 1,
      },
      tooltip: {
        formatter: function () {
          return (
            '<b>' +
            this.point.options.id +
            '</b><br>' +
            this.x +
            '<br>' +
            this.y +
            '<br>z-score: ' +
            this.point.options.zscore
          );
        },
      },
      plotOptions: {
        series: {
          allowPointSelect: true,
          stickyTracking: false,
          point: {
            events: {
              select: function () {
                const id: string = this.options.id;
                const index: number = selectedIds.indexOf(id);

                if (index > -1) {
                  selectedIds.splice(index, 1);
                  that.selectedTableData.splice(index, 1);

                  that.selectedTableData = [...that.selectedTableData];
                } else {
                  selectedIds.push(this.options.id);
                  that.selectedTableData.push({
                    id: this.options.id,
                    projsym: this.options.projsym,
                    measnum: this.options.measnum,
                    sex: this.options.sex,
                    strain: this.options.strain,
                    value: this.y,
                    varname: this.options.vrname,
                    zscore: this.options.zscore,
                  });
                  that.selectedTableData = [...that.selectedTableData];
                }

                that.chart.series.forEach((s: any) => {
                  s.points.forEach((point: any) => {
                    if (selectedIds.indexOf(point.id) > -1) {
                      point.update({ color: 'yellow' });
                    } else {
                      point.update({ color: s.color });
                    }
                  });
                });

                // cancels the operation
                return false;
              },
            },
          },
        },
        scatter: {
          jitter: {
            x: 0.24,
            y: 0,
          },
        },
      },
      legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'top',
        floating: false,
      },
      series: series,
      credits: {
        enabled: false,
      },
    };

    this.chart = new Highcharts.Chart(options);
  } // END render()
}
