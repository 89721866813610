import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { InstitutionSearchComponent } from '../../entity-searching/institution-search.component';
import { InstitutionService } from '../../../services/institution.service';
import { PersonService } from '../../../services/person.service';
import { flashMessage } from '../../utils';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog.component';

@Component({
  selector: 'edit-institution',
  templateUrl: 'edit-institution.component.html',
  styleUrls: ['../editing.scss'],
})
export class EditInstitutionComponent implements OnInit {
  // true to embed this component, false to not
  @Input() embed = false;

  // instutition object
  @Input() institution: any = {};

  // emit the change when an institution is saved or deleted
  @Output() institutionChange: EventEmitter<any> = new EventEmitter<any>();

  // event for cancel button click (only displayed on embed)
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();

  newInstitution = true;

  // TRUE, if current user has curation permissions = True
  isCurator = false;

  @ViewChild(InstitutionSearchComponent) institutionSearchComponent!: InstitutionSearchComponent;

  nameTaken = false;

  showPeople = false;

  // class constructor
  constructor(
    private institutionService: InstitutionService,
    private personService: PersonService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit() {
    if (this.institution.id) {
      this.institutionService.getInstitution(this.institution.id).subscribe((data) => {
        this.institution = data;
        this.newInstitution = false;
        // don't emit here! this is the initial retreive for when this component is embedded
      });
    }

    this.personService.currentUser$.subscribe(() => {
      this.isCurator = this.personService.isCurator();
    });
  }

  /**
   * deletes selected institution;
   * users are prompted to confirm the deletion
   */
  deleteInstitutionRecord(): void {
    if (!this.newInstitution) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          header: 'Institution Deletion Confirmation',
          message:
            'Are you sure that you wish to delete this institution?' +
            (this.institution.peoplecount
              ? '<br>This institution is associated with one or more people, ' +
                '<br>so deleting it will result in it being removed from those people.'
              : ''),
          falselabel: 'Cancel',
          truelabel: 'Delete',
          truebtn: 'btn-danger',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.institutionService.deleteInstitution(this.institution.id).subscribe(() => {
            this.institutionSearchComponent.clearSelection();
            this.institutionChange.emit(this.institution);
          });
        }
      });
    }
  }

  /**
   * creates simple comma-separated, JSON-formatted list of all
   * people associated with this institution.
   */
  displayPeople(): string {
    const people = [];

    for (const p of this.institution.people) {
      people.push('{firstname: ' + p.firstname + ', lastname: ' + p.lastname + ', email: ' + p.email + '}');
    }
    return people.join(', ');
  }

  /**
   * Figure out if the current user is allowed to edit the institution
   *
   * @returns {any}: true if the current user can edit the institution, false if not
   */
  canEdit(): boolean {
    // curators can edit anything
    if (this.isCurator) {
      return true;
    }
    // this is a new institution, so can edit
    if (this.newInstitution) {
      return true;
    }
    // creator can edit until a curator approves
    return this.personService.isCurrentUser(this.institution.user_creator.id);
  }

  /**
   * Figure out if the current user is allowed to delete the institution
   *
   * @returns {any}: true if the current user can delete the institution, false if not
   */
  canDelete(): boolean {
    // can't delete something that doesn't exist
    if (this.newInstitution) {
      return false;
    }
    // can't delete if can't edit
    if (!this.canEdit()) {
      return false;
    }
    // if there are 0 associated people, then they can delete, if more than 1, then can not
    if (this.institution.peoplecount !== 1) {
      return this.institution.peoplecount === 0;
    }
    // if there is only 1 associated person, and it happens to be the current user, then they can delete,
    // otherwise they cannot delete
    return this.personService.isCurrentUser(this.institution.people[0].id);
  }

  /**
   * on institution change, set certain flags and values
   * related to people/users associated with this institution.
   *
   */
  onInstitutionChange(): void {
    this.showPeople = false;

    if (this.institution.id) {
      this.institutionService.getInstitution(this.institution.id).subscribe((data) => {
        this.institution = data;
        this.institutionChange.emit(this.institution);
        this.newInstitution = false;
      });
    } else {
      this.newInstitution = true;
    }
  }

  /**
   * sends request to the service responsible for recording this institution's
   * information in the database; validates that the 'name', 'city' and 'country'
   * argument values are set and valid;
   * @return none
   */
  saveInstitutionRecord(): void {
    if (this.institution.name && this.institution.city_no_state && this.institution.country) {
      this.institutionService.saveInstitution(this.institution).subscribe(
        (result) => {
          this.institution = result;
          this.institutionChange.emit(this.institution);
          this.newInstitution = false;
          flashMessage(this.snackBar, 'Save successful!', 'alert-success', 2000);
        },
        () => {
          flashMessage(this.snackBar, 'Save failed.', 'alert-danger', 5000);
        },
      );
    }
  }

  get saveDisabled(): boolean {
    return (
      !this.institution.name ||
      !(this.institution.city_no_state ? this.institution.city_no_state.trim() : false) ||
      !(this.institution.country ? this.institution.country.trim() : false)
    );
  }

  get missingInfoTooltip(): string {
    return 'Institution name, city and country must be set and available to save this record';
  }

  /**
   * validates this institution's name - (name, city, country) tuples must be unique
   * @return none
   */
  validateInstitutionName(): void {
    this.nameTaken = false;

    const cachedInstitutions = this.institutionService.institutions.getValue();

    // remove white spaces from both ends of the entered institution name
    const trimmedName: string = this.institution.name ? this.institution.name.trim() : '';

    for (let i = 0; i < cachedInstitutions.length; i++) {
      if (trimmedName && trimmedName === cachedInstitutions[i].name) {
        this.nameTaken = true;
      }
    }
  }
}
