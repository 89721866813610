import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { EditPersonComponent } from '../edit-person/edit-person.component';
import { PersonSearchComponent } from '../../entity-searching/person-search.component';
import { PersonService } from '../../../services/person.service';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog.component';

@Component({
  selector: 'edit-investigator',
  templateUrl: './edit-investigator.component.html',
  styleUrls: ['../editing.scss'],
})
export class EditInvestigatorComponent implements OnInit {
  // api string
  api: string = environment.securedURLs.sip;

  // 2-way binding id for the edit-user-profile-form
  profileID: number | null = null;

  // true if the current user is a curator, false if they are not
  isCurator = false;

  // personSearch object selected by the curator
  personSearch: any = {};

  // value selected for the 'Users only" checkbox on the curator personSearch filtering
  onlyUsers = false;

  // variable for accessing the profile form
  @ViewChild(EditPersonComponent) person!: EditPersonComponent;

  // variable for accessing the profile search
  @ViewChild(PersonSearchComponent) personSearchComponent!: PersonSearchComponent;

  // declare http client and services
  constructor(public http: HttpClient, private personService: PersonService, public dialog: MatDialog) {}

  // on init, load the current user's profile
  ngOnInit() {
    this.personService.currentUser$.subscribe(() => {
      this.isCurator = this.personService.isCurator();
    });
  }

  /**
   * Deletes the selected investigator, after confirming that the user is allowed to and they are sure.
   */
  deleteInvestigator(): void {
    if (this.profileID && this.person.profile.candelete) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          header: 'Investigator Deletion Confirmation',
          message: 'Are you sure that you wish to delete this invetigator?',
          falselabel: 'Cancel',
          truelabel: 'Delete',
          truebtn: 'btn-danger',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result && this.profileID) {
          this.personService.deletePerson(this.profileID).subscribe(() => {
            this.personSearchComponent.clearSelection();
          });
        }
      });
    }
  }

  /**
   * Saves the investigator in the edit profile form and updates the selected person in the search component
   */
  saveInvestigator() {
    if (this.person.profile.allow_changes)
      this.person.saveProfile().subscribe((result) => {
        this.personSearch = result;
      });
  }

  /**
   * On investigator change in the lookup, either load the selected person into the edit form,
   * or clear the edit form as appropriate.
   */
  onInvestigatorChange(): void {
    if (this.personSearch.id) {
      this.profileID = this.personSearch.id;
    } else {
      this.person.newProfile();
    }
  }
}
