import { DivdbDatasetStatus } from '../../../services/divdb.service';

export class DatasetStatus {
  // flat list of all statuses for the dataset
  statusHistory: DivdbDatasetStatus[];

  // list of statuses grouped by processing attempt
  processingHistory: DivdbDatasetStatus[][];

  // list of statuses group by haplotype reconstruction start
  haplotypeReconstructionHistory: DivdbDatasetStatus[][];

  // most recent processing attempt (group of statuses)
  currentProcessAttempt: DivdbDatasetStatus[];

  // true if an error has occurred and the connection to the status polling needs to be restarted
  disconnected = false;

  // true if the dataset has been submitted for processing at least once
  submitted = false;

  constructor(statuses: DivdbDatasetStatus[]) {
    this.statusHistory = statuses;

    const processing = this.statusHistory.filter((s) => s.type !== 'HAPLOTYPE_RECONSTRUCTION');
    processing.forEach((s) => {
      if (!s.start_time_date) {
        s.start_time_date = new Date(`${s.start_time} UTC`);
      }

      // the messages are a completion report, empty the messages array (which
      // is used primarily for displaying errors in the UI) and create a report
      // object from the stringified JSON
      if (s.messages && s.messages.length && s.status === 'COMPLETE') {
        const reports = s.messages;

        // TODO: I'm starting to find mixed formats of the completion results/reports
        if (reports[0].results) {
          s.report = reports[0].results;
        } else if (reports[0].error_message) {
          s.report = JSON.parse(reports[0].error_message.replace(/'/g, '"')).results;
        }
      }
    });

    processing.sort((s1, s2) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - the linter is not picking up the forEach assignment made above
      s1.start_time_date > s2.start_time_date ? 1 : -1,
    );

    this.processingHistory = this.extractProcessingBatch(processing);

    // get the most recent processing status
    if (this.processingHistory.length) {
      this.currentProcessAttempt = this.processingHistory[this.processingHistory.length - 1];
      this.submitted = true;
    } else {
      this.currentProcessAttempt = [];
      this.submitted = false;
    }

    const haplotype = this.statusHistory.filter((s) => s.type === 'HAPLOTYPE_RECONSTRUCTION');
    haplotype.forEach((s) => {
      if (!s.start_time_date) {
        s.start_time_date = new Date(`${s.start_time} UTC`);
      }

      if (s.messages) {
        s.messages.forEach((msg) => {
          if (msg.start_time) {
            const startDate = new Date(msg.start_time);
            msg.start_time = startDate.toString() !== 'Invalid Date' ? startDate : null;

          }

          if (msg.end_time) {
            const endDate = new Date(msg.end_time);
            msg.start_time = endDate.toString() !== 'Invalid Date' ? endDate : null;
          }
        });
      }
    });

    haplotype.sort((s1, s2) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - the linter is not picking up the forEach assignment made above
      s1.start_time_date > s2.start_time_date ? 1 : -1,
    );

    this.haplotypeReconstructionHistory = this.extractHaplotypeBatch(haplotype);
  }

  /**
   * Returns a list of batched statuses where each batch/group of statuses represents a processing attempt
   * @param statuses - list of statuses is to batch
   */
  extractProcessingBatch(statuses: DivdbDatasetStatus[]): DivdbDatasetStatus[][] {
    if (!statuses.length) {
      return [];
    }

    // add the first status (a preprocessing task) to the batch automatically
    const batch = [statuses[0]];

    // look for other preprocessing tasks by looking for matching timestamps and add them
    batch.push(
      ...statuses.filter((s) => s.start_time_date === batch[0].start_time_date && s.run_id !== batch[0].run_id)
    );

    // currBatch should contain all preprocessing statuses for the processing attempt;
    // if any of them have completed, look for genotype loading statuses
    const numIPTasksComplete = batch.filter((s) => s.status === 'COMPLETE').length;
    if (numIPTasksComplete) {
      const startIdx = batch.length;

      // add the next {number of complete IP tasks} statuses to the batch
      // i.e. if the current batch has 2 preprocessing tasks but only one completed,
      // the processing attempt should only have a single genotype loading status
      batch.push(...statuses.slice(startIdx, startIdx + numIPTasksComplete));
    }

    // get the 'unbatched' statuses that need to be batched
    const batchedRunIDs = batch.map((s) => s.run_id);
    const remainingStatuses = statuses.filter((s) => batchedRunIDs.indexOf(s.run_id) < 0);

    return [batch, ...this.extractProcessingBatch(remainingStatuses)];
  }

  /**
   * Returns a list of batched statuses where each batch/group of statuses represents a haplotype reconstruction attempt
   * @param statuses - list of statuses is to batch
   */
  extractHaplotypeBatch(statuses: DivdbDatasetStatus[]): DivdbDatasetStatus[][] {
    if (!statuses.length) {
      return [];
    }

    // add the first status (a preprocessing task) to the batch automatically
    const batch = [statuses[0]];

    // look for other haplotype reconstruction tasks that kicked off within the same 5 second span
    // (which, if using the UI, should only be possible if the pipelines were started by the same event
    // and represent multiple platofmrs
    batch.push(
      ...statuses.filter(
        (s) =>
          s.run_id !== batch[0].run_id &&
          Math.abs(s.start_time_date.getTime() - batch[0].start_time_date.getTime()) < 5000,
      ),
    );

    // get the 'unbatched' statuses that need to be batched
    const batchedRunIDs = batch.map((s) => s.run_id);
    const remainingStatuses = statuses.filter((s) => batchedRunIDs.indexOf(s.run_id) < 0);

    return [batch, ...this.extractHaplotypeBatch(remainingStatuses)];
  }

  /**
   * Returns true if at least one of the statuses in the latest processing attempt is running
   */
  get isProcessing(): boolean {
    if (this.currentProcessAttempt.length) {
      return this.currentProcessAttempt.some((s) => this.isRunning(s));
    }
    return false;
  }

  /**
   * Returns true if the latest processing attempt has stopped or been cancelled and the dataset
   * has been submitted at least once
   */
  get shouldHaveProcessingReport(): boolean {
    return (!this.isProcessing || this.statusDisplay === 'CANCELED') && this.submitted && !this.disconnected;
  }

  /**
   * Returns true if the latest haplotype reconstruction attempt has stopped or been cancelled
   */
  get shouldHaveHaplotypeReport(): boolean {
    return this.haplotypeStatus !== 'RUNNING';
  }

  /**
   * Returns true if the specified status isn't in a static state
   * @param status - status code for the process
   */
  isRunning(status: DivdbDatasetStatus): boolean {
    if (status) {
      return ['QUEUED', 'RUNNING'].indexOf(status.status) >= 0;
    }

    return false;
  }

  /**
   * Returns true unless the dataset processing has been cancelled or the
   * dataset hasn't been submitted yet
   */
  get shouldPoll(): boolean {
    if (this.currentProcessAttempt.length) {
      return this.currentProcessAttempt.every((s) => {
        return this.submitted && s.status !== 'CANCELED';
      });
    }

    return false;
  }

  /**
   * Returns true if any of the statuses in the latest processing attempt has an error status
   */
  get hasErrored(): boolean {
    return this.currentProcessAttempt ? this.currentProcessAttempt.some((s) => this.taskErrored(s)) : false;
  }

  /**
   * Returns true if the specified status/task has an error-like status
   * @param task - task to check the status of
   */
  taskErrored(task: DivdbDatasetStatus): boolean {
    return task ? ['EXECUTOR_ERROR', 'FAILED'].indexOf(task.status) >= 0 : false;
  }

  /**
   * Returns a summarized status for the current processing attempt
   */
  get statusDisplay(): string {
    // if there's a system error message, then we're calling it a system error
    if (this.disconnected) {
      return 'STATUS DISCONNECTED';
    }

    // if the dataset hasn't been submitted yet
    if (!this.submitted) {
      return 'CREATED, NOT SUBMITTED';
    }

    // if at least something is queued or running (we're bulking it together at this time)
    if (this.isProcessing) {
      return 'PROCESSING';
    }

    // if everything finished successfully
    if (this.currentProcessAttempt.every((s) => s.status === 'COMPLETE')) {
      return 'COMPLETED';
    }

    // if any of the statuses are cancelled, that means the user initiated a
    // cancellation and should label the attempt as such
    if (this.currentProcessAttempt.some((s) => s.status === 'CANCELED')) {
      return 'CANCELED';
    }

    // if some stuff completed and some errored (also worth noting that the
    // case where some tasks completed, some errored and some are still running
    // would have been handled in the second condition and labeled as running
    if (
      this.currentProcessAttempt.some((s) => s.status === 'COMPLETE') &&
      this.currentProcessAttempt.some((s) => this.taskErrored(s))
    ) {
      return 'COMPLETED WITH ERRORS';
    }

    // if everything has errored (again, if some of it has errored and some
    // stuff is still running, the status would be flagged as running)
    if (this.currentProcessAttempt.some((s) => this.taskErrored(s))) {
      return 'FAILED';
    }

    // hopefully we shouldn't get here
    return 'UNKNOWN';
  }

  /**
   * Returns a summarized status for the current haplotype reconstruction attempt
   */
  get haplotypeStatus(): string {
    if (this.haplotypeReconstructionHistory.length) {
      const latestAttempt = this.haplotypeReconstructionHistory[this.haplotypeReconstructionHistory.length - 1];

      // if there's a system error message, then we're calling it a system error
      if (this.disconnected) {
        return 'STATUS DISCONNECTED';
      }

      // if at least something is queued or running (we're bulking it together at this time)
      if (latestAttempt.some((s) => this.isRunning(s))) {
        return 'RUNNING';
      }

      // if everything finished successfully
      if (latestAttempt.every((s) => s.status === 'COMPLETE')) {
        return 'COMPLETED';
      }

      // if any of the statuses are cancelled, that means the user initiated a
      // cancellation and should label the attempt as such
      if (latestAttempt.some((s) => s.status === 'CANCELED')) {
        return 'CANCELED';
      }

      // if some stuff completed and some errored (also worth noting that the
      // case where some tasks completed, some errored and some are still running
      // would have been handled in the second condition and labeled as running
      if (latestAttempt.some((s) => s.status === 'COMPLETE') && latestAttempt.some((s) => this.taskErrored(s))) {
        return 'PARTIALLY COMPLETED';
      }

      // if everything has errored (again, if some of it has errored and some
      // stuff is still running, the status would be flagged as running)
      if (latestAttempt.every((s) => this.taskErrored(s))) {
        return 'FAILED';
      }

      // hopefully we shouldn't get here
      return 'UNKNOWN';
    }

    return '';
  }

  /**
   * Returns the appropriate color to display the status with: green for successfully completed,
   * blue for running or queued, red for error or failure, orange for a partial completion, and
   * grey for canceled or not yet submitted
   */
  get statusColor(): string {
    if (['CREATED, NOT SUBMITTED', 'CANCELED', 'UNKNOWN'].indexOf(this.statusDisplay) >= 0) {
      return '#999';
    }

    if (this.isProcessing) {
      return '#0277bd';
    }

    if (this.statusDisplay === 'COMPLETED') {
      return '#0c0';
    }

    if (this.statusDisplay === 'COMPLETED WITH ERRORS' || this.disconnected) {
      return '#dd8b4c';
    }

    return '#c00';
  }

  /**
   * Returns the appropriate color to the haplotype reconstruction status with: green for successfully completed,
   * blue for running or queued, and red for error or failure
   */
  get haplotypeStatusColor(): string {
    if (['CANCELED', 'UNKNOWN'].indexOf(this.haplotypeStatus) >= 0) {
      return '#999';
    }

    if (this.haplotypeStatus === 'RUNNING') {
      return '#0277bd';
    }

    if (this.haplotypeStatus === 'COMPLETED') {
      return '#0c0';
    }

    if (this.haplotypeStatus === 'PARTIALLY COMPLETED' || this.disconnected) {
      return '#dd8b4c';
    }

    return '#c00';
  }
}
