import { throwError as observableThrowError, Observable, BehaviorSubject } from 'rxjs';

import { catchError, tap } from 'rxjs/operators';
import {
  Component,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  HostListener,
  ViewChild,
  OnInit,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { PersonService } from '../../../services/person.service';
import { buildObjectDiff, validateEmail } from '../../utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FlashMessageComponent } from '../../flash-message.component';
import { InstitutionSearchComponent } from '../../entity-searching/institution-search.component';

@Component({
  selector: 'edit-person',
  templateUrl: './edit-person.component.html',
  styleUrls: ['../editing.scss'],
})
export class EditPersonComponent implements OnInit, OnChanges, OnDestroy {
  // string to append to ids in order to avoid duplicate ids from multiple instances of this component in a page
  @Input() uniqueTag = '';
  // input to indicate that the current user should be used
  @Input() currentUser = false;
  // 2-way binding id of the person we are modifying. defaults to null, in which case saving will create a new person
  // and upon saving the id will be set to the id of the newly created person and emitted to the parent using idChange.
  // If this is passed-in (or changed) from the parent, then the person with that id will be loaded in.
  @Input() id = 0;

  // true to now autosave ondestroy or onbeforeunload
  @Input() noAutosave = false;

  // for keeping track of the status of the profile in the db (at import/save time)
  // (in parent component and here if needed)
  @Input() dbProfile: any = { allow_changes: true, institution: {}, editors: [], caneditpeople: [] };

  // true to require an email to save... used when this is creating an investigator with an email to share
  // a project with or add to a group (which turns them into a user)
  @Input() requireEmail = false;

  // event used to emit changes to the id
  // (can happen when saving a new person or clearing the component to allow creation of a new person)
  @Output() idChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() dbProfileChange: EventEmitter<any> = new EventEmitter<any>();

  // event flagging on save completion (emits the profile object)
  @Output() save: EventEmitter<any> = new EventEmitter<any>();

  // keep track of the currently loaded person id (discrepancy between id and what's currently loaded in profile)
  loadedID = 0;

  // object for keeping track of and modifying columns for the person. See the api build_person_json in the api
  // to see what is received in this object upon loading or a successful save, and see the put endpoint in the Profile
  // resource to see how values in this object are used to modify the person on a save
  profile: any = { allow_changes: true, institution: {}, editors: [], caneditpeople: [] };

  // on save, if the email is set and what is actually saved is null, then either the email was invalid or
  // already taken, so this string is populated and used as the placeholder (red text) in that event
  emailPlaceholder = '';

  // api string for http api calls
  api: string = environment.securedURLs.sip;

  // flag to show editors for this person
  showEditors = false;
  // currently selected person object in the person search for editors (users only)
  personSearch: any = {};

  // flag that the current user is a curator
  isCurator = false;
  // flag that the current user is a reviewer
  isReviewer = false;

  // set while a save is in progress (makes it so that another save won't begin while this one is in progress...also
  // can be referenced by parent components)
  currentlySaving = false;

  // set or unset when checking for email validity in the saveDisabled function... used to flag invalid emails
  invalidEmail = false;

  // need to be able to clear the selection in the group-search component
  @ViewChild(InstitutionSearchComponent) institutionSearch!: InstitutionSearchComponent;

  // declare http client and services
  constructor(public http: HttpClient, private personService: PersonService, private snackBar: MatSnackBar) {}

  ngOnInit() {
    // this is for initializing creation of an investigator from the autocomplete search
    if (this.dbProfile ? this.dbProfile.firstname : false) {
      this.profile.firstname = this.dbProfile.firstname;
    }
    if (this.dbProfile ? this.dbProfile.middlename : false) {
      this.profile.middlename = this.dbProfile.middlename;
    }
    if (this.dbProfile ? this.dbProfile.lastname : false) {
      this.profile.lastname = this.dbProfile.lastname;
    }
    if (this.dbProfile ? this.dbProfile.email : false) {
      this.profile.email = this.dbProfile.email;
    }
  }

  // on change, load in the profile if the parent changed the id
  ngOnChanges() {
    this.loadProfile(false);

    this.personService.currentUser$.subscribe(() => {
      this.isCurator = this.personService.isCurator();
      this.isReviewer = this.personService.isReviewer();
    });
  }

  // this function is called before the window is closed or the user navigates to a different domain
  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHander() {
    if (!this.noAutosave) {
      // autosave on unload
      this.saveProfile(true).subscribe();
    }
  }

  // this function is called when you go to a different page within the angular app
  ngOnDestroy() {
    if (!this.noAutosave) {
      // autosave on destroy
      this.saveProfile(true).subscribe();
    }
  }

  /**
   * Load the profile for the current value in id
   * @param {boolean} force: if force is true, then reload regardless of whether or not that id is already loaded,
   *                         otherwise, we only load if this.id hasn't been loaded yet
   */
  loadProfile(force: boolean) {
    if (force || (this.id && this.id !== this.loadedID) || (!this.id && this.currentUser)) {
      this.personService.getPerson(this.currentUser ? 0 : this.id).subscribe((data) => {
        this.loadedID = Number(data.id);
        if (this.id !== this.loadedID) {
          this.id = this.loadedID;
          this.idChange.emit(this.id);
        }
        this.profile = data;
        this.dbProfile = JSON.parse(JSON.stringify(data));
        this.dbProfileChange.emit(this.dbProfile);
      });
    }
  }

  /**
   * Return an observable to save the person. If an id hasn't been loaded, then the api will create a new person.
   * In that case, the new id is returned if the save is successful and that id is emitted back out to the parent.
   * Also, we get the new profile information back and populate it on save so that you can immediately see
   * if the save was successful (and which parts might not have succeeded).
   *
   * @param: autosave (boolean): true if this is an autosave, otherwise false
   * @returns {Observable<any>}: Result is the updated project object on success
   *                                (not all changes sent may have been allowed)
   */
  saveProfile(autosave: boolean = false): Observable<any> {
    if (this.profile.allow_changes && !this.currentlySaving) {
      this.currentlySaving = true;
      // only go through with saving if there are changes since the last import
      let output = buildObjectDiff(this.profile, this.dbProfile);
      if (!output.empty) {
        output = output.output;
        if (this.profile.id) {
          output.id = this.profile.id;
        }
        this.emailPlaceholder = '';
        const inputEmail = output.email;
        return this.personService.savePerson(this.profile).pipe(
          tap((data) => {
            this.id = Number(data.id);
            // this should only happen if no id was currently loaded, so a new person was created
            if (this.id && this.id !== this.loadedID) {
              this.idChange.emit(this.id);
            }

            this.loadedID = Number(data.id);
            this.profile = data;
            this.dbProfile = JSON.parse(JSON.stringify(data));
            this.dbProfileChange.emit(this.dbProfile);
            // if the email did not save successfully, then it was either invalid or already taken
            if (inputEmail && !data.email) {
              this.flashMessage('Save successful!', 'alert-success', 15000, [
                'However, the email you entered was either invalid or already in use, so it was not saved.',
              ]);
              this.emailPlaceholder = "'" + inputEmail + "' is invalid or taken.";
            } else {
              this.flashMessage('Save successful!', 'alert-success');
              this.save.emit(this.dbProfile);
            }
            this.currentlySaving = false;
          }),
          catchError((err) => {
            this.flashMessage('Save failed.', 'alert-danger', 5000);
            this.currentlySaving = false;
            // pass the error on to observers
            return observableThrowError(err);
          }),
        );
      } else {
        if (!autosave) {
          this.flashMessage('Save successful!', 'alert-success');
          this.save.emit(this.dbProfile);
        }
        this.currentlySaving = false;
      }
    }
    return new BehaviorSubject<any>(this.profile).asObservable();
  }

  /**
   * Use the "snackbar" to flash the passed-in message for the passed-in duration in
   * this passed-in class (panel).
   *
   * @param {string} message: message to show
   * @param {string} divClass: panel class to display the message in
   * @param {number} duration: duration to show the message
   * @param {string[]} warnings: list of warning strings to show
   */
  flashMessage(message: string, divClass: string = '', duration: number = 2000, warnings: string[] = []) {
    this.snackBar.openFromComponent(FlashMessageComponent, {
      duration: duration,
      data: {
        text: message,
        class: divClass,
        snackbar: this.snackBar,
        listcolor: '#CCCC00',
        listmessages: warnings,
      },
    });
  }

  // clear out the currently selected person so that a new person can be created
  // NOTE: this is currently not used anywhere in this component, but the parent component can
  // (and does in some places) call this function
  newProfile() {
    this.institutionSearch.clearSelection();
    this.profile = { allow_changes: true, institution: {}, editors: [], caneditpeople: [], owner: true };
    this.id = 0;
    this.loadedID = 0;
    this.idChange.emit(this.id);
    this.dbProfile = { allow_changes: true, institution: {}, editors: [], caneditpeople: [], owner: true };
    this.dbProfileChange.emit(this.dbProfile);
  }

  // when adding an editor, make sure the person selected in the lookup is not already an editor before
  // adding them to the list of editors
  addEditor() {
    if (typeof this.personSearch === 'object') {
      // make sure user is selected in the lookup
      if (this.personSearch.email) {
        let alreadyEditor = false;
        for (let i = 0; i < this.profile.editors.length; i++) {
          if (this.personSearch.id === this.profile.editors[i].id) {
            alreadyEditor = true;
            break;
          }
        }
        if (!alreadyEditor) {
          this.profile.editors.push(this.personSearch);
        }
      }
    }
  }

  /**
   * Remove the editor from the list of editors for the current person (this is only allowed if you are
   * the 'owner', which means this is the current user's profile and they have verified their email)
   * @param {any} editor: editor for whom the 'remove' button was clicked
   */
  removeEditor(editor: any) {
    if (typeof editor === 'object') {
      for (let i = 0; i < this.profile.editors.length; i++) {
        if (editor.id === this.profile.editors[i].id) {
          this.profile.editors.splice(i, 1);
          break;
        }
      }
    }
  }

  /**
   * Used to determine whether the save button should be disabled...
   * if either the first or last name is blank... also a valid email if it's required
   *
   * @returns {boolean}: returns true if either the first name or last name is blank
   *                     (used to control whether the save button(s) are disabled in parent components)
   */
  saveDisabled() {
    if (!this.profile.allow_changes) {
      return false;
    }

    if (!validateEmail(this.profile.email)) {
      // if email is set, then set invalid_email, otherwise leave false for now
      this.invalidEmail = !!this.profile.email;
      // if email is required, disable save
      if (this.requireEmail) {
        return true;
      }
    } else {
      this.invalidEmail = false;
    }

    // make sure first name and last name are set
    if (!this.profile.firstname || !this.profile.lastname) {
      return true;
    } else if (!this.profile.firstname.trim() || !this.profile.lastname.trim()) {
      return true;
    }
    return false;
  }

  /**
   * Tooltip for the save button... actually goes on a div that contains the save
   * button because this tooltip shows if the save button is disabled
   *
   * @returns {any} string tooltip containing info why the save button is disabled
   */
  saveDisabledTooltip() {
    if (this.saveDisabled()) {
      return (
        (this.requireEmail ? 'First Name, Last Name, and a valid Email' : 'First Name and Last Name') +
        ' are required to save'
      );
    }
    return '';
  }
}
