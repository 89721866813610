import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SelectionTreeComponent } from '../../../shared/selection-tree/selection-tree.component';

@Component({
  selector: 'animal-links',
  template: `
    <h2 mat-dialog-title>Select Animals to Link to PMID: {{ this.data.pubmedID }}</h2>
    <mat-dialog-content>
      <p>Select the animals you wish to link to the publication.</p>
      <selection-tree></selection-tree>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
      <button mat-button color="accent" mat-dialog-close>Cancel</button>
      <button mat-stroked-button color="primary" (click)="dialogRef.close(selections.checklistSelection)">Save</button>
    </mat-dialog-actions>
  `,
  styleUrls: ['./publication-links.component.scss'],
})
export class AnimalLinksComponent implements OnInit {
  @ViewChild(SelectionTreeComponent) selections!: SelectionTreeComponent;

  constructor(
    public dialogRef: MatDialogRef<AnimalLinksComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PublicationLinkData,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    // without this extra step, loadTree fails because the selection tree hasn't appeared yet
    this.cdr.detectChanges();

    const parentDisplayFn = !this.data.animals['All Animals']
      ? (nodeValue: string) => `Generation ${nodeValue} Animals`
      : null;

    // load the tree with the data
    this.selections.loadTree(this.data.animals, parentDisplayFn, null, this.data.selections);
  }
}

export interface PublicationLinkData {
  pubmedID: string;
  animals: Record<string, string[]>;
  selections: string[];
}
