// dialog for adding/editing/viewing procedures on-the-fly
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'procedure-dialog',
  template: `
    <mat-dialog-content>
      <edit-procedure
        [(procedure)]="data.procedure"
        [embed]="true"
        (cancel)="dialogRef.close()"
        (procedureChange)="dialogRef.close($event)"
      ></edit-procedure>
    </mat-dialog-content>
  `,
})
export class ProcedureDialogComponent {
  constructor(public dialogRef: MatDialogRef<ProcedureDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}
}
