<mat-toolbar>
  <h2 class="text-right">Curator Only Information</h2>
</mat-toolbar>
<br />

<timeline
  #timeline_component
  [projid]="projid"
  [invalidID]="invalidID"
  [loading]="loadingProject"
  [(projsym)]="project.projsym"
  [(title)]="project.title"
  [project]="project"
  (clickExternalLink)="state_buttons_component.openExternalUrlAfterSave($event)"
  (clickPageLink)="state_buttons_component.openPageLinkAfterSave($event)"
  (clickReleaseProject)="state_buttons_component.releaseProject()"
  (clickReimportProject)="state_buttons_component.reimportProject()"
  (clickChangeStatus)="state_buttons_component.changeProjectStatus($event)"
  (clickImportExport)="state_buttons_component.onClickProjectImportExport(timeline_component.projectActionsComponent)"
  (projectChanged)="getProject()"
  (projectRestored)="state_buttons_component.projectRestored()"
  [actionLoadingBar]="state_buttons_component.loadingBar"
></timeline>

<div [style.display]="invalidID || loadingProject ? 'none' : 'block'">
  <span style="padding-left: 18px; color: red" *ngIf="project.canEdit && !project.isCurator">
    <b>The values on this page are only modifiable by curators.</b><br /><br />
  </span>
  <mat-vertical-stepper>
    <mat-step label="Abstracts">
      <fieldset [disabled]="!project.isCurator">
        <br />
        <div class="form-horizontal">
          <div class="form-group">
            <label class="col-sm-3 control-label">Add Abstract for Method</label>
            <div class="col-sm-6">
              <methodology-search
                #method_search
                [(methodology)]="methodSearch"
                [disableList]="abstractMethods"
                [disabled]="!project.isCurator"
                (methodologyChange)="onMethodChange($event) ? method_search.clearSelection() : false"
              >
              </methodology-search>
            </div>
          </div>
        </div>
        <editable-table
          [columns]="abstractsColumns"
          [(rows)]="project.abstracts"
          [id]="'abstracts'"
          (rowsChange)="updateAbstractMethods()"
          [allowAdd]="false"
          [title]="'Abstracts'"
          [allowChanges]="project.isCurator"
        ></editable-table>
        <br />
      </fieldset>
      <div class="form-horizontal">
        <div class="form-group">
          <div class="col-sm-4 col-md-5"></div>
          <div class="col-sm-3 col-md-2">
            <button class="btn btn-info btn-block" matStepperNext id="step-1-next"><strong>Next</strong></button>
          </div>
        </div>
      </div>
    </mat-step>
    <mat-step label="Workflow and Sampling">
      <fieldset [disabled]="!project.isCurator">
        <editable-table
          [columns]="workflowColumns"
          [(rows)]="project.workflow"
          [id]="'workflow'"
          [allowChanges]="project.isCurator"
        ></editable-table>
        <br />
        <div class="row">
          <div class="col-xs-12">
            <div class="form-horizontal">
              <div class="form-group">
                <label for="ws-footnotes" class="col-sm-3 control-label">Footnotes (optional)</label>
                <div class="col-sm-6">
                  <input
                    type="text"
                    id="ws-footnotes"
                    class="form-control"
                    name="ws-footnotes"
                    maxlength="500"
                    placeholder="enter optional footnotes text"
                    [(ngModel)]="project.projdoc.workflownotes"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
      <hr width="80%" class="text-center" />
      <file-upload
        [allowEdit]="project.isCurator"
        [pointertype]="'projid'"
        [title]="'Workflow Images'"
        [pointer]="project.projid"
        [tag]="'workflow'"
        [imageOnly]="true"
      ></file-upload>
      <div class="form-horizontal">
        <div class="form-group">
          <div class="col-sm-3 col-md-4"></div>
          <div class="col-sm-3 col-md-2">
            <button class="btn btn-info btn-block" matStepperPrevious id="step-2-prev">
              <strong>Previous</strong>
            </button>
          </div>
          <div class="col-sm-3 col-md-2">
            <button class="btn btn-info btn-block" matStepperNext id="step-2-next"><strong>Next</strong></button>
          </div>
        </div>
      </div>
    </mat-step>
    <mat-step *ngIf="projid" label="Linked Projects">
      <div class="form-horizontal" style="padding-top: 20px; padding-bottom: 20px">
        <fieldset [disabled]="!project.isCurator">
          <div class="form-group">
            <label for="project-seriestag" class="col-sm-3 control-label required">Series Tag</label>
            <div *ngIf="project.seriestag" class="col-sm-6">
              <div [class]="project.isCurator ? 'input-group' : ''">
                <input
                  type="text"
                  [(ngModel)]="project.seriestag"
                  id="project-seriestag"
                  [disabled]="true"
                  class="form-control"
                  name="project-seriestag"
                  maxlength="50"
                />
                <div *ngIf="project.isCurator" class="input-group-btn">
                  <button
                    (click)="project.seriestag = null"
                    type="button"
                    matTooltip="Clear selected series"
                    class="btn btn-danger"
                  >
                    <span class="glyphicon glyphicon-remove clear-icon"></span>
                  </button>
                </div>
              </div>
            </div>
            <div [style.display]="project.seriestag ? 'none' : 'block'" class="col-sm-6">
              <combobox
                #series_component
                [htmlOptions]="true"
                [options]="projectSeriesList"
                [(value)]="seriesInput"
                [maxlength]="50"
                [displayKey]="'disphtml'"
                [showHtmlPreview]="false"
                [tooltipKey]="'tooltip'"
                [htmlAllow]="['ALL']"
                (optionSelected)="
                  project.seriestag = seriesInput['seriestag'];
                  seriesInput = null;
                  series_component.valueCtrl.setValue('');
                  updateSeriesProjects()
                "
              >
              </combobox>
            </div>
            <div
              *ngIf="!project.seriestag && project.isCurator"
              [matTooltip]="
                !series_component.valueCtrl.value
                  ? 'Enter a tag value and click this button to create a new project series.'
                  : ''
              "
              class="col-sm-1"
            >
              <button
                class="btn btn-success"
                [disabled]="!series_component.valueCtrl.value"
                [matTooltip]="!series_component.valueCtrl.value ? '' : 'Create new project series'"
                (click)="
                  project.seriestag = series_component.valueCtrl.value;
                  series_component.valueCtrl.setValue('');
                  updateSeriesProjects()
                "
              >
                <span class="glyphicon glyphicon-plus"></span>
              </button>
            </div>
          </div>
          <div class="form-group">
            <label for="seriesstub" class="col-sm-3 control-label required">Series Label</label>
            <div class="col-sm-6">
              <input
                type="text"
                [(ngModel)]="project.seriesstub"
                id="seriesstub"
                (change)="updateSeriesProjects()"
                class="form-control"
                name="seriesstub"
                maxlength="120"
              />
            </div>
          </div>
          <div [style.display]="!project.seriestag ? 'none' : 'block'">
            <div align="center">
              <span style="font-size: 20px; font-weight: 500">Projects In this Series</span>
              <br /><br />
              <span *ngIf="proj_series_component.projects.length === 0">No projects are in this series yet.</span>
            </div>
            <projects
              #proj_series_component
              [embed]="true"
              [showingSeries]="true"
              [filterString]="'?seriestag=' + project.seriestag"
            ></projects>
          </div>
          <br />
        </fieldset>
      </div>
      <div class="form-horizontal">
        <div class="form-group">
          <div class="col-sm-4 col-md-5"></div>
          <div class="col-sm-3 col-md-2">
            <button class="btn btn-info btn-block" matStepperPrevious id="step-5-prev">
              <strong>Previous</strong>
            </button>
          </div>
        </div>
      </div>
    </mat-step>
  </mat-vertical-stepper>
  <br />
  <br />
  <state-buttons
    [(project)]="project"
    [(dbProject)]="dbProject"
    #state_buttons_component
    (clickTitleLink)="timeline_component.titleInput.nativeElement.focus()"
    (saveFail)="timeline_component.setActive()"
    [currentPage]="'/curator-only/'"
  ></state-buttons>
</div>
