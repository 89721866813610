import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PanelSearchComponent } from '../../entity-searching/panel-search.component';
import { StrainSearchComponent } from '../../entity-searching/strain-search.component';
import { StrainService } from '../../../services/strain.service';
import { PersonService } from '../../../services/person.service';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog.component';
import { flashMessage } from '../../utils';
import { StrainTypeSearchComponent } from '../../entity-searching/strain-type-search.component';
import { StrainVendorSearchComponent } from '../../entity-searching/strain-vendor-search.component';

@Component({
  selector: 'edit-strain',
  templateUrl: 'edit-strain.component.html',
  styleUrls: ['../editing.scss'],
})
export class EditStrainComponent implements OnInit {
  // true to embed this component, false to not
  @Input() embed = false;

  // strain object we're creating/modifying
  @Input() strain: any = { strainvendor: {}, panel: {}, straintype_dict: {} };

  // emit the change when an strain is saved or deleted
  @Output() strainChange: EventEmitter<any> = new EventEmitter<any>();

  // event for cancel button click (only displayed on embed)
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();

  newStrain = true;

  // TRUE, if the current user has curation persmissions = True
  isCurator = false;

  isAnameWhitespace = false;
  longnameTaken = false;
  isStocknumTaken = false;

  // stock number class attribute values change:
  // input becomes required when JAX is selected as the strain vendor
  stockNumberClass = 'col-sm-3 control-label';
  // stock number error message attribute
  stockNumberErrorMessage = '';

  disableSaveMsg = 'Strain name must be available and all required fields populated to save this strain.';
  // stock number is required when The Jackson Laboratory
  // has been selected as the strain vendor
  isStocknumRequired = false;

  // true to show linked projects
  showProjects = false;

  @ViewChild(PanelSearchComponent) panelSearchComponent!: PanelSearchComponent;
  @ViewChild(StrainSearchComponent) strainSearchComponent!: StrainSearchComponent;
  @ViewChild(StrainTypeSearchComponent) straintypeSearchComponent!: StrainTypeSearchComponent;
  @ViewChild(StrainVendorSearchComponent) strainvendorSearchComponent!: StrainVendorSearchComponent;

  constructor(
    private strainService: StrainService,
    private personService: PersonService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {}

  /**
   * Initializes this component's properties: personService
   * @return {none}
   */
  ngOnInit() {
    if (this.strain.id) {
      this.newStrain = false;
      this.strainService.getStrain(this.strain.id).subscribe((data) => {
        this.strain = data;
        this.newStrain = false;
        // don't emit here! this is the initial retreive for when this component is embedded
      });
    } else if (this.strain.aname) {
      this.onKeyUp('aname');
    }

    this.personService.currentUser$.subscribe(() => {
      this.isCurator = this.personService.isCurator();
    });
  }

  /**
   * Function that returns 'true' if all conditions to save this record have been met.
   *
   * Checks all relevant variables including mandatory fields 'aname' and 'straintype'
   * @return {boolean} - 'true' if can save, 'false' if cannot
   */
  canSave(): boolean {
    return (
      this.strain.aname &&
      this.strain.straintype &&
      !this.isAnameWhitespace &&
      !this.longnameTaken &&
      this.isValidStockNumber()
    );
  }

  /**
   * Figure out if the current user is allowed to edit the strain
   *
   * @returns {any}: true if the current user can edit the strain, false if not
   */
  canEdit(): boolean {
    // curators can edit anything
    if (this.isCurator) {
      return true;
    }
    // this is a new strain, so can edit
    if (this.newStrain) {
      return true;
    }
    // creator can edit until a curator approves
    return this.personService.isCurrentUser(this.strain.user_creator.id);
  }

  /**
   * Figure out if the current user is allowed to delete the strain
   *
   * @returns {any}: true if the current user can delete the strain, false if not
   */
  canDelete(): boolean {
    // can't delete something that doesn't exist
    if (this.newStrain) {
      return false;
    }
    // can't delete if can't edit
    if (!this.canEdit()) {
      return false;
    }
    // can only delete if there are also no associated measures
    return this.strain.projcount === 0;
  }

  /**
   * Clean up user inputs of invalid characters and check whether values are valid
   *
   * @param {string} prop: strain property
   */
  onKeyUp(prop: string): void {
    if (prop === 'longname' || prop === 'aname') {
      // remove invalid characters and leading spaces for strain names
      if (this.strain[prop]) {
        this.strain[prop] = this.strain[prop].replace(/[^a-zA-Z0-9 <>:;+/\-.()[\]_]/g, '').replace(/^ +/g, '');
        // this can happen if the value is defaulted in when opening the dialog
        if (prop === 'aname' && this.strain[prop].length > 80) {
          this.strain[prop] = this.strain[prop].slice(0, 80);
        }
      }
      this.checkLongnameUnique(prop === 'aname');
    } else if (prop === 'stocknum') {
      // all current stocknumbers are composed of numbers, letters, and dashes...
      // can relax these restrictions if needed
      if (this.strain[prop]) {
        this.strain[prop] = this.strain[prop].replace(/[^a-zA-Z0-9-]/g, '');
      }
      this.isValidStockNumber(true);
    }
  }

  /**
   * Function that checks for longname uniqueness.
   * Also checks if aname is just whitespace, if this was an aname change.
   *
   * @param {boolean} anameChange: True if this was triggered from an aname change
   *                               (longname is set to match aname is longname isn't set... so
   *                                in that case we want to check for uniqueness on aname changes as well)
   *
   * @return {none}
   */
  checkLongnameUnique(anameChange: boolean = false): void {
    // we will set to the longname value if there is a valid one for checking uniqueness
    let longnameValue = '';

    // check if aname is whitespace and prepare to check
    if (anameChange) {
      this.isAnameWhitespace = false;
      if (!this.strain.longname) {
        this.longnameTaken = false;
      }
      if (this.strain.aname) {
        const trimmedAname = this.strain.aname.trim();
        // aname is set and just whitespace, then show an error on aname
        this.isAnameWhitespace = !trimmedAname;
        // if aname just changed and is set (trimmed) and longname is not set,
        // then we will check if trimmed aname is a duplicate value
        if (!this.strain.longname) {
          longnameValue = trimmedAname;
        }
      }
    } else {
      this.longnameTaken = false;
      if (this.strain.longname) {
        // if longname just changed and is set (trimmed)
        // then we will check if the trimmed value is a duplicate
        longnameValue = this.strain.longname.trim();
        // if longname is set and just whitespace, then show an error on longname
        this.longnameTaken = !longnameValue;
      } else if (this.strain.aname) {
        longnameValue = this.strain.aname.trim();
      }
    }

    if (longnameValue) {
      this.longnameTaken = false;

      const cachedStrains = this.strainService.strains.getValue();

      for (let i = 0; i < cachedStrains.length; i++) {
        if (this.strain.id === cachedStrains[i].id) {
          // don't trigger not unique flag against itself
          continue;
        }
        if (longnameValue === cachedStrains[i].longname.trim()) {
          this.longnameTaken = true;
          break;
        }
      }
    }
  }

  /**
   * Deletes this strain record from the database.
   * User is prompted to confirm the deletion.
   * @return none
   */
  deleteStrainRecord(): void {
    if (!this.newStrain) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          header: 'Strain Deletion Confirmation',
          message: 'Are you sure that you wish to delete this strain?',
          falselabel: 'Cancel',
          truelabel: 'Delete',
          truebtn: 'btn-danger',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.strainService.deleteStrain(this.strain.id).subscribe(() => {
            this.strainSearchComponent.clearSelection();
            this.strainChange.emit(this.strain);
          });
        }
      });
    }
  }

  /**
   * Sets the strain["panelsym"] property value based on user's selection -
   * the user must select one of the options and cannot just type the panel name.
   *
   * @param {any} panel - panel object (see model.Panel)
   */
  onPanelChange(panel: any): void {
    if (panel.panelsym) {
      this.strain.panelsym = panel.panelsym;
    } else {
      this.strain.panelsym = null;
    }
  }

  /**
   * On changing strain selection or clearing current selection
   * update certain variables and load data (for the selected strain).
   */
  onStrainChange(): void {
    this.showProjects = false;
    if (this.strain.id) {
      this.strainService.getStrain(this.strain.id).subscribe((data) => {
        this.strain = data;
        this.strainChange.emit(this.strain);
        this.newStrain = false;
        this.isStocknumTaken = false;
        this.longnameTaken = false;
        this.isAnameWhitespace = false;
        this.isStocknumRequired = false;
        this.stockNumberErrorMessage = '';
      });
    } else {
      this.newStrain = true;
      this.strainvendorSearchComponent.clearSelection();
      this.straintypeSearchComponent.clearSelection();
      this.panelSearchComponent.clearSelection();
      this.isStocknumTaken = false;
      this.longnameTaken = false;
      this.isAnameWhitespace = false;
      this.isStocknumRequired = false;
      this.stockNumberErrorMessage = '';
    }
  }

  /**
   * Sets the strain["straintype"] property value based on user's selection -
   * the user must select one of the options and cannot just type the strain type name.
   *
   * @param {any} strainType - strain type object (see model.Strain)
   */
  onStrainTypeChange(strainType: any): void {
    if (strainType.code) {
      this.strain.straintype = strainType.code;
    } else {
      this.strain.straintype = null;
    }
  }

  /**
   * Based on selected strain vendor option, sets this component's
   * 'strain.vendor' property. Selecting certain vendors can impact other form
   * input fields such as stock number, which may or may not become required.
   *
   * @param {any} strainVendor - strain vendor object (see model.Strain)
   */
  onStrainVendorChange(strainVendor: any): void {
    if (strainVendor.vendorcode) {
      this.strain.vendor = strainVendor.vendorcode;
    } else {
      this.strain.vendor = null;
      this.strain.stocknum = null;
      this.stockNumberErrorMessage = '';
      this.isStocknumRequired = false;
      this.isStocknumTaken = false;
    }
  }

  /**
   * sends request to the service responsible for inserting or updating
   * strain information in the database;
   * validates that the 'strain.aname' property value is set;
   * @return none
   */
  saveStrainRecord(): void {
    if (this.canSave()) {
      this.strainService.saveStrain(this.strain).subscribe(
        (result) => {
          this.strain = result;
          this.strainChange.emit(this.strain);
          this.newStrain = false;
          flashMessage(this.snackBar, 'Save successful!', 'alert-success', 2000);
        },
        (error2) => {
          flashMessage(
            this.snackBar,
            'Save failed.',
            'alert-danger',
            15000,
            error2.error ? [error2.error.message] : [],
          );
        },
      );
    }
  }

  /**
   * Performs strain stock number validation. Strain stock numbers might be subject to certain
   * restrictions and formatting rules (ex. JAX stock numbers should be 6 digits, with left padded 0s).
   *
   * @param {boolean} checkUnique - true to redo looping through cached strain stocknums to check for duplicates...
   *                                false to just return the last result of duplicate checking
   * @return {boolean} - 'true' if the value is valid, and 'false' otherwise
   */
  isValidStockNumber(checkUnique: boolean = false): boolean {
    let trimmedStocknum = '';

    // special case: JAX strain numbers must be 6 digits and padded with 0s to the left.
    if (this.strain.vendor === 'J') {
      this.isStocknumRequired = true;
      this.stockNumberClass = 'col-sm-3 control-label required';
    } else {
      this.isStocknumRequired = false;
      this.stockNumberClass = 'col-sm-3 control-label';
    }

    if (this.strain.stocknum) {
      trimmedStocknum = this.strain.stocknum.trim();
    }

    if (trimmedStocknum !== '') {
      this.stockNumberErrorMessage =
        'Stock numbers must be unique - this strain stock number for this vendor is already in use by another strain.';

      // special case: JAX strain numbers must be 6 digits and padded with 0s to the left.
      if (this.strain.vendor === 'J' && (trimmedStocknum.length !== 6 || trimmedStocknum.match(/^[0-9]+$/) === null)) {
        this.stockNumberErrorMessage =
          "This vendor's strain stock number format requires exactly 6 digits (ex. '000646')";
        return false;
      }

      if (!checkUnique) {
        return !this.isStocknumTaken;
      }
      this.isStocknumTaken = false;

      const cachedStrains = this.strainService.strains.getValue();

      for (let i = 0; i < cachedStrains.length; i++) {
        if (trimmedStocknum === cachedStrains[i].stocknum && this.strain.vendor === cachedStrains[i].vendor) {
          // stock number for new strains cannot match any existing strain's stock number
          if (this.newStrain || this.strain.id !== cachedStrains[i].id) {
            this.isStocknumTaken = true;
            break;
          }
        }
      }

      return !this.isStocknumTaken;
    }
    this.isStocknumTaken = false;
    this.stockNumberErrorMessage = '';
    return !this.isStocknumRequired;
  }

  /**
   * Generate simple comma-separated view of projects associated with strain
   *
   * Should ultimately be replace by a nice table format which is within an
   * accordion.
   *
   * @returns {string}: a project array where for each project it contains
   *   JSON structure of projid, projsym, and title
   */
  projectDisplay(): string {
    const projectArray = [];
    for (const project of this.strain.projects) {
      projectArray.push(
        '{projid: ' + project.projid + ', projsym: ' + project.projsym + ', title: ' + project.title + '}',
      );
    }
    return projectArray.join(', ');
  }
}
