<mat-toolbar>
  <h2>View/Edit Group</h2>
</mat-toolbar>
<br />
<div class="row">
  <div class="col-sm-12">
    <div class="form-horizontal" style="padding-left: 18px">
      <a routerLink="/groups">Go to My Groups</a>
      <br />
      <div *ngIf="noGroup">
        <br />
        <h4>Unable to find group id: {{ groupId }}</h4>
      </div>
      <div *ngIf="!noGroup">
        <div style="margin-left: -2px" *ngIf="!editingName">
          <span (click)="editName()" class="title-css" [ngClass]="{ 'admin-title-css': userUnrestricted && isAdmin }">
            {{ group.name }}
          </span>
          &nbsp;<span
            *ngIf="userUnrestricted && isAdmin"
            (click)="editName()"
            class="glyphicon glyphicon-pencil edit-name-icon"
          ></span>
        </div>
        <div style="margin-left: -2px" *ngIf="editingName">
          <input
            [(ngModel)]="newName"
            type="text"
            class="form-control title-css"
            id="new_group_name"
            name="new_group_name"
          />
          <span (click)="saveName()" matTooltip="Save Changes" class="glyphicon glyphicon-ok edit-name-icon"></span
          >&nbsp;
          <span
            (click)="editingName = false; newName = group.name"
            matTooltip="Discard Changes"
            class="glyphicon glyphicon-remove edit-name-icon"
          ></span>
        </div>
        <br />
        <span *ngIf="group.current_user_role">
          <span style="color: red" *ngIf="!userUnrestricted">{{ permissionNote() }}<br /></span>
          Your role within this group is <b>{{ group.current_user_role }}</b
          >.
        </span>
        <span *ngIf="!group.current_user_role">You are not a member of this group.</span>&nbsp;
        <a *ngIf="!showDescription" style="cursor: pointer" (click)="showDescription = true">
          View role descriptions....
        </a>
        <a *ngIf="showDescription" style="cursor: pointer" (click)="showDescription = false">
          Hide role descriptions...
        </a>
        <div style="color: #777777" *ngIf="showDescription">
          <p>
            <br />
            <b>Admin</b>: Admins have full control over the group. They can rename it, add members with any role, change
            member roles, remove any members, and even delete the group. They can also edit or view any projects that
            have been shared with the group. The user who created the group is automatically made the first Admin, and
            there must always be at least one Admin for the group. <br /><br />
            <b>Editor</b>: Editors can add members as other Editors or Viewers and change Viewers to Editors. However,
            while they can remove themself from the group, they may not remove other members. Editors can view or edit
            any projects that have been shared with the group. <br /><br />
            <b>Viewer</b>: Viewers may only remove themselves from the group. They may not add, remove, or modify the
            role of other members. Viewers can view but not edit any projects shared with the group. <br /><br />
            Note: Admins and Editors may only edit a project if the group is given the right to edit the project. If the
            group was only given the right to view a project, then Admins and Editors will only be able to view it,
            similar to Viewers. If a member has the right to edit a project in their own right (edit rights to the
            project was shared directly with them, but not the group), then they will still be able to edit the project.
          </p>
        </div>

        <br /><br />
        <div align="center"><span style="font-size: 20px; font-weight: 500">Members</span></div>
        <br /><br />
        <div class="form-group" *ngFor="let member of group.members">
          <label class="col-sm-2 control-label">Member</label>
          <div style="padding-top: 7px" class="col-sm-5">&nbsp;<person-display [person]="member"></person-display></div>
          <label class="col-sm-1 control-label" [for]="'role_' + member.id">Role</label>
          <div
            [matTooltip]="
              isOnlyAdmin && member.is_current_user ? 'You cannot change your role because you are the only Admin' : ''
            "
            class="col-sm-2"
          >
            <select
              *ngIf="canEditMemberRole(member)"
              class="form-control"
              [(ngModel)]="member.role"
              [id]="'role_' + member.id"
              [name]="'role_' + member.id"
              (change)="addOrModifyMemberConfirm(member, member.role)"
            >
              <option *ngFor="let option of roleOptions" [value]="option">{{ option }}</option>
            </select>
            <div *ngIf="!canEditMemberRole(member)" style="padding-top: 7px">&nbsp;&nbsp;{{ member.role }}</div>
          </div>
          <div *ngIf="showRemoveMember(member)" [matTooltip]="removeMemberDisabledTooltip(member)" class="col-sm-2">
            <button
              type="button"
              class="btn btn-danger"
              [disabled]="disableRemoveMember(member)"
              [matTooltip]="removeMemberTooltip(member)"
              (click)="removeMemberConfirm(member)"
            >
              <span class="glyphicon glyphicon-remove"></span>
            </button>
          </div>
        </div>
        <div *ngIf="userUnrestricted && (isAdmin || isEditor)" class="form-group">
          <label class="col-sm-2 control-label">Add Member</label>
          <div class="col-sm-5">
            <person-search
              #personLookup
              [(person)]="personSearch"
              [user]="true"
              [inviteByEmail]="true"
              [showDialog]="true"
              [disableList]="group.members"
            ></person-search>
          </div>
          <label class="col-sm-1 control-label" for="new_role">Role</label>
          <div class="col-sm-2">
            <select class="form-control" [(ngModel)]="newRole" id="new_role" name="new_role">
              <option *ngFor="let option of roleOptions" [value]="option">{{ option }}</option>
            </select>
          </div>
          <div class="col-sm-2">
            <button
              type="button"
              matTooltip="Add New Member"
              *ngIf="personSearch.email"
              (click)="addOrModifyMember(personSearch.id, newRole, personSearch.email); personLookup.clearSelection()"
              class="btn btn-primary"
            >
              <span class="glyphicon glyphicon-plus"></span>
            </button>
          </div>
        </div>
        <br /><br /><br />

        <div>
          <div align="center">
            <span style="font-size: 20px; font-weight: 500">Projects Shared with this Group</span>
            <br /><br />
            <span *ngIf="proj_component.projects.length === 0">No projects have been shared with this group yet.</span>
          </div>
          <projects #proj_component [embed]="true" [filterString]="'?groupid=' + groupId"></projects>
        </div>

        <div *ngIf="userUnrestricted && isAdmin" align="center">
          <br /><br /><br /><br /><br /><br /><br /><br />
          <button type="button" (click)="deleteGroup()" class="btn btn-danger">Delete Group</button>
        </div>
        <br /><br />
      </div>
    </div>
  </div>
</div>
