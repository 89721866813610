<app-header
  [user]="user"
  (toggleNav)="sideNavOpen = $event"
  [route]="router.url"
  (login)="auth.login()"
  (logout)="auth.logout()"
>
</app-header>

<mat-sidenav-container>
  <mat-sidenav mode="side" [(opened)]="sideNavOpen">
    <side-nav [route]="router.url" [user]="user" [open]="sideNavOpen"></side-nav>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
