import { Component, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OntologyService } from '../../services/ontology.service';
import { environment } from '../../../environments/environment';
import { FormControl } from '@angular/forms';

// NOTE: This component is pretty similar and takes a lot from the combobox, but was specialized enough
//       (and will be needed often enough) that it seemed prudent to make it into its own component)

@Component({
  selector: 'ontology-search',
  template: `
    <!--Using fieldset as a wrapper to make disabling work (it works differently...and not as well...for
        inputs with the formControl attribute, but a wrapping fieldset seems to work fine)-->
    <div [class]="optionSelected() ? 'input-group' : ''">
      <fieldset [disabled]="optionSelected() || disabled">
        <span [matTooltip]="inputTooltip()">
          <input
            placeholder="Begin typing an ontology name"
            class="form-control left-bord-rad-4px"
            autocomplete="off"
            [matAutocomplete]="auto"
            [formControl]="valueCtrl"
            (focus)="onFocus()"
            (focusout)="inputFocused = false"
            [style.border-color]="inputNoSelection() ? 'red' : ''"
          />
        </span>
        <span class="form-control-feedback">
          <span *ngIf="optionSelected()" style="color:green;right:40px;" class="glyphicon glyphicon-ok"></span>
          <span
            *ngIf="inputNoSelection()"
            style="color:red;right:20px;"
            class="glyphicon glyphicon-warning-sign"
          ></span>
        </span>
      </fieldset>
      <div *ngIf="optionSelected()" class="input-group-btn">
        <button
          (click)="clearSelection()"
          type="button"
          [disabled]="disabled"
          matTooltip="Clear selected ontology"
          class="btn btn-danger"
        >
          <span class="glyphicon glyphicon-remove clear-icon"></span>
        </button>
      </div>
    </div>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected()" [displayWith]="displayFn">
      <mat-option disabled *ngIf="filteredOptions.length === 0" style="color:red;">
        No matches. Please select an ontology from the options.
      </mat-option>
      <!--This is a performance optimization, which allows the user to see a few matches/options,
          while telling them that more are available if they narrow down their search.
          This pretty effectively fixes the performance issues I've seen from generating too many options at once.-->
      <mat-option
        *ngFor="let option of options"
        [value]="option"
        [disabled]="disableOption(option)"
        [matTooltip]="disableOption(option) ? '(Already Selected)' : ''"
      >
        {{ option.id }}
        <small>| {{ option.descrip }}</small>
      </mat-option>
      <mat-option
        disabled
        [style.display]="filteredOptions.length > resultsLimit ? 'block' : 'none'"
        *ngIf="filteredOptions.length > 50"
        class="mat-option show-all"
      >
        <a (click)="resultsLimit = resultsLimit + 50" style="cursor:pointer;">Show more results</a>
        or type more to narrow down results...
      </mat-option>
    </mat-autocomplete>
  `,
  styleUrls: ['searching.scss'],
})
export class OntologySearchComponent implements OnInit, OnChanges {
  // 2-way binding for the selected ontology. This component
  // will emit the selected ontology to the parent when an ontology is selected, so having a variable to bind
  // to in the parent is easier than going off of an event alone.
  @Input() ontology: any = {};

  // true to disable the search box (including preventing it from being cleared out)
  @Input() disabled = false;
  // list of ontology objects to exclude (compare 'id')
  @Input() disableList: any[] = [];

  // event emitter to send the selected ontology to the parent when one is selected from the autocomplete options
  @Output() ontologyChange: EventEmitter<any> = new EventEmitter<any>();

  // api URL
  api: string = environment.securedURLs.sip;

  // formControl for the input value
  valueCtrl: FormControl;

  // true if the input is currently focused, false otherwise
  inputFocused = false;

  // number of results that we are showing (if there are more than 50 options)
  // NOTE: There are so many ontologies, that "Show all results" takes too long, so instead I'm using a
  //       "Show more results" approach which adds 50 more results at a time
  resultsLimit = 7;

  // filtered autocomplete options, list of ontologies (any because typescript complains otherwise)
  filteredOptions: any[] = [];

  // set up the onchange observable with calls the ontologyService.getOntology() observable (http call)
  // to get filtered options in real time.
  constructor(public http: HttpClient, public ontologyService: OntologyService) {
    this.valueCtrl = new FormControl();
    this.valueCtrl.valueChanges.subscribe((result) => {
      const val = result ? result : '';
      if (typeof val !== 'string') {
        this.filteredOptions = [];
      } else {
        this.filterValues(val);
      }
    });
  }

  // on init, do an initial load of options
  ngOnInit() {
    this.ontologyService.ontologies$.subscribe(() => {
      let val = this.valueCtrl.value;
      val = val ? val : '';
      // Note: don't need to change filteredOptions right now if the type isn't a string
      if (typeof val === 'string') {
        this.filterValues(val);
      }
    });
  }

  // on change of the ontology from the parent side, set the current ontology to it if it's valid
  ngOnChanges() {
    if (this.ontology) {
      if (this.ontology.id) {
        this.valueCtrl.setValue(this.ontology);
      }
    }
  }

  get options(): any[] {
    return this.filteredOptions.length > 50 ? this.filteredOptions.slice(0, this.resultsLimit) : this.filteredOptions;
  }

  // determines whether an option was selected
  optionSelected(): boolean {
    return this.ontology && typeof this.ontology === 'object' ? this.ontology.id : false;
  }

  // determines whether the current user has input a value without selecting an option
  inputNoSelection(): boolean {
    return !this.optionSelected() && this.valueCtrl.value && !this.inputFocused;
  }

  // determines whether the selected option is not yet approved by a curator
  optionNotApproved(option: any): boolean {
    return option ? (option.user_creator ? option.user_creator.id : false) : false;
  }

  // builds a string describing the flag for an option that is not yet approved by a curator
  optionNotApprovedTooltip(option: any): string {
    return (
      'This ontology was added by the user, ' +
      option.user_creator.name_or_email +
      ', and is pending SIP Curator approval.'
    );
  }

  // builds the tooltip for the input if there's an issue
  inputTooltip(): string {
    if (this.inputNoSelection()) {
      return 'Must select option for this field to be populated (click in field to see options)';
    } else if (this.optionNotApproved(this.ontology)) {
      return this.optionNotApprovedTooltip(this.ontology);
    }
    return '';
  }

  /**
   * Filter the available ontologies in the dropdown to those for which the
   * user-entered string matches either the ontology id or the description
   *
   * @param {string} newValue: string entered by the user
   */
  filterValues(newValue: string) {
    this.resultsLimit = 7;
    if (newValue) {
      const newValueSplit = newValue.split(' ');
      let tempOptions = this.ontologyService.ontologies.getValue();
      for (let i = 0; i < newValueSplit.length; i++) {
        tempOptions = tempOptions.filter((option) =>
          option
            ? option.id.toLowerCase().indexOf(newValueSplit[i].toLowerCase()) !== -1
              ? true
              : option.descrip.toLowerCase().indexOf(newValueSplit[i].toLowerCase()) !== -1
            : false,
        );
      }
      this.filteredOptions = tempOptions;
    } else {
      this.filteredOptions = this.ontologyService.ontologies.getValue();
    }
  }

  /**
   * display function for the ontology object
   *
   * @param {any} option: ontology object or undefined
   * @returns {string}: ontology id | ontology descrip
   */
  displayFn(option?: any): string | undefined {
    if (!option) {
      return undefined;
    }
    if (typeof option === 'object') {
      return option.id + ' | ' + option.descrip;
    }
    return option;
  }

  /**
   * Determine if the option for selecting this object should be disabled because it's in the disableList
   * @param {Object} option: object option
   * @returns {boolean}: true to disable this object option, false to not
   */
  disableOption(option: any) {
    for (let i = 0; i < this.disableList.length; i++) {
      if (this.disableList[i].id === option.id) {
        return true;
      }
    }
    return false;
  }

  // if an option is selected, emit an ontologyChange event so that the parent knows who is selected
  onOptionSelected() {
    this.ontology = this.valueCtrl.value;
    this.ontologyChange.emit(this.ontology);
  }

  // clear currently selected ontology
  clearSelection() {
    this.valueCtrl.setValue('');
    this.filterValues('');
    this.ontology = {};
    this.ontologyChange.emit(this.ontology);
  }

  // upon focusing on a field, if the current value is blank and we have no options, then create them
  onFocus() {
    this.inputFocused = true;
    const value = this.valueCtrl.value;
    if ((!value || value === '') && this.filteredOptions.length === 0) {
      this.filterValues(value ? value : '');
    }
  }
}
