<div *ngIf="!isCurator && !embed" class="row">
  <div class="col-sm-12">
    <div class="form-horizontal">
      <div>
        <br />
        <h4>You do not have access to this page.</h4>
      </div>
    </div>
  </div>
</div>

<div [style.display]="isCurator || embed ? 'block' : 'none'">
  <mat-toolbar *ngIf="!embed">
    <h2>Edit Centers Information</h2>
  </mat-toolbar>
  <div class="row">
    <div class="col-sm-12">
      <div *ngIf="!embed">
        &nbsp;&nbsp;&nbsp;
        <b><a class="text9" routerLink="/">Home</a></b>
        <span class="glyphicon glyphicon-chevron-right text9"></span>
        <b><a class="text9" routerLink="/tasks">Tasks</a></b>
        <span class="glyphicon glyphicon-chevron-right text9"></span>
        <b><span class="text9">Edit Center Information</span></b>

        <br />
        <br />
        <br />
      </div>

      <div class="form-horizontal">
        <div [style.display]="embed ? 'none' : 'block'" class="form-group">
          <label class="col-sm-3 control-label">Search Centers</label>
          <div class="col-sm-6">
            <center-search [(collab)]="collab" (collabChange)="onCollabChange()" [showDialog]="false"> </center-search>
          </div>
        </div>

        <br />
        <div class="text-center">
          <span class="text16">
            <b>
              <span *ngIf="newCollab">Create New</span>
              <span *ngIf="!newCollab && canEdit()">Edit</span>
              <span *ngIf="!canEdit()">View</span>
              Center</b
            >
          </span>
        </div>
        <br />

        <fieldset [disabled]="!canEdit()">
          <div class="form-group has-feedback">
            <label for="collab-tag" class="col-sm-3 control-label required">Tag</label>
            <div class="col-sm-6">
              <input
                type="text"
                id="collab-tag"
                name="collab-tag"
                maxlength="20"
                class="form-control"
                placeholder="unique indentifier, less than 20 characters"
                [disabled]="!newCollab"
                [(ngModel)]="collab.tag"
                (keyup)="validateCollabTagAndName()"
              />

              <span *ngIf="newCollab && collab.tag" class="form-control-feedback">
                <span class="glyphicon glyphicon-ok green" *ngIf="!tagTaken && isNotEmpty(collab.tag)"></span>
                <span class="glyphicon glyphicon-warning-sign red" *ngIf="tagTaken && isNotEmpty(collab.tag)"></span>
              </span>
              <span class="red margin-top-bottom-2">
                <small *ngIf="tagTaken && isNotEmpty(collab.tag)">
                  This <b>Tag</b> is unavailable or invalid! It has already been used by another center.
                </small>
              </span>
            </div>
          </div>

          <div class="form-group has-feedback">
            <label for="collab-name" class="col-sm-3 control-label required">Name</label>
            <div class="col-sm-6">
              <input
                type="text"
                id="collab-name"
                name="collab-name"
                maxlength="80"
                class="form-control"
                [(ngModel)]="collab.name"
                (keyup)="validateCollabTagAndName()"
              />

              <span *ngIf="collab.name" class="form-control-feedback">
                <span class="glyphicon glyphicon-ok green" *ngIf="!nameTaken && isNotEmpty(collab.name)"></span>
                <span class="glyphicon glyphicon-warning-sign red" *ngIf="nameTaken && isNotEmpty(collab.name)"></span>
              </span>
              <span class="red margin-top-bottom-2">
                <small *ngIf="nameTakenBy && isNotEmpty(collab.name)">
                  This <b>Name</b> is unavailable or invalid! It has already been used by {{ nameTakenBy }}.
                </small>
              </span>
            </div>
          </div>

          <div class="form-group">
            <label for="collab-sort-order" class="col-sm-3 control-label required">Sort Order</label>
            <div class="col-sm-6 col-md-3">
              <input
                id="collab-sort-order"
                name="collab-sort-order"
                class="form-control"
                maxlength="80"
                placeholder="(ex. 'jax heart', 'jax pheno', ...)"
                [(ngModel)]="collab.sortorder"
              />
            </div>
          </div>

          <div class="form-group">
            <label for="collab-url" class="col-sm-3 control-label">URL</label>
            <div class="col-sm-6">
              <input id="collab-url" name="collab-url" class="form-control" [(ngModel)]="collab.url" />
            </div>
          </div>
        </fieldset>

        <div class="form-group">
          <label class="col-sm-3 control-label">Contact</label>
          <div class="col-sm-6">
            <person-search [disabled]="!canEdit()" [(person)]="collab.contact" [showDialog]="true"> </person-search>
          </div>
        </div>

        <!-- information about people associated with this collaborator -->
        <div *ngIf="!newCollab && isCurator">
          <div class="text-center">
            <span class="text10"
              ><b>{{ collab.projcount }} projects are associated with this center</b></span
            >
            [
            <a *ngIf="!showProjects && collab.projcount > 0" (click)="showProjects = true">show</a>
            <a *ngIf="showProjects" (click)="showProjects = false">hide</a>]
          </div>
          <div *ngIf="showProjects">
            <br />
            <p>{{ displayProjects() }}</p>
          </div>
        </div>
        <!-- END information about people associated with this institution section -->
        <br />

        <!-- save and delete buttons section -->
        <div class="form-group">
          <div [class]="(newCollab && !embed) || !canEdit() ? 'col-sm-4' : 'col-sm-2'"></div>
          <div *ngIf="canEdit()" class="col-sm-4" [matTooltip]="!canSave() ? disableSaveMessage : ''">
            <button class="btn btn-success btn-block" [disabled]="!canSave()" (click)="saveCollabRecord()">
              <strong>Save</strong>
            </button>
          </div>
          <div
            *ngIf="!newCollab && !embed"
            class="col-sm-4"
            [matTooltip]="collab.projcount > 0 ? 'Cannot delete a center with associated projects' : ''"
          >
            <button class="btn btn-danger btn-block" [disabled]="collab.projcount > 0" (click)="deleteCollabRecord()">
              <strong>Delete</strong>
            </button>
          </div>
          <div class="col-sm-4" *ngIf="embed">
            <button class="btn btn-default btn-block" (click)="cancel.emit(true)">
              <strong>{{ canEdit() ? 'Cancel' : 'Close' }}</strong>
            </button>
          </div>
        </div>
        <!-- END save and delete buttons section -->
      </div>
    </div>
  </div>
</div>
