import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PublicationDialogComponent } from '../dialogs/publication-dialog.component';

@Component({
  selector: 'pub-display',
  template: `
    <p *ngIf="pubinfo">
      <span *ngIf="pubinfo.error" style="color:darkred;">
        Unable to find publication info for pmid: {{ pubinfo.pmid }}.
      </span>
      <span *ngIf="!pubinfo.error">
        <span [innerHtml]="pubinfo.authorlist | SafeHTML"></span>&nbsp;
        <span [innerHtml]="pubinfo.title | SafeHTML"></span>&nbsp;
        <span [innerHtml]="pubinfo.journal | SafeHTML"></span> &nbsp;
        <span *ngIf="pubinfo.pmid ? pubinfo.pmid.slice(0, 4) !== 'nopm' : false">
          <a
            style="white-space:nowrap;"
            href="http://www.ncbi.nlm.nih.gov/pubmed/{{ pubinfo.pmid }}?dopt=Abstract"
            target="_blank"
          >
            PubMed {{ pubinfo.pmid }}
          </a>
          &nbsp;
        </span>
        <span *ngIf="pubinfo.pmcid">
          &nbsp;
          <a href="http://www.ncbi.nlm.nih.gov/pmc/articles/{{ pubinfo.pmcid }}/?tool=pubmed" target="_blank"
            >FullText</a
          >
        </span>
        &nbsp;&nbsp;<a style="cursor:pointer;" (click)="openEditDialog()">SIP Info</a>
        <!--Link in public to pubdb. Leaving this here to see if -->
        <!--&nbsp; <a href="{{ url_for( "staff.pub", pmid=pubinfo.pmid )}}">PubDB</a>-->
      </span>
    </p>
  `,
  styles: [],
})
export class PublicationDisplayComponent {
  // pubinfo object to display
  @Input() pubinfo: any;

  // set up the onchange observable with calls the publicationService.getPublication() observable (http call)
  // to get filtered options in real time.
  constructor(public dialog: MatDialog) {}

  // open a dialog to create, edit, or view a publication
  openEditDialog(): void {
    if (!this.pubinfo.error) {
      const publicationCopy = JSON.parse(JSON.stringify(this.pubinfo));
      const dialogRef = this.dialog.open(PublicationDialogComponent, {
        data: { publication: publicationCopy },
        autoFocus: false,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          if (result.pmid) {
            this.pubinfo = result;
          }
        }
      });
    }
  }
}
