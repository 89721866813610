import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../../services/project.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

@Component({
  selector: 'animal-info',
  templateUrl: './animal-info.component.html',
})
export class AnimalInfoComponent implements OnInit {
  // project id in the route
  projid: string | null = '';

  // flag that the project id was invalid or the user doesn't have access
  invalidID = false;

  // project object that is displayed in the page (and edited if the user make modifications)
  project: any = { projdoc: {} };

  // project object that represents the state of the project as of the last import (user to compare with
  // the project object when saving/exporting in order to determine what has changed)
  dbProject: any = { projdoc: {} };

  // hide entry fields until project is done loading
  loadingProject = false;

  constructor(private route: ActivatedRoute, private projectService: ProjectService, public router: Router) {}

  // oninit of the component, load the project from the id in the route (this component requires an id,
  // unlike proj-details, which can be used to create a new project if one isn't passed in through the route)
  ngOnInit() {
    this.projid = this.route.snapshot.paramMap.get('id') || null;
    if (Number(this.projid)) {
      this.getProject();
    } else {
      this.invalidID = true;
    }
  }

  // get the project from the api
  getProject() {
    this.loadingProject = true;

    if (this.projid) {
      this.projectService.getProject(this.projid).subscribe(
        (data) => {
          this.invalidID = data.table_form;
          if (!this.invalidID) {
            this.project = data;
            // I'm not sure if setting both to data would result in them just pointing to the same object,
            // so I'm making sure that it's a deep copy just in case
            this.dbProject = JSON.parse(JSON.stringify(data));
          }
          this.loadingProject = false;
        },
        () => {
          this.invalidID = true;
          this.loadingProject = false;
        },
      );
    }
  }
}
