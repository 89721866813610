// Angular imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// External imports
import { MarkdownModule } from 'ngx-markdown';

// Modules
import { MaterialModule } from '../../shared/material.module';
import { SharedModule } from '../../shared/shared.module';

// Components
import { HelpComponent } from './help.component';
import { HelpDialogComponent } from './help-dialog.component';

@NgModule({
  imports: [CommonModule, FormsModule, MarkdownModule.forChild(), MaterialModule, RouterModule, SharedModule],
  declarations: [HelpComponent, HelpDialogComponent],
})
export class HelpModule {}
