<div class="row">
  <div class="col-sm-12">
    <a
      routerLink="/projects"
      [queryParams]="user ? { userid: 'current' } : { status: 'Public' }"
      class="dark-cerulean text10"
    >
      <mat-icon class="v-bottom" inline="true"> arrow_back </mat-icon>Back to
      <b>{{ user ? 'My' : 'Public' }} Projects</b>
    </a>
    <div *ngIf="invalidID">
      <br />
      <h4>Project {{ projid }} either does not exist or you do not have access to it.</h4>
    </div>
  </div>
</div>

<div align="center" *ngIf="loading">
  <b>Loading project {{ projid }}...</b>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<div class="row">
  <div class="cols-sm-12">
    <div id="edit_project_header" *ngIf="!invalidID && !loading">
      <mat-horizontal-stepper
        #stepper
        (selectionChange)="selectionChange($event)"
        [selectedIndex]="selectedIndex"
        [linear]="navDisabled()"
      >
        <mat-step *ngFor="let item of items" [completed]="false">
          <ng-template matStepLabel>
            <span class="text9 dark-cerulean">{{ item.name }}</span>
          </ng-template>
        </mat-step>
      </mat-horizontal-stepper>

      <div style="padding-left: 16px" *ngIf="!project.canEdit">
        <b style="color: red">{{ noEditMessage() }}</b>
        <br /><br />
      </div>

      <fieldset [disabled]="!project.canEdit">
        <div class="form-horizontal">
          <!-- project symbol group -->
          <div *ngIf="project.projid" class="form-group">
            <label for="proj-name" [class]="'col-sm-3 control-label' + (project.isReviewer ? ' required' : '')">
              Project Symbol
              <span
                class="glyphicon glyphicon-question-sign"
                matTooltip="This is an identifier for the project. A SIP curator will update this when
                            the project is ready for release."
                matTooltipPosition="after"
                matTooltipHideDelay="2000"
              >
              </span>
            </label>
            <div class="col-sm-7">
              <input
                [(ngModel)]="projsym"
                [disabled]="!project.isReviewer"
                type="text"
                id="proj-symbol-input"
                class="form-control"
                name="proj-symbol-input"
                required
                maxlength="40"
                (change)="projsymChange.emit(projsym)"
                (keyup)="projsymKeyUp()"
              />
            </div>
          </div>
          <!-- END project symbol group -->

          <!-- project title group -->
          <div class="form-group">
            <label for="proj-name" class="col-sm-3 control-label required">
              Project Title
              <span
                class="glyphicon glyphicon-question-sign"
                matTooltip="Example: Bone mineral density and body composition in 8 Collaborative Cross strains of mice"
                matTooltipPosition="after"
                matTooltipHideDelay="2000"
              >
              </span>
              <img
                *ngIf="project.bioconnect_id"
                src="./assets/bioconnect_icon.ico"
                alt=""
                [matTooltip]="'Controlled by linked Bioconnect Study: ' + project.bioconnect_identifier"
              />
            </label>
            <div class="col-sm-7">
              <input
                #title_input_html
                [(ngModel)]="title"
                [disabled]="project.bioconnect_id"
                type="text"
                id="proj-name"
                class="form-control"
                name="proj-name"
                required
                placeholder="enter MPD project name"
                maxlength="255"
                (change)="titleChange.emit(title)"
              />
            </div>
            <div style="padding-top: 7px" *ngIf="project.projid" class="col-sm-2">
              <project-actions
                [project]="project"
                [allowTriggerImportExport]="false"
                [noViewOrEdit]="true"
                (projectDeleted)="router.navigateByUrl('/projects?userid=current')"
                (projectRestored)="projectRestored.emit($event)"
                (projectChanged)="projectChanged.emit($event)"
                (clickImportExport)="clickImportExport.emit($event)"
                (clickExternalLink)="clickExternalLink.emit($event)"
              >
              </project-actions>
            </div>
          </div>
          <!-- END project title group -->

          <!-- project status group -->
          <div *ngIf="project.projid" class="form-group">
            <label class="col-sm-3 control-label">Project Status</label>
            <div
              class="col-sm-9"
              [style.padding-top]="
                ((project.status && project.status !== 'Public') || project.changesincereleased) && project.isCurator
                  ? ''
                  : '7px'
              "
            >
              <span
                style="padding: 5px 10px; border-radius: 10px; color: white"
                [style.background-color]="statusColors[project.status ? (project.status | titlecase) : 'Public']"
              >
                {{ project.status ? (project.status | titlecase) : 'Public' }}
              </span>

              <span *ngIf="project.releasedtime || !project.status || project.status === 'Public'">
                &nbsp;&nbsp;&nbsp;<b>Released: </b>
                <span
                  [innerHtml]="project.releasedtime ? (buildDateTimeHtml(project.releasedtime) | SafeHTML) : '(Legacy)'"
                >
                </span>
                &nbsp;
                <span
                  *ngIf="project.changesincereleased"
                  class="glyphicon glyphicon-warning-sign yellow"
                  matTooltip="This project has been changed since it was last released."
                ></span>
                <span
                  *ngIf="!project.changesincereleased"
                  class="glyphicon glyphicon-ok-circle green"
                  matTooltip="The released version of this project is up-to-date."
                ></span>
              </span>

              <span *ngIf="project.deletedtime">
                &nbsp;&nbsp;&nbsp;<b style="color: red">Deleted: </b>
                <span [innerHtml]="buildDateTimeHtml(project.deletedtime) | SafeHTML"></span>
                &nbsp;
                <span
                  class="glyphicon glyphicon-question-sign"
                  matTooltip="This project was deleted. A project owner or SIP curator can use the blue trash
                              can action to restore it."
                >
                </span>
              </span>

              <span *ngIf="project.projlogs">
                &nbsp;&nbsp;<a (click)="viewHistory()" style="cursor: pointer">View History</a>
              </span>

              <span
                *ngIf="
                  ((project.status && project.status !== 'Public') || project.changesincereleased) &&
                  project.isReviewer &&
                  !project.deletedtime
                "
              >
                &nbsp;&nbsp;&nbsp;
                <button (click)="clickReleaseProject.emit(true)" [disabled]="actionLoadingBar" class="btn btn-primary">
                  {{ (project.changesincereleased ? 'Re-' : '') + 'Release Project' }}
                </button>
              </span>
              <span *ngIf="project.status === 'In Progress' && !project.deletedtime">
                &nbsp;&nbsp;&nbsp;
                <button
                  (click)="clickChangeStatus.emit('In Review')"
                  [disabled]="actionLoadingBar"
                  class="btn btn-primary"
                >
                  Move to In Review
                </button>
              </span>
              <span *ngIf="project.status === 'In Review' && !project.deletedtime">
                &nbsp;&nbsp;&nbsp;
                <button
                  (click)="clickChangeStatus.emit('In Progress')"
                  [disabled]="actionLoadingBar"
                  class="btn btn-primary"
                >
                  Move back to In Progress
                </button>
              </span>
              <!-- applies only to imported projects - re-imports the project from the external resource -->
              <span *ngIf="project.external_id && !project.deletedtime">
                &nbsp;
                <button (click)="clickReimportProject.emit(true)" [disabled]="actionLoadingBar" class="btn btn-primary">
                  <span class="glyphicon glyphicon-circle-arrow-left" style="vertical-align: middle"></span>
                  Re-Import from PFS
                </button>
              </span>
            </div>
          </div>
          <!-- END project status group -->

          <div align="center" *ngIf="actionLoadingBar">
            <br />
            <b>{{ actionLoadingBar }}...</b>
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
</div>
