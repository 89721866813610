import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class PanelService {
  // api url
  private api: string = environment.securedURLs.sip;

  // panel behavioursubject for building an observable
  public panels = new BehaviorSubject<any[]>([]);
  // observable, which can be subscribed to in order to get changes to the panel list any time it is updated
  public panels$ = this.panels.asObservable();
  // flag as to whether the panels list has been initialized yet
  private panelsListInitialized = false;

  // declare http client
  constructor(private http: HttpClient) {
    if (!this.panelsListInitialized) {
      this.panelsListInitialized = true;
      this.getPanel().subscribe();
    }
  }

  /**
   * Get either a specific panel or a list of all of them
   *
   * @param {number} panelsym: panelsym for specific panel to retrieve or null to get a list of all panels
   * @returns {Observable<any>} Observable object or array of objects
   */
  getPanel(panelsym: string = ''): Observable<any> {
    let url = this.api + 'panels';
    if (panelsym) {
      url += '?panelsym=' + panelsym;
    }
    return this.http.get<any>(url).pipe(
      tap((result) => {
        if (!panelsym) {
          this.panelsListInitialized = true;
          // cache the list and trigger the panels$ observable so that all subscribers get the updated list
          this.panels.next(result);
        }
      }),
    );
  }

  /**
   * Observable for saving a panel
   *
   * @param {any} panel: any containing panel data to save
   * @returns {Observable<any>}: updated panel object on success (not all changes sent may have been allowed)
   */
  savePanel(panel: any): Observable<any> {
    const url = this.api + 'panels';
    // TODO: fix this nested observable
    return this.http
      .put<any>(url, panel ? panel : {}, { headers: new HttpHeaders().set('Content-Type', 'application/json') })
      .pipe(
        tap(() => {
          // update the cached panels in case someone else is working on panels
          this.getPanel().subscribe();
        }),
      );
  }

  /**
   * Observable for deleting a panel
   *
   * @param {number} panelsym: panelsym of the panel to delete
   * @returns {Observable<any>}: Result is a 204 code on success
   */
  deletePanel(panelsym: string): Observable<any> {
    const url = this.api + 'panels?panelsym=' + panelsym;
    // TODO: fix this nested observable
    return this.http.delete<any>(url).pipe(
      tap(() => {
        // make extra sure the deleted panel gets removed from the cached list
        // note: leaving this manual removal in here because there is a race condition on updating the search list
        // (which isn't an issue for the save)
        const panelsList = this.panels.getValue();
        for (let i = 0; i < panelsList.length; i++) {
          if (panelsym === panelsList[i].panelsym) {
            panelsList.splice(i, 1);
            this.panels.next(panelsList);
            break;
          }
        }
        // also update the cached panels in case someone else is working on panels
        this.getPanel().subscribe();
      }),
    );
  }
}
