import { Component, Input, Output, EventEmitter, SimpleChanges, OnInit, OnChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';

// used to remove unused/unneeded keys
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { BioconnectService } from '../../services/bioconnect.service';

@Component({
  selector: 'bioconnect-study-search',
  template: `
    <!--Using fieldset as a wrapper to make disabling work (it works differently...and not as well...for
        inputs with the formControl attribute, but a wrapping fieldset seems to work fine)-->
    <div [class]="optionSelected() ? 'input-group' : ''">
      <fieldset [disabled]="optionSelected() || disabled">
        <span [matTooltip]="inputTooltip()">
          <input
            placeholder="Begin typing a study title or identifier"
            class="form-control left-bord-rad-4px"
            autocomplete="off"
            [matAutocomplete]="auto"
            [formControl]="valueCtrl"
            (focus)="inputFocused = true"
            (focusout)="inputFocused = false"
            [style.border-color]="inputNoSelection() ? 'red' : ''"
          />
        </span>
        <span class="form-control-feedback">
          <span *ngIf="optionSelected()" style="color:green;right:80px;" class="glyphicon glyphicon-ok"></span>
          <span
            *ngIf="inputNoSelection()"
            style="color:red;right:20px;"
            class="glyphicon glyphicon-warning-sign"
          ></span>
        </span>
      </fieldset>
      <div *ngIf="optionSelected()" class="input-group-btn">
        <a
          *ngIf="bioconnectUi"
          target="_blank"
          [href]="bioconnectUi + 'search/metadata-search-browse/detail-view/study/' + study.id"
          matTooltip="Link to BioConnect"
          class="btn btn-primary"
        >
          <span class="glyphicon glyphicon-link clear-icon"></span>
        </a>
        <button
          (click)="clearSelection()"
          type="button"
          [disabled]="disabled"
          matTooltip="Clear selected study"
          class="btn btn-danger"
        >
          <span class="glyphicon glyphicon-remove clear-icon"></span>
        </button>
      </div>
    </div>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected()" [displayWith]="displayFn">
      <mat-option disabled *ngIf="filteredOptions.length === 0" style="color:red;">
        <span style="color:black;" *ngIf="loading">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>Loading results...
        </span>
        <span *ngIf="!loading"> No matches. </span>
      </mat-option>
      <mat-option
        *ngFor="let option of filteredOptions"
        [value]="option"
        [disabled]="disableOption(option)"
        [matTooltip]="disableOption(option) ? '(Already linked to a MPD project)' : ''"
      >
        <b>{{ option.identifier }}</b
        >: {{ option.title }}
      </mat-option>
      <mat-option disabled [style.display]="!nextPage || loading ? 'none' : 'block'" class="mat-option show-all">
        <span [style.display]="loadingMore ? 'none' : 'block'">
          <a (click)="getNextPage()" style="cursor:pointer;">Show more results</a>
        </span>
        <span [style.display]="!loadingMore ? 'none' : 'block'">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>Loading more results...
        </span>
      </mat-option>
    </mat-autocomplete>
  `,
  styleUrls: ['searching.scss'],
})
export class BioconnectStudySearchComponent implements OnInit, OnChanges {
  // true if selection and clearing should be disabled, false if not
  @Input() disabled = false;

  // default page size...
  @Input() pageSize = 1000;

  // bioconnect study object 2-way binding
  @Input() study: any = {};

  // event emitter to send the selected study to the parent when one is selected from the autocomplete options
  @Output() studyChange: EventEmitter<any> = new EventEmitter<any>();

  // list of study ids already linked to MPD projects
  disableList: any[] = [];

  // formControl for the input value
  valueCtrl: FormControl;

  // true if the input is currently focused, false otherwise
  inputFocused = false;

  // used to link out to the selected study
  bioconnectUi = environment.unsecuredURLs.bioconnectUi;

  // true while initial results are being loaded from the api
  loading = false;

  // true while more results are being loaded from the api
  loadingMore = false;

  // unfiltered list of loaded studies
  options: any[] = [];

  // filtered autocomplete options, list of studies
  filteredOptions: any[] = [];

  // nextPage url received from the results, is the full study list get url
  nextPage = '';

  // sets up a subscription on change of the filtering value to build a list of results from the ones that are loaded in
  constructor(public http: HttpClient, public dialog: MatDialog, public bioconnectService: BioconnectService) {
    this.valueCtrl = new FormControl();
    this.valueCtrl.valueChanges.pipe(debounceTime(500)).subscribe((result) => {
      this.filterValues(result);
    });
    this.updateDisableList();
  }

  // load in options...
  ngOnInit() {
    this.nextPage =
      environment.unsecuredURLs.bioconnect + 'metadata_repository/study/?page=1&page_size=' + this.pageSize;
    this.loading = true;
    this.getNextPage(true);
  }

  // on change of the study by the parent, populate it on this search component
  ngOnChanges(changes: SimpleChanges) {
    if (this.study && changes.study ? this.study.id : false) {
      this.valueCtrl.setValue(this.study);
    }
  }

  // determines whether an option was selected
  optionSelected(): boolean {
    return this.study && typeof this.study === 'object' ? this.study.id : false;
  }

  // determines whether the current user has input a value without selecting an option
  inputNoSelection(): boolean {
    return !this.optionSelected() && this.valueCtrl.value && !this.inputFocused;
  }

  // builds the tooltip for the input if there's an issue
  inputTooltip(): string {
    if (this.inputNoSelection()) {
      return 'Must select option for this field to be populated (click in field to see options)';
    }
    return '';
  }

  /**
   * Build a list of bioconnect studies based on the page size
   *
   * @param {any} newValue: string entered by the user or selected object
   */
  filterValues(newValue: any) {
    newValue = newValue ? newValue : '';
    this.filteredOptions = [];
    if (typeof newValue !== 'string' || !newValue) {
      this.filteredOptions = this.options;
    } else {
      newValue = newValue.toLowerCase();
      this.filteredOptions = this.options.filter((option) => {
        return (
          option.identifier.toLowerCase().indexOf(newValue) !== -1 ||
          option.title.toLowerCase().indexOf(newValue) !== -1
        );
      });
    }
  }

  // queries the bioconnect api for the next page of results and appends them to the options
  getNextPage(firstPage: boolean = false) {
    if (this.nextPage) {
      if (!firstPage) {
        this.loadingMore = true;
      }
      this.http.get(this.nextPage).subscribe((result: any) => {
        this.loadingMore = false;
        this.loading = false;
        this.options = this.options.concat(
          result.results.map((option: any) => {
            return { id: option.id, identifier: option.identifier, title: option.title };
          }),
        );
        this.filterValues(this.valueCtrl.value);
        this.nextPage = result.next;
      });
    }
  }

  /**
   * display function for the study object
   * @param {any} option: study object or undefined
   * @returns {string}: display str
   */
  displayFn(option?: any): string | undefined {
    if (!option) {
      return undefined;
    }
    if (typeof option === 'object') {
      return option.identifier + ': ' + option.title;
    }
    return option;
  }

  /**
   * Determine if the option for selecting this object should be disabled because it's in the disableList
   * @param {Object} option: object option
   * @returns {boolean}: true to disable this object option, false to not
   */
  disableOption(option: any) {
    for (let i = 0; i < this.disableList.length; i++) {
      if (this.disableList[i] === option.id) {
        return true;
      }
    }
    return false;
  }

  // run to update the disable list...
  updateDisableList() {
    this.bioconnectService.getLinkedStudies().subscribe((result) => (this.disableList = result));
  }

  // if an option is selected, emit a studyChange event so that the parent knows who is selected
  onOptionSelected() {
    this.study = this.valueCtrl.value;
    this.studyChange.emit(this.study);
  }

  // clear currently selected object
  clearSelection() {
    this.valueCtrl.setValue('');
    this.filterValues('');
    this.study = {};
    this.studyChange.emit(this.study);
  }
}
