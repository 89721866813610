import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { PersonService } from '../../services/person.service';

@Component({
  selector: 'user-profile',
  templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit {
  // api string
  api: string = environment.securedURLs.sip;

  // current user's id... passed along to the edit-user-profile-form
  // component to get it to load the current user's profile
  id = 0;

  // profile object to keep track and modify the current user's columns and relationships
  profile: any = { allow_changes: true, institution: {}, editors: [], caneditpeople: [] };

  // true if the current user is a curator, false if they are not
  isCurator = false;

  // personSearch object selected by the curator
  personSearch: any = {};

  // declare http client and services
  constructor(public http: HttpClient, private personService: PersonService) {}

  // on init, load the current user's profile
  ngOnInit() {
    this.personService.currentUser$.subscribe((data) => {
      if (data && data.id) {
        this.id = Number(data.id);
        this.profile = data;
        this.isCurator = this.personService.isCurator();
      }
    });
  }
}
