<div *ngIf="!embed">
  <div class="text-center">
    <h2 class="gray7">PROJECTS</h2>
  </div>
  <br />
  <div class="text-center">
    <span [matTooltip]="createImportTooltip('Create a New Project')">
      <button class="btn btn-default" [routerLink]="['/proj-details']" style="margin: 5px" [disabled]="editDisabled()">
        <span class="dark-cerulean">Create Project</span>
      </button>
    </span>
    <span [matTooltip]="createImportTooltip('Import an Existing Project')">
      <button
        class="btn btn-default"
        [routerLink]="['/project/import']"
        style="margin: 5px"
        [disabled]="editDisabled()"
      >
        <span class="dark-cerulean">Import Project</span>
      </button>
    </span>
  </div>
  <br />
  <!--Filter panel accordion, with several different filter types-->
  <mat-accordion>
    <mat-expansion-panel (opened)="showingFilters = true" (closed)="showingFilters = false">
      <mat-expansion-panel-header>
        <mat-panel-title
          ><a>{{ showingFilters ? 'Hide' : 'Show' }} Advanced Filters</a></mat-panel-title
        >
        <mat-panel-description>
          <span
            *ngIf="filters.length > 0"
            [class]="'filter-clear' + (loadingProjects ? '-disabled' : '')"
            (click)="clearFilters()"
            >Clear&nbsp;&nbsp;</span
          >
          {{
            filters.length === 0
              ? 'Currently no filters selected'
              : filters.length === 1
              ? '1 filter selected'
              : filters.length + ' filters selected'
          }}
          {{
            filters.length === 1
              ? filters[0].type === 'userid' && filters[0].filterValues.length === 1
                ? '- User = ' + filters[0].filterValues[0].name_or_email
                : ''
              : ''
          }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <hr />
      <div class="form-horizontal">
        <div *ngFor="let filter of filters; let i = index">
          <div class="form-group">
            <div class="col-sm-1">
              <button
                matTooltip="Remove Filter"
                class="btn btn-danger"
                [disabled]="loadingProjects"
                (click)="filters.splice(i, 1); filtersChanged()"
              >
                <span class="glyphicon glyphicon-remove"></span>
              </button>
            </div>
            <label class="col-sm-1 control-label">Type</label>
            <div class="col-sm-3">
              <select
                class="form-control"
                [(ngModel)]="filter.type"
                [disabled]="loadingProjects"
                (change)="filterTypeChange(filter)"
              >
                <option [disabled]="filterTypeTaken('userid')" value="userid">User</option>
                <option [disabled]="filterTypeTaken('piid')" value="piid">Investigator</option>
                <option [disabled]="filterTypeTaken('groupid')" value="groupid">Group</option>
                <option [disabled]="filterTypeTaken('pmid')" value="pmid">Publication</option>
                <option [disabled]="filterTypeTaken('projsym')" value="projsym">Project Symbol</option>
                <option [disabled]="filterTypeTaken('status')" value="status">Status</option>
                <option [disabled]="filterTypeTaken('instauth')" value="instauth">Data Contributor Type</option>
                <option [disabled]="filterTypeTaken('mpdsector')" value="mpdsector">Type / Pardigm</option>
                <option [disabled]="filterTypeTaken('panelsym')" value="panelsym">Panel</option>
                <option [disabled]="filterTypeTaken('treatment')" value="treatment">Treatment</option>
                <option [disabled]="filterTypeTaken('largecollab')" value="largecollab">Center</option>
                <option [disabled]="filterTypeTaken('title')" value="title">Title</option>
                <option [disabled]="filterTypeTaken('archived')" value="archived">Archived/Deleted</option>
              </select>
            </div>
            <div *ngIf="filter.type === 'userid'" class="col-sm-7">
              <div *ngFor="let person of filter.filterValues; let j = index" class="form-group">
                <div class="col-sm-1"></div>
                <div class="col-sm-10">
                  <person-search
                    [(person)]="filters[i].filterValues[j]"
                    [user]="true"
                    [disabled]="loadingProjects"
                    (personChange)="onObjFilterChange(i, j, 'id')"
                  ></person-search>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-1"></div>
                <div class="col-sm-10">
                  <person-search
                    [user]="true"
                    [(person)]="userSearchObj"
                    #userSearch
                    [showDialog]="false"
                    [disableList]="filters[i].filterValues"
                    [disabled]="loadingProjects"
                    (personChange)="userSearchChange(i, userSearch)"
                  ></person-search>
                </div>
              </div>
            </div>
            <div *ngIf="filter.type === 'piid'" class="col-sm-7">
              <div *ngFor="let person of filter.filterValues; let j = index" class="form-group">
                <div class="col-sm-1"></div>
                <div class="col-sm-10">
                  <person-search
                    [(person)]="filters[i].filterValues[j]"
                    [disabled]="loadingProjects"
                    (personChange)="onObjFilterChange(i, j, 'id')"
                  ></person-search>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-1"></div>
                <div class="col-sm-10">
                  <person-search
                    [(person)]="piSearchObj"
                    #piSearch
                    [showDialog]="false"
                    [disableList]="filters[i].filterValues"
                    [disabled]="loadingProjects"
                    (personChange)="piSearchChange(i, piSearch)"
                  ></person-search>
                </div>
              </div>
            </div>
            <div *ngIf="filter.type === 'title'" class="col-sm-7">
              <div class="form-group">
                <div class="col-sm-1"></div>
                <div class="col-sm-10">
                  <input
                    type="text"
                    class="form-control"
                    [disabled]="loadingProjects"
                    placeholder="Enter title filter value"
                    [(ngModel)]="filters[i].filterValues"
                    (change)="filtersChanged()"
                  />
                </div>
              </div>
            </div>
            <div *ngIf="filter.type === 'projsym'" class="col-sm-7">
              <div class="form-group">
                <div class="col-sm-1"></div>
                <div class="col-sm-10">
                  <input
                    type="text"
                    class="form-control"
                    [disabled]="loadingProjects"
                    placeholder="Enter project symbol filter value"
                    [(ngModel)]="filters[i].filterValues"
                    (change)="filtersChanged()"
                  />
                </div>
              </div>
            </div>
            <div *ngIf="filter.type === 'instauth'" class="col-sm-7">
              <div class="form-group">
                <div class="col-sm-1"></div>
                <div class="col-sm-10">
                  <select
                    class="form-control"
                    [disabled]="loadingProjects"
                    [(ngModel)]="filters[i].filterValues"
                    (change)="filtersChanged()"
                  >
                    <option value="false">Investigator(s)</option>
                    <option value="instauth">Center</option>
                  </select>
                </div>
              </div>
            </div>
            <div *ngIf="filter.type === 'archived'" class="col-sm-7">
              <div class="form-group">
                <div class="col-sm-1"></div>
                <div class="col-sm-10">
                  <select
                    class="form-control"
                    [disabled]="loadingProjects"
                    [(ngModel)]="filters[i].filterValues"
                    (change)="filtersChanged()"
                  >
                    <option value="only">Show only archived/deleted projects</option>
                    <option value="both">Show both archived/deleted and active projects</option>
                  </select>
                </div>
              </div>
            </div>
            <div *ngIf="filter.type === 'panelsym'" class="col-sm-7">
              <div *ngFor="let panel of filter.filterValues; let j = index" class="form-group">
                <div class="col-sm-1"></div>
                <div class="col-sm-10">
                  <panel-search
                    [(panel)]="filters[i].filterValues[j]"
                    [disabled]="loadingProjects"
                    (panelChange)="onObjFilterChange(i, j, 'panelsym')"
                  ></panel-search>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-1"></div>
                <div class="col-sm-10">
                  <panel-search
                    [(panel)]="panelSearchObj"
                    #panelSearch
                    [disabled]="loadingProjects"
                    [showDialog]="false"
                    [disableList]="filters[i].filterValues"
                    (panelChange)="panelSearchChange(i, panelSearch)"
                  ></panel-search>
                </div>
              </div>
            </div>
            <div *ngIf="filter.type === 'treatment'" class="col-sm-7">
              <div *ngFor="let treatment of filter.filterValues; let j = index" class="form-group">
                <div class="col-sm-1"></div>
                <div class="col-sm-10">
                  <intervention-search
                    [(intervention)]="filters[i].filterValues[j]"
                    [disabled]="loadingProjects"
                    (interventionChange)="onObjFilterChange(i, j, 'term')"
                  >
                  </intervention-search>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-1"></div>
                <div class="col-sm-10">
                  <intervention-search
                    [(intervention)]="treatmentSearchObj"
                    #treatmentSearch
                    [disabled]="loadingProjects"
                    [showDialog]="false"
                    [disableList]="filters[i].filterValues"
                    (interventionChange)="treatmentSearchChange(i, treatmentSearch)"
                  >
                  </intervention-search>
                </div>
              </div>
            </div>
            <div *ngIf="filter.type === 'groupid'" class="col-sm-7">
              <div *ngFor="let group of filter.filterValues; let j = index" class="form-group">
                <div class="col-sm-1"></div>
                <div class="col-sm-10">
                  <group-search
                    [(group)]="filters[i].filterValues[j]"
                    [disabled]="loadingProjects"
                    (groupChange)="onObjFilterChange(i, j, 'id')"
                  ></group-search>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-1"></div>
                <div class="col-sm-10">
                  <group-search
                    [(group)]="groupSearchObj"
                    #groupSearch
                    [disabled]="loadingProjects"
                    [disableList]="filters[i].filterValues"
                    (groupChange)="groupSearchChange(i, groupSearch)"
                  ></group-search>
                </div>
              </div>
            </div>
            <div *ngIf="filter.type === 'pmid'" class="col-sm-7">
              <div *ngFor="let publication of filter.filterValues; let j = index" class="form-group">
                <div class="col-sm-1"></div>
                <div class="col-sm-10">
                  <publication-search
                    [(publication)]="filters[i].filterValues[j]"
                    [disabled]="loadingProjects"
                    [showPMID]="true"
                    (publicationChange)="onObjFilterChange(i, j, 'pmid')"
                  ></publication-search>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-1"></div>
                <div class="col-sm-10">
                  <publication-search
                    [(publication)]="publicationSearchObj"
                    #publicationSearch
                    [disabled]="loadingProjects"
                    [showPMID]="true"
                    [showDialog]="false"
                    [disableList]="filters[i].filterValues"
                    (publicationChange)="publicationSearchChange(i, publicationSearch)"
                  >
                  </publication-search>
                </div>
              </div>
            </div>
            <div *ngIf="filter.type === 'largecollab'" class="col-sm-7">
              <div *ngFor="let center of filter.filterValues; let j = index" class="form-group">
                <div class="col-sm-1"></div>
                <div class="col-sm-10">
                  <center-search
                    [(collab)]="filters[i].filterValues[j]"
                    [disabled]="loadingProjects"
                    (collabChange)="onObjFilterChange(i, j, 'id')"
                  ></center-search>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-1"></div>
                <div class="col-sm-10">
                  <center-search
                    [(collab)]="centerSearchObj"
                    #largeCollabSearch
                    [disabled]="loadingProjects"
                    [disableList]="filters[i].filterValues"
                    [showDialog]="false"
                    (collabChange)="centerSearchChange(i, largeCollabSearch)"
                  ></center-search>
                </div>
              </div>
            </div>
            <div *ngIf="filter.type === 'status'" class="col-sm-7">
              <div *ngFor="let status of filters[i].filterValues; let j = index" class="form-group">
                <div class="col-sm-1"></div>
                <div class="col-sm-10">
                  <div class="input-group">
                    <input type="text" disabled [(ngModel)]="filters[i].filterValues[j]" class="form-control" />
                    <div class="input-group-btn">
                      <button
                        (click)="filters[i].filterValues.splice(j, 1); filtersChanged()"
                        type="button"
                        [disabled]="loadingProjects"
                        matTooltip="Clear selected status"
                        class="btn btn-danger"
                      >
                        <span class="glyphicon glyphicon-remove clear-icon"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-1"></div>
                <div class="col-sm-10">
                  <select
                    *ngIf="!loadingProjects && filters[i].filterValues.length < 6"
                    class="form-control"
                    (change)="statusSearchChange(i)"
                    [(ngModel)]="statusSearch"
                  >
                    <option *ngIf="filters[i].filterValues.indexOf('Approved') === -1" value="Approved">
                      Approved
                    </option>
                    <option *ngIf="filters[i].filterValues.indexOf('In Progress') === -1" value="In Progress">
                      In Progress
                    </option>
                    <option *ngIf="filters[i].filterValues.indexOf('In Review') === -1" value="In Review">
                      In Review
                    </option>
                    <option *ngIf="filters[i].filterValues.indexOf('Needs Work') === -1" value="Needs Work">
                      Needs Work
                    </option>
                    <option *ngIf="filters[i].filterValues.indexOf('Public') === -1" value="Public">Public</option>
                    <option *ngIf="filters[i].filterValues.indexOf('Submitted') === -1" value="Submitted">
                      Submitted
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div *ngIf="filter.type === 'mpdsector'" class="col-sm-7">
              <div *ngFor="let mpdsector of filters[i].filterValues; let j = index" class="form-group">
                <div class="col-sm-1"></div>
                <div class="col-sm-10">
                  <div class="input-group">
                    <select disabled [(ngModel)]="filters[i].filterValues[j]" class="form-control">
                      <option value="pheno">Phenotype strain survey data set</option>
                      <option value="snp">SNP/variation strain survey data set</option>
                      <option value="qtla">QTL Archive data set</option>
                      <option value="onestrain">One-strain study</option>
                      <option value="phenoarchive">Phenotype archive study</option>
                      <option value="komp">Knockout mouse project (KOMP) data</option>
                    </select>
                    <div class="input-group-btn">
                      <button
                        type="button"
                        matTooltip="Clear selected type/paradigm"
                        class="btn btn-danger"
                        [disabled]="loadingProjects"
                        (click)="filters[i].filterValues.splice(j, 1); filtersChanged()"
                      >
                        <span class="glyphicon glyphicon-remove clear-icon"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-1"></div>
                <div class="col-sm-10">
                  <select
                    *ngIf="!loadingProjects && filters[i].filterValues.length < 6"
                    class="form-control"
                    (change)="typeSearchChange(i)"
                    [(ngModel)]="typeSearch"
                  >
                    <option *ngIf="filters[i].filterValues.indexOf('pheno') === -1" value="pheno">
                      Phenotype strain survey data set
                    </option>
                    <option *ngIf="filters[i].filterValues.indexOf('snp') === -1" value="snp">
                      SNP/variation strain survey data set
                    </option>
                    <option *ngIf="filters[i].filterValues.indexOf('qtla') === -1" value="qtla">
                      QTL Archive data set
                    </option>
                    <option *ngIf="filters[i].filterValues.indexOf('onestrain') === -1" value="onestrain">
                      One-strain study
                    </option>
                    <option *ngIf="filters[i].filterValues.indexOf('phenoarchive') === -1" value="phenoarchive">
                      Phenotype archive study
                    </option>
                    <option *ngIf="filters[i].filterValues.indexOf('komp') === -1" value="komp">
                      Knockout mouse project (KOMP) data
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>
        <div class="form-group">
          <div class="col-sm-6" matTooltip="Add new filter">
            <button class="btn btn-primary" [disabled]="loadingProjects" (click)="filters.push([])">
              <span class="glyphicon glyphicon-plus"></span>
            </button>
            &nbsp;&nbsp;<label>Add New Filter</label>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <br />
  <div style="padding-left: 18px" *ngIf="projects.length === 0 && !loadingProjects && !errorMessage">
    <span
      style="padding-left: 18px"
      *ngIf="
        !(filters.length === 1
          ? filters[0].type === 'userid' && filters[0].filterValues.length === 1
            ? filters[0].filterValues[0].is_current_user
            : false
          : false)
      "
    >
      <b>No were projects found matching your current filters.</b>
    </span>
    <div
      style="padding-left: 18px"
      *ngIf="
        filters.length === 1
          ? filters[0].type === 'userid' && filters[0].filterValues.length === 1
            ? filters[0].filterValues[0].is_current_user
            : false
          : false
      "
    >
      <b>You currently don't have any projects.</b>
      <br /><br />
    </div>
    <br /><br />
  </div>
  <div style="padding-left: 18px; color: red" *ngIf="!loadingProjects && errorMessage">
    <b>{{ errorMessage }}</b>
  </div>
</div>

<app-table
  *ngIf="projects.length > 0 && !loadingProjects && !errorMessage"
  [rows]="projects"
  [objType]="'project'"
  [columns]="columns"
  (triggerTableRefresh)="getProjects()"
></app-table>
<div align="center" *ngIf="loadingProjects">
  <b>Loading...</b>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<br *ngIf="!embed" />
