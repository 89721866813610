import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PersonService } from '../../services/person.service';
import { MatDialog } from '@angular/material/dialog';
import { buildOpenDialogTooltip, validateEmail } from '../utils';
import { environment } from '../../../environments/environment';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { PersonDialogComponent } from '../dialogs/person-dialog.component';

// NOTE: This component is pretty similar and takes a lot from the combobox, but was specialized enough
//       (and will be needed often enough) that it seemed prudent to make it into its own component)

@Component({
  selector: 'person-search',
  template: `
    <!--Using fieldset as a wrapper to make disabling work (it works differently...and not as well...for
        inputs with the formControl attribute, but a wrapping fieldset seems to work fine)-->
    <div [class]="optionSelected() || showDialog ? 'input-group' : ''">
      <fieldset [disabled]="optionSelected() || disabled">
        <span [matTooltip]="inputTooltip()">
          <input
            placeholder="Begin typing a name or email"
            class="form-control left-bord-rad-4px"
            autocomplete="off"
            [matAutocomplete]="auto"
            [formControl]="valueCtrl"
            (focus)="onFocus()"
            (focusout)="inputFocused = false"
            [style.border-color]="inputNoSelection() ? 'red' : ''"
          />
        </span>
        <span class="form-control-feedback">
          <span
            *ngIf="optionSelected()"
            style="color:green;"
            [style.right]="showDialog ? '80px' : '40px'"
            class="glyphicon glyphicon-ok"
          ></span>
          <span
            *ngIf="inputNoSelection()"
            style="color:red"
            [style.right]="showDialog ? '40px' : '20px'"
            class="glyphicon glyphicon-warning-sign"
          ></span>
        </span>
      </fieldset>
      <div
        *ngIf="optionSelected() || showDialog"
        class="input-group-btn"
        [matTooltip]="
          !this.valueCtrl.value && !disabled
            ? 'Type in a name and or email if the investigator is not already in the system, ' +
              'then click this button to create them'
            : ''
        "
      >
        <button
          *ngIf="showDialog"
          (click)="openEditDialog()"
          type="button"
          class="btn btn-success"
          [disabled]="!this.valueCtrl.value"
          [matTooltip]="openDialogTooltip()"
        >
          <span [class]="'glyphicon glyphicon-' + (!optionSelected() ? 'plus' : canEdit() ? 'pencil' : 'eye-open')">
          </span>
        </button>
        <button
          *ngIf="optionSelected()"
          (click)="clearSelection()"
          type="button"
          [disabled]="disabled"
          matTooltip="Clear selected person"
          class="btn btn-danger"
        >
          <span class="glyphicon glyphicon-remove clear-icon"></span>
        </button>
      </div>
    </div>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected()" [displayWith]="displayFn">
      <mat-option disabled *ngIf="loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>Loading results...
      </mat-option>
      <ng-container *ngIf="!loading">
        <mat-option disabled *ngIf="filteredOptions.length === 0">
          <span style="color:red;">
            <span *ngIf="showDialog">
              No matches.
              <button (click)="openEditDialog()" class="btn btn-success">
                <span class="glyphicon glyphicon-plus"></span> Create new investigator
              </button>
            </span>
            <span *ngIf="!showDialog">No matches. Please select an investigator from the options</span>
          </span>
        </mat-option>
        <mat-option
          *ngFor="let option of options"
          [value]="option"
          [disabled]="disableOption(option)"
          [matTooltip]="disableOption(option) ? '(Already Selected)' : ''"
        >
          <person-display
            [person]="option"
            [showCanDelete]="showCanDelete"
            [showCuratorIcon]="showCuratorIcon"
          ></person-display>
        </mat-option>
        <mat-option
          disabled
          [style.display]="!showAllResults ? 'block' : 'none'"
          *ngIf="filteredOptions.length > 50"
          class="mat-option show-all"
        >
          <span [style.display]="loadingAll ? 'none' : 'block'">
            <a (click)="filterValues(valueCtrl.value, true)" style="cursor:pointer;">Show all results</a>
            or type more to narrow down results...
          </span>
          <span [style.display]="loadingAll ? 'block' : 'none'">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>Loading all results...
          </span>
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  `,
  styleUrls: ['./searching.scss'],
})
export class PersonSearchComponent implements OnChanges {
  // true to only show users in the lookup, false to show all people in the lookup
  @Input() user = false;

  // true to only include people with a first and last name in the lookup, false to show all people in the lookup
  @Input() nameRequired = false;

  // true to allow invitation of new users by email (which needs to be handled by the parent component and their related
  // endpoints, but here is basically allows a user to create or select an investigator with an email for which there
  // isn't already a user and make them into a user
  @Input() inviteByEmail = false;

  // flag to show an icon to indicate is the user can delete this person
  @Input() showCanDelete = false;

  // flag to show an icon to indicate if the person is a mpd curator
  @Input() showCuratorIcon = false;

  // true to disable the search box (including preventing it from being cleared out)
  @Input() disabled = false;

  // list of person objects to exclude (compare 'id')
  @Input() disableList: any[] = [];

  // true to show the button to open a dialog to create, edit, or view investigators, false to not
  @Input() showDialog = true;

  // technically 2-way, but used as a 1-way binding (this component > parent). This component
  // will emit the selected person to the parent when a person is selected, so having a variable to bind
  // to in the parent is easier than going off of an event alone.
  @Input() person: any = {};

  // event emitter to send the selected person to the parent when one is selected from the autocomplete options
  @Output() personChange: EventEmitter<any> = new EventEmitter<any>();

  // api URL
  api: string = environment.securedURLs.sip;

  // formControl for the input value
  valueCtrl: FormControl;

  // true to show all results if there are more than 50
  showAllResults = false;

  // true if the input is currently focused, false otherwise
  inputFocused = false;

  // true while all results are being loaded from the api
  loadingAll = false;

  // true while any results (not all) are being loaded from the api
  loading = false;

  // filtered autocomplete options, list of people (any because typescript complains otherwise)
  filteredOptions: any[] = [];

  // latest string sent in api search request
  lastSearchStr = '';

  // set up the onchange observable with calls the personService.getPeople() observable (http call)
  // to get filtered options in real time. For users, we will always get options, but for people we will
  // wait until at least 2 characters have been entered for better efficiency
  constructor(public http: HttpClient, public dialog: MatDialog, public personService: PersonService) {
    this.valueCtrl = new FormControl();
    this.valueCtrl.valueChanges.pipe(debounceTime(500)).subscribe((result) => {
      this.filterValues(result);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const userChanged =
      changes.user &&
      ((changes.user.previousValue && !changes.user.currentValue) ||
        (!changes.user.previousValue && changes.user.currentValue));
    const reqUserChanged =
      changes.name_required &&
      ((changes.name_required.previousValue && !changes.name_required.currentValue) ||
        (!changes.name_required.previousValue && changes.name_required.currentValue));

    if (this.person && changes.person ? this.person.id : false) {
      this.valueCtrl.setValue(this.person);
    } else if (userChanged) {
      // make sure this is actually a change, and not just undefined to false or something like that before
      // re-running filterings
      this.filterValues(this.valueCtrl.value);
    } else if (reqUserChanged) {
      this.filterValues(this.valueCtrl.value);
    }
  }

  get options(): any[] {
    return this.filteredOptions.length > 50 && !this.showAllResults
      ? this.filteredOptions.slice(0, 7)
      : this.filteredOptions;
  }

  // determines whether an option was selected
  optionSelected(): boolean {
    if (this.person && typeof this.person === 'object') {
      return this.person.id;
    }
    return false;
  }

  // determines whether the current user has input a value without selecting an option
  inputNoSelection(): boolean {
    return !this.optionSelected() && this.valueCtrl.value && !this.inputFocused;
  }

  // builds the tooltip for the input if there's an issue
  inputTooltip(): string {
    if (this.inputNoSelection()) {
      return 'Must select option for this field to be populated (click in field to see options)';
    } else if (this.optionNotApproved(this.person)) {
      return this.optionNotApprovedTooltip(this.person);
    }
    return '';
  }

  // determines whether the selected option is not yet approved by a curator
  optionNotApproved(option: any): boolean {
    return option ? (option.user_creator ? option.user_creator.id : false) : false;
  }

  // builds a string describing the flag for an option that is not yet approved by a curator
  optionNotApprovedTooltip(option: any): string {
    return (
      'This investigator was added by the user, ' +
      option.user_creator.name_or_email +
      ', and is pending SIP Curator approval.'
    );
  }

  /**
   * Determine if the option for selecting this object should be disabled because it's in the disableList
   * @param {Object} option: object option
   * @returns {boolean}: true to disable this object option, false to not
   */
  disableOption(option: any) {
    for (let i = 0; i < this.disableList.length; i++) {
      if (this.disableList[i].id === option.id) {
        return true;
      }
    }
    return false;
  }

  /**
   * Filter the available panels in the dropdown to those for which the
   * user-entered string matches the panel name
   *
   * @param {any} newValue: string entered by the user or selected object
   * @param {boolean} showAll: true if the user selected to show all results, so we're getting the rest from the api
   */
  filterValues(newValue: any, showAll = false) {
    newValue = newValue ? newValue : '';
    if (typeof newValue !== 'string') {
      this.filteredOptions = [];
    } else {
      if (!showAll) {
        this.showAllResults = false;
        this.loading = true;
      } else {
        this.loadingAll = true;
      }
      this.lastSearchStr = newValue;
      const userArg = this.user ? (this.inviteByEmail ? 'email' : 'userid') : '';
      const limit = showAll ? 0 : 51;
      const offset = showAll ? this.filteredOptions.length : 0;
      // there's a bit of a race condition here where later requests can finish before earlier requests,
      // so we're keeping track of the last searched string and only setting filteredOptions if the result matches
      // (or there are no results, which is likely either because the string is too long to match anything, so
      //  that request should come back quicker than a subsequent request with results)
      this.personService.getPeople(newValue, userArg, limit, offset, this.nameRequired).subscribe((result) => {
        if (result.length > 0) {
          if (result[0].search_str === this.lastSearchStr) {
            if (showAll) {
              this.filteredOptions = this.filteredOptions.concat(result);
              this.showAllResults = true;
            } else {
              this.filteredOptions = result;
            }
          }
        } else if (!showAll) {
          this.filteredOptions = result;
        } else {
          this.showAllResults = true;
        }
        this.loadingAll = false;
        this.loading = false;
      });
    }
  }

  // return true if the current user can modify the investigator, false if not
  canEdit() {
    if (!this.person || !this.person.id) {
      return true;
    }
    return this.person.allow_changes;
  }

  /**
   * display function for the person objecyt
   * @param {any} option: person object or undefined
   * @returns {string}: display_str ('first middle last, degree | email')
   */
  displayFn(option?: any): string | undefined {
    if (!option) {
      return undefined;
    }
    if (typeof option === 'object') {
      return option.display_str;
    }
    return option;
  }

  // if an option is selected, emit a personChange event so that the parent knows who is selected
  onOptionSelected() {
    this.person = this.valueCtrl.value;
    this.personChange.emit(this.person);
  }

  // clear the currently selected person
  clearSelection() {
    this.valueCtrl.setValue('');
    this.person = {};
    this.personChange.emit(this.person);
  }

  // upon focusing on a field, if the current value is blank and we have no options, then create them
  onFocus() {
    this.inputFocused = true;
    const value = this.valueCtrl.value;
    if ((!value || value === '') && this.filteredOptions.length === 0) {
      this.filterValues(value ? value : '');
    }
  }

  // tooltip for opening the dialog for viewing/editing/creating investigators
  openDialogTooltip() {
    return buildOpenDialogTooltip(this.valueCtrl.value, this.person, 'id', this.canEdit(), 'investigator');
  }

  // open a dialog to create, edit, or view an investigator
  openEditDialog(): void {
    const personCopy = this.person ? JSON.parse(JSON.stringify(this.person)) : {};
    // default in what the user typed in as the first name
    if (!personCopy || !personCopy.id) {
      if (this.valueCtrl.value) {
        // replace instances of '|' and ',', filter removes empty values
        const names = this.valueCtrl.value
          .replace(/(\||,)/gi, '')
          .split(' ')
          .filter((val: string) => val);
        // check if any of the names are an email
        for (let i = 0; i < names.length; i++) {
          if (validateEmail(names[i])) {
            personCopy.email = names[i];
            names.splice(i, 1);
            break;
          }
        }
        // populate first, middle (if there are at least 3 items in the list), and last names
        personCopy.firstname = names[0];
        if (names.length > 2) {
          personCopy.middlename = names[1][0];
          personCopy.lastname = names[2];
        } else if (names.length > 1) {
          personCopy.lastname = names[1];
        }
      }
    }
    const dialogRef = this.dialog.open(PersonDialogComponent, {
      data: { person: personCopy, requireEmail: this.inviteByEmail },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.id) {
          this.person = result;
          this.personChange.emit(this.person);
        } else {
          this.clearSelection();
        }
      }
    });
  }
}
