<p>
  You have the ability to link publications to this dataset. By default, all publications listed as being associated
  with this study are available to select and deselect at any time. If you need to link a publication that's not
  connected to this study, you can use the publication search at the bottom of the checklist and enter the PubMed ID of
  the publication and the associated publication can be selected. If a non-inherited publication is deselected and this
  page is left, that publication will need to be relinked.
</p>

<h4 *ngIf="!editable" style="color: #777; margin-top: 1rem">
  In order to link publications, you must have edit access to this project and the dataset requires animal information
  to have been uploaded.
</h4>

<mat-selection-list (selectionChange)="changeSelection($event)" [disabled]="!editable">
  <mat-list-option
    *ngFor="let pub of publicationLinks; let i = index"
    checkboxPosition="before"
    [disableRipple]="true"
    [selected]="pub.selected"
    [value]="i"
  >
    <div mat-line>{{ pub.publication.pmid }} {{ pub.publication.title }}</div>
    <div mat-line *ngIf="pub.selected">
      Linked Animals: {{ pub.animals.length || 'All' }}
      <span *ngIf="animals.length && allowAnimalChanges(pub)" (click)="selectLinkedAnimals($event, pub)">Change</span>
    </div>
  </mat-list-option>
</mat-selection-list>
<ng-container *ngIf="editable">
  <hr />
  <form>
    <mat-form-field appearance="fill">
      <mat-label>Search for Publication by PubMed ID</mat-label>
      <input placeholder="Start typing a PubMed ID" matInput [formControl]="newPublication" [matAutocomplete]="auto" />
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option *ngIf="publicationInfo$ | async as pub" (onSelectionChange)="linkPublication(pub)">
          <span>{{ pub.title }}</span>
          <br />
          <small>{{ pub.authorlist }}</small>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>

  <div class="flex-center">
    <div
      *ngIf="editable"
      class="button-tooltip-container"
      [matTooltip]="!changesMade ? 'No changes to save' : ''"
      matTooltipPosition="right"
    >
      <button mat-flat-button color="primary" [disabled]="!changesMade" (click)="update.emit()">
        <mat-icon>check</mat-icon> Save Updates
      </button>
    </div>
  </div>
</ng-container>
