<div *ngIf="!embed">
  <mat-toolbar>
    <h2>Help and FAQs</h2>
  </mat-toolbar>
  <br /><br />
</div>

<div style="width: 100%" align="center" *ngIf="loading">
  <b>Loading Help...</b>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<div [style.display]="loading ? 'none' : 'block'">
  <div [style.display]="helpDocs.uploadedFiles.length ? 'block' : 'none'">
    <file-upload
      #helpDocs
      [allowEdit]="false"
      [pointertype]="'app'"
      [title]="'Help Documents'"
      [allowShowArchived]="false"
      [pointer]="1"
      [tag]="'help'"
      [fileTypes]="'.pdf'"
      [optional]="false"
    ></file-upload>
  </div>

  <br />

  <div style="padding-left: 18px">
    <markdown [data]="faqFile" ngPreserveWhitespaces></markdown>
  </div>
</div>
