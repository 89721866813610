<div class="row">
  <div class="col-md-12 bg-white">
    <div class="form-group col-md-6 text-left">
      <fieldset class="plot-control-panel-border">
        <legend class="plot-control-panel-border dark-cerulean">
          Informational Variables
          <span
            class="glyphicon glyphicon-question-sign"
            matTooltip="Select first filter criteria from Variable 1 list
              and add second filter criteria from Variable 2 list."
            matTooltipPosition="after"
            matTooltipHideDelay="1000"
          >
          </span>
        </legend>

        <div class="row">
          <form class="form-horizontal">
            <div class="form-group col-md-6">
              <label for="cross-infovar1-sel" class="col-sm-4 control-label noh-padding">Variable 1</label>
              <div class="col-sm-8">
                <select
                  id="cross-infovar1-sel"
                  name="cross-infovar1-sel"
                  class="form-control input-sm"
                  [ngModel]="selectedInfoVar1Value"
                  (ngModelChange)="oninfoVarChange($event, 1)"
                >
                  <option value="--">--</option>
                  <ng-container *ngFor="let infoVar of phenoInfoVariables">
                    <option
                      *ngIf="infoVar !== 'strain'"
                      [value]="infoVar"
                      [disabled]="selectedInfoVar2Value === infoVar"
                    >
                      {{ infoVar }}
                    </option>
                  </ng-container>
                </select>
              </div>
            </div>
            <div class="form-group col-md-6">
              <label for="cross-infovar2-sel" class="col-sm-4 control-label noh-padding">Variable 2</label>
              <div class="col-sm-8">
                <select
                  id="cross-infovar2-sel"
                  name="cross-infovar2-sel"
                  class="form-control input-sm"
                  [ngModel]="selectedInfoVar2Value"
                  (ngModelChange)="oninfoVarChange($event, 2)"
                  [disabled]="selectedInfoVar1Value === '--'"
                >
                  <option value="--">--</option>
                  <ng-container *ngFor="let infoVar of phenoInfoVariables">
                    <option
                      *ngIf="infoVar !== 'strain'"
                      [value]="infoVar"
                      [disabled]="selectedInfoVar1Value === infoVar"
                    >
                      {{ infoVar }}
                    </option>
                  </ng-container>
                </select>
              </div>
            </div>
          </form>
        </div>
      </fieldset>
    </div>

    <div class="form-group col-md-6 text-left">
      <fieldset class="plot-control-panel-border">
        <legend class="plot-control-panel-border dark-cerulean">Plot Options</legend>
        <label
          id="show-mean-std-label"
          for="show-mean-std-checkbox"
          class="mpd-checkbox col-sm-3"
          *ngIf="selectedMeasure.datatype === 'numeric'"
        >
          <input id="show-mean-std-checkbox" type="checkbox" checked="checked" (change)="showMeanStdDev($event)" />
          <span></span>
          Mean +/- 1 SD
        </label>
        <label id="strains-on-x-label" for="strains-on-x-checkbox" class="mpd-checkbox col-sm-4">
          <input id="strains-on-x-checkbox" type="checkbox" checked="checked" (change)="onChartInverted($event)" />
          <span></span>
          Strains on X-axis
        </label>

        <div class="col-md-4" [hidden]="!selectedMeasure.seriestype">
          <select class="form-control input-sm" [ngModel]="selectedSerie" (ngModelChange)="onseriesChange($event)">
            <option
              *ngFor="let serie of selectedMeasure.seriesvarnames; let i = index"
              [value]="selectedMeasure.seriesvarnames[i]"
            >
              {{ serie }}
            </option>
          </select>
        </div>
      </fieldset>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12 bg-white text-center">
    <b>Tip</b>: Use Shift-Click to select multiple data points for display in the data table.
  </div>
</div>

<div class="row">
  <div class="col-md-12 bg-white">
    <div id="container-ss" style="width: 100%"></div>
  </div>
</div>
<br />
<div *ngIf="chart">
  <app-table
    [rows]="selectedTableData"
    [columns]="visTableCols"
    [defPageSize]="5"
    [exportFormats]="exportFormats"
    title="Data Table"
    (actionClick)="deselectPoint($event.row)"
  >
  </app-table>
</div>
<br />
