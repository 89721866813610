<div *ngIf="!isCurator" class="row">
  <div class="col-sm-12">
    <div class="form-horizontal">
      <div>
        <br />
        <h4>You do not have access to this page.</h4>
      </div>
    </div>
  </div>
</div>

<div [style.display]="isCurator ? 'block' : 'none'">
  <mat-toolbar>
    <h2>Curator Tasks</h2>
  </mat-toolbar>
  <div class="row">
    <div class="col-sm-12">
      &nbsp;&nbsp;&nbsp;
      <a class="text9" routerLink="/"><b>Home</b></a>
      <span class="glyphicon glyphicon-chevron-right text9"></span>
      <span class="text9"><b>Curator Tasks</b></span>
    </div>
  </div>
  <br />
  <div class="curator-tasks-container">
    <h4>Curation Categories</h4>

    <div class="row">
      <div class="col-sm-12">
        <p>
          To make changes (<i>add, update, or delete</i>) information from any of the categories listed below click on
          it and you will be redirected to the editing view.
        </p>

        <div class="list-group col-sm-12 col-md-4">
          <a [routerLink]="['/edit-institutions']" class="list-group-item list-group-item-action dark-cerulean text10">
            <span class="glyphicon glyphicon-arrow-right dark-cerulean text10"></span>
            &nbsp;&nbsp;<b class="dark-cerulean text10">INSTITUTIONS</b>
          </a>
          <a [routerLink]="['/edit-centers']" class="list-group-item list-group-item-action dark-cerulean text10">
            <span class="glyphicon glyphicon-arrow-right dark-cerulean text10"></span>
            &nbsp;&nbsp;<b class="dark-cerulean text10">CENTERS</b>
          </a>
          <a [routerLink]="['/edit-interventions']" class="list-group-item list-group-item-action dark-cerulean text10">
            <span class="glyphicon glyphicon-arrow-right dark-cerulean text10"></span>
            &nbsp;&nbsp;<b class="dark-cerulean text10">TREATMENTS</b>
          </a>
        </div>

        <div class="list-group col-sm-12 col-md-4">
          <a [routerLink]="['/edit-strain']" class="list-group-item list-group-item-action">
            <span class="glyphicon glyphicon-arrow-right dark-cerulean text10"></span>
            &nbsp;&nbsp;<b class="dark-cerulean text10">STRAINS</b>
          </a>
          <a [routerLink]="['/panels']" class="list-group-item list-group-item-action dark-cerulean text10">
            <span class="glyphicon glyphicon-arrow-right dark-cerulean text10"></span>
            &nbsp;&nbsp;<b class="dark-cerulean text10">PANELS</b>
          </a>
          <a [routerLink]="['/edit-investigators']" class="list-group-item list-group-item-action dark-cerulean text10">
            <span class="glyphicon glyphicon-arrow-right dark-cerulean text10"></span>
            &nbsp;&nbsp;<b class="dark-cerulean text10">INVESTIGATORS</b>
          </a>
        </div>

        <div class="list-group col-sm-12 col-md-4">
          <a [routerLink]="['/publications']" class="list-group-item list-group-item-action dark-cerulean text10">
            <span class="glyphicon glyphicon-arrow-right dark-cerulean text10"></span>
            &nbsp;&nbsp;<b class="dark-cerulean text10">PUBLICATIONS</b>
          </a>
          <a [routerLink]="['/edit-methodologies']" class="list-group-item list-group-item-action dark-cerulean text10">
            <span class="glyphicon glyphicon-arrow-right dark-cerulean text10"></span>
            &nbsp;&nbsp;<b class="dark-cerulean text10">METHODOLOGIES</b>
          </a>
          <a
            [routerLink]="['/edit-procedure-templates']"
            class="list-group-item list-group-item-action dark-cerulean text10"
          >
            <span class="glyphicon glyphicon-arrow-right dark-cerulean text10"></span>
            &nbsp;&nbsp;<b class="dark-cerulean text10">PROCEDURE TEMPLATES</b>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
