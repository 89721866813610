<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <!-- Node def for leaf node -->
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
    <button mat-icon-button disabled></button>
    <mat-checkbox
      class="checklist-leaf-node"
      [checked]="checklistSelection.isSelected(node)"
      (change)="todoLeafItemSelectionToggle(node)"
    >
      {{ leafDisplayFn ? leafDisplayFn(node.item) : node.item }}
    </mat-checkbox>
  </mat-tree-node>

  <!-- Node def for node with children -->
  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
    <button mat-icon-button matTreeNodeToggle>
      <mat-icon class="mat-icon-rtl-mirror">
        {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
      </mat-icon>
    </button>
    <mat-checkbox
      [checked]="descendantsAllSelected(node)"
      [indeterminate]="descendantsPartiallySelected(node)"
      (change)="selectionToggle(node)"
    >
      {{ parentDisplayFn ? parentDisplayFn(node.item) : node.item }}
    </mat-checkbox>
  </mat-tree-node>
</mat-tree>
