<mat-toolbar>
  <h2 class="text-right">Animal Information</h2>
</mat-toolbar>
<br />

<timeline
  #timeline_component
  [projid]="projid"
  [invalidID]="invalidID"
  [loading]="loadingProject"
  [(projsym)]="project.projsym"
  [(title)]="project.title"
  [project]="project"
  (clickExternalLink)="state_buttons_component.openExternalUrlAfterSave($event)"
  (clickPageLink)="state_buttons_component.openPageLinkAfterSave($event)"
  (clickReleaseProject)="state_buttons_component.releaseProject()"
  (clickReimportProject)="state_buttons_component.reimportProject()"
  (clickChangeStatus)="state_buttons_component.changeProjectStatus($event)"
  (clickImportExport)="state_buttons_component.onClickProjectImportExport(timeline_component.projectActionsComponent)"
  (projectChanged)="getProject()"
  (projectRestored)="state_buttons_component.projectRestored()"
  [actionLoadingBar]="state_buttons_component.loadingBar"
></timeline>

<div [style.display]="invalidID || loadingProject ? 'none' : 'block'">
  <mat-vertical-stepper>
    <mat-step label="Animal Acquisition Information">
      <fieldset [disabled]="!project.canEdit">
        <br />
        <div class="form-horizontal">
          <div class="form-group">
            <label for="source-of-mice" class="col-sm-2 control-label required">Source of Mice</label>
            <div class="col-sm-8">
              <textarea
                rows="3"
                id="source-of-mice"
                class="form-control"
                name="source-of-mice"
                placeholder="vendor or private stocks"
                [(ngModel)]="project.projdoc.source"
                maxlength="750"
              ></textarea>
            </div>
          </div>
          <div class="form-group">
            <label for="shipping-age" class="col-sm-2 control-label required">Age (wks)</label>
            <div class="col-sm-8">
              <input
                type="text"
                id="shipping-age"
                class="form-control"
                name="shipping-age"
                [(ngModel)]="project.projdoc.age"
                maxlength="120"
                placeholder="Age at shipping? Age at testing? (specify at least one)"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="breeding" class="col-sm-2 control-label">Breeding (optional)</label>
            <div class="col-sm-8">
              <textarea
                rows="3"
                id="breeding"
                class="form-control"
                name="breeding"
                [(ngModel)]="project.projdoc.breeding"
                maxlength="500"
              ></textarea>
            </div>
          </div>
        </div>
        <br />
      </fieldset>
      <div class="form-horizontal">
        <div class="form-group">
          <div class="col-sm-4 col-md-5"></div>
          <div class="col-sm-3 col-md-2">
            <button class="btn btn-info btn-block" matStepperNext id="step-1-next"><strong>Next</strong></button>
          </div>
        </div>
      </div>
    </mat-step>
    <mat-step label="Animal Housing Conditions">
      <fieldset [disabled]="!project.canEdit">
        <br />
        <div class="form-horizontal">
          <div class="form-group">
            <label for="test-facility" class="col-sm-2 control-label required">Housing Facility</label>
            <div class="col-sm-8">
              <input
                type="text"
                id="test-facility"
                class="form-control"
                name="test-facility"
                [(ngModel)]="project.projdoc.testfacility"
                maxlength="120"
                placeholder="institution name and animal room identifier(s); indicate type of facility, e.g., SPF"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="facility-acclimation" class="col-sm-2 control-label required">
              Acclimation to Housing Facility Before Testing
            </label>
            <div class="col-sm-8">
              <input
                type="text"
                id="facility-acclimation"
                class="form-control"
                name="facility-acclimation"
                [(ngModel)]="project.projdoc.facilityacclimation"
                maxlength="500"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="feed" class="col-sm-2 control-label required">Feed</label>
            <div class="col-sm-8">
              <input
                type="text"
                id="feed"
                class="form-control"
                name="feed"
                [(ngModel)]="project.projdoc.feed"
                maxlength="120"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="water" class="col-sm-2 control-label required">Water</label>
            <div class="col-sm-8">
              <input
                type="text"
                id="water"
                class="form-control"
                name="water"
                [(ngModel)]="project.projdoc.water"
                maxlength="120"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="bedding" class="col-sm-2 control-label required">Bedding</label>
            <div class="col-sm-8">
              <input
                type="text"
                id="bedding"
                class="form-control"
                name="bedding"
                [(ngModel)]="project.projdoc.bedding"
                maxlength="120"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="cage-type" class="col-sm-2 control-label required">
              Cage Type, Distributor, Material, Size
            </label>
            <div class="col-sm-8">
              <textarea
                rows="3"
                id="cage-type"
                class="form-control"
                name="cage-type"
                [(ngModel)]="project.projdoc.cagetype"
                maxlength="255"
              ></textarea>
            </div>
          </div>
          <div class="form-group">
            <label for="husbandry" class="col-sm-2 control-label">Husbandry</label>
            <div class="col-sm-8">
              <textarea
                rows="3"
                id="husbandry"
                class="form-control"
                name="husbandry"
                [(ngModel)]="project.projdoc.husbandry"
                maxlength="255"
              ></textarea>
            </div>
          </div>
          <div class="form-group">
            <label for="cage-density" class="col-sm-2 control-label required">Cage Density</label>
            <div class="col-sm-3">
              <input
                type="text"
                id="cage-density"
                class="form-control"
                name="cage-density"
                [(ngModel)]="project.projdoc.cagedensity"
                maxlength="120"
                placeholder="number of mice per enclosure"
              />
            </div>
            <label for="cage-enrichment" class="col-sm-2 control-label">Cage Enrichment</label>
            <div class="col-sm-3">
              <input
                type="text"
                id="cage-enrichment"
                class="form-control"
                name="cage-enrichment"
                [(ngModel)]="project.projdoc.cageenrich"
                maxlength="120"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="temperature" class="col-sm-2 control-label required">Temperature (&deg;C)</label>
            <div class="col-sm-3">
              <input
                type="text"
                id="temperature"
                class="form-control"
                name="temperature"
                [(ngModel)]="project.projdoc.temp"
                maxlength="120"
                placeholder="e.g., 23 +/- 1"
              />
            </div>
            <label for="humidity" class="col-sm-2 control-label required">Humidity (%)</label>
            <div class="col-sm-3">
              <input
                type="text"
                id="humidity"
                class="form-control"
                name="humidity"
                [(ngModel)]="project.projdoc.humidity"
                maxlength="120"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="light-dark-cycle" class="col-sm-2 control-label required">Light/Dark Cycle</label>
            <div class="col-sm-8">
              <input
                type="text"
                id="light-dark-cycle"
                class="form-control"
                name="light-dark-cycle"
                [(ngModel)]="project.projdoc.lightdark"
                maxlength="120"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="health-status" class="col-sm-2 control-label required">Health Status and Monitoring</label>
            <div class="col-sm-8">
              <input
                type="text"
                id="health-status"
                class="form-control"
                name="health-status"
                [(ngModel)]="project.projdoc.healthstatus"
                maxlength="120"
                placeholder="e.g., all mice healthy; sentinel animals tested routinely"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="adverse-events" class="col-sm-2 control-label">Welfare-Related Assessments</label>
            <div class="col-sm-8">
              <textarea
                rows="3"
                id="adverse-events"
                class="form-control"
                name="adverse-events"
                [(ngModel)]="project.projdoc.adverseevents"
                maxlength="750"
                placeholder="e.g., SJL males housed individually due to fighting"
              ></textarea>
            </div>
          </div>
          <div class="form-group">
            <label for="care-and-use" class="col-sm-2 control-label required">Care and Use Statement</label>
            <div class="col-sm-8">
              <textarea
                rows="3"
                id="care-and-use"
                class="form-control"
                name="care-and-use"
                [(ngModel)]="project.projdoc.careanduse"
                maxlength="255"
              ></textarea>
            </div>
          </div>
        </div>
        <br />
      </fieldset>
      <div class="form-horizontal">
        <div class="form-group">
          <div class="col-sm-3 col-md-4"></div>
          <div class="col-sm-3 col-md-2">
            <button class="btn btn-info btn-block" matStepperPrevious id="step-2-prev">
              <strong>Previous</strong>
            </button>
          </div>
          <div class="col-sm-3 col-md-2">
            <button class="btn btn-info btn-block" matStepperNext id="step-2-next"><strong>Next</strong></button>
          </div>
        </div>
      </div>
    </mat-step>
    <mat-step label="Additional Animal Information">
      <fieldset [disabled]="!project.canEdit">
        <br />
        <div class="form-horizontal">
          <div class="form-group">
            <label for="subject-identification" class="col-sm-2 control-label">Subject Identification</label>
            <div class="col-sm-8">
              <input
                type="text"
                id="subject-identification"
                class="form-control"
                name="subject-identification"
                [(ngModel)]="project.projdoc.subjectidentification"
                maxlength="500"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="additional-notes" class="col-sm-2 control-label">Additional Notes</label>
            <div class="col-sm-8">
              <textarea
                rows="5"
                id="additional-notes"
                class="form-control"
                name="additional-notes"
                [(ngModel)]="project.projdoc.animalnotes"
                maxlength="500"
              ></textarea>
            </div>
          </div>
        </div>
        <br />
      </fieldset>
      <div class="form-horizontal">
        <div class="form-group">
          <div class="col-sm-4 col-md-5"></div>
          <div class="col-sm-3 col-md-2">
            <button class="btn btn-info btn-block" matStepperPrevious id="step-3-prev">
              <strong>Previous</strong>
            </button>
          </div>
        </div>
      </div>
    </mat-step>
  </mat-vertical-stepper>
  <br />
  <br />
  <state-buttons
    [(project)]="project"
    [(dbProject)]="dbProject"
    #state_buttons_component
    (clickTitleLink)="timeline_component.titleInput.nativeElement.focus()"
    (saveFail)="timeline_component.setActive()"
    [currentPage]="'/animal-info/'"
    [nextPage]="'/procedures/'"
  ></state-buttons>
</div>
