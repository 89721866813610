<div *ngIf="!isCurator && !embed" class="row">
  <div class="col-sm-12">
    <div class="form-horizontal">
      <div>
        <br />
        <h4>You do not have access to this page.</h4>
      </div>
    </div>
  </div>
</div>

<div [style.display]="isCurator || embed ? 'block' : 'none'">
  <mat-toolbar *ngIf="!embed">
    <h2>Edit Methodologies Information</h2>
  </mat-toolbar>
  <div class="row">
    <div class="col-sm-12">
      <div *ngIf="!embed">
        &nbsp;&nbsp;&nbsp;
        <b><a class="text9" routerLink="/">Home</a></b>
        <span class="glyphicon glyphicon-chevron-right text9"></span>
        <b><a class="text9" routerLink="/tasks">Tasks</a></b>
        <span class="glyphicon glyphicon-chevron-right text9"></span>
        <b><span class="text9">Edit Methodologies Information</span></b>

        <br />
        <br />
        <br />
      </div>

      <div class="form-horizontal">
        <div [style.display]="embed ? 'none' : 'block'" class="form-group">
          <label class="col-sm-3 control-label">Search Methodologies</label>
          <div class="col-sm-6">
            <methodology-search
              [(methodology)]="methodology"
              (methodologyChange)="onMethodologyChange()"
              [showDialog]="false"
            >
            </methodology-search>
          </div>
        </div>

        <br />
        <div class="text-center">
          <span class="text16">
            <b>
              <span *ngIf="newMethodology">Create New</span>
              <span *ngIf="!newMethodology && canEdit()">Edit</span>
              <span *ngIf="!canEdit()">View</span>
              Methodology</b
            >
          </span>
        </div>
        <br />

        <fieldset [disabled]="!canEdit()">
          <div class="form-group has-feedback">
            <label for="meth-term" class="col-sm-3 control-label required">Term</label>
            <div class="col-sm-6">
              <input
                type="text"
                id="meth-term"
                name="meth-term"
                maxlength="80"
                class="form-control"
                [disabled]="!newMethodology"
                [(ngModel)]="methodology.term"
                (keyup)="validateMethodologyTerm()"
              />
              <span *ngIf="newMethodology && methodology.term" class="form-control-feedback">
                <span class="glyphicon glyphicon-ok green" *ngIf="!termTaken && isNotEmpty(methodology.term)"></span>
                <span class="glyphicon glyphicon-warning-sign red" *ngIf="termTaken && isNotEmpty(methodology.term)">
                </span>
              </span>
              <span class="red margin-top-bottom-2">
                <small *ngIf="termTaken && isNotEmpty(methodology.term) && newMethodology">
                  Methodology term is required and must be unique: '{{ methodology.term }}' is already taken.
                </small>
              </span>
            </div>
          </div>

          <div class="form-group">
            <label for="meth-extref" class="col-sm-3 control-label">External Reference</label>
            <div class="col-sm-6">
              <input
                id="meth-extref"
                name="meth-extref"
                class="form-control"
                [(ngModel)]="methodology.extref"
                maxlength="80"
              />
            </div>
          </div>

          <div class="form-group">
            <label for="meth-props" class="col-sm-3 control-label required">Properties</label>
            <div class="col-sm-6">
              <select
                id="meth-props"
                name="meth-props"
                class="form-control"
                [(ngModel)]="methodology.props"
                maxlength="80"
              >
                <option value="physio">Physiological</option>
                <option value="behav">Behavioral</option>
                <option value="both">Both</option>
                <option value="hidden">Hidden</option>
              </select>
            </div>
          </div>
        </fieldset>

        <!-- information about abstracts associated with this methodology -->
        <div *ngIf="!newMethodology && isCurator">
          <br />
          <div class="text-center">
            <span class="text10">
              <b>{{ methodology.abstracts_count }} abstracts are associated with this methodology</b>
            </span>
            [
            <a *ngIf="!showAbstracts && methodology.abstracts_count > 0" (click)="showAbstracts = true">show</a>
            <a *ngIf="showAbstracts" (click)="showAbstracts = false">hide</a>]
          </div>
          <div *ngIf="showAbstracts">
            <br />
            <p>{{ displayAbstracts() }}</p>
          </div>
        </div>
        <!-- END information about abstracts associated with this methodology section -->

        <!-- information about measures associated with this methodology -->
        <div *ngIf="!newMethodology && isCurator">
          <br />
          <div class="text-center">
            <span class="text10">
              <b>{{ methodology.measures_count }} measures are associated with this methodology</b>
            </span>
            [
            <a *ngIf="!showMeasures && methodology.measures_count > 0" (click)="showMeasures = true">show</a>
            <a *ngIf="showMeasures" (click)="showMeasures = false">hide</a>]
          </div>
          <div *ngIf="showMeasures">
            <br />
            <p>{{ displayMeasures() }}</p>
          </div>
        </div>
        <!-- END information about measures associated with this methodology section -->
        <br />

        <!-- save and delete buttons section -->
        <div class="form-group">
          <div [class]="(newMethodology && !embed) || !canEdit() ? 'col-sm-4' : 'col-sm-2'"></div>
          <div *ngIf="canEdit()" class="col-sm-4" [matTooltip]="!canSave() ? disableSaveMsg : ''">
            <button class="btn btn-success btn-block" [disabled]="!canSave()" (click)="saveMethodologyRecord()">
              <strong>Save</strong>
            </button>
          </div>
          <div *ngIf="!newMethodology && !embed" class="col-sm-4" [matTooltip]="!canDelete() ? disableDeleteMsg : ''">
            <button class="btn btn-danger btn-block" [disabled]="!canDelete()" (click)="deleteMethodologyRecord()">
              <strong>Delete</strong>
            </button>
          </div>
          <div class="col-sm-4" *ngIf="embed">
            <button class="btn btn-default btn-block" (click)="cancel.emit(true)">
              <strong>{{ canEdit() ? 'Cancel' : 'Close' }}</strong>
            </button>
          </div>
        </div>
        <!-- END save and delete buttons section -->
      </div>
    </div>
  </div>
</div>
