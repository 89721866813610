<div *ngIf="!isCurator && !embed" class="row">
  <div class="col-sm-12">
    <div class="form-horizontal" style="padding-left: 18px">
      <div>
        <br />
        <h4>You do not have access to this page.</h4>
      </div>
    </div>
  </div>
</div>
<div [style.display]="isCurator || embed ? 'block' : 'none'">
  <mat-toolbar *ngIf="!embed">
    <h2>Edit Procedures</h2>
  </mat-toolbar>
  <div class="row">
    <div class="col-sm-12">
      <div *ngIf="!embed">
        &nbsp;&nbsp;&nbsp;
        <b><a class="text9" routerLink="/">Home</a></b>
        <span class="glyphicon glyphicon-chevron-right text9"></span>
        <b><a class="text9" routerLink="/tasks">Tasks</a></b>
        <span class="glyphicon glyphicon-chevron-right text9"></span>
        <b><span class="text9">Edit Procedure Information</span></b>

        <br /><br /><br />
      </div>
      <div *ngIf="!projectEmbed" class="form-horizontal">
        <div *ngIf="!embed" class="form-group">
          <label class="col-sm-3 control-label">Search Procedures Templates</label>
          <div class="col-sm-6">
            <procedure-search
              #select_procedure
              [(procedure)]="procedure"
              [showDialog]="false"
              (procedureChange)="onTemplateChange()"
              [onlyTemplates]="true"
            >
            </procedure-search>
          </div>
        </div>
        <div align="center">
          <span class="text16">
            <b>
              <span *ngIf="!procedure.proc_id">Create New</span>
              <span *ngIf="procedure.proc_id && canEdit()">Edit</span>
              <span *ngIf="!canEdit()">View</span>
              Procedure{{ procedure.projid ? '' : ' Template' }}
            </b>
          </span>
        </div>
        <br />
        <div *ngIf="procedure.projid && procedure.proc_id">
          <div class="form-group">
            <label class="col-sm-3 control-label">Project Symbol</label>
            <div style="padding-top: 7px" class="col-sm-6">
              {{ procedure.projsym }}
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label">Project Title</label>
            <div style="padding-top: 7px" class="col-sm-6">
              {{ procedure.projtitle }}
            </div>
          </div>
        </div>
        <fieldset [disabled]="!canEdit()" *ngIf="!procedure.projid">
          <div class="form-group">
            <label for="proc_template_name" class="col-sm-3 control-label">
              Template Name
              <span
                class="glyphicon glyphicon-question-sign"
                matTooltip="Identifier for the procedure template. It may be the same as the title. NOTE:
                                Clearing this out and saving will result in it keeping its previous value."
              ></span>
            </label>
            <div class="col-sm-6">
              <input
                type="text"
                id="proc_template_name"
                class="form-control"
                [(ngModel)]="procedure.template_name"
                maxlength="255"
              />
            </div>
            <div style="padding-top: 7px; white-space: nowrap" class="col-sm-2">
              <label style="cursor: pointer">
                <input
                  [(ngModel)]="procedure.private"
                  id="proc_template_private"
                  name="proc_template_private"
                  type="checkbox"
                />
                &nbsp;Private </label
              >&nbsp;
              <span
                class="glyphicon glyphicon-question-sign"
                matTooltip="Private templates can only be used by the creator. Templates that are not private
                                (Public) can be used by anyone, but can only be modified by the creator."
              ></span>
            </div>
          </div>
          <div *ngIf="procedure.proc_id" class="form-group">
            <label class="col-sm-3 control-label">Template Creator</label>
            <div style="padding-top: 7px" class="col-sm-6">
              <person-display [person]="procedure.user_creator" [showCuratorIcon]="true"></person-display>
            </div>
          </div>
          <div *ngIf="!procedure.proc_id" class="form-group">
            <label [for]="'proc_title_new_' + (i + 1)" class="col-sm-3 control-label">
              Title
              <span
                class="glyphicon glyphicon-question-sign"
                matTooltip="Title of the procedure. The title will be defaulted to the same as the
                                template name if it's not populated."
              ></span>
            </label>
            <div class="col-sm-6">
              <input
                type="text"
                [id]="'proc_title_new_' + (i + 1)"
                class="form-control"
                [(ngModel)]="procedure.title"
                maxlength="255"
              />
            </div>
          </div>
          <div *ngIf="!procedure.proc_id" class="form-group">
            <div class="col-sm-4 col-xs-3"></div>
            <div
              class="col-sm-4 col-xs-6"
              [matTooltip]="
                !strSet(procedure.template_name) ? 'A template name is required to create a new procedure template' : ''
              "
            >
              <button
                (click)="saveProcedure()"
                [disabled]="!strSet(procedure.template_name)"
                class="btn btn-success btn-block"
              >
                Create Procedure Template
              </button>
            </div>
          </div>
        </fieldset>
      </div>
      <div *ngIf="procedure.proc_id">
        <div class="form-horizontal">
          <div *ngIf="showBioconnectAssayLink() && procedure.bc_assay_id" class="form-group">
            <label class="col-sm-3 control-label">
              Linked BioConnect Assay
              <img src="./assets/bioconnect_icon.ico" alt="" />
            </label>
            <div class="col-sm-6" style="padding-top: 7px">
              {{ procedure.bc_assay_identifier }}
              <a
                *ngIf="bioconnectUi"
                target="_blank"
                [href]="bioconnectUi + 'search/metadata-search-browse/detail-view/assay/' + procedure.bc_assay_id"
                matTooltip="Link to BioConnect"
              >
                <b><span class="glyphicon glyphicon-link"></span></b>
              </a>
            </div>
            <div class="col-sm-3">
              <button
                type="button"
                matTooltip="Unlink this Bioconnect Assay from this Procedure"
                class="btn btn-danger"
                (click)="
                  procedure.bc_assay_id = null; procedure.bc_assay_identifier = null; bcAssaySearch.clearSelection()
                "
              >
                X
              </button>
            </div>
          </div>
          <div
            [style.display]="showBioconnectAssayLink() && !procedure.bc_assay_id ? 'block' : 'none'"
            class="form-group"
          >
            <label class="col-sm-3 control-label">
              Link BioConnect Assay
              <img src="./assets/bioconnect_icon.ico" alt="" />
            </label>
            <div class="col-sm-6" style="padding-top: 7px">
              <bioconnect-assay-search
                #bcAssaySearch
                [disabled]="!canEdit()"
                [disableList]="projectProcedures"
                [(assay)]="bcAssay"
                [studyId]="bioconnectStudyId"
                (assayChange)="onAssayChange($event)"
              ></bioconnect-assay-search>
            </div>
          </div>
          <div *ngIf="procedure.external_id || (isCurator && canEdit())" class="form-group">
            <label [for]="'proc_external_id_' + (i + 1)" class="col-sm-3 control-label">
              External Identifier (PFS)
              <span
                class="glyphicon glyphicon-question-sign"
                matTooltip="Identifier (ID) of the same procedure in another system. Only one
                                template may have any given external ID. The first template created from
                                a procedure with a particular external ID value will become the default that is
                                loaded in for that external ID in the future. Please let a SIP Curator know
                                if the chosen template for an external ID should be changed, as
                                currently only curators can update external ID values."
              ></span>
            </label>
            <div class="col-sm-6">
              <input
                type="text"
                [id]="'proc_external_id_' + (i + 1)"
                class="form-control"
                [(ngModel)]="procedure.external_id"
                maxlength="80"
                [disabled]="!isCurator || !canEdit()"
              />
            </div>
          </div>
        </div>
        <br />
        <mat-accordion>
          <mat-expansion-panel [expanded]="!procedure.primaryentry && !procedure.title">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <b>{{ canEdit() ? 'Load from Other Procedure or ' : '' }}Save this Procedure to Template</b>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <hr />
            <div class="form-horizontal">
              <div class="form-group" *ngIf="canEdit()">
                <label class="col-sm-3 control-label">
                  Load from Other Procedure
                  <span
                    class="glyphicon glyphicon-question-sign"
                    matTooltip="Select a different procedure or procedure template and load (copy/clone) it into
                                    the CURRENT procedure."
                  ></span>
                </label>
                <div class="col-sm-6">
                  <procedure-search
                    #load_from_procedure
                    [(procedure)]="loadFromProcedure"
                    [showDialog]="true"
                    [disableList]="[procedure]"
                    [disableMessage]="'(Current Procedure)'"
                  >
                  </procedure-search>
                </div>
                <div
                  [matTooltip]="!loadFromProcedure.proc_id ? 'Select a procedure to load from.' : ''"
                  class="col-sm-3"
                >
                  <button
                    class="btn btn-primary btn-block"
                    [disabled]="!loadFromProcedure.proc_id"
                    (click)="loadProcedure(loadFromProcedure.proc_id)"
                  >
                    <strong><span class="glyphicon glyphicon-download"></span> Load</strong>
                  </button>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label">
                  Update a Procedure Template
                  <span
                    class="glyphicon glyphicon-question-sign"
                    matTooltip="Select an existing procedure template and clone/copy the CURRENT procedure into it.
                                    NOTE: Only procedure templates that you can edit appear in this selection list."
                  >
                  </span>
                </label>
                <div class="col-sm-6">
                  <procedure-search
                    #save_to_procedure
                    [(procedure)]="saveToProcedure"
                    [showDialog]="true"
                    [disableList]="[procedure]"
                    [disableMessage]="'(Current Procedure)'"
                    [onlyEditable]="true"
                  >
                  </procedure-search>
                </div>
                <div
                  [matTooltip]="
                    !saveToProcedure.proc_id
                      ? 'Select a procedure template to update.'
                      : !strSet(procedure.title)
                      ? 'The current procedure must have a title.'
                      : ''
                  "
                  class="col-sm-3"
                >
                  <button
                    class="btn btn-success btn-block"
                    [disabled]="!saveToProcedure.proc_id || !strSet(procedure.title)"
                    (click)="saveProcedureAsTemplateConfirm(saveToProcedure.proc_id)"
                  >
                    <strong><span class="glyphicon glyphicon-upload"></span> Update</strong>
                  </button>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-3 control-label">
                  Create a Procedure Template
                  <span
                    class="glyphicon glyphicon-question-sign"
                    matTooltip="Create a new procedure template and clone/copy the CURRENT procedure into it."
                  >
                  </span>
                </label>
                <div class="col-sm-4">
                  <input
                    type="text"
                    id="newTemplateName"
                    class="form-control"
                    placeholder="Template Name"
                    [(ngModel)]="newTemplateName"
                    maxlength="255"
                  />
                </div>
                <div style="padding-top: 7px; white-space: nowrap" class="col-sm-2">
                  <label style="cursor: pointer">
                    <input
                      [(ngModel)]="newTemplatePrivate"
                      [id]="'newTemplatePrivate' + (i + 1)"
                      name="newTemplatePrivate"
                      type="checkbox"
                    />
                    &nbsp;Private </label
                  >&nbsp;
                  <span
                    class="glyphicon glyphicon-question-sign"
                    matTooltip="Private templates can only be used by the creator. Templates that are not private
                                    (Public) can be used by anyone, but can only be modified by the creator."
                  ></span>
                </div>
                <div
                  [matTooltip]="
                    !strSet(newTemplateName)
                      ? 'A template name is required.'
                      : !strSet(procedure.title)
                      ? 'The current procedure must have a title.'
                      : ''
                  "
                  class="col-sm-3"
                >
                  <button
                    class="btn btn-success btn-block"
                    [disabled]="!strSet(newTemplateName) || !strSet(procedure.title)"
                    (click)="saveProcedureAsTemplateConfirm()"
                  >
                    <strong><span class="glyphicon glyphicon-plus-sign"></span> Create</strong>
                  </button>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <fieldset [disabled]="!canEdit()">
          <div class="form-horizontal">
            <div *ngIf="!procedure.primaryentry && !strSet(procedure.title)" align="center">
              <br />
              <hr />
              <b
                >OR
                <br />
                Enter New Protocol
                <br />
              </b>
            </div>
            <br />
            <div class="form-group">
              <label style="padding-top: 0" class="required col-sm-3 control-label">
                Enter Procedure (Primarily)
              </label>
              <div class="col-sm-8">
                <mat-radio-group [(ngModel)]="procedure.primaryentry" [disabled]="!canEdit()">
                  <mat-radio-button value="manual"> manually </mat-radio-button>
                  &nbsp;&nbsp;
                  <mat-radio-button value="upload"> in uploaded PDF </mat-radio-button>
                  &nbsp;&nbsp;
                  <mat-radio-button value="protocols_io"> in public protocols.io protocol </mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
            <div *ngIf="procedure.primaryentry === 'protocols_io'" class="form-group">
              <label [for]="'proc_protocolio_' + (i + 1)" class="col-sm-3 control-label">
                Link Protocols.io Protocol
              </label>
              <div class="col-sm-8">
                <protocols-io-search
                  [(protocol)]="procedure.protocols_io"
                  (protocolChange)="protocolIoChange()"
                ></protocols-io-search>
              </div>
            </div>
            <file-upload
              *ngIf="procedure.primaryentry === 'upload'"
              [allowEdit]="canEdit()"
              [pointertype]="procedureFilePointertype()"
              [optional]="false"
              [pointer]="procedureFilePointer()"
              [tag]="'procedure' + (i + 1) + 'pdf'"
              [imageOnly]="false"
              [showDescription]="false"
              [fileTypes]="'.pdf'"
              [fileNumLimit]="1"
              [title]="'Procedure PDF'"
            ></file-upload>
            <div *ngIf="showProcEntryFields()">
              <div class="form-group">
                <label [for]="'proc_title_' + (i + 1)" class="col-sm-3 control-label">
                  Title
                  <span
                    class="glyphicon glyphicon-question-sign"
                    [matTooltip]="
                      procedure.projid
                        ? 'NOTE: This must be populated in order for this procedure to be selectable to clone.'
                        : 'NOTE: Clearing this out and saving will result in it keeping its previous value.'
                    "
                  >
                  </span>
                </label>
                <div class="col-sm-8">
                  <input
                    type="text"
                    [id]="'proc_title_' + (i + 1)"
                    class="form-control"
                    [(ngModel)]="procedure.title"
                    maxlength="255"
                  />
                </div>
              </div>
              <div *ngIf="procedure.primaryentry !== 'manual'" align="center">
                <br />
                <hr />
                <b>Supplementary Procedure Information</b>
                <br /><br />
              </div>
              <div class="form-group">
                <label [for]="'proc_definitions_' + (i + 1)" class="col-sm-3 control-label">
                  Definitions & Abbreviations
                </label>
                <div class="col-sm-8">
                  <textarea
                    rows="3"
                    [id]="'proc_definitions_' + (i + 1)"
                    class="form-control"
                    [(ngModel)]="procedure.definitions"
                    maxlength="255"
                  ></textarea>
                </div>
              </div>
              <div class="form-group">
                <label [for]="'proc_fasting_' + (i + 1)" class="col-sm-3 control-label">Fasting (duration)</label>
                <div class="col-sm-8">
                  <input
                    type="text"
                    [id]="'proc_fasting_' + (i + 1)"
                    class="form-control"
                    [(ngModel)]="procedure.fasting"
                    maxlength="255"
                  />
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="showProcEntryFields()">
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title><b>Equipment, Software & Supplies</b></mat-panel-title>
                </mat-expansion-panel-header>
                <hr />
                <editable-table
                  [columns]="equipColumns"
                  [fields]="equipFields"
                  [(rows)]="procedure.equipment"
                  [id]="'proc_equipment_' + (i + 1)"
                  [allowChanges]="canEdit()"
                ></editable-table>
                <hr width="80%" class="text-center" />
                <file-upload
                  [allowEdit]="canEdit()"
                  [pointertype]="procedureFilePointertype()"
                  [title]="'Equipment Images'"
                  [pointer]="procedureFilePointer()"
                  [tag]="'procedure' + (i + 1) + 'equipment'"
                  [imageOnly]="true"
                ></file-upload>
              </mat-expansion-panel>
            </mat-accordion>
            <br />
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title><b>Reagents & Solutions</b></mat-panel-title>
                </mat-expansion-panel-header>
                <hr />
                <editable-table
                  [columns]="reagentsColumns"
                  [fields]="reagentsFields"
                  [(rows)]="procedure.reagents"
                  [id]="'proc_reagents_' + (i + 1)"
                  [allowChanges]="canEdit()"
                ></editable-table>
                <hr width="80%" class="text-center" />
                <file-upload
                  [allowEdit]="canEdit()"
                  [pointertype]="procedureFilePointertype()"
                  [title]="'Reagents Images'"
                  [pointer]="procedureFilePointer()"
                  [tag]="'procedure' + (i + 1) + 'reagents'"
                  [imageOnly]="true"
                ></file-upload>
              </mat-expansion-panel>
            </mat-accordion>
            <br />
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title><b>Environmental Conditions</b></mat-panel-title>
                </mat-expansion-panel-header>
                <hr />
                <div *ngFor="let env of procedure.environments; let j = index">
                  <mat-accordion>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <b>
                            {{
                              env.environment === 'holding'
                                ? 'Holding Area and/or Sample Collection Area'
                                : env.environment === 'testing'
                                ? 'Testing Area (if applicable)'
                                : env.environment
                            }}
                          </b>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <hr />
                      <div class="form-horizontal">
                        <div class="form-group">
                          <label
                            [for]="'proc_env_facility_' + env.environment + '_' + (i + 1)"
                            class="col-sm-3 control-label"
                            >Facility</label
                          >
                          <div class="col-sm-9">
                            <input
                              type="text"
                              [id]="'proc_env_facility_' + env.environment + '_' + (i + 1)"
                              [(ngModel)]="procedure.environments[j].facility"
                              class="form-control"
                              maxlength="120"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label
                            [for]="'proc_env_acclimation_' + env.environment + '_' + (i + 1)"
                            class="col-sm-3 control-label"
                            >Acclimation Period</label
                          >
                          <div class="col-sm-9">
                            <input
                              type="text"
                              [id]="'proc_env_acclimation_' + env.environment + '_' + (i + 1)"
                              [(ngModel)]="procedure.environments[j].acclimation"
                              class="form-control"
                              maxlength="120"
                            />
                          </div>
                        </div>
                        <div *ngIf="env.environment === 'holding'">
                          <div class="form-group">
                            <label
                              [for]="'proc_env_holdingdescrip_' + env.environment + '_' + (i + 1)"
                              class="col-sm-3 control-label"
                              >Description of Holding Enclosure</label
                            >
                            <div class="col-sm-9">
                              <textarea
                                rows="3 "
                                [id]="'proc_env_holdingdescrip_' + env.environment + '_' + (i + 1)"
                                [(ngModel)]="procedure.environments[j].holdingdescrip"
                                placeholder="(size, shape, material)"
                                class="form-control"
                                maxlength="500"
                              ></textarea>
                            </div>
                          </div>
                          <div class="form-group">
                            <label
                              [for]="'proc_env_holdingmax_' + env.environment + '_' + (i + 1)"
                              class="col-sm-3 control-label"
                              >Max # Animals Per Enclosure</label
                            >
                            <div class="col-sm-3">
                              <input
                                type="number"
                                [id]="'proc_env_holdingmax_' + env.environment + '_' + (i + 1)"
                                [(ngModel)]="procedure.environments[j].holdingmax"
                                (change)="
                                  procedure.environments[j].holdingmax = forceRange(
                                    procedure.environments[j].holdingmax,
                                    0,
                                    9999
                                  )
                                "
                                class="form-control"
                                min="0"
                                max="9999"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <label
                            [for]="'proc_env_space_' + env.environment + '_' + (i + 1)"
                            class="col-sm-3 control-label"
                            >Room Space (dimensions)</label
                          >
                          <div class="col-sm-9">
                            <input
                              type="text"
                              [id]="'proc_env_space_' + env.environment + '_' + (i + 1)"
                              [(ngModel)]="procedure.environments[j].space"
                              class="form-control"
                              maxlength="120"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label
                            [for]="'proc_env_lightsource_' + env.environment + '_' + (i + 1)"
                            class="col-sm-3 control-label"
                            >Light Source</label
                          >
                          <div class="col-sm-9">
                            <input
                              type="text"
                              [id]="'proc_env_lightsource_' + env.environment + '_' + (i + 1)"
                              [(ngModel)]="procedure.environments[j].lightsource"
                              class="form-control"
                              maxlength="120"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label
                            [for]="'proc_env_lightintensity_' + env.environment + '_' + (i + 1)"
                            class="col-sm-3 control-label"
                            >Light Intensity</label
                          >
                          <div class="col-sm-9">
                            <input
                              type="text"
                              [id]="'proc_env_lightintensity_' + env.environment + '_' + (i + 1)"
                              [(ngModel)]="procedure.environments[j].lightintensity"
                              class="form-control"
                              maxlength="120"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label
                            [for]="'proc_env_temperature_' + env.environment + '_' + (i + 1)"
                            class="col-sm-3 control-label"
                            >Temperature (&deg;C)</label
                          >
                          <div class="col-sm-3">
                            <input
                              type="text"
                              [id]="'proc_env_temperature_' + env.environment + '_' + (i + 1)"
                              [(ngModel)]="procedure.environments[j].temperature"
                              class="form-control"
                              maxlength="40"
                            />
                          </div>
                          <label
                            [for]="'proc_env_humidity_' + env.environment + '_' + (i + 1)"
                            class="col-sm-3 control-label"
                            >Humidity</label
                          >
                          <div class="col-sm-3">
                            <input
                              type="text"
                              [id]="'proc_env_humidity_' + env.environment + '_' + (i + 1)"
                              [(ngModel)]="procedure.environments[j].humidity"
                              class="form-control"
                              maxlength="40"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label
                            [for]="'proc_env_acousticback_' + env.environment + '_' + (i + 1)"
                            class="col-sm-3 control-label"
                            >Acoustic Background</label
                          >
                          <div class="col-sm-3">
                            <select
                              [(ngModel)]="procedure.environments[j].acousticback"
                              [id]="'proc_env_acousticback_' + env.environment + '_' + (i + 1)"
                              class="form-control"
                            >
                              <option value=""></option>
                              <option value="Ambient">Ambient</option>
                              <option value="White noise">White noise</option>
                            </select>
                          </div>
                          <label
                            [for]="'proc_env_acousticdepriv_' + env.environment + '_' + (i + 1)"
                            class="col-sm-3 control-label"
                            >Acoustic Deprivation</label
                          >
                          <div class="col-sm-3">
                            <select
                              [(ngModel)]="procedure.environments[j].acousticdepriv"
                              [id]="'proc_env_acousticdepriv_' + env.environment + '_' + (i + 1)"
                              class="form-control"
                            >
                              <option value="None">None</option>
                              <option value="Insulation/dampening">Insulation/dampening</option>
                              <option value="Sound attenuating chamber">Sound attenuating chamber</option>
                            </select>
                          </div>
                        </div>
                        <div class="form-group">
                          <label
                            [for]="'proc_env_barrier_' + env.environment + '_' + (i + 1)"
                            class="col-sm-3 control-label"
                            >Barrier Level</label
                          >
                          <div class="col-sm-9">
                            <input
                              type="text"
                              [id]="'proc_env_barrier_' + env.environment + '_' + (i + 1)"
                              [(ngModel)]="procedure.environments[j].barrier"
                              placeholder="(e.g., SPF)"
                              class="form-control"
                              maxlength="120"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="col-sm-3 control-label">Handling With</label>
                          <div class="col-sm-9">
                            <label style="cursor: pointer; padding-top: 7px; white-space: nowrap">
                              <input [(ngModel)]="procedure.environments[j].hands" type="checkbox" />
                              &nbsp;Hands </label
                            >&nbsp;&nbsp;&nbsp;
                            <label style="cursor: pointer; padding-top: 7px; white-space: nowrap">
                              <input [(ngModel)]="procedure.environments[j].forceps" type="checkbox" />
                              &nbsp;Forceps </label
                            >&nbsp;&nbsp;&nbsp;
                            <label style="cursor: pointer; padding-top: 7px; white-space: nowrap">
                              <input [(ngModel)]="procedure.environments[j].net" type="checkbox" />
                              &nbsp;Net </label
                            >&nbsp;&nbsp;&nbsp;
                            <label style="cursor: pointer; padding-top: 7px; white-space: nowrap">
                              <input [(ngModel)]="procedure.environments[j].transferbox" type="checkbox" />
                              &nbsp;Transfer Box
                            </label>
                            <br />
                            <label [for]="'proc_env_handlingother_' + env.environment + '_' + (i + 1)">Other</label>
                            &nbsp;&nbsp;
                            <input
                              type="text"
                              [id]="'proc_env_handlingother_' + env.environment + '_' + (i + 1)"
                              [(ngModel)]="procedure.environments[j].handlingother"
                              class="form-control"
                              maxlength="120"
                              style="width: calc(100% - 54px); display: inline"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label
                            [for]="'proc_env_restraint_' + env.environment + '_' + (i + 1)"
                            class="col-sm-3 control-label"
                            >Restraint</label
                          >
                          <div class="col-sm-9">
                            <input
                              type="text"
                              [id]="'proc_env_restraint_' + env.environment + '_' + (i + 1)"
                              [(ngModel)]="procedure.environments[j].restraint"
                              class="form-control"
                              maxlength="120"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label
                            [for]="'proc_env_anesthesia_' + env.environment + '_' + (i + 1)"
                            class="col-sm-3 control-label"
                            >Anesthesia</label
                          >
                          <div class="col-sm-9">
                            <textarea
                              rows="2"
                              [id]="'proc_env_anesthesia_' + env.environment + '_' + (i + 1)"
                              [(ngModel)]="procedure.environments[j].anesthesia"
                              placeholder="(compound, dose, roue of administration)"
                              class="form-control"
                              maxlength="255"
                            ></textarea>
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="col-sm-3 control-label">
                            Collection Method {{ env.environment === 'holding' ? ' (if applicable)' : '' }}
                          </label>
                          <div class="col-sm-9">
                            <span *ngIf="env.environment === 'testing'">
                              <label style="cursor: pointer; padding-top: 7px; white-space: nowrap">
                                <input [(ngModel)]="procedure.environments[j].wholemouse" type="checkbox" />
                                &nbsp;Whole Mouse </label
                              >&nbsp;&nbsp;&nbsp;
                            </span>
                            <label style="cursor: pointer; padding-top: 7px; white-space: nowrap">
                              <input [(ngModel)]="procedure.environments[j].bleed" type="checkbox" />
                              &nbsp;Bleed </label
                            >&nbsp;&nbsp;&nbsp;
                            <label style="cursor: pointer; padding-top: 7px; white-space: nowrap">
                              <input [(ngModel)]="procedure.environments[j].swab" type="checkbox" />
                              &nbsp;Swab </label
                            >&nbsp;&nbsp;&nbsp;
                            <label style="cursor: pointer; padding-top: 7px; white-space: nowrap">
                              <input [(ngModel)]="procedure.environments[j].necropsy" type="checkbox" />
                              &nbsp;Necropsy
                            </label>
                            <br />
                            <label [for]="'proc_env_collectionother_' + env.environment + '_' + (i + 1)">Other</label>
                            &nbsp;&nbsp;
                            <input
                              type="text"
                              [id]="'proc_env_collectionother_' + env.environment + '_' + (i + 1)"
                              [(ngModel)]="procedure.environments[j].collectionother"
                              class="form-control"
                              maxlength="120"
                              style="width: calc(100% - 54px); display: inline"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="col-sm-3 control-label">Technician Protection</label>
                          <div class="col-sm-9">
                            <label style="cursor: pointer; padding-top: 7px; white-space: nowrap">
                              <input [(ngModel)]="procedure.environments[j].gloves" type="checkbox" />
                              &nbsp;Gloves </label
                            >&nbsp;&nbsp;&nbsp;
                            <label style="cursor: pointer; padding-top: 7px; white-space: nowrap">
                              <input [(ngModel)]="procedure.environments[j].mask" type="checkbox" />
                              &nbsp;Mask </label
                            >&nbsp;&nbsp;&nbsp;
                            <label style="cursor: pointer; padding-top: 7px; white-space: nowrap">
                              <input [(ngModel)]="procedure.environments[j].faceshield" type="checkbox" />
                              &nbsp;Face Shield </label
                            >&nbsp;&nbsp;&nbsp;
                            <label style="cursor: pointer; padding-top: 7px; white-space: nowrap">
                              <input [(ngModel)]="procedure.environments[j].hood" type="checkbox" />
                              &nbsp;Hood </label
                            >&nbsp;&nbsp;&nbsp;
                            <label style="cursor: pointer; padding-top: 7px; white-space: nowrap">
                              <input [(ngModel)]="procedure.environments[j].clothing" type="checkbox" />
                              &nbsp;Lab Protective Clothing </label
                            >&nbsp;&nbsp;&nbsp;
                            <label style="cursor: pointer; padding-top: 7px; white-space: nowrap">
                              <input [(ngModel)]="procedure.environments[j].shoes" type="checkbox" />
                              &nbsp;Lab Shoes
                            </label>
                          </div>
                        </div>
                        <div *ngIf="env.environment === 'testing'">
                          <div class="form-group">
                            <label
                              [for]="'proc_env_testingnum_' + env.environment + '_' + (i + 1)"
                              class="col-sm-3 control-label"
                              ># Mice Tested at the Same Time</label
                            >
                            <div class="col-sm-3">
                              <input
                                type="number"
                                [id]="'proc_env_testingnum_' + env.environment + '_' + (i + 1)"
                                [(ngModel)]="procedure.environments[j].testingnum"
                                (change)="
                                  procedure.environments[j].testingnum = forceRange(
                                    procedure.environments[j].testingnum,
                                    0,
                                    9999
                                  )
                                "
                                class="form-control"
                                min="0"
                                max="9999"
                              />
                            </div>
                            <label class="col-sm-3 control-label">
                              Female and Male Mice Tested in Same Test Group?
                            </label>
                            <div style="padding-top: 7px" class="col-sm-3">
                              <mat-radio-group
                                [(ngModel)]="procedure.environments[j].mixedgroups"
                                [disabled]="!canEdit()"
                              >
                                <mat-radio-button [value]="true"> Yes </mat-radio-button>
                                &nbsp;&nbsp;
                                <mat-radio-button [value]="false"> No </mat-radio-button>
                                &nbsp;&nbsp;
                                <mat-radio-button [value]="null"> N/A </mat-radio-button>
                              </mat-radio-group>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <label
                            [for]="'proc_env_sanitation_' + env.environment + '_' + (i + 1)"
                            class="col-sm-3 control-label"
                          >
                            Sanitation of{{ env.environment === 'holding' ? ' Reused' : '' }} Equipment
                          </label>
                          <div class="col-sm-9">
                            <input
                              type="text"
                              [id]="'proc_env_sanitation_' + env.environment + '_' + (i + 1)"
                              [(ngModel)]="procedure.environments[j].sanitation"
                              class="form-control"
                              maxlength="120"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label
                            [for]="'proc_env_notes_' + env.environment + '_' + (i + 1)"
                            class="col-sm-3 control-label"
                            >Notes</label
                          >
                          <div class="col-sm-9">
                            <textarea
                              rows="3 "
                              [id]="'proc_env_notes_' + env.environment + '_' + (i + 1)"
                              [(ngModel)]="procedure.environments[j].notes"
                              class="form-control"
                              maxlength="500"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                  <br />
                </div>
              </mat-expansion-panel>
            </mat-accordion>
            <br />
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title><b>Steps</b></mat-panel-title>
                </mat-expansion-panel-header>
                <hr />
                <div class="form-horizontal">
                  <div class="form-group">
                    <label [for]="'proc_intro_' + (i + 1)" class="col-sm-3 control-label">
                      Introductory Comments (optional)
                    </label>
                    <div class="col-sm-9">
                      <textarea
                        rows="5"
                        [id]="'proc_intro_' + (i + 1)"
                        class="form-control"
                        [(ngModel)]="procedure.intro"
                        maxlength="500"
                      ></textarea>
                    </div>
                  </div>
                  <div class="form-group">
                    <label style="padding-top: 0" class="required col-sm-3 control-label">Enter steps</label>
                    <div class="col-sm-8">
                      <mat-radio-group [(ngModel)]="procedure.stepsformat" [disabled]="!canEdit()">
                        <mat-radio-button value="manual"> manually </mat-radio-button>
                        &nbsp;&nbsp;
                        <mat-radio-button value="upload"> in uploaded PDF </mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>
                </div>
                <editable-table
                  *ngIf="procedure.stepsformat === 'manual'"
                  [columns]="stepsColumns"
                  [(rows)]="procedure.steps"
                  [id]="'proc_steps_' + (i + 1)"
                  [title]="'Steps'"
                  [allowChanges]="canEdit()"
                ></editable-table>
                <file-upload
                  *ngIf="procedure.stepsformat === 'upload'"
                  [allowEdit]="canEdit()"
                  [pointertype]="procedureFilePointertype()"
                  [optional]="false"
                  [pointer]="procedureFilePointer()"
                  [tag]="'procedure' + (i + 1) + 'stepspdf'"
                  [imageOnly]="false"
                  [showDescription]="false"
                  [fileTypes]="'.pdf'"
                  [fileNumLimit]="1"
                  [title]="'Steps PDF'"
                ></file-upload>
                <hr width="80%" class="text-center" />
                <file-upload
                  [allowEdit]="canEdit()"
                  [pointertype]="procedureFilePointertype()"
                  [title]="'Steps Images'"
                  [pointer]="procedureFilePointer()"
                  [tag]="'procedure' + (i + 1) + 'steps'"
                  [imageOnly]="true"
                ></file-upload>
              </mat-expansion-panel>
            </mat-accordion>
            <br />
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title
                    ><b>
                      Additional ARRIVE Guidelines
                      <a href="https://www.ncbi.nlm.nih.gov/pubmed/20613859" target="_blank">
                        <span class="glyphicon glyphicon-link"></span>
                      </a> </b
                  ></mat-panel-title>
                </mat-expansion-panel-header>
                <hr />
                <div class="form-horizontal">
                  <div class="form-group">
                    <label [for]="'sample_size_est_' + (i + 1)" class="col-sm-3 control-label">
                      Sample Size Estimate
                    </label>
                    <div class="col-sm-9">
                      <input
                        [(ngModel)]="procedure.sampsizeest"
                        maxlength="255"
                        type="text"
                        [id]="'sample_size_est_' + (i + 1)"
                        [name]="'sample_size_est_' + (i + 1)"
                        class="form-control"
                        placeholder="number of mice per strain per sex to ensure statistical power"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <label [for]="'test_group_alloc' + (i + 1)" class="col-sm-3 control-label">
                      Allocation of Animals to Test Group
                    </label>
                    <div class="col-sm-9">
                      <input
                        [(ngModel)]="procedure.alloctestgroup"
                        maxlength="255"
                        type="text"
                        [id]="'test_group_alloc' + (i + 1)"
                        [name]="'test_group_alloc' + (i + 1)"
                        class="form-control"
                        placeholder="randomization method or matching, if done"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <label [for]="'proc_blind_' + (i + 1)" class="col-sm-3 control-label">Blinding</label>
                    <div class="col-sm-9">
                      <input
                        type="text"
                        [id]="'proc_blind_' + (i + 1)"
                        class="form-control"
                        [(ngModel)]="procedure.blinding"
                        maxlength="255"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <label [for]="'replications_' + (i + 1)" class="col-sm-3 control-label"> Replicates </label>
                    <div class="col-sm-3">
                      <select
                        [(ngModel)]="procedure.replications"
                        [id]="'replications_' + (i + 1)"
                        class="form-control"
                      >
                        <option [value]="true">Yes</option>
                        <option [value]="false">No</option>
                      </select>
                    </div>
                    <div class="col-sm-6">
                      <input
                        [(ngModel)]="procedure.replicationsexpl"
                        maxlength="255"
                        type="text"
                        [id]="'replications_expl_' + (i + 1)"
                        [disabled]="!procedure.replications"
                        [name]="'replications_expl_' + (i + 1)"
                        required
                        class="form-control"
                        placeholder="provide explanation"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <label [for]="'proc_criteria_' + (i + 1)" class="col-sm-3 control-label">
                      Data Inclusion & Exclusion Criteria
                    </label>
                    <div class="col-sm-6">
                      <input
                        type="text"
                        [id]="'proc_criteria_' + (i + 1)"
                        class="form-control"
                        [(ngModel)]="procedure.criteria"
                        maxlength="255"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <label [for]="'proc_collection_' + (i + 1)" class="col-sm-3 control-label"> Data Collection </label>
                    <div class="col-sm-9">
                      <input
                        type="text"
                        [id]="'proc_collection_' + (i + 1)"
                        class="form-control"
                        placeholder="e.g., automated or manual; provide details"
                        [(ngModel)]="procedure.datacollection"
                        maxlength="255"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <label [for]="'proc_dataqc_' + (i + 1)" class="col-sm-3 control-label">Data QC</label>
                    <div class="col-sm-9">
                      <input
                        type="text"
                        [id]="'proc_dataqc_' + (i + 1)"
                        class="form-control"
                        [(ngModel)]="procedure.dataqc"
                        maxlength="255"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <label [for]="'proc_analysis_' + (i + 1)" class="col-sm-3 control-label">
                      Interim Data Analysis
                    </label>
                    <div class="col-sm-9">
                      <input
                        type="text"
                        [id]="'proc_analysis_' + (i + 1)"
                        class="form-control"
                        [(ngModel)]="procedure.dataanalysis"
                        maxlength="255"
                      />
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
            <br />
            <div class="form-horizontal">
              <div class="form-group">
                <label [for]="'proc_formulas_' + (i + 1)" class="col-sm-3 control-label">Formulas & Derivations</label>
                <div class="col-sm-8">
                  <input
                    type="text"
                    [id]="'proc_formulas_' + (i + 1)"
                    class="form-control"
                    [(ngModel)]="procedure.formulas"
                    maxlength="255"
                  />
                </div>
              </div>
              <div class="form-group">
                <label [for]="'proc_safety_' + (i + 1)" class="col-sm-3 control-label">Safety Issues</label>
                <div class="col-sm-8">
                  <textarea
                    rows="3"
                    [id]="'proc_safety_' + (i + 1)"
                    class="form-control"
                    [(ngModel)]="procedure.safety"
                    maxlength="500"
                  ></textarea>
                </div>
              </div>
              <div class="form-group">
                <label [for]="'proc_notes_' + (i + 1)" class="col-sm-3 control-label">Investigator Notes</label>
                <div class="col-sm-8">
                  <textarea
                    rows="3"
                    [id]="'proc_notes_' + (i + 1)"
                    class="form-control"
                    [(ngModel)]="procedure.notes"
                    maxlength="500"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
        <div *ngIf="!projectEmbed" class="form-horizontal">
          <br />
          <div class="form-group">
            <div [class]="canEdit() ? 'col-sm-2' : 'col-sm-4'"></div>
            <div class="col-sm-4" *ngIf="canEdit()">
              <button class="btn btn-success btn-block" (click)="saveProcedure()"><strong>Save</strong></button>
            </div>
            <div class="col-sm-4" *ngIf="canEdit() && !embed">
              <button class="btn btn-danger btn-block" [disabled]="!canDelete()" (click)="deleteProcedure()">
                <strong>Delete</strong>
              </button>
            </div>
            <div class="col-sm-4" *ngIf="embed">
              <button class="btn btn-default btn-block" (click)="cancel.emit(true)">
                <strong>{{ canEdit() ? 'Cancel' : 'Close' }}</strong>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
