import { Component, Input, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';

declare let $: any;

@Component({
  selector: 'editable-table',
  templateUrl: './editable-table.component.html',
  styleUrls: ['./editable-table.component.scss'],
})
export class EditableTableComponent implements OnInit, OnChanges {
  // id to make the element ids within the component unique
  @Input() id = '';

  // title of the editable-table
  @Input() title = '';

  // array of objects, which defines the columns in the editable table:
  // [{name: header,
  //   key: key in rows dict entries for the value,
  //   maxlength: maxlength of the value,
  //   class: class(es) to include for the include on the <th> element
  //          (currently used for classes the define width %),
  //   disabled: true if inputs in this column should be disabled, false or not set otherwise},...]
  @Input() columns: any[] = [];

  // array of objects, which defines additional fields for the selected row
  // [{label: field label,
  //   key: key in rows dict entries for the value,
  //   maxlength: maxlength of the value]
  @Input() fields: any[] = [];

  // array of objects, which we are displaying & modifying in the editable-table
  @Input() rows: any[] = [];

  // true to allow editing, false to only allow viewing
  @Input() allowChanges = true;

  // true to allow adding of rows, false to not allow adding
  // (set this to false if you want control of adding rows to be in the parent component)
  @Input() allowAdd = true;

  // true to allow removing of rows, false to not allow removing
  // (set this to false if you want control of removing rows to be in the parent component)
  @Input() allowRemove = true;

  // event for emitting changes to the rows
  @Output() rowsChange: EventEmitter<any> = new EventEmitter<any>();

  // index of the currently selected rows
  rowSelected: number | null = null;

  // keep track of the timeout for fixRowHeights in case it is a called again before the current timeout ends
  fixRowHeightsTimeout: any = null;

  ngOnInit() {
    // this logic is for checking when the window width has changed in such a way that requires the row heights
    // to be resized. Above a certain threshold, bootstrap only changes the width at certain thresholds,
    // so we don't need to adjust row heights every time the width changes
    let prevFixWidth = $(window).width();
    $(window).resize(() => {
      const newWidth = $(window).width();
      let fixRowHeights = false;
      if (newWidth < 753) {
        if (prevFixWidth < 753) {
          fixRowHeights = prevFixWidth !== newWidth;
        } else {
          fixRowHeights = true;
        }
      } else if (prevFixWidth < 753) {
        fixRowHeights = newWidth >= 753;
      } else if ((prevFixWidth < 977 && newWidth >= 977) || (newWidth < 977 && prevFixWidth >= 977)) {
        fixRowHeights = true;
      } else if ((prevFixWidth < 1185 && newWidth >= 1185) || (newWidth < 1185 && prevFixWidth >= 1185)) {
        fixRowHeights = true;
      }
      if (fixRowHeights) {
        prevFixWidth = newWidth;
        this.fixRowHeights(50);
      }
    });
  }

  // when a change to the rows comes from the parent, fix the row heights
  ngOnChanges() {
    this.fixRowHeights();
  }

  // this happens any time anything changes in rows, so a quick function makes it easier
  emitRows() {
    this.rowsChange.emit(this.rows);
  }

  /**
   * Make sure that we can move the row in the direction we're trying to move it, and if we can then do the swap
   *
   * @param {number} index: row index we're moving
   * @param {boolean} down: true to move the row down, false to move it up
   */
  moveRow(index: number, down: boolean = false) {
    if (this.allowChanges) {
      if (down) {
        if (index < this.rows.length - 1) {
          this.swapRows(index, index + 1);
        }
      } else {
        if (index !== 0) {
          this.swapRows(index, index - 1);
        }
      }
    }
  }

  /**
   * Delete the passed-in row index
   * @param {number} index: row index to delete
   */
  deleteRow(index: number) {
    if (this.allowChanges && index >= 0 && index < this.rows.length) {
      if (this.rowSelected === index) {
        this.rowSelected = null;
      }
      this.rows.splice(index, 1);
      this.emitRows();
      this.fixRowHeights();
    }
  }

  /**
   * Swap the rows in indexOne and indexTwo
   *
   * @param {number} indexOne: row index we're swapping with indexTwo
   * @param {number} indexTwo: row index we're swapping with indexOne
   */
  swapRows(indexOne: number, indexTwo: number) {
    if (this.allowChanges) {
      const tempRow = this.rows[indexOne];
      this.rows[indexOne] = this.rows[indexTwo];
      this.rows[indexTwo] = tempRow;
      this.emitRows();
      if (indexOne === this.rowSelected) {
        this.rowSelected = indexTwo;
      } else if (indexTwo === this.rowSelected) {
        this.rowSelected = indexOne;
      }
      this.fixRowHeights();
    }
  }

  /**
   * Loop through all of the rows and fix their heights based on the contents of the rows
   *
   * @param {number} timeout: number of ms to wait before executing fixRowHeights
   *                          (needed to make sure that the rows have actually updated before running the logic)
   */
  fixRowHeights(timeout: number = 30) {
    if (this.fixRowHeightsTimeout) {
      clearTimeout(this.fixRowHeightsTimeout);
    }
    this.fixRowHeightsTimeout = setTimeout(() => {
      this.fixRowHeightsTimeout = null;
      for (let index = 0; index < this.rows.length; index++) {
        this.fixRowHeight(index);
      }
    }, timeout);
  }

  /**
   * Determine the hightest scrollheight of all of the textareas within the row and set one of then to be 'relative'
   * position with that height. Set all other textareas in the row to 'absolute' position with height = '100%'
   * (this causes them to use the full space available to them within the <td>).
   *
   * NOTE: There are several reasons that we're using textareas and going through all of this trouble to adjust their
   *       heights and styling and make them use the available area within the <td>, instead of just using
   *       contenteditable <td> elements:
   * 1. Using the textarea allows us to use [(ngModel)] to directly do 2-way binding of the textareas to keyed values
   *    in the rows object variable.
   * 2. It's easy to bind emitting the rows to the parent componet on (change) of any of the textarea values
   * 3. We can define maxlength and other attributes available to inputs.
   * 4. I don't think that the contenteditable attribute works in IE (although I'm sure that current logic works in IE)
   * 5. Most importantly: when someone hits <enter> in a contenteditable <td>, it adds a <br> in the content, rather
   *    than a line break how we would want it for going into the DB.
   *
   * @param {number} rowIndex: index of the row for which we are fixing the height
   */
  fixRowHeight(rowIndex: number) {
    const row: any = document.getElementById('table-' + this.id)?.querySelectorAll('tbody tr')[rowIndex];
    let tAreaRelative: any = null;
    let maxScrollHeight = 0;
    if (row) {
      Array.from(row.querySelectorAll('textarea')).forEach((tArea: any) => {
        if (!tAreaRelative) {
          tAreaRelative = tArea;
          tArea.style.height = 'auto';
          // minimum allowed scrollheight = 34
          maxScrollHeight = Math.max(34, tArea.scrollHeight);
        } else {
          maxScrollHeight = Math.max(maxScrollHeight, tArea.scrollHeight);
          // I don't think this is necessary...but just in case somehow the relative tArea changes
          tArea.style.position = 'absolute';
          tArea.style.height = '100%';
        }
      });
      tAreaRelative.style.height = maxScrollHeight + 'px';
      tAreaRelative.style.position = 'relative';
    }
  }

  /**
   * Get and return the current highest textarea scrollheight for textareas within the row (used by swapRows along with
   * setRowHeight to make the swap more instantaneous than it would be to call fixRowHeight after the rows
   * variable updates)
   *
   * @param {number} rowIndex: index of the row for which we are getting the height
   */
  getRowHeight(rowIndex: number): number {
    const row: any = document.getElementById('table-' + this.id)?.querySelectorAll('tbody tr')[rowIndex];
    let maxScrollHeight = 0;
    Array.from(row.querySelectorAll('textarea')).forEach((tArea: any) => {
      maxScrollHeight = Math.max(maxScrollHeight, tArea.scrollHeight);
    });
    return maxScrollHeight;
  }

  /**
   * Set the 'relative' position textarea height to the newHeight and set all other textareas in the row to 'absolute'
   * position with height = 'auto' (this causes them to use the full space available to them within the <td>).
   * (used by swapRows along with getRowHeight to make the swap more instantaneous than it would be to call
   * fixRowHeight after the rows variable updates)
   *
   * @param {number} rowIndex: index of the row for which we are setting the height
   * @param {number} newHeight: height that we are setting the 'relative' positioned textarea height to
   */
  setRowHeight(rowIndex: number, newHeight: number) {
    const row: any = document.getElementById('table-' + this.id)?.querySelectorAll('tbody tr')[rowIndex];
    let tAreaRelative: any = null;
    Array.from(row.querySelectorAll('textarea')).forEach((tArea: any) => {
      if (!tAreaRelative) {
        tAreaRelative = tArea;
      } else {
        // I don't think this is necessary...but just in case somehow the relative tArea changes
        tArea.style.position = 'absolute';
        tArea.style.height = '100%';
      }
    });
    tAreaRelative.style.height = String(newHeight) + 'px';
    tAreaRelative.style.position = 'relative';
  }
}
