import { Component, Input } from '@angular/core';

interface ProjectLink {
  id: number;
  projid: number;
  parentprojlinkid?: number | null;
  datatype: string;
  url: string;
  descrip: string;
  children: Array<ProjectLink>;
}

@Component({
  selector: 'project-links',
  templateUrl: './project-links.component.html',
  styleUrls: ['./project-links.component.scss'],
})
export class ProjectLinksComponent {
  @Input() projectLinks: ProjectLink[] = [];
  @Input() projectId!: number;
  @Input() canEdit!: boolean;

  newIds: number[] = [];

  /**
   * Add a project link to project level links collection
   *
   */
  addProjectLink(): void {
    const newProjLink = this.createProjectLink();
    newProjLink.parentprojlinkid = null;
    newProjLink.children = [];

    this.projectLinks.push(newProjLink);
  }

  /**
   * Add a child project link to parent collection
   * The child represents derived data
   *
   * @param {ProjectLink} parent: parent object child is being added to
   */
  addChildLink(parent: ProjectLink) {
    const newProjLink = this.createProjectLink();
    parent.children.push(newProjLink);
  }

  /**
   * Create a new ProjectLink object
   * Initial values are set to empty strings, but in the future
   * we may want to set value defaults
   *
   */
  createProjectLink(): ProjectLink {
    // assign a temporary id that won't clash with saved records
    // so that this can be safely removed if there are multiple unsaved links
    const newId = 0 - this.newIds.length;
    this.newIds.push(newId);

    return <ProjectLink>{
      id: newId,
      projid: this.projectId,
      datatype: '',
      url: '',
      descrip: '',
      children: [],
    };
  }

  /**
   * Remove a project link based on the id
   *
   * @param {number} id: primary key of the link to remove
   */
  removeProjectLink(id: number) {
    for (let i = 0; i < this.projectLinks.length; i++) {
      if (id === this.projectLinks[i].id) {
        // Promote any child links
        this.promoteChildLinks(this.projectLinks[i].children);
        // Remove the link from the top level project links collection
        this.projectLinks.splice(i, 1);
        break;
      }
    }
  }

  /**
   * Remove a child link from the parent collection
   * and remove it from the overall project links collection
   *
   * @param {ProjectLink} parent: the parent object this child link belongs to
   * @param {number} id: primary key of the link to remove
   */
  removeChildLink(parent: ProjectLink, id: number) {
    for (let i = 0; i < parent.children.length; i++) {
      if (id === parent.children[i].id) {
        parent.children.splice(i, 1);
        break;
      }
    }
  }

  /**
   * For a collection of child links belonging to a parent that is
   * being deleted, promote them to parent links
   *
   * @param {ProjectLink[]} childLinks: the collection of links to be promoted
   */
  promoteChildLinks(childLinks: ProjectLink[]) {
    childLinks.forEach((childLink) => {
      // Clear out the parent id
      childLink.parentprojlinkid = null;
      // Clear out the data type (they are different for parent and child links)
      childLink.datatype = '';
      // Initialize a blank children collection
      childLink.children = [];
      // Push the child to the top level project links collection
      this.projectLinks.push(childLink);
    });
  }
}
