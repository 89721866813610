<div class="text-center">
  <h2 class="gray7">DASHBOARD</h2>
</div>
<br />
<div class="row">
  <div class="col-md-6">
    <div class="panel panel-default">
      <div class="panel-heading">
        <div class="panel-title"><i class="fa fa-user"></i> Profile Overview</div>
      </div>
      <div class="panel-body">
        <div class="row">
          <div class="col-md-3 text-center">
            <svg height="100" width="100">
              <ellipse cx="50" cy="50" rx="50" ry="50" style="fill: #aaa" />
            </svg>
          </div>
          <div class="col-md-8">
            <h4>{{ user?.fullname }}</h4>
            <h4 class="dark-cerulean">{{ user?.institution.fullname }}</h4>
            {{ user?.email }}
          </div>
          <div class="col-md-1 text-center">
            <a [routerLink]="['/settings']">
              <i class="fa fa-cog" title="Edit Account Settings"></i>
            </a>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-12">
            <h4><b>Notification Settings</b></h4>
            <mat-checkbox>Send me project update notifications</mat-checkbox>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-12">
            <h4><b>My Groups</b></h4>
            <div *ngFor="let group of user?.groups">
              <a [routerLink]="['/group', group.id]">
                <h4 class="dark-cerulean">{{ group.name }} ({{ group.role }})</h4>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- START Latest Updates Section -->
  <div class="col-md-6">
    <div class="panel panel-default">
      <div class="panel-heading">
        <div class="panel-title"><i class="fa fa-edit"></i> Latest Updates</div>
      </div>
      <div class="panel-body">
        <div *ngFor="let project of userProjects">
          <div class="row text12">
            <div class="col-md-1"></div>

            <div class="col-md-9">
              <p>
                <span class="black">{{ project.ownerName }}</span> |
                <span class="black">{{ project.groupName }}</span>
                <br />
                <span class="text10"
                  ><b>{{ project.symbol }}</b
                  >: {{ project.title }}</span
                >
                <br />
                <span class="graya text9">updated {{ project.updated }} </span>
                <a [routerLink]="['/proj-details', project.id]" class="dark-cerulean text9">( view project )</a>
              </p>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </div>
  </div>
</div>
