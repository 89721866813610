import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

// dialog for adding/editing/viewing methodologies on-the-fly
@Component({
  selector: 'methodology-dialog',
  template: `
    <div mat-dialog-content style="min-width:800px;">
      <edit-methodology
        [(methodology)]="data.methodology"
        [embed]="true"
        (cancel)="dialogRef.close()"
        (methodologyChange)="dialogRef.close($event)"
      ></edit-methodology>
    </div>
  `,
})
export class MethodologyDialogComponent {
  constructor(public dialogRef: MatDialogRef<MethodologyDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}
}
