// Angular imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

// Modules
import { MaterialModule } from '../../shared/material.module';
import { SharedModule } from '../../shared/shared.module';

// Components
import { ProjectDetailsComponent } from './project-details.component';
import { ProjectLinksComponent } from './project-links/project-links.component';

@NgModule({
  imports: [CommonModule, FormsModule, MaterialModule, RouterModule, SharedModule],
  declarations: [ProjectDetailsComponent, ProjectLinksComponent],
})
export class ProjectDetailsModule {}
