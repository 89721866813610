import { Component, Input, Output, EventEmitter, SimpleChanges, OnInit, OnChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';

// used to remove unused/unneeded keys
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { BioconnectService } from '../../services/bioconnect.service';

@Component({
  selector: 'bioconnect-assay-search',
  template: `
    <!--Using fieldset as a wrapper to make disabling work (it works differently...and not as well...for
        inputs with the formControl attribute, but a wrapping fieldset seems to work fine)-->
    <div [class]="optionSelected() ? 'input-group' : ''">
      <fieldset [disabled]="optionSelected() || disabled">
        <span [matTooltip]="inputTooltip()">
          <input
            placeholder="Begin typing a assay identifier"
            class="form-control left-bord-rad-4px"
            autocomplete="off"
            [matAutocomplete]="auto"
            [formControl]="valueCtrl"
            (focus)="inputFocused = true"
            (focusout)="inputFocused = false"
            [style.border-color]="inputNoSelection() ? 'red' : ''"
          />
        </span>
        <span class="form-control-feedback">
          <span *ngIf="optionSelected()" style="color:green;right:80px;" class="glyphicon glyphicon-ok"></span>
          <span
            *ngIf="inputNoSelection()"
            style="color:red;right:20px;"
            class="glyphicon glyphicon-warning-sign"
          ></span>
        </span>
      </fieldset>
      <div *ngIf="optionSelected()" class="input-group-btn">
        <a
          *ngIf="bioconnectUi"
          target="_blank"
          [href]="bioconnectUi + 'search/metadata-search-browse/detail-view/assay/' + assay.id"
          matTooltip="Link to BioConnect"
          class="btn btn-primary"
        >
          <span class="glyphicon glyphicon-link clear-icon"></span>
        </a>
        <button
          (click)="clearSelection()"
          type="button"
          [disabled]="disabled"
          matTooltip="Clear selected assay"
          class="btn btn-danger"
        >
          <span class="glyphicon glyphicon-remove clear-icon"></span>
        </button>
      </div>
    </div>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected()" [displayWith]="displayFn">
      <mat-option disabled *ngIf="filteredOptions.length === 0" style="color:red;">
        <span style="color:black;" *ngIf="loading">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>Loading results...
        </span>
        <span *ngIf="!loading"> No matches. </span>
      </mat-option>
      <mat-option
        *ngFor="let option of filteredOptions"
        [value]="option"
        [disabled]="disableOption(option)"
        [matTooltip]="disableOption(option) ? '(Already Selected)' : ''"
      >
        {{ option.identifier }}
      </mat-option>
    </mat-autocomplete>
  `,
  styleUrls: ['searching.scss'],
})
export class BioconnectAssaySearchComponent implements OnInit, OnChanges {
  // true if selection and clearing should be disabled, false if not
  @Input() disabled = false;

  @Input() disableList: any[] = [];

  // study ID for which we are getting linked assays
  @Input() studyId: number | null = null;

  // bioconnect assay object 2-way binding
  @Input() assay: any = {};

  // event emitter to send the selected assay to the parent when one is selected from the autocomplete options
  @Output() assayChange: EventEmitter<any> = new EventEmitter<any>();

  // formControl for the input value
  valueCtrl: FormControl;

  // true if the input is currently focused, false otherwise
  inputFocused = false;

  // used to link out to the selected assay
  bioconnectUi = environment.unsecuredURLs.bioconnectUi;

  // true while initial results are being loaded from the api
  loading = false;

  // unfiltered list of loaded assays
  options: any[] = [];

  // filtered autocomplete options, list of assays
  filteredOptions: any[] = [];

  // sets up a subscription on change of the filtering value to build a list of results from the ones that are loaded in
  constructor(public http: HttpClient, public dialog: MatDialog, public bioconnectService: BioconnectService) {
    this.valueCtrl = new FormControl();
    this.valueCtrl.valueChanges.pipe(debounceTime(500)).subscribe((result) => {
      this.filterValues(result);
    });
  }

  // load in options...
  ngOnInit() {
    this.loading = true;
    this.getAssaysForStudy(this.studyId);
  }

  // on change of the assay by the parent, populate it on this search component
  ngOnChanges(changes: SimpleChanges) {
    if (this.assay && changes.assay ? this.assay.id : false) {
      this.valueCtrl.setValue(this.assay);
    }
    if (changes.studyId) {
      this.getAssaysForStudy(this.studyId);
    }
  }

  // determines whether an option was selected
  optionSelected(): boolean {
    return this.assay && typeof this.assay === 'object' ? this.assay.id : false;
  }

  // determines whether the current user has input a value without selecting an option
  inputNoSelection(): boolean {
    return !this.optionSelected() && this.valueCtrl.value && !this.inputFocused;
  }

  // builds the tooltip for the input if there's an issue
  inputTooltip(): string {
    if (this.inputNoSelection()) {
      return 'Must select option for this field to be populated (click in field to see options)';
    }
    return '';
  }

  /**
   * Build a list of bioconnect assays based on the page size
   *
   * @param {any} newValue: string entered by the user or selected object
   */
  filterValues(newValue: any) {
    newValue = newValue ? newValue : '';
    this.filteredOptions = [];
    if (typeof newValue !== 'string' || !newValue) {
      this.filteredOptions = this.options;
    } else {
      newValue = newValue.toLowerCase();
      this.filteredOptions = this.options.filter((option) => {
        return option.identifier.toLowerCase().indexOf(newValue) !== -1;
      });
    }
  }

  // queries the bioconnect api for the next page of results and appends them to the options
  getAssaysForStudy(studyId: number | null) {
    if (studyId) {
      this.loading = true;
      // triggers 1 request to the service to cache results for this study ID... if it has already been retrieved, then
      // it will already be in the studyAssays
      this.bioconnectService.getStudyAssays(studyId);
      this.bioconnectService.studyAssays$.subscribe((result: any) => {
        if (result[studyId]) {
          this.options = result[studyId];
          this.filterValues(this.valueCtrl.value);
          this.loading = false;
        }
      });
    }
  }

  /**
   * display function for the assay object
   * @param {any} option: assay object or undefined
   * @returns {string}: display str
   */
  displayFn(option?: any): string | undefined {
    if (!option) {
      return undefined;
    }
    if (typeof option === 'object') {
      return option.identifier;
    }
    return option;
  }

  /**
   * Determine if the option for selecting this object should be disabled because it's in the disableList
   * @param {Object} option: object option
   * @returns {boolean}: true to disable this object option, false to not
   */
  disableOption(option: any) {
    for (let i = 0; i < this.disableList.length; i++) {
      if (this.disableList[i].bc_assay_id === option.id) {
        return true;
      }
    }
    return false;
  }

  // if an option is selected, emit a assayChange event so that the parent knows who is selected
  onOptionSelected() {
    this.assay = this.valueCtrl.value;
    this.assayChange.emit(this.assay);
  }

  // clear currently selected object
  clearSelection() {
    this.valueCtrl.setValue('');
    this.filterValues('');
    this.assay = {};
    this.assayChange.emit(this.assay);
  }
}
