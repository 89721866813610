import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Person, PersonService } from '../../services/person.service';
import { ProjectService } from '../../services/project.service';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {
  // user information
  user?: Person;

  // projects associated with the current user
  userProjects: ProjectSummary[] = [];

  constructor(public http: HttpClient, private personService: PersonService, private projectService: ProjectService) {}

  ngOnInit() {
    // get current user
    this.personService.currentUser$.subscribe((user) => {
      if (user?.id) {
        this.user = user;
      }
    });

    // get projects associated with this user;
    this.projectService.searchProjects('?userid=current').subscribe((data) => {
      if (data.projects) {
        // extract needed information from each project
        for (const project of data.projects) {
          // users can be associated with some project
          // either individually or through a group
          const ownerName: string = project.owner.fullname;
          let groupName = '';

          if (project.owner.id !== this.user?.id) {
            if (project.other_shared_entities.length) {
              groupName = project.other_shared_entities[0];
            } else {
              // TODO: does this need to get fixed?
              groupName = 'Test'; // groupName = this.userProfile.fullname;
            }
          }

          this.userProjects.push({
            id: project.projid,
            groupName: groupName,
            ownerName: ownerName,
            symbol: project.projsym,
            updated: project.updatedtime,
            title: project.title,
          });
        }

        // projects are sorted based on their update dates
        this.userProjects.sort((a, b) => (new Date(a.updated) < new Date(b.updated) ? 1 : -1));
        // only the 4 most recently updated projects are displayed (on the home page)
        this.userProjects = this.userProjects.slice(0, 4);
      }
    });
  }
}

interface ProjectSummary {
  id: number;
  symbol: string;
  title: string;
  ownerName: string;
  groupName: string;
  updated: string;
}
