import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';

// environments
import { environment } from '../../../../environments/environment';
// services
import { ProjectService } from '../../../services/project.service';
import { TimeSeriesPlotComponent } from '../plots/time-series-plot.component';
import { StrainSurveyPlotComponent } from '../plots/strain-survey-plot.component';

@Component({
  selector: 'qc-technical',
  templateUrl: './qc-technical.component.html',
  styleUrls: ['../data-validation.scss'],
})
export class QCTechnicalComponent implements OnInit {
  projectId: string | null = '';
  // 'false' when 'project id' is either invalid or when user does not have permission to access the project
  isIdInvalid = false;

  // project object that is displayed in the page (and edited when user makes modifications)
  project: any = {};
  // project object that represents the state of the project as of the last import (user to compare with
  // the project object when saving/exporting in order to determine what has changed)
  dbProject: any = {};

  @ViewChild(TimeSeriesPlotComponent) tsplot!: TimeSeriesPlotComponent;

  @ViewChild(StrainSurveyPlotComponent) ssplot!: StrainSurveyPlotComponent;

  // project's measures
  phenoMeasures: any[] = [];

  // informational variables (displayed in select options)
  phenoInfoVariables: string[] = [];
  categoricalFactors: string[] = [];

  status = 'complete';

  // column definitions for the table displaying measures
  measTableColumns = [
    { key: 'measnum', label: 'Measure ID', width: '2', inline_label: 'measure' },
    { key: 'varname', label: 'Name', width: '4', inline_label: 'varname' },
    { key: 'descrip', label: 'Description', width: '4', inline_label: 'descrip' },
    { key: 'intervention', label: 'Intervention', width: '2', inline_label: 'intervention' },
    { key: 'units', label: 'Units', width: '2', inline_label: 'units' },
    { key: 'datatype', label: 'Data Type', width: '2', inline_label: 'datatype' },
    { key: 'nstrainstested', label: 'Strains #', width: '2', inline_label: 'nstrainstested' },
    { key: 'granularity', label: 'Granularity', width: '2', inline_label: 'granularity' },
  ];

  // full API data - depending on selected options
  // the actual plotted data could be a subset
  qcData: any[] = [];

  selectedMeasure: {
    datatype: string;
    id: string;
    seriesvarsuffix: string[];
    varname: string;
    units: string;
    projsym: string;
    seriestype: string;
    serieslabels: string[];
    seriesvarnames: string[];
  } = {
    datatype: '',
    id: '',
    seriesvarsuffix: [],
    varname: '',
    units: '',
    projsym: '',
    seriestype: '',
    serieslabels: [],
    seriesvarnames: [],
  };

  showSeries = false;
  showStrainSurvey = false;
  isMeasureSelected = false;

  subscription: Subscription | undefined;

  visType = 'cross-sec-view'; // default value

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private projectService: ProjectService,
    public router: Router,
  ) {}

  ngOnInit() {
    this.projectId = this.route.snapshot.paramMap.get('id') || null;

    if (Number(this.projectId)) {
      // this project's measures
      this.getProjectMeasures();
    } else {
      this.isIdInvalid = true;
    }
  }

  /**
   * Calls the function sequence to set and render the visualization
   * for the specified visualization type - either 'Series' or 'Cross-Sectional'
   */
  updateVisView(): void {
    if (this.isMeasureSelected) {
      switch (this.visType) {
        case 'cross-sec-view':
          this.showStrainSurvey = true;
          this.showSeries = false;

          // setup and plot data
          this.ssplot.setPlotOptions('');
          this.ssplot.setplotCategories();
          this.ssplot.setplotData();
          this.ssplot.render();
          break;
        case 'series-view':
          this.showStrainSurvey = false;
          this.showSeries = true;

          // setup vis data
          this.tsplot.initVis();
          this.tsplot.setplotCategories();
          this.tsplot.setplotDataSeries();

          this.tsplot.setpopulationMeanStdDev();
          this.tsplot.render();
          break;
        default:
          break;
      }
    } else {
      this.showStrainSurvey = false;
      this.showSeries = false;
    }
  }

  /**
   * Changes the plot view to either 'Cross-Sectional' or 'Series'
   * and calls the appropriate methods to set and render the visualization.
   * @param {any} $event - object containing the triggered event properties
   */
  onVisViewChange($event: any): void {
    this.visType = $event.value;
    this.setMeasureInfoVars();
    this.updateVisView();
  }

  /**
   * Handles row selection events: captures the measure ID and
   * calls the visualization initiation function.
   * @param event - triggered event object
   */
  onRowSelect(event?: any): void {
    // This is more Highcharts and I don't want to have to debug this
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;

    // select event: length is zero when row has been deselected
    if (event.length > 0) {
      that.isMeasureSelected = true;
      const measure = event[0];

      // case when the last selected measure was series and the next
      // selected measure is cross section - 'visType' needs to change
      if (this.visType === 'series-view' && !measure.seriestype) {
        this.visType = 'cross-sec-view';
      }

      // set factors and series attributes
      if (measure.pheno_series.length > 0) {
        // will be an array such as ['1_1', '1_2', '1_8', '1_12', '1_14']
        const factorCombinations: string[] = [];

        measure.pheno_series.forEach((s: any) => {
          const numFactors = s.factors.length;
          if (numFactors > 0) {
            // from 'dxa_bmd_1_1' separate '1_1' - that's (2) factor combination
            const combination = s.indiv_varname.split('_').slice(-numFactors).join('_');
            factorCombinations.push(combination);
          }
        });

        that.selectedMeasure.seriesvarsuffix = factorCombinations;
        that.selectedMeasure.serieslabels = measure.serieslabels.split(',');
      }

      that.selectedMeasure.datatype = measure.datatype;
      that.selectedMeasure.id = measure.measnum;
      that.selectedMeasure.varname = measure.varname;
      that.selectedMeasure.units = measure.units;
      that.selectedMeasure.projsym = measure.projsym;
      that.selectedMeasure.seriestype = measure.seriestype;
      that.selectedMeasure.seriesvarnames = measure.pheno_series.map((m: any) => m.indiv_varname);

      // set 'informational variables' selects options
      that.setMeasureInfoVars();
    } else {
      that.isMeasureSelected = false;

      let k: keyof typeof that.selectedMeasure;
      for (k in that.selectedMeasure) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore I don't really understand what this is doing but I don't really care to screw with it
        that.selectedMeasure[k] = '';
      }

      that.isMeasureSelected = false;
    }

    this.updateVisView();
  }

  /**
   * subscribes to an observable service, which returns project data information;
   * populates the 'qcData' array
   */
  getProjectData(): void {
    // empty the array
    this.qcData.length = 0;

    const apiLink = `${environment.securedURLs.sip}projects/phenotypes/projanimaldata/${this.projectId}`;

    this.subscription = this.getApiResponse(apiLink).subscribe(
      (data) => {
        this.qcData = data;
        this.project.pheno_measures.forEach((m: any) => {
          // when measure type is not specified, it defaults to numeric
          if (!m.datatype) {
            m.datatype = 'numeric';
          }

          if (!m.is_measure && m.datatype === 'categorical') {
            this.categoricalFactors.push(m.varname);
          } else if (m.is_measure === true) {
            this.phenoMeasures.push(m);
          }
        });

        this.phenoMeasures = [...this.phenoMeasures];
        this.status = 'complete';
      },
      () => {
        this.status = `couldn't load project: ${this.projectId} measures.`;
      },
    );
  }

  /**
   * subscribes to an observable service, which returns project information;
   * populates the 'phenoMeasures' and 'phenoInfoVariables' arrays
   */
  getProjectMeasures(): void {
    this.status = 'loading';
    if (this.projectId) {
      this.projectService.getProject(this.projectId).subscribe(
        (data) => {
          this.isIdInvalid = data.table_form;

          if (!this.isIdInvalid) {
            this.phenoMeasures.length = 0;
            this.categoricalFactors.length = 0;
            this.project = data;

            this.dbProject = JSON.parse(JSON.stringify(data));

            if (data.pheno_measures) {
              this.getProjectData();
            }
          }
        },
        () => {
          this.isIdInvalid = true;
          this.status = `couldn't load project: ${this.projectId}`;
        },
      );
    }
  }

  /**
   * Sends request to an API and returns an Observable
   * @param url {string} -
   * @return Observable - API response
   */
  getApiResponse(url: string): Observable<any> {
    return this.http.get<any[]>(url, {});
  }

  /**
   * sets the applicable categorical factors with the
   * selected measure based on specific criteria
   */
  setMeasureInfoVars() {
    this.phenoInfoVariables.length = 0;

    const regExp = /_{1,}/;
    const nameCat = this.selectedMeasure.varname.split(regExp, 1)[0];
    // some factors need to be filtered out
    for (const c of this.categoricalFactors) {
      const cat = c.split(regExp);

      if (cat[0] && cat[0] === nameCat) {
        this.phenoInfoVariables.push(c);
      } else if (cat.length === 1 && cat[0] !== 'jr') {
        this.phenoInfoVariables.push(c);
      }
    }

    // 'strain' needs to be added as informational variable to series measures
    if (this.selectedMeasure.seriestype === 'series') {
      this.phenoInfoVariables.push('strain');
    }

    this.phenoInfoVariables.sort();
  }
}
