// This is environment configuration is based on environment.default-prod.ts
// and is used for deploying to studyintake

import { Environment } from './environment.default';

export const environment: Environment = {
  production: true,
  version: require('../../../../package.json').version,
  // API/services (if running app non-locally, these need to be using HTTPS or else Auth0 gets upset)
  securedURLs: {
    sip: 'https://studyintake.jax.org/api/',
    mpd: 'https://phenome.jax.org/',
    mpdPreview: 'https://phenome.jax.org/',
    r2: 'https://r2.jax.org/',
    divdb: 'https://divdb.jax.org/api/',
  },
  unsecuredURLs: {
    wookie: 'https://divdb.jax.org/',
    // blank for now to disable in prod
    bioconnect: '',
    bioconnectUi: '',
    protocolsIO: 'https://www.protocols.io/api/v3/',
  },
  // application-specific variables
  fileArchiveDays: 30,
  projectArchiveDays: 30,
  maxFileSize: '1 GB',
  protocolsIOPublicToken: 'd5f90f48bba10ae26787785cbf78e706d264cb442317336f133f7fd7d8f5330c',
  // authentication details
  authDomain: 'thejacksonlaboratory.auth0.com',
  authClientID: '6tFN09MHIY7VRsEWLEgXwam1sATYWwCT',
  authAudience: 'https://cube.jax.org',
  // feature flag
  showDevelopmentFeatures: false,
};
