<div *ngIf="!isCurator && !embed" class="row">
  <div class="col-sm-12">
    <div class="form-horizontal">
      <div>
        <br />
        <h4>You do not have access to this page.</h4>
      </div>
    </div>
  </div>
</div>

<div [style.display]="isCurator || embed ? 'block' : 'none'">
  <mat-toolbar *ngIf="!embed">
    <h2>Edit Strain Information</h2>
  </mat-toolbar>
  <div class="row">
    <div class="col-sm-12">
      <div *ngIf="!embed">
        &nbsp;&nbsp;&nbsp;
        <b><a class="text9" routerLink="/">Home</a></b>
        <span class="glyphicon glyphicon-chevron-right text9"></span>
        <b><a class="text9" routerLink="/tasks">Tasks</a></b>
        <span class="glyphicon glyphicon-chevron-right text9"></span>
        <b><span class="text9">Edit Strain Information</span></b>

        <br />
        <br />
        <br />
      </div>
      <div class="form-horizontal">
        <div [style.display]="embed ? 'none' : 'block'" class="form-group">
          <label class="col-sm-3 control-label">Search Strains</label>
          <div class="col-sm-6">
            <strain-search [(strain)]="strain" (strainChange)="onStrainChange()" [showDialog]="false"></strain-search>
          </div>
        </div>

        <br />
        <div class="text-center">
          <span class="text16">
            <b>
              <span *ngIf="newStrain">Create New</span>
              <span *ngIf="!newStrain && canEdit()">Edit</span>
              <span *ngIf="!canEdit()">View</span>
              Strain</b
            >
          </span>
        </div>
        <br />
        <fieldset [disabled]="!canEdit()">
          <div class="form-group has-feedback">
            <label for="strain-name" class="col-sm-3 control-label required">
              Name
              <span
                class="glyphicon glyphicon-question-sign"
                matTooltip="Short strain name, up to 80 characters. Allowed characters are numbers,
                                letters, spaces, and the following special characters: <>:;+/-.()[]_
                                (leading and trailing spaces are removed). Use angle brackets to denote superscript,
                                for example 'Name<superscript>'."
              ></span>
            </label>
            <div class="col-sm-6">
              <input
                type="text"
                id="strain-name"
                name="strain-name"
                maxlength="80"
                class="form-control"
                [(ngModel)]="strain.aname"
                (keyup)="onKeyUp('aname')"
              />

              <span *ngIf="strain.aname" class="form-control-feedback">
                <span class="glyphicon glyphicon-ok green" *ngIf="!isAnameWhitespace"></span>
                <span class="glyphicon glyphicon-warning-sign red" *ngIf="isAnameWhitespace"></span>
              </span>
              <span class="red margin-top-bottom-2">
                <small *ngIf="isAnameWhitespace"> Strain names can't have empty values. </small>
              </span>
            </div>
          </div>

          <div class="form-group">
            <label class="col-sm-3 control-label">Vendor</label>
            <div class="col-sm-6">
              <strain-vendor-search
                (vendorChange)="onStrainVendorChange($event)"
                [(vendor)]="strain.strainvendor"
              ></strain-vendor-search>
            </div>
          </div>

          <div class="form-group has-feedback">
            <label for="strain-stock-num" [attr.class]="stockNumberClass">
              Stock Number
              <span
                class="glyphicon glyphicon-question-sign"
                matTooltip="Stock number for the selected vendor (must select a vendor to enter a
                                Stock Number). Allowed characters are currently numbers, letters, and
                                dashes (spaces and other special characters are currently not allowed)."
              ></span>
            </label>
            <div class="col-sm-6">
              <input
                id="strain-stock-num"
                name="strain-stock-num"
                class="form-control"
                [disabled]="!strain.vendor"
                [(ngModel)]="strain.stocknum"
                (keyup)="onKeyUp('stocknum')"
                maxlength="255"
              />

              <span *ngIf="strain.stocknum" class="form-control-feedback">
                <span class="glyphicon glyphicon-ok green" *ngIf="isValidStockNumber()"></span>
                <span class="glyphicon glyphicon-warning-sign red" *ngIf="!isValidStockNumber()"></span>
              </span>
              <span class="red margin-top-bottom-2">
                <small *ngIf="!isValidStockNumber()">
                  {{ stockNumberErrorMessage }}
                </small>
              </span>
            </div>
          </div>

          <div class="form-group">
            <label class="col-sm-3 control-label required">Strain Type</label>
            <div class="col-sm-6">
              <strain-type-search
                (typeChange)="onStrainTypeChange($event)"
                [type]="strain.straintype_dict"
              ></strain-type-search>
            </div>
          </div>

          <div class="form-group has-feedback">
            <label for="strain-long-name" class="col-sm-3 control-label">
              Long Name
              <span
                class="glyphicon glyphicon-question-sign"
                matTooltip="Long strain name, up to 255 characters. Has the same character restrictions
                                            as the short strain name. If no long name is entered here, then
                                            the short name will be copied into the long name."
              ></span>
            </label>
            <div class="col-sm-6">
              <input
                id="strain-long-name"
                name="strain-long-name"
                class="form-control"
                [placeholder]="strain.aname ? strain.aname : ''"
                maxlength="255"
                [(ngModel)]="strain.longname"
                (keyup)="onKeyUp('longname')"
              />

              <span *ngIf="strain.longname || strain.aname" class="form-control-feedback">
                <span class="glyphicon glyphicon-ok green" *ngIf="!longnameTaken"></span>
                <span class="glyphicon glyphicon-warning-sign red" *ngIf="longnameTaken"></span>
              </span>
              <span class="red margin-top-bottom-2">
                <small *ngIf="longnameTaken">
                  Strain long name must be unique or an empty string (note: it cannot be just whitespace characters).
                </small>
              </span>
            </div>
          </div>
        </fieldset>

        <!--NOTE: putting this panel outside of the disabled fieldset and manually disabling it
                  so that you can still use the 'view' button-->
        <div class="form-group">
          <label class="col-sm-3 control-label">Panel</label>
          <div class="col-sm-6">
            <panel-search
              [showPanelSym]="true"
              (panelChange)="onPanelChange($event)"
              [disableList]="[{ panelsym: 'inbred' }, { panelsym: 'CC8' }]"
              [disableMessage]="'Leave Panel blank if this Strain is inbred or CC8'"
              [(panel)]="strain.panel"
              [showDialog]="true"
              [disabled]="!canEdit()"
            ></panel-search>
          </div>
        </div>

        <!-- information about projects associated with this strain -->
        <div *ngIf="!newStrain && isCurator">
          <div class="text-center">
            <span class="text10"
              ><b>{{ strain.projcount }}</b> projects are associated with this strain</span
            >
            [
            <a *ngIf="!showProjects && strain.projcount > 0" style="cursor: pointer" (click)="showProjects = true">
              show
            </a>
            <a *ngIf="showProjects" style="cursor: pointer" (click)="showProjects = false">hide</a>]
          </div>
          <div *ngIf="showProjects">
            <br />
            <p>{{ projectDisplay() }}</p>
          </div>
        </div>
        <!-- END information about projects associated with this strain section -->
        <br />
        <!-- save and delete buttons section -->
        <div class="form-group">
          <div [class]="(newStrain && !embed) || !canEdit() ? 'col-sm-4' : 'col-sm-2'"></div>
          <div *ngIf="canEdit()" class="col-sm-4" [matTooltip]="!canSave() ? disableSaveMsg : ''">
            <button class="btn btn-success btn-block" [disabled]="!canSave()" (click)="saveStrainRecord()">
              <strong>Save</strong>
            </button>
          </div>
          <div
            *ngIf="!newStrain && !embed"
            class="col-sm-4"
            [matTooltip]="!canDelete() ? 'Cannot delete a strain if it has associated projects' : ''"
          >
            <button class="btn btn-danger btn-block" [disabled]="!canDelete()" (click)="deleteStrainRecord()">
              <strong>Delete</strong>
            </button>
          </div>
          <div class="col-sm-4" *ngIf="embed">
            <button class="btn btn-default btn-block" (click)="cancel.emit(true)">
              <strong>{{ canEdit() ? 'Cancel' : 'Close' }}</strong>
            </button>
          </div>
        </div>
        <!-- END save and delete buttons section -->
      </div>
    </div>
  </div>
</div>
