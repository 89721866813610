import { Component, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';
import { environment } from '../../../environments/environment';
import { FormControl } from '@angular/forms';
import { StrainTypeService } from '../../services/strain-type.service';

@Component({
  selector: 'strain-type-search',
  template: `
    <!-- using fieldset as a wrapper to make disabling work (it works differently...and not as well...for
    inputs with the formControl attribute, but a wrapping fieldset seems to work fine)-->
    <div [class]="optionSelected() ? 'input-group' : ''">
      <fieldset [disabled]="optionSelected()">
        <span [matTooltip]="inputTooltip()">
          <input
            id="search-strain-type"
            placeholder="Begin typing a strain type name"
            class="form-control left-bord-rad-4px"
            autocomplete="off"
            [matAutocomplete]="auto"
            [formControl]="valueCtrl"
            (focus)="onFocus()"
            (focusout)="inputFocused = false"
            [style.border-color]="inputNoSelection() ? 'red' : ''"
          />
        </span>
        <span class="form-control-feedback">
          <span *ngIf="optionSelected()" style="color:green;right:40px;" class="glyphicon glyphicon-ok"></span>
          <span
            *ngIf="inputNoSelection()"
            style="color:red;right:20px;"
            class="glyphicon glyphicon-warning-sign"
          ></span>
        </span>
      </fieldset>
      <div *ngIf="optionSelected()" class="input-group-btn">
        <button (click)="clearSelection()" type="button" matTooltip="Clear selected strain type" class="btn btn-danger">
          <span class="glyphicon glyphicon-remove clear-icon"></span>
        </button>
      </div>
    </div>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected()" [displayWith]="displayFn">
      <mat-option disabled *ngIf="filteredOptions.length === 0" style="color:red;">
        No matches. Please select a strain type from the options.
      </mat-option>
      <!-- this is a performance optimization, which allows the user to see a few matches/options,
            while telling them that more are available if they narrow down their search.
            This pretty effectively fixes the performance issues I've seen from generating too many options at once.-->
      <mat-option
        *ngFor="let option of filteredOptions.length > 50 ? filteredOptions.slice(0, 7) : filteredOptions"
        [value]="option"
      >
        {{ option.descrip }}
      </mat-option>
      <mat-option disabled *ngIf="filteredOptions.length > 50">
        Type more to narrow down and see more results...
      </mat-option>
    </mat-autocomplete>
  `,
  styleUrls: ['searching.scss'],
})
export class StrainTypeSearchComponent implements OnInit, OnChanges {
  /*
    Two-way binding for the selected strain type.
      This component will emit the selected strain type to the parent when
      a strain type is selected, so having a variable to bind to the parent
      is easier than going off of an event alone.
  */
  @Input() type: any = {};

  // event emitter to send the selected type to the parent
  // when one is selected from the autocomplete options
  @Output() typeChange: EventEmitter<any> = new EventEmitter<any>();

  api: string = environment.securedURLs.sip;

  valueCtrl: FormControl;

  // true if the input is currently focused, false otherwise
  inputFocused = false;

  // filtered autocomplete strain types options
  filteredOptions: any[] = [];

  constructor(public straintypeService: StrainTypeService) {
    this.valueCtrl = new FormControl();
    this.valueCtrl.valueChanges.subscribe((result) => {
      const val = result ? result : '';
      if (typeof val !== 'string') {
        this.filteredOptions = [];
      } else {
        this.filterValues(val);
      }
    });
  }

  ngOnInit() {
    this.straintypeService.types$.subscribe(() => {
      let val = this.valueCtrl.value;
      val = val ? val : '';
      // Note: don't need to change filteredOptions right now if the type isn't a string
      if (typeof val === 'string') {
        this.filterValues(val);
      }
    });
  }

  ngOnChanges() {
    if (this.type) {
      if (this.type.code) {
        this.valueCtrl.setValue(this.type);
      }
    }
  }

  // determines whether an option was selected
  optionSelected(): boolean {
    return this.type && typeof this.type === 'object' ? this.type.code : false;
  }

  // determines whether the current user has input a value without selecting an option
  inputNoSelection(): boolean {
    return !this.optionSelected() && this.valueCtrl.value && !this.inputFocused;
  }

  // determines whether the selected option is not yet approved by a curator
  optionNotApproved(option: any): boolean {
    return option ? (option.user_creator ? option.user_creator.id : false) : false;
  }

  // builds a string describing the flag for an option that is not yet approved by a curator
  optionNotApprovedTooltip(option: any): string {
    return (
      'This strain type was added by the user, ' +
      option.user_creator.name_or_email +
      ', and is pending SIP Curator approval.'
    );
  }

  // builds the tooltip for the input if there's an issue
  inputTooltip(): string {
    if (this.inputNoSelection()) {
      return 'Must select option for this field to be populated (click in field to see options)';
    } else if (this.optionNotApproved(this.type)) {
      return this.optionNotApprovedTooltip(this.type);
    }
    return '';
  }

  /**
   * Filter the available strain types in the dropdown to those for which the
   * user-entered string matches the type description
   *
   * @param {string} newValue: string entered by the user
   */
  filterValues(newValue: string) {
    if (newValue) {
      const newValueSplit = newValue.split(' ');
      let tempOptions = this.straintypeService.types.getValue();
      for (let i = 0; i < newValueSplit.length; i++) {
        tempOptions = tempOptions.filter((option: any) =>
          option ? option.descrip.toLowerCase().indexOf(newValueSplit[i].toLowerCase()) !== -1 : false,
        );
      }

      this.filteredOptions = tempOptions;
    } else {
      this.filteredOptions = this.straintypeService.types.getValue();
    }
  }

  /**
   * display function for the institution object
   *
   * @param {any} option: institution object or undefined
   * @returns {string}: institution name
   */
  displayFn(option?: any): string | undefined {
    if (!option) {
      return undefined;
    }
    if (typeof option === 'object') {
      return option.descrip;
    }
    return option;
  }

  // if an option is selected, emit a objectChange event so that the parent knows who is selected
  onOptionSelected() {
    this.type = this.valueCtrl.value;
    this.typeChange.emit(this.type);
  }

  // clear currently selected object
  clearSelection() {
    this.valueCtrl.setValue('');
    this.filterValues('');
    this.type = {};
    this.typeChange.emit(this.type);
  }

  // upon focusing on a field, if the current value is blank and we have no options, then create them
  onFocus() {
    this.inputFocused = true;
    const value = this.valueCtrl.value;
    if ((!value || value === '') && this.filteredOptions.length === 0) {
      this.filterValues(value ? value : '');
    }
  }
}
