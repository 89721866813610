import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MethodologySearchComponent } from '../../entity-searching/methodology-search.component';
import { MethodologyService } from '../../../services/methodology.service';
import { PersonService } from '../../../services/person.service';
import { flashMessage } from '../../utils';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog.component';

@Component({
  selector: 'edit-methodology',
  templateUrl: 'edit-methodology.component.html',
  styleUrls: ['../editing.scss'],
})
export class EditMethodologyComponent implements OnInit {
  // true to embed this component, false to not
  @Input() embed = false;

  // methodology object we're creating/modifying
  @Input() methodology: any = {};

  // emit the change when a methodology is saved or deleted
  @Output() methodologyChange: EventEmitter<any> = new EventEmitter<any>();

  // event for cancel button click (only displayed on embed)
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();

  newMethodology = true;

  disableSaveMsg =
    'Methodology Term must be available and all required fields must be populated to ' +
    'be able to save this methodology';
  disableDeleteMsg = 'Cannot delete a methodology if it has associated abstracts and measures';

  // TRUE, if current user has curation permissions = True
  isCurator = false;

  @ViewChild(MethodologySearchComponent) methodologySearchComponent!: MethodologySearchComponent;

  showAbstracts = false;
  showMeasures = false;
  termTaken = false;
  termTakenBy = '';

  /**
   * Class constructor.
   */
  constructor(
    private methodologyService: MethodologyService,
    private personService: PersonService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit() {
    if (this.methodology.term) {
      this.methodologyService.getMethodology(this.methodology.term).subscribe((data) => {
        this.methodology = data;
        this.newMethodology = false;
        // don't emit here! this is the initial retreive for when this component is embedded
      });
    }

    this.personService.currentUser$.subscribe(() => {
      this.isCurator = this.personService.isCurator();
    });
  }

  /**
   * Returns 'true' if all conditions to save this record have been met and
   * it is possible to save this record.
   * Checks that 'term' and 'props' are set and that the value for 'term' is available.
   * @return {boolean} - 'true' if OK to save, 'false' if not OK
   */
  canSave(): boolean {
    // check all required inputs have values;
    // 'term' has already been trimmed and validated.
    const properties: string = (this.methodology.props || '').trim();

    return !!(this.methodology.term && properties && (!this.termTaken || !this.newMethodology));
  }

  /**
   * Generates a string containing information about all abstracts associated with this methodology.
   *
   * @return {string} - an array of abstract objects, encoded as a single JSON-formatted string.
   *                             Each object has properties: { project_id, method }
   */
  displayAbstracts(): string {
    const abstracts = [];

    for (const abstr of this.methodology.abstracts) {
      abstracts.push('{project_id: ' + abstr.project_id + ', method: ' + abstr.method + '}');
    }
    return abstracts.join(', ');
  }

  /**
   * Generates a string containing information about all measures associated with this methodology.
   *
   * @return {string} - an array of measure objects, encoded as a single JSON-formatted string.
   * @return Each object has properties: { measure_num }
   */
  displayMeasures(): string {
    const measures = [];

    for (const measure of this.methodology.measures) {
      measures.push('measure_num: ' + measure.measnum + '}');
    }
    return measures.join(', ');
  }

  /**
   * Deletes the specified methodology. User is prompted to confirm the deletion.
   *
   * @return {none}
   */
  deleteMethodologyRecord(): void {
    if (!this.newMethodology) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          header: 'Methodology Deletion Confirmation',
          message:
            'Are you sure that you wish to delete this methodology?' +
            (this.methodology.abstracts_count > 0 || this.methodology.phenomeas_count > 0
              ? '<br>This methodology is associated with one or more abstracts or measures, ' +
                '<br>so deleting it will result in it being removed from those abstracts and measures.'
              : ''),
          falselabel: 'Cancel',
          truelabel: 'Delete',
          truebtn: 'btn-danger',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.methodologyService.deleteMethodology(this.methodology.term).subscribe(() => {
            this.methodologySearchComponent.clearSelection();
            this.methodologyChange.emit(this.methodology);
          });
        }
      });
    }
  }

  /**
   * Returns true when the value passed is not only whitespaces.
   * @param {string} val - an alphanumeric character string (could be all whitespaces)
   * @return {boolean} - true or false
   */
  isNotEmpty(val: string): boolean {
    if (!val) {
      return false;
    }
    return val.trim() !== '';
  }

  /**
   * On selecting and loading another methodology in the autocomplete,
   * update and set values related to this methodology.
   * @return {none}
   */
  onMethodologyChange(): void {
    this.showAbstracts = false;
    this.showMeasures = false;

    if (this.methodology.term) {
      this.methodologyService.getMethodology(this.methodology.term).subscribe((data) => {
        this.methodology = data;
        this.methodologyChange.emit(this.methodology);
        this.newMethodology = false;
      });
    } else {
      this.newMethodology = true;
    }
  }

  /**
   * Figure out if the current user is allowed to edit the methodology
   *
   * @returns {any}: true if the current user can edit the methodology, false if not
   */
  canEdit(): boolean {
    // curators can edit anything
    if (this.isCurator) {
      return true;
    }
    // this is a new methodology, so can edit
    if (this.newMethodology) {
      return true;
    }
    // creator can edit until a curator approves
    return this.personService.isCurrentUser(this.methodology.user_creator.id);
  }

  /**
   * Figure out if the current user is allowed to delete the methodology
   *
   * @returns {any}: true if the current user can delete the methodology, false if not
   */
  canDelete(): boolean {
    // can't delete something that doesn't exist
    if (this.newMethodology) {
      return false;
    }
    // can't delete if can't edit
    if (!this.canEdit()) {
      return false;
    }
    // if there are any associated abstracts, then can't delete
    if (this.methodology.abstracts_count > 1) {
      return false;
    }
    // can only delete if there are also no associated measures
    return this.methodology.measures_count === 0;
  }

  /**
   * Sends request to the service responsible for saving this methodology's
   * information in the database.
   *
   * Validates that 'term' and 'props' argument values are set and valid.
   * @return {none}
   */
  saveMethodologyRecord(): void {
    if (this.canSave()) {
      this.methodologyService.saveMethodology(this.methodology).subscribe(
        (result) => {
          this.methodology = result;
          this.methodologyChange.emit(this.methodology);
          this.newMethodology = false;
          flashMessage(this.snackBar, 'Save successful!', 'alert-success', 2000);
        },
        () => {
          flashMessage(this.snackBar, 'Save failed.', 'alert-danger', 5000);
        },
      );
    }
  }

  /**
   * Checks that the value currently entered in the 'Term' input field is available.
   * Values in the 'Term' input field must be unique and are required.
   * @return {none}
   */
  validateMethodologyTerm(): void {
    this.termTaken = false;
    this.termTakenBy = '';

    const term: string = (this.methodology.term || '').trim();
    // it is possible that when whitespaces are trimmed, 'term'
    // becomes an empty string, in which case 'this.termTaken'
    // is set to TRUE, since empty values are not valid.
    this.termTaken = !term;

    if (term !== '') {
      const cachedMethodologies = this.methodologyService.methodologies.getValue();

      for (let i = 0; i < cachedMethodologies.length; i++) {
        if (term === cachedMethodologies[i].term) {
          if (this.newMethodology) {
            this.termTaken = true;
            this.termTakenBy = cachedMethodologies[i].term;
            break;
          }
        }
      }
    }
  }
}
