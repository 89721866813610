<mat-toolbar>
  <h2 class="text-right">Genotype Intake</h2>
</mat-toolbar>
<br />

<timeline
  #timeline
  [projid]="projectID"
  [invalidID]="isProjectIDInvalid"
  [loading]="isProjectLoading"
  [(projsym)]="project.projsym"
  [(title)]="project.title"
  [project]="project"
  (clickExternalLink)="stateButtons.openExternalUrlAfterSave($event)"
  (clickPageLink)="stateButtons.openPageLinkAfterSave($event)"
  (clickReleaseProject)="stateButtons.releaseProject()"
  (clickReimportProject)="stateButtons.reimportProject()"
  (clickChangeStatus)="stateButtons.changeProjectStatus($event)"
  (clickImportExport)="stateButtons.onClickProjectImportExport(timeline.projectActionsComponent)"
  (projectChanged)="getProject()"
  (projectRestored)="stateButtons.projectRestored()"
  [actionLoadingBar]="stateButtons.loadingBar"
></timeline>

<!-- No project PIs error -->
<h5 *ngIf="!isProjectLoading && !project.corrpi" class="dataset-error y-margin-4">
  In order to upload genotypes, you must first have identified a primary investigator for this project in the
  <span (click)="router.navigate(['/proj-details', projectID])">project details section</span>.
</h5>

<!-- DivDB connection error -->
<div *ngIf="connectionError" class="align-center y-margin-4">
  <h5 class="dataset-error">An issue was encountered while trying to connect with Diversity DB</h5>
  <button mat-button color="primary" (click)="getDataset(datasetID)">Retry</button>
</div>

<div
  *ngIf="project.corrpi && !connectionError"
  id="scroll-top"
  [style.display]="isProjectIDInvalid || isProjectLoading ? 'none' : 'block'"
>
  <div class="col-sm-12 flex-center top-margin-1">
    <div class="col-sm-12 col-lg-10 align-center">
      <h3>Genotype Intake</h3>
      <p style="color: #444">
        A solution to uploading, storing, and linking Diversity Outbred genotype data to your study using DivDB to make
        your genotype data accessible for download and auto-generating genotype probability files. At this time, only
        Diversity Outbred animals are accepted but we hope to add support for other panels soon.
      </p>
      <p style="color: #888; font-size: 13px">
        <i>
          Creating and adding data to a genotype dataset involves storing said data and metadata in systems outside of
          Study Intake. Therefore, please make sure to save your progress by using the provided buttons within the steps
          before navigating away as
          <b>this process is not auto-saved</b>.
        </i>
      </p>
    </div>
  </div>

  <div class="col-sm-12 col-lg-12 bottom-margin-2">
    <!-- ====== Dataset Details ====== -->
    <dataset-details
      *ngIf="!isProjectLoading"
      [dataset]="dataset"
      [project]="project"
      [editable]="userCanEdit"
      (save)="dataset ? updateDataset($event) : createDataset($event)"
      (retry)="processDataset()"
      (delete)="deleteDataset()"
      (cancel)="cancelRunningProcesses()"
    >
    </dataset-details>

    <div *ngIf="creationError" class="col-sm-12 col-lg-10 y-margin-1">
      <p style="color: #c00">{{ creationError }}</p>
    </div>
    <div *ngIf="updateError" class="col-sm-12 col-lg-10 y-margin-1">
      <p style="color: #c00">{{ updateError }}</p>
    </div>
    <div *ngIf="deletionError" class="col-sm-12 col-lg-10 y-margin-1">
      <p style="color: #c00">{{ deletionError }}</p>
    </div>
  </div>

  <mat-vertical-stepper *ngIf="datasetID" class="bottom-margin-1">
    <!-- ====== Upload/Review Genotype Arrays ====== -->
    <mat-step label="Genotype Arrays">
      <!-- All of the arrays that have been uploaded in previous sessions -->
      <ng-container *ngIf="previouslyUploadedArrays?.length">
        <p>Listed below are the genotype arrays that have successfully been uploaded</p>
        <restored-array
          *ngFor="let g of previouslyUploadedArrays; let i = index"
          class="col-sm-12 bottom-margin-1"
          [arrayData]="g"
          [editable]="userCanEdit"
          (remove)="deleteGenotypeArray(i, true)"
        >
        </restored-array>
      </ng-container>

      <!-- Any new arrays or arrays uploaded in the current session -->
      <ng-container *ngIf="arrayUploadForms?.length && userCanEdit">
        <array-upload
          *ngFor="let g of arrayUploadForms; let i = index"
          class="col-sm-12 y-margin-1"
          [genotypeArrayForm]="g"
          (remove)="deleteGenotypeArray(i)"
          (upload)="uploadGenotypeArray($event, i)"
        >
        </array-upload>
      </ng-container>

      <div *ngIf="!arrayUploadForms.length && userCanEdit" class="col-sm-12 top-margin-1">
        <button mat-flat-button color="primary" (click)="addNewGenotypeArray()">Add Another Array</button>
      </div>

      <div class="col-sm-12 step-footer">
        <button mat-button color="primary" matStepperNext>Next: Animal Information</button>
      </div>
    </mat-step>

    <!-- ====== Upload/Review Animal Information ====== -->
    <mat-step label="Animal Information">
      <animal-info-upload
        class="col-sm-12 bottom-margin-1"
        [editable]="userCanEdit"
        (upload)="saveAnimals($event)"
        (removeSaved)="deleteAnimals($event)"
      >
      </animal-info-upload>

      <div class="col-sm-12 step-footer">
        <button mat-button color="accent" matStepperPrevious>Previous: Genotype Arrays</button>
        <button mat-button color="primary" matStepperNext>Next: Inventory Review</button>
      </div>
    </mat-step>

    <!-- ====== Inventory Review and Selections ====== -->
    <mat-step label="Inventory Review">
      <inventory-review
        class="col-sm-12 bottom-margin-1"
        [editable]="userCanEdit"
        (save)="saveInventorySelections($event)"
        (retryGet)="getInventory()"
      >
      </inventory-review>

      <div class="col-sm-12 step-footer bottom-margin-1">
        <button mat-button color="accent" matStepperPrevious>Previous: Animal Information</button>
        <button mat-button color="primary" matStepperNext>Next: Process Dataset</button>
      </div>
    </mat-step>

    <!-- ====== Dataset Submission and Pipeline Options ====== -->
    <mat-step label="Process Dataset">
      <dataset-processing
        *ngIf="inventory && details"
        [editable]="project.canEdit"
        [status]="details.status"
        [numSelections]="inventory.numSavedSelections"
        (process)="processDataset()"
        (runHaplotypeReconstruction)="runHaplotypeReconstruction()"
      >
      </dataset-processing>
      <div class="col-sm-12 step-footer bottom-margin-1">
        <button mat-button color="accent" matStepperPrevious>Previous: Inventory Review</button>
        <button mat-button color="primary" matStepperNext>Next: Link Publications</button>
      </div>
    </mat-step>

    <!-- ====== Linking Publications ====== -->
    <mat-step label="Link Publications">
      <publication-links
        *ngIf="publicationLinks"
        [publicationLinks]="publicationLinks"
        [editable]="project.canEdit && !!animalUpload?.savedAnimals.data.length"
        [animals]="animalUpload?.savedAnimals.data || []"
        (update)="updateLinkedPublications()"
      >
      </publication-links>
      <div class="col-sm-12 step-footer bottom-margin-1">
        <button mat-button color="accent" matStepperPrevious>Previous: Process Dataset</button>
      </div>
    </mat-step>
  </mat-vertical-stepper>
</div>

<div [style.display]="isProjectIDInvalid || isProjectLoading ? 'none' : 'block'">
  <state-buttons
    [(project)]="project"
    [(dbProject)]="project"
    #stateButtons
    [noSave]="true"
    (clickTitleLink)="timeline.titleInput.nativeElement.focus()"
    (saveFail)="timeline.setActive()"
    [currentPage]="'/genotypes/'"
    [nextPage]="'/project/qc-dashboard/'"
  ></state-buttons>
</div>
