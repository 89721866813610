import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class OntologyService {
  // api url
  private api: string = environment.securedURLs.sip;

  // ontologies behavioursubject for building an observable
  public ontologies = new BehaviorSubject<any[]>([]);
  // observable, which can be subscribed to in order to get changes to the ontology list any time it is updated
  public ontologies$ = this.ontologies.asObservable();
  // flag as to whether the ontologies list has been initialized yet
  private ontologiesInitialized = false;

  // declare http client
  constructor(private http: HttpClient) {
    if (!this.ontologiesInitialized) {
      this.ontologiesInitialized = true;
      this.getOntology().subscribe();
    }
  }

  /**
   * Get either a specific ontology by id, do a search for a list, or a list of all of them
   *
   * @param {number} id (optional): specific ontology to retrieve
   * @param {number} searchStr (optional): search for a list of ontologies matching this searchStr
   */
  getOntology(id: string | null = null, searchStr: string | null = null): Observable<any> {
    let url = this.api + 'ontologies';
    if (id) {
      url += '?id=' + id;
    } else if (searchStr) {
      url += '?search_str=' + searchStr;
    }
    return this.http.get<any>(url).pipe(
      tap((result) => {
        if (!id && !searchStr) {
          this.ontologiesInitialized = true;
          // cache the list and trigger the ontologies$ observable so that all subscribers get the updated list
          this.ontologies.next(result);
        }
      }),
    );
  }

  /**
   * Function to run any of the actions in the ontology loading options, depending on arguments
   *
   * Hits the ontload POST and returns an observable
   *
   * @param {string} action: ontology loading action to execute...
   *                         "load" to load ontologies,
   *                         "measlink" to attempt to re-establish measure links (also checks which links
   *                                    are orphaned and adds the list to the log when it fails),
   *                         "count" to re-count # of measures associated with ontologies,
   *                         "check_for_updates" to check for updates from bioontology
   * @param {string} ontType: (required if action === "load" and loading from bioontology) "MA", "MP", or "VT"
   * @param {string} fileId: (either this or ont_type are required if action === "load") file id to
   *                         load the ontologies from
   * @param {number} submissionId: (optional if action === "load" and loading from bioontology (ont_type is set))
   *                               submission id to load from bioontology... if left blank, we will just get
   *                               the latest for the ont_type
   */
  ontLoadPost(
    action: string,
    ontType: string = '',
    fileId: string = '',
    submissionId: number | null = null,
  ): Observable<any> {
    const url = this.api + 'ontload';
    return this.http.post<any>(
      url,
      {
        action: action || null,
        ont_type: ontType || null,
        file_id: fileId || null,
        submission_id: submissionId,
      },
      { headers: new HttpHeaders().set('Content-Type', 'application/json') },
    );
  }

  /**
   * Get the ontology loading versions and logs
   */
  ontLoadGet(): Observable<any> {
    return this.http.get<any>(this.api + 'ontload');
  }
}
