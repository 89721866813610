// Angular imports
import { NgModule } from '@angular/core';

// Pipes
import { MUGACasePipe } from './muga-case.pipe';
import { SafeHTMLPipe } from './safe-html.pipe';
import { SafeResourceUrlPipe } from './safe-resource-url.pipe';
import { SafeUrlPipe } from './safe-url.pipe';

@NgModule({
  declarations: [MUGACasePipe, SafeHTMLPipe, SafeResourceUrlPipe, SafeUrlPipe],
  exports: [MUGACasePipe, SafeHTMLPipe, SafeResourceUrlPipe, SafeUrlPipe],
})
export class PipesModule {}
