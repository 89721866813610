import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// services
import { ProjectService } from '../../../services/project.service';

@Component({
  selector: 'qc-import-messages',
  templateUrl: './qc-general.component.html',
  styleUrls: ['../data-validation.scss'],
})
export class QCGeneralMessagesComponent implements OnInit {
  projectId: string | null = '';
  // 'false' when 'project id' is either invalid or when user does not have permission to access the project
  isIdInvalid = false;

  // project object that is displayed in the page (and edited when user makes modifications)
  project: any = { projdoc: {} };
  // project object that represents the state of the project as of the last import (user to compare with
  // the project object when saving/exporting in order to determine what has changed)
  dbProject: any = { projdoc: {} };

  importMsgs: any[] = [];

  myColumns = [
    { key: 'order', label: 'ID', width: '1', inline_label: 'ID:', type: 'html' },
    { key: 'category', label: 'Category', width: '5', inline_label: 'Category', type: 'html' },
    { key: 'message', label: 'Message', width: '13', inline_label: 'Message:', type: 'html' },
  ];

  constructor(private route: ActivatedRoute, private projectService: ProjectService, public router: Router) {}

  ngOnInit() {
    this.projectId = this.route.snapshot.paramMap.get('id') || null;

    if (Number(this.projectId)) {
      this.pullProjectData();
    } else {
      this.isIdInvalid = true;
    }
  }

  /**
   * subscribes to an observable service, which returns project information;
   * populates the 'import_msgs' array with data that will populate a table.
   */
  pullProjectData(): void {
    if (this.projectId) {
      this.projectService.getProject(this.projectId).subscribe(
        (data) => {
          this.isIdInvalid = data.table_form;

          if (!this.isIdInvalid) {
            this.project = data;

            this.dbProject = JSON.parse(JSON.stringify(data));

            if (data.import_msgs) {
              this.importMsgs = data.import_msgs;
            }
          }
        },
        () => {
          this.isIdInvalid = true;
        },
      );
    }
  }
}
