import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class ProcedureService {
  // api url
  private api: string = environment.securedURLs.sip;

  // set a couple of values for querying the protocols.io api
  private protocolsIOPublicToken: string = environment.protocolsIOPublicToken;
  private protocolsIOApi = environment.unsecuredURLs.protocolsIO;

  // procedure behavioursubject for building an observable
  public procedures = new BehaviorSubject<any[]>([]);
  // observable, which can be subscribed to in order to get changes to the procedure list any time it is updated
  public procedures$ = this.procedures.asObservable();
  // flag as to whether the procedures list has been initialized yet
  private proceduresListInitialized = false;

  // declare http client
  constructor(private http: HttpClient) {
    if (!this.proceduresListInitialized) {
      this.proceduresListInitialized = true;
      this.getProcedure().subscribe();
    }
  }

  /**
   * Query the protocols.io api listing endpoint for a list of public protocols based on passed-in values
   *
   * NOTE: this endpoint doesn't return anything unless there's at least 1 character in the key
   * NOTE: page_size is also a possible argument, which defaults to 10. I figured that was fine, so I didn't change it
   *       or include it as an argument, but we can add it if we think we need to.
   *
   * @param {string} key: filtering value, compares against several protocols.io values
   * @param {string} orderField: ordering criteria, 'relevance', 'activity', 'id', 'date', or 'name'
   * @param {string} orderDir: ordering direction, 'asc' or 'desc'
   * @param {string} pageID: id of the page to get (not currently used)
   * @returns {Observable<any>}: observable for the protocols.io listing get request
   */
  getProtocolsIOList(
    key: string,
    orderField: string = '',
    orderDir: string = '',
    pageID: string = '',
  ): Observable<ProtocolsIOList> {
    let url = this.protocolsIOApi + 'protocols?filter=public&key=' + key;
    if (orderField) {
      url += '&orderField=' + orderField;
    }
    if (orderDir) {
      url += '&orderDir=' + orderDir;
    }
    if (pageID) {
      url += '&pageID=' + pageID;
    }
    return this.http.get<ProtocolsIOList>(url, { headers: { Authorization: 'Bearer ' + this.protocolsIOPublicToken } });
  }

  /**
   * Really just a get query by url (to the protocols.io api), but it's used with the nextPage value that we
   * received from our previous query to protocols.io from their listing endpoint.
   *
   * @param {string} url: url to query
   * @returns {Observable<any>}: observable for the get request
   */
  getProtocolsIONextPage(url: string): Observable<ProtocolsIOList> {
    return this.http.get<ProtocolsIOList>(url, { headers: { Authorization: 'Bearer ' + this.protocolsIOPublicToken } });
  }

  /**
   * Gets a protocols.io protocol object for the passed-in id
   *
   * @param {string} id: protocols.io protocol id
   * @returns {Observable<any>}: protocols.io procotol object
   */
  getProtocolsIOByID(id: string): Observable<any> {
    const url = this.protocolsIOApi + 'protocols/' + id;
    return this.http.get(url, { headers: { Authorization: 'Bearer ' + this.protocolsIOPublicToken } });
  }

  /**
   * Get either a specific procedure or a list of all of them
   *
   * @param {number} procID: procID for specific procedure to retrieve
   *                          or null to get a list of all procedure templates
   * @returns {Observable<any>} Observable object or array of objects
   */
  getProcedure(procID: string = ''): Observable<any> {
    let url = this.api + 'procedures';
    if (procID) {
      url += '?proc_id=' + procID;
    }
    return this.http.get<any>(url).pipe(
      tap((result) => {
        if (!procID) {
          this.proceduresListInitialized = true;
          // cache the list and trigger the procedures$ observable so that all subscribers get the updated list
          this.procedures.next(result);
        }
      }),
    );
  }

  /**
   * Observable for saving a procedure template
   *
   * @param {any} procedure: any containing procedure template data to save
   * @returns {Observable<any>}: Result is the updated procedure template object on success
   */
  saveProcedure(procedure: any): Observable<any> {
    const url = this.api + 'procedures';
    return this.http
      .put<any>(url, procedure ? procedure : {}, { headers: new HttpHeaders().set('Content-Type', 'application/json') })
      .pipe(
        tap(() => {
          // update the cached procedures in case someone else is working on procedures
          this.getProcedure().subscribe();
        }),
      );
  }

  /**
   * Observable for copying from one procedure to another
   *
   * @param {number} sourceProcID: procedure id from which we are copying
   * @param {number} destProcID (optional): procedure id to which we are copying,
   *                                          required if there's no templateName
   * @param {string} templateName (optional): templateName for new template, required if there's no destProcID
   * @param {boolean} privateTemp (optional): true to make the new template private (if it's new)
   * @returns {Observable<any>}: Result is the destination procedure object on success
   */
  copyProcedure(
    sourceProcID: number,
    destProcID: number = 0,
    templateName: string = '',
    privateTemp: boolean = false,
  ): Observable<any> {
    const url = this.api + 'procedures';
    return this.http
      .post<any>(
        url,
        { source_proc_id: sourceProcID, dest_proc_id: destProcID, template_name: templateName, private: privateTemp },
        { headers: new HttpHeaders().set('Content-Type', 'application/json') },
      )
      .pipe(
        tap(() => {
          // update the cached procedures in case someone else is working on procedures
          this.getProcedure().subscribe();
        }),
      );
  }

  /**
   * Observable for deleting a procedure template
   *
   * @param {number} procID: procID of the procedure template to delete
   * @returns {Observable<any>}: Result is a 204 code on success
   */
  deleteProcedure(procID: string): Observable<any> {
    const url = this.api + 'procedures?proc_id=' + procID;
    return this.http.delete<any>(url).pipe(
      tap(() => {
        // make extra sure the deleted procedure gets removed from the cached list
        // note: leaving this manual removal in here because there is a race condition on updating the search list
        // (which isn't an issue for the save)
        const proceduresList = this.procedures.getValue();
        for (let i = 0; i < proceduresList.length; i++) {
          if (procID === proceduresList[i].proc_id) {
            proceduresList.splice(i, 1);
            this.procedures.next(proceduresList);
            break;
          }
        }
        // also update the cached procedures in case someone else is working on procedures
        this.getProcedure().subscribe();
      }),
    );
  }
}

export interface ProtocolsIOList {
  items: any[];
  pagination: any;
}
