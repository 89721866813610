import { throwError as observableThrowError, Observable, BehaviorSubject, combineLatest } from 'rxjs';

import { catchError, tap } from 'rxjs/operators';
import { Component, Output, EventEmitter, Input, OnDestroy, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ProjectService } from '../../services/project.service';
import { objIsEmpty, buildObjectDiff, removeAllKeysExcept } from '../utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { FlashMessageComponent } from '../flash-message.component';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog.component';
import { DivDBService } from '../../services/divdb.service';
import { ProjectActionsComponent } from '../project-actions/project-actions.component';

@Component({
  selector: '<state-buttons>',
  templateUrl: './state-buttons.component.html',
})
export class StateButtonsComponent implements OnDestroy {
  // take both the state of the project at import time and the current state of the project,
  // so that on export we can compare and only send attributes that changed (along with id)
  @Input() project: any = {};
  @Input() dbProject: any = {};

  // current page with trailing slash (ex: '/proj-details/') (the projid is appended to the end if we go to it)
  // (currently only used by proj-details if we saved a a new project)
  @Input() currentPage = '';
  // next page with trailing slash (ex: '/animal-info/') (the projid is appended to the end if we go to it)
  @Input() nextPage = '';
  // true to not allow saving on this page
  // (temporary measure to not allow saving on pages that aren't integrated with the db yet)
  @Input() noSave = false;

  // when a save is initiated...if we want to do other things before continuing with the save, then
  // having this value set to true will cause us to just send out the parentSave event and not do anything else.
  // it will be up to the parent component to do what it needs to do, and when it wants to run the save logic here,
  // then it can simply pass in the 'parent_handling' argument = true.
  @Input() parentHandleSave = false;

  // events used to emit changes to the project after save
  @Output() projectChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() dbProjectChange: EventEmitter<any> = new EventEmitter<any>();

  // event that occurs when a save is initiated if the parentHandleSave input is set to true, so that the
  // parent knows when to handle saving
  @Output() parentSave: EventEmitter<any> = new EventEmitter<any>();

  // Event that is emitted when the link to bring focus to the Project Title is clicked.
  // Since the title is now in a separate child component, the function to bring focus now
  // no longer works here, so the parent needs to call it
  @Output() clickTitleLink: EventEmitter<any> = new EventEmitter<any>();

  // event emitted when a save fails due to an error, lets the timeline component know to not change the
  // selected page in the stepper (if this is an autosave when trying to change pages)
  @Output() saveFail: EventEmitter<any> = new EventEmitter<any>();

  // flag to show the error message telling the user that they can't save with no title
  noTitleMessage = false;

  // if saving returns an error, then display the error for the user
  errorMessage = '';

  // flag to not attempt to autosave in ngOnDestroy (if user navigates away within the app)
  // Set if we just completed a save attempt before navigating away or are discarding changes with the Exit button.
  noAutosave = false;

  // set while a save is in progress (makes it so that another save won't begin while this one is in progress...also
  // can be referenced by parent components)
  currentlySaving = false;

  // passed-in to a variable in the timeline (project editing header) component that shows a loading bar
  // true to show the loading progress bar below actions and set to a string to display as text above the loading
  // progress bar, null to not show the loading progress bar... used for project release, re-release, and re-import
  loadingBar: any = null;

  // declare http client and services
  constructor(
    private projectService: ProjectService,
    public router: Router,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private divdb: DivDBService,
  ) {}

  // triggered when you exit the app entirely, either by going to an external website
  // (which includes MPD public), or just close the browser window
  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHander() {
    // we aren't checking noAutosave because it really should only be set in cases where we are
    // navigating away inside the saveProject function, or with the Exit button to discard changes,
    // both cases which would hit ngOnDestroy and not this function
    this.saveProject(false, false, true).subscribe();
  }

  // this function is called when you go to a different page within the angular app
  ngOnDestroy() {
    if (!this.noAutosave) {
      // autosave logic
      this.saveProject(false, false, true).subscribe();
    }
  }

  /**
   * Return an observable to save the project and redirect if needed
   * (the observable only really does anything if it's determined that a save is really required, otherwise
   *  we will just return a blank observable. The purpose of returning observable is to allow binding of logic
   *  on completion of the save).
   *
   * @param {boolean} next: true to go to the next page, false to not
   * @param {boolean} exit: true to exit to the projects listing page, false to not
   * @param {boolean} autosave: true if this is an autosave (so quietly ignore a cleared title and don't flash a
   *                            success message if nothing was changed), false if it is not
   * @param {boolean} parentHandling: true if this function call is from the parent's saveProject handling, so we
   *                                   should bypass the condition that sends this save attempt to the parent
   * @param {boolean} logDescrip: a description to include with the log entry for the project update, usually the page
   * @returns {Observable<any>}: Result is the updated project object on success
   *                                (not all changes sent may have been allowed)
   */
  saveProject(
    next: boolean = false,
    exit: boolean = false,
    autosave: boolean = false,
    parentHandling: boolean = false,
    logDescrip: string = '',
  ): Observable<any> {
    // if the parent is handling the save, then let them know that one was initiated
    if (this.parentHandleSave && !parentHandling) {
      this.parentSave.emit([next, exit, autosave]);
    } else if (this.project.canEdit && !this.noSave && !this.currentlySaving) {
      this.currentlySaving = true;
      // if we're autosaving OnDestroy of OnBeforeUnload and the title is blank, then just quietly
      // replace the title with the dbProject title. If dbProject title isn't set, then this is a new project so it
      // just won't be saved.
      if (autosave && !this.project.title && this.dbProject.title) {
        this.project.title = this.dbProject.title;
      }
      this.noTitleMessage = false;
      this.errorMessage = '';
      const newProject = !this.project.projid;
      let projectOutput = this.buildOutputProject();
      if (!(this.project.title ? this.project.title.trim() : false)) {
        this.noTitleMessage = true;
        window.scrollTo(0, document.body.scrollHeight);
        this.currentlySaving = false;
      } else if (!projectOutput.empty || newProject) {
        projectOutput = projectOutput.output;
        if (logDescrip) {
          projectOutput.log_descrip = logDescrip;
        } else if (this.currentPage) {
          switch (this.currentPage) {
            case '/proj-details/':
              projectOutput.log_descrip = 'Project Details Page';
              break;
            case '/animal-info/':
              projectOutput.log_descrip = 'Animal Info Page';
              break;
            case '/curator-only/':
              projectOutput.log_descrip = 'Curator Only Page';
              break;
            case '/procedures/':
              projectOutput.log_descrip = 'Procedures Page';
              break;
            case '/definitions/':
              projectOutput.log_descrip = 'Data Definitions Page';
              break;
            default:
              break;
          }
        }
        // leave a slight delay before saving project in case new pi(s) were added without being individually saved
        // (still needed after changes in case changes were made to a pi's name, which resulted in needed changes to
        // their citname, and therefore the pistring)
        return this.projectService.saveProject(projectOutput).pipe(
          tap((data) => {
            if (next) {
              // go to next page after saving
              this.noAutosave = true;
              this.router.navigate([this.nextPage + String(data.projid)]);
            } else if (exit) {
              // exit after saving
              this.noAutosave = true;
              this.router.navigateByUrl('/projects?userid=current');
            } else if (newProject) {
              // if this is a new project, then now that it has an id the route for the project id
              this.noAutosave = true;
              // if this was an autosave, then don't assume they want to stay on the page
              if (!autosave) {
                this.router.navigate([this.currentPage + String(data.projid)]);
              }
            } else {
              // saving without navigating away (here we don't set noAutosave)
              this.project = data;
              this.dbProject = JSON.parse(JSON.stringify(data));
              this.projectChange.emit(this.project);
              this.dbProjectChange.emit(this.dbProject);
            }
            this.currentlySaving = false;
            const messages = data.warning_messages ? data.warning_messages : [];
            const analyzeNum = data.analyze_measnums ? data.analyze_measnums.length : null;
            let analyzeCompletedNum = 0;
            const analyzeMessageIndex = messages.length;
            if (analyzeNum) {
              messages.push(`Re-analyzing data due to series type change: (${analyzeCompletedNum}/${analyzeNum})...`);
            }
            this.flashMessage('Save successful!', 'alert-success', messages.length > 0 ? 60000 : 2000, messages);
            // re-run analysis on measures that were flagged for it 1 at a time and use the flashMessage
            // to keep the user updated on progress
            for (const measnum of data.analyze_measnums) {
              this.projectService.analyzeMeasure(measnum).subscribe(
                () => {
                  analyzeCompletedNum += 1;
                  if (analyzeCompletedNum === analyzeNum) {
                    messages[analyzeMessageIndex] = 'Re-analyzing data due to series type change: Done';
                  } else {
                    messages[analyzeMessageIndex] =
                      'Re-analyzing data due to series type change: (' +
                      analyzeCompletedNum +
                      '/' +
                      analyzeNum +
                      ')...';
                  }
                  this.flashMessage('Save successful!', 'alert-success', 30000, messages);
                },
                (error) => {
                  messages.push(error.error.message ? error.error.message : error.message);
                  analyzeCompletedNum += 1;
                  if (analyzeCompletedNum === analyzeNum) {
                    messages[analyzeMessageIndex] = 'Re-analyzing data due to series type change: Done';
                  } else {
                    messages[analyzeMessageIndex] =
                      'Re-analyzing data due to series type change: (' +
                      analyzeCompletedNum +
                      '/' +
                      analyzeNum +
                      ')...';
                  }
                  this.flashMessage('Save successful!', 'alert-success', 30000, messages);
                },
              );
            }
          }),
          catchError((err) => {
            this.saveFail.emit(true);
            if (err.error ? err.error.message : false) {
              this.errorMessage = 'Error occurred while saving project: ' + err.error.message;
            } else {
              this.errorMessage = 'Error occurred while saving project: Unknown error.';
            }
            window.scrollTo(0, document.body.scrollHeight);
            this.currentlySaving = false;
            this.flashMessage('Save failed.', 'alert-danger', 5000);
            // pass the error onto observers
            return observableThrowError(err);
          }),
        );
      } else if (next && this.project.projid) {
        // no changes, go to next page
        this.noAutosave = true;
        this.currentlySaving = false;
        this.flashMessage('Save successful!', 'alert-success');
        this.router.navigate([this.nextPage + String(this.project.projid)]);
      } else if (exit) {
        // no changes, exit
        this.noAutosave = true;
        this.currentlySaving = false;
        this.flashMessage('Save successful!', 'alert-success');
        this.router.navigateByUrl('/projects?userid=current');
      } else {
        this.currentlySaving = false;
        if (!autosave) {
          this.flashMessage('Save successful!', 'alert-success');
        }
      }
    }
    return new BehaviorSubject<any>(this.project).asObservable();
  }

  /**
   * Event-triggered function from the timeline, which sends a status and this function changes the status and saves
   *
   * @param status: status we are changing the project to
   */
  changeProjectStatus(status: string) {
    this.project.status = status;
    this.saveProject().subscribe();
  }

  /**
   * Use the "snackbar" to flash the passed-in message for the passed-in duration in
   * this passed-in css class (bootstrap panel).
   *
   * @param {string} message: message to show
   * @param {string} divClass: panel class to display the message in
   * @param {number} duration: duration to show the message
   * @param {string[]} warnings: list of warning strings to show
   */
  flashMessage(message: string, divClass: string = '', duration: number = 2000, warnings: string[] = []) {
    this.snackBar.openFromComponent(FlashMessageComponent, {
      duration: duration,
      data: {
        text: message,
        class: divClass,
        snackbar: this.snackBar,
        listcolor: '#CCCC00',
        listmessages: warnings,
      },
    });
  }

  /**
   * Open the link only after the save completes and is successful
   *
   * @param {string} url: external url we're opening
   */
  openExternalUrlAfterSave(url: string) {
    this.saveProject(false, false, true).subscribe(() => {
      this.noAutosave = true;
      window.open(url, '_blank');
    });
  }

  /**
   * Open the link only after the save completes and is successful
   *
   * @param {string} pageUrl: page url (WITHOUT projid)
   */
  openPageLinkAfterSave(pageUrl: string) {
    this.saveProject(false, false, true).subscribe((data) => {
      this.noAutosave = true;
      this.router.navigate([pageUrl + String(data.projid)]);
    });
  }

  /**
   * Called when the restore project button is pressed and successful... just update the local values rather than
   * doing a full refresh...
   */
  projectRestored() {
    this.project.deletedtime = null;
    this.dbProject.deletedtime = null;
    this.project.canEdit = true;
    this.dbProject.canEdit = true;
  }

  /**
   * When the import & export button is clicked, save current changes before opening... if save fails, let user
   * fix issues first...
   *
   * @param projectActionsComponent: project actions component
   */
  onClickProjectImportExport(projectActionsComponent: ProjectActionsComponent) {
    this.saveProject(false, false, true).subscribe(() => {
      projectActionsComponent.openProjectImportExport();
    });
  }

  /**
   * Build the dict that gets sent to the api by figuring out which values relevant to the project have changed
   * @returns {any} {empty: true if there were no changes, so the output is empty,
   *                    output: project object output for saving}
   */
  buildOutputProject(): any {
    const projectOutput = buildObjectDiff(
      this.project,
      this.dbProject,
      { projdoc: {}, dataformat: {} },
      {
        workflow: {},
        datadefs: {},
        abstracts: {},
        expgroups: {},
        procedures: { ordered_list_keys: { steps: {}, equipment: {}, reagents: {}, environments: {} } },
        pheno_measures: { ordered_list_keys: { pheno_series: {} } },
      },
      [
        'canEdit',
        'isOwner',
        'canDelete',
        'current_user_permission',
        'isOnlyOwner',
        'primarypublicationsinfo',
        'referencepublicationsinfo',
        'largecollab_rel',
        'panel',
        'warning_messages',
        'analyze_measnums',
      ],
    );
    if (!projectOutput.empty) {
      // some custom stuff specific to projects
      if (Object.prototype.hasOwnProperty.call(projectOutput.output, 'otherpis')) {
        // for otherpis, we only really care that the array length is the same and the
        // person ids are in the same order for the purposes of detecting a change
        let include = this.project.otherpis.length !== this.dbProject.otherpis.length;
        if (!include) {
          for (let i = 0; i < this.project.otherpis.length; i++) {
            if (this.project.otherpis[i].id !== this.dbProject.otherpis[i].id) {
              include = true;
              break;
            }
          }
        }
        if (!include) {
          delete projectOutput.output.otherpis;
          // in case otherpis was the only change, check if the object is now empty
          projectOutput.empty = objIsEmpty(projectOutput.output);
        } else {
          // removing all keys that aren't id, firstname, lastname, and middlename... all that the
          // api logic cares about is the id... but the others are nice for the view history
          for (let i = 0; i < projectOutput.output.otherpis.length; i++) {
            projectOutput.output.otherpis[i] = removeAllKeysExcept(projectOutput.output.otherpis[i], [
              'id',
              'firstname',
              'lastname',
              'middlename',
            ]);
          }
        }
      }
      // for corresponding pi, we only care if they are the same person, and their name is the same
      if (Object.prototype.hasOwnProperty.call(projectOutput.output, 'correspondingpi')) {
        const include =
          this.project.correspondingpi.id !== this.dbProject.correspondingpi.id ||
          this.project.correspondingpi.firstname !== this.dbProject.correspondingpi.firstname ||
          this.project.correspondingpi.lastname !== this.dbProject.correspondingpi.lastname ||
          this.project.correspondingpi.middlename !== this.dbProject.correspondingpi.middlename;
        if (!include) {
          delete projectOutput.output.correspondingpi;
          // in case correspondingpi was the only change, check if the object is now empty
          projectOutput.empty = objIsEmpty(projectOutput.output);
        } else {
          // removing all keys that aren't id, firstname, lastname, and middlename... all that the
          // api logic cares about is the id (which is already in corrpi)...
          // but the others are nice for the view history
          projectOutput.output.correspondingpi = removeAllKeysExcept(projectOutput.output.correspondingpi, [
            'id',
            'firstname',
            'lastname',
            'middlename',
          ]);
        }
      }
      // make sure proc_id is set for procedures diffs
      if (Object.prototype.hasOwnProperty.call(projectOutput.output, 'procedures')) {
        for (let i = 0; i < projectOutput.output.procedures.length; i++) {
          projectOutput.output.procedures[i].proc_id = this.project.procedures[i].proc_id;
        }
      }
      // make sure measnum is set for pheno_measures and pheno_series diffs
      if (Object.prototype.hasOwnProperty.call(projectOutput.output, 'pheno_measures')) {
        for (let i = 0; i < this.project.pheno_measures.length; i++) {
          if (projectOutput.output.pheno_measures.length === i) {
            projectOutput.output.pheno_measures.push({});
          }
          projectOutput.output.pheno_measures[i].measnum = this.project.pheno_measures[i].measnum;
          if (Object.prototype.hasOwnProperty.call(this.project.pheno_measures[i], 'pheno_series')) {
            if (!Object.prototype.hasOwnProperty.call(projectOutput.output.pheno_measures[i], 'pheno_series')) {
              projectOutput.output.pheno_measures[i].pheno_series = [];
            }
            if (Array.isArray(this.project.pheno_measures[i].pheno_series)) {
              for (let j = 0; j < this.project.pheno_measures[i].pheno_series.length; j++) {
                if (projectOutput.output.pheno_measures[i].pheno_series.length === j) {
                  projectOutput.output.pheno_measures[i].pheno_series.push({});
                }
                projectOutput.output.pheno_measures[i].pheno_series[j].measnum =
                  this.project.pheno_measures[i].pheno_series[j].measnum;
              }
            }
          }
        }
      }
      // only include term for treatments diffs (it's all that is needed and reduces bloat in view history)
      if (Object.prototype.hasOwnProperty.call(projectOutput.output, 'treatments')) {
        for (let i = 0; i < projectOutput.output.treatments.length; i++) {
          projectOutput.output.treatments[i] = { term: projectOutput.output.treatments[i].term };
        }
      }
      // only include id for strain mappings (it's all that is needed and reduces bloat in view history)
      if (Object.prototype.hasOwnProperty.call(projectOutput.output, 'strainmaps')) {
        for (let i = 0; i < projectOutput.output.strainmaps.length; i++) {
          let id = projectOutput.output.strainmaps[i].strain.id;
          // making sure they're null instead of undefined
          id = id ? id : null;
          projectOutput.output.strainmaps[i].strain = { id: id };
        }
      }
      // make sure projid is set for the project diff
      if (this.project.projid) {
        projectOutput.output.projid = this.project.projid;
      }
    }
    return projectOutput;
  }

  // logic for running an endpoint which releases the project
  releaseProject() {
    if (!this.loadingBar && this.project.projid) {
      this.loadingBar = 'Releasing Project';
      this.saveProject().subscribe(
        () => {
          this.projectService.releaseProject(this.project.projid).subscribe(
            (result) => {
              // update release info
              this.project.status = this.dbProject.status = result.status;
              this.project.availstat = this.dbProject.availstat = result.availstat;
              this.project.releasedtime = this.dbProject.releasedtime = result.releasedtime;
              this.project.changesincereleased = this.dbProject.changesincereleased = result.changesincereleased;
              this.project.projlogs = result.projlogs;
              this.dbProject.projlogs = JSON.parse(JSON.stringify(result.projlogs));
              this.projectChange.emit(this.project);
              this.dbProjectChange.emit(this.dbProject);

              // mark any datasets as public
              if (this.project.genotypes?.length) {
                combineLatest(
                  this.project.genotypes.map((g: any) => this.divdb.releaseDataset(g.genotype_dataset_id)),
                ).subscribe();
              }

              this.flashMessage('Project release successful!', 'alert-success');
              this.loadingBar = null;
            },
            (err) => {
              if (err.error ? err.error.message : false) {
                this.errorMessage = 'Error occurred while releasing project: ' + err.error.message;
              } else {
                this.errorMessage = 'Error occurred while releasing project: Unknown error.';
              }
              window.scrollTo(0, document.body.scrollHeight);
              this.flashMessage('Project release failed.', 'alert-danger', 5000);
              this.loadingBar = null;
            },
          );
        },
        () => {
          this.errorMessage += '<br>Unable to release project because save failed.';
          this.loadingBar = null;
        },
      );
    }
  }

  /**
   * Initiates external source project re-import
   */
  reimportProject() {
    if (this.project.projid && this.project.external_id && !this.loadingBar) {
      this.loadingBar = 'Re-Importing Project from ID # ' + this.project.external_id + ' (This may take a 2-3 minutes)';
      // TODO [06/29/2020] this is hard-coded; it needs some DB work before
      // the hard coding can be replaced.
      const group = 'CBA'; // CBA PFS group

      this.projectService.importRequest(group, this.project.external_id).subscribe(
        (data) => {
          this.loadingBar = null;
          if (!data.id) {
            this.flashMessage('Project re-import failed.', 'alert-danger', 30000, [data.message]);
          } else {
            this.flashMessage('Project re-import successful!', 'alert-success');
            if (data.datadefs) {
              this.project.datadefs = this.dbProject.datadefs = data.datadefs;
            }
            const onDefinitionsPage = this.router.url.indexOf('/definitions/' + this.project.projid) !== -1;
            if (onDefinitionsPage) {
              // route away and back to make sure that the new data file is loaded
              this.router
                .navigateByUrl('/', { skipLocationChange: true })
                .then(() => this.router.navigate(['/definitions', this.project.projid]));
            }
            // show a message letting the user know what happened...
            const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
              data: {
                header: 'Imported New Data File',
                message:
                  'A new data file has been imported from ID # ' +
                  this.project.external_id +
                  ' in project ' +
                  this.project.projsym +
                  '.<br>In order to plot this new data in QC plots and MPD ' +
                  'plots, this new data file will need to be processed after making sure that the data definitions, ' +
                  'strain mappings, and sex mappings are correct.<br><br>' +
                  data.message,
                hidefalsebtn: onDefinitionsPage,
                falselabel: 'Close',
                truelabel: onDefinitionsPage ? 'Close' : 'Go to Data',
                truebtn: onDefinitionsPage ? 'btn-default' : 'btn-primary',
              },
            });
            dialogRef.afterClosed().subscribe((result) => {
              // once the import is completed, redirect to the data definition tab if the user selected to
              if (!onDefinitionsPage && result) {
                this.router.navigate(['/definitions', this.project.projid]);
              }
            });
          }
        },
        (e) => {
          console.log(e);
          this.flashMessage('Project re-import failed.', 'alert-danger', 10000, ['Unknown Error']);
          this.loadingBar = null;
        },
      );
    }
  }
}
