import { Component, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Project, ProjectFilter, ProjectService } from '../../services/project.service';
import { buildListTooltip, buildDateTimeHtml, statusColors, escapeHtml } from '../../shared/utils';
import { Location } from '@angular/common';

import { Person, PersonService } from '../../services/person.service';
import { PersonSearchComponent } from '../../shared/entity-searching/person-search.component';
import { PanelSearchComponent } from '../../shared/entity-searching/panel-search.component';
import { InterventionSearchComponent } from '../../shared/entity-searching/intervention-search.component';
import { GroupSearchComponent } from '../../shared/entity-searching/group-search.component';
import { PublicationSearchComponent } from '../../shared/entity-searching/publication-search.component';
import { CenterSearchComponent } from '../../shared/entity-searching/center-search.component';

@Component({
  selector: 'projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./project.component.scss'],
})
export class ProjectsComponent implements OnInit, OnChanges {
  // true if this component is being embedded into a different component,
  // rather than being the stand-alone route for this component: '/projects'
  @Input() embed = false;
  // filtering string (containing get arguments) input to the project get api endpoint
  @Input() filterString = '';
  // true if we want to show the series label in the table and sort by the series order
  @Input() showingSeries = false;

  // filters that generate the project list
  filters: ProjectFilter[] = [];

  // keeps track of the last filtering string for which we have gotten a list of projects from the api
  filterStringApplied = '';

  // true if we are currently showing the advanced filters, false if we are not
  showingFilters = false;

  // true while projects are being loaded from the api
  // (shows a loading bar and disables filters while we are loading)
  loadingProjects = true;
  // if loading fails...
  errorMessage = '';

  // declaring variables, which are used to build the tables
  projects: Project[] = [];
  columns = [
    { key: 'folder', label: '', width: '1', type: 'icon', cursor: 'default' },
    { key: 'project', label: 'Projects', width: '6', inline_label: 'Project:', type: 'html' },
    { key: 'status', label: 'Status', width: '3', color: 'white', padding: '5px 10px', bord_rad: '10px', type: 'html' },
    { key: 'owner', label: 'Owner', width: '3' },
    { key: 'updated', label: 'Updated', width: '3', safe: true, type: 'html' },
    { key: 'actions', label: 'Actions', width: '2', type: 'proj-actions' },
  ];

  // static variable for converting month # to 3-letter string
  months: Record<number, string> = {
    1: 'Jan',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'May',
    6: 'Jun',
    7: 'Jul',
    8: 'Aug',
    9: 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec',
  };

  // ngModel value for the status select
  statusSearch = '';

  // ngModel value for the type select
  typeSearch = '';

  // ngModel value for the user person-search component
  userSearchObj: any = {};

  // ngModel value for the investigator person-search component
  piSearchObj: any = {};

  // ngModel value for the panel-search component
  panelSearchObj: any = {};

  // ngModel value for the intervention-search (treatment) component
  treatmentSearchObj: any = {};

  // ngModel value for the large-collab-search component
  centerSearchObj: any = {};

  // ngModel value for the group-search component
  groupSearchObj: any = {};

  // ngModel value for the publication-search component
  publicationSearchObj: any = {};

  // current user information
  user?: Person;

  // declaring service and route variables
  constructor(
    private projectService: ProjectService,
    private personService: PersonService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
  ) {}

  // on init, get the data from the api to display in the tables
  ngOnInit() {
    if (!this.embed) {
      this.activatedRoute.queryParams.subscribe(() => {
        this.getProjects(true);
      });
      this.personService.currentUser$.subscribe((user) => {
        this.user = user;
      });
    }
  }

  // if we are embedding, then the filterString comes from the inputs, so when it changes
  // we need to get the new set of projects
  ngOnChanges(changes: SimpleChanges) {
    if (this.showingSeries) {
      if (this.columns[2].key !== 'seriesstub') {
        this.columns.splice(2, 0, { key: 'seriesstub', label: 'Series Label', width: '4' });
      }
    }
    if (this.embed && changes.filterString) {
      this.getProjects();
    }
  }

  /**
   * Get the projects based on the filters. If we aren't embedding (the component is the whole page),
   * then the initial url get arguments (for example: '?userid=current') is carried forward into the
   * api get call. The angular get arguments use the same format as the api project get endpoint.
   *
   * @param {boolean} init: †rue if we want to build the filterString from the current url
   */
  getProjects(init: boolean = false) {
    // this will display the loading bar
    this.loadingProjects = true;
    this.errorMessage = '';

    if (init) {
      // if this is an initial request for the standalone version, build the filterString from the url get arguments
      this.filterString = '';
      let first = true;
      for (const key in this.activatedRoute.snapshot.queryParams) {
        this.filterString += (first ? '?' : '&') + key + '=' + this.activatedRoute.snapshot.queryParams[key];
        first = false;
      }
      this.filterString += (first ? '?' : '&') + 'getfilterinfo=true';
    }

    // build the api call httpclient observable for getting the project from the sever
    this.projectService.searchProjects(this.filterString).subscribe(
      (data) => {
        if (init) {
          // since we set 'getfilterinfo=true, the api send us information about the filters
          // that can be used to populate the initial filters
          this.filters = data.filters || [];
          // updates the filtering string with the correct id
          // for the current user if the request had 'userid=current'
          this.buildFilterString();
        }
        // map the projects to the format used by the table
        this.projects = data.projects.map((project: any) => {
          return this.buildMyProjectRow(project);
        });
        if (this.showingSeries) {
          this.projects.sort((a: any, b: any) => (a.seriesstub > b.seriesstub ? 1 : -1));
        } else {
          this.projects.sort((a: any, b: any) => (a.updated < b.updated ? 1 : -1));
        }
        // keep track of the current filtering string so that changes to the filters that don't
        // change the filterString don't trigger refreshes of the projects table
        this.filterStringApplied = this.filterString;
        // stop showing the loading bar and show the table of results
        this.loadingProjects = false;
      },
      () => {
        this.errorMessage = 'An error occurred while loading projects.';
        this.loadingProjects = false;
      },
    );
  }

  /**
   * Convert project data to format as it's displayed in the table
   *
   * @param {any} project: project object
   * @returns {any}: project table row object
   */
  buildMyProjectRow(project: any): any {
    return {
      id: project.projid,
      project:
        (project.projsym !== 'Project' + String(project.projid)
          ? '<strong>' + escapeHtml(project.projsym) + '</strong>: '
          : '') + escapeHtml(project.title, ['sup']),
      seriesstub: project.seriesstub,
      status: project.status ? project.status : 'Public',
      status_bg: statusColors[project.status ? project.status : 'Public'],
      actions: project,
      folder: project.deletedtime
        ? 'delete'
        : !project.status || project.status === 'Public'
        ? 'lock_open'
        : !project.current_user_permission
        ? 'block'
        : project.other_shared_entities.length > 0
        ? 'folder_shared'
        : 'lock',
      folder_color: project.deletedtime ? 'red' : '',
      folder_tooltip: project.deletedtime
        ? 'This project was deleted. A project owner or SIP curator can ' +
          'use the blue trash can action to restore it.'
        : buildListTooltip(
            project.other_shared_entities,
            project.current_user_permission
              ? (!project.status || project.status === 'Public'
                  ? 'This project is public (everyone can view it' +
                    (project.canEdit ? ', but only you can edit it' : '')
                  : 'This project is private (only you have access') +
                  "). Your permission level is '" +
                  project.current_user_permission +
                  "'."
              : 'This is a legacy project, only SIP curators may modify it. (You do not have access).',
            'Shared with -- ',
            project.current_user_permission
              ? (!project.status || project.status === 'Public'
                  ? '. This project is public (everyone can view it)'
                  : '') +
                  ". Your permission level is '" +
                  project.current_user_permission +
                  "'."
              : '. (You do not have access).',
          ),
      owner: project.owner.name_or_email,
      updated: project.updatedtime
        ? buildDateTimeHtml(project.updatedtime)
        : this.buildReleaseDateHtml(project.releasedate),
    };
  }

  /**
   * Build a html string for the 'Updated' column using the release date field
   * (if updatedtime isn't set, which would be a pre-existing public project)
   *
   * @param {string} date: date string in the format, '2011/05',
   * @returns {string}: date html in the format, 'May <span style="color:darkgray">(2011)</span>'
   */
  buildReleaseDateHtml(date: string): string {
    if (date) {
      const displayVal =
        this.months[Number(date.slice(5))] + ' <span style="color:darkgray">(' + date.slice(0, 4) + ')</span>';
      const sortVal = date.slice(0, 4) + date.slice(5) + '010000';
      return '<span style="display:none;">' + sortVal + '</span>' + displayVal;
    }
    return '';
  }

  // ----- filtering functions (right now only showing on the project page) -----

  // checks if a filter type has already been selected
  filterTypeTaken(type: string) {
    for (const filter of this.filters) {
      if (filter.type === type) {
        return true;
      }
    }
    return false;
  }

  /**
   * On change of the filter type, set the key to the correct value if it's an object type filter,
   * and also clear out the filterValues appropriately (depending on whether it's a list-type filter)
   * @param {any} filter: filter object for which the type was changed
   */
  filterTypeChange(filter: any) {
    filter.key = null;
    // these 9 filters are 'list' type, meaning that the user can select a list of filter values that
    // will be OR'd together when building the list of matching projects. So, filterValues is an
    // empty list when it is cleared out
    if (
      ['userid', 'groupid', 'piid', 'pmid', 'status', 'mpdsector', 'panelsym', 'largecollab', 'treatment'].indexOf(
        filter.type,
      ) !== -1
    ) {
      filter.filterValues = [];
      // the following 7 filters are 'object' types in addition to being 'list' types,
      // so the key is set to the correct value set to facilitate easy buliding of the filterString.
      // The other 2 'list' type filters ('status' and 'mpdsector') are not 'object' type.
      if (['userid', 'groupid', 'piid'].indexOf(filter.type) !== -1) {
        filter.key = 'id';
      } else if (filter.type === 'largecollab') {
        filter.key = 'tag';
      } else if (filter.type === 'panelsym') {
        filter.key = 'panelsym';
      } else if (filter.type === 'treatment') {
        filter.key = 'term';
      } else if (filter.type === 'pmid') {
        filter.key = 'pmid';
      }
    } else {
      // the only 4 filters that are not 'list' type are 'instauth' (boolean, and only one option per project),
      // 'archived', which is 'both' or 'only' in a dropdown,
      // and 'projsym' and 'title', which are both free text entry fields. It should be noted that 'projsym'
      // and 'title' differ in that multiple words in 'projsym' are OR'd together for project filtering (since
      // 'projsym' is a single-word value), while while multiple words in 'title' are AND'd together for project
      // filtering (since title is a multiple-word value). None of these 3 filter types are 'object' types
      filter.filterValues = null;
    }
    // in case a previously populated filter was cleared as a result of this change...
    this.filtersChanged();
  }

  // any time that the filters variable changes in a way that could result in filterString changing, we rebuild
  // filterString and if it has changed, then we go back out to the api for the newly filtered list of projects
  filtersChanged() {
    this.buildFilterString();
    if (this.filterString !== this.filterStringApplied) {
      this.getProjects();
    }
  }

  /**
   * When an object filter is changed, check if it was cleared... if so, then remove it from the list
   *
   * @param {number} i: filters index
   * @param {number} j: index of the list of filter object values for this particular filter type
   * @param {string} key: object key to check whether or not the filter value is cleared or populated
   */
  onObjFilterChange(i: number, j: number, key: string) {
    if (!this.filters[i].filterValues[j][key]) {
      this.filters[i].filterValues.splice(j, 1);
      this.filtersChanged();
    }
  }

  // Logic for the clear filters link/button.
  // Don't allow this logic to run if we are currently loading.
  clearFilters() {
    if (!this.loadingProjects) {
      this.filters = [];
      this.filtersChanged();
      this.showingFilters = false;
    }
  }

  // Build the filterString component based on the currently selected filter,
  // which is the contents of the filters variable.
  // If we are on the projects page, also update the url (without refreshing the route)
  // so that copy-pasting the url will yield the same results.
  buildFilterString() {
    this.filterString = '';
    let first = true;
    for (let i = 0; i < this.filters.length; i++) {
      const type = this.filters[i].type;
      const filterValues = this.filters[i].filterValues;
      const key = this.filters[i].key;
      let value = '';
      // build the value appropriately depending on the filter type
      if (Array.isArray(filterValues)) {
        let values = [];
        if (key) {
          for (const filter of filterValues) {
            values.push(filter[key]);
          }
        } else {
          values = filterValues;
        }
        value = values.join(',');
      } else if (key) {
        value = filterValues[key];
      } else {
        value = filterValues;
      }
      // only if the filter has a set value do we want to send it to the api
      if (value) {
        this.filterString += (first ? '?' : '&') + type + '=' + value;
        first = false;
      }
    }
    // If we are on the projects page, this logic will update the url (without refreshing the route)
    // so that copy-pasting the url will yield the same results.
    if (!this.embed) {
      this.location.go('/projects' + this.filterString);
    }
  }

  // series of functions to handle changes to the search/selection field for various filtering types
  // (specifically for handling the filter types that are arrays, which is most of them)

  /**
   * Handle new status filter selection
   * @param {number} i: filterValues index for the 'status' filter type
   */
  statusSearchChange(i: number) {
    if (this.statusSearch) {
      this.filters[i].filterValues.push(this.statusSearch);
      this.statusSearch = '';
      this.filtersChanged();
    }
  }

  /**
   * Handle new type filter selection
   * @param {number} i: filterValues index for the 'mpdsector' (type) filter type
   */
  typeSearchChange(i: number) {
    if (this.typeSearch) {
      this.filters[i].filterValues.push(this.typeSearch);
      this.typeSearch = '';
      this.filtersChanged();
    }
  }

  /**
   * Handle new user filter selection
   * @param {number} i: filterValues index for the 'userid' filter type
   * @param {PersonSearchComponent} userSearch: person-search component to clear
   */
  userSearchChange(i: number, userSearch: PersonSearchComponent) {
    if (this.userSearchObj ? this.userSearchObj.id : false) {
      this.filters[i].filterValues.push(this.userSearchObj);
      userSearch.clearSelection();
      this.filtersChanged();
    }
  }

  /**
   * Handle new pi filter selection
   * @param {number} i: filterValues index for the 'piid' filter type
   * @param {PersonSearchComponent} piSearch: person-search component to clear
   */
  piSearchChange(i: number, piSearch: PersonSearchComponent) {
    if (this.piSearchObj ? this.piSearchObj.id : false) {
      this.filters[i].filterValues.push(this.piSearchObj);
      piSearch.clearSelection();
      this.filtersChanged();
    }
  }

  /**
   * Handle new panel filter selection
   * @param {number} i: filterValues index for the 'panelsym' filter type
   * @param {PanelSearchComponent} panelSearch: panel-search component to clear
   */
  panelSearchChange(i: number, panelSearch: PanelSearchComponent) {
    if (this.panelSearchObj ? this.panelSearchObj.panelsym : false) {
      this.filters[i].filterValues.push(this.panelSearchObj);
      panelSearch.clearSelection();
      this.filtersChanged();
    }
  }

  /**
   * Handle new treatment filter selection
   * @param {number} i: filterValues index for the 'treament' filter type
   * @param {InterventionSearchComponent} treatmentSearch: intervention-search component to clear
   */
  treatmentSearchChange(i: number, treatmentSearch: InterventionSearchComponent) {
    if (this.treatmentSearchObj ? this.treatmentSearchObj.term : false) {
      this.filters[i].filterValues.push(this.treatmentSearchObj);
      treatmentSearch.clearSelection();
      this.filtersChanged();
    }
  }

  /**
   * Handle new center filter selection
   * @param {number} i: filterValues index for the 'largecollab' (center) filter type
   * @param {CenterSearchComponent} collabSearch: search-large-collab component to clear
   */
  centerSearchChange(i: number, collabSearch: CenterSearchComponent) {
    if (this.centerSearchObj ? this.centerSearchObj.tag : false) {
      this.filters[i].filterValues.push(this.centerSearchObj);
      collabSearch.clearSelection();
      this.filtersChanged();
    }
  }

  /**
   * Handle new group filter selection
   * @param {number} i: filterValues index for the 'groupid' filter type
   * @param {GroupSearchComponent} groupSearch: group-search component to clear
   */
  groupSearchChange(i: number, groupSearch: GroupSearchComponent) {
    if (this.groupSearchObj ? this.groupSearchObj.id : false) {
      this.filters[i].filterValues.push(this.groupSearchObj);
      groupSearch.clearSelection();
      this.filtersChanged();
    }
  }

  /**
   * Handle new publication filter selection
   * @param {number} i: filterValues index for the 'pmid' filter type
   * @param {PublicationSearchComponent} publicationSearch: publication-search component to clear
   */
  publicationSearchChange(i: number, publicationSearch: PublicationSearchComponent) {
    if (this.publicationSearchObj ? this.publicationSearchObj.pmid : false) {
      this.filters[i].filterValues.push(this.publicationSearchObj);
      publicationSearch.clearSelection();
      this.filtersChanged();
    }
  }

  /**
   * Determine whether the current user is allowed to edit data in SIP
   *
   * @returns {boolean}: true if editing is disabled, false if not
   */
  editDisabled(): boolean {
    if (!this.user) {
      return true;
    }
    return !this.user.emailverified || !!this.user.restricted;
  }

  /**
   * Builds a tooltip that tells the user what the Create or Import button does
   * OR tells they why if they aren't allowed to use them
   *
   * @param {string} defaultTooltip: default tooltip to return if there are no issues
   * @returns {string}: tooltip for the create or import project button
   */
  createImportTooltip(defaultTooltip: string = ''): string {
    if (!this.user) {
      return 'You must log in to create or import projects';
    }
    if (this.user.restricted) {
      return (
        'Edit rights on SIP are currently restricted. ' +
        'You must be given access by a curator to create or import projects.'
      );
    }
    if (!this.user.emailverified) {
      return 'You must verify your email (instructions on the profile page) before creating or importing projects.';
    }
    return defaultTooltip;
  }
}
