import { tap } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output, ViewChildren, QueryList, ViewChild } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { flashMessage, forceRange } from '../../utils';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog.component';
import { ProcedureService } from '../../../services/procedure.service';
import { PersonService } from '../../../services/person.service';
import { ProcedureSearchComponent } from '../../entity-searching/procedure-search.component';
import { FileUploadComponent } from '../../file-upload/file-upload.component';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'edit-procedure',
  templateUrl: './edit-procedure.component.html',
  styleUrls: ['../editing.scss'],
})
export class EditProcedureComponent implements OnInit {
  // true to embed this component, false to not
  @Input() embed = false;

  // true if this the embedded in the project editing page (won't necessarily always be true at the same time as
  // projid being set for the procedure, since you can view, but not edit, project procedures in the dialog)
  // NOTE: embed should be true if this is true
  @Input() projectEmbed = false;

  // true to disable editing (used with embed)
  @Input() disabled = false;

  // index in procedures for project (used for ids)
  @Input() i = 0;

  // true if the current user is a curator, false if they are not (input is set if it's embedded in a project)
  // otherwise we're populating this by request in ngOnInit
  @Input() isCurator = false;

  // id of linked bioconnect study, used for searching assays under the study to link to the procedure, and
  // as a flag to show assay_id
  @Input() bioconnectStudyId: number | null = null;

  // other procedures linked to the project... used to disable things that need to be mutually exclusive
  @Input() projectProcedures: any[] = [];

  // procedure object we're creating/modifying (defaults to private=true when creating new template)
  @Input() procedure: any = { private: true };

  // emit the change when a procedure is saved or deleted
  @Output() procedureChange: EventEmitter<any> = new EventEmitter<any>();

  // event for cancel button click (only displayed on embed)
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();

  // event that is emitted when the parent should handle saving the current procedure as a template (because we want
  // to save the current procedure first, and saving is handled in the parent)
  @Output() saveTemplate: EventEmitter<any> = new EventEmitter<any>();

  // variable for accessing all of the fileupload child components (as an array)
  // in this parent component's typescript
  @ViewChildren(FileUploadComponent) fileUploads!: QueryList<FileUploadComponent>;

  // need to be able to clear the selection in the procedure-search component for stand-alone page
  @ViewChild('select_procedure') selectProcedureComponent!: ProcedureSearchComponent;

  // need to be able to clear the selection in the procedure-search component for procedures to load from
  // NOTE: not currently used/needed because this seems to clear itself when the new procedure loads...
  //       but keeping it just in case
  @ViewChild('load_from_procedure') loadFromProcedureComponent!: ProcedureSearchComponent;

  // need to be able to clear the selection in the procedure-search component for templates to save to
  // when it is successfully updated
  @ViewChild('save_to_procedure') saveToProcedureComponent!: ProcedureSearchComponent;

  // makes this function useable in html
  forceRange = forceRange;

  // objects for procedure lookups to to load from or update to
  loadFromProcedure: any = {};
  saveToProcedure: any = {};

  // variables bound to inputs for creating a new template cloned from current procedure
  newTemplatePrivate = true;
  newTemplateName = '';

  // used to check if bioconnect linking show be shown and link out to assay
  bioconnect = environment.unsecuredURLs.bioconnect;
  bioconnectUi = environment.unsecuredURLs.bioconnectUi;

  // assay search variable
  bcAssay: any = {};

  // constructors
  constructor(
    private procedureService: ProcedureService,
    private personService: PersonService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {}

  // define workflow editable-table columns
  stepsColumns: any[] = [
    { key: 'step', name: 'Step #', class: 'width5-percent', maxlength: 20 },
    { key: 'substep', name: 'Substep (optional)', class: 'width5-percent', maxlength: 20 },
    { key: 'descrip', name: 'Description', class: 'width80-percent', maxlength: 1000 },
  ];

  // define equipment editable-table columns and fields
  equipColumns: any[] = [
    { key: 'name', name: 'Name', class: 'width15-percent', maxlength: 120 },
    { key: 'manufacturer', name: 'Manufacturer', class: 'width15-percent', maxlength: 120 },
    { key: 'model', name: 'Model / Identifier', class: 'width15-percent', maxlength: 120 },
    { key: 'city', name: 'City', class: 'width10-percent', maxlength: 80 },
    { key: 'state', name: 'State', class: 'width5-percent', maxlength: 40 },
    { key: 'country', name: 'Country', class: 'width15-percent', maxlength: 80 },
    { key: 'rrid', name: 'RRID', class: 'width10-percent', maxlength: 80 },
  ];
  equipFields: any[] = [
    { key: 'descrip', label: 'Details', maxlength: 500, tooltip: 'For example, apparatus dimensions' },
  ];

  // define reagents editable-table columns and fields
  reagentsColumns: any[] = [
    { key: 'name', name: 'Name', class: 'width15-percent', maxlength: 120 },
    { key: 'manufacturer', name: 'Manufacturer', class: 'width15-percent', maxlength: 120 },
    { key: 'vendnum', name: 'Vendor Number', class: 'width15-percent', maxlength: 120 },
    { key: 'city', name: 'City', class: 'width10-percent', maxlength: 80 },
    { key: 'state', name: 'State', class: 'width5-percent', maxlength: 40 },
    { key: 'country', name: 'Country', class: 'width15-percent', maxlength: 80 },
    { key: 'rrid', name: 'RRID', class: 'width10-percent', maxlength: 80 },
  ];
  reagentsFields: any[] = [{ key: 'descrip', label: 'Details', maxlength: 500 }];

  // determine if the current user is a curator
  ngOnInit() {
    // if we are in project editing, we'll just pass in the isCurator boolean as an input
    if (!this.projectEmbed) {
      this.personService.currentUser$.subscribe(() => {
        this.isCurator = this.personService.isCurator();
      });
    }
  }

  /**
   * On selecting and loading another procedure in the autocomplete (stand-alone page mode),
   * get all of the details about this procedure.
   */
  onTemplateChange(): void {
    if (this.procedure.proc_id) {
      this.procedureService.getProcedure(this.procedure.proc_id).subscribe(
        (data) => {
          this.procedure = data;
          this.procedureChange.emit(this.procedure);
        },
        (error2) => {
          this.procedure = { private: true };
          this.procedureChange.emit(this.procedure);
          flashMessage(
            this.snackBar,
            'Failed to get procedure',
            'alert-danger',
            5000,
            error2.error ? [error2.error.message] : [],
          );
        },
      );
    }
  }

  /**
   * Observable for saving the current procedure, includes flashing messages
   *
   * @returns {Observable<any>}: observable for procedure save
   */
  saveProcedureObservable(): Observable<any> {
    return this.procedureService.saveProcedure(this.procedure).pipe(
      tap(
        (result) => {
          this.procedure = result;
          this.procedureChange.emit(this.procedure);
          flashMessage(this.snackBar, 'Save successful!', 'alert-success', 2000);
        },
        (error2) => {
          flashMessage(this.snackBar, 'Save failed.', 'alert-danger', 5000, error2.error ? [error2.error.message] : []);
        },
      ),
    );
  }

  // save this procedure after making sure we are able to
  saveProcedure() {
    if (this.canEdit() && !this.procedure.projid) {
      this.saveProcedureObservable().subscribe();
    }
  }

  // delete this procedure
  deleteProcedure() {
    if (this.canDelete()) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          header: 'Confirm Delete Procedure Template',
          message: 'Are you sure that you wish to delete this procedure template?',
          falselabel: 'Cancel',
          truelabel: 'Delete',
          truebtn: 'btn-danger',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.procedureService.deleteProcedure(this.procedure.proc_id).subscribe(() => {
            this.selectProcedureComponent.clearSelection();
            this.procedureChange.emit(this.procedure);
          });
        }
      });
    }
  }

  /**
   * Figure out if the current user is allowed to edit the procedure
   *
   * @returns {any}: true if the current user can edit the procedure, false if not
   */
  canEdit(): boolean {
    // use the project canEdit (which can actually be false even for Curators if the project is archived)
    if (this.procedure.projid || this.projectEmbed) {
      return this.projectEmbed ? !this.disabled : false;
    }
    // curators can edit anything... except archived projects
    if (this.isCurator) {
      return true;
    }
    // this is a new procedure, so can edit
    if (!this.procedure.proc_id) {
      return true;
    }
    // permission sent with object lets us know if we can edit
    return ['Edit', 'Owner'].indexOf(this.procedure.permission) !== -1;
  }

  /**
   * Figure out if the current user is allowed to delete the procedure
   *
   * @returns {any}: true if the current user can delete the procedure, false if not
   */
  canDelete(): boolean {
    // can't delete procedures on projects (project procedures page handles that) or in embed
    if (this.procedure.projid || this.projectEmbed || this.embed) {
      return false;
    }
    // can't delete something that doesn't exist
    if (!this.procedure.proc_id) {
      return false;
    }
    // can't delete if can't edit
    return this.canEdit();
  }

  /**
   * Get the fileupload component variable for the procedure with the passed-in listingorder
   *
   * @param {string} type: type of fileupload ('steps', 'equipment', or 'reagents')
   * @returns {FileUploadComponent} file upload component associated with the procedure
   */
  getFileUploadComponent(type: string = 'steps'): FileUploadComponent | null {
    for (const fileupload of this.fileUploads.toArray()) {
      if (fileupload.tag === 'procedure' + String(this.i + 1) + type) {
        return fileupload;
      }
    }
    return null;
  }

  // build the pointer for file-upload components in the html
  procedureFilePointer() {
    return this.procedure.projid ? this.procedure.projid : this.procedure.proc_id;
  }

  // build the pointertype for file-upload components in the html
  procedureFilePointertype() {
    return this.procedure.projid ? 'projid' : 'proc_id';
  }

  // when a protocols.io protocol is linked to the procedure, if there's not currently a title, make it
  // the same as the protocols.io protocol's title
  protocolIoChange() {
    const protocol = this.procedure.protocols_io;
    if (protocol ? protocol.id : false) {
      if (!this.procedure.title) {
        this.procedure.title = protocol.title;
      }
    }
  }

  /**
   * Determines whether the SIP entry fields for the procedure should be shown.
   * Note: Regardless of what the user picks, they will be shown eventually if they are entering a procedure,
   *       but this is for the purpose of hiding them until they have made decisions about whether they want to
   *       load from a template and what their primary form of entry will be.
   *
   * @returns {any}: true to show procedure entry fields, false to not
   */
  showProcEntryFields() {
    // if there's already a title, then the user already entered something manually, so show entry fields
    if (this.procedure.title) {
      return true;
    }
    if (this.procedure.primaryentry) {
      if (this.procedure.primaryentry === 'protocols_io') {
        // if primary entry format is protocols_io, then let them select a protocol before showing the title and
        // other info, since we can default in some of it from the protocols_io protocol (like title)
        return this.procedure.protocols_io.id;
      }
      // if primaryentry is set and not 'protocols_io', then show entry fields
      return true;
    }
    // else return false
    return false;
  }

  /**
   * Copy from current procedure to a template (new or existing)...
   * Confirms that the current procedure is eligible to be cloned ot a template and
   * confirms whether the user is sure if they want to overwrite the destination procedure if one
   * was selected.
   *
   * @param {number} destProcID (optional): procedure id to which we are copying,
   *                                          required if there's no templateName
   */
  saveProcedureAsTemplateConfirm(destProcID: number = 0) {
    if (this.procedure.proc_id && this.procedure.title) {
      if (destProcID) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          data: {
            header: 'Confirm Update Procedure Template',
            message:
              'Are you sure that you wish to update the selected procedure template from the current ' +
              'procedure? <br><br>Any information entered in the selected procedure template will be removed and ' +
              'replaced with the values in the current procedure.',
            falselabel: 'Cancel',
            truelabel: 'Update',
            truebtn: 'btn-success',
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.saveProcedureAsTemplate(destProcID);
          }
        });
      } else if (this.newTemplateName) {
        this.saveProcedureAsTemplate(0, this.newTemplateName, this.newTemplatePrivate);
      }
    }
  }

  /**
   * Copy from current procedure to a template (new or existing)... makes sure
   * that current procedure is saved first (including possibility of passing this off to the parent by event
   * if this is a project protocol), also confirms other requirements and includes flash messages
   *
   * @param {number} destProcID (optional): procedure id to which we are copying,
   *                                          required if there's no templateName
   * @param {string} templateName (optional): templateName for new template, required if there's no destProcID
   * @param {boolean} privateTemp (optional): true to make the new template private (if it's new)
   */
  saveProcedureAsTemplate(destProcID: number = 0, templateName: string = '', privateTemp: boolean = false) {
    if (this.procedure.proc_id && this.procedure.title) {
      if (this.canEdit()) {
        if (this.projectEmbed) {
          this.saveTemplate.emit({
            source_proc_id: this.procedure.proc_id,
            destproc_id: destProcID,
            template_name: templateName,
            private: privateTemp,
          });
        } else {
          this.saveProcedureObservable().subscribe(
            () => {
              this.copyProcedureToTemplate(this.procedure.proc_id, destProcID, templateName, privateTemp);
            },
            (error2) => {
              flashMessage(
                this.snackBar,
                'Failed to save procedure before saving it as a template.',
                'alert-danger',
                5000,
                error2.error ? [error2.error.message] : [],
              );
            },
          );
        }
      } else {
        this.copyProcedureToTemplate(this.procedure.proc_id, destProcID, templateName, privateTemp);
      }
    }
  }

  /**
   * Copies from current procedure to a template (new or existing),
   * includes flash message for success or failure.
   * Also, clear the destination procedure out of the lookup if successful (it may have the wrong info now)
   *
   * @param {number} sourceProcID: procedure id from which we are copying
   * @param {number} destProcID (optional): procedure id to which we are copying,
   *                                          required if there's no templateName
   * @param {string} templateName (optional): templateName for new template, required if there's no destProcID
   * @param {boolean} privateTemp (optional): true to make the new template private (if it's new)
   */
  copyProcedureToTemplate(
    sourceProcID: number,
    destProcID: number = 0,
    templateName: string = '',
    privateTemp: boolean = false,
  ) {
    this.procedureService.copyProcedure(sourceProcID, destProcID, templateName, privateTemp).subscribe(
      () => {
        if (destProcID) {
          this.saveToProcedureComponent.clearSelection();
        }
        flashMessage(this.snackBar, 'Successfully saved procedure template!', 'alert-success', 2000);
      },
      (error2) => {
        flashMessage(
          this.snackBar,
          'Failed to save procedure template.',
          'alert-danger',
          5000,
          error2.error ? [error2.error.message] : [],
        );
      },
    );
  }

  /**
   * Loads the selected procedure or procedure template into this current procedure...
   * confirms that the user is sure if they want to overwrite the contents of the current procedure.
   *
   * @param {number} sourceProcID: selected source procedure procID to clone into this procedure
   */
  loadProcedure(sourceProcID: number) {
    if (this.procedure.proc_id && sourceProcID) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          header: 'Confirm Load Procedure',
          message:
            'Are you sure that you wish to load the selected procedure into the current procedure? ' +
            '<br><br>Any information entered in the current procedure will be removed and ' +
            'replaced with the values in the selected procedure.',
          falselabel: 'Cancel',
          truelabel: 'Load',
          truebtn: 'btn-primary',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.procedureService.copyProcedure(sourceProcID, this.procedure.proc_id).subscribe(
            (result) => {
              this.procedure = result;
              this.procedureChange.emit(this.procedure);
              // For some reason, the procedure seems to reload the file components on its own
              // when the new procedure is loaded in
              // this.reloadFiles();
              flashMessage(this.snackBar, 'Successfully loaded procedure from template!', 'alert-success', 2000);
            },
            (error) => {
              flashMessage(
                this.snackBar,
                'Failed to load procedure from template.',
                'alert-danger',
                5000,
                error.error ? [error.error.message] : [],
              );
            },
          );
        }
      });
    }
  }

  /**
   * On change of selected assay, updated the bioconnect assay id and identifier in the procedure
   *
   * @param assay: selected assay option (or blank/undefined if cleared)
   */
  onAssayChange(assay: any) {
    if (assay.id) {
      this.procedure.bc_assay_id = assay.id;
      this.procedure.bc_assay_identifier = assay.identifier;
    }
  }

  // true to show either the linked bioconnect assay, or the search to link a bioconnect assay
  showBioconnectAssayLink() {
    return this.procedure.projid && this.bioconnectStudyId && this.bioconnect && this.bioconnectUi;
  }

  /**
   * Determines whether the passed-in string is populated or just whitespace
   *
   * @param {string} value: string (or null or undefined value) to evaluate
   * @returns {string | boolean}: true if the string is populated, false if not
   */
  strSet(value: string) {
    return value ? value.trim() : false;
  }

  // reloads all of the file-upload components to get changes in the currently uploaded files
  // NOTE: not currently used anywhere, but keeping just in case
  reloadFiles() {
    for (const fileupload of this.fileUploads.toArray()) {
      fileupload.loadFiles();
    }
  }
}
