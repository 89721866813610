<div class="col-sm-12 bottom-margin-1">
  <div class="col-sm-12 col-lg-6">
    <p>
      Submit the selected samples for processing. This genotype data will then be loaded into Diversity DB and available
      for download. Upon submitting, the uploaded genotype array data will go through preprocessing, generating a final
      inventory file and then will be loaded into Diversity DB's database. Once finished, you will be able to download
      genotypes through Diversity DB.
    </p>
    <div class="button-tooltip-container" [matTooltip]="processButtonTooltip()">
      <button mat-flat-button color="primary" [disabled]="!canProcess" (click)="getConfirmation()">
        Process Dataset
      </button>
    </div>
  </div>
  <div class="col-sm-12 col-lg-6">
    <p>
      Send the processed dataset through a ready-made haplotype reconstruction pipeline to generate genotype probability
      files for each genotyping platform included in the dataset. These genotype probability files will then be
      available for download through Diversity DB.
    </p>
    <div class="button-tooltip-container" [matTooltip]="runHaplotypeReconstructionTooltip()">
      <button
        mat-flat-button
        color="accent"
        [disabled]="!canRunHaplotypeReconstruction"
        (click)="runHaplotypeReconstruction.emit()"
      >
        Generate Genotype Probabilities
      </button>
    </div>
  </div>
  <div *ngIf="submissionError" class="col-sm-12 align-center">
    <p class="error">{{ submissionError }}</p>
  </div>
</div>
