import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';

// dialog for flashing a 'snackbar' message on the screen
@Component({
  selector: 'flash-message',
  template: `
    <div *ngIf="data" [class]="'form-horizontal' + (data.class ? ' ' + data.class : '')">
      <div class="form-group" style="margin-bottom:0;">
        <div class="col-xs-10">
          <span [style.color]="data.color" [style.background]="data.background"
            ><b>{{ data.text }}</b></span
          >
          <ul *ngIf="data.listmessages ? data.listmessages.length > 0 : false" [style.color]="data.listcolor">
            <li *ngFor="let message of data.listmessages">{{ message }}</li>
          </ul>
        </div>
        <div class="col-xs-2" align="right">
          <span (click)="data.snackbar.dismiss()" class="glyphicon glyphicon-remove hover-icon"></span>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .hover-icon {
        cursor: pointer;
        color: dimgray;
      }
      .hover-icon:hover {
        color: black;
      }
      .form-horizontal {
        padding: 14px 24px;
        border-radius: 2px;
      }
    `,
  ],
})
export class FlashMessageComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: FlashMessageData) {}
}

export interface FlashMessageData {
  class: string;
  background?: string;
  color?: string;
  text: string;
  listcolor: string; // TODO: camelCase this
  listmessages: string[]; // TODO: camelCase this
  snackbar: MatSnackBar;
}
