import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { R2DataObject } from './r2.service';
import {
  SavedAnimal,
  UnsavedAnimal,
} from '../components/genotype-intake/animal-info-upload/animal-info-upload.component';
import {
  InventoryAnimal,
  MissingInventoryAnimal,
} from '../components/genotype-intake/inventory-review/inventory-review.component';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DivDBService {
  // base URL to the DivDB service
  divdb: string = environment.securedURLs.divdb;

  constructor(private http: HttpClient) {}

  /**
   * Returns the DivDB dataset associated with the specified dataset ID
   * @param datasetID - the ID of the dataset that should be retrieved
   */
  getDataset(datasetID: number): Observable<DivdbDataset> {
    return this.http.get<DivdbDataset>(`${this.divdb}datasets/${datasetID}`);
  }

  /**
   * Creates a new dataset with the specified attributes in DivDB and returns the result
   * @param name - name of the new dataset
   * @param desc - short description of the new dataset
   * @param investID - ID of the investigator of the current SIP study
   * @param secondID - secondary contact for the new dataset (this could be the user submitting the
   *                                   genotypes, the owner of the SIP study, or another person the user enters)
   */
  createNewDataset(name: string, desc: string, investID: number, secondID: number | null): Observable<DivdbDataset> {
    const data = {
      name: name,
      description: desc,
      investigator_id: investID,
      contact2_id: secondID,
    };

    return this.http.post<DivdbDataset>(`${this.divdb}datasets/`, data);
  }

  /**
   * Updates a dataset with the specified attributes in DivDB and returns the result
   * @param datasetID - the ID of the dataset being updated
   * @param name - updated dataset name
   * @param desc - updated dataset description
   * @param secondContactID - updated secondary contact for the dataset
   */
  updateDataset(datasetID: number, name: string, desc: string, secondContactID: number): Observable<DivdbDataset> {
    const data = {
      name: name,
      description: desc,
      contact2_id: secondContactID < 0 ? null : secondContactID, // send null rather than -1
    };

    return this.http.patch<DivdbDataset>(`${this.divdb}datasets/${datasetID}`, data);
  }

  /**
   * Updates a dataset to be marked as public
   * @param datasetID - the ID of the dataset being released
   */
  releaseDataset(datasetID: number): Observable<DivdbDataset> {
    return this.http.patch<DivdbDataset>(`${this.divdb}datasets/${datasetID}`, { is_private: false });
  }

  /**
   * Updates a dataset to be marked as private
   * @param datasetID - the ID of the dataset being unreleased
   */
  unreleaseDataset(datasetID: number): Observable<DivdbDataset> {
    return this.http.patch<DivdbDataset>(`${this.divdb}datasets/${datasetID}`, { is_private: true });
  }

  /**
   * Delete a dataset based on id.  For now,DivDB archives the dataset.
   * @param datasetID - the ID of the dataset being deleted
   */
  deleteDataset(datasetID: number): Observable<any> {
    return this.http.delete<any>(`${this.divdb}datasets/${datasetID}`);
  }

  /**
   * Adds the list of animals with the uploaded data to the dataset and returns the list of animals added
   * successfully and a list of any animals that failed to be added
   * @param datasetID - ID of the dataset that is getting animals added
   * @param animals - list of animals to add
   */
  addAnimalInfo(datasetID: number, animals: UnsavedAnimal[]): Observable<AnimalInfoPostReport> {
    // TODO: DivDB API doesn't like empty strings for DOB so make any empty strings into null values
    animals = animals.map((a) => {
      if (a.dob?.trim() === '') {
        a.dob = null;
      } else {
        const dobAsDate = new Date(a.dob || '');

        if (dobAsDate.toString() !== 'Invalid Date') {
          a.dob = `${dobAsDate.getFullYear()}-${dobAsDate.getMonth() + 1}-${dobAsDate.getDate()}`;
        }
      }
      return a;
    });

    return this.http.post<AnimalInfoPostReport>(`${this.divdb}datasets/${datasetID}/animal_info`, animals);
  }

  /**
   * Returns the animal info for the dataset with the specified dataset ID
   * @param datasetID - the ID of the dataset the animal info should come from
   */
  getAnimalInfo(datasetID: number): Observable<SavedAnimal[]> {
    return this.http.get<SavedAnimal[]>(`${this.divdb}datasets/${datasetID}/animal_info`);
  }

  getArrayUploadURLS(datasetID: number, file: File,
                     platform: string, contentType: string): Observable<ArrayFileUploadUrl> {
    const encContentType = encodeURIComponent(contentType)
    const formattedPlatform = formatGenotypingPlatform(platform)
    const args = `file_name=${file.name}&platform=${formattedPlatform}&content_type=${encContentType}`
    return this.http.get<ArrayFileUploadUrl>(`${this.divdb}datasets/${datasetID}/array_files/upload_url?${args}`)
  }

  putFileInCloudStorage(url: string, file: File): Observable<any> {
    return this.http.put(url, file, { headers: { 'Content-Type': file.type } });
  }


  /**
   * Adds a genotyping array to an existing DivDB dataset and returns the result with the updated data
   * @param datasetID - ID of the DivDB dataset that the array should be added to
   * @param platform - selected genotype platform for the array
   * @param finalReportFilename - Final report file name, uses `arrayNameFromFinalReportFilename` to generate array name
   * @param finalReportUrl - The GS URL of the final report file
   * @param sampleMapUrl - The GS URL of the sample map file
   */
  addArrayToDataset(
    datasetID: number, platform: string, finalReportFilename: string, finalReportUrl: string, sampleMapUrl: string
  ): Observable<newArrayFileset[]> {
    const data = {
      name: arrayNameFromFinalReportFilename(finalReportFilename),
      platform: formatGenotypingPlatform(platform),
      files: [
        {
          url: sampleMapUrl,
          type: 'sample_map',
        },
        {
          url: finalReportUrl,
          type: 'final_report',
        },
      ],
    };
    return this.http.post<newArrayFileset[]>(`${this.divdb}datasets/${datasetID}/array_files`, data);
  }

  /**
   * Returns an object containing an array of animals that appeared in both the submitted animal
   * information and sample maps and an array of animals that were only present in the animal
   * information given the specified platform
   * @param datasetID - ID of the DivDB dataset to get a current inventory
   * @param platform - genotyping platform desired for inventory
   */
  getInventory(datasetID: number, platform: string): Observable<Inventory> {
    return this.http
      .get<Inventory>(`${this.divdb}datasets/${datasetID}/inventory?platform=${formatGenotypingPlatform(platform)}`)
      .pipe(
        map((resp) => {
          resp.inventory.forEach((i) => (i.platform = formatGenotypingPlatform(platform)));
          return resp;
        }),
      );
  }

  /**
   * Returns a list of inventory files generated by inventory animal submissions
   * @param datasetID - ID of the DivDB dataset to get inventory files for
   */
  getInventoryFiles(datasetID: number): Observable<InventoryFile[]> {
    return this.http.get<InventoryFile[]>(`${this.divdb}datasets/${datasetID}/inventory/files`);
  }

  /**
   * Submits the selected inventory animals given the specified platform and dataset ID to DivDB to
   * generate an inventory file and returns the data object where the inventory file is saved to
   * @param datasetID - ID of the DivDB dataset to submit an inventory set of animals for
   * @param inventories - array of animals to be submitted for processing
   */
  submitFinalInventory(datasetID: number, inventories: InventoryPost[]): Observable<R2DataObject> {
    return this.http.post<R2DataObject>(`${this.divdb}datasets/${datasetID}/inventory`, inventories);
  }

  /**
   * Starts the pipelines and database loading processing in DivDB for the specified dataset
   * @param datasetID - ID of the DivDB dataset to start processing
   */
  startProcessing(datasetID: number): Observable<DivdbDatasetStatus[]> {
    return this.http.post<DivdbDatasetStatus[]>(`${this.divdb}datasets/${datasetID}/process`, null);
  }

  /**
   * Cancels the process with the specified task ID
   * @param datasetID - ID for the dataset a task is to be cancelled from
   * @param taskID - the task ID for the process to cancel
   */
  cancelProcess(datasetID: number, taskID: string): Observable<TaskCancellationStatus> {
    return this.http
      .delete<TaskCancellationStatus>(`${this.divdb}datasets/${datasetID}/process/${taskID}`)
      .pipe(tap(console.log));
  }

  /**
   * Returns the processing status for the specified DivDB dataset
   * @param datasetID - dataset ID for the dataset to get the status for
   */
  getProcessingStatus(datasetID: number): Observable<DivdbDatasetStatus[]> {
    return this.http.get<DivdbDatasetStatus[]>(`${this.divdb}datasets/${datasetID}/process`);
  }

  /**
   * Starts the haplotype reconstruction pipeline for the specified dataset and platform
   * @param datasetID - ID of the DivDB dataset to start the haplotype reconstruction pipeline for
   * @param platform - the platform the pipeline is run against
   */
  startHaplotypeReconstruction(datasetID: number, platform: string): Observable<any> {
    return this.http.post<DivdbDatasetStatus>(`${this.divdb}datasets/${datasetID}/process/haplotype/${platform}`, {});
  }

  getPublications(datasetID: number): Observable<LinkedPublicationResponse[]> {
    return this.http.get<LinkedPublicationResponse[]>(`${this.divdb}datasets/${datasetID}/publication`);
  }

  linkPublication(datasetID: number, newPublication: NewLinkedPublication): Observable<LinkedPublicationResponse> {
    return this.http.post<LinkedPublicationResponse>(`${this.divdb}datasets/${datasetID}/publication`, newPublication);
  }

  deletePublication(datasetID: number, pubmedID: number | string): Observable<string> {
    return this.http.delete<string>(`${this.divdb}datasets/${datasetID}/publication/${pubmedID}`);
  }
}

/**
 * Returns the properly-capitalized versions of a genotyping platform
 * @param platform - genotyping platform string to transform
 */
export function formatGenotypingPlatform(platform: string): string {
  if (platform.toLowerCase() === 'muga') {
    return 'MUGA';
  } else if (platform.toLowerCase() === 'megamuga') {
    return 'MegaMUGA';
  } else if (platform.toLowerCase() === 'gigamuga') {
    return 'GigaMUGA';
  }

  return '';
}

/**
 * Utility function that returns the genotype array run name from the specified final report filename
 * @param finalReportFilename - name of the final report file to extract from
 * @private
 */
export function arrayNameFromFinalReportFilename(finalReportFilename: string): string {
  return finalReportFilename.replace(/\.[^/.]+$/, '').replace('_FinalReport', '');
}

// defining the structure of data received from the API
/* eslint-disable camelcase */
export interface DivdbDataset {
  name: string;
  description: string;
  investigator_id: number;
  contact2_id: number;
  dataset_id: number;
  symbol: string;
  investigator: string;
  contact2: string;
  arrays: ArrayFileset[];
  files: DivdbFile[];
  is_private: boolean;
  phenotypes: boolean;
  platforms: string[];
  publications?: DivdbPublication[];
  tags: string[];
  animal_count: number;
  inventory_files: InventoryFile[];
  status: DivdbDatasetStatus[];
}

export interface NewLinkedPublication {
  pubmed_id: string;
  animals: string[];
}

export interface LinkedPublicationResponse {
  publication: DivdbPublication;
  animals: SavedAnimal[];
  errors: string[] | null;
}

export interface DivdbPublication {
  authors: string;
  firstauthor: string;
  fulljournalname: string;
  issue: string;
  lastauthor: string;
  pages: string;
  pmcid: string;
  pmid: string;
  pubdate: string;
  source: string;
  title: string;
  volume: string;
}

export interface DivdbFile {
  type: string;
  type_display_name: string;
  url: string;
}

export interface DivdbDatasetStatus {
  status: string;
  run_id: string;
  messages: any[];
  platform: string;
  start_time: string;
  type: string;
  start_time_date: Date; // added by UI when received
  report?: {
    // added by UI when received
    loaded: number;
    to_load: {
      arrays: number;
      samples: number;
      skipped: number;
    };
  };
}

export interface InventoryFile {
  name: string;
  url: string;
  type: string;
}

export interface ArrayFileUploadUrl {
  upload_url: string;
  gs_url: string;
}

export interface ArrayFileUpload {
  platform: string;

}

export interface newArrayFile {
  url: string;
  type: string;
  name: string;
}

export interface newArrayFileset {
  name: string;
  platform: string;
  files: newArrayFile[];
}

export interface ArrayFileset {
  platform: string;
  final_report_name: string;
  final_report_url: string;
  sample_map_name: string;
  sample_map_url: string;
}

export interface AnimalInfoPostReport {
  animals: SavedAnimal[];
  failures: AnimalInfoPostFailure[];
}

export interface AnimalInfoPostFailure {
  message: string;
  animal: UnsavedAnimal;
}

export interface InventoryPost {
  platform: string;
  inventory: InventoryAnimal[];
}

export interface Inventory {
  inventory: InventoryAnimal[];
  missing: MissingInventoryAnimal[];
}

export interface TaskCancellationStatus {
  run_id: string;
  ended: boolean;
  status: string;
  status_update_time: string;
}
/* eslint-enable camelcase */
