import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class CenterService {
  // api url
  private api: string = environment.securedURLs.sip;

  // collab behavioursubject for building an observable
  public collabs = new BehaviorSubject<any[]>([]);
  // observable, which can be subscribed to in order to get changes
  // to the collabs list any time it is updated
  public collabs$ = this.collabs.asObservable();
  // flag as to whether the collabs list has been initialized yet
  private collabsListInitialized = false;

  // declare http client
  constructor(private http: HttpClient) {
    if (!this.collabsListInitialized) {
      this.collabsListInitialized = true;
      this.getCollab().subscribe();
    }
  }

  /**
   * Sends a http request to GET either a specific collaborator or a list of all of them
   *
   * @param {string} tag: specific collaborator to retrieve or null to get a list of all collaborators
   * @returns {Observable<any>} Observable
   */
  getCollab(tag: string = ''): Observable<any> {
    let url = this.api + 'largecollabs';

    if (tag) {
      url += '?tag=' + String(tag);
    }
    return this.http.get<any>(url).pipe(
      tap((result) => {
        if (!tag) {
          this.collabsListInitialized = true;
          // cache the list and trigger the collabs$ observable
          // so that all subscribers get the updated list
          this.collabs.next(result);
        }
      }),
    );
  }

  /**
   * Sends a http request to create/update a collab's information
   *
   * @param {any} collab - object representing a collab
   * @return {Observable<any>} - updated collab object (on success)
   */
  saveCollab(collab: any): Observable<any> {
    const url = this.api + 'largecollabs';

    return this.http
      .put<any>(url, collab ? collab : {}, { headers: new HttpHeaders().set('Content-Type', 'application/json') })
      .pipe(
        tap(() => {
          // update the cached collab in case someone else is working on collabs
          this.getCollab().subscribe();
        }),
      );
  }

  /**
   * Sends http request to delete a collab and updates cache
   *
   * @param {number} tag - tag of the collab to delete
   * @return {Observable<any>} - an observable
   */
  deleteCollab(tag: string = ''): Observable<any> {
    let url = this.api + 'largecollabs';

    if (tag) {
      url += '?tag=' + String(tag);
    }

    return this.http.delete<any>(url).pipe(
      tap(() => {
        const collabsList = this.collabs.getValue();

        for (let i = 0; i < collabsList.length; i++) {
          if (tag === collabsList[i].tag) {
            collabsList.splice(i, 1);
            this.collabs.next(collabsList);
            break;
          }
        }

        this.getCollab().subscribe();
      }),
    );
  }
}
