import { AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * Validates that the filename contains or does not contain any whitespaces based on the value
 * of allowWhitespace flag (which is false by default)
 * @param {boolean} allowWhitespace - flag indicating if the validator is should return an error
 *                                    if whitespaces have been found (default false)
 */
export function WhitespaceValidator(allowWhitespace: boolean = false): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!allowWhitespace && String(control.value).indexOf(' ') >= 0) {
      return { cannotContainSpaces: { value: control.value } };
    }

    return null;
  };
}
