// Angular imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// Modules
import { MaterialModule } from '../../shared/material.module';
import { SharedModule } from '../../shared/shared.module';

// Components
import { QCDashboardComponent } from './dashboard.component';
import { QCGeneralMessagesComponent } from './general/qc-general.component';
import { QCStatisticalComponent } from './statistical/qc-statistical.component';
import { QCTechnicalComponent } from './technical/qc-technical.component';
import { TimeSeriesPlotComponent } from './plots/time-series-plot.component';
import { StrainSurveyPlotComponent } from './plots/strain-survey-plot.component';

@NgModule({
  imports: [CommonModule, FormsModule, MaterialModule, RouterModule, SharedModule],
  declarations: [
    QCDashboardComponent,
    QCGeneralMessagesComponent,
    QCTechnicalComponent,
    QCStatisticalComponent,
    TimeSeriesPlotComponent,
    StrainSurveyPlotComponent,
  ],
})
export class DataValidationModule {}
