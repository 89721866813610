import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate } from '@angular/router';
import { AuthService } from './services/auth.service';
import { tap } from 'rxjs/operators';

//
// This is pretty much right out of the auth0 angular quickstart
//
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  // if not logged-in, redirect to auth0 login
  canActivate() {
    return this.auth.isAuthenticated$.pipe(
      tap((loggedIn) => {
        if (!loggedIn) {
          this.router.navigate(['/welcome']);
        }
      }),
    );
  }
}
