<div class="row">
  <mat-toolbar>
    <h4 class="gray7">Technical QC: {{ project.projsym }}</h4>
  </mat-toolbar>
</div>
<br />
<div class="row">
  <div class="col-sm-12">
    <a [routerLink]="['/project/data-information/details', projectId]" class="dark-cerulean text10">
      <mat-icon class="v-bottom" inline="true"> arrow_back </mat-icon>QC Summary</a
    >
    &nbsp;|&nbsp;
    <a [routerLink]="['/project/qc-dashboard', projectId]" class="dark-cerulean text10"> Dashboard</a>
    &nbsp;|&nbsp;
    <a [routerLink]="['/project/statistical-qc/details', projectId]" class="dark-cerulean text10">
      Statistical QC<mat-icon class="v-bottom" inline="true"> arrow_forward </mat-icon></a
    >
  </div>
</div>
<br />
<div class="row">
  <div class="col-md-11">
    <app-table
      [rows]="phenoMeasures"
      [columns]="measTableColumns"
      [rowSelect]="true"
      [defPageSize]="5"
      (rowSelected)="onRowSelect($event)"
      [rowIdKey]="'measnum'"
    >
    </app-table>
  </div>
</div>
<br />
<br />
<!-- only visible when the 'chart' object is instantiated -->
<div class="row" *ngIf="isMeasureSelected">
  <div class="col-md-11 text-center">
    <div style="background: white; padding: 5px 0">
      <mat-button-toggle-group
        name="plotType"
        aria-label="Plot Type"
        [value]="visType"
        (change)="onVisViewChange($event)"
      >
        <mat-button-toggle value="cross-sec-view"> Cross-Sectional View </mat-button-toggle>
        <mat-button-toggle value="series-view" [disabled]="!selectedMeasure.seriestype">
          Series View
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
</div>
<br />
<div class="row">
  <div class="col-md-11" id="container-qc-technical-vis">
    <time-series-plot
      #tsplot
      [qcData]="qcData"
      [selectedMeasure]="selectedMeasure"
      [phenoInfoVariables]="phenoInfoVariables"
      [hidden]="!showSeries"
    >
    </time-series-plot>
    <strain-survey-plot
      #ssplot
      [qcData]="qcData"
      [selectedMeasure]="selectedMeasure"
      [phenoInfoVariables]="phenoInfoVariables"
      [hidden]="!showStrainSurvey"
    >
      >
    </strain-survey-plot>
  </div>

  <div *ngIf="status === 'loading'" class="col-md-11 text-center">
    <b>Loading measures. Please wait...</b>
    <br />
    <br />
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <div *ngIf="status !== 'complete' && status !== 'loading'" class="col-md-11 text-center">
    <b>Error</b>: {{ status }}
    <br />
    <br />
    Please reload the page and if the problem persists contact SIP technical team.
  </div>
</div>
