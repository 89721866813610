import { Component, Input, Output, EventEmitter, ElementRef, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { MatDialog } from '@angular/material/dialog';
import { buildDateTimeHtml, statusColors } from '../utils';
import { PersonService } from '../../services/person.service';
import { LogViewerDialogComponent } from '../dialogs/log-viewer-dialog.component';
import { ProjectActionsComponent } from '../project-actions/project-actions.component';

@Component({
  selector: 'timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent implements OnInit {
  @Input() reimportStatus = '';
  // project object we are building this timeline and header for (includes title input and other header links)
  @Input() project: any = { canEdit: true };

  // id for the project we are currently modifying (comes from the url)
  // (if this isn't set, then routes will be disabled)
  // NOTE: This may not always be the same as the projid in the project. For example, if the id is invalid, this
  //       can still be set and we want it for the error message.
  @Input() projid!: string;

  // true if the id iv invalid or the user doesn't have access to this project, otherwise false
  @Input() invalidID = false;

  // true if project is currently loading... show a loading progress bar
  @Input() loading = false;

  // true to show the loading progress bar below actions and set to a string to display as text above the loading
  // progress bar, null to not show the loading progress bar... used for project release, re-release, and re-import
  @Input() actionLoadingBar: any = null;

  // project.title. This has its own input because it's the only key in th project object that needs 2-way binding
  @Input() title = '';
  // allows 2-way binding of the project title. emitted when the title is changed
  @Output() titleChange: EventEmitter<any> = new EventEmitter<any>();
  // project.projsym. This has its own input because it's the only key in th project object that needs 2-way binding
  @Input() projsym = '';
  // allows 2-way binding of the project projsym. emitted when the projsym is changed
  @Output() projsymChange: EventEmitter<any> = new EventEmitter<any>();

  // Event that is emitted when the an external link is clicked (right now just the preview link).
  // The link to be gone to is sent in the event, and if the parent/state buttons handle any saving
  // required before going to it.
  @Output() clickExternalLink: EventEmitter<any> = new EventEmitter<any>();

  // Event that is emitted when the a page link is clicked if the project hasn't been created yet (but there is a title)
  // The link to be gone to (minus the '/' and projid) is sent in the event. The parent/state buttons handle
  // saving the project before going to the correct link (with the projid added) (this should only happen on the
  // proj-details page)
  @Output() clickPageLink: EventEmitter<any> = new EventEmitter<any>();

  // Event to trigger project release (after save)
  @Output() clickReleaseProject: EventEmitter<any> = new EventEmitter<any>();

  // Event to trigger project status change... event will contain status we are changing to
  @Output() clickChangeStatus: EventEmitter<any> = new EventEmitter<any>();

  // Event to trigger project re-import (for imported projects only)
  @Output() clickReimportProject: EventEmitter<any> = new EventEmitter<any>();

  // Event to trigger when import and export project action button is pressed
  @Output() clickImportExport: EventEmitter<any> = new EventEmitter<any>();

  // event that emits to let the parent component know that the project was restored
  @Output() projectRestored: EventEmitter<any> = new EventEmitter<any>();

  // event that emits to let the parent component know that the project was changed (currently just import)
  @Output() projectChanged: EventEmitter<any> = new EventEmitter<any>();

  // needed by the parent to bring focus to the title input
  @ViewChild('title_input_html') titleInput!: ElementRef;

  @ViewChild('stepper') stepper!: MatStepper;

  @ViewChild(ProjectActionsComponent) projectActionsComponent!: ProjectActionsComponent;

  // so that we can use them in the html...
  buildDateTimeHtml = buildDateTimeHtml;
  statusColors = statusColors;

  items: any[] = [
    { routePath: '/proj-details', name: 'PROJECT DETAILS' },
    { routePath: '/animal-info', name: 'ANIMAL PROFILE' },
    { routePath: '/procedures', name: 'PROCEDURES' },
    { routePath: '/definitions', name: 'DATA UPLOAD' },
    { routePath: '/genotypes', name: 'GENOTYPE INTAKE' },
    { routePath: '/project/qc-dashboard', name: 'DATA VALIDATION' },
    { routePath: '/curator-only', name: 'CURATOR CHECK' },
  ];

  // this is used as an input on the horizontal stepper to indicate the default selected step
  selectedIndex = 0;

  // used to determine the reason that the user cannot edit (email not verified or restricted)
  user: any = null;

  constructor(public router: Router, public dialog: MatDialog, private personService: PersonService) {}

  // on init, set active item and step
  ngOnInit() {
    this.setActive();

    this.personService.currentUser$.subscribe((result) => (this.user = result));
  }

  // remove restricted characters
  projsymKeyUp() {
    this.projsym = this.projsym.replace(/[^a-zA-Z0-9-]/g, '');
  }

  // figure out which item and step are active based on the route
  setActive() {
    for (let i = 0; i < this.items.length; i++) {
      // set active item
      if (this.router.url.indexOf(this.items[i].routePath) !== -1) {
        this.items[i].active = true;
        // sets the step we are currently on
        if (this.stepper) {
          this.stepper.selectedIndex = i;
        } else {
          this.selectedIndex = i;
        }
      } else {
        this.items[i].active = false;
      }
    }
  }

  // determine whether the page nav should be disabled for project editing
  // (used for the [linear] input on mat-horizontal-stepper, which if true in combination with the [completed]
  //  input being false on the current mat-step,which is currently always the case, disables selecting other steps)
  navDisabled() {
    return !this.project.projid && !(this.title ? this.title.trim() : false);
  }

  /**
   * On selection change of the stepper, triggers going to that page.
   *
   * @param event: stepper selection change event
   */
  selectionChange(event: any) {
    const i = event.selectedIndex;
    const goToPage = this.items[i];
    if (!goToPage.active && !goToPage.disabled) {
      this.clickPageLink.emit(goToPage.routePath + '/');
    }
  }

  // show a dialog with the history of changes made by users on this project
  viewHistory() {
    const entries = JSON.parse(JSON.stringify(this.project.projlogs));
    const dialogRef = this.dialog.open(LogViewerDialogComponent, {
      data: {
        header: 'Project History: ' + this.project.projsym,
        entries: entries,
        logtype: 'oneproj',
      },
    });
    dialogRef.afterClosed().subscribe();
  }

  // customizes the message to let the person know why they can't edit a project
  noEditMessage() {
    if (!this.user) {
      return (
        'You can view, but not edit this project. ' +
        'If you wish to edit this project, you must log into a user with edit access.'
      );
    }
    if (this.user.restricted) {
      return 'You can view, but not edit this project. Edit access to SIP is currently restricted.';
    }
    if (['Owner', 'Edit'].indexOf(this.project.current_user_permission) !== -1) {
      if (this.project.deletedtime) {
        return (
          'This project cannot be currently edited because it is archived. ' +
          (this.project.current_user_permission === 'Owner' ? 'You' : 'An owner') +
          ' must restore it before you can edit it.'
        );
      }
      if (!this.user.emailverified) {
        return 'Until you verify your email (instructions on the profile page), you can only view this project.';
      }
      // shouldn't hit this one... so just doing a generic message for it
      return 'You can currently only view this project.';
    }
    if (this.project.deletedtime) {
      return 'You can view, but not edit this project. Also, this project has been archived.';
    }
    return 'You can view, but not edit this project.';
  }
}
