<div class="col-sm-12 bottom-margin-1">
  <h5 *ngIf="!dataset">
    Please enter a name for the genotype dataset and select a primary investigator. Optionally, you may also enter a
    description and select a secondary contact if you wish.
  </h5>
</div>

<div class="col-sm-12 col-lg-6">
  <!-- Dataset name -->
  <div class="col-sm-12 align-left bottom-margin-1">
    <label for="dataset-name">
      Dataset Name
      <span *ngIf="editable">
        <input
          type="checkbox"
          class="dataset-checkbox"
          [checked]="datasetName === project.title"
          (change)="toggleProjectName($event.target)"
        />
        Use Project Title
      </span>
    </label>
    <input
      id="dataset-name"
      class="form-control"
      maxlength="255"
      [disabled]="!editable"
      [(ngModel)]="datasetName"
      placeholder="Name the genotype dataset (max 255 chars)"
    />
  </div>

  <!-- Dataset symbol -->
  <div *ngIf="dataset" class="col-sm-12 bottom-margin-1">
    <label for="dataset-symbol">Dataset Symbol (autogenerated)</label>
    <input id="dataset-symbol" class="form-control" readonly [(ngModel)]="dataset.symbol" />
  </div>

  <!-- Dataset investigator -->
  <div class="col-sm-12 col-lg-6 bottom-margin-1 align-left">
    <label for="dataset-investigator">Primary Investigator</label>
    <select
      id="dataset-investigator"
      class="form-control"
      [disabled]="!editable || !!dataset"
      [(ngModel)]="investigatorID"
    >
      <option *ngIf="investigatorID === -1" [value]="-1" disabled>Select a Primary Investigator</option>
      <option *ngFor="let inv of investigators" [value]="inv.id" [disabled]="inv.disabled">
        {{ inv.display_str }}{{ inv.disabled ? '*' : '' }}
      </option>
    </select>
  </div>

  <!-- Dataset secondary contact -->
  <div class="col-sm-12 col-lg-6 bottom-margin-1 align-left">
    <label for="dataset-secondContact">Secondary Contact {{ editable ? '(Optional)' : '' }}</label>
    <select [(ngModel)]="secondaryContactID" id="dataset-secondContact" class="form-control" [disabled]="!editable">
      <option [value]="-1">None</option>
      <option *ngFor="let contact of secondaryContacts" [value]="contact.id" [disabled]="contact.disabled">
        {{ contact.display_str }}{{ contact.disabled ? '*' : '' }}
      </option>
    </select>
  </div>
  <div *ngIf="hasInvalidInvestigators" class="col-sm-12">
    <p class="info-msg">
      *Note: At least one of the options for {{ !datasetID ? 'primary investigator and/or' : '' }} secondary contact are
      not able to be selected for this dataset due to a missing first and/or last name.
    </p>
  </div>
</div>

<div class="col-sm-12 col-lg-6">
  <!-- Dataset description -->
  <div class="col-sm-12 align-left bottom-margin-1">
    <label for="dataset-desc">
      Dataset Description {{ editable ? '(Optional)' : '' }}
      <span *ngIf="editable && project.description">
        <input
          type="checkbox"
          class="dataset-checkbox"
          [checked]="description === project.description"
          (change)="toggleProjectDesc($event.target)"
        />
        Use Project Description
      </span>
    </label>
    <textarea
      id="dataset-desc"
      class="form-control"
      maxlength="750"
      [disabled]="!editable"
      [(ngModel)]="description"
      placeholder="Describe the genotype dataset (max 750 chars)"
    >
    </textarea>
  </div>

  <!-- Dataset Status -->
  <ng-container *ngIf="datasetID && datasetCreated">
    <div class="col-sm-12" id="status-container">
      <div style="display: flex; flex-direction: column; justify-content: center">
        <div style="display: flex; flex-direction: row">
          <h5>
            <b>Dataset Status:</b>
            <span
              [ngStyle]="{
                color: status.statusColor,
                cursor: status.shouldHaveProcessingReport ? 'pointer' : 'initial'
              }"
              (click)="status.shouldHaveProcessingReport ? showProcessingReport() : null"
            >
              {{ status.statusDisplay }}
              <mat-icon *ngIf="status.shouldHaveProcessingReport" class="size-16">info_outline</mat-icon>
            </span>
          </h5>
          <mat-spinner *ngIf="status.isProcessing" color="accent" strokeWidth="3" diameter="22"> </mat-spinner>
        </div>
        <div *ngIf="status.haplotypeStatus" style="display: flex; flex-direction: row">
          <h5>
            <b>Haplotype Reconstruction:</b>
            <span
              [ngStyle]="{
                color: status.haplotypeStatusColor,
                cursor: status.shouldHaveHaplotypeReport ? 'pointer' : 'initial'
              }"
              (click)="status.shouldHaveHaplotypeReport ? showHaplotypeReconstructionReport() : null"
            >
              {{ status.haplotypeStatus }}
              <mat-icon *ngIf="status.shouldHaveHaplotypeReport" class="size-16">info_outline</mat-icon>
            </span>
          </h5>
          <mat-spinner *ngIf="status.haplotypeStatusColor === '#0277bd'" color="accent" strokeWidth="3" diameter="22">
          </mat-spinner>
        </div>
      </div>

      <ng-container *ngIf="!status.disconnected">
        <button
          *ngIf="(status.isProcessing || status.haplotypeStatus === 'RUNNING') && project.canEdit"
          mat-button
          matTooltip="Stop all running processes"
          (click)="cancel.emit()"
        >
          <mat-icon>cancel</mat-icon> Cancel
        </button>

        <button
          *ngIf="status.hasErrored && editable"
          mat-button
          matTooltip="Retry processing of dataset"
          (click)="retry.emit()"
        >
          <mat-icon>autorenew</mat-icon> Retry Processing
        </button>
      </ng-container>

      <button
        *ngIf="status.statusHistory.length && !status.disconnected"
        mat-button
        matTooltip="See all processing statuses for the dataset"
        (click)="showSubmissionHistory()"
      >
        <mat-icon>history</mat-icon> History
      </button>
    </div>
    <div class="col-sm-12 bottom-margin-1" style="display: flex; flex-direction: row">
      <ng-container *ngIf="status.disconnected">
        <h5 style="color: #dd8b4c; margin-right: 1rem">
          We encountered an issue with getting current status. Please refresh page or
          <span
            style="font-weight: bold; text-decoration: underline; color: #0277bd; cursor: pointer"
            (click)="getStatus()"
          >
            RECONNECT </span
          >.
        </h5>
      </ng-container>
    </div>
  </ng-container>

  <!-- Dataset Buttons -->
  <div *ngIf="datasetID" class="col-sm-12" id="button-container">
    <button
      *ngIf="detailsChanged && editable"
      mat-stroked-button
      color="accent"
      [disabled]="!datasetName"
      (click)="saveDetails()"
    >
      <mat-icon>check</mat-icon> Save Changes
    </button>

    <a *ngIf="wookie" mat-button color="primary" href="{{ wookie }}dataset/{{ datasetID }}" target="_blank">
      <mat-icon>launch</mat-icon> See Dataset
    </a>

    <button *ngIf="editable" mat-button style="color: #c00" (click)="deleteDataset()">
      <mat-icon>delete</mat-icon> Delete Dataset
    </button>
  </div>
</div>

<div *ngIf="!datasetID && editable" class="col-sm-12 align-center bottom-margin-4">
  <button
    mat-raised-button
    color="accent"
    [disabled]="!datasetName || investigatorID === -1"
    (click)="saveDetails()"
  >
    Create Dataset
  </button>
  <mat-divider></mat-divider>
</div>
