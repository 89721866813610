import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, from, Observable, of, throwError } from 'rxjs';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { catchError, concatMap, shareReplay, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

import { environment } from '../../environments/environment';

//
// This service is almost completely lifted from wookie and Auth0's documentation,
// filled in with the app's client ID and a little custom routing
// The one thing that I added was the getToken$ endpoint, which I needed for the interceptor
//
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // observable of Auth0 instance of client
  auth0Client$ = (from(
    createAuth0Client({
      domain: environment.authDomain,
      client_id: environment.authClientID,
      redirect_uri: `${window.location.origin}/callback`,
      audience: environment.authAudience,
    }),
  ) as Observable<Auth0Client>).pipe(
    shareReplay(1), // Every subscription receives the same shared value
    catchError((err) => throwError(err)),
  );

  // observable that tracks whether user is authenticated
  isAuthenticated$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.isAuthenticated())),
    tap((res) => {
      this.loggedIn = res;
    }),
  );

  // observable that gets the auth0 token
  getToken$: Observable<string> = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.getTokenSilently())),
  );

  // observable that, not surprisingly, handles the redirect callback
  handleRedirectCallback$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback())),
  );

  // subject of user profile data
  userProfileSubject$ = new BehaviorSubject<any>(null);

  // observable form of userProfileSubject$
  userProfile$ = this.userProfileSubject$.asObservable();

  // local property for login status
  loggedIn = false;

  constructor(private router: Router) {}

  /**
   * Returns an observable of the current user; options can be passed, more info
   * https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
   * @param {any} options - options, see URL above
   */
  getUser$(options?: any): Observable<any> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getUser(options))),
      tap((user) => {
        this.userProfileSubject$.next(user);
      }),
    );
  }

  /**
   * This should only be called on app initialization
   */
  localAuthSetup(): void {
    // set up local authentication streams
    const checkAuth$ = this.isAuthenticated$.pipe(
      concatMap((loggedIn: boolean) => {
        if (loggedIn) {
          // if authenticated, get user and set in app; pass options here if needed
          return this.getUser$();
        }
        // if not authenticated, return stream that emits 'false'
        return of(loggedIn);
      }),
    );
    checkAuth$.subscribe((response: { [key: string]: any } | boolean) => {
      // if authenticated, response will be user object, if not, response will be 'false'
      this.loggedIn = !!response;
    });
  }

  /**
   * Redirects user to Auth0 login page and, on success, can redirect to specified path after
   * successful login
   * @param {string} redirectPath - where to redirect to after login success (default: /dashboard)
   */
  login(redirectPath = '/dashboard'): void {
    // ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // call method to log in
      client.loginWithRedirect({
        redirect_uri: `${window.location.origin}/callback`,
        appState: { target: redirectPath },
      });
    });
  }

  /**
   * This should be called by the callback component when the app reloads after a user logs in from
   * Auth0 login page
   */
  handleAuthCallback(): void {
    const authComplete$: Observable<any[]> = this.handleRedirectCallback$.pipe(
      concatMap(() =>
        // redirect callback complete; get user and login status
        combineLatest([this.getUser$(), this.isAuthenticated$]),
      ),
    );
    // subscribe to authentication completion observable; response will be an array of user and
    // login status
    authComplete$.subscribe(([user, loggedIn]) => {
      // redirect to target route after callback processing
      this.loggedIn = !!loggedIn;
      this.router.navigate([user ? 'dashboard' : 'projects']);
    });
  }

  /**
   * Logs user out using Auth0 client
   */
  logout(): void {
    // ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // call method to log out
      client.logout({
        client_id: environment.authClientID,
        returnTo: `${window.location.origin}/projects`,
      });
    });
  }
}
