import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class StrainVendorService {
  // api url
  private api: string = environment.securedURLs.sip;

  // strain vendor behavioursubject for building an observable
  public vendors = new BehaviorSubject<any[]>([]);
  // observable, which can be subscribed to in order to get changes to the strain vendors list any time it is updated
  public vendors$ = this.vendors.asObservable();
  // flag as to whether the strain vendors list has been initialized yet
  private vendorsListInitialized = false;

  // declare http client
  constructor(private http: HttpClient) {
    if (!this.vendorsListInitialized) {
      this.vendorsListInitialized = true;
      this.getVendor().subscribe();
    }
  }

  /**
   * Get either a specific strain vendor or a list of all of them
   *
   * @param {number} vcode: vendor code for specific strain vendor to retrieve or null to get a list of all vendors
   * @returns {Observable<any>} Observable
   */
  getVendor(vcode: string | null = null): Observable<any> {
    let url = this.api + 'strains/strain-vendors';

    if (vcode) {
      url += '?vendorcode=' + vcode;
    }

    return this.http.get<any>(url).pipe(
      tap((result) => {
        if (!vcode) {
          this.vendorsListInitialized = true;
          // cache the list and trigger the vendors$ observable,
          // so that all subscribers get the updated list
          this.vendors.next(result);
        }
      }),
    );
  }
}
