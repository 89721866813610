<div class="form-horizontal">
  <div class="col-sm-6">
    <fieldset [disabled]="!profile.allow_changes">
      <div class="form-group">
        <label [for]="'first-name_' + uniqueTag" class="col-sm-4 control-label required">First Name</label>
        <div class="col-sm-8">
          <input
            [(ngModel)]="profile.firstname"
            type="text"
            [id]="'first-name_' + uniqueTag"
            maxlength="80"
            autocomplete="given-name"
            class="form-control"
            name="first-name"
            (change)="profile.firstname = profile.firstname.trim()"
          />
        </div>
      </div>

      <div class="form-group">
        <label [for]="'mid-init_' + uniqueTag" class="col-sm-4 control-label">Middle Initial</label>
        <div class="col-sm-8">
          <input
            [(ngModel)]="profile.middlename"
            type="text"
            class="form-control"
            [id]="'mid-init_' + uniqueTag"
            name="mid-init"
            style="width: 25%"
            maxlength="1"
          />
        </div>
      </div>

      <div class="form-group">
        <label [for]="'last-name_' + uniqueTag" class="col-sm-4 control-label required">Last Name</label>
        <div class="col-sm-8">
          <input
            [(ngModel)]="profile.lastname"
            type="text"
            autocomplete="family-name"
            maxlength="80"
            class="form-control"
            [id]="'last-name_' + uniqueTag"
            name="last-name"
            (change)="profile.lastname = profile.lastname.trim()"
          />
        </div>
      </div>

      <div class="form-group">
        <label [for]="'degree_' + uniqueTag" class="col-sm-4 control-label">Degree</label>
        <div class="col-sm-8">
          <select class="form-control" [(ngModel)]="profile.degree" [id]="'degree_' + uniqueTag" name="degree">
            <option value="PhD">PhD</option>
            <option value="MD">MD</option>
            <option value="MD, PhD">MD, PhD</option>
            <option value="DVM">DVM</option>
            <option value=""></option>
          </select>
        </div>
      </div>

      <div class="form-group">
        <label [for]="'email_' + uniqueTag" [class]="'col-sm-4 control-label' + (requireEmail ? ' required' : '')">
          <span *ngIf="profile.isuser">
            <span style="color: blue" matTooltip="This person is a user" class="glyphicon glyphicon-user"></span>
            <span
              *ngIf="profile.is_sso_user && profile.is_current_user"
              class="glyphicon glyphicon-star"
              style="color: orange"
              matTooltip="This person is a Single Sign On user"
            ></span>
          </span>
          <span *ngIf="profile.emailverified">
            <span style="color: green" matTooltip="User email verified" class="glyphicon glyphicon-ok-circle"></span>
          </span>
          <span *ngIf="invalidEmail">
            <span style="color: red" matTooltip="Invalid email" class="glyphicon glyphicon-warning-sign"></span>
          </span>
          Email
        </label>
        <div class="col-sm-8">
          <input
            [placeholder]="emailPlaceholder"
            maxlength="80"
            [(ngModel)]="profile.email"
            type="email"
            class="form-control"
            [id]="'email_' + uniqueTag"
            name="email"
            [disabled]="profile.isuser"
            autocomplete="email"
            (change)="profile.email = profile.email.trim()"
            [style.border-color]="invalidEmail ? 'red' : ''"
          />
        </div>
      </div>
    </fieldset>
  </div>
  <div class="col-sm-6">
    <div class="form-group">
      <label [for]="'institution_' + uniqueTag" style="white-space: nowrap" class="col-sm-4 control-label">
        Institution
      </label>
      <div class="col-sm-8">
        <institution-search
          [(institution)]="profile.institution"
          [disabled]="!profile.allow_changes"
          (institutionChange)="profile.inst = profile.institution.id || null"
        >
        </institution-search>
      </div>
    </div>

    <fieldset [disabled]="!profile.allow_changes">
      <!-- NOTE: Spelling city, country, and province-state in ids with l337 AND breaking up labels with invisible
                 spans in order to prevent annoying chrome autofill from going and popping up over our autocomplete
                 options (yes, chrome is that annoying about forcing their autocomplete) -->
      <div class="form-group">
        <label [for]="'c17y_' + uniqueTag" class="col-sm-4 control-label">
          Ci<span style="display: none">Not Visible</span>ty
        </label>
        <div class="col-sm-8">
          <input
            [(ngModel)]="profile.institution.city_no_state"
            type="text"
            [disabled]="true"
            class="form-control"
            [id]="'c17y_' + uniqueTag"
            [name]="'c17y_' + uniqueTag"
          />
        </div>
      </div>

      <div class="form-group">
        <label [for]="'pr0v1nc3-s7a73_' + uniqueTag" class="col-sm-4 control-label">
          Pro<span style="display: none">Not Visible</span>vince/St<span style="display: none">Not Visible</span>ate
        </label>
        <div class="col-sm-8">
          <input
            [(ngModel)]="profile.institution.state"
            type="text"
            [disabled]="true"
            class="form-control"
            [id]="'pr0v1nc3-s7a73_' + uniqueTag"
            [name]="'pr0v1nc3-s7a73_' + uniqueTag"
          />
        </div>
      </div>

      <div class="form-group">
        <label [for]="'c0un7ry_' + uniqueTag" class="col-sm-4 control-label">
          Cou<span style="display: none">Not Visible</span>ntry
        </label>
        <div class="col-sm-8">
          <input
            [(ngModel)]="profile.institution.country"
            type="text"
            [disabled]="true"
            class="form-control"
            [id]="'c0un7ry_' + uniqueTag"
            [name]="'c0un7ry_' + uniqueTag"
          />
        </div>
      </div>

      <div class="form-group">
        <label [for]="'orcid_' + uniqueTag" class="col-sm-4 control-label">
          ORCID
          <span class="text8">
            (<a target="_blank" [href]="'https://orcid.org' + (profile.orcid ? '/' + profile.orcid : '')"
              >https://orcid.org</a
            >)
          </span>
        </label>
        <div class="col-sm-8">
          <input
            [(ngModel)]="profile.orcid"
            type="text"
            class="form-control"
            [id]="'orcid_' + uniqueTag"
            name="orcid"
            maxlength="80"
          />
        </div>
      </div>
    </fieldset>
  </div>
  <fieldset [disabled]="!profile.allow_changes">
    <div align="center" *ngIf="profile.isuser">
      <div align="center" *ngIf="isCurator">
        <br />
        <label [for]="'approle_' + uniqueTag"> Application Role </label>
        &nbsp;&nbsp;
        <mat-radio-group
          [id]="'approle_' + uniqueTag"
          [name]="'approle_' + uniqueTag"
          [(ngModel)]="profile.approle"
          [disabled]="profile.is_current_user || (profile.approle === 'Reviewer' && !isReviewer)"
        >
          <mat-radio-button value="User">User</mat-radio-button>
          &nbsp;
          <mat-radio-button value="Curator">Curator</mat-radio-button>
          &nbsp;
          <mat-radio-button [disabled]="!isReviewer" value="Reviewer">Curator & Reviewer</mat-radio-button>
        </mat-radio-group>
      </div>
      <br />
    </div>
  </fieldset>
</div>

<div align="center">
  <a (click)="showEditors = !showEditors" style="cursor: pointer">
    <span *ngIf="!showEditors">
      <span class="glyphicon glyphicon-plus-sign"></span> View<span *ngIf="profile.allow_changes">/Edit</span> Editors
      for this Person
    </span>
    <span *ngIf="showEditors">
      <span class="glyphicon glyphicon-minus-sign"></span>
      Hide Editors for this Person
    </span>
  </a>
</div>

<div class="form-horizontal" *ngIf="showEditors">
  <br />
  <div class="col-sm-12">
    <div class="form-group" *ngIf="profile.allow_changes">
      <label class="col-sm-2 control-label">Add Editors</label>
      <div class="col-sm-8">
        <person-search
          #person_lookup
          [disableList]="profile.editors"
          [(person)]="personSearch"
          [user]="true"
          [showDialog]="false"
        ></person-search>
      </div>
      <div class="col-sm-2">
        <button
          *ngIf="personSearch.id"
          type="button"
          (click)="addEditor(); person_lookup.clearSelection()"
          matTooltip="Add Editor"
          class="btn btn-primary"
        >
          <span class="glyphicon glyphicon-plus"></span>
        </button>
      </div>
    </div>
    <div class="form-group" *ngIf="profile.editors.length === 0">
      <label class="col-sm-2 control-label">Editors</label>
      <div style="padding-top: 7px" class="col-sm-8">This person currently has no editors.</div>
    </div>
    <div class="form-group" *ngFor="let editor of profile.editors">
      <label class="col-sm-2 control-label">Editor</label>
      <div style="padding-top: 7px" class="col-sm-8">&nbsp;<person-display [person]="editor"></person-display></div>
      <div class="col-sm-2">
        <button
          type="button"
          class="btn btn-danger"
          matTooltip="Remove Editor"
          *ngIf="profile.owner"
          (click)="removeEditor(editor)"
        >
          <span class="glyphicon glyphicon-remove"></span>
        </button>
      </div>
    </div>
  </div>
  <br />
</div>
