// dialog for adding/editing/viewing strains on-the-fly
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'strain-dialog',
  template: `
    <div mat-dialog-content style="min-width:800px;">
      <edit-strain
        [(strain)]="data.strain"
        [embed]="true"
        (cancel)="dialogRef.close()"
        (strainChange)="dialogRef.close($event)"
      ></edit-strain>
    </div>
  `,
})
export class StrainDialogComponent {
  constructor(public dialogRef: MatDialogRef<StrainDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}
}
