// Angular imports
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// External imports
import { MarkdownModule } from 'ngx-markdown';

// Modules
import { AppRoutingModule } from './app-routing.module';
import { DataValidationModule } from './components/data-validation/data-validation.module';
import { GenotypeIntakeModule } from './components/genotype-intake/genotype-intake.module';
import { HelpModule } from './components/help/help.module';
import { MaterialModule } from './shared/material.module';
import { PipesModule } from './shared/pipes/pipes.module';
import { ProjectDetailsModule } from './components/project-details/project-details.module';
import { SharedModule } from './shared/shared.module';
import { SideNavModule } from './components/side-nav/side-nav.module';

// Services & interceptors
import { AuthService } from './services/auth.service';
import { BioconnectService } from './services/bioconnect.service';
import { CenterService } from './services/center.service';
import { DivDBService } from './services/divdb.service';
import { GroupService } from './services/group.service';
import { InstitutionService } from './services/institution.service';
import { InterventionService } from './services/intervention.service';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { MetadataService } from './services/metadata.service';
import { MethodologyService } from './services/methodology.service';
import { OntologyService } from './services/ontology.service';
import { PanelService } from './services/panel.service';
import { PersonService } from './services/person.service';
import { ProcedureService } from './services/procedure.service';
import { ProjectService } from './services/project.service';
import { PublicationService } from './services/publication.service';
import { R2Service } from './services/r2.service';
import { StrainService } from './services/strain.service';
import { StrainTypeService } from './services/strain-type.service';
import { StrainVendorService } from './services/strain-vendor.service';

// Components
import { AdminComponent } from './components/admin/admin.component';
import { AnimalInfoComponent } from './components/animal-info/animal-info.component';
import { AppComponent } from './app.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DataDefinitionsComponent } from './components/data-definitions/data-definitions.component';
import { GroupComponent } from './components/group/group.component';
import { GroupListingComponent } from './components/grouplisting/group-listing.component';
import { HeaderComponent } from './components/header/header.component';
import { ImportProjectComponent } from './components/import-project/import-project.component';
import { ProceduresComponent } from './components/procedures/procedures.component';
import { ProjectCurationComponent } from './components/project-curation/project-curation.component';
import { ProjectIsaDataComponent } from './components/data-definitions/project-isa-data/project-isa-data.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { SettingsComponent } from './components/settings/settings.component';
import { ShareProjectComponent } from './components/share-project/share-project.component';
import { TasksComponent } from './components/tasks/tasks.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';

@NgModule({
  declarations: [
    AdminComponent,
    AnimalInfoComponent,
    AppComponent,
    DashboardComponent,
    DataDefinitionsComponent,
    GroupComponent,
    GroupListingComponent,
    HeaderComponent,
    ImportProjectComponent,
    ProceduresComponent,
    ProjectCurationComponent,
    ProjectIsaDataComponent,
    ProjectsComponent,
    SettingsComponent,
    ShareProjectComponent,
    TasksComponent,
    UserProfileComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    DataValidationModule,
    FormsModule,
    GenotypeIntakeModule,
    HelpModule,
    HttpClientModule,
    MarkdownModule.forRoot({ loader: HttpClient }),
    MaterialModule,
    ProjectDetailsModule,
    SharedModule,
    SideNavModule,
    PipesModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    AuthService,
    BioconnectService,
    CenterService,
    DivDBService,
    GroupService,
    InstitutionService,
    InterventionService,
    MetadataService,
    MethodologyService,
    PanelService,
    PersonService,
    ProcedureService,
    ProjectService,
    PublicationService,
    OntologyService,
    R2Service,
    StrainService,
    StrainTypeService,
    StrainVendorService,
  ],
  bootstrap: [AppComponent],
  // testing purposes
  exports: [
    AdminComponent,
    AnimalInfoComponent,
    AppComponent,
    DashboardComponent,
    DataDefinitionsComponent,
    GroupComponent,
    GroupListingComponent,
    HeaderComponent,
    ImportProjectComponent,
    ProceduresComponent,
    ProjectCurationComponent,
    ProjectIsaDataComponent,
    ProjectsComponent,
    SettingsComponent,
    ShareProjectComponent,
    TasksComponent,
    UserProfileComponent,
  ],
})
export class AppModule {}
