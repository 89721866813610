import { Component, OnInit, ViewChild } from '@angular/core';
import { ProjectService } from '../../services/project.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { FileUploadComponent } from '../../shared/file-upload/file-upload.component';
import { StrainService } from '../../services/strain.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { buildListTooltip } from '../../shared/utils';
import { StateButtonsComponent } from '../../shared/state-buttons/state-buttons.component';
import { MatDialog } from '@angular/material/dialog';
import { buildDateTimeHtml, forceRange, range } from '../../shared/utils';
import { ConfirmationDialogComponent } from '../../shared/dialogs/confirmation-dialog.component';
import { DynamicButtonDialogComponent } from '../../shared/dialogs/dynamic-button-dialog.component';
import { TooltipPosition } from '@angular/material/tooltip';

@Component({
  selector: 'data-definitions',
  templateUrl: './data-definitions.component.html',
  styleUrls: ['./data-definitions.component.scss'],
})
export class DataDefinitionsComponent implements OnInit {
  // tooltip position
  position: TooltipPosition = 'after';

  // variable for accessing the fileupload child component in this parent component's typescript
  @ViewChild(FileUploadComponent) fileUpload?: FileUploadComponent;

  // used to trigger saving of data definitions before going to the process data step
  @ViewChild(StateButtonsComponent) stateButtons!: StateButtonsComponent;

  // error message from the processing endpoint
  processErrorMessage = '';
  // messages on successful processing (or at least not an error)
  processMessages: string[] = [];
  // flag to show or hide the extra messages from a successful processing
  showProcessMessages = true;
  // flag that is set while data is being processed
  processingData = false;
  // number of measures we are analyzing, which also serves as a flag that measures are currently being analyzed
  analyzeNum = 0;
  // number of measures that have completed analyzing
  analyzeCompletedNum = 0;
  // url for previewing data
  previewURL = environment.securedURLs.mpdPreview;
  // true to show new showDataNodeNetworkFeature
  showDataNodeNetworkFeature = environment.showDevelopmentFeatures;
  // what do to with current measures when we upload data
  // ('delete' all, 'replace' duplicate varnames, or otherwise leave existing measure alone)
  currentmeasures = '';

  // project id in the route
  projid: any = '';
  // flag that the project id was invalid or the user doesn't have access
  invalidID = false;
  // project object that is displayed in the page (and edited if the user make modifications)
  project: any = { datadefs: [], dataformat: { methodology: {} }, pheno_measures: [], isa_data: [] };
  // project object that represents the state of the project as of the last import (user to compare with
  // the project object when saving/exporting in order to determine what has changed)
  dbProject: any = { datadefs: [], dataformat: { methodology: {} }, pheno_measures: [], isa_data: [] };

  // keep track of previous values of project.datadefs and project.pheno_measures so that onChange of a
  // variable name, we have the previous value of the variable name to compare against other variable names
  // (looking for matches to make suggestions of other variable names to change)
  oldVarnames: any[] = [];
  oldDatadefVarnames: any[] = [];
  // set to true when someone selects "No, don't ask again" when a matching variable name is found on change
  disableCheckForMatchingVarnames = false;

  // pagination variables... for variables list (measures, etc)
  currentPage = 1;
  // value on the input element for current page
  // (so that the currentPage doesn't end up with a value that's too large or below 1 before it can be corrected)
  currentPageIn = 1;
  maxPage = 1;
  pageSize = 5;
  // value on the input select for pageSize (so that the numeric pageSize doesn't end up being 'all')
  pageSizeIn: any = 5;

  // options for some measure fields (they are populated by api calls)
  unitsOptions: string[] = [];
  seriesTypeOptions: any[] = [];
  panelOptions: string[] = [];

  // hide entry fields until project is done loading
  loadingProject = false;

  // while the user is editing a varname, this is set to the index of the varname that they are editing
  editingVarname: number | null = null;
  editingSVarname: number | null = null;
  bulkUpdate: any = {
    methodology: {},
    intervention_rel: {},
    ontologies: [],
    meas_procs: [],
    measnums: [],
    applyTo: 'all',
  };

  // index of the row we are currently moving (switching the order)
  orderSwitchIndex: any = null;

  // for click-drag multi-selecting, this is populated with the property that we are multi-selecting
  // this also behaves as a flag to indicate when we are multi-select click-dragging.
  clickDrag = '';
  // value we are populating the property for rows with on the click-drag multi-select
  clickDragVal: any = null;
  // starting index for the click-drag. currently only used to make sure we aren't populating rows
  // above the one where we started for series
  clickDragStartIndex: any = null;
  // index of the last row that had click-drag handling performed...
  // used to determine if any rows were skipped because the user is dragging too quickly,
  // so that we can recursively handle the indeces for the skipped rows (in the correct order)
  // NOTE: This is used both for multi-select and row switching/moving click-drag logic
  clickDragLastHandled: any = null;

  // flag that we're dragging down the series_parent_column value of the selected cell
  seriesArrowClick = false;

  // so that we can use it in the html...
  buildDateTimeHtml = buildDateTimeHtml;
  forceRange = forceRange;
  range = range;
  Math = Math;

  constructor(
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private strainService: StrainService,
    public router: Router,
    private http: HttpClient,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.strainService.strains$.subscribe(() => {
      this.checkBuildDefaultValMappings();
    });
    // oninit of the component, load the project from the id in the route (this component requires an id,
    // unlike proj-details, which can be used to create a new project if one isn't passed in through the route)
    this.projid = this.route.snapshot.paramMap.get('id') || null;
    if (Number(this.projid)) {
      this.getProject();

      // populate options for some measure fields
      this.http.get<string[]>(environment.securedURLs.sip + 'units').subscribe((result) => {
        this.unitsOptions = result;
      });
      this.http.get<any[]>(environment.securedURLs.sip + 'seriestypes').subscribe((result) => {
        this.seriesTypeOptions = result;
      });
      this.http.get<any[]>(environment.securedURLs.sip + 'panels').subscribe((result) => {
        this.panelOptions = result.map((panel) => panel.panelsym);
      });
    } else {
      this.invalidID = true;
    }
  }

  // get the project from the api
  getProject() {
    this.loadingProject = true;

    if (this.projid) {
      this.projectService.getProject(this.projid).subscribe(
        (data) => {
          this.invalidID = data.table_form;
          if (!this.invalidID) {
            this.project = data;
            // I'm not sure if setting both to data would result in them just pointing to the same object,
            // so I'm making sure that it's a deep copy just in case
            this.dbProject = JSON.parse(JSON.stringify(data));
            this.recalcPages();
          }
          this.loadingProject = false;
        },
        () => {
          this.invalidID = true;
          this.loadingProject = false;
        },
      );
    }
  }

  // logic for running an enpoint which processes the data and data definitions
  // into the public schema format using the schemapop service
  processData() {
    this.processMessages = [];
    if (!this.processingData) {
      this.processingData = true;
      this.stateButtons.saveProject().subscribe(
        () => {
          this.processErrorMessage = this.cannotProcessMessage();
          if (!this.processErrorMessage) {
            setTimeout(() => {
              this.projectService.processProjectData(this.projid, this.currentmeasures).subscribe(
                (result) => {
                  if (result.dataformat && result.dataformat.projid) {
                    this.project.dataformat = result.dataformat;
                    this.dbProject.dataformat = JSON.parse(JSON.stringify(result.dataformat));
                  }
                  if (result.pheno_measures) {
                    this.project.factors = result.factors;
                    this.dbProject.factors = JSON.parse(JSON.stringify(result.factors));
                  }
                  if (result.pheno_measures) {
                    this.project.pheno_measures = result.pheno_measures;
                    this.dbProject.pheno_measures = JSON.parse(JSON.stringify(result.pheno_measures));
                    this.recalcPages();
                  }
                  if (result.updatedtime) {
                    this.project.updatedtime = this.dbProject.updatedtime = result.updatedtime;
                  }
                  if (result.projlogs) {
                    this.project.projlogs = result.projlogs;
                    this.dbProject.projlogs = JSON.parse(JSON.stringify(result.projlogs));
                  }
                  this.project.changesincereleased = this.dbProject.changesincereleased = result.changesincereleased;
                  this.processMessages = result.messages;
                  this.analyzeCompletedNum = 0;
                  this.analyzeNum = result.new_measnums.length > 0 ? result.new_measnums.length : null;
                  this.processingData = false;
                  // analyze the new measures 1 at a time and update the progress count & bar as we go
                  for (const measnum of result.new_measnums) {
                    this.projectService.analyzeMeasure(measnum).subscribe(
                      () => {
                        this.analyzeCompletedNum += 1;
                        if (this.analyzeCompletedNum === this.analyzeNum) {
                          this.analyzeNum = 0;
                          this.analyzeCompletedNum = 0;
                        }
                      },
                      (error) => {
                        this.processMessages.push(error.error.message ? error.error.message : error.message);
                        this.analyzeCompletedNum += 1;
                        if (this.analyzeCompletedNum === this.analyzeNum) {
                          this.analyzeNum = 0;
                          this.analyzeCompletedNum = 0;
                        }
                      },
                    );
                  }
                },
                (error) => {
                  this.processErrorMessage = error.error.message ? error.error.message : error.message;
                  this.processingData = false;
                },
              );
            }, 1500);
          } else {
            this.processingData = false;
          }
        },
        () => {
          this.processErrorMessage = 'Unable to process the data because an error occurred saving the project.';
          this.processingData = false;
        },
      );
    }
  }

  /**
   * Check the front end for errors that we want to catch being sending data to the back-end. There is some
   * overlap with the back end here, but there are some things in each that are not in the other. The main reason
   * for these checks in the front end are because I want to catch where the user has entered new strains
   * (because it's easier to do in the front end where we already have
   * a list of options cached and can just compare input values with the options)
   *
   * @returns {string}: error string to display rather than attempting to process the data
   */
  cannotProcessMessage(): string {
    // strain issues
    if (!this.setCheckZero(this.project.dataformat.straincol)) {
      return 'Please select a Strain Column for this project.';
    }
    return '';
  }

  /**
   * Recalculate and adjust the pageSize, maxPage and currentPage values
   * based on the number of measures, pageSizeIn, and currentPageIn values
   */
  recalcPages() {
    this.pageSize = this.pageSizeIn === 'all' ? this.project.pheno_measures.length : this.pageSizeIn;
    this.maxPage =
      this.pageSizeIn === 'all' ? 1 : Math.max(1, Math.ceil(this.project.pheno_measures.length / this.pageSize));
    this.currentPageIn = this.currentPage = this.currentPageIn > this.maxPage ? this.maxPage : this.currentPageIn;
    this.currentPageIn = this.currentPage = this.currentPageIn < 1 ? 1 : this.currentPageIn;
    this.savePreviousVarnames();
  }

  /**
   * Determine whether the 'Apply Update' for Bulk Update button should be available
   * Metadata fields must be populated, and variables to update must be selected for the button to be active.
   *
   * @returns {boolean}: true to disable the 'Apply Update' button, false to not
   */
  applyBulkUpdateDisabled(): boolean {
    if (this.clearBulkUpdateDisabled(true)) {
      return true;
    }
    return this.bulkUpdate.applyTo === 'selected' && this.bulkUpdate.measnums.length === 0;
  }

  /**
   * Determine whether the 'Clear Selections' for Bulk Update button should be available
   * At least 1 metadata field must be populated.
   *
   * @param {boolean} ignoreSelectedMeasures: true to remove 'measnums' and 'applyTo' from the comparison,
   *                                            false to not (false by default)
   * @returns {boolean}: true to disable the 'Clear Selections' button, false to not
   */
  clearBulkUpdateDisabled(ignoreSelectedMeasures: boolean = false): boolean {
    const bulkUpdateCopy = JSON.parse(JSON.stringify(this.bulkUpdate));
    let compareTo: any = { ontologies: [], meas_procs: [], measnums: [], applyTo: 'all' };
    if (ignoreSelectedMeasures) {
      delete bulkUpdateCopy.measnums;
      delete bulkUpdateCopy.applyTo;
      compareTo = { ontologies: [], meas_procs: [] };
    }
    // remove keys from the copy we're using to compare if they aren't populated
    for (const key of [
      'is_measure',
      'is_covar',
      'private',
      'method',
      'intervention',
      'seriestype',
      'paneldesc',
      'units',
      'magnitude',
      'ageweeks',
      'projyear',
      'mpdsector',
      'download_fileid'
    ]) {
      if (Object.prototype.hasOwnProperty.call(bulkUpdateCopy, key) && !bulkUpdateCopy[key]) {
        delete bulkUpdateCopy[key];
      }
    }
    if (
      !Object.prototype.hasOwnProperty.call(bulkUpdateCopy, 'method') &&
      Object.prototype.hasOwnProperty.call(bulkUpdateCopy, 'methodology')
    ) {
      delete bulkUpdateCopy.methodology;
    }
    if (
      !Object.prototype.hasOwnProperty.call(bulkUpdateCopy, 'intervention') &&
      Object.prototype.hasOwnProperty.call(bulkUpdateCopy, 'intervention_rel')
    ) {
      delete bulkUpdateCopy.intervention_rel;
    }
    return JSON.stringify(bulkUpdateCopy) === JSON.stringify(compareTo);
  }

  /**
   * Applies the populated metadata fields in the Bulk Update section to the selected variables.
   * Only apply populated values (cannot currently be used to clear values). Keeps track
   * of how many variables are found/selected and how many actually end up being updated.
   */
  applyBulkUpdate() {
    let numFound = 0;
    let numUpdated = 0;
    for (let i = 0; i < this.project.pheno_measures.length; i++) {
      if (
        this.bulkUpdate.applyTo === 'all' ||
        this.bulkUpdate.measnums.indexOf(this.project.pheno_measures[i].measnum) !== -1
      ) {
        numFound++;
        let updated = false;
        if (
          this.bulkUpdate.seriestype &&
          this.project.pheno_measures[i].seriestype &&
          this.bulkUpdate.seriestype !== this.project.pheno_measures[i].seriestype
        ) {
          this.project.pheno_measures[i].seriestype = this.bulkUpdate.seriestype;
          updated = true;
        }
        if (this.bulkUpdate.mpdsector && this.project.pheno_measures[i].mpdsector !== this.bulkUpdate.mpdsector) {
          this.project.pheno_measures[i].mpdsector = this.bulkUpdate.mpdsector;
          updated = true;
        }
        if (this.bulkUpdate.is_measure && !this.project.pheno_measures[i].is_measure) {
          this.project.pheno_measures[i].is_measure = this.bulkUpdate.is_measure;
          updated = true;
        }
        if (
          this.bulkUpdate.is_covar &&
          this.project.pheno_measures[i].datatype !== 'categorical' &&
          !this.project.pheno_measures[i].is_covar
        ) {
          this.project.pheno_measures[i].is_covar = this.bulkUpdate.is_covar;
          updated = true;
        }
        if (this.bulkUpdate.private && !this.project.pheno_measures[i].private) {
          this.project.pheno_measures[i].private = this.bulkUpdate.private;
          updated = true;
        }
        if (this.bulkUpdate.units && this.project.pheno_measures[i].units !== this.bulkUpdate.units) {
          this.project.pheno_measures[i].units = this.bulkUpdate.units;
          updated = true;
        }
        if (this.bulkUpdate.projyear && this.project.pheno_measures[i].projyear !== this.bulkUpdate.projyear) {
          this.project.pheno_measures[i].projyear = this.bulkUpdate.projyear;
          updated = true;
        }
        if (this.bulkUpdate.ageweeks && this.project.pheno_measures[i].ageweeks !== this.bulkUpdate.ageweeks) {
          this.project.pheno_measures[i].ageweeks = this.bulkUpdate.ageweeks;
          updated = true;
        }
        if (
          (this.bulkUpdate.magnitude || this.bulkUpdate.magnitude === 0) &&
          this.project.pheno_measures[i].magnitude !== this.bulkUpdate.magnitude
        ) {
          this.project.pheno_measures[i].magnitude = this.bulkUpdate.magnitude;
          updated = true;
        }
        if (this.bulkUpdate.paneldesc && this.project.pheno_measures[i].paneldesc !== this.bulkUpdate.paneldesc) {
          this.project.pheno_measures[i].paneldesc = this.bulkUpdate.paneldesc;
          updated = true;
        }
        if (
          this.bulkUpdate.intervention &&
          this.project.pheno_measures[i].intervention !== this.bulkUpdate.intervention
        ) {
          this.project.pheno_measures[i].intervention = this.bulkUpdate.intervention;
          this.project.pheno_measures[i].intervention_rel = this.bulkUpdate.intervention_rel;
          updated = true;
        }
        if (this.bulkUpdate.method && this.project.pheno_measures[i].method !== this.bulkUpdate.method) {
          this.project.pheno_measures[i].method = this.bulkUpdate.method;
          this.project.pheno_measures[i].methodology = this.bulkUpdate.methodology;
          updated = true;
        }
        if (
          this.bulkUpdate.download_fileid &&
          this.project.pheno_measures[i].download_fileid !== this.bulkUpdate.download_fileid) {
          this.project.pheno_measures[i].download_fileid = this.bulkUpdate.download_fileid;
          updated = true;
        }
        if (this.bulkUpdate.ontologies.length > 0) {
          for (let j = 0; j < this.bulkUpdate.ontologies.length; j++) {
            let found = false;
            const id = this.bulkUpdate.ontologies[j].id;
            for (let o = 0; o < this.project.pheno_measures[i].ontologies.length; o++) {
              if (this.project.pheno_measures[i].ontologies[o].id === id) {
                found = true;
                break;
              }
            }
            if (!found) {
              this.project.pheno_measures[i].ontologies.push(this.bulkUpdate.ontologies[j]);
              updated = true;
            }
          }
        }
        if (this.bulkUpdate.meas_procs.length > 0) {
          for (let j = 0; j < this.bulkUpdate.meas_procs.length; j++) {
            let found = false;
            const id = this.bulkUpdate.meas_procs[j].proc_id;
            for (let o = 0; o < this.project.pheno_measures[i].meas_procs.length; o++) {
              if (this.project.pheno_measures[i].meas_procs[o].proc_id === id) {
                found = true;
                break;
              }
            }
            if (!found) {
              this.project.pheno_measures[i].meas_procs.push(this.bulkUpdate.meas_procs[j]);
              updated = true;
            }
          }
        }
        if (updated) {
          numUpdated++;
        }
      }
    }
    // let the user know the results
    this.stateButtons.flashMessage(
      'Updated ' + numUpdated + ' variables.',
      numUpdated > 0 ? 'alert-success' : 'alert-warning',
      numFound > numUpdated ? 10000 : 2000,
      numFound > numUpdated
        ? [
            String(numFound - numUpdated) +
              ' variables were not updated ' +
              'because they either already had the selected values or were ineligible for the selected values.',
          ]
        : [],
    );
  }

  // returns true if a file has been uploaded, false otherwise
  fileUploaded(): boolean {
    if (this.fileUpload) {
      return this.fileUpload?.uploadedFiles?.filter((value) => !value.deletedtime).length > 0;
    }

    return false;
  }

  // returns a list of the file column values in the currently uploaded file
  fileCols() {
    return this.fileUploaded()
      ? this.fileUpload?.uploadedFiles.filter((value) => {
          return !value.deletedtime;
        })[0].columns
      : [];
  }

  /**
   * Get the array of unique values for the column at the passed-in index, if they exis
   *
   * @param {number} index: file column index
   * @returns {Array} array of values or empty array
   */
  fileColVals(index: number): string[] {
    return Number(index) < this.fileCols().length
      ? this.fileUpload?.uploadedFiles.filter((value) => {
          return !value.deletedtime;
        })[0].columns[Number(index)].values
      : [];
  }

  // on update of a file (happens when file column values are retrieved),
  // re-run the default value population logic
  onFileUpdate() {
    if (this.setCheckZero(this.project.dataformat.straincol) || this.setCheckZero(this.project.dataformat.sexcol)) {
      // handle getting needed column values and doing default mapping on them
      this.defaultValMappings();
    }
  }

  /**
   * Build the rows in the data defs widget based on the columns in the uploaded file.
   * We make initial guesses on which column contains the strain, sex, or id based on column headers.
   *
   * @param {boolean} initialBuild: True if this is the initial build after uploading the data file,
   *                                 triggers population of default strain and sex mapping values after
   *                                 we're done if True
   */
  buildDataDefs(initialBuild = false) {
    const cols = this.fileCols();
    const ddLength = this.project.datadefs.length;
    if (initialBuild && ddLength > 0) {
      // if there are data column annotations from a previously uploaded file, then
      // preserve them as much as we're able to
      const lengthDiff = this.project.datadefs.length - cols.length;
      if (lengthDiff > 0) {
        this.project.datadefs = this.project.datadefs.slice(0, cols.length);
        if (this.project.dataformat.sexcol >= cols.length) {
          this.project.dataformat.sexcol = null;
        }
        if (this.project.dataformat.straincol >= cols.length) {
          this.project.dataformat.straincol = null;
        }
        if (this.project.dataformat.idcol >= cols.length) {
          this.project.dataformat.idcol = null;
        }
        this.clearFileColumns(cols.length - 1);
      }
      // show a message letting the user know what happened...
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          header: 'Data Column Annotations',
          message:
            'Your data column annotations from the previously uploaded file were left unchanged.<br>' +
            (lengthDiff > 0
              ? 'The one exception is that there are <b>' +
                lengthDiff +
                '</b> less columns in ' +
                'the new file than there were in the previous file,<br>so that number of columns were removed from ' +
                'the end of the table.<br>'
              : lengthDiff < 0
              ? 'In addition, this new file has <b>' +
                (0 - lengthDiff) +
                '</b> more columns ' +
                'than the previous one did,<br>so that many new columns were added to the end of the table.<br>'
              : '') +
            'If you wish to reset the data column annotations, then you can click the green refresh ' +
            'button to the top left of the table.<br>Note: Resetting the table is recommended if the column order ' +
            'or number of columns have changed from the previous file.',
          hidefalsebtn: true,
          truelabel: 'Close',
          truebtn: 'btn-default',
        },
      });
      dialogRef.afterClosed().subscribe();
    } else {
      this.project.datadefs = [];
      this.project.dataformat.sexcol = null;
      this.project.dataformat.straincol = null;
      this.project.dataformat.idcol = null;
      this.clearFileColumns();
    }
    for (let i = this.project.datadefs.length; i < cols.length; i++) {
      const colName = this.varnameClean(cols[i].name);
      const colRow: any = {
        column: i,
        varname: colName,
        s_varname: colName,
        datatype: cols[i].all_numeric ? 'Numeric' : 'Categorical',
      };
      // usually id, sex, and strain should in the early columns, so stop trying after 8
      if (i < 8) {
        if (
          !this.setCheckZero(this.project.dataformat.idcol) &&
          this.project.dataformat.granularity === 'animal' &&
          (colName.toLowerCase().indexOf('id') !== -1 ||
            colName.toLowerCase().indexOf('mouse') !== -1 ||
            colName.toLowerCase().indexOf('mouse') !== -1 ||
            colName.toLowerCase().indexOf('animal') !== -1 ||
            colName.toLowerCase().indexOf('subject') !== -1)
        ) {
          colRow.param = 'ID';
          colRow.datatype = 'Categorical';
          this.project.dataformat.idcol = i;
        } else if (
          !this.setCheckZero(this.project.dataformat.straincol) &&
          colName.toLowerCase().indexOf('strain') !== -1
        ) {
          colRow.param = 'Strain';
          colRow.datatype = 'Categorical';
          this.project.dataformat.straincol = i;
        } else if (!this.setCheckZero(this.project.dataformat.sexcol) && colName.toLowerCase().indexOf('sex') !== -1) {
          colRow.param = 'Sex';
          colRow.datatype = 'Categorical';
          this.project.dataformat.sexcol = i;
        } else {
          colRow.is_phenotype = true;
        }
      } else {
        colRow.is_phenotype = true;
      }
      this.project.datadefs.push(colRow);
    }
    this.savePreviousVarnames();
    this.checkBuildDefaultValMappings(!initialBuild);
  }

  /**
   * If the data definitions/dictionary are reset such that datadef columns are removed, then this should be called to
   * clear the file_column values in pheno_measures and pheno_series because we can't be sure it will match up anymore.
   *
   * NOTE: If only some columns are removed, then we only need to remove the ones on the end.
   *
   * @param {number} maxColumn: new maximum column index, preserve file_column values below or equal to this maximum
   *
   */
  clearFileColumns(maxColumn: number = -1) {
    for (let i = 0; i < this.project.pheno_measures.length; i++) {
      if (this.project.pheno_measures[i].file_column > maxColumn) {
        this.project.pheno_measures[i].file_column = null;
      }
      for (let j = 0; j < this.project.pheno_measures[i].pheno_series.length; j++) {
        if (this.project.pheno_measures[i].pheno_series[j].file_column > maxColumn) {
          this.project.pheno_measures[i].pheno_series[j].file_column = null;
        }
      }
    }
  }

  /**
   * Swap two adjacent rows in the datadefs
   *
   * @param {number} i: index of the one row to switch
   * @param {number} j: index of the other row to switch
   */
  swapDataDefs(i: number, j: number) {
    // only allow switching adjacent rows
    if (j === i - 1 || j === i + 1) {
      const val = this.project.datadefs[i];
      this.project.datadefs[i] = this.project.datadefs[j];
      this.project.datadefs[j] = val;
      // make sure i is lower (for special logic around series)
      if (i > j) {
        const a = i;
        i = j;
        j = a;
      }
      const iSeries = this.project.datadefs[i].series_parent_column;
      const jSeries = this.project.datadefs[j].series_parent_column;
      // specil logic for swapping rows at the beginning or end of a series
      if (this.isSeriesParent(i)) {
        this.setProp(j, 'series_parent_column', this.project.datadefs[i].series_parent_column);
      } else if (this.isSeriesParent(j)) {
        // set prop would remove all pointers to this series, so just directly removing it from the series
        this.project.datadefs[i].series_parent_column = null;
      } else if (!this.setCheckZero(iSeries) && this.setCheckZero(jSeries)) {
        this.setProp(i, 'series_parent_column', jSeries);
        this.setProp(j, 'series_parent_column', null);
      }
      this.savePreviousVarnames();
    }
  }

  /**
   * Logic run on mouse entry of a datadef row.
   * Includes handling for click-drag multi-selecting and click-dragging to move rows.
   *
   * @param {number} i: index the mouse just entered
   */
  handleClickDrag(i: number) {
    // sometimes this can activate twice in a row on the same index, just ignore it if so
    // also, make sure that we are doing a click-drag and the index is valid
    if (i < this.project.datadefs.length && !this.checkNumbersEqual(this.clickDragLastHandled, i)) {
      // if the user is dragging really fast, recursively handle any indeces that were skipped before handling this one
      if (this.setCheckZero(this.clickDragLastHandled)) {
        if (i < this.clickDragLastHandled && !this.checkNumbersEqual(this.clickDragLastHandled, i + 1)) {
          this.handleClickDrag(i + 1);
        } else if (i > this.clickDragLastHandled && !this.checkNumbersEqual(this.clickDragLastHandled, i - 1)) {
          this.handleClickDrag(i - 1);
        }
      }
      // click-drag to select multiple rows
      if (this.clickDrag) {
        // if we are click-dragging on the series column, don't allow population of rows above the
        // row where we started
        if (!(this.clickDrag === 'series_parent_column' && i < this.clickDragStartIndex)) {
          this.setProp(i, this.clickDrag, this.clickDragVal);
        }
      } else if (this.setCheckZero(this.orderSwitchIndex)) {
        // click-drag to move rows (change order)
        // only allow switching adjacent rows
        if (
          this.checkNumbersEqual(this.orderSwitchIndex, i - 1) ||
          this.checkNumbersEqual(this.orderSwitchIndex, i + 1)
        ) {
          this.swapDataDefs(this.orderSwitchIndex, i);
          this.orderSwitchIndex = i;
        } else if (!this.checkNumbersEqual(this.orderSwitchIndex, i)) {
          this.orderSwitchIndex = null;
        }
      }
      this.clickDragLastHandled = i;
    }
  }

  /**
   * When clicking (mouse down) in a column with click-drag multi-selecting enabled, then this function is called to
   * begin the click-drag multi-select.
   *
   * @param {number} i: datadefs index
   * @param {string} prop: datadef property
   */
  handleMouseDown(i: number, prop: string) {
    if (!this.propDisabled(i, prop)) {
      let val: any = this.project.datadefs[i][prop];
      if (prop === 'series_parent_column') {
        if (!this.setCheckZero(val)) {
          val = this.project.datadefs[i].column;
        } else if (!this.seriesArrowClick) {
          val = null;
        }
      } else if (prop === 'datatype') {
        val = val === 'Categorical' ? 'Numeric' : 'Categorical';
      } else if (prop !== 'n_mice') {
        val = !val;
      }
      this.clickDragStartIndex = i;
      this.clickDragLastHandled = i;
      this.clickDragVal = val;
      this.setProp(i, prop, val);
      this.clickDrag = prop;
    }
    this.seriesArrowClick = false;
  }

  /**
   * Handle updating of the passed-in property at the passed-in index of datadefs with the passed-in value,
   * along with special case handling logic.
   *
   * @param {number} i: datadefs index
   * @param {string} prop: datadef property
   * @param val: value to set the property to
   * @param {boolean} ignoreDisabled: true to still make the change even if the field is disabled...
   *                                  used to allow changes to be made to properties in the code in cases where
   *                                  a field becomes disabled and needs to be cleared out at the same time
   */
  setProp(i: number, prop: string, val: any, ignoreDisabled: boolean = false) {
    if ((ignoreDisabled || !this.propDisabled(i, prop)) && i < this.project.datadefs.length) {
      const currentSeriesVal = this.project.datadefs[i].series_parent_column;
      this.project.datadefs[i][prop] = val;
      if (prop === 'series_parent_column') {
        if (this.setCheckZero(val)) {
          // series must also be phenotypes
          this.setProp(i, 'is_phenotype', true, ignoreDisabled);
        }
        if (this.setCheckZero(currentSeriesVal)) {
          if (i + 1 < this.project.datadefs.length) {
            const nextSeriesVal = this.project.datadefs[i + 1][prop];
            // if a row series_parent is changed and there are following rows in the same series, then
            // recursively change them to the same series_parent (null or set)
            if (this.checkNumbersEqual(nextSeriesVal, currentSeriesVal)) {
              this.setProp(i + 1, 'series_parent_column', val, ignoreDisabled);
            }
          }
        }
      } else if (prop === 'is_phenotype' && !val) {
        // phenotypes cannot be series
        this.setProp(i, 'series_parent_column', null, ignoreDisabled);
      }
    }
  }

  /**
   * Don't show the pencil to allow editing the column varnames if that varname is currently being edited,
   * the current user can't edit the project, or the project is an imported project
   * (unless the current user is a curator)
   *
   * @param {number} i: column index
   * @returns {boolean}: true to show the pencil to allow varnames to be edited in the data dictionary, false to not
   */
  dataDefVarnameShowPencil(i: number) {
    return !(
      this.checkNumbersEqual(this.editingVarname, i) ||
      !this.project.canEdit ||
      (this.project.external_id && !this.project.isCurator)
    );
  }

  /**
   * Don't show the pencil to allow editing the column series varnames if that varname is currently being edited,
   * or the current user can't edit the project
   *
   * @param {number} i: column index
   * @returns {boolean}: true to show the pencil to allow series varnames to be edited in the data dictionary,
   *                     false to not
   */
  dataDefSeriesVarnameShowPencil(i: number) {
    return !(this.checkNumbersEqual(this.editingSVarname, i) || !this.project.canEdit);
  }

  /**
   * Disable editing varnames in pheno_measures of the current phenotype data if
   * the current user can't edit the project, or the project is an imported project
   * (unless the current user is a curator or it's series varname)
   *
   * @param {number} i: variable index
   * @returns {boolean}: true to allow individual varnames in current phenotype to be edited, false to not
   */
  currentPhenotypeVarnameDisabled(i: number) {
    return (
      !this.project.canEdit ||
      (!this.project.pheno_measures[i].seriestype && this.project.external_id && !this.project.isCurator)
    );
  }

  /**
   * Disable editing individual varnames within a series (pheno_series) in the current phenotype data if
   * the current user can't edit the project, or the project is an imported project
   * (unless the current user is a curator)
   *
   * @returns {boolean}: true to allow individual varnames in current phenotype to be edited, false to not
   */
  currentPhenotypeIndivVarnameDisabled() {
    return !this.project.canEdit || (this.project.external_id && !this.project.isCurator);
  }

  /**
   * Determine if the passed-in property at the passed-in datadefs index should be/is disabled.
   *
   * @param {number} i: datadefs index
   * @param {string} prop: datadef property
   * @returns {boolean}: true if disabled, false if not
   */
  propDisabled(i: number, prop: string): boolean {
    if (i >= this.project.datadefs.length) {
      return true;
    }
    if (this.project.datadefs[i].param) {
      // datatype is locked for params other than informational
      if (prop === 'datatype' && this.project.datadefs[i].param !== 'Informational') {
        return true;
      }
      // only covariate params can also be measures...
      if (
        ['is_phenotype', 'series_parent_column'].indexOf(prop) !== -1 &&
        this.project.datadefs[i].param !== 'Covariate'
      ) {
        return true;
      }
    }
    if (['n_mice', 'std_err', 'std_dev'].indexOf(prop) !== -1) {
      // sd and sem don't make sense for a categorical measure
      if (['std_err', 'std_dev'].indexOf(prop) !== -1 && this.project.datadefs[i].datatype !== 'Numeric') {
        return true;
      }
      // we only need nmice, sem, and sd for measures or covariates
      return !(this.project.datadefs[i].is_phenotype || this.project.datadefs[i].param === 'Covariate');
    }
    return false;
  }

  /**
   * On change of a param, update various values appropriately
   *
   * @param {number} i: datadefs index for which we updated a param
   */
  paramSelect(i: number) {
    const col = this.project.datadefs[i].column;
    const val = this.project.datadefs[i].param;
    if (val === 'Strain') {
      this.project.dataformat.straincol = col;
      this.checkBuildDefaultValMappings(true);
    } else if (val === 'Sex') {
      this.project.dataformat.sexcol = col;
      this.checkBuildDefaultValMappings(true);
    } else if (val === 'ID') {
      this.project.dataformat.idcol = col;
    }
    if (val !== 'Strain' && this.checkNumbersEqual(col, this.project.dataformat.straincol)) {
      this.project.dataformat.straincol = null;
    }
    if (val !== 'Sex' && this.checkNumbersEqual(col, this.project.dataformat.sexcol)) {
      this.project.dataformat.sexcol = null;
    }
    if (val !== 'ID' && this.checkNumbersEqual(col, this.project.dataformat.idcol)) {
      this.project.dataformat.idcol = null;
    }
    if (val && val !== 'Covariate') {
      if (val !== 'Informational') {
        this.project.datadefs[i].datatype = 'Categorical';
      }
      // this means it's an ID, Sex, Strain, or Factor, or Informational in which case we don't want to
      // allow them to make it a measure
      this.setProp(i, 'is_phenotype', null, true);
    } else if (val === 'Covariate') {
      this.project.datadefs[i].datatype = 'Numeric';
    }
  }

  /**
   * Check if the passed-in index is a the first/parent in a measure series
   *
   * @param {number} i: datadefs index
   * @returns {boolean}: true if this is the first/parent of a series, false if not
   */
  isSeriesParent(i: number) {
    return this.checkNumbersEqual(this.project.datadefs[i].column, this.project.datadefs[i].series_parent_column);
  }

  /**
   * Focus on the varname modification input for the passed-in datadefs index after a momentary delay.
   * (For some reason it doesn't work without the delay, either because it's a part of click logic, which
   *  inherently is already focusing on the thing you're clicking, or because we need to wait a second for the
   *  input to become visible.)
   *
   * @param {string} id: element id
   */
  focusVarnameInput(id: string) {
    setTimeout(() => document.getElementById(id)?.focus(), 10);
  }

  // used for column selection for nmice, sd, and sem
  sortedDatadefs() {
    const tempDatadefs = this.project.datadefs.slice();
    return tempDatadefs.sort((a: any, b: any) => a.column - b.column);
  }

  // on upload of a file, if the straincol and/or sexcol are set, then get the file column values for them
  onFileUpload() {
    this.project.dataformat.changesinceprocessed = true;
    // default granularity based on selections from previous pages
    // (it needs to be populated before running buildDataDefs)
    if (!this.project.dataformat.granularity) {
      if (this.project.sexes === 'pooled') {
        this.project.dataformat.granularity = 'strainsex';
      } else {
        this.project.dataformat.granularity = 'animal';
      }
    }
    this.buildDataDefs(true);
  }

  // when files are loaded in on entry of the page, if there is a file and there are not datadefs, then
  // do the initial build of the datadefs as if the file were just uploaded. This is to handle cases
  // like with PFS where the csv file was uploaded by some other means than the file upload component
  onFilesLoaded() {
    if (this.fileUploaded()) {
      if (this.project.datadefs.length === 0) {
        this.onFileUpload();
      } else {
        this.checkBuildDefaultValMappings();
      }
    }
  }

  /**
   * This function checks if there is a strain or sex column selected... and whether or
   * default strain and sex mappings exist for them... if they don't then it either builds them or
   * goes to the backend to get the file column values, which will trigger the building of the default
   * values after the backend returns those column values.
   *
   * @param {boolean} alwaysUpdateMappings: true to update default mappings, regardless of whether or
   *                                        not they already exist for that column
   */
  checkBuildDefaultValMappings(alwaysUpdateMappings: boolean = false) {
    if (this.setCheckZero(this.project.dataformat.straincol) || this.setCheckZero(this.project.dataformat.sexcol)) {
      const indexvals = [];
      let buildDefaultVals = false;
      if (this.setCheckZero(this.project.dataformat.straincol)) {
        if (this.fileColVals(this.project.dataformat.straincol).length === 0) {
          if (Number(this.project.dataformat.straincol) < this.fileCols().length) {
            indexvals.push(Number(this.project.dataformat.straincol));
          }
        } else if (this.project.strainmaps.length === 0) {
          buildDefaultVals = true;
        }
      }
      if (this.setCheckZero(this.project.dataformat.sexcol)) {
        if (this.fileColVals(this.project.dataformat.sexcol).length === 0) {
          if (Number(this.project.dataformat.sexcol) < this.fileCols().length) {
            indexvals.push(Number(this.project.dataformat.sexcol));
          }
        } else if (this.project.sexmaps.length === 0) {
          buildDefaultVals = true;
        }
      }
      if (indexvals) {
        // note: onFileUpdate will trigger defaultValMappings after these fileColVals are retrieved
        this.fileUpload?.getColValues(0, indexvals);
      } else if (buildDefaultVals || alwaysUpdateMappings) {
        this.defaultValMappings();
      }
    }
  }

  // run through the values in the file columns that are selected for strains and sexes, create the
  // strainmaps and sexmaps records, and populate default matches if we can find any.
  defaultValMappings() {
    if (this.setCheckZero(this.project.dataformat.straincol) && this.strainService.strainsLoaded) {
      const strains = this.strainService.strains.getValue();
      let newInputs = false;
      // looping through string file column values
      for (let i = 0; i < this.fileColVals(this.project.dataformat.straincol).length; i++) {
        const val = this.fileColVals(this.project.dataformat.straincol)[i];
        let valFound = false;
        for (let j = 0; j < this.project.strainmaps.length; j++) {
          if (this.project.strainmaps[j].input === val) {
            valFound = true;
            break;
          }
        }
        // if we don't already have a strainmaps record for this file column value, see if there are any strains
        // for which the aname is a perfect match or only 1 partial match, and default that as the mapped strain
        if (!valFound) {
          newInputs = true;
          let strain = {};
          let strainPoss = [];
          for (let j = 0; j < strains.length; j++) {
            // check for exact JR # match
            if (strains[j].vendor === 'J') {
              const stocknum = strains[j].stocknum;
              if (Number(stocknum) === Number(val)) {
                strain = strains[j];
                strainPoss = [];
                break;
              }
            }
            const aname = strains[j].aname;
            const longname = strains[j].longname;
            // check for exact matches on longname (NOTE: No longer assuming there's only 1
            // exact match on aname, unless there's no longname, since duplicates are allowed)
            // NOTE: longname should never be blank, but this logic is checking just in case...
            if (longname && longname === val) {
              strain = strains[j];
              strainPoss = [];
              break;
            } else if (!longname && aname === val) {
              strain = strains[j];
              strainPoss = [];
              break;
            } else if (val.indexOf(aname) !== -1 || aname.indexOf(val) !== -1) {
              // otherwise, add partial matches to the list of possibilities
              strainPoss.push(strains[j]);
            } else if (longname && (val.indexOf(longname) !== -1 || longname.indexOf(val) !== -1)) {
              strainPoss.push(strains[j]);
            }
          }
          if (strainPoss.length === 1) {
            strain = strainPoss[0];
          }
          this.project.strainmaps.push({ input: val, strain: strain });
        }
      }
      // if there are new inputs, then sort them
      if (newInputs) {
        this.project.strainmaps.sort(function (a: any, b: any) {
          return a.input - b.input;
        });
      }
      this.removeExtraStrainMappings(true);
    }
    if (this.setCheckZero(this.project.dataformat.sexcol)) {
      let newInputs = false;
      // looping through string file column values
      for (let i = 0; i < this.fileColVals(this.project.dataformat.sexcol).length; i++) {
        const val = this.fileColVals(this.project.dataformat.sexcol)[i];
        let valFound = false;
        for (let j = 0; j < this.project.sexmaps.length; j++) {
          if (this.project.sexmaps[j].input === val) {
            valFound = true;
            break;
          }
        }
        // if we don't already have a sexmaps record for this file column value, see if there are an 'f's in the string,
        // if so default in 'f', otherwise default in 'm'
        // (this makes it so that there will always be selected sex value for sexmap records, unlike strainmaps)
        if (!valFound) {
          newInputs = true;
          let output = null;
          if (val.toLowerCase().indexOf('f') !== -1) {
            output = 'f';
          } else {
            output = 'm';
          }
          this.project.sexmaps.push({ input: val, sex: output });
        }
      }
      // if there are new inputs, then sort them
      if (newInputs) {
        this.project.sexmaps.sort(function (a: any, b: any) {
          return a.input - b.input;
        });
      }
      this.removeExtraSexMappings();
    }
  }

  /**
   * Create a new factor. Assigns it a temporary id beginning with 'NEW_', which will be converted to
   * an actual id by the backend if it's saved.
   */
  createFactor() {
    let id = 'NEW_0';
    const len = this.project.factors.length;
    for (let j = 0; j < len + 1; j++) {
      let noConflict = true;
      id = 'NEW_' + j;
      for (let k = 0; k < len; k++) {
        if (this.project.factors[k].id === id) {
          noConflict = false;
          break;
        }
      }
      if (noConflict) {
        break;
      }
    }
    this.project.factors.push({
      num_measures: 0,
      values: [],
      id: id,
      factorID: id,
      projid: this.projid,
      listorder: len + 1,
    });
  }

  /**
   * Create a new factor value. Assigns it a temporary id beginning with 'NEW_', which will be converted to
   * an actual id by the backend if it's saved.
   *
   * @param {number} i: factor index for which we are creating a new value
   */
  createFactorValue(i: number) {
    let id = 'NEW_0';
    const len = this.project.factors[i].values.length;
    for (let j = 0; j < len + 1; j++) {
      let noConflict = true;
      id = 'NEW_' + j;
      for (let k = 0; k < len; k++) {
        if (this.project.factors[i].values[k].id === id) {
          noConflict = false;
          break;
        }
      }
      if (noConflict) {
        break;
      }
    }
    this.project.factors[i].values.push({
      num_measures: 0,
      id: id,
      projid: this.projid,
      listorder: len + 1,
      factor_id: this.project.factors[i].id,
    });
  }

  /**
   * Add a new factor to the list associated with a measure
   *
   * @param {number} measInd: measure index for which we're adding the measure factor
   * @param factorID: factor id we're adding to the meas factors
   * @param {number} seriesInd (optional): series measure index for which we're adding the measure factor
   *                                      (won't be set if this is a simple measure,
   *                                       will be set for individual measures within a series)
   */
  addMeasFactor(measInd: number, factorID: any, seriesInd: number | null = null) {
    const factorInd = this.getFactor(factorID, true);
    if (factorInd || factorInd === 0) {
      const factor = JSON.parse(JSON.stringify(this.project.factors[factorInd]));
      if (seriesInd !== null) {
        this.project.pheno_measures[measInd].pheno_series[seriesInd].factors.push(factor);
      } else {
        this.project.pheno_measures[measInd].factors.push(factor);
      }
      this.resortMeasFactors(measInd, seriesInd);
      this.project.factors[factorInd].num_measures++;
    }
  }

  /**
   * Sort the list of factors associated with a measure by the current order in the factors list.
   * Can be done for just a single measure (used after a factor is added to a measure),
   * or for all measures (used when factor order is changed)
   *
   * @param {number} measInd (optional):  measure index for which we're sorting linked factors
   *                                     (if this isn't set, then we just sort all measure factors)
   * @param {number} seriesInd (optional): series measure index for which we're sorting linked factors
   *                                      (won't be set if this is a simple measure,
   *                                       will be set for individual measures within a series)
   */
  resortMeasFactors(measInd: number | null = null, seriesInd: number | null = null) {
    const factorListOrders: any = {};
    for (let i = 0; i < this.project.factors.length; i++) {
      factorListOrders[this.project.factors[i].id] = i;
    }
    // if we specified a specific measure for which to resort factors (because a factor was added to that measure),
    // then just do that one...otherwise just resort all meas factors (because factor order was changed)
    if (measInd !== null) {
      if (seriesInd !== null) {
        this.project.pheno_measures[measInd].pheno_series[seriesInd].factors.sort(function (a: any, b: any) {
          return factorListOrders[a.factor_id] - factorListOrders[b.factor_id];
        });
      } else {
        this.project.pheno_measures[measInd].factors.sort(function (a: any, b: any) {
          return factorListOrders[a.factor_id] - factorListOrders[b.factor_id];
        });
      }
    } else {
      // otherwise just sort all meas factors
      for (let i = 0; i < this.project.pheno_measures.length; i++) {
        if (!this.project.pheno_measures[i].seriestype) {
          this.project.pheno_measures[i].factors.sort(function (a: any, b: any) {
            return factorListOrders[a.factor_id] - factorListOrders[b.factor_id];
          });
        } else {
          for (let j = 0; j < this.project.pheno_measures[i].pheno_series.length; j++) {
            this.project.pheno_measures[i].pheno_series[j].factors.sort(function (a: any, b: any) {
              return factorListOrders[a.factor_id] - factorListOrders[b.factor_id];
            });
          }
        }
      }
    }
  }

  /**
   * Build a count of the number of factors linked with a measure that don't have a value selected
   *
   * @param {number} measInd (optional):  measure index for which we're counting number of factors without values
   * @param {number} seriesInd (optional): series measure index for which we're counting number of factors without
   *                                       values (won't be set if this is a simple measure, will be set for
   *                                       individual measures within a series)
   * @returns {number}: number of factors linked to the measure without values selected
   */
  measFactorBlankCount(measInd: number, seriesInd: number | null = null): number {
    let count = 0;
    if (seriesInd !== null) {
      for (let i = 0; i < this.project.pheno_measures[measInd].pheno_series[seriesInd].factors.length; i++) {
        if (!this.project.pheno_measures[measInd].pheno_series[seriesInd].factors[i].factor_value_id) {
          count++;
        }
      }
    } else {
      for (let i = 0; i < this.project.pheno_measures[measInd].factors.length; i++) {
        if (!this.project.pheno_measures[measInd].factors[i].factor_value_id) {
          count++;
        }
      }
    }
    return count;
  }

  /**
   * Remove a measure factor (the link between a measure and factor)
   * Also, updates the number of linked measures kept track of for the factor and factor value
   * (if there was one) that were removed
   *
   * @param {number} measInd: measure index for which we're removing the measure factor
   * @param mFactorInd: index of the factor link that we're removing
   * @param {number} seriesInd (optional): series measure index for which we're removing the measure factor
   *                                      (won't be set if this is a simple measure,
   *                                       will be set for individual measures within a series)
   */
  removeMeasFactor(measInd: number, mFactorInd: number, seriesInd: number | null = null) {
    let factorID = null;
    let factorValueID = null;
    if (seriesInd !== null) {
      const factor = this.project.pheno_measures[measInd].pheno_series[seriesInd].factors[mFactorInd];
      factorID = factor.factor_id;
      factorValueID = factor.factor_value_id;
      this.project.pheno_measures[measInd].pheno_series[seriesInd].factors.splice(mFactorInd, 1);
    } else {
      const factor = this.project.pheno_measures[measInd].factors[mFactorInd];
      factorID = factor.factor_id;
      factorValueID = factor.factor_value_id;
      this.project.pheno_measures[measInd].factors.splice(mFactorInd, 1);
    }
    const factorInd = this.getFactor(factorID, true);
    // update number of linked measures for the factor and factor value
    if (factorInd || factorInd === 0) {
      this.project.factors[factorInd].num_measures--;
      if (factorValueID) {
        for (let i = 0; i < this.project.factors[factorInd].values.length; i++) {
          if (String(this.project.factors[factorInd].values[i].id) === String(factorValueID)) {
            this.project.factors[factorInd].values[i].num_measures--;
            break;
          }
        }
      }
    }
  }

  /**
   * Change a measure factor value (the selected value for the linked factor)
   * Also, updates the number of linked measures kept track of for the factor value that was removed (if it was
   * previously populated) and the new factor value (if it is now populated)
   *
   * @param {number} measInd: measure index for which we're changing the measure factor value
   * @param mFactorInd: index of the factor link that we're removing
   * @param newFactorValueID: new factor value id (after the change), can be blank
   * @param {number} seriesInd (optional): series measure index for which we're changing the measure factor value
   *                                      (won't be set if this is a simple measure,
   *                                       will be set for individual measures within a series)
   */
  changeMeasFactorValue(measInd: number, mFactorInd: number, newFactorValueID: any, seriesInd: number | null = null) {
    let factorID = null;
    let oldFactorValueID = null;
    if (seriesInd !== null) {
      const factor = this.project.pheno_measures[measInd].pheno_series[seriesInd].factors[mFactorInd];
      factorID = factor.factor_id;
      // this copy value is maintained so that we know what the previous value was after it has been changed
      oldFactorValueID = factor.factor_value_id_copy;
      factor.factor_value_id_copy = newFactorValueID;
    } else {
      const factor = this.project.pheno_measures[measInd].factors[mFactorInd];
      factorID = factor.factor_id;
      // this copy value is maintained so that we know what the previous value was after it has been changed
      oldFactorValueID = factor.factor_value_id_copy;
      factor.factor_value_id_copy = newFactorValueID;
    }
    const factorInd = this.getFactor(factorID, true);
    // update number of linked measures for the factor values
    if (factorInd || factorInd === 0) {
      // this check probably isn't needed, since one of the other should be populated...
      if (newFactorValueID || oldFactorValueID) {
        for (let i = 0; i < this.project.factors[factorInd].values.length; i++) {
          if (newFactorValueID && String(this.project.factors[factorInd].values[i].id) === String(newFactorValueID)) {
            this.project.factors[factorInd].values[i].num_measures++;
          } else if (
            oldFactorValueID &&
            String(this.project.factors[factorInd].values[i].id) === String(oldFactorValueID)
          ) {
            this.project.factors[factorInd].values[i].num_measures--;
          }
        }
      }
    }
  }

  /**
   * Builds a list of factor dicts for factors that are not already linked with the measure.
   * Used to build a list of factors that can be added in the select for adding new factors to a measure.
   *
   * @param {number} measInd (optional):  measure index for which we're building a list of unused factors
   * @param {number} seriesInd (optional): series measure index for which we're building a list of unused factors
   *                                      (won't be set if this is a simple measure,
   *                                       will be set for individual measures within a series)
   * @returns {any}: list of untaken factor dicts that can be linked with the measure
   */
  untakenMeasFactors(measInd: number, seriesInd: number | null = null) {
    const factors = JSON.parse(JSON.stringify(this.project.factors));
    for (let j = factors.length - 1; j >= 0; j--) {
      if (seriesInd !== null) {
        for (let i = 0; i < this.project.pheno_measures[measInd].pheno_series[seriesInd].factors.length; i++) {
          const factor = this.project.pheno_measures[measInd].pheno_series[seriesInd].factors[i];
          if (String(factor.factor_id) === String(factors[j].id)) {
            factors.splice(j, 1);
            break;
          }
        }
      } else {
        for (let i = 0; i < this.project.pheno_measures[measInd].factors.length; i++) {
          if (String(this.project.pheno_measures[measInd].factors[i].factor_id) === String(factors[j].id)) {
            factors.splice(j, 1);
            break;
          }
        }
      }
    }
    return factors;
  }

  /**
   * Take an input factor id and find the factor in project.factors...returning either the factor dict or its index
   * in project.factors
   *
   * @param id: factor id for which we're retrieving either the factor dict or index (in project.factors)
   * @param {boolean} index: true to return the index where we found the factor (in project.factors), false to
   *                         return the factor dict
   * @returns {any}: factor index or dict
   */
  getFactor(id: any, index: boolean = false) {
    for (let i = 0; i < this.project.factors.length; i++) {
      if (String(this.project.factors[i].id) === String(id)) {
        if (index) {
          return i;
        }
        return this.project.factors[i];
      }
    }
    if (index) {
      return -1;
    }
    return {};
  }

  /**
   * Return a count of the number of blank values for a factor
   *
   * @param {number} factorInd: factor for which we're counting blank values
   * @returns {number}: number of blank values in the factor
   */
  numFactorValuesBlank(factorInd: number) {
    let count = 0;
    for (let i = 0; i < this.project.factors[factorInd].values.length; i++) {
      if (!this.project.factors[factorInd].values[i].value) {
        count++;
      }
    }
    return count;
  }

  /**
   * Delete a factor. Confirms the user is sure, removes the meas factor links, and saves right after the deletion so
   * that is is updated in the backend.
   *
   * @param {number} factorInd: index of the factor we're deleting
   */
  deleteFactor(factorInd: number) {
    const num = this.project.factors[factorInd].num_measures;
    let name = this.project.factors[factorInd].name;
    name = name ? name : '(Unnamed)';
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        header: 'Confirm Delete Factor',
        message:
          "Are you sure that you wish to delete the factor, '" +
          name +
          "'?" +
          (num > 0 ? "<br>This factor's links with " + num + ' variables will be lost if it is deleted.' : '') +
          '<br>This change (and other unsaved changes) will be saved immediately upon deletion.',
        falselabel: 'Cancel',
        truelabel: 'Delete',
        truebtn: 'btn-danger',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const factorID = this.project.factors[factorInd].id;
        // remove the measure links to this factor
        for (let i = 0; i < this.project.pheno_measures.length; i++) {
          if (!this.project.pheno_measures[i].seriestype) {
            for (let f = 0; f < this.project.pheno_measures[i].factors.length; f++) {
              if (String(this.project.pheno_measures[i].factors[f].factor_id) === String(factorID)) {
                this.project.pheno_measures[i].factors.splice(f, 1);
                break;
              }
            }
          } else {
            for (let j = 0; j < this.project.pheno_measures[i].pheno_series.length; j++) {
              for (let f = 0; f < this.project.pheno_measures[i].pheno_series[j].factors.length; f++) {
                if (String(this.project.pheno_measures[i].pheno_series[j].factors[f].factor_id) === String(factorID)) {
                  this.project.pheno_measures[i].pheno_series[j].factors.splice(f, 1);
                  break;
                }
              }
            }
          }
        }
        for (let i = factorInd + 1; i < this.project.factors.length; i++) {
          this.project.factors[i].listorder--;
        }
        this.project.factors.splice(factorInd, 1);
        this.stateButtons.saveProject().subscribe();
      }
    });
  }

  /**
   * Delete a factor value. Confirms the user is sure, first.
   *
   * We first remove the links to the factor value, then save, then delete the factor value, and save again...
   * this is done because deleting the factor value and saving without unlinking them in a previous save
   * will delete the factor link as well, even if the value was changed to something else before saving...
   * this is a quirk in the sqlalchemy relationship where factor id is used for 2 foreign keys and 2 relationships.
   *
   * @param {number} factorInd: index of the factor we're deleting
   * @param {number} valInd: index of the factor value we're deleting
   */
  deleteFactorValue(factorInd: number, valInd: number) {
    const num = this.project.factors[factorInd].values[valInd].num_measures;
    let name = this.project.factors[factorInd].name;
    name = name ? name : '(Unnamed)';
    let value = this.project.factors[factorInd].values[valInd].value;
    value = value ? value : '(blank)';
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        header: 'Confirm Delete Factor Value',
        message:
          "Are you sure that you wish to delete the value, '" +
          value +
          "', from the factor, '" +
          name +
          "'?" +
          (num > 0
            ? "<br>This factor value's links with " + num + ' variables will be lost if it is ' + 'deleted.'
            : '') +
          '<br>This change (and other unsaved changes) will be saved immediately upon deletion.',
        falselabel: 'Cancel',
        truelabel: 'Delete',
        truebtn: 'btn-danger',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const factorID = this.project.factors[factorInd].id;
        const id = this.project.factors[factorInd].values[valInd].id;
        // remove measure links to the factor value (without removing the factor link, just clears the value)
        for (let i = 0; i < this.project.pheno_measures.length; i++) {
          if (!this.project.pheno_measures[i].seriestype) {
            for (let f = 0; f < this.project.pheno_measures[i].factors.length; f++) {
              if (String(this.project.pheno_measures[i].factors[f].factor_id) === String(factorID)) {
                if (String(this.project.pheno_measures[i].factors[f].factor_value_id) === String(id)) {
                  this.project.pheno_measures[i].factors[f].factor_value_id = null;
                  this.project.pheno_measures[i].factors[f].factor_value_id_copy = null;
                  break;
                }
              }
            }
          } else {
            for (let j = 0; j < this.project.pheno_measures[i].pheno_series.length; j++) {
              for (let f = 0; f < this.project.pheno_measures[i].pheno_series[j].factors.length; f++) {
                const factor = this.project.pheno_measures[i].pheno_series[j].factors[f];
                if (String(factor.factor_id) === String(factorID)) {
                  if (String(factor.factor_value_id) === String(id)) {
                    factor.factor_value_id = null;
                    factor.factor_value_id_copy = null;
                    break;
                  }
                }
              }
            }
          }
        }
        // saving both before and after because the nature of the link between measures, factors, and factor values
        // in the database can cause the factor to be deleted entirely from the measure, even if it was switched to a
        // different factor value before the save (if the deletion of that factor value is in the same save), this
        // way we avoid that issue
        this.stateButtons.saveProject().subscribe(() => {
          for (let i = valInd + 1; i < this.project.factors[factorInd].values.length; i++) {
            this.project.factors[factorInd].values[i].listorder--;
          }
          this.project.factors[factorInd].values.splice(valInd, 1);
          this.stateButtons.saveProject().subscribe();
        });
      }
    });
  }

  /**
   * Swap two current factors
   * @param {number} indOne: factors index one that we are swapping
   * @param {number} indTwo: factors index two that we are swapping
   */
  swapFactors(indOne: number, indTwo: number) {
    const length = this.project.factors.length;
    if (indOne >= 0 && indTwo >= 0 && indOne !== indTwo && indOne < length && indTwo < length) {
      const tempFactor = this.project.factors[indOne];
      this.project.factors[indOne] = this.project.factors[indTwo];
      this.project.factors[indOne].listorder = indOne + 1;
      this.project.factors[indTwo] = tempFactor;
      this.project.factors[indTwo].listorder = indTwo + 1;
      this.resortMeasFactors();
    }
  }

  /**
   * Swap two factor values within a factor
   * @param {number} factorInd: factor index in the factors list for which we are swapping two values
   * @param {number} indOne: value index one that we are swapping
   * @param {number} indTwo: value index two that we are swapping
   */
  swapFactorValues(factorInd: number, indOne: number, indTwo: number) {
    if (factorInd < this.project.factors.length) {
      const length = this.project.factors[factorInd].values.length;
      if (indOne >= 0 && indTwo >= 0 && indOne !== indTwo && indOne < length && indTwo < length) {
        const tempVal = this.project.factors[factorInd].values[indOne];
        this.project.factors[factorInd].values[indOne] = this.project.factors[factorInd].values[indTwo];
        this.project.factors[factorInd].values[indOne].listorder = indOne + 1;
        this.project.factors[factorInd].values[indTwo] = tempVal;
        this.project.factors[factorInd].values[indTwo].listorder = indTwo + 1;
      }
    }
  }

  /**
   * Swap two individual measures within a current measure series
   * @param {number} seriesIndex: series index in the measures list for which we are swapping two individual measures
   * @param {number} indOne: measures index one that we are swapping
   * @param {number} indTwo: measures index two that we are swapping
   */
  swapPhenoIndivMeasurements(seriesIndex: number, indOne: number, indTwo: number) {
    if (seriesIndex < this.project.pheno_measures.length) {
      if (this.project.pheno_measures[seriesIndex].seriestype) {
        const length = this.project.pheno_measures[seriesIndex].pheno_series.length;
        if (indOne >= 0 && indTwo >= 0 && indOne !== indTwo && indOne < length && indTwo < length) {
          const tempParam = this.project.pheno_measures[seriesIndex].pheno_series[indOne];
          this.project.pheno_measures[seriesIndex].pheno_series[indOne] =
            this.project.pheno_measures[seriesIndex].pheno_series[indTwo];
          this.project.pheno_measures[seriesIndex].pheno_series[indOne].listorder = indOne + 1;
          this.project.pheno_measures[seriesIndex].pheno_series[indTwo] = tempParam;
          this.project.pheno_measures[seriesIndex].pheno_series[indTwo].listorder = indTwo + 1;
          this.savePreviousVarnames();
        }
      }
    }
  }

  /**
   * Swap two current measures
   * @param {number} indOne: measures index one that we are swapping
   * @param {number} indTwo: measures index two that we are swapping
   */
  swapPhenoMeasurements(indOne: number, indTwo: number) {
    const length = this.project.pheno_measures.length;
    if (indOne >= 0 && indTwo >= 0 && indOne !== indTwo && indOne < length && indTwo < length) {
      const tempParam = this.project.pheno_measures[indOne];
      this.project.pheno_measures[indOne] = this.project.pheno_measures[indTwo];
      this.project.pheno_measures[indOne].listorder = indOne + 1;
      this.project.pheno_measures[indTwo] = tempParam;
      this.project.pheno_measures[indTwo].listorder = indTwo + 1;
      this.savePreviousVarnames();
    }
  }

  /**
   * Build a string for describing the variable based on whether it's a measure, covariate, both, or informational only
   *
   * @param {number} i: measure index
   * @param {boolean} short: true if both a measure and covariate, only return "Measure" in the interest of shortness,
   *                         false to return both
   * @param {boolean} lowercase: true to return the string in all lowercase, false to capitalize the first letter
   * @returns {string}: string to describe the variable categories
   */
  variableCategories(i: number, short: boolean = false, lowercase: boolean = false) {
    const varDict = this.project.pheno_measures[i];
    const isMeas = varDict.is_measure;
    const isCovar = varDict.is_covar;
    let cat = 'Informational';
    if (isMeas) {
      cat = 'Measure';
      if (isCovar && !short) {
        cat += ' & Covariate';
      }
    } else if (isCovar) {
      cat = 'Covariate';
    }
    cat = lowercase ? cat.toLowerCase() : cat;
    return cat;
  }

  /**
   * If it's no longer populated, remove the ontology from ontologiesList at index i
   * @param {any[]} ontologiesList: list of ontology objects we're checking
   *                                    (from a measure or an individual measure within a series)
   * @param {number} o: index in ontologiesList, which just changed, so we're checking if it was cleared
   */
  removeEmptyOntology(ontologiesList: any[], o: number) {
    if (!ontologiesList[o].id) {
      ontologiesList.splice(o, 1);
    }
  }

  /**
   * Build a description string for the ontologies list
   * @param {any[]} ontologiesList: list of ontology objects that we want to build a string for
   * @returns {string}: string describing the ontologies
   */
  buildOntologiesDescrip(ontologiesList: any[]) {
    const ontStrings = [];
    for (let j = 0; j < ontologiesList.length; j++) {
      ontStrings.push(ontologiesList[j].id);
    }

    return buildListTooltip(ontStrings, 'None selected', '', '', ' others...', 3);
  }

  /**
   * On change of the ontology search, if an ontology was selected, then add the ontology to the list of ontologies
   * for the datadef (measure) and return true (so that we know to clear the search), otherwise return false. This
   * function gets triggered also when the search is cleared, so that we return false in that case to avoid an
   * infinite loop.
   *
   * @param {any} ont: ontdict object
   * @param {any[]} ontologiesList: list of ontology objects associated with this datadef,
   *                                    to which we are adding this ontology (simple measure or individual measures)
   * @returns {boolean} true if an ontology was selected (triggers clearing the search), false if not
   */
  ontologyChange(ont: any, ontologiesList: any[]) {
    if (ont.id) {
      ont.projid = this.projid;
      ont.ontid = ont.id;
      ontologiesList.push(ont);
      return true;
    }
    return false;
  }

  /**
   * If it's no longer populated, remove the meas_proc from measProcsList at index i
   * @param {any[]} measProcsList: list of meas_procs objects we're checking
   * @param {number} o: index in measProcsList, which just changed, so we're checking if it was cleared
   */
  removeEmptyMeasProc(measProcsList: any[], o: number) {
    if (!measProcsList[o].proc_id) {
      measProcsList.splice(o, 1);
    }
  }

  /**
   * Build a description string for the meas_procs list
   * @param {any[]} measProcsList: list of meas_proc objects that we want to build a string for
   * @returns {string}: string describing the procedures
   */
  buildMeasProcsDescrip(measProcsList: any[]) {
    const procStrings = [];
    for (let j = 0; j < measProcsList.length; j++) {
      const title = measProcsList[j].title
        ? measProcsList[j].title.trim()
          ? measProcsList[j].title
          : '(No Title)'
        : '(No Title)';
      procStrings.push('#' + measProcsList[j].listingorder + ': ' + title);
    }

    return buildListTooltip(procStrings, 'None selected', '', '', ' others...', 1);
  }

  /**
   * On change of the procedure search, if an procedure was selected, then add the procedure to the list of meas_procs
   * for the datadef (measure) and return true (so that we know to clear the search), otherwise return false. This
   * function gets triggered also when the search is cleared, so that we return false in that case to avoid an
   * infinite loop.
   *
   * @param {any} proc: procedure object
   * @param {any[]} measProcsList: list of meas_proc objects associated with this variable (or bulkUpdate),
   *                                    to which we are adding this procedure
   * @returns {boolean} true if a procedure was selected (triggers clearing the search), false if not
   */
  measProcChange(proc: any, measProcsList: any[]) {
    if (proc.proc_id) {
      measProcsList.push(proc);
      measProcsList.sort(function (a: any, b: any) {
        return a.listingorder > b.listingorder ? 1 : -1;
      });
      return true;
    }
    return false;
  }

  // On change of the granularity, set various fields appropriately to the new granularity
  granularityChange() {
    if (this.project.dataformat.granularity === 'strainsex') {
      this.project.dataformat.idcol = null;
      for (let i = 0; i < this.project.datadefs.length; i++) {
        if (this.project.datadefs[i].param === 'ID') {
          this.project.datadefs[i].param = '';
          break;
        }
      }
    }
  }

  // get the number of strainmaps without a strain selected or populated
  unpopulatedStrainMapsCount(): number {
    let count = 0;
    for (let i = 0; i < this.project.strainmaps.length; i++) {
      if (!(this.project.strainmaps[i].strain ? this.project.strainmaps[i].strain.id : false)) {
        count++;
      }
    }
    return count;
  }

  // Remove all strain mappings that don't have a matching input in the file column
  removeExtraStrainMappings(emptyOnly: boolean = false) {
    if (this.setCheckZero(this.project.dataformat.straincol)) {
      const fileValues = this.fileColVals(Number(this.project.dataformat.straincol));
      for (let i = this.project.strainmaps.length - 1; i >= 0; i--) {
        if (fileValues.indexOf(this.project.strainmaps[i].input) === -1) {
          if (!emptyOnly || !this.project.strainmaps[i].strain.id) {
            this.project.strainmaps.splice(i, 1);
          }
        }
      }
    }
  }

  // Remove all sex mappings that don't have a matching input in the sex file column
  removeExtraSexMappings() {
    if (this.setCheckZero(this.project.dataformat.sexcol)) {
      const fileValues = this.fileColVals(Number(this.project.dataformat.sexcol));
      for (let i = this.project.sexmaps.length - 1; i >= 0; i--) {
        if (fileValues.indexOf(this.project.sexmaps[i].input) === -1) {
          this.project.sexmaps.splice(i, 1);
        }
      }
    }
  }

  /**
   * Function to delete an existing measure. Can delete either a simple or series measure in pheno_measures,
   * or just an individual measure within a series.
   *
   * @param {number} measInd: index in pheno_measures to delete
   * @param {number} childMeasInd (optional): index in pheno_series to delete if we are just deleting an indivual
   *                                              measure in a series, otherwise null/false
   */
  deleteMeasure(measInd: number, childMeasInd: number | null = null) {
    // build a string to tell them which measure they're attempting to delete
    let measInfo = '';
    if (childMeasInd !== null) {
      measInfo =
        "'" +
        this.project.pheno_measures[measInd].pheno_series[childMeasInd].indiv_varname +
        "'" +
        ", in the series, '" +
        this.project.pheno_measures[measInd].varname +
        "'";
    } else {
      measInfo = "'" + this.project.pheno_measures[measInd].varname + "'";
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        header: 'Confirm Delete Measure',
        message:
          'Are you sure that you wish to delete the measure, ' +
          measInfo +
          '?' +
          '<br>All data associated with this measure will also be deleted.' +
          '<br>This change (and other unsaved changes) will be saved immediately upon deletion.',
        falselabel: 'Cancel',
        truelabel: 'Delete',
        truebtn: 'btn-danger',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (childMeasInd !== null) {
          this.project.pheno_measures[measInd].pheno_series[childMeasInd].deletemeasure = 'DELETE';
        } else {
          this.project.pheno_measures[measInd].deletemeasure = 'DELETE';
        }
        this.stateButtons.saveProject().subscribe(() => {
          this.recalcPages();
        });
      }
    });
  }

  /**
   * Clean invalid punctuation from the varname
   * @param {string} string: varname string
   * @returns {string}: varname string with invalid punctuation replaced by underscores
   */
  varnameClean(string: string) {
    return string.replace(/[^._a-zA-Z0-9]/g, '_');
  }

  /**
   * Saves previous values of pheno_measures and datadefs
   * (only needs to be called if order changes, or variable name are changed) so that onChange of a
   * variable name, we have the previous value of the variable name to compare against other variable names
   * (looking for matches to make suggestions of other variable names to change)
   */
  savePreviousVarnames() {
    this.oldVarnames = JSON.parse(JSON.stringify(this.project.pheno_measures));
    this.oldDatadefVarnames = JSON.parse(JSON.stringify(this.project.datadefs));
  }

  /**
   * When a variable name in one of 4 places is changed (2 in data definitions/dictionary... aka the datadefs,
   * and 2 in current phenotype data... aka the pheno_measures and pheno_series), then check for matching variable
   * names in the OTHER location to suggest also making the same change to the variable name there.
   *
   * During processing, factor values can be appended onto the datadefs variable names to create the
   * pheno_measures and pheno_series variable names. Since current phenotype data is derived from the data dictionary
   * during processing, it's possible for there to be more than one variable in current phenotype data linked to 1
   * row in the data definitions, but not vice versa:
   * in short... 1 datadefs row -> many pheno_measures and/or pheno_series rows
   *
   * For a match to be identified, there must be file_column values in the current phenotype data to tell us which
   * columns in the datadefs they were derived from. Then, at least part of the variable name must match (for datadefs,
   * we only get a match if the entire old datadefs varname is in the pheno_measures or pheno_series varname, while
   * it's the other way in reverse).
   *
   * All of this is a long-winded explanation for the fact that when a variable name is changed in datadefs, we will
   * loop through all appropriate current phenotype data variables and possibly find multiple matches....
   * meanwhile, when a variable name is changed in the pheno_measures or pheno_series, if a match is found in datadefs
   * and changed, then we will recursively call this function for that datadefs variable name that just changed to
   * see if there are other matches in the current phenotype data.
   *
   * NOTE: Recursion can only occur when a variable name in the current phenotype data is changed
   *       and the logic in this function ends up changing a datadefs variable name, and it should only
   *       happen once.
   *
   * @param {string} newVarname: new variable name the varname at changeLocation as changed to
   * @param {string} changeLocation: "datadef_s_varname" if the changed varname is a series varname in the datadefs,
   *                                 "datadef_varname" if the
   * @param {number} index: index of the datadefs or pheno_measures row the changed varname belongs to
   * @param {number} phenoSeriesIndex: (optional) index of the pheno_series row (within the pheno_measures[index] row)
   *                                   the changed pheno_series varname belong to
   * @param {number} ignoreIndex: (optional) index of a pheno_measures row to ignore... if ignoreSeriesIndex is -1, then
   *                              this pheno_measures row is ignored, if ignoreSeriesIndex is > -1, then this
   *                              pheno_measures row is NOT ignored, but the child pheno_series row at ignoreSeriesIndex
   *                              is ignored (this is used if recursion is needed to skip the row that triggered the
   *                              recursion... otherwise it could ask to change the variable that was just manually
   *                              changed by the user)
   * @param {number} ignoreSeriesIndex: (optional) index of a pheno_series row to ignore
   *                                    (within pheno_measures[ignoreIndex].pheno_series)
   *                                    (this is used if recursion is needed to skip the row that triggered the
   *                                    recursion... otherwise it could ask to change the variable that was just
   *                                    manually changed by the user)
   * @returns {null}: returns null to shortcut out if the user disabled this feature during this session
   */
  checkChangeMatchingVarname(
    newVarname: string,
    changeLocation: string,
    index: number,
    phenoSeriesIndex: number = -1,
    ignoreIndex: number = -1,
    ignoreSeriesIndex: number = -1,
  ) {
    // don't bother checking if the user said they don't want this message anymore
    if (this.disableCheckForMatchingVarnames) {
      return null;
    }

    const recommendNote =
      "</b>'?<br><br>NOTE: It is recommended to keep variable names the Data Dictionary and the " +
      'Current Phenotype Data in sync with each other in order to allow smoother updating of data when ' +
      're-processing is required.';
    if (changeLocation === 'datadef_s_varname') {
      // on change of a series variable name in datadefs,
      // then look for matching variable names in pheno_measures
      const ddOldSerVarname = this.oldDatadefVarnames[index].s_varname;
      const ddFileCol = this.project.datadefs[index].column;
      for (let i = 0; i < this.project.pheno_measures.length; i++) {
        const pmCurrentVarname = this.project.pheno_measures[i].varname;
        if (
          pmCurrentVarname.indexOf(ddOldSerVarname) !== -1 &&
          (i !== ignoreIndex || ignoreSeriesIndex !== -1) &&
          this.project.pheno_measures[i].file_column === ddFileCol
        ) {
          const pmNotSeries = !this.project.pheno_measures[i].seriestype;
          const pmChangeVarname = pmCurrentVarname.replace(ddOldSerVarname, newVarname);
          const pmNotSeriesMessage = pmNotSeries
            ? '<br><br>NOTE: Even though the variable you changed is for a Series in ' +
              'the data definitions, this matching variable name in the Current Phenotype Data ' +
              'is for a Single Measure (not a series)'
            : '';
          const message = `You are changing the Series Variable Name: <br>
             '<b>${ddOldSerVarname}</b>'in the Data Dictionary to: <br>
             '<b>${newVarname}</b>'.<br>
             Would you also like to change the matching ${pmNotSeries ? '' : 'Series '}Variable Name: <br>
             '<b>${pmCurrentVarname}</b>' in the Current Phenotype Data to: <br>
             '<b>${pmChangeVarname}${recommendNote}${pmNotSeriesMessage}`;
          const header = pmNotSeries ? 'Change Matching Variable Name?' : 'Change Matching Series Variable Name?';
          const dialogRef = this.triggerYesNoDialog(header, message);
          dialogRef.afterClosed().subscribe((answer) => {
            if (answer === "No, don't ask again") {
              this.disableCheckForMatchingVarnames = true;
              this.dialog.closeAll();
            } else if (answer === 'Yes') {
              this.project.pheno_measures[i].varname = pmChangeVarname;
              // store this change so that subsequent calls know about it
              this.savePreviousVarnames();
            }
          });
        }
      }
    } else if (changeLocation === 'datadef_varname') {
      // on change of a variable name in datadefs (either single measures varnames or individual varnames
      // of measures within a series), then look for matching datadef variable names OR datadef series variable names
      const ddOldVarname = this.oldDatadefVarnames[index].varname;
      const ddFileCol = this.project.datadefs[index].column;
      const ddNotSeries = !this.setCheckZero(this.project.datadefs[index].series_parent_column);
      for (let i = 0; i < this.project.pheno_measures.length; i++) {
        // check pheno_series before this row's varname in pheno_measures because the last dialog to open will show
        // on top, so this way the pheno_measures row will be on the top
        if (this.project.pheno_measures[i].seriestype) {
          for (let j = 0; j < this.project.pheno_measures[i].pheno_series.length; j++) {
            const psCurrentVarname = this.project.pheno_measures[i].pheno_series[j].indiv_varname;
            if (
              psCurrentVarname.indexOf(ddOldVarname) !== -1 &&
              !(i === ignoreIndex && j === ignoreSeriesIndex) &&
              this.project.pheno_measures[i].pheno_series[j].file_column === ddFileCol
            ) {
              const psChangeVarname = psCurrentVarname.replace(ddOldVarname, newVarname);
              const message = `You are changing the Variable Name: <br>
                 '<b>${ddOldVarname}</b>' in the Data Dictionary to: <br>
                 '<b>${newVarname}</b>'.<br>
                 Would you also like to change the matching Individual Variable Name (within a series): <br>
                 '<b>${psCurrentVarname}</b>' in the Current Phenotype Data to: <br>
                 '<b>${psChangeVarname}${recommendNote}`;
              const dialogRef = this.triggerYesNoDialog('Change Matching Individual Variable Name?', message);
              dialogRef.afterClosed().subscribe((answer) => {
                if (answer === "No, don't ask again") {
                  this.disableCheckForMatchingVarnames = true;
                  this.dialog.closeAll();
                } else if (answer === 'Yes') {
                  this.project.pheno_measures[i].pheno_series[j].indiv_varname = psChangeVarname;
                  // store this change so that subsequent calls know about it
                  this.savePreviousVarnames();
                }
              });
            }
          }
        }
        const pmCurrentVarname = this.project.pheno_measures[i].varname;
        const pmNotSeries = !this.project.pheno_measures[i].seriestype;
        // check for whaether the pheno_measures value itself matches... this should only happen if the datadef row is
        // not series... either it is a series and the series varname came from the datadefs s_varname (not varname),
        // which is a different condition, or it isn't a series and it the pheno_measures row is a single variable
        // if there were no factors, or a series row if there are factors
        if (
          pmCurrentVarname.indexOf(ddOldVarname) !== -1 &&
          ddNotSeries &&
          (i !== ignoreIndex || ignoreSeriesIndex !== -1) &&
          this.project.pheno_measures[i].file_column === ddFileCol
        ) {
          const pmChangeVarname = pmCurrentVarname.replace(ddOldVarname, newVarname);
          const message = `You are changing the Variable Name: <br>
             '<b>${ddOldVarname}</b>' in the Data Dictionary to: <br>
             '<b>${newVarname}</b>'.<br>
             Would you also like to change the matching ${pmNotSeries ? '' : 'Series '}Variable Name: <br>
             '<b>${pmCurrentVarname}</b>' in the Current Phenotype Data to: <br>
             '<b>${pmChangeVarname}${recommendNote}`;
          const dialogRef = this.triggerYesNoDialog('Change Matching Variable Name?', message);
          dialogRef.afterClosed().subscribe((answer) => {
            if (answer === "No, don't ask again") {
              this.disableCheckForMatchingVarnames = true;
              this.dialog.closeAll();
            } else if (answer === 'Yes') {
              this.project.pheno_measures[i].varname = pmChangeVarname;
              // store this change so that subsequent calls know about it
              this.savePreviousVarnames();
            }
          });
        }
      }
    } else if (changeLocation === 'pheno_measures') {
      // on change of a variable name in pheno_measures (either single measures varnames or series measure varnames),
      // then look for matching datadef variable names OR datadef series variable names
      const pmOldVarname = this.oldVarnames[index].varname;
      const pmFileCol = this.project.pheno_measures[index].file_column;
      const pmNotSeries = !this.project.pheno_measures[index].seriestype;
      for (let i = 0; i < this.project.datadefs.length; i++) {
        if (this.project.datadefs[i].column === pmFileCol) {
          const ddCurrentVarname = this.project.datadefs[i].varname;
          const ddIsSeriesParent = this.isSeriesParent(i);
          const ddNotSeries = !this.setCheckZero(this.project.datadefs[i].series_parent_column);
          const ddcurrentSerVarname = this.project.datadefs[i].s_varname;
          // only deal with datadefs varname if changed pheno_measures variable begins with the datadefs varname
          if (ddNotSeries && pmOldVarname.indexOf(ddCurrentVarname) === 0) {
            const extra = pmOldVarname.replace(ddCurrentVarname, '');
            // note: we can't figure out what to change the datadefs varname to unless it is either a perfect match
            //       OR the part that comes after the common value is still there in the newVariable
            if (!extra || newVarname.indexOf(extra) !== -1) {
              const ddChangeVarname = !extra ? newVarname : newVarname.slice(0, newVarname.indexOf(extra));
              if (ddChangeVarname && ddChangeVarname !== ddCurrentVarname) {
                const message = `You are changing the ${pmNotSeries ? '' : 'Series '}Variable Name: <br>
                   '<b>${pmOldVarname}</b>' in the Current Phenotype Data to: <br>
                   '<b>${newVarname}</b>'.<br>
                   Would you also like to change the matching Variable Name: <br>
                   '<b>${ddCurrentVarname}</b>' in the Data Dictionary to: <br>
                   '<b>${ddChangeVarname}${recommendNote}`;
                const dialogRef = this.triggerYesNoDialog('Change Matching Variable Name?', message);
                dialogRef.afterClosed().subscribe((answer) => {
                  if (answer === "No, don't ask again") {
                    this.disableCheckForMatchingVarnames = true;
                    this.dialog.closeAll();
                  } else if (answer === 'Yes') {
                    this.project.datadefs[i].varname = ddChangeVarname;
                    // update any other matching varnames based on this change
                    this.checkChangeMatchingVarname(ddChangeVarname, 'datadef_varname', i, -1, index);
                    // store this change so that subsequent calls know about it
                    this.savePreviousVarnames();
                  }
                });
              }
            }
          } else if (!pmNotSeries && ddIsSeriesParent && pmOldVarname.indexOf(ddcurrentSerVarname) === 0) {
            // only deal with datadefs series varname if changed pheno_measures variable begins with the datadefs series
            // varname and this pheno_measures varname is also for a series
            const extra = pmOldVarname.replace(ddcurrentSerVarname, '');
            // note: we can't figure out what to change the datadefs series varname to unless it is either a perfect
            //       match OR the part that comes after the common value is still there in the newVariable
            if (!extra || newVarname.indexOf(extra) !== -1) {
              const ddChangeSerVarname = !extra ? newVarname : newVarname.slice(0, newVarname.indexOf(extra));
              if (ddChangeSerVarname && ddChangeSerVarname !== ddcurrentSerVarname) {
                const message = `You are changing the ${pmNotSeries ? '' : 'Series '}Variable Name: <br>
                   '<b>${pmOldVarname}</b>' in the Current Phenotype Data to: <br>
                   '<b>${newVarname}</b>'.<br>
                   Would you also like to change the matching Series Variable Name: <br>
                   '<b>${ddcurrentSerVarname}</b>' in the Data Dictionary to: <br>
                   '<b>${ddChangeSerVarname}${recommendNote}`;
                const dialogRef = this.triggerYesNoDialog('Change Matching Series Variable Name?', message);
                dialogRef.afterClosed().subscribe((answer) => {
                  if (answer === "No, don't ask again") {
                    this.disableCheckForMatchingVarnames = true;
                    this.dialog.closeAll();
                  } else if (answer === 'Yes') {
                    this.project.datadefs[i].s_varname = ddChangeSerVarname;
                    // update any other matching varnames based on this change
                    this.checkChangeMatchingVarname(ddChangeSerVarname, 'datadef_s_varname', i, -1, index);
                    // store this change so that subsequent calls know about it
                    this.savePreviousVarnames();
                  }
                });
              }
            }
          }
          // only 1 datadefs row will match pmFileCol
          break;
        }
      }
    } else if (changeLocation === 'pheno_series' && phenoSeriesIndex > -1) {
      // on change of a variable name in pheno_series (individual variable names in a series within the current
      // phenotype data), then look for matching datadef variable names
      const psOldVarname = this.oldVarnames[index].pheno_series[phenoSeriesIndex].indiv_varname;
      const psFileCol = this.project.pheno_measures[index].pheno_series[phenoSeriesIndex].file_column;
      for (let i = 0; i < this.project.datadefs.length; i++) {
        if (this.project.datadefs[i].column === psFileCol) {
          const ddCurrentVarname = this.project.datadefs[i].varname;
          // only deal with datadefs varname if changed pheno_measures variable begins with the datadefs varname
          if (psOldVarname.indexOf(ddCurrentVarname) === 0) {
            const extra = psOldVarname.replace(ddCurrentVarname, '');
            // note: we can't figure out what to change the datadefs varname to unless it is either a perfect match
            //       OR the part that comes after the common value is still there in the newVariable
            if (!extra || newVarname.indexOf(extra) !== -1) {
              const ddChangeVarname = !extra ? newVarname : newVarname.slice(0, newVarname.indexOf(extra));
              if (ddChangeVarname && ddChangeVarname !== ddCurrentVarname) {
                const message = `You are changing the Individual Variable Name (within a series): <br>
                   '<b>${psOldVarname}</b>' in the Current Phenotype Data to: <br>
                   '<b>${newVarname}</b>'.<br>
                   Would you also like to change the matching Variable Name: <br>
                   '<b>${ddCurrentVarname}</b>' in the Data Dictionary to: <br>
                   '<b>${ddChangeVarname}${recommendNote}`;
                const dialogRef = this.triggerYesNoDialog('Change Matching Variable Name?', message);
                dialogRef.afterClosed().subscribe((answer) => {
                  if (answer === "No, don't ask again") {
                    this.disableCheckForMatchingVarnames = true;
                    this.dialog.closeAll();
                  } else if (answer === 'Yes') {
                    this.project.datadefs[i].varname = ddChangeVarname;
                    // update any other matching varnames based on this change
                    this.checkChangeMatchingVarname(ddChangeVarname, 'datadef_varname', i, -1, index, phenoSeriesIndex);
                    // store this change so that subsequent calls know about it
                    this.savePreviousVarnames();
                  }
                });
              }
            }
          }
          // only 1 datadefs row will match psFileCol
          break;
        }
      }
    }

    this.savePreviousVarnames();
  }

  /**
   * Trigger a dialog to ask the user if they want to do something, yes/no
   *
   * @param {string} header: header to display in dialog
   * @param {string} message: message detailing the question they are answering yes/no/no, don't ask again to
   * @returns {MatDialogRef<DynamicButtonDialogComponent, any>}
   */
  triggerYesNoDialog(header: string, message: string) {
    return this.dialog.open(DynamicButtonDialogComponent, {
      data: {
        header: header,
        message: message,
        buttonMinWidth: '150px',
        buttons: [
          { value: 'Yes', class: 'btn-primary' },
          { value: 'No' },
          { value: "No, don't ask again", class: 'btn-danger' },
        ],
      },
    });
  }

  /**
   * Function to force a variable that may be technically a string, but is a number to be set to a number and add 1,
   * for usage in html. For some reason, html doesn't allow forcing a string into a number using Number(). Without using
   * this, adding 1 to the string will add it to the end of the string as a character, rather than adding it as a number
   * @param {Number} i: number we are adding 1 to
   * @returns {number} number with 1 added
   */
  addOne(i: number): number {
    return Number(i) + 1;
  }

  /**
   * Checking if this value is set, where the value being to equal 0 counts as set
   * @param value: value we are checking
   * @returns {boolean}: true if the value is set, otherwise false
   */
  setCheckZero(value: any): boolean {
    return value || value === 0;
  }

  /**
   * Check if the values (should be numbers) are equal or both equal to 0
   *
   * @param val1: value 1 to compare
   * @param val2: value 2 to compare
   * @returns {boolean}: True if they're equal, false if they're not
   */
  checkNumbersEqual(val1: any, val2: any) {
    return val1 === val2 || (val1 === 0 && val2 === 0);
  }
}
