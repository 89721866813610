import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../services/project.service';
import { PersonService } from '../../services/person.service';
import { groupMembersTooltip } from '../../shared/utils';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../shared/dialogs/confirmation-dialog.component';

@Component({
  selector: 'share-project',
  templateUrl: './share-project.component.html',
  styleUrls: ['./share-project.component.scss'],
})
export class ShareProjectComponent implements OnInit {
  // make this utils function usable in the html
  groupMembersTooltip = groupMembersTooltip;

  // projid from the url
  projid: string | null = null;

  // project dict
  project: any = { userpermissions: [], grouppermissions: [] };

  // used to determine whether there is a current user, and whether they can edit groups based
  // on whether they are restricted (not on the whitelist), or their email isn't verified
  userUnrestricted = false;

  // used to determine the reason that the user cannot edit (email not verified or restricted)
  user: any = null;

  // currently selected person object in the person search
  personSearch: any = {};
  // currently selected group object in the group search
  groupSearch: any = {};

  // permission to assign to a new person
  newPermission = 'Edit';
  // permission to assign to a new group
  newGroupPermission = 'Edit';

  // true if the title is currently being editeed
  editingTitle = false;
  // current title for the title input
  newTitle = '';
  // options for the user permissions dropdowns
  userPermOptions: string[] = [];
  // true to show the description for permissions
  showDescription = false;
  // true if no project is loaded (either the id is invalid, or the current user doesn't have access to the project)
  noProject = true;

  constructor(
    private route: ActivatedRoute,
    private projectService: ProjectService,
    public router: Router,
    public dialog: MatDialog,
    private personService: PersonService,
  ) {}

  // on init, get the project
  ngOnInit() {
    this.projid = this.route.snapshot.paramMap.get('id') || null;
    if (Number(this.projid)) {
      this.getProject();
    }

    this.personService.currentUser$.subscribe((result) => {
      this.user = result;
      this.userUnrestricted = this.personService.userUnrestricted();
    });
  }

  // get the project
  getProject() {
    if (this.projid) {
      this.projectService.getProject(this.projid).subscribe((data) => {
        this.noProject = false;
        this.project = data;
        this.setFlags();
      });
    }
  }

  // set access flags based the permission of the current user within the project
  setFlags() {
    this.newTitle = this.project.title;
    this.editingTitle = false;
    if (this.project.isOwner) {
      this.userPermOptions = ['Owner', 'Edit', 'View'];
    } else if (this.project.canEdit) {
      this.userPermOptions = ['Edit', 'View'];
    }
  }

  // show and focus the input for editing the title
  editTitle() {
    if (this.project.canEdit) {
      this.editingTitle = true;
      setTimeout(() => {
        document.getElementById('new_project_title')?.focus();
      }, 10);
    }
  }

  /**
   * Tells the user the reason that they can't modify permissions if they have a permission level
   *
   * @returns {string}: note about why the user can't edit the project if they're restricted
   */
  permissionNote(): string {
    if (!this.user) {
      return '';
    }
    const note = 'Note: You are unable to modify this project or its permissions ';
    if (this.user.restricted) {
      return note + 'at this time because edit access to SIP is restricted.';
    }
    if (!this.user.emailverified) {
      return note + 'until you verify your email on your profile.';
    }
    return '';
  }

  /**
   * Add a user permission or modify a user's permission level to the project
   * Confirm that the user is sure before changing (reducing) their own permission
   *
   * @param {object} user: person dict of the user we are adding or modifying
   * @param {string} permission: permission level ('View' or 'Edit') we are setting to the user
   */
  addOrModifyUserPermissionConfirm(user: any, permission: string) {
    if (user.id && permission && !user.isOnlyOwner && this.userUnrestricted) {
      if (user.is_current_user) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          data: {
            header: 'Confirm Permission Change',
            message:
              'Are you sure that you wish change your permission to ' +
              permission +
              '?' +
              '<br>You will be unable to undo this change on your own.' +
              (permission === 'View' ? '<br>Also, you will likely lose the ability to edit this project.' : ''),
            falselabel: 'Cancel',
            truelabel: 'Change',
            truebtn: 'btn-danger',
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.addOrModifyUserPermission(user.id, permission);
          } else {
            if (this.project.isOwner) {
              user.permission = 'Owner';
            } else {
              user.permission = 'Edit';
            }
          }
        });
      } else {
        this.addOrModifyUserPermission(user.id, permission);
      }
    }
  }

  /**
   * Add a user permission or modify a user's permission level to the project
   *
   * @param {number} id: person id for the user we are adding or modifying
   * @param {string} permission: permission level ('View' or 'Edit') we are setting to the user
   * @param {string} email (optional): an email can be provided in place of an id if we want to invite a new user to MPD
   */
  addOrModifyUserPermission(id: number, permission: string, email: string = '') {
    if ((id || email) && permission && this.userUnrestricted) {
      this.projectService
        .saveProject({
          projid: this.project.projid,
          userpermissions: [{ id: id, permission: permission, email: email }],
          log_descrip: 'Share Project Page',
        })
        .subscribe((data) => {
          this.project = data;
          this.setFlags();
        });
    }
  }

  /**
   * Add a group permission or modify a group's permission level to the project
   *
   * @param {number} id: group id for the group we are adding or modifying
   * @param {string} permission: permission level ('View' or 'Edit') we are setting to the group
   */
  addOrModifyGroupPermission(id: number, permission: string) {
    if (id && permission && this.userUnrestricted) {
      this.projectService
        .saveProject({
          projid: this.project.projid,
          grouppermissions: [{ id: id, permission: permission }],
          log_descrip: 'Share Project Page',
        })
        .subscribe((data) => {
          this.project = data;
          this.setFlags();
        });
    }
  }

  /**
   * Determines whether the option to remove user permission should be shown
   * @param {any} userPerm: permission we are determining whether to show removal option for
   * @returns {boolean}: true to show removal option, false to not
   */
  showRemoveUserPermission(userPerm: any) {
    if (!this.userUnrestricted) {
      return false;
    }
    if (userPerm.is_current_user) {
      return true;
    }
    return this.project.isOwner && !this.project.deletedtime;
  }

  /**
   * Determines whether the option to remove user permission should be disabled
   * NOTE: This won't be checked unless showRemoveUserPermission passes
   *
   * @param {any} userPerm: userPerm dict
   * @returns {boolean}: true to disable button to remove user permission
   */
  disableRemoveUserPermission(userPerm: any) {
    // an owner can't remove themselves if they are the only owner
    return this.project.isOwner && userPerm.isOnlyOwner;
  }

  /**
   * Tooltip that shows on the remove user permission button
   * NOTE: This won't be used/displayed unless showRemoveUserPermission passes
   *
   * @param {any} userPerm: userPerm dict
   * @returns {boolean}: tooltip string
   */
  removeUserPermissionTooltip(userPerm: any) {
    // if the button is disabled, don't show this tooltip
    if (this.disableRemoveUserPermission(userPerm)) {
      return '';
    }
    return "Revoke user's access to the project";
  }

  /**
   * Tooltip that shows if the remove user permission button is disabled
   * (put on the div that the button is in so that it shows in chrome even though the button is disabled)
   * NOTE: This won't be used/displayed unless showRemoveUserPermission passes
   *
   * @param {any} userPerm: userPerm dict
   * @returns {boolean}: tooltip string
   */
  removeUserPermissionDisabledTooltip(userPerm: any) {
    if (this.disableRemoveUserPermission(userPerm)) {
      return 'You cannot remove yourself from this project because you are the only Owner';
    }
    return '';
  }

  /**
   * Remove the user (only a Owner or the user themself can do this).
   * Confirm that the user is sure before removing themself.
   *
   * @param {object} user: user dict of the user we are removing
   */
  removeUserPermissionConfirm(user: any) {
    if (user.id && !user.isOnlyOwner && this.userUnrestricted) {
      if (user.is_current_user) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          data: {
            header: 'Confirm Removal',
            message:
              'Are you sure that you wish revoke access this project from yourself?' +
              '<br>You will likely lose access to this project.',
            falselabel: 'Cancel',
            truelabel: 'Remove',
            truebtn: 'btn-danger',
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.removeUserPermission(user);
          }
        });
      } else {
        this.removeUserPermission(user);
      }
    }
  }

  /**
   * Remove the user (only an Owner or the user themself can do this)
   *
   * @param {object} user: user dict of the user we are removing
   */
  removeUserPermission(user: any) {
    if (user.id && !user.isOnlyOwner && this.userUnrestricted) {
      this.projectService
        .saveProject({
          projid: this.project.projid,
          userpermissions: [{ id: user.id, remove: 'true' }],
          log_descrip: 'Share Project Page',
        })
        .subscribe((data) => {
          this.project = data;
          this.setFlags();
        });
    }
  }

  /**
   * Remove the group (only an Owner or an Admin of the group can do this).
   * Confirm that the user is sure before removing the group (if they aren't an Owner).
   *
   * @param {object} group: group dict of the group we are removing
   */
  removeGroupPermissionConfirm(group: any) {
    if (group.id && this.userUnrestricted) {
      if (!this.project.isOwner) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          data: {
            header: 'Confirm Removal',
            message:
              'Are you sure that you wish revoke access to this project from this group ?' +
              '<br>You will likely lose access to this project.',
            falselabel: 'Cancel',
            truelabel: 'Remove',
            truebtn: 'btn-danger',
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.removeGroupPermission(group);
          }
        });
      } else {
        this.removeGroupPermission(group);
      }
    }
  }

  /**
   * Remove the group (only an Owner or an admin of the group can do this)
   *
   * @param {object} group: group dict of the group we are removing
   */
  removeGroupPermission(group: any) {
    if (group.id && this.userUnrestricted) {
      this.projectService
        .saveProject({
          projid: this.project.projid,
          grouppermissions: [{ id: group.id, remove: 'true' }],
          log_descrip: 'Share Project Page',
        })
        .subscribe((data) => {
          this.project = data;
          this.setFlags();
        });
    }
  }

  // save a new title for this project
  saveTitle() {
    if (this.newTitle && this.userUnrestricted) {
      this.projectService
        .saveProject({
          projid: this.project.projid,
          title: this.newTitle,
          log_descrip: 'Share Project Page',
        })
        .subscribe((data) => {
          this.project = data;
          this.setFlags();
        });
    }
  }

  /**
   * Open an external link in a new tab
   * @param {string} url: external url to open
   */
  openExternalLink(url: string) {
    window.open(url, '_blank');
  }
}
