<div class="row">
  <mat-toolbar>
    <h4 class="gray7">QC Summary: {{ project.projsym }}</h4>
  </mat-toolbar>
</div>
<br />
<div class="row">
  <div class="col-sm-12">
    <a [routerLink]="['/project/qc-dashboard', projectId]" class="dark-cerulean text10"> Dashboard</a>
    &nbsp;|&nbsp;
    <a [routerLink]="['/project/technical-qc/details', projectId]" class="dark-cerulean text10">
      Technical QC<mat-icon class="v-bottom" inline="true"> arrow_forward </mat-icon>
    </a>
  </div>
</div>
<br />
<div class="row">
  <div class="col-md-12">
    <app-table [rows]="importMsgs" [columns]="myColumns"></app-table>
  </div>
</div>
<br /><br />
