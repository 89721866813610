<!-- ====== New Animals ====== -->
<div *ngIf="editable" class="col-sm-12 col-lg-6 bottom-margin-1">
  <div class="table-container mat-elevation-z2">
    <div class="top-1 align-center bottom-margin-1">
      <h4>
        New Animals
        <mat-icon
          style="color: #777; padding-top: 5px"
          matTooltip="Only DO (Diversity Outbred) mice are accepted at this time. We will
          support animals that do not have DO generations at a later date"
        >
          info_outline
        </mat-icon>
      </h4>
      <p>
        Upload a file containing information about the animals to be included in this dataset. The fields of interest
        for each animal are
        <b
          >animal ID which should match IDs in the uploaded sample maps, date of birth (optional), sex and DO
          generation</b
        >. Once the file is processed you will have the ability to review and fix any incorrect or missing fields.
      </p>

      <!-- File input, spinner, missing info icon -->
      <div class="col-sm-12">
        <!-- File input -->
        <div class="col-sm-7 align-left">
          <form [formGroup]="animalInfo" class="align-left row">
            <label for="animal-info-filename">Animal Information File</label>
            <div class="input-group">
              <!-- Shows the placeholder or name of selected file -->
              <input
                id="animal-info-filename"
                class="form-control"
                placeholder="Select a file containing animal information"
                formControlName="file"
                readonly
                (click)="animalInfoFile.click()"
              />

              <!-- Hidden file input (because they're ugly) -->
              <input
                type="file"
                #animalInfoFile
                id="animal-info"
                class="form-control file-select"
                (change)="readFile($event)"
              />

              <!-- Browse for file button -->
              <span class="input-group-btn">
                <button class="btn btn-default" type="button" (click)="animalInfoFile.click()">Browse</button>
              </span>
            </div>
          </form>
          <p class="error align-left">{{ fileImportErrorMsg }}</p>
        </div>

        <ng-container *ngIf="!animalInfo.get('file').value && !newAnimals && !fileImportErrorMsg">
          <div class="col-sm-2">
            <h4 style="padding-top: 3rem">OR</h4>
          </div>

          <div class="col-sm-3">
            <button mat-stroked-button color="primary" style="margin-top: 2.5rem" (click)="addAnimal()">
              Add Animal
            </button>
          </div>
        </ng-container>

        <div class="col-sm-2">
          <!-- Loading spinner -->
          <mat-spinner *ngIf="animalInfo.get('file').value && !newAnimals && !fileImportErrorMsg" diameter="30">
          </mat-spinner>

          <!-- Missing animal data info icon that opens dialog with more info -->
          <button
            mat-icon-button
            *ngIf="newAnimals && numIncompleteAnimals"
            color="warn"
            (click)="showMissingAnimalInfoDetails()"
          >
            <mat-icon aria-label="Animals that are missing information">info_outline</mat-icon>
          </button>

          <!-- Missing fields info icon that opens dialog with more info -->
          <button
            mat-icon-button
            *ngIf="fileImportErrorMsg?.includes('Unable to locate fields')"
            color="warn"
            (click)="showMissingFieldDetails()"
          >
            <mat-icon aria-label="At least one required field was not found">error</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <ng-container *ngIf="newAnimals">
      <div class="filter">
        <mat-form-field>
          <input
            matInput
            #newFilter
            [placeholder]="'Search ' + (newAnimals?.data?.length || 0) + ' animals'"
            (keyup)="applyFilter(newAnimals, $event.target.value)"
          />
          <button
            *ngIf="newFilter.value"
            mat-icon-button
            matSuffix
            aria-label="clear"
            (click)="clearFilter(newAnimals, newFilter)"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <mat-table id="new-animals-table" [dataSource]="newAnimals">
        <!-- ID Column -->
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef> Animal ID </mat-header-cell>
          <mat-cell *matCellDef="let animal">
            <mat-form-field floatLabel="never">
              <input
                matInput
                placeholder="Animal ID"
                [(ngModel)]="animal.animal_id"
                (change)="validateNewAnimalIDs()"
              />
              <mat-icon matSuffix *ngIf="!animal.animal_id" color="warn">warning</mat-icon>
            </mat-form-field>
          </mat-cell>
        </ng-container>

        <!-- DOB Column -->
        <ng-container matColumnDef="dob">
          <mat-header-cell *matHeaderCellDef>
            DOB (Optional)
            <mat-icon style="height: 14px; width: 14px; font-size: 14px; margin-left: 2px" [matTooltip]="dobWarning">
              info_outline
            </mat-icon>
          </mat-header-cell>
          <mat-cell *matCellDef="let animal">
            <mat-form-field floatLabel="never">
              <input matInput placeholder="YYYY-MM-DD" [(ngModel)]="animal.dob" />
              <mat-icon matSuffix *ngIf="hasBadDate(animal)" color="warn" matTooltip="Invalid date">warning</mat-icon>
            </mat-form-field>
          </mat-cell>
        </ng-container>

        <!-- Sex Column -->
        <ng-container matColumnDef="sex">
          <mat-header-cell *matHeaderCellDef> Sex </mat-header-cell>
          <mat-cell *matCellDef="let animal">
            <mat-form-field floatLabel="never">
              <mat-select [(ngModel)]="animal.sex" placeholder="Sex">
                <mat-option value="NA">N/A</mat-option>
                <mat-option value="M">M</mat-option>
                <mat-option value="F">F</mat-option>
                <mat-option value="XO">XO</mat-option>
              </mat-select>
            </mat-form-field>
          </mat-cell>
        </ng-container>

        <!-- DO Generation Column -->
        <ng-container matColumnDef="doGen">
          <mat-header-cell *matHeaderCellDef> DO Gen </mat-header-cell>
          <mat-cell *matCellDef="let animal">
            <mat-form-field floatLabel="never">
              <input matInput type="number" min="1" placeholder="DO Gen" [(ngModel)]="animal.do_generation" />
              <mat-icon matSuffix *ngIf="!isDOGenValid(animal.do_generation)" color="warn"> warning </mat-icon>
            </mat-form-field>
          </mat-cell>
        </ng-container>

        <!-- Animal Save Error Column -->
        <ng-container matColumnDef="error">
          <mat-header-cell *matHeaderCellDef [ngClass]="'error-col'"> Error </mat-header-cell>
          <mat-cell *matCellDef="let animal" [ngClass]="'error-col'">
            <mat-icon *ngIf="animal.error" [matTooltip]="animal.error" color="warn">error</mat-icon>
          </mat-cell>
        </ng-container>

        <!-- Delete Column -->
        <ng-container matColumnDef="delete">
          <mat-header-cell *matHeaderCellDef [ngClass]="'narrow-col'"></mat-header-cell>
          <mat-cell *matCellDef="let animal; let i = index" [ngClass]="'narrow-col'">
            <button
              mat-icon-button
              class="remove"
              aria-label="Remove"
              matTooltip="Remove imported animal"
              (click)="removeAnimal(animal)"
            >
              <mat-icon>delete_outline</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="add">
          <mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" id="add-animal-cell">
            <button mat-button color="primary" (click)="addAnimal()">Add Animal</button>
          </mat-footer-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        <mat-footer-row *matFooterRowDef="['add']; sticky: true"></mat-footer-row>
      </mat-table>

      <div class="flex-center y-margin-1">
        <div class="button-tooltip-container">
          <button mat-button (click)="clearNewAnimalTable(animalInfoFile)">Clear Animals</button>
        </div>
        <div
          class="button-tooltip-container"
          [matTooltip]="disableSave ? 'Please resolve errors and/or fill in missing required information' : ''"
        >
          <button
            mat-stroked-button
            color="primary"
            [disabled]="disableSave || !!numIncompleteAnimals"
            (click)="upload.emit(newAnimals.data)"
          >
            Save Animals
          </button>
        </div>
        <p *ngIf="duplicateIDs.length" [matTooltip]="duplicateIDsString" class="cursor-pointer margin-top-half">
          <mat-icon color="warn" aria-hidden="true" class="v-align-middle"> warning </mat-icon>
          {{ duplicateIDs.length }} duplicated IDs
        </p>
      </div>
    </ng-container>
  </div>
</div>

<!-- ====== Saved Animals ====== -->
<div class="col-sm-12 col-lg-{{ editable ? '6' : '12' }}">
  <div class="table-container mat-elevation-z2">
    <div class="top-1 align-center">
      <h4>Saved Animals</h4>
      <p>This dataset currently has information for the following animals</p>
    </div>
    <div class="filter">
      <mat-form-field>
        <input
          matInput
          #savedFilter
          [placeholder]="'Search ' + (savedAnimals?.data?.length || 0) + ' animals'"
          (keyup)="applyFilter(savedAnimals, $event.target.value)"
        />
        <button
          *ngIf="savedFilter.value"
          mat-icon-button
          matSuffix
          aria-label="clear"
          (click)="clearFilter(savedAnimals, savedFilter)"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <mat-table id="saved-animals-table" [dataSource]="savedAnimals">
      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef> Animal ID </mat-header-cell>
        <mat-cell *matCellDef="let animal">
          {{ animal.animal_id }}
        </mat-cell>
      </ng-container>

      <!-- DOB Column -->
      <ng-container matColumnDef="dob">
        <mat-header-cell *matHeaderCellDef> DOB </mat-header-cell>
        <mat-cell *matCellDef="let animal">
          {{ animal.dob }}
        </mat-cell>
      </ng-container>

      <!-- Sex Column -->
      <ng-container matColumnDef="sex">
        <mat-header-cell *matHeaderCellDef> Sex </mat-header-cell>
        <mat-cell *matCellDef="let animal">
          {{ animal.sex }}
        </mat-cell>
      </ng-container>

      <!-- DO Generation Column -->
      <ng-container matColumnDef="doGen">
        <mat-header-cell *matHeaderCellDef> DO Gen </mat-header-cell>
        <mat-cell *matCellDef="let animal">
          {{ animal.do_generation }}
        </mat-cell>
      </ng-container>

      <!-- Delete Column -->
      <ng-container matColumnDef="delete">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let animal; let i = index">
          <!-- TODO: hiding these buttons until the functionality is supported by DivDB API -->
          <button
            *ngIf="editable"
            mat-icon-button
            style="display: none"
            class="remove"
            aria-label="Remove"
            matTooltip="Remove saved animal"
            (click)="removeAnimal(animal, true)"
          >
            <mat-icon>delete_outline</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns.slice(0, 5); sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns.slice(0, 5)"></mat-row>
    </mat-table>
    <div *ngIf="!savedAnimals" class="spinner-container">
      <mat-spinner color="accent" strokeWidth="5" diameter="50"></mat-spinner>
    </div>
    <div class="align-center y-margin-1">
      <p *ngIf="apiErrorMsg" class="error">{{ apiErrorMsg }}</p>
      <!-- TODO: hiding this button until the functionality is supported by DivDB API -->
      <button
        *ngIf="savedAnimals?.data.length && editable"
        mat-button
        style="display: none"
        (click)="clearSavedAnimalTable()"
      >
        Clear Animals
      </button>
    </div>
  </div>
</div>
