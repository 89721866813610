import { Component, OnInit, ViewChildren, QueryList, ViewChild } from '@angular/core';
import { ProjectService } from '../../services/project.service';
import { ProcedureService } from '../../services/procedure.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { FileUploadComponent } from '../../shared/file-upload/file-upload.component';
import { StateButtonsComponent } from '../../shared/state-buttons/state-buttons.component';
import { MatDialog } from '@angular/material/dialog';
import { PublicationDialogComponent } from '../../shared/dialogs/publication-dialog.component';
import { ConfirmationDialogComponent } from '../../shared/dialogs/confirmation-dialog.component';
import { EditProcedureComponent } from '../../shared/entity-editing/edit-procedure/edit-procedure.component';

@Component({
  selector: 'procedures',
  templateUrl: './procedures.component.html',
  styleUrls: ['./procedures.component.scss'],
})
export class ProceduresComponent implements OnInit {
  position = 'after';

  // variable for accessing all of the edit-procedure child components (as an array)
  // in this parent component's typescript
  @ViewChildren(EditProcedureComponent) procedureComponents!: QueryList<EditProcedureComponent>;

  // variable for accessing the state buttons child component in this parent component's typescript
  @ViewChild(StateButtonsComponent) stateButtons!: StateButtonsComponent;

  // api string
  private api: string = environment.securedURLs.sip;

  // project id in the route
  projid: string | null = '';
  // flag that the project id was invalid or the user doesn't have access
  invalidID = false;
  // project object that is displayed in the page (and edited if the user make modifications)
  project: any = { projdoc: {}, procedures: [] };
  // project object that represents the state of the project as of the last import (user to compare with
  // the project object when saving/exporting in order to determine what has changed)
  dbProject: any = { projdoc: {}, procedures: [] };

  // hide entry fields until project is done loading
  loadingProject = false;

  // define workflow editable-table columns
  stepsColumns: any[] = [
    { key: 'step', name: 'Step #', class: 'width5-percent', maxlength: 20 },
    { key: 'substep', name: 'Substep (optional)', class: 'width5-percent', maxlength: 20 },
    { key: 'descrip', name: 'Description', class: 'width80-percent', maxlength: 1000 },
  ];

  // define equipment editable-table columns and fields
  equipColumns: any[] = [
    { key: 'name', name: 'Name', class: 'width15-percent', maxlength: 120 },
    { key: 'manufacturer', name: 'Manufacturer', class: 'width15-percent', maxlength: 120 },
    { key: 'model', name: 'Model / Identifier', class: 'width15-percent', maxlength: 120 },
    { key: 'city', name: 'City', class: 'width10-percent', maxlength: 80 },
    { key: 'state', name: 'State', class: 'width5-percent', maxlength: 40 },
    { key: 'country', name: 'Country', class: 'width15-percent', maxlength: 80 },
    { key: 'rrid', name: 'RRID', class: 'width10-percent', maxlength: 80 },
  ];
  equipFields: any[] = [
    { key: 'descrip', label: 'Details', maxlength: 500, tooltip: 'For example, apparatus dimensions' },
  ];

  // define reagents editable-table columns and fields
  reagentsColumns: any[] = [
    { key: 'name', name: 'Name', class: 'width15-percent', maxlength: 120 },
    { key: 'manufacturer', name: 'Manufacturer', class: 'width15-percent', maxlength: 120 },
    { key: 'vendnum', name: 'Vendor Number', class: 'width15-percent', maxlength: 120 },
    { key: 'city', name: 'City', class: 'width10-percent', maxlength: 80 },
    { key: 'state', name: 'State', class: 'width5-percent', maxlength: 40 },
    { key: 'country', name: 'Country', class: 'width15-percent', maxlength: 80 },
    { key: 'rrid', name: 'RRID', class: 'width10-percent', maxlength: 80 },
  ];
  reagentsFields: any[] = [{ key: 'descrip', label: 'Details', maxlength: 500 }];

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private procedureService: ProcedureService,
    public router: Router,
    public dialog: MatDialog,
  ) {}

  // oninit of the component, load the project from the id in the route (this component requires an id,
  // unlike proj-details, which can be used to create a new project if one isn't passed in through the route)
  ngOnInit() {
    this.projid = this.route.snapshot.paramMap.get('id') || null;
    if (Number(this.projid)) {
      this.getProject();
    } else {
      this.invalidID = true;
    }
  }

  // get the project from the api
  getProject() {
    this.loadingProject = true;

    if (this.projid) {
      this.projectService.getProject(this.projid).subscribe(
        (data) => {
          this.invalidID = data.table_form;
          if (!this.invalidID) {
            this.project = data;
            // I'm not sure if setting both to data would result in them just pointing to the same object,
            // so I'm making sure that it's a deep copy just in case
            this.dbProject = JSON.parse(JSON.stringify(data));
          }
          this.loadingProject = false;
        },
        () => {
          this.invalidID = true;
          this.loadingProject = false;
        },
      );
    }
  }

  /**
   * Remove the procedure and any files associated with it. If there are subsequent procedures,
   * then reduce their listingorder by 1 and change the file tags for their associated files accordingly.
   *
   * @param {number} index: index of the procedure we're removing
   */
  removeProc(index: number) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        header: 'Confirm Delete File',
        message: 'Are you sure that you wish to delete Procedure ' + String(index + 1) + '"?',
        falselabel: 'Cancel',
        truelabel: 'Delete',
        truebtn: 'btn-danger',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.project.procedures[index].delete_this_procedure = 'true';
        // this isn't really necessary, but just in case...so that we don't have 2 procedures with the same
        // listingorder if the delete fails
        this.project.procedures[index].listingorder = 9999;

        // move the files associated with procedures later in the listingorder to point at their new
        // listingorder value (also not really necessary becasue backend handles based on order,
        // but just in case...)
        for (let i = index + 1; i < this.project.procedures.length; i++) {
          this.project.procedures[i].listingorder--;
        }
        // note, the backend now handles updating the file tags, and reload of the files seems to happen
        // on its own when the procedure components get shifted up
        this.stateButtons.saveProject().subscribe();
      }
    });
  }

  /**
   * Get the fileupload component variable for the procedure with the passed-in listingorder.
   * NOTE: This function is currently no longer used because the backend now handles updating file tags,
   *       but keeping this function around in case it's needed for something else later on.
   *
   * @param {number} listingorder: listingorder of procedure for which we want to get the fileupload
   * @param {string} type: type of fileupload ('steps', 'equipment', or 'reagents')
   * @returns {FileUploadComponent} file upload component associated with the procedure
   */
  getFileUploadComponent(listingorder: number, type: string = 'steps'): FileUploadComponent | null {
    for (const procedure of this.procedureComponents.toArray()) {
      if (procedure.procedure.listingorder === listingorder) {
        return procedure.getFileUploadComponent(type);
      }
    }
    return null;
  }

  /**
   * On change of the primary or related publications, trim and sort them and attempt to retrieve
   * and display (preview) the publication info for them
   * @param {string} pubtype: type of publication ('primary', or 'reference')
   */
  pubsChange(pubtype: string) {
    if (this.project[pubtype + 'publications']) {
      const trimedList = [];
      for (const pmid of this.project[pubtype + 'publications'].split(',')) {
        const trimedPMID = pmid.trim();
        if (trimedPMID) {
          if (trimedList.indexOf(trimedPMID) === -1) {
            trimedList.push(trimedPMID);
          }
        }
      }
      const infoPMIDs = this.project[pubtype + 'publicationsinfo'].map(function (a: any) {
        return a.pmid;
      });
      // remove pmids from the info that are not in the new trimedList
      for (const pmid of infoPMIDs) {
        if (trimedList.indexOf(pmid) === -1) {
          this.project[pubtype + 'publicationsinfo'].splice(infoPMIDs.indexOf(pmid), 1);
        }
      }
      // the sorting will also remove duplicates from publications, since it re-maps the list of pmids based
      // on the list of publications info
      this.sortPublications(pubtype);
      // add pmids to the info that are in the new trimedList and not in the info
      for (const pmid of trimedList) {
        if (infoPMIDs.indexOf(pmid) === -1) {
          this.http.get<any>(this.api + 'publications?pmid=' + pmid).subscribe((result) => {
            // when we get the publication info back, add it and sort the preview by authorlist
            this.project[pubtype + 'publicationsinfo'].push(result);
            this.sortPublications(pubtype);
          });
        }
      }
    } else {
      this.project[pubtype + 'publicationsinfo'] = [];
    }
  }

  /**
   * Sort the given publication type by author (also removes duplicates)
   *
   * @param {string} pubtype: type of publication ('primary', or 'reference')
   * */
  sortPublications(pubtype: string) {
    this.project[pubtype + 'publicationsinfo'].sort(function (a: any, b: any) {
      if (a.error || !a.authorlist) {
        return 1;
      } else if (b.error || !b.authorlist) {
        return -1;
      }
      const x = a.authorlist.toLowerCase();
      const y = b.authorlist.toLowerCase();
      return x > y ? 1 : -1;
    });
    // map to the comma-separed string of the pmids in the same order
    this.project[pubtype + 'publications'] = this.project[pubtype + 'publicationsinfo']
      .map(function (a: any) {
        return a.pmid;
      })
      .join(', ');
  }

  // open a dialog to search or create a publication
  openPubDialog(pubtype: string): void {
    const dialogRef = this.dialog.open(PublicationDialogComponent, {
      data: { publication: {}, embedShowSearch: true },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.pmid) {
          if (this.project[pubtype + 'publications'].indexOf(result.pmid) === -1) {
            this.project[pubtype + 'publicationsinfo'].push(result);
            this.sortPublications(pubtype);
          }
        }
      }
    });
  }

  /**
   * Swap two procedures in the order (array), and change their listingorders.
   * Backend changes listingorder, but we need to
   *
   * @param {number} indexOne: index of the first procedure we want to swap
   * @param {number} indexTwo: index of the second procedure we want to swap
   */
  swapProcedures(indexOne: number, indexTwo: number) {
    const length = this.project.procedures.length;
    if (indexOne >= 0 && indexTwo >= 0 && indexOne !== indexTwo && indexOne < length && indexTwo < length) {
      const tempProc = this.project.procedures[indexOne];
      this.project.procedures[indexOne] = this.project.procedures[indexTwo];
      this.project.procedures[indexOne].listingorder = indexOne + 1;
      this.project.procedures[indexTwo] = tempProc;
      this.project.procedures[indexTwo].listingorder = indexTwo + 1;
      // note, the backend now handles updating the file tags, and reload of the files seems to happen
      // on its own when the procedure components get switched
      this.stateButtons.saveProject().subscribe();
    }
  }

  // Add a procedure and save immediately so that it gets assigned a procID
  addProcedure() {
    this.project.procedures.push({
      projid: this.project.projid,
      listingorder: this.project.procedures.length + 1,
      steps: [],
      equipment: [],
      reagents: [],
      environments: [{ environment: 'holding' }, { environment: 'testing' }],
      protocols_io: {},
    });
    this.stateButtons.saveProject().subscribe();
  }

  /**
   * Save the project and then copy one of the procedures into a template if successful.
   * Includes flashing messages.
   *
   * @param {any} compyInput: dict containing arguments for the copyProcedure function:
   *                             {sourceProcID: procedure id from which we are copying,
   *                              destProcID (optional): procedure id to which we are copying...
   *                                                       required if there's no templateName,
   *                              templateName (optional): templateName for new template...
   *                                                        required if there's no destProcID,
   *                              privateTemp (optional): true to make the new template private (if it's new)}
   */
  saveProcedureAsTemplate(compyInput: any) {
    if (compyInput.source_proc_id) {
      // TODO: fix nested subscribes
      this.stateButtons.saveProject().subscribe(
        () => {
          this.procedureService
            .copyProcedure(
              compyInput.source_proc_id,
              compyInput.destproc_id,
              compyInput.template_name,
              compyInput.private,
            )
            .subscribe(
              () => {
                this.stateButtons.flashMessage('Successfully saved procedure template!', 'alert-success', 2000);
              },
              (error2) => {
                this.stateButtons.flashMessage(
                  'Failed to save procedure template.',
                  'alert-danger',
                  10000,
                  error2.error ? [error2.error.message] : [],
                );
              },
            );
        },
        () => {
          this.stateButtons.flashMessage(
            'Failed to save project before saving procedure as template.',
            'alert-danger',
            5000,
          );
        },
      );
    }
  }
}
