import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// services
import { GroupService } from '../../services/group.service';
import { groupMembersTooltip } from '../utils';
import { environment } from '../../../environments/environment';
import { FormControl } from '@angular/forms';

// NOTE: This component is pretty similar and takes a lot from the combobox, but was specialized enough
//       (and will be needed often enough) that it seemed prudent to make it into its own component)
@Component({
  selector: 'group-search',
  template: `
    <!--Using fieldset as a wrapper to make disabling work (it works differently...and not as well...for
        inputs with the formControl attribute, but a wrapping fieldset seems to work fine)-->
    <div [class]="optionSelected() ? 'input-group' : ''">
      <fieldset [disabled]="optionSelected() || disabled">
        <span [matTooltip]="inputTooltip()">
          <input
            placeholder="Begin typing a group name"
            class="form-control left-bord-rad-4px"
            autocomplete="off"
            [matAutocomplete]="auto"
            [formControl]="valueCtrl"
            (focus)="onFocus()"
            (focusout)="inputFocused = false"
            [style.border-color]="inputNoSelection() ? 'red' : ''"
          />
        </span>
        <span class="form-control-feedback">
          <span *ngIf="optionSelected()" style="color:green;right:40px;" class="glyphicon glyphicon-ok"></span>
          <span
            *ngIf="inputNoSelection()"
            style="color:red;right:20px;"
            class="glyphicon glyphicon-warning-sign"
          ></span>
        </span>
      </fieldset>
      <div *ngIf="optionSelected()" class="input-group-btn">
        <button
          (click)="clearSelection()"
          type="button"
          [disabled]="disabled"
          matTooltip="Clear selected group"
          class="btn btn-danger"
        >
          <span class="glyphicon glyphicon-remove clear-icon"></span>
        </button>
      </div>
    </div>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected()" [displayWith]="displayFn">
      <mat-option disabled *ngIf="filteredOptions.length === 0" style="color:red;">
        No matches. Please select a group from the options.
      </mat-option>
      <mat-option
        *ngFor="let option of options"
        [value]="option"
        [disabled]="disableOption(option)"
        [matTooltip]="disableOption(option) ? '(Already Selected)' : ''"
      >
        <span [matTooltip]="groupMembersTooltip(option)">
          <b>{{ option.members.length }}</b>
          <span style="color:blue;padding-left:3px;" class="glyphicon glyphicon-user"></span>
        </span>
        |
        {{ option.name }}
      </mat-option>
      <mat-option
        disabled
        [style.display]="!showAllResults ? 'block' : 'none'"
        *ngIf="filteredOptions.length > 50"
        class="mat-option show-all"
      >
        <span [style.display]="loadingAll ? 'none' : 'block'">
          <a (click)="filterValues(valueCtrl.value, true)" style="cursor:pointer;">Show all results</a>
          or type more to narrow down results...
        </span>
        <span [style.display]="loadingAll ? 'block' : 'none'">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>Loading all results...
        </span>
      </mat-option>
    </mat-autocomplete>
  `,
  styleUrls: ['./searching.scss'],
})
export class GroupSearchComponent implements OnChanges {
  // technically 2-way, but used as a 1-way binding (this component > parent). This component
  // will emit the selected group to the parent when a group is selected, so having a variable to bind
  // to in the parent is easier than going off of an event alone.
  @Input() group: any;

  // true to disable the search box (including preventing it from being cleared out)
  @Input() disabled = false;
  // list of group objects to exclude (compare 'id')
  @Input() disableList: any[] = [];

  // event emitter to send the selected group to the parent when one is selected from the autocomplete options
  @Output() groupChange: EventEmitter<any> = new EventEmitter<any>();

  // make this utils function usable in the html
  groupMembersTooltip = groupMembersTooltip;

  // api URL
  api: string = environment.securedURLs.sip;

  // formControl for the input value
  valueCtrl: FormControl;

  // true if the input is currently focused, false otherwise
  inputFocused = false;

  // true to show all results if there are more than 50
  showAllResults = false;

  // true while all results are being loaded from the api
  loadingAll = false;

  // filtered autocomplete options, list of groups (any because typescript complains otherwise)
  filteredOptions: any[] = [];

  // latest string sent in api search request
  lastSearchStr = '';

  // set up the onchange observable with calls the groupService.getGroups() observable (http call)
  // to get filtered options in real time.
  constructor(public http: HttpClient, public groupService: GroupService) {
    this.valueCtrl = new FormControl();
    this.valueCtrl.valueChanges.subscribe((result) => {
      this.filterValues(result);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.group && changes.group ? this.group.id : false) {
      this.valueCtrl.setValue(this.group);
    } else if ((!this.valueCtrl.value || this.valueCtrl.value === '') && this.filteredOptions.length === 0) {
      // this causes the initial options to get loaded at load time rather than when the dropdown is focused
      this.filterValues('');
    }
  }

  get options(): any[] {
    return this.filteredOptions.length > 50 && !this.showAllResults
      ? this.filteredOptions.slice(0, 7)
      : this.filteredOptions;
  }

  /**
   * Filter the available panels in the dropdown to those for which the
   * user-entered string matches the panel name
   *
   * @param {any} newValue: string entered by the user or selected object
   * @param {boolean} showAll: true if the user selected to show all results, so we're getting the rest from the api
   */
  filterValues(newValue: any, showAll = false) {
    newValue = newValue ? newValue : '';
    if (typeof newValue !== 'string') {
      this.filteredOptions = [];
    } else {
      if (!showAll) {
        this.showAllResults = false;
      } else {
        this.loadingAll = true;
      }
      this.lastSearchStr = newValue;
      // there's a bit of a race condition here where later requests can finish before earlier requests,
      // so we're keeping track of the last searched string and only setting filteredOptions if the result matches
      // (or there are no results, which is likely either because the string is too long to match anything, so
      //  that request should come back quicker than a subsequent request with results)
      this.groupService
        .getGroups(newValue, showAll ? 0 : 51, showAll ? this.filteredOptions.length : 0)
        .subscribe((result) => {
          if (result.length > 0) {
            if (result[0].search_str === this.lastSearchStr) {
              if (showAll) {
                this.filteredOptions = this.filteredOptions.concat(result);
                this.showAllResults = true;
              } else {
                this.filteredOptions = result;
              }
            }
          } else if (!showAll) {
            this.filteredOptions = result;
          } else {
            this.showAllResults = true;
          }
          this.loadingAll = false;
        });
    }
  }

  // determines whether an option was selected
  optionSelected(): boolean {
    return this.group && typeof this.group === 'object' ? this.group.id : false;
  }

  // determines whether the current user has input a value without selecting an option
  inputNoSelection(): boolean {
    return !this.optionSelected() && this.valueCtrl.value && !this.inputFocused;
  }

  // determines whether the selected option is not yet approved by a curator
  optionNotApproved(option: any): boolean {
    return option ? (option.user_creator ? option.user_creator.id : false) : false;
  }

  // builds a string describing the flag for an option that is not yet approved by a curator
  optionNotApprovedTooltip(option: any): string {
    return (
      'This group was added by the user, ' +
      option.user_creator.name_or_email +
      ', and is pending SIP Curator approval.'
    );
  }

  // builds the tooltip for the input if there's an issue
  inputTooltip(): string {
    if (this.inputNoSelection()) {
      return 'Must select option for this field to be populated (click in field to see options)';
    } else if (this.optionNotApproved(this.group)) {
      return this.optionNotApprovedTooltip(this.group);
    }
    return '';
  }

  /**
   * Determine if the option for selecting this group should be disabled because it's in the disableList
   * @param {Object} group: group object option
   * @returns {boolean}: true to disable this group option, false to not
   */
  disableOption(group: any) {
    for (let i = 0; i < this.disableList.length; i++) {
      if (this.disableList[i].id === group.id) {
        return true;
      }
    }
    return false;
  }

  /**
   * display function for the group object
   * @param {any} option: group object or undefined
   * @returns {string}: display_str ('first middle last, degree | email')
   */
  displayFn(option?: any): string | undefined {
    if (!option) {
      return undefined;
    }
    if (typeof option === 'object') {
      return option.name + ' | ' + String(option.members.length) + ' Members';
    }
    return option;
  }

  // if an option is selected, emit a groupChange event so that the parent knows who is selected
  onOptionSelected() {
    this.group = this.valueCtrl.value;
    this.groupChange.emit(this.group);
  }

  // upon focusing on a field, if the current value is blank and we have no options, then create them
  onFocus() {
    this.inputFocused = true;
    const value = this.valueCtrl.value;
    if ((!value || value === '') && this.filteredOptions.length === 0) {
      this.filterValues(value ? value : '');
    }
  }

  // clear currently selected group
  clearSelection() {
    this.valueCtrl.setValue('');
    this.group = {};
    this.groupChange.emit(this.group);
  }
}
